<div class="item-details-content">
  <div class="bg-item">
    <div class="itemDetails" fxLayout="column">
      <div class="header-survey">
        <div class="content">
          <div fxLayout="row" fxLayoutAlign="start center">
            <custom-button importance="primary-small" text="{{'generic.CLOSE' | translate | uppercase }}"
              (onClick)="backToItemDetails()">
            </custom-button>
            <h5 *ngIf="!isQualitiOfServiceSurvey" tabindex="0">{{ 'survey.SURVEY_RESULT' | translate }}</h5>
            <h5 *ngIf="isQualitiOfServiceSurvey" tabindex="0">{{ 'survey.SURVEY_COMPLETED' | translate }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxfxLayout="row" class="survey content">
    <div class="survey-wrapper">

      <!-- Loader -->
      <ng-template #fetchingDataLoader>
        <generali-loader></generali-loader>
      </ng-template>

      <div *ngIf="initiativeLoaded; else fetchingDataLoader" class="inviteBoxBottom" fxfxLayout="row"
        fxLayoutAlign="space-between center">
        <div class="main-wrapper" fxFlexFill>
          <div fxLayout="row" fxLayoutAlign="start center" class="control-panel-wrapper center-box width-auto"
            fxFlexFill layout-wrap>
            <div class="control-panel-obj-detail md-whiteframe-1dp no-padding no-margin" fxFlexFill>
              <div class="control-panel-top-bar"></div>
              <div fxLayout="column" fxLayoutAlign="center start">
                <div layout-xs="column" fxLayout="row" fxLayoutAlign="start center" layout-wrap fxFlexFill>
                  <form name="surveyQuestionsForm" class="form-validation" fxFlexFill>
                    <div class="box-group">
                      <div fxLayout="row">
                        <div *ngIf="!isQualitiOfServiceSurvey" class="survey-infobox-container">
                          <p *ngIf="isSurveyPassed && !textSurveyPassed" translate="survey.PASSED"></p>
                          <p style="display: inline" *ngIf="isSurveyPassed && textSurveyPassed">{{textSurveyPassed}}</p>
                          <p style="display: inline"
                            *ngIf="isSurveyPassed && textSurveyPassed && textCustomTitlePosition"> {{ itemTitle }}</p>
                          <p style="display: inline" *ngIf="isSurveyPassed && textSurveyPassed">
                            {{ textCustomTitlePosition }}</p>

                          <p *ngIf="!isSurveyPassed && !textSurveyNotPassed" translate="survey.NOT_PASSED"></p>
                          <p *ngIf="!isSurveyPassed && textSurveyNotPassed">{{textSurveyNotPassed}}</p>
                          <div fxLayout="row" *ngIf="!isSurveyPassed && textIfCanRepeat" class="btn-container">
                            <span translate="survey.NOT_PASSED_RETRY"></span>
                          </div>
                          <div fxLayout="row" *ngIf="showWrongAnswers && !isSurveyPassed && wrongQuestions >= 0"
                            class="btn-container">
                            <span translate="survey.WRONG_QUESTION_COUNT"></span>
                            <span>&nbsp;{{ wrongQuestions}}</span>
                          </div>
                          <div class="space15"></div>
                          <div fxLayout="row" *ngIf="showCountAnswers" class="btn-container">
                            <span translate="survey.NUMBER_OF_PASSED_SURVEY1"> </span>
                            <span>&nbsp;{{correctQuestions }}&nbsp;</span>
                            <span translate="survey.NUMBER_OF_PASSED_SURVEY2">
                            </span><span>&nbsp;{{ totalQuestions}}</span>
                            <div class="space15"></div>
                          </div>
                          <div *ngIf="showWrongAnswers">
                            <p *ngIf="wrongQuestions > 0" translate="survey.WRONG_ANSWERS"></p>
                            <p *ngIf="wrongQuestions < 1" translate="survey.NO_WRONG_ANSWERS"></p>
                            <ul>
                              <div *ngFor="let wronganswer of listWrongAnswers">
                                <li><span>{{wronganswer}}</span></li>
                              </div>
                            </ul>
                            <div class="space15"></div>
                          </div>
                        </div>

                        <!-- Questionario di gradimento -->
                        <div *ngIf="isQualitiOfServiceSurvey" class="survey-infobox-container">
                          <p translate="survey.THANKS"></p>
                          <div fxLayout="row" class="btn-container">
                            <span translate="survey.FEEDBACK_SURVEY_COMPLETED_HINT"></span>
                          </div>
                          <div class="space15"></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>