/*
 * Servizio che invia i dati al DataLayer di Google Tag Manager per Google Analytics emettendo anche gli eventi di tracciamento.
 *
 * Per funzionare necessita di:
 * - Un pezzo di codice nel constructor dell'app.component:
 *     // Creo e carico lo script di Google Tag Manager per Google Analytics
 *     const script = document.createElement('script');
 *     script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
 *     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
 *     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
 *     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
 *     })(window,document,'script','dataLayer','${environment.googleTagManagerCode}')`
 *     document.head.insertBefore(script, null);
 *     // Fine
 * - un attributo nell'environment che lo leghi al TagManager (es: googleTagManagerCode: "GTM-XXXXXX")
 * - poiché il googleTagManagerCode è diverso per produzione e non produzione, per la build in sviluppo o staging è necessario
 *   utilizzare la seguente sintassi, affinché venga creato il pacchetto bundle finale in stile produzione
 *   ma con il codice Google Tag Manager corretto: ng build —aot —configuration=dev
 */

import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as moment from "moment";

import { User } from "atfcore-commonclasses/bin/classes/anag";

import * as fromApp from "../../ngrx/app.reducers";

import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";

/** Elenco degli userId da ignorare. Sono una copia di quelli definiti in AtfCore conf/default.json */
const USERIDS_TO_SKIP_IN_PRODUCTION = [
  "0000",
  "1111",
  "2222",
  "DEMO",
  "DEMO1",
  "DEMO2",
  "DEMO3",
  "DEMO4",
  "DEMO5",
  "DEMO6",
  "DEMO7",
  "DEMO8",
  "DEMO9",
  "DEMO10",
  "DEMO_2",
];

const STREAM_SOURCE = "LearningUP Web";
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private store: Store<fromApp.AppState>;
  private commonCustomDimensions: CommonCustomDimensions;
  private loggedUser: User;

  // Tengo i dati dell'ultima pagina vista per poterla inviare negli eventi e evitare invii doppi (es: al refresh del token)
  private lastPageSent: string;
  private lastTitleSent: string;

  constructor(
    _store: Store<fromApp.AppState>,
    private route: ActivatedRoute) {
    this.store = _store;
    this.route = route;
  }

  /** Metodo che permette di ignorare l'invio ad Analytics di un set di utenti di test in ambiente di produzione */
  private skipSendForTestUsers(): boolean {
    return !!(
      environment.skipSendTestUsersToAnalytics &&
      this.loggedUser &&
      USERIDS_TO_SKIP_IN_PRODUCTION.some(
        (skip) => skip == this.loggedUser.userId
      )
    );
  }

  /** Calcola le fasce partendo da una data */
  private calcYearRange(refDate: Date): string {
    if (refDate) {
      let now = moment();
      let ref = moment(refDate);
      var yearDiff = now.diff(ref, "years");
      if (yearDiff <= 2) {
        return "0-2";
      } else if (yearDiff <= 5) {
        return "3-5";
      } else if (yearDiff <= 10) {
        return "6-10";
      } else if (yearDiff <= 15) {
        return "11-15";
      } else if (yearDiff <= 20) {
        return "16-20";
      } else if (yearDiff <= 30) {
        return "21-30";
      } else if (yearDiff <= 40) {
        return "31-40";
      } else if (yearDiff <= 50) {
        return "41-50";
      } else if (yearDiff <= 60) {
        return "51-60";
      } else if (yearDiff <= 70) {
        return "61-70";
      } else if (yearDiff <= 80) {
        return "71-80";
      } else {
        return "80+";
      }
    }
    return null;
  }

  /** L'utente sta navigando su una nuova pagina */
  sendVirtualPageViewEvent(
    pageUrl: string,
    pageTitle: string,
    pageViewCustomDimensions?: VirtualPageViewCustomDimensions
  ) {
    // Se non ho ancora i dati comuni dell'utente...
    if (!this.commonCustomDimensions) {
      // ...li calcolo
      this.store.select(fromApp.getLoggedUser).subscribe((loggedUser) => {
        if (loggedUser && loggedUser.user) {
          this.loggedUser = loggedUser.user;
          // Se ho il loggedUser significa che sono autenticato
          this.commonCustomDimensions = {
            isAuthenticated: true,
            fasciaEta: this.calcYearRange(loggedUser.user.dataNascita),
            chiaveSesso: loggedUser.user.chiaveSesso,
            defTpDip: loggedUser.user.defTpDip,
            socAppartenenza: loggedUser.user.socAppartenenza,
            socDistacco: loggedUser.user.socDistacco,
            chief: loggedUser.user.chief,
            sedeLavoro: loggedUser.user.sedeLavoro,
            anonymousId: loggedUser.user.anonymousId
          };
        }
        // ... e poi chiamo Analytics
        this._sendPageViewInternal(
          pageUrl,
          pageTitle,
          pageViewCustomDimensions,
          this.commonCustomDimensions
        );
      });
    } else {
      // Se li ho già, chiamo subito Analytics
      this._sendPageViewInternal(
        pageUrl,
        pageTitle,
        pageViewCustomDimensions,
        this.commonCustomDimensions
      );
    }
  }

  /** L'utente sta per iniziare un login social */
  sendSocialLoginStartedEvent(socialType: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualSocialLoginStarted",
        socialType: socialType,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha scelto la seniority nella profilazione */
  sendRegistrationSeniorityChoosedEvent(seniority: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualRegistrationSeniorityChoosed",
        seniority: seniority,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha scelto gli interessi nel profilo */
  sendProfileInteresseChoosedEvent(interesse: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualProfileInteresseChoosed",
        interesse: interesse,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha scelto gli interessi nella profilazione */
  sendRegistrationInteresseChoosedEvent(interesse: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualRegistrationInteresseChoosed",
        interesse: interesse,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha scelto gli ambiti di interesse nel profilo */
  sendProfileAmbitoDiInteresseChoosedEvent(ambito: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualProfileAmbitoDiInteresseChoosed",
        ambito: ambito,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha scelto gli ambiti di interesse nella profilazione */
  sendRegistrationAmbitoDiInteresseChoosedEvent(ambito: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualRegistrationAmbitoDiInteresseChoosed",
        ambito: ambito,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha scelto la seniority nel profilo */
  sendProfileSeniorityChoosedEvent(seniority: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualProfileSeniorityChoosed",
        seniority: seniority,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente apre il tutorial dell'applicazione */
  sendOnboardingTutorialOpenedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualOnboardingTutorialOpened",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente chiude il tutorial dell'applicazione */
  sendOnboardingTutorialFinishedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualOnboardingTutorialFinished",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha completato la profilazione */
  sendProfilingCompletedEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualProfilingCompleted",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente salva una posizione lavorativa */
  sendJobPositionSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualJobPositionSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente rimuove dal salvataggio una posizione lavorativa */
  sendJobPositionUnSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualJobPositionUnSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente avvia il contenuto di un corso (video e scorm) */
  sendVirtualContentPlayEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualContentPlay",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente conferma la partecipazione ad un evento */
  sendEventSignupEvent(id: string, onlineEventLink: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualEventSignup",
        id: id,
        onlineEventLink: onlineEventLink,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente conferma l'iscrizione ad un evento */
  sendEventSubscribeEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualEventSubscribe",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente conferma la disiscrizione ad un evento */
  sendEventUnsubscribeEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualEventUnsubscribe",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente scarica un certificato */
  sendCertificateDownloadEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualCertificateDownload",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente scarica un certificato */
  sendChallengeAcceptedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualChallengeAccepted",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente riscatta un premio */
  sendGiftClaimEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualGiftClaim",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente salva un  articolo */
  sendArticleSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualArticleSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente rimuove dal salvataggio un articolo */
  sendArticleUnSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualArticleUnSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente rimuove dal salvataggio un corso */
  sendCourseSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualCourseSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente rimuove dal salvataggio un corso */
  sendCourseUnSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualCourseUnSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente ha inviato un messaggio in chat */
  sendChatMessageSentEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualChatMessageSent",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente salva un evento */
  sendEventSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualEventSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente rimuove dal salvataggio un evento */
  sendEventUnSavedEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualEventUnSaved",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente si candida ad una posizione lavorativa */
  sendJobPositionApplicationSentEvent(id: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualJobPositionApplicationSent",
        id: id,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  private _sendPageViewInternal(
    pageUrl: string,
    pageTitle: string,
    pageViewCustomDimensions: VirtualPageViewCustomDimensions,
    commonDimensions: CommonCustomDimensions
  ) {
    if (!this.skipSendForTestUsers()) {
      // Taglio l'url per togliere i parametri
      let pageUrlParts = pageUrl && pageUrl.split("?");
      let newPage = (pageUrlParts && pageUrlParts[0]) || null;
      // Se la nuova pagina è la stessa che ho "appena" inviato, non la invio di nuovo
      if (newPage !== this.lastPageSent || pageTitle !== this.lastTitleSent) {
        this.lastPageSent = newPage;
        this.lastTitleSent = pageTitle;
        let pvcd = <VirtualPageViewCustomDimensions>(
          (pageViewCustomDimensions || {})
        );
        let ccd = <CommonCustomDimensions>(commonDimensions || {});

        this.route.queryParams.subscribe(params => {
          window["dataLayer"].push({
            event: "VirtualPageView",
            page: newPage,
            title: pageTitle,
            isAuthenticated: ccd.isAuthenticated || null,
            fasciaEta: ccd.fasciaEta || null,
            chiaveSesso: ccd.chiaveSesso || null,
            defTpDip: ccd.defTpDip || null,
            socAppartenenza: ccd.socAppartenenza || null,
            socDistacco: ccd.socDistacco || null,
            chief: ccd.chief || null,
            sedeLavoro: ccd.sedeLavoro || null,
            id: pvcd.id || null,
            titleDetail: pvcd.titleDetail || null,
            itemType: pvcd.itemType || null,
            itemTypeSpecialization: pvcd.itemTypeSpecialization || null,
            anonymousId: ccd.anonymousId || null,
            streamSource: STREAM_SOURCE,
            utm_source: params["utm_source"],
            utm_medium: params["utm_medium"],
            utm_id: params["utm_id"],
            utm_campaign: params["utm_campaign"],
            utm_content: params["utm_content"],
            utm_term: params["utm_term"]
          });
        });
      }
    }
  }


  /** L'utente sta aggiungendo o rimuovendo il Like ad un oggetto */
  sendLikeUnLikeEvent(likeType: string, itemId: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualLikeUnLike",
        likeType: likeType,
        id: itemId,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina avvia il processo di condivisione di un oggetto con i colleghi (apre la modale di condivisione) */
  sendObjectShareStartEvent(itemId: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectShareStart",
        id: itemId,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina completa il processo di condivisione di un oggetto con i colleghi */
  sendObjectShareDoneEvent(itemId: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectShareDone",
        id: itemId,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina aggiunge alla wishlist un oggetto */
  sendObjectWishlistedEvent(itemId: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectWishlistedEvent",
        id: itemId,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina aggiunge ai preferiti un oggetto */
  sendObjectBookmarkedEvent(itemId: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualObjectBookmarkedEvent",
        id: itemId,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina apre la sezione per il supporto */
  sendViewSupportSectionEvent() {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualViewSupportSection",
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente scarica una infografica della formazione */
  sendTrainingInfographPdfDownloadedEvent(infographName: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualTrainingInfographPdfDownloaded",
        infographName: infographName,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente essendo su una pagina effettua una ricerca di contenuti (solo ricerca iniziale - no paginazioni successive, no filtri aggiuntivi) */
  sendSearchEvent(searchedText: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualSearch",
        searchedText: searchedText,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** L'utente sta per lasciare la piattaforma per accedere ad un link esterno */
  sendOutboundLinkEvent(destinationUrl: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualOutboundLink",
        destinationUrl: destinationUrl,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        anonymousId:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.anonymousId) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** Click sui tag sotto la barra di ricerca, accanto alla voce "Lasciati ispirare" */
  sendEventTagClickHome(searchedText: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualInspiration",
        searchedText: searchedText,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        title: this.lastTitleSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** Click sul Box notifiche */
  sendEventNotificationClick(title: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualNotification",
        title: title,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** Click sul Box scadenze */
  sendEventExpirationClick(title: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "VirtualToDo",
        title: title,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** Click sul Box scadenze */
  sendEventQuickLinkClick(title: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "virtualQuickLink",
        title: title,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }

  /** Click su una card di un carosello */
  sendEventCarouselClick(carouselName: string, id: string, title: string) {
    if (!this.skipSendForTestUsers()) {
      window["dataLayer"].push({
        event: "CarouselClick",
        id: id,
        carouselName: carouselName,
        title: title,
        // Dati utente e basati sull'ultimo invio
        page: this.lastPageSent,
        isAuthenticated:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.isAuthenticated) ||
          null,
        fasciaEta:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.fasciaEta) ||
          null,
        chiaveSesso:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.chiaveSesso) ||
          null,
        defTpDip:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.defTpDip) ||
          null,
        socAppartenenza:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socAppartenenza) ||
          null,
        socDistacco:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.socDistacco) ||
          null,
        chief:
          (this.commonCustomDimensions && this.commonCustomDimensions.chief) ||
          null,
        sedeLavoro:
          (this.commonCustomDimensions &&
            this.commonCustomDimensions.sedeLavoro) ||
          null,
        streamSource: STREAM_SOURCE
      });
    }
  }
}


export class CommonCustomDimensions {
  isAuthenticated: boolean;
  fasciaEta: string;
  chiaveSesso: string;
  defTpDip: string;
  socAppartenenza: string;
  socDistacco: string;
  chief: string;
  sedeLavoro: string;
  anonymousId: string;
}

export class VirtualPageViewCustomDimensions {
  id?: string; // Id del contenuto (itemId di un oggetto Library/Corso, oppure userId di un utente)
  titleDetail?: string; // Ulteriori specifiche al titolo della pagina (il nome del corso o della persona di cui sto guardando il dettaglio)
  isMandatoryTraining?: boolean; // Se corso di formazione obbligatoria (solo nella pagina di dettaglio)
  itemType?: string; // Tipologia di corso
  itemTypeSpecialization?: string; // Tipologia di corso in deroga
}
