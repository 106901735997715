<div class="default-background" [ngClass]="{'dark-background': darkBackground}">
  <!-- slider card corsi suggeriti -->
  <div class="section-content-wrapper" fxLayout="row">
    <!-- Contenuto bloccato -->
    <div (click)="emitOnContentBlockedClicked()" class="content-locked-layer" *ngIf="isContentBlocked" fxLayout="column"
      fxLayoutAlign="center center">
    </div>

    <!-- Scroll a sinistra -->
    <div *ngIf="!preventShowArrows && contentLength >= 5" class="arrow-nav position-left"
      [ngClass]="{'disabled': leftLimit}" (click)="scrollCards(sectionId,'left')">
      <svg-icon src="assets/icons/icon-angle-left.svg"></svg-icon>
    </div>

    <!-- slick card corsi suggeriti -->
    <div class="slick-content-wrapper">
      <!-- Wrapper risultati -->
      <div id="{{sectionId}}" *ngIf="contentLength > 0; else noData" class="slick-slider-wrapper"
        (mousemove)="cardsMouseMove(sectionId,$event)" (mousedown)="cardsMouseDown(sectionId,$event)"
        (mouseleave)="cardsMouseUp(sectionId, $event)" (mouseup)="cardsMouseUp(sectionId, $event)"
        [ngClass]="{ 'cursor-grabbing' : cardClicked[sectionId], 'cursor-grab': contentLength >= 5}" fxLayout="row"
        fxLayoutGap="16px" fxLayoutAlign="start start">
        <ng-content></ng-content>
      </div>
      <!-- Placeholder nessun risultato -->
      <ng-template #noData>
        <!-- Placeholder nessuna notifica -->
        <div class="placeholder-row" [ngClass]="{'margin0': isFromYourAppsHome}" fxLayout="row" fxLayout.sm="column"
          fxLayout.md="column" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutAlign.sm="start start"
          fxLayoutAlign.md="start start" fxLayoutAlign.xs="start start">
          <div ngClass.xs="truncate-images">
            <img alt="image" src="../../../../assets/img/first-img-placeholder-slick.png">
            <img alt="image" class="margin-left20" src="../../../../assets/img/second-img-placeholder-slick.png">
          </div>
          <div fxLayout="column" ngClass.xs="margin-top25 margin-left0-important" ngClass.sm="margin-top25 margin-left0"
            ngClass.md="margin-top25 margin-left0" class="margin-left-24 margin-right24">
            <h2 tabindex="0" class="no-news-title" [ngClass]="{'white-color': isFromYourAppsHome}"
              translate="generic.NO_NEWS_SLICK_PLACEHOLDER"></h2>
            <p tabindex="0" class="no-contents-subtitle" [ngClass]="{'white-color': isFromYourAppsHome}"
              translate="generic.NO_CONTENTS_SLICK_PLACEHOLDER"></p>
          </div>
        </div>
      </ng-template>
    </div>

    <!-- Scroll automatico a destra -->
    <div *ngIf="!preventShowArrows && contentLength >= 5" class="arrow-nav position-right"
      (click)="scrollCards(sectionId,'right')">
      <svg-icon src="assets/icons/icon-angle-right.svg"></svg-icon>
    </div>
  </div>
</div>