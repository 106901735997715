<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary" fxLayout="row" fxFlexFill>
	<div class="login-column" fxFlex.sm="50" fxFlex.gt-sm="40" fxFlexFill-xs fxLayout="column"
		fxLayoutAlign="center center">
		<img width="140" src="assets/img/logo_generali.svg"
			alt="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}"
			title="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}">
		<p translate="login.SAML_ERROR"></p>
		<p *ngIf="error && error.specificError">{{error.specificError}}</p>
	</div>
</div>