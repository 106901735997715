import { ElementRef, AfterViewInit, Input, Renderer2, Directive, OnDestroy } from '@angular/core';
import { UrlService } from 'src/app/shared/services/url.service';
import { getSocialLoginConfig } from 'src/app/shared/utils';

@Directive({
    selector: '[linkedinShare]'
})

export class LinkedInShareComponent implements AfterViewInit, OnDestroy {
    @Input() linkedinShareUrl: string;
    listenerFn: () => void;
    config: any = null;
    constructor(private element: ElementRef, private renderer: Renderer2, private urlService: UrlService) {
        const conf = getSocialLoginConfig(this.urlService);
        this.config = conf.linkedIn;

        const url = 'https://platform.linkedin.com/in.js';
        if (!document.querySelector(`script[src='${url}']`)) {
            let script = document.createElement('script');
            script.src = url;
            script.innerHTML = `
             lang: ${this.config.lang}
              api_key: '${this.config.clientId}'
              authorize: ${this.config.authorize}
             `;
            document.body.appendChild(script);
        }
    }

    onClick() {
        let articleUrl = encodeURIComponent(this.linkedinShareUrl);
        let articleTitle = encodeURIComponent('Certificato');
        let articleSummary = encodeURIComponent('Certificato');
        var articleSource = encodeURIComponent('GeneraliLearningPlatform');
        var goto = 'http://www.linkedin.com/shareArticle?mini=true' +
            '&url=' + articleUrl +
            '&title=' + articleTitle +
            '&summary=' + articleSummary +
            '&source=' + articleSource;
        let left = (screen.width - 570) / 2;
        let top = (screen.height - 570) / 2;
        let params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left + ",scrollbars=no;resizable=no";
        window.open(goto, "LinkedIn", params);
    }

    ngAfterViewInit(): void {
        this.listenerFn = this.renderer.listen(this.element.nativeElement, 'click', () => {
            this.onClick()
        });
    }

    ngOnDestroy() {
        if (this.listenerFn) {
            this.listenerFn();
        }
    }
}