import { Component, Input, Output, EventEmitter, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { Subject } from 'rxjs';
import { GeneraliCarouselComponent } from 'generali';

@Component({
  selector: 'modal-badge-content',
  templateUrl: './modal-badge-content.component.html',
  styleUrls: ['./modal-badge-content.component.scss']
})

export class ModalBadgeContentComponent implements AfterViewInit {
  @Input() entireObject: any;
  @Input() contentLength: number;
  @Input() carouselIndex: number = 0;
  @Output() closeFunction = new EventEmitter<any>();

  @ViewChild("badgesInfoCarousel") badgesInfoCarousel: GeneraliCarouselComponent;

  currentYear: number = new Date().getFullYear();
  scrollToRight: Subject<void> = new Subject<void>();
  scrollToLeft: Subject<void> = new Subject<void>();
  showLeftArrow: boolean;
  showRightArrow: boolean;

  constructor(public redirectService: RedirectService) {
  }

  ngAfterViewInit() {
    let timeout = setTimeout(() => {
      // Le immagine devono caricarsi. Senza timeout il carosello non riesce a calcolare
      // la larghezza degli elementi
      this.badgesInfoCarousel.setElementWidth();
      this.badgesInfoCarousel.toIndexNoSmooth(0);
      this.checkVisibilityGalleryArrows();
      clearTimeout(timeout);

    }, 0);
  }

  // Chiude la modale
  closeBadgeModal(): void {
    this.closeFunction.emit();
  }

  onScrollEvent(index: number) {
    this.carouselIndex = index;
    setTimeout(() => {
      this.checkVisibilityGalleryArrows();
    }, 100)
    
  }

  checkVisibilityGalleryArrows(): void {
    if (this.badgesInfoCarousel) {
      this.showLeftArrow = this.carouselIndex > 0;
      this.showRightArrow = this.carouselIndex < (this.contentLength - 1);
    } else {
      this.showLeftArrow = false;
      this.showRightArrow = false;
    }
  }

  scrollToRightBadgeInfo() {
    this.scrollToRight.next();
  }

  scrollToLeftBadgeInfo() {
    this.scrollToLeft.next();
  }
}