/*
 * Servizio che gestisce le chiamate ai servizi relative agli item
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ItemAttributeTypes, ItemAttributeObjectTypes, ItemTypes, ConsumableItem, CommonConstants, Item, ItemChild, User, CourseManagerItem, Survey, SenecaResponse, TextTemplate, EnrichedItemTaker, CourseCatalog, CourseDateCatalog } from 'atfcore-commonclasses';
import { TranslateService } from '@ngx-translate/core';
import { take, map } from 'rxjs/operators';
import { ItemUtil, IDataItem } from 'src/app/home/item-details/models/item.model';
import { GlpItem } from 'src/app/home/item-details/utils/item-details.utils';
import { ExtendedSurvey } from 'src/app/shared/models/extended-survey.module';
import { UrlService } from 'src/app/shared/services/url.service';
import { Observable } from 'rxjs';

export const libraryItems: string[] = ['BOOK', 'GRAPHS', 'LEARNING', 'MAGAZINE', 'SCORM', 'MAGAZINES', 'PLAN', 'PODCAST', 'BOOKS', 'DOCUMENT', 'DVD', 'EBOOK', 'ELEARNING', 'GRAPH', 'IMAGE', 'VIDEO', 'SURVEY', 'SURVEY_ITEM', 'CERTIFICATE_ITEM'];
export const libraryContainers: string[] = ['LEARNING_PLAN', 'PROJECT', 'SECTION', 'CONTAINER'];
export const onlineItems: string[] = ['ASSESSMENT', 'WEBINAR'];

export const SEARCHABLE_ITEM_TYPES: string[] = [
    ItemTypes.ITEM,
    ItemTypes.CONTAINER,
    ItemTypes.ITEMS_COLLECTION,
    ItemTypes.DAM_ITEM,
    ItemTypes.COURSE_SYLLABUS,
    ItemTypes.SCORM_FREE,
    ItemTypes.SCORM_INVITE
];
export const LIBRARY_ITEM_TYPES: string[] = [
    ItemTypes.ITEM,
    ItemTypes.CONTAINER,
    ItemTypes.ITEMS_COLLECTION,
    ItemTypes.DAM_ITEM,
    ItemTypes.SCORM_FREE,
    ItemTypes.SCORM_INVITE
];
export const LIBRARY_CONTAINERS_ITEM_TYPES: string[] = [
    ItemTypes.CONTAINER
];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS: string[] = [
    ItemAttributeObjectTypes.PODCAST,
    ItemAttributeObjectTypes.GRAPH,
    ItemAttributeObjectTypes.DOCUMENT,
    ItemAttributeObjectTypes.ELEARNING,
    'SCORM',
    ItemAttributeObjectTypes.VIDEO,
    ItemAttributeObjectTypes.IMAGE,
    ItemAttributeObjectTypes.PROJECT,
    ItemAttributeObjectTypes.LEARNING_PLAN,
    ItemAttributeObjectTypes.EBOOK,
    ItemTypes.CERTIFICATE_ITEM,
    ItemAttributeObjectTypes.SURVEY
];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS_WITHOUTH_CONTAINRS: string[] = [
    ItemAttributeObjectTypes.PODCAST,
    ItemAttributeObjectTypes.GRAPH,
    ItemAttributeObjectTypes.DOCUMENT,
    ItemAttributeObjectTypes.ELEARNING,
    ItemTypes.CERTIFICATE_ITEM,
    'SCORM',
    ItemAttributeObjectTypes.VIDEO,
    ItemAttributeObjectTypes.IMAGE,
    ItemAttributeObjectTypes.EBOOK,
    ItemAttributeObjectTypes.SURVEY
];

export const getBaseUrlForRedirectFromItem = (url) => {
    let baseUrl;
    if (url && url.length) {
        if (url.indexOf('/home/myLearning') !== -1) {
            baseUrl = '/home/myLearning/';
        } else if (url.indexOf('/home/learningSystem') !== -1) {
            baseUrl = '/home/learningSystem/';
        } else if (url.indexOf('/home/calendar') !== -1) {
            baseUrl = '/home/calendar/';
        } else if (url.indexOf('/home/dashboard') !== -1) {
            baseUrl = '/home/dashboard/';
        } else if (url.indexOf('/home/trainingPassport/clusters') !== -1) {
            baseUrl = '/home/trainingPassport/clusters/home';
        } else if (url.indexOf('/home/trainingPassport/myTraining') !== -1) {
            baseUrl = '/home/trainingPassport/myTraining/';
        } else if (url.indexOf('/home/trainingPassport') !== -1) {
            baseUrl = '/home/trainingPassport/';
        } else if (url.indexOf('/home/diversityAndInclusion') !== -1) {
            baseUrl = '/home/diversityAndInclusion/';
        } else if (url.indexOf('/home/womenInsuranceNetwork') !== -1) {
            baseUrl = '/home/womenInsuranceNetwork/';
        } else if (url.indexOf('/home/faculty') !== -1) {
            baseUrl = '/home/faculty/facultyWhat';
        } else if (url.indexOf('/homeSearch') !== -1) {
            baseUrl = '/search/homeSearch';
        } else {
            baseUrl = '/home/';
        }
    }

    return baseUrl;
}

@Injectable()
export class ItemsService {
    private _cm2MediatorUrl: string;

    constructor(private translate: TranslateService,
        private urlService: UrlService,
        private http: HttpClient) {
        this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
    }

    getStageIdOfSyllabus(syllabusId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('syllabusId', syllabusId);
        return this.http.get<SenecaResponse<string>>(this._cm2MediatorUrl + 'get-latest-stageId-from-syllabusid', {
            params: httpParams
        });
    }

    getDownloadTempFileUrl(filename: string, ssorqtp?: string) {
        return this._cm2MediatorUrl + 'download-temp-file?filename=' + filename + '&ssortkqp=' + ssorqtp;
    }

    generateCourseCertificates(editionId: string, userIds?: string[], asImage?: boolean, getFullPath?: boolean, isEuropAssistanceUser?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', editionId);

        httpParams = getFullPath ? httpParams.append('getFullPath', 'true') : httpParams;
        httpParams = asImage ? httpParams.append('asImage', 'true') : httpParams;
        httpParams = isEuropAssistanceUser ? httpParams.append('euraCertificates', 'true') : httpParams
        if (userIds && userIds.length) {
            userIds.forEach(userId => {
                httpParams = httpParams.append('userIds', userId);
            })
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-all-certificates', {
            params: httpParams
        });
    }

    sendValutation(itemId: string, vote: number) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'judge-course', { itemId: itemId, vote: vote });
    }

    getListCourseCertificates() {
        let httpParams = new HttpParams();
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'list-certificates', {
            params: httpParams
        });
    }

    // Imposta l'etichetta in traduzione degli item library
    setItemTypologyTranslations = (itemList) => {
        if (itemList && itemList.length) {
            for (let i = 0, itemsLength = itemList.length; i < itemsLength; i++) {
                let currentItem = itemList[i];
                if (itemList[i].item) {
                    currentItem = itemList[i].item;
                }
                // Tipologia di ogggetto
                let typology: string = null;

                if (currentItem.itemType && (currentItem.itemType == ItemTypes.SCORM_FREE || currentItem.itemType == ItemTypes.SCORM_INVITE)) {
                    currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.ELEARNING');
                    currentItem.typology = currentItem.itemType;
                }

                if (!currentItem.typeTranslation && currentItem.itemAttributes && currentItem.itemAttributes.length) {
                    for (let k = 0; k < currentItem.itemAttributes.length; k++) {
                        const currentAttribute = currentItem.itemAttributes[k];
                        if (currentAttribute.attributeType == ItemAttributeTypes.OBJECT_TYPE) {
                            typology = currentAttribute.attributeValue;
                            break;
                        }
                    }

                    if (typology) {
                        currentItem.typology = typology;
                        if (typology == ItemAttributeObjectTypes.PODCAST) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.PODCAST');
                        } else if (typology == ItemAttributeObjectTypes.GRAPH) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.GRAPH');
                        } else if (typology == ItemAttributeObjectTypes.DOCUMENT) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.DOCUMENT');
                        } else if (typology == ItemAttributeObjectTypes.EBOOK) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.EBOOK');
                        } else if (typology == ItemAttributeObjectTypes.VIDEO) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.VIDEO');
                        } else if (typology == ItemAttributeObjectTypes.IMAGE) {
                            currentItem.typeTranslation = this.translate.instant('awWizard.materials.itemTypes.IMAGE');
                        }
                    }
                }
            }
        }
    }

    // Recupera il count degli item
    countItems(searchedText: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-items', {
            params: httpParams
        });
    }

    // Servizio che recupera il nome di un item (per i taker)
    getItemTitleByIdForPublic(userId: string, itemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<string>>(this._cm2MediatorUrl + 'get-item-title-by-id-for-public', {
            params: httpParams
        });
    }

    userSelfConfirm(userId: string, editionItemId: string, sendEmail?: boolean, usedTakes?: number, coursePrivacyAccepted?: boolean, additionalEnrollAttributes?: any[]) {
        return this.http.post<SenecaResponse<boolean>>(this._cm2MediatorUrl + 'user-self-confirm', {
            userId,
            editionItemId,
            sendEmail,
            usedTakes,
            coursePrivacyAccepted,
            additionalEnrollAttributes
        });
    }

    updateSurvey(userId: string, survey: Survey, closeSurvey: boolean, itemId: string) {
        return this.http.post<SenecaResponse<ExtendedSurvey>>(this._cm2MediatorUrl + 'update-survey', {
            userId,
            survey,
            closeSurvey,
            itemId
        });
    }

    userSelfCancel(userId: string, editionItemId: string, statusNote: string, sendEmail?: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'user-self-cancel', {
            userId,
            editionItemId,
            sendEmail,
            statusNote
        });
    }

    // Servizio che recupera un Item dato il suo id
    getItemForGlp(itemId: string, createEngagement?: boolean, ignoreDeadlinePassedError?: boolean, params: {
        ignoreTenant?: boolean
    } = {}) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = httpParams.append('createEngagement', createEngagement ? 'true' : 'false');
        httpParams = httpParams.append('ignoreDeadlinePassedError', ignoreDeadlinePassedError ? 'true' : 'false');
        httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_TRAINING_PLATFORM);
        httpParams = httpParams.append('referenceApplicationName', CommonConstants.APPLICATION_TRAINING_PLATFORM);
        httpParams = httpParams.append('ignoreTenant', `${Boolean(params.ignoreTenant)}`);

        return this.http.get<SenecaResponse<GlpItem>>(this._cm2MediatorUrl + 'glp-get-item-or-object-detail-by-id', {
            params: httpParams
        });
    }

    // Recupera una lista di rilevazioni di un'edizione specifica
    getEditionAssessmentsForPublic(userId?: string, editionItemId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('editionItemId', editionItemId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-edition-assessments-for-public', {
            params: httpParams
        });
    }

    // Recupera una lista di rilevazioni di un'edizione specifica
    glpConfirmOfcoursemeCourseCompletion(courseId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('courseId', courseId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-confirm-ofcourseme-course-completion', {
            params: httpParams
        });
    }

    // Recupera una lista di rilevazioni di un'edizione specifica
    glpConfirmOfcoursemeCourseOpenAfterOpenIt(courseId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('courseId', courseId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-track-ofcourseme-course-opening', {
            params: httpParams
        });
    }

    // Imposta le notifiche come lette
    markNotificationAsReadByReference(referenceId: string, referenceType: string, referenceApplicationName: string): any {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'mark-notification-as-read-by-reference', {
            referenceApplicationName,
            referenceType,
            referenceId
        });
    }

    // Servizio che recupera un Item dato il suo id
    getConsumableItemByIdForUser(translate, itemId: string, withChildsLoaded?: boolean, childsTreeDepth?: number, attributeTypesToRetrieve?: string[], createEngagement?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        if (childsTreeDepth) {
            httpParams = httpParams.append('childsTreeDepth', childsTreeDepth.toString());
        }
        httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
        httpParams = httpParams.append('createEngagement', createEngagement ? 'true' : 'false');
        httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_TRAINING_PLATFORM);
        httpParams = httpParams.append('referenceApplicationName', CommonConstants.APPLICATION_TRAINING_PLATFORM);
        if (attributeTypesToRetrieve && attributeTypesToRetrieve.length) {
            attributeTypesToRetrieve && attributeTypesToRetrieve.forEach(type => {
                httpParams = httpParams.append('attributeTypesToRetrieve', type);
            });
        }

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-consumable-item-by-id-for-user', {
            params: httpParams
        })
            .pipe(
                take(1),
                // delay(1000), per test
                map((senecaResponse) => {
                    if (senecaResponse && senecaResponse.error) {
                        throw senecaResponse.error;
                    } else {
                        const item = senecaResponse.response || {} as ConsumableItem;

                        return this.mapConsumableItemToIDataItem(item, translate);
                    }
                })
            );
    }

    mapConsumableItemToIDataItem(item: ConsumableItem, translate?) {
        if (item) {
            const itemAttributes = item.itemAttributes;
            const itemTenants = item.itemTenants;
            const certificationDate = item.certificationDate;
            const scormRegistration = item.scormRegistration;
            const stars = ItemUtil.getStars(item) || null;
            const wishlistEnabled = ItemUtil.isWishlistEnabled(item);
            const isCourseCertificationEnabled = ItemUtil.isCourseCertificationEnabled(item);
            const itemTypeLabel = ItemUtil.getItemTypeLabel(item, translate) || null;
            const cardCover = item.backgroundImgUrl || ItemUtil.getCardCover(item) || './assets/img/learningPlan_bg.png';
            const backgroundImgUrl = item.backgroundImgUrl;
            const hourValue = ItemUtil.getItemHourValue(item, translate) || null;
            const cardTypeLabel = ItemUtil.getItemCardType(item, translate) || null;
            const isAtomicItem = ItemUtil.isAtomicItem(item);
            const isVideo = ItemUtil.isVideo(item);
            const isImage = ItemUtil.isImage(item);
            const isProject = ItemUtil.isProject(item);
            const isItemOtherOrPhysicalType = ItemUtil.isPhysicalItem(item) || ItemUtil.isOtherTypeItem(item);
            const isLearningPlan = ItemUtil.isLearningPlan(item);
            const isContainerItem = ItemUtil.isContainerItem(item);
            const subtype = ItemUtil.getItemObjectSubType(item);
            const isSyllabusCourse = ItemUtil.isSyllabusCourse(item);
            const isScorm = ItemUtil.isScormItem(item);
            let completionPercentage = item.completionPercentage || '0';
            completionPercentage = completionPercentage + '%';

            const _IDataItem = {
                itemId: item.itemId,
                itemType: item.itemType,
                mimeType: item.mimeType,
                itemRegistration: item.itemRegistration,
                isCourseCertificationEnabled,
                certificationDate,
                damObjectUrl: item.damObjectUrl,
                extendedItem: item.extendedItem,
                itemAttributes,
                name: item.title && item.title.trim(),
                subTitle: item.subTitle && item.subTitle.trim(),
                description: item.description && item.description.trim(),
                imgBackground: (item as any).backgroundImgUrl || null,
                azureImage: item && item.mimeType && item.mimeType.includes('image') ? item.damObjectUrl : null,
                itemTypeLabel,
                subtype,
                isProject,
                itemLangs: item.itemLangs || [],
                isLearningPlan,
                isItemOtherOrPhysicalType,
                isVideo,
                isImage,
                scormRegistration,
                cardCover,
                hourValue,
                cardTypeLabel,
                completionPercentage,
                isCertified: item.isCertified,
                isConsumed: item.isConsumed,
                isAtomicItem,
                isContainerItem,
                isSyllabusCourse,
                isScorm,
                itemTenants,
                itemChilds: item.itemChilds,
                childrenCount: item.childrenCount || 0,
                wishlistEnabled,
                isBookmarked: item.isBookmarked,
                stars
            } as IDataItem;
            let children: ItemChild[] = null;
            if (_IDataItem.itemChilds && _IDataItem.itemChilds) {
                children = _IDataItem.itemChilds.filter(ic => !!ic.childObject && ic.childObject.itemAttributes && ic.childObject.itemAttributes.length) || [];
            }
            // Se sono presenti dei figli ed è esplicitamente richiesto, carico ricorsivamente anche gli oggetti referenziati dai loro attributi
            if (children && children.length) {
                children.map((child) => {
                    child.childObject = this.mapConsumableItemToIDataItem(child.childObject as any, translate) as any;
                });
            }
            return _IDataItem;
        }
        return null;
    }

    // Servizio che recupera un Item dato il suo id
    getDraft(itemId: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, withRegistration?: boolean, createEngagement?: boolean, withAttributesLoaded?: boolean, withAllVisibiltiesLoaded?: boolean, loadInvitedInstead?: boolean, skipVisibilitiesHandling?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()) || '');
        httpParams = httpParams.append('withChildsLoaded', (withChildsLoaded && withChildsLoaded.toString()) || '');
        httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
        httpParams = httpParams.append('createEngagement', (createEngagement && createEngagement.toString()) || '');
        httpParams = httpParams.append('withAllVisibiltiesLoaded', (withAllVisibiltiesLoaded && withAllVisibiltiesLoaded.toString()) || '');
        httpParams = httpParams.append('withAttributesLoaded', (withAttributesLoaded && withAttributesLoaded.toString()) || '');
        httpParams = httpParams.append('loadInvitedInstead', (loadInvitedInstead && loadInvitedInstead.toString()) || '');
        httpParams = skipVisibilitiesHandling ? httpParams.append('skipVisibilitiesHandling', 'true') : httpParams;
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-draft-by-id/' + itemId, {
            params: httpParams
        });
    }

    // Recupera il counter totale persone a cui suggerire l'oggetto
    glpCountPersonsToSuggestItem(itemId: string, searchedText?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('textFilter', searchedText);
        }
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'glp-count-users-which-can-suggest-item', {
            params: httpParams
        });
    }

    // Recupera una lista di persone a cui suggerire l'oggetto
    glpGetPersonsToSuggestItem(itemId: string, searchedText?: string, pageNum?: number, numRecords?: number) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);

        const from = pageNum;
        const to = numRecords;
        httpParams = httpParams.append('pageNum', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());

        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('textFilter', searchedText);
        }

        return this.http.get<SenecaResponse<User[]>>(this._cm2MediatorUrl + 'glp-list-users-which-can-suggest-item', {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id
    getItemTitle(itemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<string>>(this._cm2MediatorUrl + 'get-item-title-by-id/' + itemId, {
            params: httpParams
        });
    }

    // Servizio che recupera il certificato di un item
    getCertificate(itemId: string, asImage?: boolean, getFullPath?: boolean, isPreview?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = asImage ? httpParams.append('asImage', 'true') : httpParams;
        httpParams = httpParams.append('isPreview', (isPreview && isPreview.toString()) || '');
        httpParams = getFullPath ? httpParams.append('getFullPath', 'true') : httpParams;
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-certificate', {
            params: httpParams
        });
    }

    // Servizio che recupera il certificato di un item
    downloadCertificateItem(itemId: string, fullPath?: boolean, fromAdmin?: boolean, containerId?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = httpParams.append('containerId', containerId || '');
        httpParams = fullPath ? httpParams.append('fullPath', 'true') : httpParams;
        httpParams = fromAdmin ? httpParams.append('fromAdmin', 'true') : httpParams;
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-library-certificate', {
            params: httpParams
        });
    }

    // Servizio che recupera la lista di persone suggeritrici
    getItemPrompterList(itemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-item-prompter-list', {
            params: httpParams
        });
    }

    // Servizio che recupera i like di un item
    getLikesCountsByTypesAndReference(referenceId: string, referenceType: string, likeType: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId);
        httpParams = httpParams.append('referenceType', referenceType);
        httpParams = httpParams.append('likeType', likeType);
        /* httpParams = httpParams.append('originApplicationName', originApplicationName);
        httpParams = httpParams.append('referenceApplicationName', referenceApplicationName); */
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-likes-counts-by-types-and-reference', {
            params: httpParams
        });
    }

    // Elimina un like
    deleteLike(likeId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('likeId', likeId || '');
        return this.http.delete<SenecaResponse<null>>(this._cm2MediatorUrl + 'delete-like-by-id/' + likeId, {
            params: httpParams
        });
    }

    // Servizio che recupera i like dell'utente loggato
    getMyLikeByTypeAndReference(referenceId: string, referenceType: string, likeType: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId);
        httpParams = httpParams.append('referenceType', referenceType);
        httpParams = httpParams.append('likeType', likeType);
        /* httpParams = httpParams.append('originApplicationName', originApplicationName);
        httpParams = httpParams.append('referenceApplicationName', referenceApplicationName); */
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-my-like-by-type-and-reference', {
            params: httpParams
        });
    }

    // Servizio che recupera i tenant dell'utente loggato
    getTenantsByIds(tenantIds: string[]) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (tenantIds && tenantIds.length) {
            httpParams = httpParams.append('tenantIds', tenantIds && typeof tenantIds == 'string' ? tenantIds : JSON.stringify(tenantIds));
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-tenants-by-ids', {
            params: httpParams
        });
    }

    // Aggiunta una visibilità (suggerimento) ad un utente
    addSuggestion(destinationUser: any, itemId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'add-suggestion', {
            destinationUser,
            itemId
        });
    }

    // Aggiunta una visibilità (suggerimento) ad un utente per gli item Library
    glpAddItemSuggestion(destinationUser: any, itemId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-add-item-suggestion', {
            destinationUser,
            itemId
        });
    }

    // Aggiunta una visibilità (suggerimento) ad un utente per un corso del cm2
    glpAddCourseSuggestion(destinationUser: any, itemId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-add-course-suggestion', {
            destinationUser,
            itemId
        });
    }

    // Recupera il link per scaricare un allegato
    getAttachmentUrl(attachmentId: string, adminMode: boolean, item: Item, forcedItemIdEngagement: string, forceCreateConsumedEngagement: boolean, isItemOtherType: boolean, itemId?: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-attachment-url/' + attachmentId, {
            attachmentId,
            adminMode,
            item,
            itemId,
            originApplicationName: CommonConstants.APPLICATION_TRAINING_PLATFORM,
            forcedItemIdEngagement,
            forceCreateConsumedEngagement,
            isItemOtherType
        });
    }

    searchAllAvailableItems(params, translate) {
        const searchedText = params && params.searchedText && params.searchedText.trim() || null;
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', params.itemId || '');
        httpParams = httpParams.append('status', params.status || 'A');
        httpParams = !!params.sorting ? httpParams.append('sorting', params.sorting) : httpParams;
        httpParams = params.openedFromDate ? httpParams.append('openedFromDate', params.openedFromDate) : httpParams;
        httpParams = params.openedToDate ? httpParams.append('openedToDate', params.openedToDate) : httpParams;
        httpParams = params.itemsPoolAttributeFilters ? httpParams.append('itemsPoolAttributeFilters', JSON.stringify([params.itemsPoolAttributeFilters])) : httpParams;
        httpParams = !!searchedText && httpParams.append('searchedText', searchedText) || httpParams;
        httpParams = httpParams.append('fromRecord', `${params.fromRecord || 0}`);
        httpParams = httpParams.append('numRecords', `${params.numRecords || 20}`);
        httpParams = params.withCompletionPercentage ? httpParams.append('withCompletionPercentage', 'true') : httpParams;
        httpParams = params.withChildrenCount ? httpParams.append('withChildrenCount', 'true') : httpParams;
        httpParams = params.suggestedItems ? httpParams.append('suggestedItems', 'true') : httpParams;
        httpParams = params.invited ? httpParams.append('invited', 'true') : httpParams;
        httpParams = params.certified ? httpParams.append('certified', 'true') : httpParams;
        httpParams = httpParams.append('withChildsLoaded', params && params.withChildsLoaded ? 'true' : 'false');
        httpParams = httpParams.append('myOnly', params && params.myOnly ? 'true' : 'false');
        httpParams = httpParams.append('useRentService', params && params.useRentService ? 'true' : 'false');
        httpParams = httpParams.append('useGetMyActivitiesItems', params && params.useGetMyActivitiesItems ? 'true' : 'false');
        httpParams = httpParams.append('onlyForMe', params && params.onlyForMe ? 'true' : 'false');
        httpParams = httpParams.append('openedOnly', params && params.openedOnly ? 'true' : 'false');
        httpParams = httpParams.append('allData', params && params.allData ? 'true' : 'false');
        httpParams = httpParams.append('withStartDate', params && params.withStartDate ? 'true' : 'false');

        httpParams = httpParams.append('useListItemsByAny', params && params.useListItemsByAny ? 'true' : 'false');
        httpParams = httpParams.append('suggested', params && params.suggested ? 'true' : 'false');

        params && params.objectTypeSpecializations && params.objectTypeSpecializations.forEach(type => {
            httpParams = httpParams.append('objectTypeSpecializations', type);
        });

        params && params.originApplicationNames && params.originApplicationNames.forEach(type => {
            httpParams = httpParams.append('originApplicationName', type);
        });

        params && params.attributeTypes && params.attributeTypes.forEach(type => {
            httpParams = httpParams.append('attributeTypes', type);
        });
        params && params.attributeRefTypes && params.attributeRefTypes.forEach(type => {
            httpParams = httpParams.append('attributeRefTypes', type);
        });
        params && params.attributeRefIds && params.attributeRefIds.forEach(type => {
            httpParams = httpParams.append('attributeRefIds', type);
        });

        httpParams = params.referenceApplicationName && httpParams.append('referenceApplicationNames', params.referenceApplicationName) || httpParams;

        params && params.itemIds && params.itemIds.forEach(type => {
            httpParams = httpParams.append('itemIds', type);
        });

        const itemTypes = params && params.itemTypes || SEARCHABLE_ITEM_TYPES;

        itemTypes.forEach(type => {
            httpParams = httpParams.append('itemTypes', type);
        });

        httpParams = params && params.suggestedItems ? httpParams.append('suggestedItems', `true`) : httpParams;

        return this.http.get<SenecaResponse<ConsumableItem[]>>(this._cm2MediatorUrl + 'search-all-available-items', {
            params: httpParams
        })
            .pipe(
                take(1),
                map((senecaResponse) => {
                    if (senecaResponse && senecaResponse.error) {
                        throw senecaResponse.error;
                    } else {
                        const items = senecaResponse.response || [];
                        const mappedItems = [];
                        for (let i = 0, itemsLength = items.length; i < itemsLength; i++) {
                            const currentItem = items[i];
                            mappedItems.push(this.mapConsumableItemToIDataItem(currentItem, translate));
                        }
                        return mappedItems;
                    }
                })
            );
    }


    // Toggle della wishlist
    toggleCourseInWishlist(courseId: string): any {
        // Preparo i parametri per la richiesta http
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'toggle-course-in-wishlist', {
            courseId
        });
    }

    // Recupera i learning plan dell'utente loggato
    getAllMyLearningPlans(title: string, fromRecord: number, numRecords: number): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        const from = fromRecord;
        const to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('searchedText', title || '');
        httpParams = httpParams.append('status', 'A');
        httpParams = httpParams.append('sorting', 'S');
        httpParams = httpParams.append('myOnly', 'true');
        httpParams = httpParams.append('objectTypeSpecializations', ItemAttributeObjectTypes.LEARNING_PLAN);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-search-library-items/' + fromRecord + '/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera il count dei learning plan dell'utente loggato
    countAllMyLearningPlans(title: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('searchedText', title || '');
        httpParams = httpParams.append('status', 'A');
        httpParams = httpParams.append('myOnly', 'true');
        httpParams = httpParams.append('objectTypeSpecializations', ItemAttributeObjectTypes.LEARNING_PLAN);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-count-library-items', {
            params: httpParams
        });
    }

    getSuggestedTags(numRecords): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'suggest-tags/' + numRecords, {
            params: httpParams
        });
    }

    // Recupera una lista di item
    suggestedItems(numRecords: any, withRegistration?: boolean, techSkillsOnly?: boolean, softSkillsOnly?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        const to = numRecords;
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('fromRecord', '0');
        httpParams = httpParams.append('withRegistration', (withRegistration && withRegistration.toString()) || '');
        httpParams = techSkillsOnly ? httpParams.append('techSkillsOnly', 'true') : httpParams;
        httpParams = softSkillsOnly ? httpParams.append('softSkillsOnly', 'true') : httpParams;
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-suggested-items', {
            params: httpParams
        });
    }

    // Recupera una lista di corsi dell'utente
    getCoursesListByUserId(fromRecord: any, numRecords: any, fromDate?, toDate?, courseUserStatuses?: string | string[], courseType?: string, allData?: boolean, past?: boolean, future?: boolean, description?: string, sort?: string, desc?: boolean, asConsumableItem?: boolean, withUserCatalogActions?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('startDate', (fromDate && fromDate.toString()) || '');
        httpParams = httpParams.append('endDate', (toDate && toDate.toString()) || '');
        httpParams = httpParams.append('courseType', courseType || '');
        httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
        httpParams = httpParams.append('sort', (sort && sort.toString()) || '');
        httpParams = httpParams.append('description', (description && description.toString()) || '');
        if (past) {
            httpParams = httpParams.append('past', (past && past.toString()) || '');
        }
        if (future) {
            httpParams = httpParams.append('future', (future && future.toString()) || '');
        }
        if (asConsumableItem) {
            httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
        }
        if (withUserCatalogActions) {
            httpParams = httpParams.append('withUserCatalogActions', (withUserCatalogActions && withUserCatalogActions.toString()) || '');
        }
        httpParams = httpParams.append('desc', desc && desc.toString() || '');
        if (courseUserStatuses && courseUserStatuses.length) {
            httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-courses-list-by-userId', {
            params: httpParams
        });
    }

    // Recupera una lista di corsi in vetrina
    getFeaturedCourseDates(fromRecord: any, numRecords: any): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-featured-course-dates', {
            params: httpParams
        });
    }

    // Recupera il counter totale persone a cui suggerire l'oggetto
    countPersonsToSuggestItem(itemId: string, searchedText?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        httpParams = httpParams.append('onlyConfirmed', 'true');
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'count-persons-in-tenant', {
            params: httpParams
        });
    }

    // Recupera una lista di persone a cui suggerire l'oggetto
    getPersonsToSuggestItem(itemId: string, searchedText?: string, fromRecord?: number, numRecords?: number) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);

        const from = fromRecord;
        const to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());

        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        httpParams = httpParams.append('onlyConfirmed', 'true');

        return this.http.get<SenecaResponse<User[]>>(this._cm2MediatorUrl + 'get-persons-in-tenant/' + from + '/' + to, {
            params: httpParams
        });
    }

    getSupplierPersonAttachment(uploadId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadId', uploadId);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-upload-by-id', {
            params: httpParams
        });
    }

    // Recupera il count lista di corsi dell'utente
    countCourseListByUser(startDate?, endDate?, courseUserStatuses?: string | string[], courseType?: string, past?: boolean, future?: boolean, description?: string, asConsumableItem?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('startDate', (startDate && startDate.toString()) || '');
        httpParams = httpParams.append('endDate', (endDate && endDate.toString()) || '');
        httpParams = httpParams.append('courseType', courseType || '');
        httpParams = httpParams.append('description', (description && description.toString()) || '');
        if (past) {
            httpParams = httpParams.append('past', (past && past.toString()) || '');
        }
        if (future) {
            httpParams = httpParams.append('future', (future && future.toString()) || '');
        }
        if (asConsumableItem) {
            httpParams = httpParams.append('asConsumableItem', (asConsumableItem && asConsumableItem.toString()) || '');
        }
        if (courseUserStatuses && courseUserStatuses.length) {
            httpParams = httpParams.append('courseUserStatuses', courseUserStatuses && typeof courseUserStatuses == 'string' ? courseUserStatuses : JSON.stringify(courseUserStatuses));
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-course-list-by-user', {
            params: httpParams
        });
    }

    getSuggestedCatalogCourses(params) {
        const itemId = params.itemId || '';
        const fromRecord = params.fromRecord || 0;
        const numRecords = params.numRecords || 20;

        return this.http.get<SenecaResponse<any[]>>(this._cm2MediatorUrl + `get-suggested-catalog-courses/${itemId}/${fromRecord}/${numRecords}`, {
        });
    }

    // Crea un like
    createLike(like, referenceId: string, referenceType: string, originApplicationName: string, referenceApplicationName: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'create-like', {
            like,
            referenceId,
            referenceType,
            originApplicationName,
            referenceApplicationName
        });
    }

    // Aggiorna una bozza
    updateDraft(item: Item) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'update-draft', {
            item
        });
    }

    // Conferma una bozza
    confirmDraft(itemId: string, indexItem?: boolean, addPublicVisibility?: boolean, withChildsLoaded?: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'confirm-draft', {
            itemId,
            indexItem,
            originApplicationName: CommonConstants.APPLICATION_TRAINING_PLATFORM,
            addPublicVisibility,
            withChildsLoaded
        });
    }

    // Crea un learning plan [chiamato playlist]
    createLearningPlan(item: Item, adminMode: boolean, addPublicVisibility: boolean, coverUrl: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-create-learning-plan', {
            item,
            adminMode,
            addPublicVisibility,
            coverUrl
        });
    }

    // Crea un progetto
    createProject(item: Item, adminMode: boolean, addPublicVisibility: boolean, originApplicationName: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'create-project', {
            item,
            adminMode,
            addPublicVisibility,
            originApplicationName
        });
    }

    // Crea un learning plan
    createSurveyTemplate(surveyTemplates, questionsPerGroupsOfOriginalTemplates, newTemplateQuestionsPerPage, titles, pageTitles, groupTitles,
        referenceType, referenceId, originApplicationName, referenceApplicationName) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'create-survey-template-from-multiple-templates', {
            surveyTemplates,
            questionsPerGroupsOfOriginalTemplates,
            newTemplateQuestionsPerPage,
            titles,
            pageTitles,
            groupTitles,
            referenceType,
            referenceId,
            referenceApplicationName,
            originApplicationName
        });
    }

    // Servizio che recupera il dettaglio di un progetto
    getProjectById(itemId: string, ignoreVisibility?: boolean, withChildsLoaded?: boolean, withRegistration?: boolean, createEngagement?: boolean, withAttributesLoaded?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);

        httpParams = ignoreVisibility ? httpParams.append('ignoreVisibility', 'true') : httpParams;
        httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
        httpParams = withRegistration ? httpParams.append('withRegistration', 'true') : httpParams;
        httpParams = createEngagement ? httpParams.append('createEngagement', 'true') : httpParams;
        httpParams = withAttributesLoaded ? httpParams.append('withAttributesLoaded', 'true') : httpParams;
        httpParams = httpParams.append('skipVisibilitiesHandling', 'true');
        httpParams = httpParams.append('originApplicationName', CommonConstants.APPLICATION_TRAINING_PLATFORM);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-item-by-id/' + itemId, {
            params: httpParams
        });
    }

    // Recupera il contatore delle immagini delle card
    countCardCovers(title?: string, ignoreVisibility?: boolean, originApplicationName?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('originApplicationName', originApplicationName || '');
        httpParams = ignoreVisibility ? httpParams.append('ignoreVisibility', 'true') : httpParams;
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-card-covers', {
            params: httpParams
        });
    }

    // Crea un nuovo oggetto multimediale
    createMultimediaItem(uploadObj) {
        const formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('fileName', uploadObj.fileName || '');
        formData.append('itemType', uploadObj.itemType || '');
        formData.append('title', uploadObj.title || '');
        formData.append('fileType', uploadObj.fileType);
        formData.append('itemId', uploadObj.itemId || '');
        // formData.append('parentId', uploadObj.itemId || ''); da usare per i materiali dei corsi
        formData.append('objectType', uploadObj.objectType || '');
        formData.append('draftOnly', uploadObj.draftOnly);
        if (uploadObj.useCoreUpload) {
            formData.append('useCoreUpload', 'true');
        }
        formData.append('originApplicationName', uploadObj.originApplicationName || '');
        formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
        formData.append('adminMode', uploadObj.adminMode);
        const params = new HttpParams();
        const options = {
            params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'create-multimedia-item', formData, options);
        return this.http.request(req);
    }

    // Crea un nuovo oggetto multimediale
    createSurveyItem(uploadObj) {
        const formData = new FormData();
        formData.append('itemType', uploadObj.itemType || '');
        formData.append('title', uploadObj.title || '');
        formData.append('objectType', uploadObj.objectType || '');
        formData.append('draftOnly', uploadObj.draftOnly);
        formData.append('originApplicationName', uploadObj.originApplicationName || '');
        formData.append('adminMode', uploadObj.adminMode);
        const params = new HttpParams();
        const options = {
            params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'create-survey-item', formData, options);
        return this.http.request(req);
    }

    // Crea un nuovo allegato
    createAttachment(uploadObj) {
        const formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('entryId', uploadObj.entryId || '');
        formData.append('title', uploadObj.title || '');
        formData.append('itemId', uploadObj.itemId || '');
        formData.append('originApplicationName', uploadObj.originApplicationName || '');
        const params = new HttpParams();
        const options = {
            params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'create-attachment', formData, options);
        return this.http.request(req);
    }

    // Crea un nuovo oggetto fisico
    createRentableItem(uploadObj) {
        const formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('fileName', uploadObj.fileName || '');
        formData.append('fileType', uploadObj.fileType);
        formData.append('title', uploadObj.title || '');
        formData.append('objectType', uploadObj.objectType || '');
        formData.append('originApplicationName', uploadObj.originApplicationName);
        formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
        formData.append('adminMode', uploadObj.adminMode);
        const params = new HttpParams();
        const options = {
            params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'create-rentable-item', formData, options);
        return this.http.request(req);
    }

    // Crea un nuovo oggetto di tipo 'altro'
    createOtherItem(uploadObj) {
        const formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('fileName', uploadObj.fileName);
        formData.append('fileType', uploadObj.fileType);
        formData.append('title', uploadObj.title || '');
        formData.append('objectType', uploadObj.objectType || '');
        formData.append('originApplicationName', uploadObj.originApplicationName);
        formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
        formData.append('adminMode', uploadObj.adminMode);
        const params = new HttpParams();
        const options = {
            params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'create-other-item', formData, options);
        return this.http.request(req);
    }

    // Aggiunge un nuovo survey template
    uploadSurveyTemplate(uploadObj) {
        const formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('surveyType', uploadObj.surveyType);
        formData.append('referenceType', uploadObj.referenceType);
        formData.append('referenceId', uploadObj.referenceId);
        formData.append('originApplicationName', uploadObj.originApplicationName);
        formData.append('referenceApplicationName', uploadObj.referenceApplicationName);
        formData.append('useCustomGroups', uploadObj.useCustomGroups);
        const params = new HttpParams();
        const options = {
            params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'upload-survey-template', formData, options);
        return this.http.request(req);
    }

    // Esegue l'upload di uno scorm
    uploadScorm(uploadObj: any) {
        const formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('courseName', uploadObj.courseName);
        formData.append('fileName', uploadObj.courseName);
        formData.append('title', uploadObj.courseName);
        formData.append('objectType', uploadObj.objectType);
        formData.append('itemType', uploadObj.scormType);
        formData.append('scormType', uploadObj.scormType);
        formData.append('originApplicationName', uploadObj.originApplicationName);
        formData.append('addPublicVisibility', uploadObj.addPublicVisibility);
        formData.append('adminMode', uploadObj.adminMode);
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'create-multimedia-item', formData);
    }

    // Recupera immagini delle card
    getCardCovers(from, to, title?: string, sorting?: string, itemTypes?: string | string[], ignoreVisibility?: boolean, originApplicationName?: string, skipImageSize?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('sorting', sorting || '');
        httpParams = httpParams.append('originApplicationName', originApplicationName || '');
        httpParams = ignoreVisibility ? httpParams.append('ignoreVisibility', 'true') : httpParams;
        httpParams = skipImageSize ? httpParams.append('skipImageSize', 'true') : httpParams;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        if (itemTypes && itemTypes.length) {
            httpParams = httpParams.append('itemTypes', itemTypes && typeof itemTypes == 'string' ? itemTypes : JSON.stringify(itemTypes));
        }

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-card-covers/' + from + '/' + to, {
            params: httpParams
        });
    }

    // Cancella una visibilità
    removeVisibility(itemId: string, visibilityId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId || '');
        httpParams = httpParams.append('visibilityId', visibilityId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'remove-visibility/' + itemId + '/' + visibilityId, {
            params: httpParams
        });
    }

    // Servizio che importa una lista di persone di un Item come taker
    importPersonsAsTakers(cidOrEmailList: string[], userIds: string[], itemId: string, simulate: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'import-persons-as-takers', {
            cidOrEmailList,
            itemId,
            userIds,
            simulate
        });
    }

    // Servizio che importa una lista di gruppi di un Item
    importGroupsAsTakers(groupIds: string[], itemId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'import-groups-as-takers', {
            groupIds,
            itemId
        });
    }

    // Servizio che cancella i taker
    deleteTakers(takerIds: string[]) {
        return this.http.post<SenecaResponse<boolean>>(this._cm2MediatorUrl + 'delete-takers', {
            takerIds
        });
    }

    // Recupera una lista di taker
    listUserTakers(itemId: string, allData?: boolean, fromRecord?: number, numRecords?: string, searchedText?: string): any {
        const from = fromRecord;
        const to = numRecords;
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from && from.toString() || '');
        httpParams = httpParams.append('numRecords', to && to.toString() || '');
        httpParams = httpParams.append('allData', allData && allData.toString() || '');
        // httpParams = !!withAllStatusesLoaded ? httpParams.append('withAllStatusesLoaded', 'true') : httpParams;
        httpParams = httpParams.append('itemId', itemId || '');
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<SenecaResponse<TextTemplate[]>>(this._cm2MediatorUrl + 'list-takers/' + from + '/' + to, {
            params: httpParams
        });
    }

    // Servizio che verifica se un'edizione richiede il check sulla privacy
    isCoursePrivacyToAccept(userId: string, editionItemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('editionItemId', editionItemId);
        return this.http.get<SenecaResponse<boolean>>(this._cm2MediatorUrl + 'is-course-privacy-to-accept', {
            params: httpParams
        });
    }

    listUserMaterialsForParent(userId: string, fromRecord: number, numRecords: number, parentIds: string[], materialTypes: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('parentId', JSON.stringify(parentIds));
        httpParams = httpParams.append('materialType', JSON.stringify(materialTypes));
        return this.http.get<SenecaResponse<Item[]>>(this._cm2MediatorUrl + 'list-user-materials-for-parent', {
            params: httpParams
        });
    }

    countUserMaterialsForParent(userId: string, parentIds: string[], materialTypes: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('parentId', JSON.stringify(parentIds));
        httpParams = httpParams.append('materialType', JSON.stringify(materialTypes));
        return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'count-user-materials-for-parent', {
            params: httpParams
        });
    }

    // Recupera tutte le Survey dell'utente loggato
    getSurveys(userId: string, referenceId: string, itemId?: string, startIfNotStarted?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('itemId', itemId || '');
        httpParams = httpParams.append('startIfNotStarted', startIfNotStarted && startIfNotStarted.toString() || '');
        // Per ora è gestito solo nella glp il caso di questionario non ripetibile
        httpParams = httpParams.append('showResultOfNotPassedRepeteableSurvey', 'true');
        return this.http.get<SenecaResponse<Survey>>(this._cm2MediatorUrl + 'get-surveys/' + referenceId, {
            params: httpParams
        });
    }

    // Recupera tutte le Survey dell'utente loggato
    getFlatSurveyBySurveyId(userId: string, surveyId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('surveyId', surveyId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this._cm2MediatorUrl + 'get-flat-survey-by-surveyId/' + surveyId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id
    listUserTakersForCourse(data: CourseManagerItem.ListUserTakersForPublic) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', data.userId);
        httpParams = httpParams.append('editionItemIds', JSON.stringify(data.editionItemIds));
        httpParams = !!data.stageItemId ? httpParams.append('stageItemId', data.stageItemId) : httpParams;
        httpParams = httpParams.append('allData', '' + data.allData);
        return this.http.get<SenecaResponse<Array<EnrichedItemTaker>>>(this._cm2MediatorUrl + 'list-user-takers-for-public', {
            params: httpParams
        });
    }

    // Aggiunge una visibilità
    addVisibility(itemId: string, visibilityType: string, fromAdmin: boolean, publicItem: boolean, ignoreVisibility: boolean, originApplicationName?: string, forceMailAndNotification?: boolean, destination?: any) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'add-visibility', {
            itemId,
            visibilityType,
            originApplicationName,
            fromAdmin,
            publicItem,
            forceMailAndNotification,
            ignoreVisibility,
            destination
        });
    }

    // Aggiunge uno o più child ad un item
    addChilds(parentId: string, childsId: string[], isDraft: boolean, originApplicationName?: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'add-childs', {
            parentId,
            childsId,
            originApplicationName,
            isDraft
        });
    }

    // Crea una nuova sezione
    createSection(item: Item, originApplicationName?: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'create-section', {
            item,
            originApplicationName
        });
    }

    // Recupera tutte le Survey dell'utente loggato
    addSurveyRandomGroupQuestionsLimit(surveyId: string, groupsQuestionLimit: any) {
        return this.http.post<any>(this._cm2MediatorUrl + 'add-or-update-survey-random-group-questions-limit', {
            surveyId,
            groupsQuestionLimit
        });
    }

    deleteCourseItem(itemId: string, removeSyllabus?: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'delete-course-item/' + itemId, {
            itemId,
            removeSyllabus
        });
    }

    deleteLibraryItem(itemId: string, removeAtomicItemsToo?: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'delete-library-item/' + itemId, {
            itemId,
            removeAtomicItemsToo
        });
    }

    listItemAttributeByIdAndType(itemId: string, itemAttributeTypes: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = httpParams.append('itemAttributeType', itemAttributeTypes);
        httpParams = httpParams.append('allData', 'true');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'list-item-attributes', {
            params: httpParams
        });
    }

    getCourseEditions(itemId: string, withSurveyLoaded?: boolean): Observable<SenecaResponse<CourseCatalog | CourseDateCatalog[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('courseId', itemId);
        httpParams = httpParams.append('createEngagement', 'false');
        httpParams = httpParams.append('withSurveyLoaded', withSurveyLoaded ? 'true' : 'false');
        httpParams = httpParams.append('withBookmarkStatus', 'false');
        httpParams = httpParams.append('skipActions', 'false');
        httpParams = httpParams.append('getOnlyEditions', 'true');
        httpParams = httpParams.append('withCourseDaysLoaded', 'true');

        return this.http.get<SenecaResponse<CourseCatalog | CourseDateCatalog[]>>(this._cm2MediatorUrl + `glp-get-catalog-course-by-courseId/${itemId}`, {
            params: httpParams
        });
    }


    // Recupera il count dei materiali
    countMaterialsForParent(parentId: string, materialType: string, title?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('parentId', parentId || '');
        httpParams = httpParams.append('materialType', materialType || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-materials-for-parent', {
            params: httpParams
        });
    }

    // Recupera la lista di edizioni per cui c'è un certificato
    getEditionIdsWhereUserHasCertificateByStageIid(userId: string, stageId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('stageId', stageId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-edition-ids-where-user-has-certificate-by-stage-id', {
            params: httpParams
        });
    }

    getEditionIdsReleaseDateHasCertificateByStageIid(userId: string, stageId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('stageId', stageId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-edition-ids-and-release-date-where-user-has-certificate-by-stage-id', {
            params: httpParams
        });
    }

    // Recupera una lista di materiali
    listMaterialsForParent(fromRecord: number, numRecords: number, parentId: string, materialType: string, title?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        const from = fromRecord;
        const to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('parentId', parentId || '');
        httpParams = httpParams.append('materialType', materialType || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'list-materials-for-parent', {
            params: httpParams
        });
    }

    // Recupera una lista di item
    searchItems(searchedText: string, fromRecord: number, numRecords: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        const from = fromRecord;
        const to = numRecords;
        httpParams = httpParams.append('fromRecord', from && from.toString());
        httpParams = httpParams.append('numRecords', to && to.toString());
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'search-items', {
            params: httpParams
        });
    }

    // Recupera una lista di item
    listItemsByAny(searchedText?: string, fromRecord?: number, numRecords?: string, allData?: boolean, referenceId?: string[], referenceApplicationName?: string[], referenceType?: string[], originApplicationName?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '');
        httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
        httpParams = httpParams.append('allData', (allData && allData.toString()) || '');
        httpParams = httpParams.append('originApplicationName', (originApplicationName && originApplicationName.toString()) || '');
        if (referenceId && referenceId.length) {
            referenceId.forEach(referenceId => {
                httpParams = httpParams.append('referenceId', referenceId);
            });
        }
        if (referenceApplicationName && referenceApplicationName.length) {
            referenceApplicationName.forEach(referenceApplicationName => {
                httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
            });
        }
        if (referenceType && referenceType.length) {
            referenceType.forEach(referenceType => {
                httpParams = httpParams.append('referenceType', referenceType);
            });
        }
        httpParams = httpParams.append('searchedText', searchedText || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-list-items-by-any-for-training-passport', {
            params: httpParams
        });
    }

    // Recupera gli assessment di un item
    listAssessmentOfItemByReferenceId(referenceId: string[], referenceType: string[], referenceApplicationName?: string[], originApplicationName?: string, fromRecord?: number, numRecords?: number, allData?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();

        if (referenceId && referenceId.length) {
            referenceId.forEach(referenceId => {
                httpParams = httpParams.append('referenceId', referenceId);
            });
        }
        if (referenceType && referenceType.length) {
            referenceType.forEach(referenceType => {
                httpParams = httpParams.append('referenceType', referenceType);
            });
        }
        if (referenceApplicationName && referenceApplicationName.length) {
            referenceApplicationName.forEach(referenceApplicationName => {
                httpParams = httpParams.append('referenceApplicationName', referenceApplicationName);
            });
        }
        httpParams = httpParams.append('originApplicationName', (originApplicationName && originApplicationName.toString()) || '');
        httpParams = httpParams.append('fromRecord', (fromRecord && fromRecord.toString()) || '');
        httpParams = httpParams.append('numRecords', (numRecords && numRecords.toString()) || '');
        httpParams = httpParams.append('allData', (allData && allData.toString()) || '');

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'list-assessment-of-item-by-referenceId', {
            params: httpParams
        });
    }

    // Torna il percorso di una tappa (utilizato anche per identificare se un oggetto corso è in realtà una tappa, ovvero è contenuto in un percorso)
    getPathFromStage(stageItemId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId);
        return this.http.get<SenecaResponse<TextTemplate[]>>(this._cm2MediatorUrl + 'get-path-from-stage/' + stageItemId, {
            params: httpParams
        });
    }

    // Torna un percorso dato il suo Id
    getPathFromIdForAdmin(pathItemId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('pathItemId', pathItemId);
        return this.http.get<SenecaResponse<Item>>(this._cm2MediatorUrl + 'get-path-from-id-for-admin/' + pathItemId, {
            params: httpParams
        });
    }

    // Torna uno Stage dato il suo Id
    getStageFromIdForAdmin(stageItemId: string): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('stageItemId', stageItemId);
        httpParams = httpParams.append('withFullAttributesLoaded', 'true');
        httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
        httpParams = httpParams.append('itemAttributeTypesToResolve',
            JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER]));

        return this.http.get<SenecaResponse<TextTemplate[]>>(this._cm2MediatorUrl + 'get-stage-from-id-for-admin/' + stageItemId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id per supplier
    itemByIdForSupplier(itemId: string, withChildsLoaded?: boolean) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = withChildsLoaded ? httpParams.append('withChildsLoaded', 'true') : httpParams;
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-item-by-id-for-supplier/' + itemId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id
    deleteItem(itemId: string, removeSyllabus?: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-delete-item-for-training-passport', {
            itemId,
            removeSyllabus
        });
    }

    // Servizio che cancella un multimedia item
    deleteMultimediaItem(itemId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + `delete-multimedia-item/${itemId}`, {
            itemId
        });
    }

    // Servizio che recupera i corsi suggeriti
    getSuggestedCoursesList(fromRecord: number, numRecords: number, itemId?: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (itemId) {
            httpParams = httpParams.append('itemId', itemId);
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-suggested-items-by-application/', {
            params: httpParams
        });
    }

    // Servizio che recupera i corsi che potresti completare
    getUserStartedCoursesList(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-started-items-to-complete/', {
            params: httpParams
        });
    }

    // Recupera numero collezioni per progetti speciali
    getWebCoursesCollectionForSpecialProject(specialProjectId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('specialProjectId', specialProjectId);
        return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'count-collections-for-special-project/', {
            params: httpParams
        });
    }

    // Recupera i count per progetti speciali per admin
    countSpecialProjectForAdmin() {
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-special-projects-for-admin/', {
        });
    }

    // Recupera i count per progetti speciali per user
    countSpecialProjectForUser(specialProjectId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('specialProjectId', specialProjectId);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-special-projects-for-user/', {
            params: httpParams
        });
    }

    // Recupera i count per progetti speciali per user
    countSpecialProjectItemsAndCourses(specialProjectId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('specialProjectId', specialProjectId);
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'count-special-project-items-and-courses/', {
            params: httpParams
        });
    }

    // Servizio che recupera i corsi speciali Welion dall'id per Admin
    getSpecialProjectByIdForAdmin(itemId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-project-by-id-for-admin/', {
            params: httpParams
        });
    }

    // Servizio che recupera i corsi speciali Welion che potresti completare
    getSpecialProjectItemsAndCoursesAdmin(specialProjectId: string, fromRecord: number, numRecords: number, searchedText?: string, ignoreVisibility?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('specialProjectId', specialProjectId);
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        searchedText ? httpParams = httpParams.append('searchedText', searchedText) : httpParams = httpParams;
        if (ignoreVisibility) {
            httpParams = httpParams.append('ignoreVisibility', (ignoreVisibility && ignoreVisibility.toString()));
        }

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-project-items-and-courses-admin/', {
            params: httpParams
        });
    }

    // Servizio che recupera i corsi speciali Welion dall'id per Admin
    getSpecialProjectItemsAndCourses(specialProjectId: string, fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        httpParams = httpParams.append('specialProjectId', specialProjectId);

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-project-items-and-courses/', {
            params: httpParams
        });
    }

    // Servizio che recupera i corsi speciali Welion OfCourseMe per Admin
    getSpecialProjectOfCourseMeForAdmin(specialProjectId: string, fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('specialProjectId', specialProjectId);
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-project-ofcourseme-collections-for-admin/', {
            params: httpParams
        });
    }

    // Servizio che recupera la collezione dei corsi speciali Welion OfCourseMe per Admin
    getSpecialProjectOfCourseMeCollections(specialProjectId: string, fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('specialProjectId', specialProjectId);
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-project-ofcourseme-collections/', {
            params: httpParams
        });
    }

    // Servizio che recupera la collezione dei corsi speciali per Admin
    getSpecialProjectForAdmin(fromRecord: number, numRecords: number, allData?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (allData) {
            httpParams = httpParams.append('allData', (allData && allData.toString()));
        }

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-projects-for-admin/', {
            params: httpParams
        });
    }

    // Servizio che recupera la collezione dei corsi speciali per Admin
    getSpecialProjectForUser(fromRecord: number, numRecords: number, allData?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (allData) {
            httpParams = httpParams.append('allData', (allData && allData.toString()));
        }

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-special-projects-for-user/', {
            params: httpParams
        });
    }

    // Seleziona la collezione per progetti speciali
    selectCollectionForSpecialProject(collectionId: string, specialProjectId: string, order: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'select-collection-for-special-project/', {
            collectionId,
            specialProjectId,
            order
        });
    }

    // Rimuove la selezione della collezione per progetti speciali
    unselectCollectionForSpecialProject(collectionId: string, specialProjectId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'unselect-collection-for-special-project/', {
            collectionId,
            specialProjectId
        });
    }

    // Recupera le scadenze dell'utente
    getUserDeadlines(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-important-news-for-the-user/', {
            params: httpParams
        });
    }

    getUserLatestInitiativeItem(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-latest-initiatives-and-items-created-with-user-direct-visibility/', {
            params: httpParams
        });
    }

    // Servizio che recupera i corsi che ti hanno suggerito
    getFromSuggestedCoursesList(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        httpParams = httpParams.append('myDataOnly', 'true');

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-suggested-courses-and-items-between-users/', {
            params: httpParams
        });

    }

    // Scarica un certificato pubblico
    getPublicCertificate(userId: string, itemId: string) {
        let httpParams = new HttpParams();
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + `certificate/${userId}/${itemId}`, {
            params: httpParams
        });
    }

    // Recupera i corsi catalog quando l'IDP nel Learning System è attivo
    getCatalogCourseDates() {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', 'true');
        httpParams = httpParams.append('allIdpCoursesGroupedByCluster', 'true');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-learning-system-items-and-courses', {
            params: httpParams
        });
    }
    // Recupera i corsi catalog passandolgli il clusterId
    getCatalogCoursesByCluster(fromRecord: number, numRecords: number, clusterId: string, idpCourses?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        httpParams = httpParams.append('clusterId', clusterId);
        if (idpCourses) {
            httpParams = httpParams.append('idpCourses', (idpCourses && idpCourses.toString()));
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-catalog-courses-by-cluster/', {
            params: httpParams
        });
    }

    // Recupera i corsi catalog passandogli l'argumentId
    getCatalogCoursesByCategory(fromRecord: number, numRecords: number, clusterId: string, idpCourses?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        httpParams = httpParams.append('argumentId', clusterId);
        if (idpCourses) {
            httpParams = httpParams.append('idpCourses', (idpCourses && idpCourses.toString()));
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-catalog-courses-by-argument/', {
            params: httpParams
        });
    }

    // Recupera i corsi wishlist nel MyLearning
    getWishlistCourses(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-courses-wishlist/', {
            params: httpParams
        });
    }

    // Recupera i corsi prestiti nel MyLearning
    getLoansCourses(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-loans-of-the-user/', {
            params: httpParams
        });
    }

    // Recupera numero cordi di uno speciifico cluster
    getNumberCoursesCluster(clusterId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('clusterId', clusterId);
        return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'glp-count-catalog-courses-by-cluster/', {
            params: httpParams
        });
    }

    // Recupera numero cordi di uno specifico argument
    getNumberCoursesCategory(argumentId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('argumentId', argumentId);
        return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'glp-count-catalog-courses-by-argument/', {
            params: httpParams
        });
    }

    // Recupera i corsi sei stato invitato a del myLearning
    getYouAreInvitedCourses(fromRecord: number, numRecords: number, selectedFilter: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (selectedFilter) {
            httpParams = httpParams.append('sortBy', selectedFilter);
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-courses-where-user-has-been-invited/', {
            params: httpParams
        });
    }

    // Recupera i corsi playlist a del myLearning
    getPlaylistCourses(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-playlists/', {
            params: httpParams
        });
    }

    // Recupera i corsi preferiti a del myLearning
    getPreferredCourses(fromRecord: number, numRecords: number) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-bookmarked-courses/', {
            params: httpParams
        });
    }

    // Recupera i count dei corsi filtrati sei stato invitato a del myLearning
    countYouAreInvitedCoursesFiltered() {
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-count-courses-where-user-has-been-invited-divided-by-all-filters/', {
        });
    }

    // Fa l'upload dell'elemento nel dettaglio della cassetta degli attrezzi
    uploadAttachment(uploadObj: any) {
        const formData = new FormData();
        formData.append('addCustomPlayerId', uploadObj.addCustomPlayerId);
        formData.append('uploadSection', uploadObj.uploadSection);
        formData.append('uploadType', uploadObj.uploadType);
        formData.append('referenceType', uploadObj.referenceType);
        formData.append('referenceId', uploadObj.referenceId);
        formData.append('fromAdmin', uploadObj.fromAdmin);
        formData.append('name', uploadObj.name);
        formData.append('fileName', uploadObj.fileName);
        formData.append('token', uploadObj.token);
        formData.append('file', uploadObj.file.file.rawFile);
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-upload-attachment', formData);

    }

    // Condivide un elemento nel dettaglio della cassetta degli attrezzi
    addItemVisibilities(itemId: string, notifyShare: boolean, recipients: string[], customText: string, withAllVisibilitiesLoaded: boolean, loadInvitedInstead: boolean) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-add-item-visibilities/', {
            itemId,
            notifyShare,
            recipients,
            customText,
            withAllVisibilitiesLoaded,
            loadInvitedInstead,
            createEngagement: true
        });
    }

    // Elimina la condivisione di un elemento nel dettaglio della cassetta degli attrezzi
    deleteItemVisibilities(itemId: string, visibilityId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-remove-item-visibility/', {
            itemId,
            visibilityId
        });
    }

    // Recupera corsi Learning System per la parte a destra della tabella nel My Learning System oppure nella ricerca globale se cè il cerca ovunque
    getLearningSystemCoursesList(fromRecord: number, numRecords: number, useSearch?: boolean, searchedText?: string, status?: string, argumentsTags?: string[], languages?: string, techSkillsOnly?: boolean, softSkillsOnly?: boolean, functionalAreas?: string[], clusters?: string[], itemTypes?: string[], levels?: string[], sorting?: string, createEngagement?: boolean, ignoreobjectTypeSpecializations?: string[], techCompetences?: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (useSearch) {
            httpParams = httpParams.append('useSearch', useSearch && useSearch.toString());
        }
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        if (status) {
            httpParams = httpParams.append('status', status);
        }
        if (argumentsTags && argumentsTags.length) {
            if (Array.isArray(argumentsTags)) {
                argumentsTags.forEach(argumentsTag => {
                    httpParams = httpParams.append('argumentsTags', argumentsTag);
                });
            } else {
                httpParams = httpParams.append('argumentsTags', argumentsTags);
            }
        }
        if (languages && languages.length) {
            if (Array.isArray(languages)) {
                for (let i = 0; i < languages.length; i++) {
                    if (languages[i] == 'en') {
                        languages[i] = 'LANG_EN';
                    }
                    if (languages[i] == 'it') {
                        languages[i] = 'LANG_IT';
                    }
                }
                languages.forEach(language => {
                    httpParams = httpParams.append('langs', language);
                });
            } else {
                if (languages == 'en') {
                    languages = 'LANG_EN';
                } else if (languages == 'it') {
                    languages = 'LANG_IT';
                }
                httpParams = httpParams.append('langs', languages);
            }
        }
        if (functionalAreas && functionalAreas.length) {
            if (Array.isArray(functionalAreas)) {
                functionalAreas.forEach(functionalArea => {
                    httpParams = httpParams.append('functionalAreas', functionalArea);
                });
            } else {
                httpParams = httpParams.append('functionalAreas', functionalAreas);
            }
        }
        if (clusters && clusters.length) {
            if (Array.isArray(clusters)) {
                clusters.forEach(cluster => {
                    httpParams = httpParams.append('clusters', cluster);
                });
            } else {
                httpParams = httpParams.append('clusters', clusters);
            }
        }
        if (techSkillsOnly) {
            httpParams = httpParams.append('techSkillsOnly', techSkillsOnly.toString());
        }
        if (softSkillsOnly) {
            httpParams = httpParams.append('softSkillsOnly', softSkillsOnly.toString());
        }
        if (itemTypes && itemTypes.length) {
            if (Array.isArray(itemTypes)) {
                itemTypes.forEach(itemType => {
                    if (itemType != 'OF_COURSE_ME') {
                        httpParams = httpParams.append('objectTypeSpecializations', itemType);
                    }
                });
            } else {
                if (itemTypes != 'OF_COURSE_ME') {
                    httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
                }
            }
        }

        if (techCompetences && techCompetences.length) {
            if (Array.isArray(techCompetences)) {
                techCompetences.forEach(techCompetence => {
                    httpParams = httpParams.append('techCompetences', techCompetence);
                });
            } else {
                httpParams = httpParams.append('techCompetences', techCompetences);
            }
        }

        if (levels && levels.length) {
            if (Array.isArray(levels)) {
                levels.forEach(level => {
                    httpParams = httpParams.append('levels', level);
                });
            } else {
                httpParams = httpParams.append('levels', levels);
            }
        }

        if (sorting) {
            httpParams = httpParams.append('sorting', sorting);
        }
        if (createEngagement) {
            httpParams = httpParams.append('createEngagement', createEngagement.toString());
        }
        if (ignoreobjectTypeSpecializations && ignoreobjectTypeSpecializations.length) {
            if (Array.isArray(ignoreobjectTypeSpecializations)) {
                ignoreobjectTypeSpecializations.forEach(ignoreobjectTypeSpecialization => {
                    httpParams = httpParams.append('ignoreobjectTypeSpecializations', ignoreobjectTypeSpecialization);
                });
            } else {
                httpParams = httpParams.append('ignoreobjectTypeSpecializations', ignoreobjectTypeSpecializations);
            }
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-learning-system-items-and-courses/', {
            params: httpParams
        });
    }

    // Recupera i corsi aggiunti alla D&I
    getDiversityAndInclusionCourses(fromRecord: number, numRecords: number, itemTypes?: string[], useSearch?: boolean, searchedText?: string, status?: string, argumentsTags?: string[], languages?: string, techSkillsOnly?: boolean, softSkillsOnly?: boolean, functionalAreas?: string[], clusters?: string[], levels?: string[], sorting?: string, createEngagement?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (useSearch) {
            httpParams = httpParams.append('useSearch', useSearch && useSearch.toString());
        }
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        if (status) {
            httpParams = httpParams.append('status', status);
        }
        if (argumentsTags && argumentsTags.length) {
            if (Array.isArray(argumentsTags)) {
                argumentsTags.forEach(argumentsTag => {
                    httpParams = httpParams.append('argumentsTags', argumentsTag);
                });
            } else {
                httpParams = httpParams.append('argumentsTags', argumentsTags);
            }
        }
        if (languages && languages.length) {
            if (Array.isArray(languages)) {
                for (let i = 0; i < languages.length; i++) {
                    if (languages[i] == 'en') {
                        languages[i] = 'LANG_EN';
                    }
                    if (languages[i] == 'it') {
                        languages[i] = 'LANG_IT';
                    }
                }
                languages.forEach(language => {
                    httpParams = httpParams.append('langs', language);
                });
            } else {
                if (languages == 'en') {
                    languages = 'LANG_EN';
                } else if (languages == 'it') {
                    languages = 'LANG_IT';
                }
                httpParams = httpParams.append('langs', languages);
            }
        }
        if (functionalAreas && functionalAreas.length) {
            if (Array.isArray(functionalAreas)) {
                functionalAreas.forEach(functionalArea => {
                    httpParams = httpParams.append('functionalAreas', functionalArea);
                });
            } else {
                httpParams = httpParams.append('functionalAreas', functionalAreas);
            }
        }
        if (clusters && clusters.length) {
            if (Array.isArray(clusters)) {
                clusters.forEach(cluster => {
                    httpParams = httpParams.append('clusters', cluster);
                });
            } else {
                httpParams = httpParams.append('clusters', clusters);
            }
        }
        if (techSkillsOnly) {
            httpParams = httpParams.append('techSkillsOnly', techSkillsOnly.toString());
        }
        if (softSkillsOnly) {
            httpParams = httpParams.append('softSkillsOnly', softSkillsOnly.toString());
        }
        if (itemTypes && itemTypes.length) {
            if (Array.isArray(itemTypes)) {
                itemTypes.forEach(itemType => {
                    if (itemType != 'OF_COURSE_ME') {
                        httpParams = httpParams.append('objectTypeSpecializations', itemType);
                    }
                });
            } else {
                if (itemTypes != 'OF_COURSE_ME') {
                    httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
                }
            }
        }

        if (levels && levels.length) {
            if (Array.isArray(levels)) {
                levels.forEach(level => {
                    httpParams = httpParams.append('levels', level);
                });
            } else {
                httpParams = httpParams.append('levels', levels);
            }
        }

        if (sorting) {
            httpParams = httpParams.append('sorting', sorting);
        }
        if (createEngagement) {
            httpParams = httpParams.append('createEngagement', createEngagement.toString());
        }
        httpParams = httpParams.append('diversityAndInclusionOnly', 'true');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-learning-system-items-and-courses', {
            params: httpParams
        });
    }

    // Recupera corsi ricerca globale con il cerca in myLearning
    getMyLearningGlobalSearchCoursesList(fromRecord: number, numRecords: number, searchedText?: string, status?: string, argumentsTags?: string[], languages?: string, techSkillsOnly?: boolean, softSkillsOnly?: boolean, functionalAreas?: string[], clusters?: string[], itemTypes?: string[], levels?: string[], sorting?: string, createEngagement?: boolean, ignoreobjectTypeSpecializations?: string[], techCompetences?: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        if (status) {
            httpParams = httpParams.append('status', status.toString());
        }
        if (argumentsTags && argumentsTags.length) {
            if (Array.isArray(argumentsTags)) {
                argumentsTags.forEach(argumentsTag => {
                    httpParams = httpParams.append('argumentsTags', argumentsTag);
                });
            } else {
                httpParams = httpParams.append('argumentsTags', argumentsTags);
            }
        }
        if (languages && languages.length) {
            if (Array.isArray(languages)) {
                for (let i = 0; i < languages.length; i++) {
                    if (languages[i] == 'en') {
                        languages[i] = 'LANG_EN';
                    }
                    if (languages[i] == 'it') {
                        languages[i] = 'LANG_IT';
                    }
                }
                languages.forEach(language => {
                    httpParams = httpParams.append('langs', language);
                });
            } else {
                if (languages == 'en') {
                    languages = 'LANG_EN';
                } else if (languages == 'it') {
                    languages = 'LANG_IT';
                }
                httpParams = httpParams.append('langs', languages);
            }
        }
        if (functionalAreas && functionalAreas.length) {
            if (Array.isArray(functionalAreas)) {
                functionalAreas.forEach(functionalArea => {
                    httpParams = httpParams.append('functionalAreas', functionalArea);
                });
            } else {
                httpParams = httpParams.append('functionalAreas', functionalAreas);
            }
        }
        if (techCompetences && techCompetences.length) {
            if (Array.isArray(techCompetences)) {
                techCompetences.forEach(techCompetence => {
                    httpParams = httpParams.append('techCompetences', techCompetence);
                });
            } else {
                httpParams = httpParams.append('techCompetences', techCompetences);
            }
        }
        if (clusters && clusters.length) {
            if (Array.isArray(clusters)) {
                clusters.forEach(cluster => {
                    httpParams = httpParams.append('clusters', cluster);
                });
            } else {
                httpParams = httpParams.append('clusters', clusters);
            }
        }
        if (techSkillsOnly) {
            httpParams = httpParams.append('techSkillsOnly', techSkillsOnly.toString());
        }
        if (softSkillsOnly) {
            httpParams = httpParams.append('softSkillsOnly', softSkillsOnly.toString());
        }
        if (itemTypes && itemTypes.length) {
            if (Array.isArray(itemTypes)) {
                itemTypes.forEach(itemType => {
                    if (itemType != 'OF_COURSE_ME') {
                        httpParams = httpParams.append('objectTypeSpecializations', itemType);
                    }
                });
            } else {
                if (itemTypes != 'OF_COURSE_ME') {
                    httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
                }
            }
        }

        if (levels && levels.length) {
            if (Array.isArray(levels)) {
                levels.forEach(level => {
                    httpParams = httpParams.append('levels', level);
                });
            } else {
                httpParams = httpParams.append('levels', levels);
            }
        }

        if (sorting) {
            httpParams = httpParams.append('sorting', sorting);
        }
        if (createEngagement) {
            httpParams = httpParams.append('createEngagement', createEngagement.toString());
        }
        if (ignoreobjectTypeSpecializations && ignoreobjectTypeSpecializations.length) {
            if (Array.isArray(ignoreobjectTypeSpecializations)) {
                ignoreobjectTypeSpecializations.forEach(ignoreobjectTypeSpecialization => {
                    httpParams = httpParams.append('ignoreobjectTypeSpecializations', ignoreobjectTypeSpecialization);
                });
            } else {
                httpParams = httpParams.append('ignoreobjectTypeSpecializations', ignoreobjectTypeSpecializations);
            }
        }

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-search-items-in-my-learning/', {
            params: httpParams
        });
    }

    // Recupera collezione dal web per la Home
    getOfCourseMeCollections(fromRecord: number, numRecords: number, retrieveBookmark?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (retrieveBookmark) {
            httpParams = httpParams.append('retrieveBookmark', "true");
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-ofcourseme-collections/', {
            params: httpParams
        });
    }

    // Recupera corsi dal web per il MyLearning e cerca globale
    getOfCourseMeCourses(fromRecord: number, numRecords: number, searchedText?: string, langs?: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords.toString());
        if (searchedText) {
            httpParams = httpParams.append('searchedText', searchedText.toString());
        }
        if (langs && langs.length) {
            if (Array.isArray(langs)) {
                for (let i = 0; i < langs.length; i++) {
                    if (langs[i] == 'LANG_EN') {
                        langs[i] = 'en';
                    }
                    if (langs[i] == 'LANG_IT') {
                        langs[i] = 'it';
                    }
                }
                langs.forEach(lang => {
                    httpParams = httpParams.append('langCode', lang);
                });
            } else {
                if (langs == 'LANG_EN') {
                    langs = 'en';
                } else if (langs == 'LANG_IT') {
                    langs = 'it';
                }
                httpParams = httpParams.append('langCode', langs);
            }
        }
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-ofcourseme-courses/', {
            params: httpParams
        });
    }

    // Scarica lo storico nel passaporto formativo
    exportMyAttendedCourses() {
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-export-my-attended-courses/', {

        });
    }

    // Scarica catalogo
    exportCatalogReport(idpCourses: boolean) {
        let httpParams = new HttpParams();
        if (idpCourses) {
            httpParams = httpParams.append('idpCourses', (idpCourses && idpCourses.toString()));
        }
        httpParams = httpParams.append('getFullPath', 'false');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-catalog-report/', {
            params: httpParams
        });
    }

    // Scarica il report persone nella dashboard
    exportUsersReport() {
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'export-excel-ofcourseme-courses/', {
        });
    }

    // Se l'utente loggato può vedere le card app dei vari calendari
    isNetworkDashboardVisible() {
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-is-network-dashboard-visible/', {
        });
    }

    glpGetUserDefTpDipInfo() {
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-defTpDip-info', {
        });
    }


    getDeliberationUploadUrl(userId: string, uploadId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('uploadId', uploadId);

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-deliberation-upload-url-for-course', {
            params: httpParams
        });
    }

    // Track del download del certificato
    trackDownloadCertificate(editionId: string) {
        return this.http.post<SenecaResponse<boolean>>(this._cm2MediatorUrl + 'track-course-certificate-download', {
            editionId
        });
    }

    // Track dello share del certificato
    trackShareCertificate(editionId: string) {
        return this.http.post<SenecaResponse<boolean>>(this._cm2MediatorUrl + 'track-course-certificate-sharing', {
            editionId
        });
    }

    getItemCollectionForGlp(itemId: string, createEngagement?: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('itemId', itemId);
        httpParams = httpParams.append('createEngagement', 'true');

        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-my-collection-details-by-id', {
            params: httpParams
        });
    }
}
