import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, Event } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-page-views-counter',
  templateUrl: './page-views-counter.component.html',
  styleUrls: ['./page-views-counter.component.scss']
})
export class PageViewsCounterComponent implements OnInit, OnDestroy {
  views: number = 0;
  counterInitDone: boolean = false;
  isLoadingData: boolean;
  getPageCounterView$;
  glpTrackPageView$;
  navitagionEnd$;

  currentPage: string;

  constructor(private router: Router, private translate: TranslateService, private authService: AuthService) {
  }

  ngOnInit(): void {
    let _this = this;
    this.navitagionEnd$ = this.router.events.subscribe((event: Event) => {
      if ((event instanceof NavigationEnd) && event && event.url && event.url !== _this.currentPage && _this.counterInitDone) {
        _this.currentPage = `${event.url}`;
        if (event.url && (event.url.includes('home')
          || event.url.includes('profile')
          || event.url.includes('collectionList')
          || event.url.includes('support')
          || event.url.includes('search')
          || event.url.includes('specialProjects')
          || event.url.includes('specialProjectPage')
          || event.url.includes('manageFaq')
          || event.url.includes('manageHeroHome')
          || event.url.includes('manageInspiredTags')
          || event.url.includes('faculty'))) {
          // Alzo un evento per tracciare la visualizzazione di pagina
          _this.setPageView(event.url);
        }
      }
    });

    if (
      (this.router.url.indexOf('home') !== -1
        || this.router.url.indexOf('profile') !== -1
        || this.router.url.indexOf('collectionList') !== -1
        || this.router.url.indexOf('support') !== -1
        || this.router.url.indexOf('search') !== -1
        || this.router.url.indexOf('specialProjects') !== -1
        || this.router.url.indexOf('specialProjectPage') !== -1
        || this.router.url.indexOf('manageFaq') !== -1
        || this.router.url.indexOf('manageHeroHome') !== -1
        || this.router.url.indexOf('manageInspiredTags') !== -1
        || this.router.url.indexOf('faculty') !== -1)
      &&
      !this.counterInitDone) {
      // Questo serve perché il router.events.subscribe non si trigghera quando si fa il refresh manuale della pagina
      this.counterInitDone = true;

      this.currentPage = `${this.router.url}`;

      // Alzo un evento per tracciare la visualizzazione di pagina
      this.setPageView(this.router.url);
    }
  }

  // Salva la visualizzazione di pagina
  setPageView(url) {
    this.isLoadingData = true;

    this.glpTrackPageView$ = this.authService.glpTrackPageView(url)
      .subscribe((data) => {
        if (data && data.error) {
          this.isLoadingData = false;
        } else {
          // Poi chiamo il servizio per recuperare il counter totale
          this.getPageCounterView(url);
        }
      },
        (err) => {
          this.isLoadingData = false;
        })
  }

  getPageCounterView(url) {
    this.isLoadingData = true;

    this.getPageCounterView$ = this.authService.glpGetViewsCounter(url)
      .subscribe((data) => {
        if (data && data.error) {
        } else if (data && data.response) {
          this.views = data.response || 0;
        }
        this.isLoadingData = false;
      },
        (err) => {
          this.isLoadingData = false;
        })
  }

  // Torna l'etichetta in base al numero di visualizzazioni di pagina
  getViewsLabel() {
    if (this.views === 1) {
      return ' ' + this.translate.instant('generic.VIEW');
    }

    return ' ' + this.translate.instant('generic.VIEWS');
  }

  ngOnDestroy(): void {
    if (this.getPageCounterView$) {
      this.getPageCounterView$.unsubscribe();
    }
    if (this.glpTrackPageView$) {
      this.glpTrackPageView$.unsubscribe();
    }
    if (this.navitagionEnd$) {
      this.navitagionEnd$.unsubscribe();
    }
  }
}
