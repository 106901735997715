<div *ngIf="inputValue" class="input-icon-container" fxLayout="row" fxLayoutAlign="start center"
  [ngClass]="{'disabled': inputValue.disabled}">
  <input-placeholder tabindex="-1" *ngIf="_showLoader" fxFlexFill></input-placeholder>
  <svg-icon tabindex="-1" *ngIf="iconSrc" [src]="iconSrc">
  </svg-icon>
  <input fxFlex [fxFlexOffset]="iconSrc ? '12px' : '0px'" [id]="_id" [type]="_type" [formControl]="inputValue"
    [placeholder]="placeholder" [attr.aria-label]="placeholder" [hidden]="_showLoader"
    (ngModelChange)="emitOnModelChanged($event)" [tabindex]="_tabIndex"
    [ngClass]="{'filled': inputIsFilled, 'invalid': inputValue.dirty && inputValue.invalid}">
    <svg-icon class="password-visibility-icon" (click)="toInputTextPasswordToggle()" tabindex="-1" *ngIf="passwordVisibility" [src]="iconSrcForPasswordVisibility">
    </svg-icon>
</div>