import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, take } from 'rxjs/operators';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { UrlService } from 'src/app/shared/services/url.service';
import { handleFromRecord, handleNumRecords } from 'src/app/shared/utils';
import {
  FacultyActivity,
  GetMyTrainingData,
  GLPShowcaseCard,
  GLPShowcaseCardWithCount,
} from 'atfcore-commonclasses/bin/classes/generalitrainingplatform';
import { ReferenceTypes, TalentFarmBadge, User } from 'atfcore-commonclasses';

import {
  getCompetenceUserAnalytics,
  TrainedCompetences,
} from 'atfcore-commonclasses/bin/classes/generalitrainingplatform/serviceResponses';

export interface UserActivity {
  id: string;
  title: string;
  eventName: string;
  itemType: string;
  when: string;
  status?: string;
}

type KnownFacultyRole =
  | 'MENTOR'
  | 'AMBASSADOR'
  | 'GURU'
  | 'TRAINER'
  | 'COMMUNITY_MANAGER'
  | 'D&I'
  | 'Ambassador'
  | 'Engagement Champion'
  | 'Mentor'
  | "Guru dell'onboarding"
  | 'Senior Expert'
  | 'Trainer';

// FacultyRole e' flessibile all'aggiunta di nuove ruoli oltre a quelli presenti
export type FacultyRole = KnownFacultyRole | Omit<string, KnownFacultyRole>;

export type UserCertificate = {
  rows: {
    engagementId: string;
    hashtags: string[];
    title: string;
    certifiedBy: string;
    duration: number;
    certificationDate: Date;
    tipology: string;
    uploadUrl: string;
    certificateFileName: string;
    itemId: string;
    containerItemId: string;
    user: User;
  }[];
  rowsCount: number;
};

@Injectable()
export class ProfileService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Retrieves the latest activities of the logged user
   * @param {number} fromRecord - Required: The number of the first record to load (exclusive - 0 to start from the first)
   * @param {number} numRecords - Required: The number of records to load
   * @return {any[]}
   */
  getMyLatestActivities(
    fromRecord: number,
    numRecords: number,
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean
  ): Observable<SenecaResponse<any[]>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);

    if (getOnlyUnread) {
      httpParams = httpParams.append('getOnlyUnread', "true");
    }
    if (getOnlyRead) {
      httpParams = httpParams.append('getOnlyRead', "true");
    }

    return this.http.get<SenecaResponse<any[]>>(
      this._mediatorUrl + 'glp-get-notification-list/' + fromRecord + '/' + numRecords,
      { params: httpParams }
    )
      .pipe(
        map((senecaResponse) => {
          let formattedNotifications = [];
          if (senecaResponse.response && senecaResponse.response.length) {
            for (let i = 0, resLength = senecaResponse.response.length; i < resLength; i++) {
              let currentNotification = senecaResponse.response[i];
              formattedNotifications.push({
                ...currentNotification,
                id: currentNotification.notificationQueueId,
                title: currentNotification.text,
                eventName: currentNotification.notificationType,
                when: currentNotification.creationDate,
                status: currentNotification.notificationStatus
              })
            }
          }
          senecaResponse.response = formattedNotifications;
          return senecaResponse;
        })
      );
  }

  countMyUnreadActivities(
    getOnlyUnread?: boolean,
    getOnlyRead?: boolean) {
    let httpParams: HttpParams = new HttpParams();

    if (getOnlyUnread) {
      httpParams = httpParams.append('getUnread', "true");
    }
    if (getOnlyRead) {
      httpParams = httpParams.append('getRead', "true");
    }
    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'glp-get-notification-counters',
      { params: httpParams }
    );
  }

  // Recupera il counter totale persone all'interno del proprio Tenant
  countPersonsInTenant(searchedText?: string, includeMe?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    return this.http.get<SenecaResponse<number>>(this._cm2MediatorUrl + 'count-persons-in-tenant', {
      params: httpParams
    });
  }

  // Recupera una lista di persone all'interno del proprio Tenant
  getPersonsInTenant(fromRecord: number, numRecords: number, searchedText?: string, includeMe?: boolean): any {
    let from = fromRecord && fromRecord.toString();
    let to = numRecords && numRecords.toString();
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', from || '0');
    httpParams = httpParams.append('numRecords', to || '25');
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (includeMe) {
      httpParams = httpParams.append('includeMe', includeMe.toString());
    }
    return this.http.get<SenecaResponse<any[]>>(this._cm2MediatorUrl + 'get-persons-in-tenant/' + from + '/' + to, {
      params: httpParams
    });
  }

  // Recupera i badge per la pagine nel profilo
  getBadges() {
    return this.http.get<SenecaResponse<TalentFarmBadge[]>>(
      this._mediatorUrl + 'get-my-badges'
    );
  }

  // Recupera i certificati per la pagine nel profilo
  getUserCertificates() {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.append('allData', 'true');
    return this.http.get<SenecaResponse<any[]>>(
      this._mediatorUrl + 'list-initiatives-with-earned-certificates'
    );
  }

  // restituisce i ruoli faculty che sono associati all'utente loggato
  getFacultyRoles(): Observable<SenecaResponse<FacultyRole[]>> {
    return this.http.get<SenecaResponse<FacultyRole[]>>(
      `${this._cm2MediatorUrl}glp-get-my-faculty-roles`
    );
  }

  getMandatoryTrainingCoursesToComplete(
    numRecords: number,
    fromRecord: number = 0,
    allData?: boolean
  ): Observable<SenecaResponse<GLPShowcaseCard[]>> {
    let params = new HttpParams();

    params = params.append('fromRecord', fromRecord && fromRecord.toString());
    params = params.append('numRecords', numRecords && numRecords.toString());
    if (allData) {
      params = params.append('allData', allData.toString());
    }

    return this.http.get<SenecaResponse<GLPShowcaseCard[]>>(
      `${this._cm2MediatorUrl}glp-get-mandatory-training-courses-to-complete`,
      { params }
    );
  }

  countMandatoryTrainingCoursesToComplete(): Observable<
    SenecaResponse<number>
  > {
    return this.http.get<SenecaResponse<number>>(
      `${this._cm2MediatorUrl}glp-count-mandatory-training-courses-to-complete`
    );
  }

  getFavoriteCourses(
    fromRecord: number = 0,
    numRecords: number,
    allData?: boolean
  ): Observable<SenecaResponse<GLPShowcaseCardWithCount>> {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord && fromRecord.toString());
    params = params.append('numRecords', numRecords && numRecords.toString());

    if (allData) {
      params = params.append('allData', 'true');
    }

    return this.http.get<SenecaResponse<GLPShowcaseCardWithCount>>(
      `${this._cm2MediatorUrl}glp-get-bookmarked-courses`,
      { params }
    );
  }

  // ########### PASSAPORTO ###########

  // Competenze formative
  getTrainedCompetences(
    numRecords: number,
    fromRecord: number = 0,
    softCompetences: boolean = true,
    techCompetences: boolean = true
  ): Observable<
    SenecaResponse<{
      rows: TrainedCompetences[];
      rowsCount: number;
    }>
  > {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());
    params = params.append('softCompetences', softCompetences.toString());
    params = params.append('techCompetences', techCompetences.toString());

    return this.http.get<
      SenecaResponse<{
        rows: TrainedCompetences[];
        rowsCount: number;
      }>
    >(`${this._cm2MediatorUrl}trained-competences-for-user`, { params });
  }

  isMandatoryTrainingRegularForManager(
    numRecords: number,
    fromRecord: number = 0,
    fullDepth: boolean,
    withSubordinatesLoaded: boolean,
    withCourseCounts: boolean
  ): Observable<
    SenecaResponse<{
      rows: TrainedCompetences[];
      rowsCount: number;
    }>
  > {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());

    if (fullDepth) {
      params = params.append('fullDepth', "true");
    }

    if (withSubordinatesLoaded) {
      params = params.append('withSubordinatesLoaded', "true");
    }

    if (withCourseCounts) {
      params = params.append('withCourseCounts', "true");
    }

    return this.http.get<
      SenecaResponse<{
        rows: TrainedCompetences[];
        rowsCount: number;
      }>
    >(`${this._cm2MediatorUrl}is-mandatory-training-regular-for-manager`, { params });
  }

  mandatoryTrainingUserCourseAnalyticsForManager(
    numRecords: number,
    fromRecord: number = 0,
    userId: string
  ): Observable<
    SenecaResponse<{
      rows: TrainedCompetences[];
      rowsCount: number;
    }>
  > {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());

    if (userId) {
      params = params.append('userId', userId);
    }

    return this.http.get<
      SenecaResponse<{
        rows: TrainedCompetences[];
        rowsCount: number;
      }>
    >(`${this._cm2MediatorUrl}mandatory-training-user-course-analytics-for-manager`, { params });
  }

  // Competenze formative - Dettaglio corsi

  // get-competence-user-analytics
  getCompetenceUserAnalytics(
    competenceId: string,
    fromRecord,
    numRecords,
  ): Observable<
    SenecaResponse<{ rows: getCompetenceUserAnalytics[]; rowsCount: number }>
  > {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());
    params = params.append('competenceId', competenceId.toString());

    return this.http.get<
      SenecaResponse<{ rows: getCompetenceUserAnalytics[]; rowsCount: number }>
    >(`${this._cm2MediatorUrl}get-competence-user-analytics`, { params });
  }

  // Attività Faculty
  // Ritorna le attività della faculty creata
  getFacultyActivities(
    userId: string,
    projectActivities: boolean = true,
    trainerActivities: boolean = true,
    communityActivities: boolean = true,
    onboardingActivities: boolean = true,
    mentorActivities: boolean = true,
    tutorActivities: boolean = true,
  ): Observable<SenecaResponse<FacultyActivity[]>> {
    let params = new HttpParams();
    params = params.append('userId', userId);

    const activities = [];

    if (projectActivities) {
      activities.push(ReferenceTypes.DATA_USER_PROJECT_ACTIVITY);
    }
    if (trainerActivities) {
      activities.push(ReferenceTypes.DATA_USER_TRAINER_ACTIVITY);
    }
    if (communityActivities) {
      activities.push(ReferenceTypes.DATA_USER_COMMUNITY_ACTIVITY);
    }
    if (onboardingActivities) {
      activities.push(ReferenceTypes.DATA_USER_ONBOARDING_ACTIVITY);
    }
    if (mentorActivities) {
      activities.push(ReferenceTypes.DATA_USER_MENTOR_ACTIVITY);
    }
    if (tutorActivities) {
      activities.push(ReferenceTypes.DATA_USER_TUTOR_ACTIVITY);
    }

    // create am array param
    activities.forEach((activity) => {
      params = params.append('facultyActivities', activity);
    });

    return this.http.get<SenecaResponse<FacultyActivity[]>>(
      `${this._cm2MediatorUrl}glp-get-user-faculty-activities`,
      { params }
    );
  }

  /**
   * Servizio per creare un'attività faculty.
   * I ruoli faculty da passare nel facultyRole vanno recuperati dal servizio:
   *    'glp-get-my-faculty-roles'
   *
   * @param facultyActivityType dovrebbe avere il seguente valore: ReferenceTypes.DATA_USER_MENTOR_ACTIVITY
   */
  createFacultyActivity(facultyActivity: {
    startDate: Date;
    endDate: Date;
    activityName: string;
    facultyActivityType: string;
    facultyRole: string;
  }): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      `${this._cm2MediatorUrl}glp-create-user-faculty-activity`,
      facultyActivity
    );
  }

  // Servizio per cancellare un'attività faculty
  deleteFacultyActivity(
    activityId: string
  ): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      `${this._cm2MediatorUrl}glp-delete-user-faculty-activity`,
      { activityId }
    );
  }

  /**
   * Servizio per aggiornare un'attività faculty
   * @param facultyActivityType dovrebbe avere il seguente valore: ReferenceTypes.DATA_USER_MENTOR_ACTIVITY
   * @returns
   */
  updateFacultyActivity(facultyActivity: {
    startDate: Date;
    endDate: Date;
    activityName: string;
    facultyActivityType: string;
    facultyRole: string;
  }): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(
      `${this._cm2MediatorUrl}glp-update-user-faculty-activity`,
      facultyActivity
    );
  }

  // Corsi svolti

  // Recupera il conteggio e i dettagli per la mia formazione
  getTrainingData(
    startDate,
    endDate,
    fromRecord,
    numRecords,
    searchedText
  ): Observable<SenecaResponse<GetMyTrainingData>> {
    let params = new HttpParams();
    params = params.append('startDate', startDate.toString());
    params = params.append('endDate', endDate.toString());
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());
    params = params.append('searchedText', searchedText || '');

    return this.http.get<SenecaResponse<GetMyTrainingData>>(
      `${this._cm2MediatorUrl}glp-get-my-training-data`,
      { params }
    );
  }

  // Genera il report dei corsi svolti
  // Scarica storico
  exportMyAttendedCourses(): Observable<SenecaResponse<string>> {
    return this.http.get<SenecaResponse<string>>(
      `${this._cm2MediatorUrl}glp-export-my-attended-courses`
    );
  }

  // ########### ATTESTATI ###########

  /**
   *  Recupero attestati e conteggio.
   * onlyCourseCertificates andrà passato a true, in modo da recuperare solo i certificati assegnati dai corsi
   */
  getListOfUserAccreditations(
    fromRecord: number = 0,
    numRecords: number = 50,
    onlyCourseCertificates: true = true,
    hashtags?: string[]
  ): Observable<SenecaResponse<UserCertificate>> {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());
    params = params.append(
      'onlyCourseCertificates',
      onlyCourseCertificates.toString()
    );
    if (hashtags) {
      params = params.append('hashtags', hashtags.toString());
    }

    return this.http.get<SenecaResponse<UserCertificate>>(
      `${this._cm2MediatorUrl}list-training-passport-user-certificates`,
      { params }
    );
  }

  // Utilizzato per scaricare gli attestati. Ritorna il path per poterli scaricare
  getCourseCertificate(
    engagementId: string
  ): Observable<SenecaResponse<string>> {
    let params = new HttpParams();
    params = params.append('engagementId', engagementId);

    return this.http.get<SenecaResponse<string>>(
      `${this._cm2MediatorUrl}get-course-certificate-for-passport`,
      { params }
    );
  }

  // Certificati

  /**
   *  Recupero attestati e conteggio.
   * onlyCreatedCertificates andrà passato a true, in modo da recuperare solo i certificati creati
   */
  getListOfUserCertificates(
    fromRecord: number = 0,
    numRecords: number = 50,
    onlyCreatedCertificates: true = true,
    hashtags?: string[]
  ): Observable<SenecaResponse<UserCertificate>> {
    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord.toString());
    params = params.append('numRecords', numRecords.toString());
    params = params.append(
      'onlyCreatedCertificates',
      onlyCreatedCertificates.toString()
    );
    if (hashtags) {
      params = params.append('hashtags', hashtags.toString());
    }

    return this.http.get<SenecaResponse<UserCertificate>>(
      `${this._cm2MediatorUrl}list-training-passport-user-certificates`,
      { params }
    );
  }

  listTrainingPassportUserCertificateHashtag(fromRecord: string, numRecords: string, fullDepth?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord);
    httpParams = httpParams.append('numRecords', numRecords);
    httpParams = httpParams.append('fullDepth', fullDepth === true ? 'true' : 'false');
    return this.http.get<SenecaResponse<any>>(`${this._cm2MediatorUrl}list-training-passport-user-certificate-hashtags`, {
      params: httpParams
    });
  }

  deleteTrainingPassportUserCertificate(certificateId: string) {
    return this.http.post<SenecaResponse<any>>(`${this._cm2MediatorUrl}delete-training-passport-user-certificate`, {
      certificateId: certificateId,
    });
  }

  // Crea certificato
  createTrainingPassportUserCertificate(
    file: File,
    title: string,
    certifiedBy: string,
    certificationDate: string,
    durationInMinutes: string,
    hashtags
  ) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    // ente
    formData.append('certifiedBy', certifiedBy);
    // data rilascio
    formData.append('releaseDate', certificationDate);
    formData.append('durationInMinutes', durationInMinutes);

    if (hashtags && hashtags.length) {
      for (let i = 0; i < hashtags.length; i++) {
        formData.append('hashtags', hashtags[i]);
      }
    }

    return this.http.post<SenecaResponse<UserCertificate>>(
      `${this._cm2MediatorUrl}create-training-passport-user-certificate`,
      formData
    );
  }

  // Modifica certificato
  updateTrainingPassportUserCertificate(
    engagementId: string,
    file: File,
    title: string,
    certifiedBy: string,
    certificationDate: string,
    durationInMinutes: string,
    hashtags
  ) {
    let formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    formData.append('title', title);
    formData.append('engagementId', engagementId);
    formData.append('certifiedBy', certifiedBy);
    formData.append('releaseDate', certificationDate);
    formData.append('durationInMinutes', durationInMinutes);

    if (hashtags && hashtags.length) {
      for (let i = 0; i < hashtags.length; i++) {
        formData.append('hashtags', hashtags[i]);
      }
    }

    return this.http.post<SenecaResponse<UserCertificate>>(
      `${this._cm2MediatorUrl}update-training-passport-user-certificate`,
      formData
    );
  }
}
