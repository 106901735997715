<button class="course-card-list-item-wrapper full-width no-style-button" tabindex="0"
    attr.aria-label="{{'generic.CLICKABLE_CARD' | translate}}" (click)="emitOnButtonClicked()">
    <div class="scroll-reference" id="{itemId}" [attr.id]="itemId"></div>
    <div fxLayout.gt-xs="row" fxLayout.xs="column">

        <div class="img-wrapper" [ngStyle]="{'background-image': 'url(' + cardCover + ')'}" fxFlex.gt-xs>
            <!-- Lucchetto, item bloccato -->
            <div class="item-locked-layer" *ngIf="isItemLocked" fxLayout="column" fxLayoutAlign="center center">
                <svg-icon class="item-locked-svg" src="../../../assets/icons/item-blocked.svg">
                </svg-icon>
                <p tabindex="0" translate="generic.HOW_CAN_I_UNBLOCK_IT"></p>
            </div>
            <!-- Label obbligatorio (oggetto dentro contenitore) -->
            <div *ngIf="showRequiredLabel && !isItemLocked" class="card-image-label mandatory-item-inside-container"
                fxLayout="row" fxLayoutAlign="center center">
                <p tabindex="0">{{('generic.MANDATORY') | translate}}</p>
            </div>
            <!-- Label propedeutico (oggetto dentro contenitore) -->
            <div *ngIf="showPropedeuticLabel && !isItemLocked"
                class="card-image-label propedeutic-item-inside-container"
                [ngClass]="{ 'margin-top25' :showRequiredLabel }" fxLayout="row" fxLayoutAlign="center center">
                <p tabindex="0">{{('generic.PROPEDEUTIC') | translate}}</p>
            </div>
        </div>
        <div class="info-wrapper" fxFlex.gt-xs fxLayout="column">
            <!-- Titolo -->
            <h3 tabindex="0" *ngIf="title">{{title | cutString:false:130:'...'}}</h3>
            <!-- Descrizione (se non è presente sottotitolo se non è presente lasciare vuoto) -->
            <p tabindex="0">{{(competences | cutString:false:130:'...') || ''}}
            </p>
            <!-- Categoria -->
            <div *ngIf="argumentName && argumentName.length" class="cluster-wrapper" fxLayout="row"
                fxLayoutAlign="start center">
                <!-- <h5 [ngClass]="clusterColorBackground"></h5> -->
                <p tabindex="0">{{argumentName || ''}}</p>
            </div>
        </div>
        <!-- Box info avanzate -->
        <div class="type-wrapper" fxFlex.gt-xs fxLayout="column" fxLayoutAlign="center center">
            <p class="type-color" [ngClass]="headerBackground"></p>
            <p *ngIf="!isCatalogCourse" tabindex="0" class="type-color-label">{{isOnline ? ('generic.ONLINE' |
                translate) : ('generic.IN_ROOM' | translate)}}
            </p>
            <p tabindex="0" class="type-label">{{categoryTitle}}</p>
            <p tabindex="0" class="duration-label" [ngClass]="headerTitleColor"
                *ngIf="headerDetail && headerDetail.length">
                {{headerDetail}}</p>
            <p tabindex="0" class="progress-label"
                *ngIf="completionPercentage && !isCompleted && !isVerified && !addedToWishlistDate">
                {{completionPercentage}}%</p>
            <p tabindex="0" class="progress-label" *ngIf="addedToWishlistDate">wishlist</p>
            <!-- Label completato/verificato/frequentato -->
            <div *ngIf="isCompleted || isVerified" class="complete-label-wrapper full-width" fxLayout="row"
                fxLayoutAlign="center center">
                <div class="complete-label-data" fxLayout="row" fxLayoutAlign="center center">
                    <p tabindex="0" *ngIf="isCompleted && !isVerified && (!isCM || isAssessment)">
                        {{ 'itemDetailsPage.generic_card.COMPLETED' | translate }}</p>
                    <p tabindex="0" *ngIf="isVerified">{{ 'itemDetailsPage.generic_card.VERIFIED' | translate }}</p>
                    <p tabindex="0" *ngIf="isCompleted && isCM && !isAssessment">{{ 'itemDetailsPage.generic_card.TAKE_PART' | translate
                        }}</p>
                </div>
            </div>
        </div>
    </div>
</button>

<!-- TODO Creare placeholder quando non ci sono dati disponibili -->