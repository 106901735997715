import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import { UrlService } from "src/app/shared/services/url.service";


export type FaqItem = {
  itemId: string;
  title: string;
  subTitle: string;
  description: string;
}

@Injectable()
export class FAQService {
  private readonly _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  getListFAQItems(): Observable<SenecaResponse<FaqItem[]>> {
    return this.http.get<SenecaResponse<FaqItem[]>>(`${this._cm2MediatorUrl}get-list-faq-items`);
  }

  countListFAQItems(): Observable<SenecaResponse<number>> {
    return this.http.get<SenecaResponse<number>>(`${this._cm2MediatorUrl}count-faq-items`);
  }

}
