import * as moment from 'moment';
import { Subscriber } from 'rxjs';

export function getTokenFromSsortkqp(authService: any): Promise<string> {
    return new Promise((resolve, reject) => {
        authService.crateRetrieveTokenAfterLogin().subscribe((senecaResponse) => {
            if (senecaResponse.error) {
                reject(senecaResponse.error);
            } else {
                if (senecaResponse && senecaResponse.response) {
                    resolve(senecaResponse.response);
                } else {
                    resolve(null);
                }
            }
        },
            (err: any) => {
                reject(err);
            })
    })
}
/**
 * Indica il tempo da sottrarre alla data inizio o fine dell'edizione per mostrare i materiali
 */
export var MATERIAL_VISIBILITY_DELTA_HOURS = 2;

export function genericResponseSubscriber<T>(__this: any, successCallback?: (param: T) => void, errorCallback?: (param: T) => void) {
    if (!__this.toastr || !__this.translate) {
        throw new Error('Error the component dependencies ToastrService or TranslateService aren\'t resolved');
    }

    return <Subscriber<T>>{
        next: (responseData: T) => {
            const _responseData: any = responseData;
            if (_responseData.error) {
                if (errorCallback) {
                    errorCallback(responseData);
                } else {
                    __this.toastr.error(__this.translate.instant('errors.' + _responseData.error));
                }
            } else {
                if (successCallback) {
                    successCallback(responseData);
                }
            }
        },
        error: (err) => {
            if (errorCallback) {
                errorCallback(err);
            } else {
                __this.toastr.error(__this.translate.instant('errors.' + err.message));
            }
        }
    };
}

export function defaultTo(value: any, _def: any) {
    return (!!value && value) || _def;
}


export class DateUtil {

    static now = function (): Date {
        return moment.utc().toDate();
    };

    static dateToUtc = function (date: Date): Date {
        if (!date) {
            return null;
        }
        try {
            return moment.utc(date).toDate()
        }
        catch (error) {
            return null;
        }
    }

    static stringToUtc = function (date: string): Date {
        if (!date) {
            return null;
        }
        try {
            return this.dateToUtc(new Date(date));
        }
        catch (error) {
            return null;
        }
    }

    static getDateWithoutSeconds(value): Date {
        if (!value) {
            return null;
        }
        if (typeof value === 'string') {
            return new Date(new Date(value).setSeconds(0, 0));
        }
        if (value instanceof Date) {
            return new Date(value.setSeconds(0, 0));
        }
    }

    static getDateWithoutTime(value): Date {
        if (!value) {
            return null;
        }
        if (typeof value === 'string') {
            return new Date(new Date(value).setHours(0, 0, 0, 0));
        }
        if (value instanceof Date) {
            return new Date(new Date(value).setHours(0, 0, 0, 0));
        }
    }

    static convertModelToUTC(value): string {
        const _date = DateUtil.getDateWithoutSeconds(value);
        if (_date && _date instanceof Date) {
            return new Date(_date.toUTCString()).toISOString();
        }
        return null;
    }

    static getDayDateFromUTCDate(value): string {
        // trasformo nella data localizzata
        // e azzero ore minuti e secondi e converto in UTC
        const _localizedDate = DateUtil.getDateWithoutTime(value);
        return DateUtil.convertModelToUTC(_localizedDate);
    }

    static calculateDateTimeByDay(dayDate: string, time: string) {
        let resultFromMoment = null;
        if (dayDate && time) {
            resultFromMoment = moment(dayDate).add(new Date(time).getHours(), 'hours').toISOString();
            resultFromMoment = moment(resultFromMoment).add(new Date(time).getMinutes(), 'minutes').toISOString();
        } else {
            resultFromMoment = time && moment(time).toISOString();
        }
        return resultFromMoment;
    }
}

export function parseBoolean(val: string | boolean | number): boolean {
    return !!(val && (val === true || val === "true" || val === "1" || val === 1));
}

export function buildAutocompleteServiceKey(srvName: string, allData?: string | boolean, fromRecord?: string | number,
    numRecords?: string | number, type?: string, searchedText?: string, useCache?: boolean) {
    // caceh disabilitata
    // return null;

    // da commentare per disabilitare la cache su tutti i servizi gestiti
    if (!parseBoolean(useCache) || !srvName) {
        return null;
    }
    const _type = (type && type.replace(/[^a-zA-Z0-9-_]|\-/g, '')) || '';

    let serviceKey = srvName + _type;
    if (parseBoolean(allData)) {
        serviceKey += '_allData';
    } else if (!isNaN(<number>fromRecord) || !isNaN(<number>numRecords)) {
        serviceKey += '_from_' + (!isNaN(<number>fromRecord) ? fromRecord : '0')
            + '_numRecords_' + (!isNaN(<number>numRecords) ? numRecords : '0');
    }
    return serviceKey;
}

export class CachedResult {
    searchString: string;
    response: any;
}

export function findCachedResult(cache: any, key: string, searchedText?: string) {
    if (cache && key && cache[key]) {
        const cachedResults = <CachedResult[]>cache[key];
        return cachedResults && cachedResults.length && cachedResults.find((result: CachedResult) => {
            return result.searchString === searchedText;
        });
    }
    return null;
}

export function storeCachedResult(cache: any, key: string, response: any, searchedText?: string) {
    // TODO-MC aggiungere limite di risultati in cache in modo da non rischiare di occupare troppa memoria
    if (cache && key) {
        const newResult = <CachedResult>{
            searchString: searchedText,
            response: response
        };
        if (!cache[key]) {
            cache[key] = [newResult];
        } else if (!findCachedResult(cache, key, searchedText)) {
            const cachedResults = <CachedResult[]>cache[key];
            if (cachedResults && cachedResults.length) {
                cachedResults.push(newResult);
            }
        }
    }
}