<div class="error-403-wrapper" fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFill>
    <div class="logo-container" fxLayout="column" fxLatyoutAlign="center center">
      <img placement="bottom" class="logoRegular cursor-pointer" src="./assets/img/generali/logo-generali.svg">
    </div>
    <h2 tabindex="0">
      LearningUP
    </h2>
    <div fxLayout="row" fxLayoutAlign="center center">
      <h1 tabindex="0" class="error-403 text-inline center lighter">403</h1>
    </div>
    <h2 tabindex="0" class="no-margin">OOOOOOOOPS!</h2>
    <h4 tabindex="0" class="center"><span translate="errors.403"></span>
    </h4>
  </div>
</div>
