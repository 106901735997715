<div *ngIf="_inputValue" class="select-icon" fxLayout="row" fxLayoutAlign="start center"
  [ngClass]="{'disabled': _inputValue.disabled}">
  <input-placeholder tabindex="-1" *ngIf="_showLoader" fxFlexFill></input-placeholder>
  <svg-icon tabindex="-1" *ngIf="iconSrc" [src]="iconSrc">
  </svg-icon>

  <custom-select class="select-icon-with-dropdown" fxFlex [ngClass]="{'hide-placeholder': _inputValue.value }" [fxFlexOffset]="iconSrc ? '12px' : '0px'"
    [id]="_id" [placeholderLabel]="placeholder" [disabled]="_inputValue.disabled" [attrAriaLabel]="placeholder"
    [tabindex]="_tabIndex" [selectedValue]="_inputValue.value" (onSelectionChanged)="onSelectionChanged($event)"
    [items]="items" [loading]="_showLoader" hideSelected [dropdownPosition]="dropdownPosition">
  </custom-select>
</div>