<div class="footer">
  <div class="first-row">
    <div class="logo-wrapper">
      <svg-icon src="../../../assets/icons/logos/icon-general-dsk.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-all-dsk.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-cat-dsk.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-welion-dsk.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-jeniot-dsk.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-das-dsk.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-genagricola.svg"></svg-icon>

      <svg-icon src="../../../assets/icons/logos/icon-genertel.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-genertel-life.svg"></svg-icon>
      <svg-icon src="../../../assets/icons/logos/icon-europ-assistance.svg"></svg-icon>

    </div>
    <div class="menu-wrapper">
      <div class="btn-footer-menu" *ngFor="let tab of firstFooterTabs; trackBy: trackByTitle" tabindex="0" role="button"
        (click)="openLink(tab)">
        <h5>{{isDesktop ? ((tab.title | translate) | uppercase) : (tab.title | translate)}}</h5>
        <svg-icon class="flex-show-lt-md" src="../../../assets/icons/icon-plus.svg" height="12px" width="12px">
        </svg-icon>
      </div>
    </div>
  </div>
  <div class="second-row">
    <p>© Generali Italia S.p.A. <br fxShow.xs fxHide /> - C.F. 00409920584 - P.IVA 01333550323
    </p>
  </div>
</div>
