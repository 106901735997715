/*
*  Lista di azioni riguardanti il profilo dell'utente loggato
*/

import { Action } from '@ngrx/store';

import { Lang, User } from 'atfcore-commonclasses/bin/classes/anag';

export const UPDATE_USER = 'UPDATE_USER';
export const CANCEL_LOGGED_USER = 'CANCEL_LOGGED_USER';
export const SET_LOGGED_SOCIAL_USER = 'SET_LOGGED_SOCIAL_USER';

// Decodifica il Token salvandolo nel Global Application Data
export const DECODE_TOKEN = 'DECODE_TOKEN';

// Salva le lingue disponibili, recuperate tramite servizi
export const SAVE_AVAILABLE_LANGS = 'SAVE_AVAILABLE_LANGS';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public payload: { loggedUser: User }) { }
}

export class SaveAvailableLangs implements Action {
  readonly type = SAVE_AVAILABLE_LANGS;

  constructor(public payload: Lang[]) { }
}

export class CancelLoggedUser implements Action {
  readonly type = CANCEL_LOGGED_USER;
}

export class DecodeToken implements Action {
  readonly type = DECODE_TOKEN;

  // Payload dell'azione, che è il token
  constructor(public payload: string) { }
}
export class SetLoggedSocialUser implements Action {
  readonly type = SET_LOGGED_SOCIAL_USER;

  // Payload dell'azione, che è il token
  constructor(public payload: any) { }
}

export type ProfileActions = UpdateUser
  | DecodeToken
  | SetLoggedSocialUser
  | CancelLoggedUser
  | SaveAvailableLangs;
