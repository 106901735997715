import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MapById } from 'atfcore-commonclasses';

type PagesTypes = 'NEWS_PAGE' | 'COURSES_PAGE' | 'EVENTS_PAGE';
@Injectable()
export class ScrollTo {

  private pagesWithScroll = new MapById<{
    filterBy: string;
    scrollY: number;
    confirmed: boolean;
  }>();

  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  /**
   * Scroll to an anchor in the document by the element id
   * @param elementId Id of the element where to scroll
   */
  element(elementId: string) {
    const element = this.document.getElementById(elementId);
    if (element !== undefined && element !== null) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  /**
   * Scroll to top
   */
  header() {
    this.element('app-header');
  }

  /** Salva lo scroll del componente */
  setComponentScroll(page: PagesTypes, scrollY: number, filterBy: string) {
    this.pagesWithScroll[page] = {
      filterBy: filterBy,
      scrollY: scrollY,
      confirmed: false
    }
  }

  /** Ritorna lo scroll del componente solo se è stato confermato. In ogni caso lo elimina */
  getComponentScrollAndReset(page: PagesTypes): {
    filterBy: string;
    scrollY: number;
    confirmed: boolean;
  } {
    const scroll = this.pagesWithScroll[page] || {
      filterBy: '',
      scrollY: 0,
      confirmed: false
    };

    this.pagesWithScroll[page] = null;

    return scroll.confirmed ? scroll : null;
  }

  /** Conferma lo scroll del componente, in modo che la prossima volta torni in questo punto */
  confirmComponentScroll(page: PagesTypes) {
    if (this.pagesWithScroll[page]) {
      this.pagesWithScroll[page] = {
        ...this.pagesWithScroll[page],
        confirmed: true
      }
    }
  }

}
