import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { UrlService } from "src/app/shared/services/url.service";
import { handleFromRecord, handleNumRecords, handleSearchedText, handleArgumentsIds } from 'src/app/shared/utils';
import { SenecaResponse, Prize, TalentFarmSenecaResponse } from 'atfcore-commonclasses';
import { PrizeImplemented } from 'src/app/shared/interfaces/prize.interface';

@Injectable()
export class PrizeService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  // Recupera il count dei premi
  countPrizes(searchedText?: string, argumentsIds?: string | string[], minCoins?: number, maxCoins?: number) {

    let httpParams: HttpParams = new HttpParams();

    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleArgumentsIds(httpParams, argumentsIds);

    if (minCoins) {
      httpParams = httpParams.append('minCoins', minCoins.toString());
    }

    if (maxCoins) {
      httpParams = httpParams.append('maxCoins', maxCoins.toString());
    }

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-prizes', { params: httpParams });
  }

  // Recupera i premi
  getPrizes(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string, argumentsIds?: string | string[], minCoins?: number, maxCoins?: number, mySavedOnly?: boolean) {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleArgumentsIds(httpParams, argumentsIds);
    httpParams = httpParams.append('asCourseItem', 'true');

    if (minCoins) {
      httpParams = httpParams.append('minCoins', minCoins.toString());
    }

    if (maxCoins) {
      httpParams = httpParams.append('maxCoins', maxCoins.toString());
    }

    if(mySavedOnly) {
      httpParams = httpParams.append('mySavedOnly', 'true');
    }

    return this.http.get<SenecaResponse<Prize[]>>(this._mediatorUrl + 'list-prizes', { params: httpParams });
  }

  // Recupera i premi in evidenza
  listSuggestedPrizes(allData?: boolean, fromRecord?: number, numRecords?: number) {
    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = httpParams.append('asCourseItem', 'true');

    return this.http.get<SenecaResponse<Prize[]>>(this._mediatorUrl + 'list-suggested-prizes', { params: httpParams });
  }

  // Recupera il premio da un suo prizeId
  getPrizeById(prizeId: string) {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.append('prizeId', prizeId);
    httpParams = httpParams.append('asCourseItem', 'true');

    return this.http.get<SenecaResponse<PrizeImplemented>>(this._mediatorUrl + 'get-manual-prize-by-id', { params: httpParams });
  }

  // Riscatta un premio
  reedemPrize(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<number>>(this._mediatorUrl + 'redeem-prize', { itemId: itemId });
  }

  // Salva premio
  savePrizeForLater(prizeId: string) {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'save-prize-for-later', { prizeId: prizeId });
  }

  // Rimuovi premio
  removePrizeSavedFromLater(prizeId: string) {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'remove-prize-from-saved-for-later', { prizeId: prizeId });
  }
}
