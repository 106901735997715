import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface SelectListOption {
  selected?: boolean,
  label?: string,
  value?: string | Object,
  disabled?: boolean
};

@Component({
  selector: 'select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {

  @Input() title: string;
  @Input() selectAllLabel?: string;

  /**
   * Values selected
   */
  _selectedValue: any = [];
  @Input()
  set selectedValue(value: any[]) {
    this._selectedValue = value ? value : [];
    if (this._shadowItems) {
      this.selectValues();
    }
  }
  get selectedValue(): any[] {
    return this._multiple ? this._selectedValue : [this._selectedValue];
  };
  @Output() selectedValueChange = new EventEmitter<any[]>();

  _multiple: boolean;
  @Input() set multiple(value: boolean | string) {
    this._multiple = coerceBooleanProperty(value);
  };

  _noHeader: boolean;
  @Input() set noHeader(value: boolean | string) {
    this._noHeader = coerceBooleanProperty(value);
  };

  _isLoading: boolean;
  @Input() set isLoading(value: boolean | string) {
    this._isLoading = coerceBooleanProperty(value);
  };

  /**
   * Object property to use for selected model. By default binds to whole object.
   */
  @Input() bindValue?: string;

  /**
   * Object property to use for label.
   */
  @Input() bindLabel: string = 'text';

  /**
   * Select all
   */
  _hasSelectAll: boolean;
  @Input() set hasSelectAll(value: boolean | string) {
    this._hasSelectAll = coerceBooleanProperty(value);
  };

  /**
   * If is open
   */
  _isOpen: boolean = false;
  @Input() set isOpen(value: boolean | string) {
    this._isOpen = coerceBooleanProperty(value);
  };

  _labelToTranslate: boolean = false;
  @Input() set labelToTranslate(value: boolean | string) {
    this._labelToTranslate = coerceBooleanProperty(value);
  };

  _items: any[];
  @Input()
  get items(): any[] {
    return this._items;
  }
  set items(value: any[]) {

    this._items = value ? value : [];

    // Initialize the shadow items for management
    this._shadowItems = this._items.map((x: SelectListOption) => {
      return {
        selected: false,
        value: this.bindValue ? x[this.bindValue] : x,
        label: this.bindLabel ? x[this.bindLabel] : x,
        disabled: false
      };
    });

    this.selectValues();

  }

  /**
   * Fired on model change. Outputs whole model
   */
  @Output() onSelectionChanged = new EventEmitter<any[]>();

  /**
   * Fired when item is added while [multiple]="true". Outputs added item
   */
  @Output() addItem = new EventEmitter<any>();

  /**
   * Fired when item is removed while [multiple]="true"
   */
  @Output() removeItem = new EventEmitter<any>();

  /**
   * Fired on select dropdown close
   */
  @Output() close = new EventEmitter();

  _id: string;
  @Input() id: string | number;

  _isSelectedAll: boolean = false;
  get isSelectedAll(): boolean {
    return this._items.length === this._selectedValue.length;
  }

  _shadowItems: SelectListOption[];

  private _blockEvents = false;

  constructor() {

  }

  ngOnInit(): void {

    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "select-list-" + this.id;

    // Manage the selected value
    if (!this._multiple && this._selectedValue && this._selectedValue.length > 0) {
      this._selectedValue = this._selectedValue[0];
    }
    if (this._multiple && !this._selectedValue) {
      this._selectedValue = [];
    }
  }

  /**
   * Select the values in the shadow items
   */
  private selectValues() {
    // Reset dei shadow items
    this._shadowItems.forEach(x => x.selected = false);

    // Impostazione della select
    if (this._multiple && this._selectedValue && this._selectedValue.length) {
      this._selectedValue.forEach(value => {
        for (let i = 0; i < this._shadowItems.length; i++) {
          if (JSON.stringify(this._shadowItems[i].value) === JSON.stringify(this.bindValue ? value[this.bindValue] : value)) {
            this._shadowItems[i].selected = true;
            break;
          }
        }
      });
      this._isSelectedAll = this.isSelectedAll;
    } else {
      for (let i = 0; i < this._shadowItems.length; i++) {
        if (JSON.stringify(this._shadowItems[i].value) === JSON.stringify(this.bindValue ? this._selectedValue[0][this.bindValue] : this._selectedValue[0])) {
          this._shadowItems[i].selected = true;
          break;
        }
      }
    }
  }

  trackBy(index: any, item: any) {
    return index;
  }

  /**
   * Triggered when an item is selected
   * @param item Item that changed
   */
  itemChange(item?: SelectListOption) {
    if (!this._blockEvents) {

      if (!this.isSelectedAll) {
        if (this._multiple) {
          if (item.selected) {
            this._selectedValue.push(item.value);
            this.addItem.emit();
          } else {
            let indexToRemove = -1;
            for (let i = 0; i < this._selectedValue.length; i++) {
              if (JSON.stringify(this._selectedValue[i]) === JSON.stringify(item.value)) {
                indexToRemove = i;
                break;
              }
            }
            if (indexToRemove !== -1) {
              this._selectedValue.splice(indexToRemove, 1);
            }
            this.removeItem.emit();
          }
        } else {
          this._selectedValue = this.bindValue ? item.value[this.bindValue] : item.value;
        }
      }

      this.selectedValueChange.emit(this._selectedValue);
      this.onSelectionChanged.emit(this._selectedValue);
    }
  }

  /**
   * Arrow action that open/close the list
   */
  arrowClick() {
    this._isOpen = !this._isOpen;
  }

  selectAll() {
    this._blockEvents = true;
    this._selectedValue = this._items.map(x => x);
    this._shadowItems.forEach(x => {
      x.selected = true;
    });
    setTimeout(() => {
      this._blockEvents = false;
      this.selectedValueChange.emit(this._selectedValue);
      this.addItem.emit();
      this.onSelectionChanged.emit(this._selectedValue);
    }, 500);
  }

  unselectAll() {
    this._blockEvents = true;
    this._selectedValue = [];
    this._shadowItems.forEach(x => {
      x.selected = false;
    });
    setTimeout(() => {
      this._blockEvents = false;
      this.selectedValueChange.emit(this._selectedValue);
      this.addItem.emit();
      this.onSelectionChanged.emit(this._selectedValue);
    }, 500);
  }

  resolveItemTitle(item: any) {
    if (this.bindLabel) {
      return item[this.bindLabel];
    } else return item;
  }

  compareWith(item, selected) {
    return item[this.bindLabel] === selected[this.bindLabel];
  };

  emitRemoveItem(item: any): void {
    if (this.removeItem && this.removeItem instanceof EventEmitter) {
      this.removeItem.emit(item);
    }
  }

  emitAddItem(item: any): void {
    if (this.addItem && this.addItem instanceof EventEmitter) {
      this.addItem.emit(item);
    }
  }

  emitOnClose(): void {
    if (this.close && this.close instanceof EventEmitter) {
      this.close.emit();
    }
  }

  emitOnSelectionChanged(data: any): void {
    if (this.onSelectionChanged && this.onSelectionChanged instanceof EventEmitter) {
      this.onSelectionChanged.emit(data);
    }
  }

}
