<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div>
  <div class="login-page full-width" *ngIf="langs && langs.length" fxLayout="row" fxFlexFill>
    <div class="login-page-body" fxLayout="column" fxLayoutAlign="start start">

      <img class="login-page-logo" src="../../../assets/img/generali/logo-generali-red-header.png" height="90">

      <div class=" login-page-content" fxLayout="column" fxLayoutAlign="start start">
        <h4 class="lighter" translate="introduction.TITLE"></h4>
        <h6 class="lighter" translate="introduction.DESCRIPTION"></h6>

        <form *ngIf="loginForm" class="full-width" fxLayout="column" fxLayoutAlign="start center" (ngSubmit)="onLogin()"
          [formGroup]="loginForm">

          <!-- EMAIL -->
          <input-icon class="login-page-input-email" [placeholder]="'generic.EMAIL' | translate" [inputValue]="email"
            iconSrc="../../../assets/icons/user/icon-user.svg" [showLoader]="showLoader">
          </input-icon>

          <!-- PASSWORD -->
          <input-icon [passwordVisibility]="true" class="login-page-input-password"
            [placeholder]="'generic.PASSWORD' | translate" [inputValue]="password" type="password"
            iconSrc="../../../assets/icons/user/icon-password.svg" [showLoader]="showLoader">
          </input-icon>

          <!-- LOGIN BUTTON -->
          <custom-button [text]="'introduction.LOGIN' | translate" [disabled]="loginForm.invalid" type="submit">
          </custom-button>
        </form>

      </div>

    </div>
  </div>

  <!-- Modale che apre la sezione di ricerca mobile -->
  <app-modal class="modal-signin" id="recoverPasswordModal">

    <generali-carousel #signinCarousel [isFullWindow]="true" noScroll hidePoints sectionId="profilation-carousel"
      [contentLength]="1" [index]="1">

      <!-- Fissi -->
      <ng-container>

        <!-- Chiudi -->
        <svg-icon tabindex="-1" class="modal-signin-close cursor-pointer"
          src="../../../assets/icons/icon-close-light.svg" height="40" width="40" (click)="closeModalRecoverPassword()">
        </svg-icon>
      </ng-container>

      <!-- Recupero password -->
      <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="start center">
        <!-- Logo generali -->
        <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
          <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
            <img alt="LearningUP" height="90" layout="column"
              src="/assets/img/generali/logo_lockup_bianco.png">
          </div>
        </div>

        <!-- Inserisci password -->
        <h4 tabindex="-1" class="center" translate="signin.recover-page.TITLE" fxFlexOffset="60px"
          fxFlexOffset.xs="40px">
        </h4>

        <!-- Form registrazione -->
        <form *ngIf="signinFormPwRecover" class="modal-signin-page-actions" fxLayout="column"
          fxLayoutAlign="space-between" (ngSubmit)="saveNewPassword()" [formGroup]="signinFormPwRecover">

          <div fxLayout="column">

            <!-- Descrizione password -->
            <h5 tabindex="-1" class="lighter modal-signin-password-description">
              Inserisci la tua password , deve contenere almeno:<br>
              - 8 caratteri (non si possono ripetere i caratteri più di 2 volte)<br>
              - un carattere minuscolo, uno maiuscolo, un numero ed almeno un carattere speciale tra questi:
              !"#$%&'()*+,-./:;<=>?@[\]^_`&#123;|&#125;~
            </h5>

            <!-- Password -->
            <input-icon [passwordVisibility]="true" tabIndexValue="-1" class="modal-signin-input-password"
              [placeholder]="'signin.first-page.PASSWORD' | translate" [inputValue]="passwordRecover" type="password"
              iconSrc="../../../assets/icons/user/icon-password.svg" [showLoader]="showLoader">
            </input-icon>

            <!-- Conferma password -->
            <input-icon [passwordVisibility]="true" tabIndexValue="-1"
              [placeholder]="'signin.first-page.PASSWORD2' | translate" [inputValue]="passwordRecoverCheck"
              type="password" iconSrc="../../../assets/icons/user/icon-password.svg" [showLoader]="showLoader">
            </input-icon>
          </div>

          <div fxLayout="column">
            <!-- Continua -->
            <div class="modal-signin-button" fxLayout="row" fxLayoutAlign="center center">
              <custom-button tabIndexValue="-1" [text]="'signin.first-page.ACTION' | translate"
                [disabled]="signinFormPwRecover && signinFormPwRecover.invalid" type="submit">
              </custom-button>
            </div>
          </div>

        </form>
      </div>
    </generali-carousel>
  </app-modal>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>
