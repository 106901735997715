import { Component, ChangeDetectionStrategy, AfterViewInit, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

import * as fromApp from '../../../ngrx/app.reducers';
import { BaseSubscriberComponent } from '../base-subscriber.component';

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends BaseSubscriberComponent implements AfterViewInit {

  @Input() _value: number = 1;
  _steps: number = 6;

  _stepWidth: number;

  _stepLength: number;

  @Input() value: number;
  @Output() valueChange = new EventEmitter<number>();

  @Input() onSlide = new EventEmitter<number>();

  constructor(private store: Store<fromApp.AppState>,) {
    super();

    this.store.select(fromApp.getScreenWidth)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (screenWidth) => {
          if (screenWidth) {
            this.calculateElementWidth();
          }
        });
  }

  ngAfterViewInit() {
    this.calculateElementWidth();
  }

  scrollRight() {
    let event = {
      target: {
        value: this._value + 1
      }
    }
    this.changeValue(event);
  }

  scrollLeft() {
    let event = {
      target: {
        value: this._value - 1
      }
    }
    this.changeValue(event);
  }

  /**
   * Calculate the length of the step by the lenght of the slider
   */
  calculateElementWidth() {
    const element: HTMLElement = document.getElementById('slider-input');
    if (element) {
      this._stepWidth = Math.round(element.offsetWidth / (this._steps - 1));
      this.setStepLength();
    }
  }

  changeValue(event: any) {
    if (event !== undefined && event.target !== undefined) {
      this._value = parseInt(event.target.value);
      if (this.valueChange !== undefined) {
        this.valueChange.emit(this._value);
      }
      if (this.onSlide !== undefined) {
        this.onSlide.emit(this._value);
      }
    } else {
      this._value = 0;
    }
    this.setStepLength();
  }

  setStepLength(): void {
    // Lunghezza dello step più margine
    if (this._value < 2) {
      this._stepLength = this._stepWidth > 80 ? 42 : 32;
    } else {
      const pxMultiplier: number = this._stepWidth > 80 ? 7 : 5;
      const stepLength: number = ((this._value - 1) * this._stepWidth) + ((this._steps - this._value) * pxMultiplier)
      this._stepLength = stepLength;
    }

  }

}
