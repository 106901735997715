import { ItemTypes } from "atfcore-commonclasses/bin/classes/item";

/*
 * Modello che dinisce le classi utili per la gestione delle iniziative
*/

export class InitiativeType {
    public id?: string;
    public name: string;
    public code: string;
    public disabled?: boolean;

    constructor(name: string, code: string, disabled: boolean, id?: string) {
        this.id = id;
        this.name = name;
        this.code = code;
        this.disabled = disabled;
    }
}

export class InitiativeUtils {
    static isOnlineInitiative(initiativeType: string): boolean {
        return this.isOnlineCourseOrEvent(initiativeType) || initiativeType === ItemTypes.ASSESSMENT;
    }

    static isOnlineEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE;
    }

    static isOnlineCourseOrEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE;
    }

    static hasLinkedLibraryItem(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE;
    }

    static isAssessment(initiativeType: string): boolean {
        return initiativeType === ItemTypes.ASSESSMENT;
    }

    static isCatalogStage(initiativeType: string): boolean {
        return initiativeType === "CATALOG_STAGE";
    }

    static isEvent(initiativeType: string): boolean {
        return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.EVENT_CLASS_STAGE;
    }

    static isCourse(initiativeType: string): boolean {
        return initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_CLASS_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE || initiativeType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
    }

    static isCourseOnlineStage(initiativeType: string): boolean {
        return initiativeType === ItemTypes.COURSE_ONLINE_STAGE;
    }

    static isWebinar(initiativeType: string): boolean {
        return initiativeType === ItemTypes.WEBINAR;
    }

    static isCascadingMeeting(initiativeType: string): boolean {
        return initiativeType === ItemTypes.CASCADING_MEETING;
    }

    static isStage(initiativeType: string): boolean {
        return this.isAssessment(initiativeType) || this.isCourse(initiativeType) || this.isEvent(initiativeType) || this.isWebinar(initiativeType);
    }

    static isStageOnClass(initiativeType: string): boolean {
        return initiativeType == ItemTypes.COURSE_CLASS_STAGE || initiativeType == ItemTypes.EVENT_CLASS_STAGE || initiativeType == ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
    }

    static isPath(initiativeType: string): boolean {
        return initiativeType === ItemTypes.COURSE_PATH;
    }
}

export const CKEditorConfig = {
    allowedContent: true,
    autoParagraph: false,
    extraPlugins: 'divarea',
    height: 317,
    enterMode: 2,
    toolbar: [
        {
            name: 'document',
            groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
            items: [
                'Source', '-', 'Preview', 'Undo', 'Redo', 'Bold', 'Italic', 'Underline',
                'Strike', 'Subscript', 'Superscript', 'RemoveFormat', 'NumberedList', 'BulletedList', 'Outdent',
                'Indent', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Image', 'Table',
                'Link', 'Unlink', 'Smiley', 'SpecialChar', 'TextColor', 'BGColor', 'Maximize'
            ]
        },
        { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] }
    ]
};