<div class="space40"></div>

<div class="content theme-primary" *ngIf="isExternalCallbackUrl">
  <div class="header-img">
    <div class="logo-home" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="start start">
        <img height="70" width="100,44" src="../../../assets/img/logo_generali.svg">
      </div>
    </div>
  </div>
</div>

<div class="theme-primary" *ngIf="pageReady">
  <div class="space20"></div>

  <div fxLayoutAlign="start center" class="privacy-title-container">
  <h2>Privacy Policy
  </h2>
</div>
  <div class="space20"></div>
  <h5><b>Informativa sul trattamento dei dati personali ai sensi degli articoli 13 e 14 del Regolamento UE n.679/2016 del 27 aprile 2016.</b></h5>
  <div class="space20"></div>
  <h5>La informiamo che i suoi dati personali anagrafici e identificativi (nome, cognome, indirizzo, codice fiscale e/o documento di identità), e, previo suo consenso, anche sotto forma di foto e/o video, sono trattati, in qualità di titolare del trattamento da Generali Business Solutions Scpa, e dalla società del gruppo Generali (di seguito Società titolare), come indicato nell’elenco sotto riportato, che mette a disposizione dei contenuti formativi (e/o premi)  o  nel caso in cui sia interessata al suo profilo, ai fini di consentirLe  l’accesso e la fruizione dei servizi di “LearningUP”, piattaforma informatica con contenuti di approfondimento su tematiche di Formazione e di opportunità di lavoro del Gruppo Generali.<br>
    La informiamo che i suoi dati potranno essere utilizzati nel legittimo interesse della Compagnia per individuare e prevenire eventuali frodi e per esercitare i diritti di difesa della stessa.<br>
    Le offriamo, inoltre, l’opportunità di partecipare, con il suo consenso, a eventi challenge  selezionabili in base alle sue particolari caratteristiche o interessi, con la possibilità di analizzare le sue preferenze ed attitudini a fini di un’eventuale selezione.<br>
    La informiamo, inoltre, che i suoi dati potranno essere pubblicati in internet, siti web aziendali e nei social nei quali la società titolare è presente con propri profili.<br>
    Lei potrà conoscere quali sono i suoi dati trattati presso la Società titolare, ove ne ricorrano le condizioni, esercitare i diversi diritti relativi al loro utilizzo (diritto di accesso, rettifica, aggiornamento, integrazione, cancellazione, limitazione al trattamento, alla portabilità, alla revoca del consenso al trattamento e di ottenere una copia dei propri dati laddove questi siano conservati in paesi al di fuori dell’Unione Europea, nonché di ottenere indicazione del luogo nel quale tali dati vengono conservati o trasferiti) nonché opporsi per motivi legittimi ad un loro particolare trattamento e comunque al loro uso a fini commerciali, in tutto o in parte anche per quanto riguarda l’uso di modalità automatizzate  rivolgendosi al Responsabile della Protezione dei Dati (RPD) della Società titolare, designato del titolare e contattabile  all’indirizzo sotto riportato.<br>
    La informiamo, inoltre, che, qualora ravvisi un trattamento dei suoi dati non coerente con i consensi da Lei espressi può sporgere reclamo al Garante per la protezione dei dati personali, con le modalità indicate sul sito del Garante stesso.<br>
    I suoi dati personali possono essere conservati per periodi di tempo diversi a seconda della finalità per la quale sono trattati dalla Società titolare, in conformità alla normativa privacy tempo per tempo applicabile, in particolare: (i) per finalità contrattuali per un periodo di 10 anni dal momento della cessazione dell'efficacia del contratto o, in caso di contestazioni, per il termine prescrizionale previsto dalla normativa per la tutela dei diritti connessi, fatti salvi in ogni caso periodi di conservazione maggiori previsti da specifiche normative di settore; (ii) per i dati relativi all’utilizzo dei servizi offerti, per il periodo necessario agli scopi per i quali sono stati raccolti o trattati e, comunque, non superiore a 24 mesi dalla fruizione del servizio medesimo.<br>
    I suoi dati, saranno diffusi nei limiti delle finalità suddette e saranno trattati con idonee modalità e procedure anche informatizzate, da nostri dipendenti, collaboratori ed altri soggetti anche esterni, designati Responsabili e/o Incaricati del trattamento o, comunque, operanti quali Titolari, che sono coinvolti nella gestione dei rapporti con lei in essere o che svolgono per nostro conto compiti di natura tecnica, organizzativa, operativa.
    <ul>
      <li>
        per Alleanza Assicurazioni S.p.A. via e-mail all’indirizzo <br><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria all’indirizzo
        RPD Generali Italia, via Marocchesa 14, 31021 Mogliano veneto TV;
      </li>
      <li>
        per Assicurazioni Generali S.p.A. via e-mail all’indirizzo: <br><a href = "mailto: dpoag@generali.com">dpoag@generali.com</a> oppure via posta ordinaria all’indirizzo:
        Assicurazioni Generali S.p.A., Piazza Cordusio n. 2, 20123 Milano, all’attenzione del Data Protection Officer;
      </li>
      <li>
        per Banca Generali S.p.A. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: DataProtectionOfficerBG@bancagenerali.it">DataProtectionOfficerBG@bancagenerali.it</a> <br>oppure via posta
        ordinaria all’indirizzo DPO Banca generali SpA Corso Cavour n. 5/A 34132 Trieste;
      </li>
      <li>
        per GenerFid S.p.A. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: DataProtectionOfficerGENERFID@generfid.it">DataProtectionOfficerGENERFID@generfid.it</a><br>oppure via posta ordinaria
        all’indirizzo DPO GenerFid c/o Banca Generali SpA Corso Cavour n. 5/A 34132 Trieste;
      </li>
      <li>
        per Generali Business Solutions S.c.p.A via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria
        all’indirizzo RPD Generali Italia, via Marocchesa 14, 31021 Mogliano veneto TV
      </li>
      <li>
        per Generali Insurance Asset Management S.p.A via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: DPO.GIAM@generali.com">DPO.GIAM@generali.com</a>;
      </li>
      <li>
        per Generali Investments Partners S.p.A via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: DPO.GIP@generali.com">DPO.GIP@generali.com</a>;
      </li>
      <li>
        per Generali Investments Holding S.p.A via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: DPO.GIH@generali.com">DPO.GIH@generali.com</a>;
      </li>
      <li>
        per Generali Italia S.p.A. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria all’indirizzo RPD
        Generali Italia, via Marocchesa 14, 31021 Mogliano veneto TV;
      </li>
      <li>
        per Generali Jeniot S.p.A. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria all’indirizzo RPD
        Generali Italia, via Marocchesa 14, 31021 Mogliano veneto TV;
      </li>
      <li>
        per Generali Operations Service Platform S.r.l. via e-mail all’indirizzo: <br fxHide fxShow.xs><a href = "mailto: GOSPDPO@generali.com">GOSPDPO@generali.com</a> oppure via posta
        ordinaria all’indirizzo: “Responsabile della Protezione dei Dati GOSP” - Via Machiavelli 3,  34132 Trieste;
      </li>
      <li>
        per Generali Real Estate S.p.A via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: DPO.GRE@generali.com">DPO.GRE@generali.com</a>;
      </li>
      <li>
        per Generali Welion S.c.a.r.l. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria all’indirizzo RPD
        Generali Italia, via Marocchesa 14, 31021 Mogliano veneto TV;
      </li>
      <li>
        per Genertel S.p.A. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria all’indirizzo RPD Generali
        Italia, via Marocchesa 14, 31021 Mogliano veneto TV;
      </li>
      <li>
        per Genertellife S.p.A. via e-mail all’indirizzo <br fxHide fxShow.xs><a href = "mailto: RPD.it@generali.com">RPD.it@generali.com</a> oppure via posta ordinaria all’indirizzo RPD
        Generali Italia, via Marocchesa 14, 31021 Mogliano veneto TV.
      </li>
    </ul>
    </h5>
  <div class="space20"></div>
  <div class="space20"></div>
</div>