import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
    selector: 'item-details-header-actions',
    styleUrls: ['./item-details-header-actions.component.scss'],
    templateUrl: './item-details-header-actions.component.html'
})
export class ItemDetailsHeaderActionsComponent implements OnInit {
    hasMoreEditions: boolean = false;

    @Input() collectionId: string;
    @Input() webCourseId: string;
    @Input() wishlistEnabled: boolean;
    @Input() isSyllabusCourse: boolean;
    @Input() isCatalogStage: boolean;
    @Input() isWebinar: boolean;
    @Input() isExternalCourse: boolean;
    @Input() isOnlineCourse: boolean;
    @Input() showLpContinue: boolean;
    @Input() isPlaylistPlan: boolean;
    @Input() iLikeThis: boolean;
    @Input() iDislikeThis: boolean;
    @Input() firstIncompleteId;
    @Input() completedItems;
    @Input() secondaryActionOptions;
    @Input() completionPercentage;

    _editions;
    @Input() set editions(value) {
        this._editions = value;
        if (this._editions && this._editions.length && this._editions.length > 1) {
            this.hasMoreEditions = true;
        } else {
            this.hasMoreEditions = false;
        }
        this.getUniqueEditionSecondBtnAction();
    };

    _selectedEditionOfMainSelect;
    @Input() set selectedEditionOfMainSelect(value) {
        this._selectedEditionOfMainSelect = value;

        // if (this._selectedEditionOfMainSelect) {
        //     console.log(this._selectedEditionOfMainSelect.courseDateId);
        //     console.log("_selectedEditionOfMainSelect", this._selectedEditionOfMainSelect)
        // }
    };

    _uniqueEditionMainBtnAction;
    @Input() set uniqueEditionMainBtnAction(value) {
        this._uniqueEditionMainBtnAction = value;
    };

    _addedToWishlist;
    @Input() set addedToWishlist(value) {
        this._addedToWishlist = value;
    };

    _isBookmarked;
    @Input() set isBookmarked(value) {
        this._isBookmarked = value;
    };

    _libraryItemSecondBtnAction;
    @Input() set libraryItemSecondBtnAction(value) {
        this._libraryItemSecondBtnAction = value;
    };

    _libraryItemMainBtnAction;
    @Input() set libraryItemMainBtnAction(value) {
        this._libraryItemMainBtnAction = value;
    };

    @Output() scrollIntoPage = new EventEmitter();
    @Output() openWebinarUniqueEditionDetails = new EventEmitter();
    @Output() onCourseMainBtnActionClicked = new EventEmitter();
    @Output() onSecondaryActionClicked = new EventEmitter();
    @Output() onLibraryItemMainBtnActionClicked = new EventEmitter();
    @Output() onLike = new EventEmitter();
    @Output() onDislike = new EventEmitter();
    @Output() toggleWishlist = new EventEmitter();
    @Output() toggleBookmark = new EventEmitter();
    @Output() selectedEditionCallToActionClicked = new EventEmitter();

    uniqueEditionSecondBtnAction: string = '';

    constructor() {
    }

    ngOnInit() {
    }

    scrollToNextItem() {
        let card = document.getElementById(this.firstIncompleteId);
        card.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

    emitScrollIntoPage(data) {
        this.scrollIntoPage.emit(data);
    }

    emitOnSecondaryActionClicked(data) {
        this.onSecondaryActionClicked.emit(data);
    }

    emitOpenWebinarUniqueEditionDetails() {
        this.openWebinarUniqueEditionDetails.emit();
    }

    emitOnLike() {
        this.onLike.emit();
    }

    emitOnDislike() {
        this.onDislike.emit();
    }

    emitOnCourseMainBtnActionClicked(data?) {
        this.onCourseMainBtnActionClicked.emit(data);
    }

    emitOnLibraryItemMainBtnActionClicked(data?) {
        this.onLibraryItemMainBtnActionClicked.emit(data);
    }

    // Verifica se mostrare i pulsanti per un corso monoedizione
    showOneEditonBtnContainer() {
        return ((this.isSyllabusCourse && this._editions && this._editions[0] && this._editions.length === 1) || (this.isSyllabusCourse && this._editions && this._editions.length && this._editions.length === 1 && this.isOnlineCourse));
    }

    // Verifica se il pulsante di wishlist è visibile
    isWishlistBtnVisible() {
        return this.wishlistEnabled && this.isSyllabusCourse && !this.hasMoreEditions && (!this.editions || !this.editions.length);
    }

    // Verifica se il pulsante per aggiungere l'oggetto nei preferiti è visibile
    isBookmarktBtnVisible() {
        // return !this.collectionId && !this.webCourseId;
        return true;
    }

    emitSelectedEditionCallToActionClicked(selectedEdition) {
        this.selectedEditionCallToActionClicked.emit(selectedEdition);
    }

    // Ritorna l'azione secondaria dell'unica edizione disponibile del cm2
    getUniqueEditionSecondBtnAction() {
        const edition = this._editions && this._editions[0];
        if (edition && !edition.isOnlineCourse || edition && edition.isOnlineEvent) {
            if (edition.isInvited && edition.canCancel) {
                // Se l'utente è invitato: "Non puoi partecipare?" (rifiuta l'invito)
                this.uniqueEditionSecondBtnAction = 'DO_YOU_WANT_TO_REFUSE_INVITE';
            } else if (edition.isConfirmed && edition.canCancel) {
                // L'utente ha accettato in precedenza l'invito, e ora può annullarlo
                this.uniqueEditionSecondBtnAction = 'DO_YOU_WANT_TO_REFUSE_INVITE';
            } else if (!edition.isConfirmed && !edition.isInvited && edition.canCancel) {
                // L'utente può solo rifiutare (qui ci può essere il caso di overbooking, anche se non è ancora gestito)
                this.uniqueEditionSecondBtnAction = 'DO_YOU_WANT_TO_REFUSE_INVITE';
            }
        }
    }
}
