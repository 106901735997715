<div fxLayout="row" fxFill fxLayoutAlign="center center">
  <div fxLayout="column" fxFill fxLayoutAlign="center center" *ngIf="scormUrl">
    <iframe height="100%" id="scormPlayerIFrame" width="100%" style="color:#ffffff" class="contentPlayerIFrame"
      frameborder="0" allowfullscreen [src]="scormUrl | safeHtml: 'resourceUrl'" allowtransparency="true"
      #scormIFrame></iframe>
    <br>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="scormPlayerRestartText" translate="itemDetail.RESTART_SCORM_OBJECT"></span>
      <!-- <button (click)="reloadPage()" class="md-raised " aria-label="Refresh">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span translate="itsemDetail.REFRESH_PAGE"></span>
        </div>
      </button> -->
      <custom-button class="height-45" importance="primary" noMargin="true" dimension="btn-regular" (onClick)="reloadPage()"
        [text]="'itemDetail.REFRESH_PAGE'| translate | uppercase"
        [attr.aria-label]="'itemDetail.REFRESH_PAGE'| translate | uppercase">
      </custom-button>
    </div>
  </div>
  <div class="with-after-bg" style="background-image:url({{itemWrapper.itemImage}});" [ngClass]="{'z-index-1': withZIndex}" fxLayout="row" fxFill fxLayoutAlign="center center" *ngIf="!scormUrl">
    <!-- <div fxLayout="row" fxLayoutAlign="center center">
      <a *ngIf="launchEnabled" (click)="launchScorm()">{{startLabel}}</a>
    </div> -->
    <div fxLayout="row" *ngIf="!singletonLock" fxLayoutAlign="center center">
      <custom-button importance="primary" noMargin="true" dimension="btn-regular" (onClick)="launchScorm()"
        [text]="startLabel | uppercase" [attr.aria-label]="startLabel | uppercase">
      </custom-button>
    </div>
    <button *ngIf="!launchEnabled" class="md-raised btn-center md-no-focus" [disabled]="true">
      {{cannotStartLabel}}
    </button>
    <div *ngIf="singletonLock && firstSingletonCheckMade" style="text-align: center;">
      <span class="scormPlayerSingletonError" translate="itemDetail.SINGLETON_SCORM_OBJECT_IN_USE"></span>
    </div>
  </div>
</div>
