/*
 * Azioni per l'autenticazione. Il LOGIN setta semplicemente l'utente come loggato; il DO_LOGIN invece chiamerà i servizi per l'autenticazione a back-end
 *
*/

import { Action } from '@ngrx/store';

import { UserAcknowledges } from 'atfcore-commonclasses/bin/classes/anag';

// Costanti che identificano tutte le azioni
export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGIN_FINISHED = 'DO_LOGIN_FINISHED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const DO_LOGOUT = 'DO_LOGOUT';
export const DO_LOGOUT_NO_REDIRECT = 'DO_LOGOUT_NO_REDIRECT';
export const LOGOUT_FINISHED = 'LOGOUT_FINISHED';
export const SET_USER_AUTHENTICATED = 'SET_USER_AUTHENTICATED';
export const SET_TOKEN = 'SET_TOKEN';
export const DO_TOKEN_LOGIN = 'DO_TOKEN_LOGIN';
export const SET_USER_ACKNOWLEDGES = 'SET_USER_ACKNOWLEDGES';
export const GET_USER_ACKNOWLEDGES = 'GET_USER_ACKNOWLEDGES';
export const RETRIEVE_USER_ACKNOWLEDGES = 'RETRIEVE_USER_ACKNOWLEDGES';
export const UPDATE_USER_ACKNOWLEDGES = 'UPDATE_USER_ACKNOWLEDGES';
export const CHECK_WELCOME_PAGE = 'CHECK_WELCOME_PAGE';
export const WELCOME_PAGE_SHOWED = 'WELCOME_PAGE_SHOWED';
export const SET_IS_MANAGER_GLP = "SET_IS_MANAGER_GLP";
export const SET_IS_TAKER = 'SET_IS_TAKER';
export const SET_IS_MANAGER = "SET_IS_MANAGER";
export const SET_IS_ADMIN = "SET_IS_ADMIN";
export const IS_FETCHING_GLP_AUTHS_FINISHED = "IS_FETCHING_GLP_AUTHS_FINISHED";
export const RETRIEVE_GLP_AUTHS = "RETRIEVE_GLP_AUTHS";

/**
 * Effettua la login
 */
export class DoLogin implements Action {
  readonly type = DO_LOGIN;

  // Payload dell'azione
  constructor(public payload: {
    email: string, password: string,
    isTaker?: boolean;
    isSso?: boolean;
    isManager?: boolean;
    isAdmin?: boolean;
  }) { }
}

export class DoLoginFinished implements Action {
  readonly type = DO_LOGIN_FINISHED;
}

export class SetIsFetchingGlpAuthsFinished implements Action {
  readonly type = IS_FETCHING_GLP_AUTHS_FINISHED;

  constructor(public payload: boolean) { }
}

export class SetIsTaker implements Action {
  readonly type = SET_IS_TAKER;
}

export class SetIsManager implements Action {
  readonly type = SET_IS_MANAGER;
}

export class SetIsAdmin implements Action {
  readonly type = SET_IS_ADMIN;
}

export class CheckWelcomePage implements Action {
  readonly type = CHECK_WELCOME_PAGE;
}

export class WelcomePageShowed implements Action {
  readonly type = WELCOME_PAGE_SHOWED;
}

export class DoTokenLogin implements Action {
  readonly type = DO_TOKEN_LOGIN;

  // Payload dell'azione
  constructor(public payload: { identityToken: string }) { }
}

export class SetUserAcknowledges implements Action {
  readonly type = SET_USER_ACKNOWLEDGES;

  // Payload dell'azione
  constructor(public payload: UserAcknowledges) { }
}

export class GetUserAcknowledges implements Action {
  readonly type = GET_USER_ACKNOWLEDGES;

  // Payload dell'azione
  constructor(public payload: UserAcknowledges) { }
}

export class RetrieveUserAcknowledges implements Action {
  readonly type = RETRIEVE_USER_ACKNOWLEDGES;
}

export class UpdateUserAcknowledges implements Action {
  readonly type = UPDATE_USER_ACKNOWLEDGES;

  // Payload dell'azione
  constructor(public payload: UserAcknowledges) { }
}

export class Login implements Action {
  readonly type = LOGIN;
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class DoLogout implements Action {
  readonly type = DO_LOGOUT;
}

export class DoLogoutNoRedirect implements Action {
  readonly type = DO_LOGOUT_NO_REDIRECT;
}

export class LogoutFinished implements Action {
  readonly type = LOGOUT_FINISHED;
}

export class SetUserAuthenticated implements Action {
  readonly type = SET_USER_AUTHENTICATED;

  constructor(
    public payload?: {
      isTaker?: boolean;
      isManager?: boolean;
      isAdmin?: boolean;
    }
  ) { }
}

export class SetIsManagerGlp implements Action {
  readonly type = SET_IS_MANAGER_GLP;

  constructor(public payload: boolean) { }
}

export class SetToken implements Action {
  readonly type = SET_TOKEN;

  // Payload dell'azione
  constructor(public payload: string) { }
}

export class RetrieveGlpAuths implements Action {
  readonly type = RETRIEVE_GLP_AUTHS;

  constructor() { }
}

// Definisco un tipo custom per eseguire il bundle delle azioni in un singolo export
export type AuthActions = Login
  | Logout
  | DoLogout
  | DoLogoutNoRedirect
  | LogoutFinished
  | SetToken
  | SetUserAuthenticated
  | SetIsTaker
  | SetIsManager
  | SetIsAdmin
  | SetIsManagerGlp
  | CheckWelcomePage
  | WelcomePageShowed
  | DoTokenLogin
  | SetUserAcknowledges
  | GetUserAcknowledges
  | RetrieveUserAcknowledges
  | UpdateUserAcknowledges
  | DoLogin
  | RetrieveGlpAuths
  | DoLoginFinished;
