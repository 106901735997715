import { Pipe, PipeTransform } from '@angular/core';

/**
 * Given a filename and a maximum length,
 * returns a truncated version of the filename.
 * Example:
 *    'my-long-file-name.pdf', 10 => 'my-long-fi...pdf'
 */

@Pipe({
  name: 'truncateFilename'
})
export class TruncateFilenamePipe implements PipeTransform {

  transform(value: string, maxLength: number = 15): string {

    if(value.length <= maxLength) {
      return value;
    }

    // check if the file has an extension
    const lastDotIndex = value.lastIndexOf('.');
    const hasExtension = lastDotIndex > 0;

    if(hasExtension) {
      const extension = value.substring(lastDotIndex);
      const filename = value.substring(0, lastDotIndex);
      const maxFilenameLength = maxLength - extension.length;
      return filename.substring(0, maxFilenameLength) + '...' + extension;
    }

    return value.substring(0, maxLength) + '...';
  }

}
