import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { Lang } from 'atfcore-commonclasses/bin/classes/anag';
import { WelcomePageConfig } from 'atfcore-commonclasses/bin/classes/talent-farm';

import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../../auth/ngrx/auth.actions';

import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/core/modal/modal-services.component';
import { WelcomeService } from 'src/app/core/services/welcome.service';
import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import * as CoreActions from '../../core/ngrx/core.actions';

@Component({
  selector: 'app-first-introduction',
  templateUrl: './first-introduction.component.html',
  styleUrls: ['./first-introduction.component.scss']
})
export class FirstIntroductionComponent extends BaseSubscriberComponent {
  langs: Lang[];
  isAuthenticated: boolean;

  scrollToIntro: Subject<void> = new Subject<void>();
  scrollToCustomization: Subject<void> = new Subject<void>();

  onOpenProfilationModal: Subject<void> = new Subject<void>();

  isLoadingWelcomePage: boolean = true;
  welcomePage: WelcomePageConfig;
  isFirstIntroFromHome: boolean;
  carouselIndex: number;

  get hasVideo(): boolean {
    return this.welcomePage && this.welcomePage.homePageType === 'VIDEO';
  }

  constructor(
    private store: Store<fromApp.AppState>,
    private redirectService: RedirectService,
    private modalService: ModalService,
    private translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    private welcomeService: WelcomeService,
    private location: LocationStrategy) {
    super();

    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.modalService.clearClassForScroll();

    combineLatest([
      this.store.select(fromApp.getAvailableLangs),
      this.store.select(fromApp.isLoginFinished)
    ])
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        ([availableLangs, isLoginFinished]) => {
          this.langs = availableLangs;
          localStorage.setItem('welcomePageShowed', 'true');
          if (this.router.url.indexOf('first-intro-home') !== -1) {
            this.isFirstIntroFromHome = true;
            //   this.getWelcomePage();
          } else if (this.router.url.indexOf('first-intro-mail-confirmation') !== -1) {
            this.isFirstIntroFromHome = false;
            if (isLoginFinished) {
              //    this.getWelcomePage();
              //    this.openProfilationModal();
            }
          } else {
            this.isFirstIntroFromHome = false;
            if (isLoginFinished) {
              //    this.getWelcomePage();
            }
          }

          this.isFirstIntroFromHome = false;
          setTimeout(() => {
            this.openProfilationModal();
          }, 500);
        });
  }

  indexChange(carouselIndex: number) {
    this.carouselIndex = carouselIndex;
  }

  goToLocalLogin() {
    this.redirectService.goToLocalLogin();
  }

  getWelcomePage() {
    this.welcomeService.getWelcomePage()
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (welcomePage) => {
          if (welcomePage.error) {
            this.toastr.error(this.translate.instant("errors." + welcomePage.error));
          } else {
            this.store.dispatch(new AuthActions.WelcomePageShowed);
            this.welcomePage = welcomePage.response;
          }
          this.isLoadingWelcomePage = false;
        }, (err) => {
          this.isLoadingWelcomePage = false;
          if (err && err.message) {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        });
  }

  goToHome() {
    // this.closeProfilationModal();
    const redirectAfterSignIn = sessionStorage.getItem('redirectAfterSignIn');
    const sessionStorageUrl = sessionStorage.getItem('redirectUrl');

    if (redirectAfterSignIn) {
      sessionStorage.removeItem('redirectAfterSignIn');
      this.router.navigate([redirectAfterSignIn]);
    } else if (sessionStorageUrl) {
      this.store.dispatch(new CoreActions.RemoveRedirectUrl())
      this.router.navigate([sessionStorageUrl]);
    } else {
      this.redirectService.goToHome();
    }
  }

  openProfilationModal() {
    this.onOpenProfilationModal.next();
    this.modalService.open("profilationModal");
  }

  closeProfilationModal() {
    this.modalService.close("profilationModal");

    this.goToHome();
  }

}
