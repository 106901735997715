/*
* Wrapper di tutti i reducer dell'applicazione, ossia funzioni pure che trasformano il nostro Stato applicativo
*/

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';

export interface AppState {
  profile: fromProfile.ProfileState;
  auth: fromAuth.AuthState;
  core: fromCore.CoreState;
}

export const reducers: ActionReducerMap<AppState> = {
  profile: fromProfile.profileReducer,
  auth: fromAuth.authReducer,
  core: fromCore.coreReducer
};

// Grazie al metodo createFeatureSelector, siamo in grado di ottenere facilmente un riferimento ad una proprietà dello State. Una volta ottenuto tale riferimento,
// possiamo quindi comporlo con altri selettori che puntano a proprietà dello State di livello più basso attraversando in modo efficace il tree dello State.
// E' un feature selector, che ci torna uno slice dello State corrispondente ad AppState
export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('profile');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');

// Per ottenere riferimenti alle proprietà più in basso nel tree dello State, utilizziamo il createSelector
// Selettori relativi allo state dell'auth
export const getAuthState = createSelector(
  getAuthAppState, // Passato come primo parametro affinché possiamo iniziare la nostra ricerca nello State da questo punto. Possiamo passare più State
  (state: fromAuth.AuthState) => state // Projector function è passata come argomento ai valori restituiti dei selettori indicati e nell'ordine in cui sono stato fatto
);
export const getToken = createSelector(
  getAuthState,
  fromAuth.getToken
);
// Selettori relativi allo state del core
export const getCoreState = createSelector(
  getCoreAppState,
  (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authenticated
);
export const isTaker = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.isTaker
);
export const isFetchingGlpAuths = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.isFetchingGlpAuths
);
export const isManagerGlp = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.isManagerGlp
);
export const isLoginFinished = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.loginFinished
);
export const getClusters = createSelector(
  getCoreAppState,
  fromCore.getClusters
);
export const isFetchingClusters = createSelector(
  getCoreAppState,
  fromCore.isFetchingClusters
);
export const isWelcomePageShowed = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.welcomePageShowed
);
export const getUserAcknowledges = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.userAcknowledges
);
export const isFetchingLangs = createSelector(
  getCoreAppState,
  fromCore.isFetchingLangs
);
export const getRedirectUrl = createSelector(
  getCoreAppState,
  fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
  getCoreAppState,
  fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
  getCoreAppState,
  fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
  getCoreAppState,
  fromCore.showApplicationLoader
);
export const getApplicationLang = createSelector(
  getCoreAppState,
  fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
  getCoreAppState,
  fromCore.getDefaultLang
);
export const getCategories = createSelector(
  getCoreAppState,
  fromCore.getCategories
);

// Selettori relativi allo state del profilo
export const getProfileState = createSelector(
  getProfileAppState,
  (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
  getProfileState,
  fromProfile.getLoggedUser
);
export const getAvailableLangs = createSelector(
  getProfileState,
  fromProfile.getAvailableLangs
);

export const getScreenSize = createSelector(
  getCoreAppState,
  fromCore.getScreenSize
);
export const getScreenWidth = createSelector(
  getCoreAppState,
  fromCore.getScreenWidth
);
