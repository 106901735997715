<div class="searchbar-container" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{'lighter': _lighter}">
  <form class="input-search-container" [ngClass]="{'for-leaderboard': isForLeaderboard,'header-input': _isHeaderSearch, 'dark-mood': _headerDarkMood,
  'for-home': _forHome}" fxLayout="row" fxLayoutAlign="space-between center" (ngSubmit)="onSubmit()">
    <input [id]="_id" (ngModelChange)="emitOnModelChanged($event)" (blur)="onBlurEvent.emit($event)" type="text"
      name="search" [placeholder]="placeholder" [(ngModel)]="bindValue"
      [ngClass]="{'for-leaderboard': isForLeaderboard,'bold': _isHeaderSearch && inputIsFilled, 'filled': !_isHeaderSearch && inputIsFilled}"
      [attr.name]="attrName" [attr.aria-label]="attrAriaLabel">
    <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
      <svg-icon (click)="onSubmit()" src="../../../assets/icons/icon-search.svg"></svg-icon>
      <svg-icon *ngIf="showClear" class="margin-icon" (click)="clearText()" src="../../../assets/icons/icon-close.svg">
      </svg-icon>
    </div>
  </form>
</div>