/*
 * Componente che crea il componente scorm
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  Renderer2,
} from "@angular/core";
import { Router } from "@angular/router";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { timer, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { ItemTypes } from "atfcore-commonclasses/bin/classes/item";
import { CourseItem } from "atfcore-commonclasses/bin/classes/talent-farm";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { ScormRegistration } from "atfcore-commonclasses/bin/classes/scorm";

import { ScormService } from "./scorm.service";
import { AnalyticsService } from "src/app/shared/services/analytics.service";

@Component({
  selector: "scorm-player",
  templateUrl: "./scorm-player.component.html",
  styleUrls: ["./scorm-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ScormPlayerComponent implements OnInit, OnDestroy {

  _playerVisible?: boolean;
  @Input() set playerVisible(value: boolean | string) {
    this._playerVisible = coerceBooleanProperty(value);
  }

  @Input() itemWrapper: CourseItem;
  @Input() editions?;
  @Input() stageId?: string;
  @Input() scormId?: string;
  @Input() withZIndex?: boolean;
  @Input() isCourseExpired?: boolean;
  @Output() checkEnableCertificaion = new EventEmitter<any>();

  @Input() projectId?: string;

  @ViewChild("scormIFrame") public scormIFrame: ElementRef;

  launchEnabled: boolean;
  requireSingletonCheck: boolean;
  firstSingletonCheckMade: boolean;
  singletonLock: boolean;
  startLabel: string;
  cannotStartLabel: string;
  adminMode: boolean;
  private _destroy$ = new Subject();
  isSingletonInUse$: Subscription;
  markSingletonInUse$: Subscription;
  getScormPreviewUrl$: Subscription;
  getScormLaunchUrl$: Subscription;
  createScormRegistration$: Subscription;
  scormUrl: string;
  virtualContentPlayEventSent: boolean = false;

  constructor(
    private analyticsService: AnalyticsService,
    protected _sanitizer: DomSanitizer,
    private translate: TranslateService,
    private scormService: ScormService,
    private toastr: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) { }

  isChrome() {
    return (
      /Chrome/.test(window.navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor)
    );
  }

  ngOnInit() {
    // Sto in ascolto di quando l'evento scorm finisce
    window.addEventListener(
      "message",
      (event) => {
        if (
          event.data === "SCORM_CONSUMED" &&
          <string>typeof this.checkEnableCertificaion != "null" &&
          typeof this.checkEnableCertificaion != "undefined"
        ) {
          this.checkEnableCertificaion.emit();
        }
      },
      false
    );

    this.launchEnabled = false;
    this.requireSingletonCheck = false;
    this.firstSingletonCheckMade = false;
    this.singletonLock = true;

    // Verifica se sono nella parte amministrativa
    if (this.router.url.indexOf("Admin") !== -1) {
      this.adminMode = true;
    } else {
      this.adminMode = false;
    }

    this.startLabel = this.translate.instant("itemDetail.START_SCORM_OBJECT");
    this.cannotStartLabel = this.translate.instant(
      "itemDetail.CANNOT_START_SCORM_OBJECT"
    );

    if (this.isCourseExpired) {
      this.cannotStartLabel = this.translate.instant(
        "itemDetail.CANNOT_START_SCORM_OBJECT_IS_EXPIRED"
      );
    }

    if (this._playerVisible) {
      this.scormId =
        this.scormId ||
        (this.itemWrapper &&
          this.itemWrapper.scormId);

      // this.requireSingletonCheck = this.itemWrapper && this.itemWrapper.requireSingletonCheck;
      // Vedo se posso abilitare l'utente a lanciare l'oggetto o meno
      if (
        // (this.itemWrapper && this.itemWrapper.scormRegistration) ||
        (this.itemWrapper &&
          this.itemWrapper.itemType === ItemTypes.SCORM_FREE) ||
        this.adminMode
      ) {
        this.launchEnabled = true;
        // Se l'oggetto non richiede la verifica per i singleton, allora spengo il lock,
        // altrimenti lancio il listener per vedere se ci sono altri singleton in esecuzioe
        if (this.requireSingletonCheck) {
          timer(0, 2000)
            .pipe(takeUntil(this._destroy$))
            .subscribe((t) => this.isSingletonInUse());
        } else {
          this.singletonLock = false;
          // this.launchScorm();
        }
      }
    }
  }
  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next();
    }
    if (this.isSingletonInUse$) {
      this.isSingletonInUse$.unsubscribe();
    }
    if (this.markSingletonInUse$) {
      this.markSingletonInUse$.unsubscribe();
    }
    if (this.getScormPreviewUrl$) {
      this.getScormPreviewUrl$.unsubscribe();
    }
    if (this.getScormLaunchUrl$) {
      this.getScormLaunchUrl$.unsubscribe();
    }
    if (this.createScormRegistration$) {
      this.createScormRegistration$.unsubscribe();
    }
  }

  createScormRegistration(): Promise<SenecaResponse<ScormRegistration>> {
    return new Promise((resolve, reject) => {
      // Il wrapper, nel caso di corsi, è lo stage
      this.stageId = this.stageId || (this.itemWrapper && this.itemWrapper.itemId);
      let editionId: string | string[] = null;
      if (this.editions && this.editions.length) {
        editionId = this.editions.map((edition) => edition.courseDateId);
        if (editionId && !this.stageId) {
          // Se sono in questo significa che sono nel dettaglio di un oggetto aggiunto ad un contenitore, il quale è wrappato da una iniziativa online
          // Potrei essere nel caso in cui sono nel dettaglio di un oggetto di un LP aggiunto ad un corso del cm2:
          // se il syllabus è associato anche ad una rilevazione, si corre il rischio che
          // this.editions[0].stagItemId contenga il valore "stageId" della rilevazione e non dell'iniziativa principale;
          // per ovviare a questo problema è stato aggiunto "projectId", poiché nella casistica appena descritta
          // il projectId è il valore della rilevazione principale, essendo l'url:
          // itemDetailSec/:projectId/:sectionId/:itemId.
          // Questo è valido solo nei casi in cui ci sia un corso online cm2.
          this.stageId = this.projectId ? this.projectId : this.editions[0].stageItemId;
        }
      } else {
        editionId = this.itemWrapper && this.itemWrapper.editionId;
      }
      // Se l'oggetto è un corso, allora il libraryItem è l'oggetto Item che contiene lo scorm (es: SCORM_FREE)
      const libraryItemId = this.itemWrapper && (<any>this.itemWrapper).libraryItem && (<any>this.itemWrapper).libraryItem.itemId;
      let itemId = (this.itemWrapper && this.itemWrapper.libraryItemId) || libraryItemId;

      // TODO: Verificare se qui posso arrivare con oggetti che non siano corsi e in caso adattare la logica
      // Se non è un corso, probabilmente, il suo ID è l'item che punta allo scorm (es: SCORM_FREE)
      if (!itemId) {
        itemId = this.itemWrapper && this.itemWrapper.itemId;
      }
      // Fine TODO

      this.createScormRegistration$ = this.scormService
        .createScormRegistration(this.scormId, itemId, editionId, this.stageId)
        .subscribe((data) => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
            reject();
          } else {
            resolve(data);
          }
        });
    });
  }

  getScormLaunchUrl(): Promise<SenecaResponse<string>> {
    return new Promise((resolve, reject) => {
      this.getScormLaunchUrl$ = this.scormService
        .getScormLaunchUrl(
          this.itemWrapper.scormRegistration.registrationId,
          this.requireSingletonCheck
        )
        .subscribe((data) => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
            reject();
          } else {
            resolve(data);
          }
        });
    });
  }

  // Preview amministratore
  getScormPreviewUrl(): Promise<SenecaResponse<string>> {
    return new Promise((resolve, reject) => {
      this.getScormPreviewUrl$ = this.scormService
        .getScormPreviewUrl(this.scormId)
        .subscribe((data) => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
            reject();
          } else {
            resolve(data);
          }
        });
    });
  }

  // Script per il caricamento della pagina
  reloadPage() {
    window.location.reload();
  }

  isSingletonInUse() {
    this.isSingletonInUse$ = this.scormService
      .isSingletonInUse()
      .subscribe((data) => {
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant("errors." + data.error));
          // Se va male, assumo che l'oggetto sia bloccato
          this.singletonLock = true;
        } else {
          // Predo lo stato che mi è arrivato
          this.singletonLock = !!data.response;
        }
        // Mi segno che ho fatto la richiesta al server
        this.firstSingletonCheckMade = true;
        if (!this.singletonLock) {
          //this.launchScorm();
        }
        this.cdr.detectChanges();
      });
  }

  markSingletonInUse() {
    this.markSingletonInUse$ = this.scormService
      .markSingletonInUse()
      .subscribe();
  }

  manageFrame() {
    setTimeout(() => {
      const iframDoc = this.scormIFrame && this.scormIFrame.nativeElement;
      if (iframDoc) {
        let actualDocument = iframDoc.contentDocument && iframDoc.contentDocument.activeElement && iframDoc.contentDocument.activeElement.children && iframDoc.contentDocument.activeElement.children[1] && iframDoc.contentDocument.activeElement.children[1].children[3] && iframDoc.contentDocument.activeElement.children[1].children[3].children[1] && iframDoc.contentDocument.activeElement.children[1].children[3].children[1].contentDocument;
        if (actualDocument && actualDocument.querySelector("#PopupBlocked")) {
          let inputs = actualDocument.querySelector("#PopupBlocked").getElementsByTagName("input");
          if (inputs && inputs.length) {
            for (
              let i = 0, inputsLength = inputs.length;
              i < inputsLength;
              i++
            ) {
              let currentInput = inputs[i];
              currentInput.style.color = "#fff";
              currentInput.style.backgroundColor = "#C5281C";
              currentInput.style.width = "150px";
              currentInput.style.height = "40px";
              currentInput.style.border = "0px";
              currentInput.style.borderRadius = "4px";
            }
          }
        }
      };
    }, 3000);
  }

  launchScorm() {
    // Se sono amministratore, lo lancio in modalità preview
    if (this.adminMode) {
      let getScormUrlPromise = this.getScormPreviewUrl();

      getScormUrlPromise.then((data: SenecaResponse<string>) => {
        if (data && (data.error || !data.response)) {
          this.toastr.error(this.translate.instant("error.generic.MESSAGE"));
        } else {
          // Salvo l'url, cosa che aprirà l'iFrame che a sua volta avvierà l'oggetto
          this.scormUrl = data.response;
          this.cdr.detectChanges();
          this.manageFrame();
        }
      });
    } else if (this.itemWrapper.scormRegistration) {

      // Invio il tracciamento dell'apertura dello scorm ad analytics
      if (!this.virtualContentPlayEventSent) {
        this.virtualContentPlayEventSent = true;
        this.analyticsService.sendVirtualContentPlayEvent(this.itemWrapper.itemId);
      }

      // Se ho già una registrazione, apro direttamente l'oggetto
      let getScormLaunchUrlPromise = this.getScormLaunchUrl();
      getScormLaunchUrlPromise.then((data: SenecaResponse<string>) => {
        if (data && (data.error || !data.response)) {
          // Dati non validi, quindi alzo l'errore
          this.toastr.error(this.translate.instant("error.generic.MESSAGE"));
        } else {
          // Salvo l'url, cosa che aprirà l'iFrame che a sua volta avvierà l'oggetto
          this.scormUrl = data.response;
          // E quindi marco anche il fatto che l'oggetto è in uso (periodicamente ogni 2 secondi dato che la registrazione ne dura 3)
          if (this.requireSingletonCheck) {
            timer(0, 2000)
              .pipe(takeUntil(this._destroy$))
              .subscribe((t) => this.markSingletonInUse());
          }
          this.cdr.detectChanges();
          this.manageFrame();
        }
      });
    } else {
      // Se non ce l'ho, allora verifico se l'oggetto è di consumo libero, nel qual caso la creo
      if (this.itemWrapper.itemType === ItemTypes.SCORM_FREE) {

        // Invio il tracciamento dell'apertura dello scorm ad analytics
        if (!this.virtualContentPlayEventSent) {
          this.virtualContentPlayEventSent = true;
          this.analyticsService.sendVirtualContentPlayEvent(this.itemWrapper.itemId);
        }

        let createScormRegistrationPromise = this.createScormRegistration();
        createScormRegistrationPromise.then(
          (data: SenecaResponse<ScormRegistration>) => {
            if (data && (data.error || !data.response)) {
              // Dati non validi, quindi alzo l'errore
              this.toastr.error(
                this.translate.instant("error.generic.MESSAGE")
              );
            } else {
              // Salvo la registration
              this.itemWrapper.scormRegistration = data.response;
              // E richiamo questo stesso metodo per finire nell'altro ramo dell'if
              this.launchScorm();
            }
          }
        );
      } else {
        // Errore, qui non ci dovrei nemmeno arrivare
        this.toastr.error(this.translate.instant("error.generic.MESSAGE"));
      }
    }
  }
}
