<app-modal class="modal-badge" [id]="id">
  <div class="container" fxLayout="column" fxLayoutAlign="space-between center">

    <div fxLayout="column" fxLayoutAlign="start center">
      <!-- BADGE ICON -->
      <svg-icon [src]="currentModal.iconSrc" [ngClass]="{'profile': currentModal.id === 'modal-badge-profile'}">
      </svg-icon>
      <!-- NEW BADGE -->
      <h5 fxFlexOffset="28px" fxFlexOffset.xs="24px" class="bold" translate="badge.NEW_BADGE"></h5>
      <!-- TITLE -->
      <h3 fxFlexOffset="12px" fxFlexOffset.xs="16px" class="bold" [translate]="currentModal.title"></h3>
    </div>

    <!-- CONTINUE -->
    <custom-button ngClass.xs="full-width" [text]="'generic.CONTINUE_UPPER' | translate" (onClick)="closeModalBadge()">
    </custom-button>

  </div>
</app-modal>

<app-modal class="modal-badge star" [id]="_idStar">
  <div class="container" fxLayout="column" fxLayoutAlign="space-between center">

    <div fxLayout="column" fxLayoutAlign="start center">
      <!-- ICON STAR -->
      <svg-icon class="star" src="../../../assets/icons/badge/icon-star.svg"></svg-icon>
      <!-- COINS -->
      <h5 fxFlexOffset="34px" class="bold" translate="badge.COINS"></h5>
      <!-- TITLE -->
      <h3 fxFlexOffset="12px" fxFlexOffset.xs="16px" class="bold">+{{currentModal.coins}}</h3>
    </div>

    <!-- CONTINUE -->
    <custom-button ngClass.xs="full-width" [text]="'generic.CONTINUE_UPPER' | translate"
      (onClick)="closeModalBadgeStar()">
    </custom-button>

  </div>
</app-modal>
