import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewEncapsulation
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { PlayerService } from "./player.service";

declare let kWidget: any;

export interface VideoProgress {
  currentTime: number,
  totalTime: number
}

export type PlayerEventState = 'playing' | 'paused' | 'viewed' | 'seeked' | 'end';

@Component({
  selector: "kaltura-player",
  templateUrl: "./kaltura-player.component.html",
  styleUrls: ["./kaltura-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KalturaPlayerComponent implements OnDestroy {
  @Input() itemId: string;
  @Input() courseId: string;
  @Input() itemTitle: string;
  @Input() customId: string = "contentPlayer";

  private _playerVisible: boolean;
  @Input() set playerVisible(value: boolean | string) {
    this._playerVisible = coerceBooleanProperty(value);
  };

  @Input() seekTo: number;
  @Output() itemConsumed = new EventEmitter<any>();

  /**
   * Lo script può essere di due tipi: Auto o Dynamic.
   * Se è Dynamic allora è composto da 2 pezzi.
   */
  private _baseScript: string;
  private _dynamicScript: string;
  @Input() set kalturaScript(value: string) {
    if (value) {
      this._listenerRegistrated = false;
      this._ignoreFirstSeek = false;
      let kalturaScriptParts = value.split("$$|||$$");
      if (kalturaScriptParts.length === 2) {
        this._baseScript = kalturaScriptParts[0];
        this._dynamicScript = kalturaScriptParts[1];
      } else {
        this._baseScript = value;
      }

      if (this._playerVisible) {
        // Appendo lo script di kaltura
        this.loadScript(this._baseScript).subscribe(result => {
          // Se il widget è dinamico appendo il secondo pezzo di script, che crea il player vero e proprio
          if (this._dynamicScript) {
            this.loadScript(this._dynamicScript).subscribe(dynamic => {
              this.registerKalturaHooks();
            });
          } else {
            // E chiamo il metodo che registra gli hooks
            this.registerKalturaHooks();
          }

        });
      }
    }
  };

  kdp: any;

  private _timeout: NodeJS.Timeout;
  private _lastUpdate: number;

  private _ignoreFirstSeek: boolean = false;
  private _totalTime: number;

  private _listenerRegistrated: boolean = false;

  get getContentPlayer(): any { return document.getElementById(this.customId) };

  get currentTime(): number { return this.kdp.evaluate("{video.player.currentTime}") };

  @Output() videoInProgress = new EventEmitter<VideoProgress>();
  @Output() videoConsumed = new EventEmitter<boolean>();

  private _unsubscribeSignal$: Subject<void> = new Subject();

  playedVideoAnalyticsEventSent: boolean = false;

  constructor(
    private analyticsService: AnalyticsService,
    private playerService: PlayerService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef) {
  }

  /**
   * Alla distruzione dello scope, va fatto l'unbind degli eventi
   * per evitare che tutti gli oggetti visitati riceveranno tutti gli eventi
   */
  ngOnDestroy() {

    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();

    // Dato che Kaltura sembra non eliminare i listener correttamente,
    // elimino l'itemId dallo scope, così da impedire l'invio di notifiche multiple.
    this.itemId = null;
    clearTimeout(this._timeout);
    // Poi distruggo il widget
    if (this.kdp && typeof this.kdp.kUnbind === "function") {
      this.kdp.kUnbind(".allListener");
      if (this._dynamicScript) {
        // kWidget può generare un errore nel caso in cui
        // sia già distrutto
        try {
          if (kWidget) {
            kWidget.destroy(this.kdp);
            kWidget = null;
          }
        } catch { }
      }
    }
  }

  /**
   * Carica lo script
   * @param scriptText
   * @returns
   */
  private loadScript(scriptText: string): Observable<void> {
    return new Observable(observable => {

      let script = document.createElement("script") as any;
      script.type = "text/javascript";

      //create the DOM element
      let scriptSrc: Element = this.renderer.createElement("div");
      scriptSrc.innerHTML = scriptText;
      let scriptElem = scriptSrc.querySelector("script");

      script.src = scriptElem && scriptElem.src;
      script.async = true;

      if (script.readyState) {
        //IE
        script.onreadystatechange = () => {
          if (script.readyState === "loaded" || script.readyState === "complete") {
            script.onreadystatechange = null;
            observable.next();
            observable.complete();
          }
        };
      } else {
        //Others
        script.onload = () => {
          observable.next();
          observable.complete();
        };
      }
      script.onerror = (error: any) => {
        observable.error();
      }

      document.getElementsByTagName("head")[0].appendChild(script);
    });
  }

  /**
   * Registra i listeners
   */
  private registerKalturaHooks = () => {

    this.kdp = this.getContentPlayer;

    // Se i listener non sono registrati aspetto che l'iframe venga caricato
    if (!this._listenerRegistrated) {

      if (this.kdp && typeof this.kdp.kBind === "function") {

        clearTimeout(this._timeout);

        this._listenerRegistrated = true;
        this._ignoreFirstSeek = false;

        this._totalTime = this.kdp.evaluate("{duration}");

        // Registro i listeners
        this.kdp.kBind("playerStateChange.allListener", this.onPlayerStateChange);
        this.kdp.kBind("playerUpdatePlayhead.allListener", this.onPlayerUpdatePlayhead);
        this.kdp.kBind("seeked.allListener", this.onSeeked);
        this.kdp.kBind("playerPlayEnd.allListener", this.onPlayerPlayEnd);

      } else {
        // Ricarico aspettando che lo script si carichi
        this._timeout = setTimeout(() => {
          this.cdr.detectChanges();
          this.registerKalturaHooks();
        }, 200);
      }
    }
  }

  /**
   * Triggered when the player change his state
   * @param eventName Event name
   */
  private onPlayerStateChange = (eventName: PlayerEventState) => {
    if (eventName === 'playing' || eventName === 'paused') {
      // Tengo traccia in analytics del fatto che il video è stato avviato
      if (!this.playedVideoAnalyticsEventSent) {
        this.playedVideoAnalyticsEventSent = true;

        this.analyticsService.sendVirtualContentPlayEvent(this.courseId);
      }
      const currentTime = this.currentTime;
      // Quando arriva alla fine l'oggetto torna in paused con currentTime prossimo
      // o superiore a totalTime, quindi questa combinazione la ignoro
      if (!(eventName === 'paused' && currentTime >= this._totalTime * 0.99)) {
        this.callPlayerService(eventName, currentTime);
        this.videoInProgress.emit({ currentTime: currentTime, totalTime: this._totalTime });
      }
    }
  }

  /**
   * Questo evento viene alzato quando il video aggiorna la barra di stato.
   * Va tracciato questo evento e non il playerReady perché sui dispositivi mobile e spesso
   * anche sui browser si pianta.
   * Kaltura suggerisce di tracciare il cambio della barra di stato come da esempio:
   * http://player.kaltura.com/modules/KalturaSupport/tests/SeekApi.qunit.html
   * Se devo quindi riprendo da dov'ero
   */
  private onPlayerUpdatePlayhead = () => {
    if (this.seekTo > 0) {
      this._ignoreFirstSeek = true;
      this.kdp.sendNotification("doSeek", this.seekTo);
      this.seekTo = null;
      this._lastUpdate = new Date().getTime();
    } else {
      // Se sono passati più di 5 secondi dall'ultima volta che sono passato per questo evento,
      // invio lo stato di avanzamento al sistema
      const currentTime = this.currentTime;
      const now = new Date().getTime();
      // Evito di salvarmi le informazione di avanzamento quando sono troppo prossimo
      //alla fine per evitare conflitti con il completato
      if ((!this._lastUpdate || this._lastUpdate + 5000 < now) && currentTime < this._totalTime * 0.95) {
        this.callPlayerService('viewed', currentTime);
      }
    }
  }

  /**
   * Quando il video viene mandato avanti
   * L'evento può scattare anche in maniera programmatica
   * @param seeked Tempo in secondi
   */
  private onSeeked = (seeked: number) => {
    // console.log('seeked: ' + seeked);
    if (this._ignoreFirstSeek) {
      this._ignoreFirstSeek = false;
    } else {
      if (seeked || seeked === 0) {
        this.callPlayerService('seeked', seeked);
      } else {
        console.log('seeked not valid');
      }
    }
  }

  /**
   * Quando il video termina
   */
  private onPlayerPlayEnd = () => {
    // Segnalo il termine della fruizione
    if (this.videoConsumed) {
      this.videoConsumed.emit(true);
    }
    this.callPlayerService('end', this.currentTime);
  }

  /**
   * Chiama il servizio per salvare lo visione corrente
   * @param eventName Event name
   * @param totalTime Total time
   * @param currentTime Current timing
   */
  private callPlayerService(eventName: PlayerEventState, currentTime: number) {

    this._lastUpdate = new Date().getTime();

    if (this.itemId) {
      this.playerService.updatePlayerStatus(this.itemId, eventName, this._totalTime, currentTime)
        .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
        .subscribe();

      if (eventName == 'end') {
        setTimeout(() => {
          this.itemConsumed.emit(true);
        }, 500)
      }
    }
  }

}
