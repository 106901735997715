import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RedirectService } from 'src/app/shared/services/redirect.service';

@Component({
  selector: 'modal-coin-content',
  templateUrl: './modal-coin-content.component.html',
  styleUrls: ['./modal-coin-content.component.scss']
})

export class ModalCoinContentComponent {
  @Input() coinsNumber: any;
  @Output() closeFunction = new EventEmitter<any>();

  constructor(public redirectService: RedirectService) {
  }

  // Chiude la modale
  closeModalBadgeStar(): void {
    this.closeFunction.emit();
  }
}