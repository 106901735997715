<div *ngFor="let item of list; let index = index; trackBy: trackBy">
  <div [id]="'expandable-list-divider' + index" class="divider"></div>
  <div fxLayout="row">
    <div fxFlex fxLayout="column">
      <h5 tabindex="0" class="cursor-pointer" (click)="openCloseItem(index)">{{item.title}}</h5>
      <h6 tabindex="0" *ngIf="item.expanded">{{item.description}}</h6>
    </div>
    <svg-icon class="cursor-pointer" fxFlexOffset="40px" fxFlexOffset.xs="24px" *ngIf="!item.expanded"
      (click)="openItem(index)" src="../../../../assets/icons/icon-plus2.svg"></svg-icon>
    <svg-icon class="cursor-pointer" fxFlexOffset="40px" fxFlexOffset.xs="24px" *ngIf="item.expanded"
      (click)="closeItem(index)" src="../../../../assets/icons/icon-minus.svg"></svg-icon>
  </div>

</div>