/*
 * Servizio che verifica dallo State, unica fonte di verità, se l'utente è correttamente loggato e autenticato
 */

// Import delle azioni dell'auth
import * as AuthActions from "../ngrx/auth.actions";
// Import delle azioni del core
import * as CoreActions from "../../core/ngrx/core.actions";
// Import dello State dell'applicativo
import * as fromApp from "../../ngrx/app.reducers";
// Import del tipo di informazione che estrapolerò dallo State
import * as fromAuth from "../ngrx/auth.reducers";

import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { map, take } from "rxjs/operators";

import { AuthService } from "./auth.service";
import { Injectable } from "@angular/core";
import { LangsService } from "src/app/core/services/langs.service";
import { Store } from "@ngrx/store";

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<fromApp.AppState>,
    private langsService: LangsService,
    private authService: AuthService,
    private router: Router
  ) { }

  // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return (
      this.store
        .select("auth")
        // Con il select() eseguiamo una subscription allo State, e quindi si trigghera ogni volta che ci sono delle modifiche; per
        // ovviare a tale comportamento sfruttiamo il take(1). (Il metodo pipe() invece è richiesto da RxJS6+)
        .pipe(
          take(1),
          map((authState: fromAuth.AuthState) => {
            let url = state.url;

            let skipSso: boolean;

            if (
              authState.authenticated &&
              this.isValidEntryPoint(url, authState)
            ) {
              // Utente correttamente loggato e può accedere alla risorsa
              return true;
            }

            // Potrebbe essere stato fatto un refresh, di conseguenza non c'è più il dato all'interno dello State. Guardo quindi se ho il token in sessione, perché se ce l'avessi significa che l'utente è già autenticato
            let sessionStorageToken: string = sessionStorage.getItem("token");
            let sessionStorageTakerCid = sessionStorage.getItem("takerCid");

            let localStorageToken: string = localStorage.getItem("token");

            if (
              !sessionStorageToken &&
              !sessionStorageTakerCid &&
              localStorageToken
            ) {
              sessionStorage.setItem("token", localStorageToken);
              sessionStorageToken = localStorageToken;
              localStorage.removeItem("token");
            }

            // Potrebbe essermi arrivato il parametro "skipSso" nell'url, che mi avverte di forzare il redirect dell'utente non loggato al local login
            const query =
              window.location.search.substring(1) ||
              window.location.hash.substring(1);
            const vars = query.split("&");
            let skipSsoParam;
            let ssortkqpParam;
            let urlToken: string = null;
            if (vars && vars.length) {
              for (let i = 0; i < vars.length; i++) {
                let pair = vars[i].split("=");
                if (
                  decodeURIComponent(pair[0]) == "skipSso" ||
                  pair[0].includes("skipSso")
                ) {
                  skipSsoParam = decodeURIComponent(pair[1]);
                  break;
                }

                if (
                  decodeURIComponent(pair[0]) == "token" ||
                  pair[0].includes("token")
                ) {
                  urlToken = decodeURIComponent(pair[1]);
                  sessionStorage.setItem("token", urlToken);
                  sessionStorageToken = urlToken;
                  break;
                } else if (
                  decodeURIComponent(pair[0]) == "ssortkqp" ||
                  pair[0].includes("ssortkqp")
                ) {
                  ssortkqpParam = decodeURIComponent(pair[1]);
                  break;
                }
              }
            }

            if (skipSsoParam) {
              url = removeURLParameter(url, "skipSso");
              if (skipSsoParam == "true" || skipSsoParam == true) {
                skipSso = true;
              }
            }

            if (ssortkqpParam && !urlToken) {
              if(url) {
                url = decodeURIComponent(url);
                url = removeURLParameter(url, "ssortkqp");
              }

              let getTokenFromKeyPromise = this.getTokenFromSsortkqp(
                ssortkqpParam
              );
              getTokenFromKeyPromise.then((token: any) => {
                if (token && token.length) {
                  sessionStorage.setItem("token", token);
                  sessionStorageToken = sessionStorage.getItem("token");
                }

                // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
                if (url) {
                  // Poiché c'è un bug di Chrome (o di Angular, o di Ngnix, chi lo sa), accade che, dopo il ritorno nell'applicazione dall'sso, i path params (ssortkq incluso) vengono encodati. Così, però, angular porta alla pagina 404 not found. Dunque rimuovo il parametro dall'url
                  url = decodeURIComponent(url);
                  url = removeURLParameter(url, "ssortkqp");
                  if (this.isValidEntryPoint(url, authState)) {
                    this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                    // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                    sessionStorage.setItem("redirectUrl", url);
                  }
                }

                if (sessionStorageTakerCid) {
                  this.store.dispatch(
                    new AuthActions.DoLogin({
                      email: sessionStorageTakerCid,
                      password: null,
                      isTaker: true,
                    })
                  );
                } else if (sessionStorageToken) {
                  this.store.dispatch(new AuthActions.SetUserAuthenticated());
                  // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                  this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                  return true;
                } else {
                  // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                  let url = state.url;
                  let isTaker = false;

                  if (url) {
                    if (skipSsoParam) {
                      url = removeURLParameter(url, "skipSso");
                      if (skipSsoParam == "true" || skipSsoParam == true) {
                        skipSso = true;
                      }
                    }
                    this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                  }

                  if (!isTaker) {
                    if (skipSso) {
                      this.router.navigate(["/localLogin"]);
                    } else {
                      // Faccio in modo che i query params vengano propagati anche al redirect verso il login, così che non vadano persi.
                      // Questi possono includere i parametri "utm_*" di Google Analytics.
                      const queryParams = vars?.reduce((obj, str) => {
                        const [key, value] = str.split("=");
                        obj[key] = value;
                        return obj;
                      }, {});
                      this.router.navigate(["/login"], {
                        queryParams: queryParams
                      });
                    }
                  }
                }
                return false;
              });
            } else {
              // Salvataggio se dell'url di redirect se questo è tra gli entrypoint consentiti
              if (url) {
                if (this.isValidEntryPoint(url, authState)) {
                  this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                  // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                  sessionStorage.setItem("redirectUrl", url);
                }
              }

              let isSsoTaker = false;
              // Se sono un taker ma ho eseguito il login da sso avrò un token valido; pertanto devo basarmi sulla pagina di destinazione (courseSubscription/sso) per sapere se sono un take o un admin
              if (
                (url && url.indexOf("courseSubscription/sso") !== -1) ||
                (url && url.indexOf("survey/sso") !== -1)
              ) {
                isSsoTaker = true;
              }

              if (sessionStorageTakerCid) {
                this.store.dispatch(
                  new AuthActions.DoLogin({
                    email: sessionStorageTakerCid,
                    password: null,
                    isTaker: true,
                  })
                );
              } else if (sessionStorageToken) {
                if (isSsoTaker) {
                  this.store.dispatch(
                    new AuthActions.DoLogin({
                      email: sessionStorageToken,
                      password: null,
                      isTaker: true,
                      isSso: true,
                    })
                  );
                } else {
                  this.store.dispatch(new AuthActions.SetUserAuthenticated());
                  // Ri inizio il polling per il token. L'effect si occuperà anche di settare il nuovo token e di decodificarlo
                  this.store.dispatch(new CoreActions.StartRenewTokenPolling());
                }
                return true;
              } else {
                // Utente non loggato, quindi redirect alla pagina di Login. Prima però salvo nello Store l'url corrente affinché, una volta eseguito correttamente il login, si esegua il redirect nella pagina richiesta
                let url = state.url;
                let isTaker = false;

                if (url) {
                  if (skipSsoParam) {
                    url = removeURLParameter(url, "skipSso");
                    if (skipSsoParam == "true" || skipSsoParam == true) {
                      skipSso = true;
                    }
                  }
                  this.store.dispatch(new CoreActions.SaveRedirectUrl(url));
                  if (url.indexOf("takers") !== -1) {
                    // Salvo il redirect url anche nel session storage (visto che per i taker non c'è una home page di default su cui atterrare, e se facessi F5 l'informazione del redirectUrl in redux si perderebbe)
                    isTaker = true;
                    // Ci sono due tipi di login per i taker, uno per chi è abilitato all'sso e uno per chi non lo è; in quest'ultimo caso vado alla pagina classica di login, nel primo caso invece eseguo il redirect all'sso. Conosco l'abilitazione dell'utente in base all'url da cui arrivo, ovvero se esso contiene la parola 'sso'
                    if (url.indexOf("sso") !== -1) {
                      // Vado login con sso
                      this.router.navigate(["/takerLogin"]);
                    } else {
                      // Cosa poco bella ma necessaria: in questo punto non si riescono a recuepreare i params dall'activa route, quindi bisogna prenderli tramite js;
                      // In questo caso mi serve vedere se nell'url c'è lo userId. Esso è situato, se prensente, come ultimo parametro. Pertanto, ci saranno
                      // in questo caso quattro slash /
                      let takerUserId = null;
                      if (url) {
                        let slashInUrl = (url.match(/\//g) || []).length;
                        let isSurveyRedirect = 4;
                        if (url.indexOf("survey") !== -1) {
                          isSurveyRedirect = 5;
                        }
                        if (slashInUrl && slashInUrl === isSurveyRedirect) {
                          let lastSlashindex = url.lastIndexOf("/");
                          takerUserId = url.substring(lastSlashindex + 1);
                          // Se ho lo userId nell'url, lo salvo nel sessionStorage
                          if (takerUserId) {
                            sessionStorage.setItem("takerUserId", takerUserId);
                          }
                        }
                      }
                      // Vado login senza sso
                      this.router.navigate(["/takerLocalLogin"]);
                    }
                  }
                }

                if (!isTaker) {
                  if (skipSso) {
                    this.router.navigate(["/localLogin"]);
                  } else {
                    // Faccio in modo che i query params vengano propagati anche al redirect verso il login, così che non vadano persi.
                    // Questi possono includere i parametri "utm_*" di Google Analytics.
                    const queryParams = vars?.reduce((obj, str) => {
                      const [key, value] = str.split("=");
                      obj[key] = value;
                      return obj;
                    }, {});
                    this.router.navigate(["/login"], {
                      queryParams: queryParams
                    });
                  }
                }
              }
              return false;
            }
          })
        )
    );
  }

  isValidEntryPoint(url: string, authState: fromAuth.AuthState) {
    let isValidUrl = false;
    // con l'utente autenticato solo determinati entrypoints sono ammessi in base al tipo di autenticazione
    if (authState && authState.authenticated) {
      const isDefaulAuth = !authState.isTaker;
      isValidUrl =
        (authState.isTaker && url.indexOf("takers") !== -1) ||
        (isDefaulAuth &&
          url.indexOf("profile") !== -1) ||
        url.indexOf("specialProjects") !== -1 ||
        url.indexOf("specialProjectPage") !== -1 ||
        url.indexOf("support") !== -1 ||
        url.indexOf("collection-list") !== -1 ||
        url.indexOf("initiatives") !== -1 ||
        url.indexOf("takers") !== -1 ||
        url.indexOf("locations") !== -1 ||
        url.indexOf("collectionList") !== -1 ||
        url.indexOf("manageFaq") !== -1 ||
        url.indexOf("manageHeroHome") !== -1 ||
        url.indexOf("manageInspiredTags") !== -1 ||
        url.indexOf("home") !== -1 ||
        url.indexOf("search") !== -1 ||
        url.indexOf("dashboard") !== -1 ||
        url.indexOf("faculty") !== -1 ||
        url.indexOf("first-intro") !== -1 ||
        url.indexOf('featured') !== -1;
    } else {
      // altrimenti (se devo autenticare) consento il tentativo di accesso per rimandare l'utente alla login opportuna
      isValidUrl =
        url.indexOf("takers") !== -1 ||
        url.indexOf("profile") !== -1 ||
        url.indexOf("collection-list") !== -1 ||
        url.indexOf("specialProjects") !== -1 ||
        url.indexOf("specialProjectPage") !== -1 ||
        url.indexOf("support") !== -1 ||
        url.indexOf('featured') !== -1 ||
        url.indexOf("initiatives") !== -1 ||
        url.indexOf("takers") !== -1 ||
        url.indexOf("home") !== -1 ||
        url.indexOf("search") !== -1 ||
        url.indexOf("dashboard") !== -1 ||
        url.indexOf("faculty") !== -1 ||
        url.indexOf("first-intro") !== -1 ||
        url.indexOf("collectionList") !== -1 ||
        url.indexOf("manageFaq") !== -1 ||
        url.indexOf("manageHeroHome") !== -1 ||
        url.indexOf("manageInspiredTags") !== -1 ||
        url.indexOf("locations") !== -1;
    }
    return isValidUrl;
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(key: string) {
    return new Promise((resolve, reject) => {
      this.authService.retrieveTokenAfterLogin(key).subscribe(
        (senecaResponse) => {
          if (senecaResponse.error) {
            reject();
          } else {
            if (senecaResponse && senecaResponse.response) {
              resolve(senecaResponse.response);
            } else {
              resolve(null);
            }
          }
        },
        (err) => {
          reject();
        }
      );
    });
  }
}
