import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "input-code",
  templateUrl: "./input-code.component.html",
  styleUrls: ["./input-code.component.scss"]
})
export class InputCodeComponent implements OnInit {

  @Input() inputValue: FormControl;

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      const element = document.getElementById(this._id);
      if (element) {
        element.setAttribute("tabindex", value);
      }
    }
  };

  _id: string;
  @Input() id: string | number;

  get inputIsFilled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== null && this.inputValue.value;
  }

  constructor() { }

  ngOnInit() {

    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "input-code-" + this.id;

  }

  /**
   * Clear the text
   */
  clearText() {
    this.inputValue.setValue(undefined);
    this.focusOnInput();
  }

  /**
   * Focus on the input
   */
  focusOnInput() {
    setTimeout(() => {
      const element: HTMLElement = document.getElementById(this._id);;
      element.focus();
    }, 0);
  }

}
