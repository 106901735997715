import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickerMode, PickerType } from '@busacca/ng-pick-datetime/lib/date-time/date-time.class';

import { DatepickerInterface } from 'src/app/shared/interfaces/datepicker.interface';
import { convertModelToUTC } from 'src/app/shared/utils';

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatepickerComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DatepickerComponent), multi: true }
  ]
})
export class DatepickerComponent implements OnInit {

  @Input() placeholder: string = '';

  _id: string;
  @Input() id: string;

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      const element = document.getElementById(this._id);
      if (element) {
        element.setAttribute("tabindex", value);
      }
    }
  };

  _reviewMode: boolean = true;
  @Input() set reviewMode(value: boolean | string) {
    this._reviewMode = coerceBooleanProperty(value);
  }

  readonly optionsDateBase: DatepickerInterface = {
    firstDayOfWeek: 1,
    bigBanner: true,
    timePicker: false,
    format: 'dd/MM/yyyy',
    defaultOpen: true
  };

  @Input() optionsDate: DatepickerInterface;

  @Input() pickerType: PickerType;
  @Input() pickerMode: PickerMode;

  _isMin: boolean = true;
  @Input() set isMin(value: boolean | string) {
    this._isMin = coerceBooleanProperty(value);

    if (this._isMin) {
      this._newDate = new Date();
      this._newDate.setDate(this._newDate.getDate() - 1);
    }
  }

  @Input() maxDate: Date | FormControl; // FormControl type inserito per fix, controllare se accettabile
  @Input() minDate: Date | FormControl; // FormControl type inserito per fix, controllare se accettabile

  /**
   * Se non specificato lavoro in UTC tenendo a modello il valore in UTC e a livello di view il formato del Locale
   */
  _utcMode: boolean = true;
  @Input() set utcMode(value: boolean | string) {
    this._utcMode = coerceBooleanProperty(value);
  }

  _showLoader: boolean;
  @Input() set showLoader(value: boolean | string) {
    this._showLoader = coerceBooleanProperty(value);
  };

  @Output() dateChanged = new EventEmitter();

  @ViewChild('datePickerInput') datePickerInput: ElementRef;
  @ViewChild('dt2') owlDateTimeComp: any;

  @Input() inputValue: FormControl;
  @Output() inputValueChange = new EventEmitter();
  @Input() noFormControl?;

  _newDate: Date;

  get inputIsFilled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== null && this.inputValue.value;
  }

  constructor(private __renderer: Renderer2) {
  }

  ngOnInit(): void {

    if (this.id) {
      this._id = "datepicker-" + this.id;
    } else {
      this._id = "datepicker-" + Math.floor((Math.random() * 10000) + 1);
    }

    this.pickerType = this.pickerType || 'calendar'; // calendar, timer, both
    this.pickerMode = this.pickerMode || 'popup'; // popup, dialog

    this.optionsDate = Object.assign(this.optionsDateBase, this.optionsDate || {});

    this.placeholder = this.placeholder || '';

  }

  updateModel($event): void {
    if (this.inputValue && !this.noFormControl) {
      this.inputValue.setValue(this._utcMode ? convertModelToUTC($event && $event.value) : $event && $event.value);
      this.dateChanged.emit(this.inputValue.value);
      this.inputValueChange.emit(this.inputValue.value);
    } else if (this.noFormControl) {
      const newVal = this._utcMode ? convertModelToUTC($event && $event.value) : $event && $event.value;
      this.dateChanged.emit(newVal);
      this.inputValueChange.emit(newVal);
    }
  }

  cleanOpenedStyle() {
    // alla chiusura pulisce il bordino che evidenzia l'input del date/time picker
    if (this.__renderer) {
      this.__renderer.removeAttribute(this.datePickerInput.nativeElement, 'aria-owns');
    }
  }

}
