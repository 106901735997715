<!--<div class="full-width header" [ngClass]="{'header-dark': hasDarkBackground}"
  [ngClass.gt-sm]="{'no-padding-right': searchActive, 'header-dark': hasDarkBackground}"> -->
<div *ngIf="!isThisCurrentPage('mobileSearch')" class="full-width header"
  [ngClass.gt-sm]="{ 'no-padding-right': searchActive }" id="tutorial-1">
  <div class="side-space header-content" fxLayout="row" fxLayoutAlign="start center">
    <div class="generali-app-title" (click)="goToHome()">
      <img *ngIf="!isEuropAssistanceUser" src="assets/icons/learning_up_logo.png" class="learning-up-logo"/>
      <img *ngIf="isEuropAssistanceUser" src="assets/icons/logo-ioApprendo.png" class="logo-ioapprendo"/>
    </div>

    <div *ngIf="!searchActive" class="header-tabs">
      <!-- Tabs sezioni -->
      <div tabindex="0" *ngFor="let tab of headerTabs; trackBy: trackByTitle" role="button" (click)="onTabClick(tab)">
        <p [translate]="tab.title" [ngClass]="{ 'router-active': isLinkActive(tab.link) }"></p>
      </div>

      <div tabindex="0" class="header-divider"></div>

      <!-- Vai al profilo -->
      <div tabindex="0" class="header-tabs-account" role="button" fxLayout="row" fxLayoutAlign="center center"
        (click)="openAccountDialog()" [ngClass]="{
          'router-active': isLinkActive('/profile'),
          'icon-placeholder': isPlaceholderIcon
        }">
        <img class="border-radius50" tabindex="0" role="button" title="Account" height="24" width="24"
          [src]="getUserAvatar()" />
        <p tabindex="0" translate="header.MY_TRAINING"></p>
      </div>

      <!-- Icona Notifiche desktop -->
      <svg-notification [isBlack]="true" *ngIf="isAuthenticated" fromHeader [hasNotification]="isUnreadActivities"
        (onClick)="openNotificationDialog()" [hasDarkMood]="hasDarkBackground">
      </svg-notification>

      <!-- Icona Cerca desktop -->
      <svg-icon tabindex="0" role="button" (click)="showSearch()" title="Search" height="24" width="24"
        src="../../../assets/icons/icon-search.svg">
      </svg-icon>
    </div>

    <div *ngIf="searchActive" class="header-search">
      <input-search isHeaderSearch [headerDarkMood]="hasDarkBackground" class="full-width"
        [placeholder]="'header.SEARCH_PLACEHOLDER' | translate" [bindValue]="searchedText"
        (onModelChanged)="onSearchedTextChanged($event)" (onStartSearch)="onInputSubmit()"
        (onBlurEvent)="onInputBlur()">
      </input-search>
    </div>

    <div class="mobile-icons-wrapper">
      <!-- Icona Cerca mobile -->
      <div tabindex="0" class="no-user-select header-mobile-search" (click)="goToSearch()" fxLayout="column"
        fxLayoutAlign="center center" role="button">
        <svg-icon class="flex-hide-xs" role="button" title="Search" height="20" width="20"
          src="../../../assets/icons/icon-search.svg">
        </svg-icon>
        <svg-icon class="flex-show-xs" role="button" title="Search" src="../../../assets/icons/icon-search-mobile.svg">
        </svg-icon>
        <p translate="header.SEARCH"></p>
      </div>
      <!-- Icona Menu mobile -->
      <div tabindex="0" class="no-user-select" role="button" (click)="goToMobileMenuPage()" fxLayout="column"
        fxLayoutAlign="center center">
        <svg-icon class="flex-hide-xs" role="button" title="Menu" height="20" width="20"
          src="../../../assets/icons/icon-menu.svg">
        </svg-icon>
        <svg-icon class="flex-show-xs" role="button" title="Menu" src="../../../assets/icons/icon-menu-mobile.svg">
        </svg-icon>
        <p translate="header.MENU"></p>
      </div>
    </div>
  </div>
</div>

<!-- Header quando sono in ricerca lato mobile oppure quando ho aperto il menu lato mobile -->
<div *ngIf="
    isThisCurrentPage('mobileSearch') || isThisCurrentPage('mobileMenuPage')
  " class="search-mobile-header">
  <div class="general-header" fxLayout="row" fxLayoutAlign="space-between center">
    <img alt="Vai alla Homepage" class="cursor-pointer" height="35" layout="column" (click)="goToHome()"
      src="/assets/img/generali/logo-generali-red-header.png" />
    <img role="button" title="Menu" (click)="closeModalSearch()" height="22" width="22"
      src="../../../assets/icons/icon-times.svg" />
  </div>
</div>

<div tabindex="-1" id="headerSearchAccount" *ngIf="showAccountPanel" class="header-dialog header-account"
  (blur)="onBlurAccount($event)">
  <!-- INFO PROFILO -->
  <div class="header-profile-container" fxLayout="row" fxLayoutAlign="start center">
    <!-- IMG PROFILO -->
    <img layout="column" class="header-img-avatar" attr.aria-label="{{ 'header.PROFILE' | translate }}" [src]="avatar"
      alt="avatar" title="avatar" />

    <!-- INFO PROFILO -->
    <div class="header-profile-text" fxFlex fxLayout="column" fxLayoutAlign="space-between start">
      <!-- SEZIONE SALUTO UTENTE -->
      <div fxLayout="row" fxLayoutAlign="start center" tabindex="0">
        <p class="lighter" [translate]="
            userForename ? 'header.profile.HI_COMMA' : 'header.profile.HI'
          "></p>
        <p class="word-break" *ngIf="userForename">
          &nbsp;{{ userForename }}
        </p>
        <p class="lighter" translate="header.profile.EXCLA"></p>
      </div>

      <!-- VAI AL PROFILO -->
      <div fxLayout="row" class="cursor-pointer" (click)="goToProfile()">
        <!--<p translate="header.profile.GO_TO_PROFILE"></p>
        <svg-icon class="margin-top4" src="../../../../assets/icons/icon-arrow.svg"></svg-icon>-->
      </div>
    </div>
  </div>

  <!-- DIVISORE -->
  <div class="header-profile-divider"></div>

  <!-- MODIFICA PROFILO -->
  <div class="header-profile-action cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center"
    (click)="goToProfile()">
    <p translate="header.MY_TRAINING"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div class="header-profile-divider"></div>

  <!-- FACULTY -->
  <div *ngIf="!isEuropAssistanceUser" class="header-profile-action cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center"
    (click)="goToFaculty()">
    <p translate="header.FACULTY"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="authObject?.isFacultyAdmin" class="header-profile-divider"></div>

  <!-- FACULTY -->
  <div *ngIf="authObject?.isFacultyAdmin" class="header-profile-action cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center"
    (click)="goToFacultyBackoffice()">
    <p translate="header.FACULTY_BACKOFFICE"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="isManager || isGai || isHrbp" class="header-profile-divider"></div>

  <!-- Learning dashboard -->
  <div *ngIf="isManager || isGai || isHrbp" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="goToLearningDashboard()">
    <p translate="header.LEARNING_DASHBOARD"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="isUserAuthorizedOnEditCoursemanager()" class="header-profile-divider"></div>

  <!-- Modifica corsi del cm2 -->
  <div *ngIf="isUserAuthorizedOnEditCoursemanager()" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="goToEditCoursemanagerCourse()">
    <p translate="header.EDIT_COURSEMANAGER_COURSE"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="isUserAuthorizedOnEditLibrary()" class="header-profile-divider"></div>

  <!-- Modifica oggetti library -->
  <div *ngIf="isUserAuthorizedOnEditLibrary()" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="goToLibraryEditItem()">
    <p translate="header.EDIT_LIBRARY_ITEM"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="canManageSpecialProjects()" class="header-profile-divider"></div>

  <!-- Modifica progetti speciali -->
  <div *ngIf="canManageSpecialProjects()" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="showAccountPanel = false; redirectService.goToSpecialprojectsList()">
    <p translate="specialProjects.SPECIAL_PROJECTS"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="canManageSlidershowHome()" class="header-profile-divider"></div>

  <!-- Gestione Slideshow Home -->
  <div *ngIf="canManageSlidershowHome()" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="showAccountPanel = false; redirectService.goToHeroHomeSlideshow()">
    <p translate="managerSlideshowHome.MENU_TITLE"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="canManageInspiredTags()" class="header-profile-divider"></div>

  <!-- Gestione tag "Lasciati ispirare" -->
  <div *ngIf="canManageInspiredTags()" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="showAccountPanel = false; redirectService.goToManageInspiredTags()">
    <p translate="manageInspiredTags.MENU_TITLE"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div *ngIf="canManageFaq()" class="header-profile-divider"></div>

  <!-- Gestione FAQ -->
  <div *ngIf="canManageFaq()" class="header-profile-action cursor-pointer" fxLayout="row"
    fxLayoutAlign="space-between center" (click)="showAccountPanel = false; redirectService.goToManageFaq()">
    <p translate="manageFaq.MENU_TITLE"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>

  <!-- DIVISORE -->
  <div class="header-profile-divider"></div>

  <!-- LOGOUT -->
  <div class="header-profile-action cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center"
    (click)="logout()">
    <p translate="header.profile.LOGOUT"></p>
    <svg-icon src="../../../../assets/icons/icon-arrow-right.svg"></svg-icon>
  </div>
</div>

<!-- Sezione notifiche -->
<div tabindex="-1" id="headerSearchNotification" *ngIf="showNotificationPanel" class="header-dialog header-notification"
  (blur)="onBlurNofification($event)" fxLayout="column">
  <!-- Notifiche -->
  <p class="header-notification-title center" translate="header.notification.TITLE"></p>

  <ng-container *ngIf="isLoadingNotifications || isLoadingUnreadActivities">
    <div class="header-profile-divider"></div>
    <generali-loader></generali-loader>
    <div class="header-profile-divider"></div>
  </ng-container>

  <ng-container *ngIf="
      !isLoadingNotifications &&
      !isLoadingUnreadActivities &&
      (!userActivities || !userActivities.length)
    ">
    <!-- Divisore -->
    <div class="header-profile-divider"></div>

    <!-- Nessun notifica -->
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
      <p class="header-notification-title font-weight-light" translate="header.notification.NO_NOTIFICATION"></p>
    </div>

    <!-- Divisore -->
    <div class="header-profile-divider"></div>
  </ng-container>

  <!-- Notifiche -->
  <div *ngIf="
      !isLoadingUnreadActivities &&
      !isLoadingNotifications &&
      userActivities &&
      userActivities.length
    " fxLayout="column">
    <div *ngFor="let activity of userActivities; trackBy: trackById" fxFlex
      class="header-notification-item cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center"
      (click)="onNotificationClick(activity)">
      <!-- Notifica -->
      <svg-notification [activity]="activity" [hasNotification]="activity.status == 'TO_READ'">
      </svg-notification>
      <div class="header-notification-text" fxFlex fxLayout="column">
        <p [innerHTML]="activity.title"></p>
        <p class="header-notification-date">
          {{ getTimeFromNow(activity.when) }}
        </p>
      </div>
    </div>
  </div>

  <!-- Vai al profilo -->
  <p class="header-notification-all center cursor-pointer" translate="header.profile.GO_TO_PROFILE"
    (click)="goToProfile()"></p>
</div>
