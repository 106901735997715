import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module'
import { CoreModule } from './core/core.module';
import { reducers } from './ngrx/app.reducers';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthEffects } from './auth/ngrx/auth.effects';
import { CoreEffects } from './core/ngrx/core.effects';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { SocialLoginModule } from "angularx-social-login";
import { ModalService } from './core/modal/modal-services.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ScrollTo } from './shared/services/scroll-to.service';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { SupportPageComponent } from './support-page/support-page.component';
import { ScormService } from './core/scorm-player/scorm.service';
import { PlatformService } from './home/services/platform.service';
import { ItemsService } from './home/services/items.service';
import { CourseEditionService } from './home/services/course-edition.service';

registerLocaleData(localeIt, "it");

// HTTP interceptors
const httpInterceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

// La compilazione Ahead of Time richiede una funzione esportata
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

// Classe per la gestione delle traduzioni mancanti
export class AppMissingHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams) {
    //console.log('Missing translation handler', params);
    return;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SupportPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: AppMissingHandler
      },
      defaultLanguage: 'it'
    }),
    ToastrModule.forRoot({ preventDuplicates: true }),
    AppRoutingModule,
    SharedModule,
    FlexLayoutModule,
    AuthModule,
    CoreModule,
    SocialLoginModule,
    AngularSvgIconModule.forRoot(),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([AuthEffects, CoreEffects]),
    StoreRouterConnectingModule.forRoot(),
    GuidedTourModule,
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [
    GoogleMapsAPIWrapper,
    ModalService,
    ScrollTo,
    GuidedTourService,
    ScormService,
    PlatformService,
    CourseEditionService,
    ItemsService,
    { provide: LOCALE_ID, useValue: "it" },
    httpInterceptors
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
