import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'custom-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() bindValue;
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() disabled?: boolean;
  @Input() attrAriaLabel?: string;
  @Input() errorRequiredText?: string;
  @Input() errorMinLengthText?: string;
  @Input() errorMaxLengthText?: string;
  @Input() errorForbiddenText?: string;
  @Input() inputContainerClass?: string;
  @Input() required?: boolean;
  @Input() subtitle?: string;
  @Input() title?: string;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitOnModelChanged(data?) {
    if (this.onModelChanged && this.onModelChanged instanceof EventEmitter) {
      this.onModelChanged.emit(data);
    }
  }
}
