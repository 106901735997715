/*
 * Servizio che permette le chiamate rest relative ai taker (invitati ad un corso)
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ItemAttributeTypes, SenecaResponse, ItemFrontEndWrapper, CourseManagerItem, EnrichedItemTaker, Survey, Item, Note, CommonConstants } from "atfcore-commonclasses";
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { ExtendedItem } from "src/app/home/item-details/models/extended-item.model";
import { OfCourseMeCourse, TagTypes } from 'atfcore-commonclasses';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { ExtendedSurvey } from 'src/app/shared/models/extended-survey.module';

export class getCatalogCoursesParams {
    fromRecord?: number;
    numRecords?: number;
    allData?: boolean;
    searchedText?: string;
    mineOnly?: boolean;
    withCountLibraryItems?: boolean;
    invited?: boolean;
    wishlistableOnly?: boolean;
    institutionalTrainingOnly?: boolean;
    voluntaryTrainingOnly?: boolean;
    matchWithUserFunctionalArea?: boolean;
    skipEngagementCreation?: boolean;
    withBookmarkStatus?: boolean;
    idpCourses?: boolean;
    softSkillsOnly?: boolean;
    techSkillsOnly?: boolean;
    techSkils?: string[];
    softSkils?: string[];
    clusters?: string[];
    functionalAreas?: string[];
    levels?: string[];
    types?: string[];
    streamTags?: string[];
    categories?: string[]
}

export class ClusterAnalytic {
    clusterId: string;
    clusterTitle: string;
    order: number;
    orderIndex: number;
    percentage: number;
    customClass?: string;
    customLabelClass?: string;
}

@Injectable()
export class PlatformService {
    applicationData: GlobalApplicationData;
    result$: Subscription;
    constructor(private store: Store<fromApp.AppState>,
        private http: HttpClient) {
        // Dallo store recupero l'oggetto principale contenente generici dati, e la lingua dell'applicazione
        let globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
        const combinedSelectes$ = combineLatest(globalApplicationData$);
        this.result$ = combinedSelectes$.subscribe(
            ([globalApplicationData]) => {
                this.applicationData = globalApplicationData;
            });
    }

    // Torna uno Stage dato il suo Id
    getStageFromIdForPublic(userId: string, stageItemId: string, isLibraryItem?: boolean): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('stageItemId', stageItemId);
        httpParams = httpParams.append('isLibraryItem', isLibraryItem && isLibraryItem.toString() || '');
        httpParams = httpParams.append('withFullAttributesLoaded', 'true');
        httpParams = httpParams.append('withFullChildrenAttributesLoaded', 'true');
        httpParams = httpParams.append('itemAttributeTypesToResolve',
            JSON.stringify([ItemAttributeTypes.LOCATION, ItemAttributeTypes.TUTOR, ItemAttributeTypes.TEACHER,
            ItemAttributeTypes.PERSON_IN_CHARGE
                /* TEMPLATES ItemAttributeTypes.INVITATION_MAIL, ItemAttributeTypes.SELF_CANCEL_MAIL, ItemAttributeTypes.PREREGISTRATION_MAIL,
                ItemAttributeTypes.CONFIRMATION_MAIL, ItemAttributeTypes.OB_PROMOTION_MAIL, ItemAttributeTypes.PRESENCE_REGISTERED_MAIL,
                ItemAttributeTypes.COURSE_REMINDER_MAIL */
            ]));

        return this.http.get<ExtendedItem>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-stage-from-id-for-public/' + stageItemId, {
            params: httpParams
        });
    }

    // Recupera un upload
    getUploadByIdForPublic(userId?: string, uploadId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadId', uploadId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-upload-by-id-for-public', {
            params: httpParams
        });
    }

    glpGetOfCoursemeCourseById(courseId: string, retrieveBookmark?: boolean, retrieveJudgement?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('courseId', courseId || '');
        if (retrieveBookmark) {
            httpParams = httpParams.append('retrieveBookmark', "true");
        }
        if (retrieveJudgement) {
            httpParams = httpParams.append('retrieveJudgement', "true");
        }
        return this.http.get<SenecaResponse<OfCourseMeCourse>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-ofcourseme-course-by-id', {
            params: httpParams
        });
    }

    glpGetOfCoursemeCollectionById(collectionId: string, retrieveBookmark?: boolean, retrieveJudgement?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('collectionId', collectionId || '');
        if (retrieveBookmark) {
            httpParams = httpParams.append('retrieveBookmark', "true");
        }
        if (retrieveJudgement) {
            httpParams = httpParams.append('retrieveJudgement', "true");
        }
        return this.http.get<SenecaResponse<OfCourseMeCourse>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-ofcourseme-collection-by-id', {
            params: httpParams
        });
    }

    // Recupera una firma
    getMailSignatureTextByIdForPublic(userId: string, textTemplateId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('textTemplateId', textTemplateId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-mail-signature-text-by-id-for-public', {
            params: httpParams
        });
    }

    // Recupera tutte le Survey dell'utente loggato
    getSurveys(userId: string, referenceId: string, itemId?: string, startIfNotStarted?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('itemId', itemId || '');
        httpParams = httpParams.append('startIfNotStarted', startIfNotStarted && startIfNotStarted.toString() || '');
        httpParams = httpParams.append('showResultOfNotPassedRepeteableSurvey', 'true');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-surveys/' + referenceId, {
            params: httpParams
        });
    }

    // Crea in base al token l'ssorqtp
    crateRetrieveTokenAfterLogin(): any {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/create-retrieve-token-after-login', null);
    }

    // Recupera tutte le Survey dell'utente loggato
    getFlatSurveyBySurveyId(userId: string, surveyId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('surveyId', surveyId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-flat-survey-by-surveyId/' + surveyId, {
            params: httpParams
        });
    }

    // Recupera una lista di rilevazioni di un'edizione specifica
    getEditionAssessmentsForPublic(userId?: string, editionItemId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId || '');
        httpParams = httpParams.append('editionItemId', editionItemId || '');
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-edition-assessments-for-public', {
            params: httpParams
        });
    }

    searchTags(type: string, allData?: boolean): any {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('type', type || '');
        httpParams = httpParams.append('allData', allData && allData.toString() || '');
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/find-tags', {
            params: httpParams
        });
    }

    // Recupera una specifica survey
    getSurveyBySurveyId(userId: string, surveyId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('surveyId', surveyId || '');
        httpParams = httpParams.append('userId', userId || '');
        return this.http.get<SenecaResponse<Survey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-survey-by-surveyId/' + surveyId, {
            params: httpParams
        });
    }

    // Servizio che recupera un Item dato il suo id
    listUserTakers(data: CourseManagerItem.ListUserTakersForPublic) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', data.userId);
        httpParams = httpParams.append('editionItemIds', JSON.stringify(data.editionItemIds));
        httpParams = !!data.stageItemId ? httpParams.append('stageItemId', data.stageItemId) : httpParams;
        httpParams = httpParams.append('allData', '' + data.allData);
        return this.http.get<SenecaResponse<Array<EnrichedItemTaker>>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-user-takers-for-public', {
            params: httpParams
        });
    }

    // Servizio che verifica se un'edizione richiede il check sulla privacy
    isCoursePrivacyToAccept(userId: string, editionItemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('editionItemId', editionItemId);
        return this.http.get<SenecaResponse<boolean>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/is-course-privacy-to-accept', {
            params: httpParams
        });
    }

    // Servizio che recupera la descrizione di un attributo
    getItemAttributeDescriptionForPublic(attributeType: string, initiativeId: string, userId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('attributeType', attributeType);
        httpParams = httpParams.append('itemId', initiativeId);
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-item-attribute-description-for-public', {
            params: httpParams
        });
    }

    // Servizio che recupera il nome di un item (per i taker)
    getItemTitleByIdForPublic(userId: string, itemId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('itemId', itemId);
        return this.http.get<SenecaResponse<string>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-item-title-by-id-for-public', {
            params: httpParams
        });
    }

    // Servizio che recupera l'andamento formativo dei cluster dell'utente loggato
    getClustersAnalyticsByUserId(clusterId?: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('clusterId', clusterId || '');
        return this.http.get<SenecaResponse<ClusterAnalytic[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-clusters-analytics-by-userId', {
            params: httpParams
        });
    }

    userSelfConfirm(userId: string, editionItemId: string, sendEmail?: boolean, usedTakes?: number, coursePrivacyAccepted?: boolean) {
        return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/user-self-confirm', {
            userId: userId,
            editionItemId: editionItemId,
            sendEmail: sendEmail,
            usedTakes: usedTakes,
            coursePrivacyAccepted: coursePrivacyAccepted
        });
    }

    createRent(rent, itemId: string) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-create-rent', {
            rent: rent,
            itemId: itemId
        });
    }

    updateSurvey(userId: string, survey: Survey, closeSurvey: boolean, itemId: string) {
        return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/update-survey', {
            userId: userId,
            survey: survey,
            closeSurvey: closeSurvey,
            itemId: itemId
        });
    }

    userSelfCancel(userId: string, editionItemId: string, statusNote: string, sendEmail?: boolean) {
        return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/user-self-cancel', {
            userId: userId,
            editionItemId: editionItemId,
            sendEmail: sendEmail,
            statusNote: statusNote
        });
    }

    newFacultyCandidate(rentAttributes) {
        return this.http.post<SenecaResponse<ItemFrontEndWrapper>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-new-faculty-candidate', {
            rentAttributes: rentAttributes
        });
    }

    listUserMaterialsForParent(userId: string, fromRecord: number, numRecords: number, parentIds: string[], materialTypes: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('parentId', JSON.stringify(parentIds));
        httpParams = httpParams.append('materialType', JSON.stringify(materialTypes));
        return this.http.get<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/list-user-materials-for-parent', {
            params: httpParams
        });
    }

    getCatalogCourses(params: getCatalogCoursesParams) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', params.fromRecord && params.fromRecord.toString());
        httpParams = httpParams.append('numRecords', params.numRecords && params.numRecords.toString());
        httpParams = httpParams.append('allData', params.allData && params.allData.toString() || '');
        httpParams = httpParams.append('mineOnly', params.mineOnly && params.mineOnly.toString() || '');
        httpParams = httpParams.append('withCountLibraryItems', params.withCountLibraryItems && params.withCountLibraryItems.toString() || '');
        httpParams = httpParams.append('invited', params.invited && params.invited.toString() || '');
        httpParams = httpParams.append('wishlistableOnly', params.wishlistableOnly && params.wishlistableOnly.toString() || '');
        httpParams = httpParams.append('institutionalTrainingOnly', params.institutionalTrainingOnly && params.institutionalTrainingOnly.toString() || '');
        httpParams = httpParams.append('voluntaryTrainingOnly', params.voluntaryTrainingOnly && params.voluntaryTrainingOnly.toString() || '');
        httpParams = httpParams.append('matchWithUserFunctionalArea', params.matchWithUserFunctionalArea && params.matchWithUserFunctionalArea.toString() || '');
        httpParams = httpParams.append('skipEngagementCreation', params.skipEngagementCreation && params.skipEngagementCreation.toString() || '');
        httpParams = httpParams.append('withBookmarkStatus', params.withBookmarkStatus && params.withBookmarkStatus.toString() || '');
        httpParams = httpParams.append('softSkillsOnly', params.softSkillsOnly && params.softSkillsOnly.toString() || '');
        httpParams = httpParams.append('techSkillsOnly', params.techSkillsOnly && params.techSkillsOnly.toString() || '');
        httpParams = httpParams.append('idpCourses', params.idpCourses && params.idpCourses.toString() || '');
        httpParams = httpParams.append('searchedText', params.searchedText || '');

        if (params.techSkils && params.techSkils.length) {
            httpParams = httpParams.append('techSkils', JSON.stringify(params.techSkils));
        }
        if (params.softSkils && params.softSkils.length) {
            httpParams = httpParams.append('softSkils', JSON.stringify(params.softSkils));
        }
        if (params.clusters && params.clusters.length) {
            httpParams = httpParams.append('clusters', JSON.stringify(params.clusters));
        }
        if (params.functionalAreas && params.functionalAreas.length) {
            httpParams = httpParams.append('functionalAreas', JSON.stringify(params.functionalAreas));
        }
        if (params.levels && params.levels.length) {
            httpParams = httpParams.append('levels', JSON.stringify(params.levels));
        }
        if (params.types && params.types.length) {
            httpParams = httpParams.append('types', JSON.stringify(params.types));
        }
        if (params.streamTags && params.streamTags.length) {
            httpParams = httpParams.append('streamTags', JSON.stringify(params.streamTags));
        }
        if (params.categories && params.categories.length) {
            httpParams = httpParams.append('categories', JSON.stringify(params.categories));
        }

        return this.http.get<SenecaResponse<Item[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-catalog-courses/"' + params.fromRecord + '/' + params.numRecords, {
            params: httpParams
        });
    }

    countUserMaterialsForParent(userId: string, parentIds: string[], materialTypes: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('parentId', JSON.stringify(parentIds));
        httpParams = httpParams.append('materialType', JSON.stringify(materialTypes));
        return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-user-materials-for-parent', {
            params: httpParams
        });
    }

    retriveAttachmentUrl(userId: string, item: Item, attachmentId: string, adminMode: boolean, isItemOtherType: boolean) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/get-attachment-url/${attachmentId}`, {
            userId: userId,
            item: item,
            originApplicationName: CommonConstants.APPLICATION_TRAINING_PLATFORM,
            adminMode: adminMode,
            isItemOtherType: isItemOtherType
        });
    }

    countUsedTakesForPublic(userId: string, editionItemIds: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('editionItemIds', JSON.stringify(editionItemIds));

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/count-used-takes-for-public`, {
            params: httpParams
        });
    }

    tryLibraryItemTakerEnroll(itemId: string, stageItemId?: string, editionId?: string) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/enroll-library-item-taker`, {
            itemId: itemId,
            stageId: stageItemId,
            editionId: editionId
        });
    }

    // Se può accedere al jumpin
    canAccessJumpin() {
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/can-access-jumpin`, {
        });
    }

    // Se può accedere al women insurance
    canAccessWomenInsuranceNetwork() {
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/can-access-women-insurance-network`, {
        });
    }

    // Se può accedere al we Learn
    canAccessWeLearn() {
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/can-access-welearn`, {
        });
    }

    // Se può accedere alla formazione google
    canAccessFormazioneGoogle() {
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/can-access-formazionegoogle`, {
        });
    }

    // Se può accedere alla formazione linguistica
    canAccessLinguisticTraining() {
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/can-access-linguistic-training`, {
        });
    }

    // Se può accedere al Map2TheNew
    canAccessMap2TheNew() {
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/can-access-map2`, {
        });
    }

    getSpecialProjectsForUser(numRecords, fromRecord, allData?: boolean): Observable<SenecaResponse<any[]>> {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord || 0);
        httpParams = httpParams.append('numRecords', numRecords || 0);
        if (allData) {
            httpParams = httpParams.append('allData', allData.toString());
        }
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext +
            'rest-api/coursemanager-mediator/get-special-projects-for-user', {
            params: httpParams
        });
    }

    // Recupera count corsi completati su passaporto formativo
    countCompletedCourses(fromDate: Date, toDate: Date, title: string, courseType: any, userStatus: any) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromDate', fromDate && fromDate.toString());
        httpParams = httpParams.append('toDate', toDate && toDate.toString());
        httpParams = httpParams.append('title', title);
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }
        if (userStatus && userStatus.length) {
            userStatus.forEach(userStatus => {
                httpParams = httpParams.append('userStatus', userStatus);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-count-my-attended-courses`, {
            params: httpParams
        });
    }

    // Recupera get corsi completati su passaporto formativo
    getCompletedCourses(fromRecord: number, numRecords: number, fromDate: string, toDate: string, title: string, courseType: any, userStatus: any, sorting: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('fromDate', fromDate && fromDate.toString());
        httpParams = httpParams.append('toDate', toDate && toDate.toString());
        httpParams = httpParams.append('title', title);
        httpParams = httpParams.append('sorting', sorting);
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }
        if (userStatus && userStatus.length) {
            userStatus.forEach(userStatus => {
                httpParams = httpParams.append('userStatus', userStatus);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-my-attended-courses`, {
            params: httpParams
        });
    }

    // Recupera count corsi obbligatori su passaporto formativo
    countObbligatoryCourses(fromDate: Date, toDate: Date, title: string, courseType: any) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromDate', fromDate && fromDate.toString());
        httpParams = httpParams.append('title', title);
        httpParams = httpParams.append('mandatoryTrainingOnly', 'true');
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-count-my-proposed-courses`, {
            params: httpParams
        });
    }

    // Recupera get corsi obbligatori su passaporto formativo
    getObbligatoryCourses(fromRecord: number, numRecords: number, fromDate: Date, toDate: Date, title: string, courseType: any, userStatus: any) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('fromDate', fromDate && fromDate.toString());
        httpParams = httpParams.append('title', title);
        httpParams = httpParams.append('mandatoryTrainingOnly', 'true');
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }
        if (userStatus && userStatus.length) {
            userStatus.forEach(userStatus => {
                httpParams = httpParams.append('userStatus', userStatus);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-my-proposed-courses`, {
            params: httpParams
        });
    }

    // Recupera count corsi non completati su passaporto formativo
    countNotCompletedCourses(fromDate: Date, toDate: Date, title: string, courseType: any) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromDate', fromDate && fromDate.toString());
        httpParams = httpParams.append('toDate', toDate && toDate.toString());
        httpParams = httpParams.append('title', title);
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-count-my-proposed-courses`, {
            params: httpParams
        });
    }

    // Recupera get corsi non completati su passaporto formativo
    getNotCompletedCourses(fromRecord: number, numRecords: number, fromDate: Date, toDate: Date, title: string, courseType: any, sorting: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('fromDate', fromDate && fromDate.toString());
        httpParams = httpParams.append('toDate', toDate && toDate.toString());
        httpParams = httpParams.append('title', title);
        httpParams = httpParams.append('sorting', sorting);
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-my-proposed-courses`, {
            params: httpParams
        });
    }

    getSoftCompetencesAwardsDescriptionByUserId(userId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('tagType', "SOFT_COMPETENCES");

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-awards-description-by-userId`, {
            params: httpParams
        });

    }

    getTechCompetencesAwardsDescriptionByUserId(userId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('tagType', "TECH_COMPETENCES");

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-awards-description-by-userId`, {
            params: httpParams
        });

    }

    getCompetenceAwardDescriptionByUserId(userId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('tagType', [TagTypes.TECH_COMPETENCES, TagTypes.SOFT_COMPETENCES].toString());
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-awards-description-by-userId`, {
            params: httpParams
        });
    }

    searchTagsForCassett(topmostOnly: string, allData: string, fromRecord: number, numRecords: number, type: string[], creationUserId: string, title: string, description: string, parentId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('topmostOnly', topmostOnly);
        httpParams = httpParams.append('allData', allData);
        if (fromRecord) {
            httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        }
        if (numRecords) {
            httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        }
        if (type && type.length) {
            type.forEach(type => {
                httpParams = httpParams.append('type', type);
            });
        }
        httpParams = httpParams.append('creationUserId', creationUserId);
        if (title) {
            httpParams = httpParams.append('title', title);
        }
        if (description) {
            httpParams = httpParams.append('description', description);
        }
        if (parentId) {
            httpParams = httpParams.append('parentId', parentId);
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-search-tags`, {
            params: httpParams
        });

    }

    countUserCompletionsByClusterId(clusterId: string, startDate: number, endDate: number, title: string, courseType: any) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('clusterId', clusterId);
        httpParams = httpParams.append('startDate', startDate && startDate.toString());
        httpParams = httpParams.append('endDate', endDate && endDate.toString());
        httpParams = httpParams.append('title', title);
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-count-user-completions-by-clusterId`, {
            params: httpParams
        });


    }

    getUserCompletionsByClusterId(clusterId: string, fromRecord: number, numRecords: number, startDate: Date, endDate: Date, title: string, courseType: any, sorting?: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('clusterId', clusterId);
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('startDate', startDate && startDate.toString());
        httpParams = httpParams.append('endDate', endDate && endDate.toString());
        httpParams = httpParams.append('sorting', sorting && sorting.toString());
        httpParams = httpParams.append('title', title);
        if (courseType && courseType.length) {
            courseType.forEach(courseType => {
                httpParams = httpParams.append('courseType', courseType);
            });
        }

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-get-user-completions-by-clusterId`, {
            params: httpParams
        });


    }

    findNotes(allData: boolean, notes: string, fromRecord?: number, numRecords?: number, referenceIds?: string[]) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', allData && allData.toString());
        httpParams = httpParams.append('noteTypes', notes);

        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        if (referenceIds && referenceIds.length) {
            referenceIds.forEach(referenceIds => {
                httpParams = httpParams.append('referenceIds', referenceIds);
            });
        }

        return this.http.get<SenecaResponse<Note[]>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-find-notes`, {
            params: httpParams
        });
    }

    listItemsByAny(allData: boolean, fromRecord: number, numRecords: number, itemTypes: string[], referenceId: string, referenceType: string, getUploadInfo: boolean, isUserUploadedContent: boolean, getEmbeddedPlayerCode: boolean, addCustomPlayerId: boolean, withAttributesLoaded: true, excludeMyItems: boolean, withAllVisibilitiesLoaded: boolean, loadInvitedInstead: boolean) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('allData', allData && allData.toString());
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        if (itemTypes && itemTypes.length) {
            itemTypes.forEach(itemTypes => {
                httpParams = httpParams.append('itemTypes', itemTypes);
            });
        }
        httpParams = httpParams.append('referenceId', referenceId);
        httpParams = httpParams.append('referenceType', referenceType);
        httpParams = httpParams.append('getUploadInfo', getUploadInfo && getUploadInfo.toString());
        httpParams = httpParams.append('isUserUploadedContent', true.toString());
        httpParams = httpParams.append('getEmbeddedPlayerCode', getEmbeddedPlayerCode && getEmbeddedPlayerCode.toString());
        httpParams = httpParams.append('addCustomPlayerId', addCustomPlayerId && addCustomPlayerId.toString());
        httpParams = httpParams.append('withAttributesLoaded', true.toString());
        httpParams = httpParams.append('excludeMyItems', excludeMyItems && excludeMyItems.toString());
        httpParams = httpParams.append('withAllVisibilitiesLoaded', withAllVisibilitiesLoaded && withAllVisibilitiesLoaded.toString());
        httpParams = httpParams.append('loadInvitedInstead', loadInvitedInstead && loadInvitedInstead.toString());

        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + `rest-api/coursemanager-mediator/glp-list-items-by-any-for-training-passport`, {
            params: httpParams
        });
    }

    deleteFolder(folderId: string) {
        return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-delete-user-folder', {
            tagId: folderId
        });
    }

    deleteItem(item: any, itemId: string) {
        return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-delete-item-for-training-passport', {
            item: item,
            itemId: itemId
        });
    }

    getMediaDownloadUrl(attachemntId: string) {
        return this.http.post<SenecaResponse<ExtendedSurvey>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-media-download-url/' + attachemntId, {
            entryId: attachemntId
        });
    }

    // Recupera il counter totale persone all'interno del proprio Tenant
    countPersonsInTenant(searchedText?: string, includeMe?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        if (includeMe) {
            httpParams = httpParams.append('includeMe', includeMe.toString());
        }
        return this.http.get<SenecaResponse<number>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/count-persons-in-tenant', {
            params: httpParams
        });
    }

    // Recupera una lista di persone all'interno del proprio Tenant
    getPersonsInTenant(fromRecord: number, numRecords: number, searchedText?: string, includeMe?: boolean): any {
        let from = fromRecord && fromRecord.toString();
        let to = numRecords && numRecords.toString();
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', from || '0');
        httpParams = httpParams.append('numRecords', to || '25');
        if (searchedText && searchedText.length) {
            httpParams = httpParams.append('searchedText', searchedText || '');
        }
        if (includeMe) {
            httpParams = httpParams.append('includeMe', includeMe.toString());
        }
        return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-persons-in-tenant/' + from + '/' + to, {
            params: httpParams
        });
    }

    hasReferenceOpenRents(referenceId: string, onlyForMe?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId);
        if (onlyForMe) {
            httpParams = httpParams.append('onlyForMe', onlyForMe.toString());
        }
        return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-has-reference-open-rents', {
            params: httpParams
        });
    }

    getRentsByAny(referenceId: string, allData?: boolean, onlyForMe?: boolean): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId);
        if (allData) {
            httpParams = httpParams.append('allData', allData.toString());
        }
        if (onlyForMe) {
            httpParams = httpParams.append('onlyForMe', onlyForMe.toString());
        }
        return this.http.get<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-rents-by-any', {
            params: httpParams
        });
    }

    createNewFolder(newTag: any): any {
        return this.http.post<SenecaResponse<any[]>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-create-user-folder', {
            tag: newTag
        });
    }

    createNote(noteId: string, title: string, description: string, noteType: string, referenceId?: string, referenceType?: string, referenceApplicationName?: string) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-create-note', {
            title: title,
            description: description,
            noteType: noteType,
            referenceId: referenceId,
            referenceType: referenceType,
            referenceApplicationName: referenceApplicationName
        });
    }

    abortRent(rent, newStatusType: string, itemId: string) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-abort-rent', {
            rent: rent,
            newStatusType: newStatusType,
            itemId: itemId
        });
    }

    updateNote(noteId: string, title: string, description: string, noteType: string, referenceId?: string, referenceApplicationName?: string, referenceType?: string) {
        return this.http.post<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-update-note', {
            noteId: noteId,
            title: title,
            description: description,
            noteType: noteType,
            referenceId: referenceId,
            referenceApplicationName: referenceApplicationName,
            referenceType: referenceType
        });
    }

    deleteNote(noteId: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('noteId', noteId || '');
        return this.http.delete<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-delete-note/' + noteId, {
            params: httpParams
        });
    }

    getUserMainApplicationData(token: string) {
        // Preparo i parametri per la richiesta http
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/get-user-main-application');
    }

    isSsoEnabledForLoggedUser() {
        let httpParams = new HttpParams();
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/is-sso-enabled-for-logged-user', {
            params: httpParams
        });
    }

    // Recupera corsi esterni nel MyLearning
    getFeaturedCourseDates(token: string) {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('token', token);
        return this.http.get<SenecaResponse<any>>(this.applicationData.applicationContext + 'rest-api/coursemanager-mediator/glp-get-featured-course-dates', {
            params: httpParams
        });
    }

}