/*
 * Componente per la pagina di supporto
*/

import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
    selector: 'app-support',
    templateUrl: './support.component.html',
    styleUrls: ['./support.component.scss']
})
export class SupportComponent {
    hasHistory: boolean;

    constructor(
        private router: Router, private location: Location) {
    }

    ngOnInit() {
        this.hasHistory = this.router.navigated;
    }

    goBack() {
        alert(this.hasHistory);
        this.location.back();
    }

}
