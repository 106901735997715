export enum DeviceType {
  DESKTOP = 'D',
  TABLET = 'T',
  SMARTPHONE = 'P'
}

export enum SortingType {
  SCORE_DESC = 'S',
  CREATE_DATE_DESC = 'D',
  TITLE_ASC = 'T'
}
