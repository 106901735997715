/*
* Componente principale dell'applicazione
*/

import { Component, NgZone, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as CoreActions from './core/ngrx/core.actions';
import * as fromApp from './ngrx/app.reducers';

import { ModalService } from './core/modal/modal-services.component';
import { environment } from 'src/environments/environment';
import { RedirectService } from './shared/services/redirect.service';
import { LangsService } from './core/services/langs.service';
import { BaseSubscriberComponent } from './shared/components/base-subscriber.component';
import { filter, pairwise } from 'rxjs/operators';
declare var window: any;

function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts = url.split("?");
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + "=";
    var pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
  }
  return url;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseSubscriberComponent implements OnInit {
  showApplicationLoader: boolean;
  isFetchingLangs$: Observable<boolean>;
  isAuthenticated: boolean;
  isSettingAck: boolean;

  readonly hideHeaderPages = [
    'localLogin',
    'emailConfirmation',
    'recoveryUserPassword',
    'signin',
    'profilation',
    'login',
    '403',
    /* 'privacy',
    'privacySecurity', */
    /* 'termsOfUse', */
    'terms-of-use',
    'terms-of-services',
    'first-intro',
    'first-intro-home',
    'first-intro-mail-confirmation',
    'intro',
    'confirmPrivacyData',
    'emailConfirmation'
  ];

  readonly hideFooterPages = [
    'localLogin',
    'emailConfirmation',
    'recoveryUserPassword',
    'signin',
    'profilation',
    'login',
    '403',
    /* 'privacy',
    'privacySecurity', */
    /* 'termsOfUse', */
    'terms-of-use',
    'terms-of-services',
    'first-intro',
    'first-intro-home',
    'first-intro-mail-confirmation',
    'intro',
    'confirmPrivacyData',
    'emailConfirmation'
  ];

  get showHeader(): boolean {
    return !this.showApplicationLoader && this.hideHeaderPages.indexOf(this.router.url.substring(1).split('/')[0]) === -1;
  }

  get showFooter(): boolean {
    return !this.showApplicationLoader && this.hideFooterPages.indexOf(this.router.url.substring(1).split('/')[0]) === -1;
  }

  isUsingInternetExplorer: boolean;

  constructor(private store: Store<fromApp.AppState>,
    private modalService: ModalService,
    private redirectService: RedirectService,
    private zone: NgZone,
    private langsService: LangsService,
    private router: Router) {
    super();

    this.router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        let precedentUrl = decodeURIComponent(events[0].urlAfterRedirects);
        precedentUrl = removeURLParameter(precedentUrl, "ssortkqp");
        let nextUrl = decodeURIComponent(events[1].urlAfterRedirects);
        nextUrl = removeURLParameter(nextUrl, "ssortkqp");
        if (precedentUrl != nextUrl) {
          // se sono in una pagina di dettaglio, controllo se sono nel dettaglio di un oggetto di una playlist
          // in questo caso, controllo se ho un url già salvato in localStorage con l'indirizzo dell'oggetto padre
          // se è così, e il nuovo url sarebbe un redirect in home, non sovrascrivo l'url salvato
          let itemDetailsPage = window.location.href.split("itemDetails/");
          if (itemDetailsPage.length > 0 && itemDetailsPage[1] && itemDetailsPage[1].split("/").length > 0 && precedentUrl.includes("home/main")) {
            let playlistId = itemDetailsPage[1].split("/")[0];
            precedentUrl = "home/itemDetails/" + playlistId;
          }
          localStorage.setItem('backRedirect', precedentUrl);
        }
      });


    // Creo e carico lo script di Google Tag Manager per Google Analytics
    const script = document.createElement('script');
    script.innerHTML = `(function(
      w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.googleTagManagerCode}')`
    document.head.insertBefore(script, null);

    // Mostro il loader mentre sto caricando le lingue
    this.isFetchingLangs$ = this.store.select(fromApp.isFetchingLangs);

    combineLatest([
      this.store.select(fromApp.isAuthenticated),
      this.store.select(fromApp.showApplicationLoader)
    ])
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        ([isAuthenticated, showApplicationLoader]) => {
          this.isAuthenticated = isAuthenticated;
          this.showApplicationLoader = showApplicationLoader;
        });

    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    this.store.select(fromApp.getApplicationLang)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (applicationLang) => {
          this.langsService.useLanguage(applicationLang || 'it');
        });

    this.isUsingInternetExplorer = this.isInternetExplorerBrowser();

    // Se l'utente usa explorer, apro la modale che suggerisce di cambiare browser
    const switchBrowserModalViewed: string = sessionStorage.getItem('switchBrowserModalViewed');
    if (this.isUsingInternetExplorer && !switchBrowserModalViewed) {
      setTimeout(() => {

        this.openSwitchBrowserModal();
      }, 2500)
    }
  }

  isInternetExplorerBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

  openSwitchBrowserModal() {
    this.modalService.open("switch-browser-modal");
  }

  closeSwitchBrowserModal() {
    sessionStorage.setItem('switchBrowserModalViewed', "true");
    this.modalService.close("switch-browser-modal");
  }

  // Verifica in quale pagina sono
  isThisCurrentPage(page: string) {
    return page && this.router && this.router.url && this.router.url.indexOf(page) !== -1;
  }

  // Quando il componente si è inizializzato ed è pronto, devo settare i parametri applicativi come il base URL dell'applicazione. Di questo se ne occuperà il side effect una volta lanciata l'action per recuperare le lingue disponibili a sistema.
  ngOnInit() {
    // Recupero le lingue disponibili
    this.store.dispatch(new CoreActions.GetAvailableLangs());
  }

  goToLocalLogin() {
    this.store.dispatch(new CoreActions.SaveRedirectUrl(this.router.url));
    sessionStorage.setItem('redirectAfterSignIn', this.router.url);
    this.closeModalLogin();
    this.redirectService.goToLocalLogin();
  }

  closeModalLogin() {
    this.modalService.closeModalLogin();
  }
}
