import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replacePlaceholder',
})
export class ReplacePlaceholderPipe implements PipeTransform {
  transform(
    source: string,
    value: unknown,
    placeholder: string = '{{}}'
  ): string {

    let target: string;

    if (!value) {
      return '';
    }

    if (typeof value === 'string') {
      target = value;
    } else if (typeof value === 'number') {
      target = value.toString();
    } else {
      throw new Error('Value is not a string or number. Typeof value is : ' + typeof value);
    }

    return source.replace(placeholder, target);
  }
}
