<div class="forum-page" fxLayout="column">

  <!-- Intestazione con la ricerca dei post e pulsante di creazione -->
  <div class="forum-page-header" fxLayout="row" fxLayoutAlign="space-between center">

    <h4 tabindex="-1" fxShow fxHide.xs translate="forum-page.TITLE" class="center lighter"></h4>

    <div [ngClass.xs]="'forum-page-header-full'" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">

      <!-- Ricerca post -->
      <input-search id="forum-page-input-search" attrAriaLabel="{{ 'libraryHome.SEARCH_IN' | translate }}" lighter
        [placeholder]="'header.SEARCH_PLACEHOLDER' | translate" [bindValue]="searchedText"
        (onStartSearch)="onInputSubmit($event)">
      </input-search>

      <!-- Apre la modale per creare un nuovo post -->
      <custom-button importance="primary-small" fxFlexOffset="16px" fxFlexOffset.xs="24px"
        [text]="'forum-page.NEW_POST' | translate" (onClick)="openNewPostModal()">
      </custom-button>
    </div>
  </div>

  <!-- Linee guida -->
  <div fxFlexOffset="40px" class="forum-page-guidelines" fxLayout="row" fxLayout.xs="column"
    fxLayoutAlign="start center" fxLayoutAlign.xs="start stretch">

    <div fxLayout="column" fxFlex>
      <h6 tabindex="-1" class="guideline" translate="forum-page.GUIDELINES_TITLE"></h6>
      <h6 tabindex="-1" fxFlexOffset="8px" translate="forum-page.GUIDELINES_DESCRIPTION"></h6>
    </div>

    <!-- Scarica il PDF con la guida -->
    <div (click)="downloadGuidelines()" class="forum-page-guidelines-download" fxFlexOffset="80px"
      fxFlexOffset.xs="20px" fxLayout="row" class="cursor-pointer">
      <h6 tabindex="-1" class="download" translate="forum-page.GUIDELINES_DOWNLOAD"></h6>
      <svg-icon fxFlexOffset="5px" src="../../../assets/icons/icon-arrow.svg"></svg-icon>
    </div>

  </div>

  <div class="forum-page-divider"></div>

  <div class="forum-page-post" *ngFor="let post of posts; let index = index"
    [fxFlexOffset]="index === 0 ? '0px' : '32px'">

    <div fxLayout="row">

      <!-- Avatar lato desktop -->
      <img fxShow fxHide.xs *ngIf="post.srcImage" layout="column" attr.aria-label="Post avatar" [src]="post.srcImage"
        alt="Post avatar" title="Post avatar">

      <!-- Immagine di default lato desktop quando manca l'avatar dell'utente -->
      <div fxShow fxHide.xs *ngIf="!post.srcImage" class="post-container-avatar" fxLayout="column"
        fxLayoutAlign="center center">
        <svg-icon src="../../../assets/img/generali/logo-generali-cut-white.svg"></svg-icon>
      </div>

      <div fxFlex fxLayout="column" fxFlexOffset="16px" fxFlexOffset.xs="0px">
        <!-- Titolo del post -->
        <div fxLayout="row" fxLayoutAlign="start center">
          <h6 tabindex="-1" class="title">{{ post.title }}</h6>
          <svg-icon fxHide fxShow.xs *ngIf="isAuthenticated && post.isOwnPost" fxFlexOffset="16px"
            class="forum-post-social-bin cursor-pointer" (click)="openHidePostModal(index)"
            src="../../../assets/icons/icon-trash.svg">
          </svg-icon>
        </div>
        <!-- Autore -->
        <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="8px" fxFlexOffset.xs="12px">

          <!-- Avatar mobile -->
          <img fxHide fxShow.xs *ngIf="post.srcImage" layout="column" attr.aria-label="Post avatar"
            [src]="post.srcImage" alt="Post avatar" title="Post avatar">

          <!-- Avatar mobile -->
          <div fxHide fxShow.xs *ngIf="!post.srcImage" class="post-container-avatar" fxLayout="column"
            fxLayoutAlign="center center">
            <svg-icon src="../../../assets/img/generali/logo-generali-cut-white.svg"></svg-icon>
          </div>

          <h6 fxFlexOffset.xs="12px" tabindex="-1" class="author" [ngClass]="{'staff': post.isStaff}">{{ post.author }}
          </h6>
          <generali-badge fxFlexOffset="12px" fxFlexOffset.xs="20px" *ngIf="post.isStaff"
            [title]="'forum-page.STAFF' | translate" type="small-default" fxLayout="column" fxLayoutAlign="center">
          </generali-badge>
          <ng-container *ngIf="!post.isStaff">
            <h6 tabindex="-1" class="author">&nbsp;•&nbsp;</h6>
            <h6 tabindex="-1" class="author lighter">{{ getTimeFromNow(post.when) }}</h6>
          </ng-container>
        </div>
        <!-- Testo del post -->
        <long-text fxFlexOffset="20px" fxFlexOffset.xs="8px" [text]="post.text" hideShowMore [limit]="postPreviewLength"
          [readMore]="post.opened" customClass="post-text" class="cursor-pointer" (click)="openPost(post)">
        </long-text>

        <!-- Like e commenti -->
        <div fxLayout="row" fxFlexOffset="24px">
          <!-- Numero di mi piace -->
          <div fxLayout="row" fxLayoutAlign="start center">
            <svg-icon class="cursor-pointer forum-post-social-icon" (click)="likePost(post)" *ngIf="!post.isLiked"
              src="../../../assets/icons/icon-heart.svg"></svg-icon>
            <svg-icon class="cursor-pointer forum-post-social-icon" (click)="unlikePost(post)" *ngIf="post.isLiked"
              src="../../../assets/icons/icon-heart-fill.svg">
            </svg-icon>
            <h6 tabindex="-1" fxFlexOffset="8px" class="social">{{ ('forum-page.LIKE' + (post.likes === 1 ? '' : 'S')) |
              translate: { value: post.likes} }}
            </h6>
          </div>

          <!-- Numero di risposte (commenti) -->
          <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="32px" fxFlexOffset.xs="24px"
            class="cursor-pointer" (click)="openPost(post)">
            <svg-icon src="../../../assets/icons/icon-message.svg"></svg-icon>
            <h6 tabindex="-1" fxFlexOffset="8px" class="social">
              {{ ('forum-page.ANSWER' + (post.answers?.length === 1 ? '' : 'S'))
              | translate: { value: post.answers?.length} }}
            </h6>
          </div>
        </div>
      </div>

      <!-- Freccia per il toggle del post lato mobile -->
      <div *ngIf="postHasArrow(post)" fxHide fxShow.xs class="arrow">
        <svg-icon class="cursor-pointer" (click)="post.opened = !post.opened" [ngClass]="{'opened': post.opened}"
          src="../../../assets/icons/icon-arrow-down.svg"></svg-icon>
      </div>

      <!-- Freccia il toggle del post lato desktop, e cestino per cancellare il post -->
      <div fxShow fxHide.xs [fxFlexOffset]="postHasArrow(post) ? '28px' : '100px'">
        <div *ngIf="postHasArrow(post)" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon *ngIf="isAuthenticated && post.isOwnPost" class="cursor-pointer" (click)="openHidePostModal(index)"
            src="../../../assets/icons/icon-trash.svg"></svg-icon>

          <div [fxFlexOffset]="isAuthenticated && post.isOwnPost ? '32px' : '80px'" class="arrow">
            <svg-icon class="cursor-pointer" (click)="post.opened = !post.opened" [ngClass]="{'opened': post.opened}"
              src="../../../assets/icons/icon-arrow-down.svg"></svg-icon>
          </div>
        </div>
      </div>

    </div>

    <!-- Post espanso -->
    <div *ngIf="post.opened" fxLayout="column" class="forum-page-post-expanded">

      <!-- Commenti -->
      <div *ngIf="post.answers?.length > 0" fxLayout="column">

        <div class="forum-page-divider answers"></div>

        <div *ngFor="let answer of post.answers" fxLayout="row" fxFlexOffset="24px">
          <!-- Avatar lato desktop della persona che ha scritto -->
          <img fxShow fxHide.xs *ngIf="answer.srcImage" layout="column" attr.aria-label="Post avatar"
            [src]="answer.srcImage" alt="Post avatar" title="Post avatar">

          <!-- Avatar lato desktop della persona che ha scritto -->
          <div fxShow fxHide.xs *ngIf="!answer.srcImage" class="post-container-avatar" fxLayout="column"
            fxLayoutAlign="center center">
            <svg-icon src="../../../assets/img/generali/logo-generali-cut-white.svg"></svg-icon>
          </div>

          <div fxLayout="column" fxFlexOffset="16px" fxFlexOffset.xs="0px">
            <div fxLayout="row" fxLayoutAlign="start center">

              <!-- Avatar lato mobile della persona che ha scritto -->
              <img fxHide fxShow.xs *ngIf="answer.srcImage" layout="column" attr.aria-label="Post avatar"
                [src]="answer.srcImage" alt="Post avatar" title="Post avatar">

              <!-- Avatar lato mobile della persona che ha scritto -->
              <div fxHide fxShow.xs *ngIf="!answer.srcImage" class="post-container-avatar" fxLayout="column"
                fxLayoutAlign="center center">
                <svg-icon src="../../../assets/img/generali/logo-generali-cut-white.svg"></svg-icon>
              </div>

              <h6 fxFlexOffset.xs="12px" tabindex="-1" class="author">{{ answer.author }}</h6>
              <h6 tabindex="-1" class="author">&nbsp;•&nbsp;</h6>
              <h6 tabindex="-1" class="author lighter">{{ getTimeFromNow(answer.when) }}</h6>
            </div>

            <h6 fxFlexOffset="10px" fxFlexOffset.xs="12px">{{ answer.text }}</h6>

          </div>
        </div>

      </div>

      <!-- Commento -->
      <div *ngIf="isAuthenticated" fxLayout="row" fxFlexOffset="40px" fxFlexOffset.xs="24px">
        <!-- Solo desktop -->
        <img fxShow fxHide.xs layout="column" attr.aria-label="{{ 'header.PROFILE' | translate }}" [src]="avatar"
          alt="avatar" title="avatar">
        <textarea #commentInput fxFlexOffset="16px" fxFlexOffset.xs="0px" class="answer-input" fxFlex
          [placeholder]="'forum-page.COMMENT' | translate" attr.aria-label="Comment post"
          (keyup.enter)="onSubmitComment(index, commentInput)"></textarea>
      </div>

    </div>

  </div>

</div>

<!-- Modale per creare un nuovo post -->
<app-modal class="modal-forum" id="forumNewPost">
  <div class="container" fxLayout="column" fxLayoutAlign="space-between center">

    <!-- Chiude la modale -->
    <svg-icon fxShow [fxHide.gt-xs]="postSubmitted" class="modal-forum-close cursor-pointer"
      src="../../../assets/icons/icon-close-light.svg" height="40" width="40" (click)="closeNewPostModal()">
    </svg-icon>

    <div fxLayout="column" fxLayoutAlign="start center">
      <!-- Titolo della modale -->
      <h4 *ngIf="!postSubmitted" class="center lighter" translate="forum-page.newPostModal.TITLE" tabindex="-1">
      </h4>
      <h4 *ngIf="postSubmitted" class="center lighter" translate="generic.DONE" tabindex="-1">
      </h4>
      <!-- Post creato -->
      <svg-icon *ngIf="postSubmitted" fxFlexOffset="20px" fxFlexOffset.xs="24px" tabindex="-1"
        src="../../../assets/icons/badge/icon-check.svg" height="60" width="60">
      </svg-icon>
      <!-- Descrizione -->
      <h6 *ngIf="!postSubmitted" fxFlexOffset="12px" fxFlexOffset.xs="16px" class="lighter"
        translate="forum-page.newPostModal.DESCRIPTION" tabindex="-1"></h6>

      <h6 *ngIf="postSubmitted" fxFlexOffset="32px" fxFlexOffset.xs="24px" class="lighter"
        translate="forum-page.newPostModal.DESCRIPTION2" tabindex="-1"></h6>

      <h6 *ngIf="postSubmitted" fxFlexOffset="20px" class="lighter" translate="forum-page.newPostModal.DESCRIPTION3"
        tabindex="-1"></h6>
    </div>

    <!-- Form per il nuovo post -->
    <form *ngIf="!postSubmitted" class="full-width" fxFlex fxLayout="column" fxLayoutAlign="space-between center"
      fxFlexOffset="32px" fxFlexOffset.xs="40px" (ngSubmit)="createNewPost()" [formGroup]="newPostForm">

      <div class="full-width" fxLayout="column">

        <!-- Titolo del post -->
        <input type="text" [placeholder]="'forum-page.newPostModal.TITLE_PLACEHOLDER' | translate"
          formControlName="title" attr.aria-label="Title post">

        <!-- Testo del post -->
        <textarea fxFlexOffset="20px" fxFlexOffset.xs="24px" formControlName="description"
          [placeholder]="'forum-page.newPostModal.DESCRIPTION_PLACEHOLDER' | translate"
          attr.aria-label="Description post"></textarea>

      </div>

      <!-- Conferma la creazione del post -->
      <custom-button ngClass.xs="full-width" [text]="'forum-page.newPostModal.ACTION' | translate"
        [disabled]="newPostForm.invalid" type="submit">
      </custom-button>
    </form>

    <!-- Chiude la modale -->
    <custom-button *ngIf="postSubmitted" ngClass.xs="full-width" [text]="'forum-page.newPostModal.ACTION2'| translate"
      (onClick)="closeNewPostModal()">
    </custom-button>

  </div>
</app-modal>

<!-- Modale per l'uscita dalla seconda pagina senza salvare i dati utente -->
<app-modal class="modal-forum" id="forumHidePost">

  <div class="container" fxLayout="column" fxLayoutAlign="space-between center">

    <!-- Chiude la modale -->
    <svg-icon class="modal-forum-close cursor-pointer" src="../../../assets/icons/icon-close-light.svg" height="40"
      width="40" (onClick)="closeHidePostModal()">
    </svg-icon>

    <!-- Titolo e descrizione della modale -->
    <div fxLayout="column" fxLayoutAlign="start center" fxFlexOffset="40px" fxFlexOffset.xs="0px">
      <h4 class="hide-post center lighter" translate="forum-page.hideModal.TITLE"></h4>
      <h6 fxFlexOffset="12px" fxFlexOffset.xs="20px" class="hide-post lighter"
        translate="forum-page.hideModal.DESCRIPTION"></h6>
    </div>

    <div ngClass.xs="full-width" fxLayout="row" fxLayout.xs="column-reverse" fxLayoutAlign="center center">
      <!-- Chiude la modale -->
      <custom-button ngClass.xs="full-width" fxFlexOffset.xs="32px" [text]="'forum-page.hideModal.BACK' | translate"
        importance="secondary" (onClick)="closeHidePostModal()">
      </custom-button>
      <!-- Chiude la modale confermando l'operazione -->
      <custom-button ngClass.xs="full-width" fxFlexOffset="32px" fxFlexOffset.xs="0px"
        [text]="'forum-page.hideModal.ACTION' | translate" (onClick)="hidePost()">
      </custom-button>
    </div>
  </div>
</app-modal>
