/*
*  Reducer che gestiscono e modificano lo State applicativo riguardante il profilo dell'utente loggato
*/

import * as jwt_decode from 'jwt-decode';

import { Lang, User } from 'atfcore-commonclasses/bin/classes/anag';
import { JwtPayload } from 'atfcore-commonclasses/bin/classes/auth';

import * as ProfileActions from './profile.actions';

export interface ProfileState {
  loggedUser: JwtPayload;
  editedUser: User;
  langs: Lang[]
}

const initialState: ProfileState = {
  loggedUser: null,
  editedUser: null,
  langs: null
};

export function profileReducer(state = initialState, action: any) {
  switch (action.type) {
    case ProfileActions.UPDATE_USER:
      const oldUserInfo = state.loggedUser;
      const updatedUser = {
        ...oldUserInfo,
        ...action.payload.loggedUser
      };
      const user = updatedUser;
      return {
        ...state,
        loggedUser: user,
        editedUser: null
      };
    case (ProfileActions.CANCEL_LOGGED_USER):
      // Cancello le informazioni sull'utente loggato
      return {
        ...state,
        loggedUser: null
      };
    case (ProfileActions.SAVE_AVAILABLE_LANGS):
      // Salva le lingue disponibili a sistema recuperate tramite servizi
      return {
        ...state,
        langs: action.payload
      };
    case (ProfileActions.DECODE_TOKEN):
      const jwt: any = jwt_decode(action.payload);
      // Decodifico il token ricevuto dopo il login e lo salvo, questo mi identificherà l'oggetto rappresentante l'utente loggato
      const decodedJwt: JwtPayload = {
        ...state.loggedUser,
        ...jwt
      };
      return {
        ...state,
        loggedUser: decodedJwt
      };
    case (ProfileActions.SET_LOGGED_SOCIAL_USER):
      return {
        ...state,
        loggedUser: action.payload
      };
    default:
      return state;
  }
}

// Export delle funzioni che tornano una parte dello Store applicativo
export const getLoggedUser = (state: ProfileState) => state.loggedUser;
export const getAvailableLangs = (state: ProfileState) => state.langs;
