/*
 * Componente per l'errore 403, utente non autorizzato
*/

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Component({
  selector: 'app-page-not-authorized',
  templateUrl: './page-not-authorized.component.html',
  styleUrls: ['./page-not-authorized.component.scss']
})
export class PageNotAuthorizedComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService,
    private router: Router) { }

  ngOnInit() {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Errore utente non autorizzato");
  }
}
