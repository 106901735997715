/*
 * Componente per la pagina di compilazione di una Survey
*/

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Item, ItemLang, ItemTypes, JwtPayload, Lang, SenecaResponse, SurveyAnswer, SurveyAttributeTypes, SurveyStructure } from "atfcore-commonclasses";
import { LangsService } from '../../core/services/langs.service';
import * as fromApp from '../../ngrx/app.reducers';
import * as langModel from '../../shared/models/lang.model';
import * as moment from 'moment';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AnalyticsService, VirtualPageViewCustomDimensions } from 'src/app/shared/services/analytics.service';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { ExtendedItem } from 'src/app/home/item-details/models/extended-item.model';
import { IDataItem } from 'src/app/home/item-details/models/item.model';
import { ModalService } from 'src/app/core/modal/modal-services.component';
import { getBaseUrlForRedirectFromItem, ItemsService } from '../services/items.service';
import { PlatformService } from '../services/platform.service';
import { getDecodedUrlParam, onceIfNotEmpty } from 'src/app/shared/utils';
import { ExtendedSurvey } from 'src/app/shared/models/extended-survey.module';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss']
})
export class SurveyComponent extends BaseSubscriberComponent implements OnInit {
    initiativeLoaded: boolean;
    initiativeId: string;
    itemId: string;
    defaultLang: string;
    applicationLang: string;
    availableLangs: Lang[];
    firstInitCompleted: boolean;
    initiative: ExtendedItem;
    loggedUser: JwtPayload;
    isFetchingData: boolean;
    currentLang: Lang;
    langList: Lang[];
    langMap: { [key: string]: Lang };
    currentItemLang: ItemLang;
    copyrightText: string;
    courseEdition: ExtendedItem;
    stopDateCourseSubscriptionFormat = langModel.dateFormats[2];
    timeFormats = langModel.timeFormats[0];
    timeFormat = langModel.timeFormats[0];
    eventDebouncer = new Subject();
    reload$ = new Subject();
    courseEditionId: string;
    isSurveyEditable: boolean;
    currentSurveyInfo: ExtendedSurvey;
    currentSurveyStructure: SurveyStructure[];
    pagesAndGroups: Array<any>;
    totalGroups: number;
    selectedGroup;
    currentPos: number;
    nextDisabled: boolean;
    prevDisabled: boolean;
    selectedPage;
    questions: Array<any>;
    percentageProgressLinear: any;
    percentageOfTotalQuestions: number;
    groupHint;
    isGroupHelpCollapsed: boolean;
    selectAnswer;
    getItemDetails$: Subscription;
    item: IDataItem;
    isItemSurvey: boolean;
    originId: {
        itemId?: string,
        projectId?: string,
        sectionId?: string,
        lpId?: string
    };
    customItemIdRedirect: string;

    constructor(private modalService: ModalService,
        private route: ActivatedRoute,
        private router: Router,
        private platformService: PlatformService,
        private itemService: ItemsService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private langsService: LangsService,
        public redirectService: RedirectService,
        private store: Store<fromApp.AppState>,
        private cdr: ChangeDetectorRef,
        private analyticsService: AnalyticsService) {
        super();

        // Verifica se sono nella parte amministrativa 
        if (this.router.url.indexOf('itemSurvey') !== -1) {
            this.isItemSurvey = true;
        } else {
            this.isItemSurvey = false;
        }
    }

    ngOnInit() {
        this.originId = {};
        this.initComponent();
        // Collegamento alla lingua di default
        const defaultLang$: Observable<string> = this.store.select(fromApp.getDefaultLang).pipe(onceIfNotEmpty());
        // Collegamento alla lingua scelta dall'utente
        const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang).pipe(onceIfNotEmpty());
        // Collegamento alle lingue disponibili
        const availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs).pipe(onceIfNotEmpty());
        // Collegamento all'utente loggato
        const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());
        const queryParams$ = this.route.queryParams;
        // Il cast <any> è necessario per evitare l'errore di Typescript che non riesce a costruire un combineLatest con più di 6 parametri
        const combinedSelectes$ = combineLatest<any>(this.route.params, defaultLang$,
            availableLangs$, applicationLang$, loggedUser$, this.reload$, queryParams$)
            .pipe(
                map(([params, defaultLang, availableLangs, applicationLang, loggedUser, reload, queryParams]) => {
                    this.initiativeLoaded = false;
                    // Salvo l'utente loggato
                    if (!this.loggedUser) {
                        this.loggedUser = loggedUser;
                    }
                    this.customItemIdRedirect = queryParams.customItemIdRedirect;
                    this.defaultLang = defaultLang;
                    this.availableLangs = availableLangs;
                    this.applicationLang = applicationLang;
                    // Se i parametri sono diversi, forzo l'aggiornamento dei dati
                    let initiativeId = params.initiativeId;
                    let itemId = this.isItemSurvey ? params.itemId : null;
                    this.originId = params;
                    let courseEditionId = decodeURIComponent(params.courseEditionId);

                    // L'id, recuperato dall'url, potrebbe essere: "ce93996b-45e4-4ec1-adad-3a88a995a8b5?customItemIdRedirect=e8f3b399-3737-47ed-b815-0423f1f927a1"
                    // Quindi cancello tutto quello che viene dopo la &
                    if (courseEditionId && courseEditionId.indexOf('?') > -1) {
                        courseEditionId = getDecodedUrlParam(courseEditionId);
                    }

                    let forceReloadInitiative = false;
                    if ((this.initiativeId && params.initiativeId) || (this.courseEditionId && params.courseEditionId)) {
                        forceReloadInitiative = true;
                    }
                    // Aggiorno i parametri presi dall'url
                    this.initiativeId = initiativeId;
                    this.itemId = itemId;
                    this.courseEditionId = courseEditionId;
                    if (!this.isItemSurvey && this.loggedUser && !this.isFetchingData && (forceReloadInitiative || !this.initiative)) {
                        this.loadInitiative();
                    }

                    if (this.isItemSurvey && this.itemId) {
                        this.loadItem();
                    }
                }),
                takeUntil(this.unsubscribe$)
            );

        combinedSelectes$.subscribe(() => {
            this.firstInitCompleted = true;
        });
        // avvia il primo caricamento dell'iniziativa e dell'edizione
        this.reload$.next('');
    }

    // Effettua il cambio di selezione sul multicheckbox
    radioInputChanged(target: any, newValue: string): void {
        target.selectedAnswerIds = newValue;
    }

    initComponent(reloadJustEdition?: boolean) {
        this.langList = [];
        this.langMap = {};
        // Verifica se una survey è editabile
        this.isSurveyEditable = false;
        this.isGroupHelpCollapsed = true;
        this.currentSurveyInfo = null;
        this.currentSurveyStructure = null;
        this.pagesAndGroups = [];
        this.totalGroups = 0;
        this.currentPos = 0; // Parto sempre dalla prima domanda
        this.nextDisabled = true;
        this.prevDisabled = true;
        this.selectedPage = null;
        this.selectedGroup = null;
        this.percentageOfTotalQuestions = 0;
        if (!reloadJustEdition) {
            this.currentItemLang = null;
            // Testo del copyright, valorizzato con l'anno della data attuale
            this.copyrightText = null;
            // Lista di edizioni dell'iniziativa
            this.courseEdition = null;
        }
        this.initiative = null;
        this.item = null;
        this.isFetchingData = false;
        // Trigger del refresh della Landing page
        this.reload$.next('');
    }

    loadItem() {
        this.isFetchingData = true;
        this.getItemDetails$ = this.itemService.getConsumableItemByIdForUser(this.translate, this.itemId)
            .pipe(
                map((item) => {
                    this.item = item;
                    this.loadSurveyInfo();

                    this.initLangs();

                    // Setto la scritta del copyright
                    this.setCopyrightText();
                })
            )
            .subscribe(data => {
                this.isFetchingData = false;
                this.initiativeLoaded = true;
            });
    }

    // Valorizza il testo contenente il copyright con l'anno attuale
    setCopyrightText() {
        let year = (new Date()).getFullYear();
        this.copyrightText = this.translate.instant("generic.COPYRIGHT") + " © " + year + " - Generali Italia S.p.a.";
    }

    // Carica i dati dell'iniziativa
    loadInitiative() {
        this.isFetchingData = true;
        this.platformService.getStageFromIdForPublic(this.loggedUser.user.userId, this.initiativeId)
            .subscribe(
                (initiaData: SenecaResponse<ExtendedItem>) => {
                    if (initiaData.error) {
                        // Vedo se c'è la traduzione dell'errore
                        this.toastr.error(this.translate.instant('errors.' + initiaData.error));
                        this.initiativeLoaded = true;
                        this.isFetchingData = false;
                    } else if (!initiaData.response) {
                        // Alzo l'errore di Item non trovato
                        this.toastr.error(this.translate.instant('errors.ITEM_NOT_FOUND'));
                        this.initiativeLoaded = true;
                        this.isFetchingData = false;
                    } else {
                        // Salvo i dati dell'iniziativa
                        this.initiative = initiaData.response;

                        this.initLangs();

                        // Setto la scritta del copyright
                        this.setCopyrightText();

                        let modules = [];
                        if (this.initiative && this.initiative.itemChilds) {
                            modules = this.initiative.itemChilds.map(x => x.childObject)
                                .filter(x => x && x.itemType === ItemTypes.COURSE_MODULE);
                        }
                        if (modules && modules.length) {
                            for (let q = 0, modulesLength = modules.length; q < modulesLength; q++) {
                                let currentModule = modules[q];
                                for (let i = 0, itemChildsLength = currentModule.itemChilds.length; i < itemChildsLength; i++) {
                                    let currentItemChild = currentModule.itemChilds[i];
                                    if (currentItemChild.childObject && currentItemChild.childObject.itemId === this.courseEditionId) {
                                        this.courseEdition = currentItemChild.childObject;
                                        break;
                                    }
                                }
                            }
                        }
                        let fromInitiative = false;
                        // Se non ho trovato l'edizione, ma il courseEditionId dell'id è identico all'initiativeId, significa che sto procedendo con una survey a livello di iniziativa
                        if (!this.courseEdition && this.initiativeId === this.courseEditionId) {
                            fromInitiative = true;
                            this.courseEdition = this.initiative;
                        }
                        if (this.courseEdition) {
                            // Se sono a livello di iniziativa, recupero subito la sruvey
                            if (fromInitiative) {
                                this.loadSurveyInfo();
                            } else {
                                this.getAssessmentsOfEdition()
                                    .then(() => {
                                        this.loadSurveyInfo();
                                    })
                                    .catch((err) => {
                                        this.initiativeLoaded = true;
                                        this.isFetchingData = false;
                                        this.toastr.error(this.translate.instant('errors.' + err.message));
                                    });
                            }
                        } else {
                            this.toastr.error(this.translate.instant('errors.ITEM_NOT_FOUND'));
                            this.initiativeLoaded = true;
                            this.isFetchingData = false;
                            this.backToItemDetails();
                        }
                    }
                },
                (err) => {
                    this.initiativeLoaded = true;
                    this.isFetchingData = false;
                    this.toastr.error(this.translate.instant('errors.' + err.message));
                });
    }

    // Abilita il pulsante di conferma
    btnEnabled() {
        let allOk = true;

        // Controllo se nelle domande obbligatorie ho almeno una risposta
        if (this.questions) {
            for (let j = 0; j < this.questions.length; j++) {
                for (let k = 0; k < this.currentSurveyInfo.surveyStructures.length; k++) {
                    if (this.questions[j].surveyStructureId == this.currentSurveyInfo.surveyStructures[k].surveyStructureId) {
                        let currentQuestion: any = this.questions[j];
                        if (currentQuestion.answerType === 'D') {
                            allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.dateAnswer : allOk;
                        } else if (currentQuestion.answerType === 'T' || currentQuestion.answerType === 'E') {
                            allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.textAnswer : allOk;
                        } else if (currentQuestion.answerType === 'F' || currentQuestion.answerType === 'N') {
                            allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.numericAnswer : allOk;
                        } else if (currentQuestion.answerType === 'S' || currentQuestion.answerType === 'C') {
                            allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.selectedAnswerIds : allOk;
                        } else if (currentQuestion.answerType === 'X') {
                            allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.selected : allOk;
                        } else if (currentQuestion.answerType === 'M') {
                            let isAtLeastOneSelected = false;
                            for (let m = 0, answersLength = currentQuestion.answers.length; m < answersLength; m++) {
                                let ans = currentQuestion.answers[m];
                                if (ans.selected) {
                                    // Segnalo che nella multipla checkbox obbligatoria c'è stata almeno una selezione
                                    isAtLeastOneSelected = true;
                                }
                            }
                            allOk = currentQuestion.answerMandatory == 'true' && allOk ? isAtLeastOneSelected : allOk;
                        }
                    }
                }
            }
        }
        if (allOk) {
            allOk = this.initiativeLoaded;
        }
        return allOk;
    };

    checkboxInputChanged(question) {
        question.selected = !question.selected;
    }

    // Inizializza e setta le lingue
    initLangs() {
        if (this.availableLangs && this.availableLangs.length) {
            for (let i = 0, availableLangsLength = this.availableLangs.length; i < availableLangsLength; i++) {
                let currentAvailableLang = this.availableLangs[i];
                if (currentAvailableLang.langCode === this.defaultLang) {
                    this.currentLang = currentAvailableLang;
                }
                this.langList.push(currentAvailableLang);
                this.langMap[currentAvailableLang.langCode] = currentAvailableLang;
            }
        }

        // Scelgo una lingua di default
        this.selectLang();
    }

    //  Metodo che passa all'elemento precedente
    prevItem() {
        this.initiativeLoaded = false;
        // Controllo se ho dato almeno una risposta, per vedere se salvare i dati
        let atLeastOneAnswer = false;
        if (this.questions) {
            for (let j = 0; j < this.questions.length; j++) {
                for (let k = 0; k < this.currentSurveyInfo.surveyStructures.length; k++) {
                    if (this.questions[j].surveyStructureId == this.currentSurveyInfo.surveyStructures[k].surveyStructureId) {
                        let currentQuestion: any = this.questions[j];
                        if (currentQuestion.answerType === 'D' && currentQuestion.dateAnswer) {
                            atLeastOneAnswer = true;
                        } else if ((currentQuestion.answerType === 'T' || currentQuestion.answerType === 'E') && currentQuestion.textAnswer) {
                            atLeastOneAnswer = true;
                        } else if (currentQuestion.answerType === 'F' || currentQuestion.answerType === 'N' && currentQuestion.numericAnswer) {
                            atLeastOneAnswer = true;
                        } else if ((currentQuestion.answerType === 'S' || currentQuestion.answerType === 'C') && currentQuestion.selectedAnswerIds) {
                            atLeastOneAnswer = true;
                        } else if (currentQuestion.answerType === 'X' && currentQuestion.selected) {
                            atLeastOneAnswer = true;
                        } else if (currentQuestion.answerType === 'M') {
                            for (let m = 0, answersLength = currentQuestion.answers.length; m < answersLength; m++) {
                                let ans = currentQuestion.answers[m];
                                if (ans.selected) {
                                    // Segnalo che nella multipla checkbox obbligatoria c'è stata almeno una selezione
                                    atLeastOneAnswer = true;
                                }
                            }
                        }
                    }
                }
            }
        }

        if (atLeastOneAnswer) {
            // Devo sempre salvare i dati
            let promise = this.saveData();
            // E agisco in base allo stato della promessa
            promise.then((obj: any) => {
                this.currentPos--;
                this.adjustSelectedPageAndGroupByPos(this.currentPos);
                // Carico i dati dell'item
                this.questions = [];
                // In base al gruppo attuale, mostro la pagina fissa di header o una dinamica
                this.loadQuestionsAndAnswers();
                this.percentageProgressLinear = (this.currentPos / this.totalGroups) * 100;
                this.initiativeLoaded = true;
            }, (err: any) => {
                this.toastr.error(err.message);
                this.initiativeLoaded = true;
            });
        } else {
            this.currentPos--;
            this.adjustSelectedPageAndGroupByPos(this.currentPos);
            // Carico i dati dell'item
            this.questions = [];
            // In base al gruppo attuale, mostro la pagina fissa di header o una dinamica
            this.loadQuestionsAndAnswers();
            this.percentageProgressLinear = (this.currentPos / this.totalGroups) * 100;
            this.initiativeLoaded = true;
        }
    };

    // Metodo che passa all'elemento successivo
    nextItem() {
        this.initiativeLoaded = false;
        // Devo sempre salvare i dati
        let promise = this.saveData();
        // E agisco in base allo stato della promessa
        promise.then((obj: any) => {
            if (this.percentageOfTotalQuestions == 0) {
                this.percentageOfTotalQuestions = this.percentageProgressLinear;
            }
            this.currentPos++;
            this.percentageProgressLinear = (this.currentPos / this.totalGroups) * 100;
            this.adjustSelectedPageAndGroupByPos(this.currentPos);
            // Carico i dati dell'item
            this.questions = [];
            this.loadQuestionsAndAnswers();
            this.initiativeLoaded = true;
        }, (err: any) => {
            this.toastr.error(err.message);
            this.initiativeLoaded = true;
        });
    };

    // Salva le domande e risposte correnti tornando una promessa
    saveData(closeSurvey?: boolean) {
        return new Promise((resolve, reject) => {
            if (this.isSurveyEditable) {
                // Verifico che tutte le domande obbligatorie abbiano una risposta
                let allOk = true;

                // Aggiungo le risposte nell'array giusto della Survey
                for (let j = 0; j < this.questions.length; j++) {
                    for (let k = 0; k < this.currentSurveyInfo.surveyStructures.length; k++) {
                        if (this.questions[j].surveyStructureId == this.currentSurveyInfo.surveyStructures[k].surveyStructureId) {
                            let dateNow: Date = new Date();

                            // Preparo l'oggetto che rappresenta la risposta alla domanda corrente
                            let currentAnswer: SurveyAnswer = {
                                surveyStructureId: this.questions[j].surveyStructureId,
                                textAnswer: null,
                                numericAnswer: null,
                                dateAnswer: null,
                                selectedAnswerIds: null,
                                creationDate: dateNow,
                                creationUserId: this.loggedUser.user.userId
                            };
                            // e lo valorizzo in base al tipo di domanda
                            // - "X": single checkbox
                            // - "M": multi checkbox
                            let currentQuestion: any = this.questions[j];
                            if (currentQuestion.answerType === 'D') {
                                // Data
                                currentAnswer.dateAnswer = currentQuestion.dateAnswer;
                                allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.dateAnswer : allOk;
                            } else if (currentQuestion.answerType === 'T' || currentQuestion.answerType === 'E') {
                                // Input di testo o textarea
                                currentAnswer.textAnswer = currentQuestion.textAnswer;
                                allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.textAnswer : allOk;
                            } else if (currentQuestion.answerType === 'F' || currentQuestion.answerType === 'N') {
                                // Input numerico
                                currentAnswer.numericAnswer = currentQuestion.numericAnswer;
                                allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.numericAnswer : allOk;
                            } else if (currentQuestion.answerType === 'S' || currentQuestion.answerType === 'C') {
                                // Radio button singolo oppure select
                                currentAnswer.selectedAnswerIds = currentQuestion.selectedAnswerIds;
                                allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.selectedAnswerIds : allOk;
                            } else if (currentQuestion.answerType === 'X') {
                                // Checkbox singolo
                                currentAnswer.textAnswer = currentQuestion.selected ? 'Y' : 'N';
                                allOk = currentQuestion.answerMandatory == 'true' && allOk ? currentQuestion.selected : allOk;
                            } else if (currentQuestion.answerType === 'M') {
                                let isAtLeastOneSelected = false;
                                // Checkbox multipli
                                currentAnswer.selectedAnswerIds = "";
                                for (let m = 0, answersLength = currentQuestion.answers.length; m < answersLength; m++) {
                                    let ans = currentQuestion.answers[m];
                                    if (ans.selected) {
                                        currentAnswer.selectedAnswerIds += (currentAnswer.selectedAnswerIds ? ";" : "") + ans.answerId;
                                        // Segnalo che nella multipla checkbox obbligatoria c'è stata almeno una selezione
                                        isAtLeastOneSelected = true;
                                    }
                                }
                                allOk = currentQuestion.answerMandatory == 'true' && allOk ? isAtLeastOneSelected : allOk;
                            }
                            // Metto tutta la questions perchè contiene anche i campi della SurveyAnswer
                            this.currentSurveyInfo.surveyStructures[k].surveyAnswer = currentAnswer;
                        }
                    }
                }

                // Se ho degli errori, mi fermo comunque
                if (!allOk) {
                    this.initiativeLoaded = true;
                    reject();
                } else {
                    let refItem = this.item && this.item.itemId ? this.item : this.courseEdition;
                    // Chiamo il server per l'aggiornamento della Survey
                    this.platformService.updateSurvey(this.loggedUser.user.userId, this.currentSurveyInfo, closeSurvey, refItem.itemId)
                        .subscribe(
                            (data: SenecaResponse<ExtendedSurvey>) => {
                                // Se c'è segnalo l'errore
                                if (data.error) {
                                    this.toastr.error(this.translate.instant('errors.' + data.error));
                                    this.initiativeLoaded = true;
                                    reject();
                                } else {
                                    resolve(data.response);
                                }
                            },
                            (err) => {
                                this.initiativeLoaded = true;
                                this.toastr.error(this.translate.instant('errors.' + err));
                                reject();
                            });
                }
            } else {
                // Non posso editare nè salavre, quindi do l'ok subito
                resolve(true);
            }
        })
    };

    // Recupera la survey (rilevazione) associata all'edizione
    getAssessmentsOfEdition() {
        let promises = [];
        // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
        if (this.courseEdition) {
            promises.push(new Promise((resolve: Function, reject: Function) => {
                this.platformService.getEditionAssessmentsForPublic(this.loggedUser.user.userId, this.courseEdition.itemId)
                    .subscribe(
                        (assessmentData: SenecaResponse<Item[]>) => {
                            if (assessmentData.error) {
                                // Vedo se c'è la traduzione dell'errore
                                this.toastr.error(this.translate.instant('errors.' + assessmentData.error));
                                reject();
                            } else if (assessmentData && assessmentData.response && assessmentData.response.length) {
                                // Attualmente ci può essere al massimo un assessment per ogni edizione
                                this.courseEdition.assessmentId = assessmentData.response[0].itemId;
                                resolve();
                            } else {
                                this.courseEdition.assessmentId = null;
                                resolve();
                            }
                        },
                        (err) => {
                            this.toastr.error(this.translate.instant('errors.' + err.message));
                            reject();
                        });
            }));
        }
        // Risolvo le promesse coi dati recuperati
        return Promise.all(promises);
    }

    // Carica i dati della survey e carica anche i gruppi di domande (accordion)
    loadSurveyInfo() {
        let id = this.itemId || this.courseEdition.assessmentId || this.courseEdition.itemId;
        this.platformService.getSurveys(this.loggedUser.user.userId, id, !this.itemId ? id : null, true)
            .subscribe(
                (data: SenecaResponse<ExtendedSurvey>) => {
                    if (data.error) {
                        // Vedo se c'è la traduzione dell'errore
                        this.toastr.error(this.translate.instant('errors.' + data.error));
                        this.initiativeLoaded = true;
                        this.isFetchingData = false;
                        this.backToItemDetails();
                    } else {
                        if (data.response && data.response.surveyStatus) {
                            if (data.response.surveyStatus == 'STARTED') {
                                // Indico che la survey è editabile
                                this.isSurveyEditable = true;
                            }
                            // Survey iniziata, quindi la salvo e procedo
                            this.currentSurveyInfo = data.response;
                        }

                        // Salvo la struttura della Survey, formattando la data
                        this.currentSurveyInfo.surveyDateFormatted = this.currentSurveyInfo && this.currentSurveyInfo.surveyDate && moment(this.currentSurveyInfo.surveyDate).format('lll');

                        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
                        const customDimension: VirtualPageViewCustomDimensions = {
                            id: id,
                            titleDetail: this.currentSurveyInfo.title
                        };
                        this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Questionario", customDimension);

                        // Recupero la struttura della Survey
                        this.platformService.getFlatSurveyBySurveyId(this.loggedUser.user.userId, this.currentSurveyInfo.surveyId)
                            .subscribe(
                                (surveyStructure: SenecaResponse<SurveyStructure[]>) => {
                                    if (surveyStructure.error) {
                                        // Vedo se c'è la traduzione dell'errore
                                        this.toastr.error(this.translate.instant('errors.' + surveyStructure.error));
                                        this.initiativeLoaded = true;
                                        this.isFetchingData = false;
                                    } else {
                                        // Salvo la struttura
                                        this.currentSurveyStructure = surveyStructure.response;
                                        // Ora recupero i gruppi di domande (body dell'accordion) le relative pagine (header dell'accordion)
                                        this.loadPagesAndGroups();
                                        this.isFetchingData = false;
                                        this.initiativeLoaded = true;
                                    }
                                },
                                (err) => {
                                    this.initiativeLoaded = true;
                                    this.isFetchingData = false;
                                    this.toastr.error(this.translate.instant('errors.' + err));
                                });
                    }
                },
                (err) => {
                    this.initiativeLoaded = true;
                    this.isFetchingData = false;
                    this.toastr.error(this.translate.instant('errors.' + err));
                    this.backToItemDetails();
                });
    }

    //  Carica i gruppi di domande (body dell'accordion) le relative pagine (header dell'accordion)
    loadPagesAndGroups() {
        // La struttura ce l'ho già per intero, quindi la elaboro direttamente
        this.pagesAndGroups.length = 0;
        // Ora faccio una rottura di codice sulle domande
        let lastItem: any = {};

        if (this.currentSurveyStructure && this.currentSurveyStructure.length) {
            for (let h = 0, structureLength = this.currentSurveyStructure.length; h < structureLength; h++) {
                let row = this.currentSurveyStructure[h];
                if (row.itemType === "P") {
                    lastItem = {
                        itemType: "P",
                        itemText: row.itemText,
                        groups: []
                    };
                    this.pagesAndGroups.push(lastItem);
                }
                else if (row.itemType === "G") {
                    lastItem.groups.push({
                        surveyStructureId: row.surveyStructureId,
                        itemType: "G",
                        itemText: row.itemText,
                        itemHint: row.itemHint
                    });
                }
            }
        }

        // Aggiorno il conteggio delle domande
        let count = 0;
        for (let i = 0; i < this.pagesAndGroups.length; i++) {
            count += this.pagesAndGroups[i].groups.length;
        }
        this.totalGroups = count;
        // Ricalcolo i bottoni prev e next
        this.changePrevNext();
        // E mostro la prima pagina
        this.showFirstPage();
    };

    // Cambia la posizione corrente
    changePrevNext() {
        this.nextDisabled = this.currentPos >= this.totalGroups - 1;
        this.prevDisabled = this.currentPos <= 0;
    };

    //  Funzione che calcola quali sono gli oggetti del gruppo e pagina correnti partendo dalla posizione
    adjustSelectedPageAndGroupByPos(pos) {
        let count = 0;
        for (let i = 0; i < this.pagesAndGroups.length; i++) {
            for (let j = 0; j < this.pagesAndGroups[i].groups.length; j++) {
                if (count === pos) {
                    this.currentPos = count;
                    this.changePrevNext();
                    this.selectedGroup = this.pagesAndGroups[i].groups[j];
                }
                count++;
            }
        }
    };

    // Annulla l'operazione di una modale di conferma
    genericModalDismiss() {
        return;
    }

    // Quando cambi una checkbox nel questionario
    onSelectedSurveyOptions(event, question) {
        question.selectedAnswerIds = event.answerId;
    }

    openConfirmSurveyModal() {
        this.modalService.open('confirmSurvey');
    }

    closeConfirmSurveyModal(confirm?: boolean) {
        this.modalService.close('confirmSurvey');
        if (confirm) {
            this.initiativeLoaded = false;
            let refItem = this.item && this.item.itemId ? this.item : this.initiative;
            let promise = this.saveData(true);
            // E agisco in base allo stato della promessa
            promise.then((obj: any) => {
                this.toastr.success(this.translate.instant('generic.DATA_SAVED'));
                this.initiativeLoaded = true;
                let baseUrl = this.getBaseUrlForRedirect();

                if (this.originId.lpId && this.originId.lpId.length) {
                    this.router.navigate([baseUrl + 'surveyResult', this.originId.lpId, refItem.itemId, this.currentSurveyInfo.surveyId]);
                } else if (this.originId.projectId && this.originId.projectId.length) {
                    this.router.navigate([baseUrl + 'surveyResult', this.originId.projectId, this.originId.sectionId, refItem.itemId, this.currentSurveyInfo.surveyId]);
                } else if (this.customItemIdRedirect) {
                    this.router.navigate([baseUrl + 'surveyResult', refItem.itemId, this.currentSurveyInfo.surveyId], { queryParams: { customItemIdRedirect: this.customItemIdRedirect } });
                } else {
                    this.router.navigate([baseUrl + 'surveyResult', refItem.itemId, this.currentSurveyInfo.surveyId]);
                }
            }, (err: any) => {
                this.toastr.error(err.message);
                this.initiativeLoaded = true;
            });
        }
    }

    //  Funzione che mostra la prima pagina del questionario, che è fissa
    showFirstPage() {
        // Selezione la pagina e il gruppo
        this.currentPos = 0;
        this.adjustSelectedPageAndGroupByPos(this.currentPos);
        // Carico i dati dell'item
        this.questions = [];
        this.loadQuestionsAndAnswers();
        this.percentageProgressLinear = (this.currentPos + 1 / this.totalGroups) * 100;
    };

    //  Metodo che carica le risposte alla domanda selezionata
    public loadQuestionsAndAnswers() {
        let lastItem: any = {};
        let currentAnswer: any = null;
        let useItem = false;
        this.questions = [];


        if (this.currentSurveyStructure && this.currentSurveyStructure.length) {
            for (let h = 0, structureLength = this.currentSurveyStructure.length; h < structureLength; h++) {
                let row = this.currentSurveyStructure[h];
                let answerType: string = null;
                let mandatory: string = null;
                // Recupero il tipo di risposta e il mandatory
                for (let i = 0; i < this.currentSurveyInfo.surveyStructures.length; i++) {
                    if (this.currentSurveyInfo.surveyStructures[i].surveyStructureAttributes && row.surveyStructureId === this.currentSurveyInfo.surveyStructures[i].surveyStructureId) {
                        for (let k = 0; k < this.currentSurveyInfo.surveyStructures[i].surveyStructureAttributes.length; k++) {
                            if (this.currentSurveyInfo.surveyStructures[i].surveyStructureAttributes[k].attributeType === SurveyAttributeTypes.QUESTION_TYPE) {
                                answerType = this.currentSurveyInfo.surveyStructures[i].surveyStructureAttributes[k].attributeValue;
                            } else if (this.currentSurveyInfo.surveyStructures[i].surveyStructureAttributes[k].attributeType === SurveyAttributeTypes.MANDATORY) {
                                mandatory = this.currentSurveyInfo.surveyStructures[i].surveyStructureAttributes[k].attributeValue;
                            }
                        }
                        break;
                    }
                }

                if (row.itemType === "G") {
                    useItem = row.surveyStructureId == this.selectedGroup.surveyStructureId;
                    if (useItem) {
                        this.groupHint = row.itemHint;
                    }
                }
                else if (useItem && row.itemType === "Q") {

                    let answs: any = [];
                    // Cerco se ho già una risposta per questa domanda
                    for (let k = 0; k < this.currentSurveyInfo.surveyStructures.length; k++) {
                        if (this.currentSurveyInfo.surveyStructures[k].surveyAnswer && row.surveyStructureId == this.currentSurveyInfo.surveyStructures[k].surveyStructureId) {
                            answs.push(this.currentSurveyInfo.surveyStructures[k].surveyAnswer);
                        }
                    }

                    if (answs && answs.length === 1) {
                        currentAnswer = answs[0];
                        // Prevalorizzo i valori temporanei delle question per gli oggetti di tipo:
                        // - "M": multi checkbox
                        if (currentAnswer.selectedAnswerIds && answerType === 'M') {
                            currentAnswer.idList = currentAnswer.selectedAnswerIds.split(";");
                        }

                        // Trasformo la data da stirnga in Data per l'md-datepicker
                        if (currentAnswer.dateAnswer && answerType === 'D') {
                            currentAnswer.dateAnswer = new Date(currentAnswer.dateAnswer);
                        }
                    }

                    else {
                        currentAnswer = null;
                    }
                    lastItem = {
                        surveyStructureId: row.surveyStructureId,
                        itemType: "Q",
                        answerType: answerType,
                        answerMandatory: mandatory,
                        itemText: row.itemText,
                        itemHint: row.itemHint,
                        isQuestionHelpCollapsed: true,
                        selectedAnswerIds: currentAnswer ? currentAnswer.selectedAnswerIds : null,
                        textAnswer: currentAnswer ? currentAnswer.textAnswer : null,
                        numericAnswer: currentAnswer ? currentAnswer.numericAnswer : null,
                        dateAnswer: currentAnswer ? currentAnswer.dateAnswer : null,
                        // Per tipo X
                        selected: !!currentAnswer && currentAnswer.textAnswer === 'Y',
                        answers: []
                    };
                    this.questions.push(lastItem);
                }
                else if (useItem && row.itemType === "A") {
                    lastItem.answers.push({
                        answerId: row.surveyStructureId,
                        itemType: "A",
                        itemText: row.itemText,
                        itemHint: row.itemHint,
                        // Per tipo M
                        selected: !!currentAnswer && !!currentAnswer.idList && currentAnswer.idList.filter((id) => {
                            return id == row.surveyStructureId
                        }).length === 1
                    });
                }
            }
        }
        this.isGroupHelpCollapsed = true;
    };

    getBaseUrlForRedirect() {
        return getBaseUrlForRedirectFromItem(this.router.url);
    }

    // Torna al dettaglio dell'item
    backToItemDetails() {
        let refItem = this.item && this.item.itemId ? this.item : this.initiative;
        let baseUrl = this.getBaseUrlForRedirect();

        if (this.originId.lpId && this.originId.lpId.length) {
            this.router.navigate([baseUrl + 'itemDetails', this.originId.lpId, refItem.itemId]);
        } else if (this.originId.projectId && this.originId.projectId.length) {
            this.router.navigate([baseUrl + 'itemDetails', this.originId.projectId, this.originId.sectionId, refItem.itemId]);
        } else if (this.customItemIdRedirect) {
            this.router.navigate([baseUrl + 'itemDetails', this.customItemIdRedirect]);
        } else {
            this.router.navigate([baseUrl + 'itemDetails', refItem.itemId]);
        }
    }

    // Imposta una lingua. Serve affinché nella View si esegua il binding sull'attributo collegato alla lingua impostata
    selectLang(lang?: Lang) {
        lang = lang || this.currentLang;
        let refItem: any = this.item && this.item.itemId ? this.item : this.initiative;
        // Lingua dell'iniziativa
        let initiativeIndex: number = this.langsService.findItemLangIndex(lang.langCode, refItem);
        this.currentItemLang = refItem.itemLangs[initiativeIndex];
        this.currentLang = lang;
    }

}