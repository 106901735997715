import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { combineLatest } from "rxjs/internal/observable/combineLatest";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { JwtPayload } from "atfcore-commonclasses/bin/classes/auth";

import * as fromApp from '../../ngrx/app.reducers';

import { ForumMockService } from "../services/forum-mock.service";
import { AnswerInterface, ForumPostsResponse, PostInterface } from "src/app/shared/interfaces/forum.interface";
import { ModalService } from "../modal/modal-services.component";
import { getTimeFromNow, getUserAvatar, getUserFullName } from "src/app/shared/utils";
import { BaseSubscriberComponent } from "src/app/shared/components/base-subscriber.component";

@Component({
  selector: "forum-page",
  templateUrl: "./forum-page.component.html",
  styleUrls: ["./forum-page.component.scss"],
})
export class ForumPageComponent extends BaseSubscriberComponent implements OnInit {

  params;
  loggedUser: JwtPayload;
  isAuthenticated: boolean;

  userFullName: string;
  avatar: string;

  postSubmitted: boolean = false;

  searchedText: string;

  posts: PostInterface[] = [];

  newPostForm: FormGroup;

  get postTitle(): string {
    return this.newPostForm.get('title').value;
  }

  get postDescription(): string {
    return this.newPostForm.get('description').value;
  }

  get searchedTextValid(): boolean {
    return this.searchedText !== undefined && this.searchedText !== null && this.searchedText.length > 0;
  }

  postPreviewLength: number = 270;

  postIndexToHide: number;

  constructor(
    private store: Store<fromApp.AppState>,
    private forumMockService: ForumMockService,
    private translate: TranslateService,
    private modalService: ModalService,
    private toastr: ToastrService) {
    super();

    this.newPostForm = new FormGroup({
      'title': new FormControl(undefined, [Validators.required, Validators.maxLength(100)]),
      'description': new FormControl(undefined, [Validators.required, Validators.maxLength(800)])
    });

  }

  ngOnInit() {
    const appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
    if (appInitiatives) {
      appInitiatives.scrollTop = 0;
    }

    // Combito tutti gli observable
    combineLatest([
      this.store.select(fromApp.isAuthenticated),
      this.store.select(fromApp.getLoggedUser),
      this.store.select(fromApp.getApplicationLang)
    ])
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        ([isAuthenticated, loggedUser, applicationLang]) => {

          this.isAuthenticated = isAuthenticated;
          this.loggedUser = loggedUser;

          if (applicationLang) {
            moment.locale(applicationLang);
          }

          this.userFullName = getUserFullName(this.loggedUser);
          this.avatar = getUserAvatar(this.loggedUser);

        });

    this.searchForumPosts();

  }

  /**
  * Search function
  */
  onInputSubmit(newValue?: string) {
    this.searchedText = newValue;
    this.searchForumPosts();
  }

  /**
    * Search post in the forum based on text
    */
  searchForumPosts() {
    this.forumMockService.getForumPosts(this.searchedText)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (data: SenecaResponse<ForumPostsResponse>) => {
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
          } else {
            this.posts = data.response.posts;
          }
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
        }
      );
  }

  /**
   * Get the difference from now to the date
   * Use of moment.js
   * @param date Date
   */
  getTimeFromNow(date: Date): string {
    return getTimeFromNow(date);
  }

  /**
   * Like a post
   * @param post Selected post
   */
  likePost(post: PostInterface) {
    post.isLiked = true;
    post.likes++;
  }

  /**
   * Unlike a post
   * @param post Selected post
   */
  unlikePost(post: PostInterface) {
    post.isLiked = false;
    post.likes--;
  }

  /**
   * If the post has arrow to expand
   * @param post Post
   */
  postHasArrow(post: PostInterface): boolean {
    return this.isAuthenticated || post.answers?.length > 0 || post.text.length > this.postPreviewLength;
  }

  openPost(post: PostInterface) {
    post.opened = true;
  }

  /**
   * Submit a comment on a post
   * @param index Post's index
   * @param value Comment input
   */
  onSubmitComment(index: number, input: any) {
    if (input && input.value) {
      const comment: AnswerInterface = {
        id: "",
        author: this.userFullName,
        srcImage: "",
        text: input.value,
        when: new Date()
      };
      input.value = "";
      this.posts[index].answers.push(comment);
    }
  }

  //#region NEW POST MODAL

  openNewPostModal() {
    this.postSubmitted = false;
    this.modalService.open("forumNewPost");
  }

  closeNewPostModal() {
    this.postSubmitted = false;
    this.modalService.close("forumNewPost");
  }

  createNewPost() {
    this.forumMockService.newPost(this.postTitle, this.postDescription)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
          } else {
            this.postSubmitted = true;
          }
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
        }
      );
  }

  //#endregion

  //#region NEW POST MODAL

  /**
   * Open a modal for hide a post
   * @param index Post's index
   */
  openHidePostModal(index: number) {
    this.postIndexToHide = index;
    this.modalService.open("forumHidePost");
  }

  closeHidePostModal() {
    this.postIndexToHide = undefined;
    this.modalService.close("forumHidePost");
  }

  hidePost() {
    const post: PostInterface = this.posts[this.postIndexToHide];
    this.forumMockService.hidePost(post.id)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
          } else {
            this.posts.splice(this.postIndexToHide, 1);
            this.closeHidePostModal();
          }
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
        }
      );
  }

  //#endregion

  /**
   * Scarica il documento con le linee guida
   */
  downloadGuidelines() {
    window.open('/assets/documents/linee_guida_forum.pdf');
  }

}
