export enum ModalBadgeId {
  CUP = 'modal-badge-cup',
  JOB = 'modal-badge-job',
  PROFILE = 'modal-badge-profile'
};

export interface ModalBadge {
  id: ModalBadgeId,
  iconSrc: string,
  title: string,
  coins: number
}
