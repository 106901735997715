import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export type BackType = 'homepage' | 'back' | 'news' | 'events' | 'jobs' | 'courses';

@Component({
  selector: 'back-button',
  templateUrl: 'back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  _label: string;
  @Input() label: BackType = 'back';
  @Input() disabled: boolean;

  _hasCustomBack: boolean;
  @Input() set hasCustomBack(value: boolean | string) {
    this._hasCustomBack = coerceBooleanProperty(value);
  };
  @Output() customBack = new EventEmitter<void>();

  constructor(private location: Location) {
  }

  ngOnInit() {
    this._label = 'component.backButtons.';
    switch (this.label) {
      case 'homepage':
        this._label = this._label + 'HOMEPAGE';
        break;
      case 'news':
        this._label = this._label + 'NEWS';
        break;
      case 'courses':
        this._label = this._label + 'COURSES';
        break;
      case 'events':
        this._label = this._label + 'EVENTS';
        break;
      case 'jobs':
        this._label = this._label + 'JOBS';
        break;
      case 'back':
      default:
        this._label = this._label + 'BACK';
        break;
    }
  }

  onBack() {
    if (this._hasCustomBack) {
      this.customBack.emit();
    } else {
      this.location.back();
    }
  }
}
