import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-utm-campaign',
  templateUrl: './utm-campaign.component.html'
})
export class UtmCampaignComponent {
  redirectUrl;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    private route: ActivatedRoute) {
    let redirectUrl$: any = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });

    this.route.queryParams.subscribe(params => {
      // Potrebbe essere che ci sia una gestione delle campagne di Analytics, quindi avrò un url di questo tipo: https://xxx/#/utmCampaign?utm_source=employeeup&utm_medium=stories&utm_campaign=sollecito16marzo 
      const utm_source = params && params['utm_source'];
      const utm_medium = params && params['utm_medium'];
      const utm_campaign = params && params['utm_campaign'];
      const utm_term = params && params['utm_term'];
      const utm_content = params && params['utm_content'];

      let suffixForAnalyticsCampaing = '';
      // Per le campagne di google, solo i parametri utm_term e utm_content sono facoltativi, gli altri sono obbligatori
      if (utm_source) {
        suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "?utm_source=" + utm_source;

        if (utm_medium) {
          suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_medium=" + utm_medium;
        }

        if (utm_campaign) {
          suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_campaign=" + utm_campaign;
        }

        if (utm_term) {
          suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_term=" + utm_term;
        }

        if (utm_content) {
          suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_content=" + utm_content;
        }
      }

      // Vado alla home con i parametri della campagna
      this.router.navigate(['home'], { queryParams: { utm_source: utm_source, utm_medium: utm_medium, utm_campaign: utm_campaign, utm_term: utm_term, utm_content: utm_content } });
    });
  }
}
