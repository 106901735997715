<app-modal class="modal-badge" id="modal-badge">
    <div class="modal-badge-info-container" id="modal-badge-container" fxLayout="column"
    fxLayoutAlign="start center">
  <div fxLayout="row" class="gallery-container">
    <!-- Freccia carosello galleria sinistra -->
    <div class="gallery-carousel-arrow left" fxLayout="column" fxLayoutAlign="center center">
      <svg-icon class="cursor-pointer" src="assets/icons/icon-arrow-left.svg" fxHide.xs [fxShow]="showLeftArrow"
        (click)="scrollToLeftBadgeInfo()"></svg-icon>
    </div>
    <generali-carousel [preventOverflowMarginOnRight]="true" class="badge-info-generali-carousel" noScrollDesktop #badgesInfoCarousel hidePoints
      sectionId="modal-badge-carousel" [contentLength]="contentLength" [scrollToRight]="scrollToRight.asObservable()"
      [(index)]="carouselIndex" (onScrollEvent)="onScrollEvent($event)" [scrollToLeft]="scrollToLeft.asObservable()">
      <div *ngFor="let currentModal of entireObject" class="container" fxLayout="column"
        fxLayoutAlign="space-between center">

        <div fxLayout="column" fxLayoutAlign="start center">
          <!-- BADGE ICON -->
          <svg-icon [src]="currentModal.iconSrc">
          </svg-icon>
          <!-- NEW BADGE -->
          <h5 fxFlexOffset="28px" fxFlexOffset.xs="24px" class="bold" translate="badge.NEW_BADGE"></h5>

          <!-- TITLE -->
          <h3 fxFlexOffset="12px" fxFlexOffset.xs="16px" class="bold">{{ ('badges.titles.' + currentModal.badgeType) | translate }}</h3>

          <!-- LEVEL -->
          <h3 class="level-description" fxFlexOffset="12px" fxFlexOffset.xs="16px">{{ ('badges.levels.' + currentModal.level) | translate }}</h3>
        </div>

        <!-- CONTINUE -->
        <custom-button ngClass.xs="full-width" [text]="'generic.CONTINUE_UPPER' | translate"
          (onClick)="closeBadgeModal()">
        </custom-button>

      </div>
    </generali-carousel>
    <!-- Freccia carosello galleria destra -->
    <div *ngIf="contentLength > 1" class="gallery-carousel-arrow right" fxLayout="column" fxLayoutAlign="center center">
      <svg-icon class="cursor-pointer" src="assets/icons/icon-arrow-right.svg" [fxShow]="showRightArrow" fxHide.xs
        (click)="scrollToRightBadgeInfo()"></svg-icon>
    </div>
  </div>
  </div>
</app-modal>
