<div *ngIf="!fromRight" fxLayout="row" fxLayoutAlign="end end" style="padding: 5px 10px" [ngStyle]="containerStyle">
  <img *ngIf="imgSrc" class="img-sender" placement="bottom" tooltip-class="custom-tooltip" [src]="imgSrc"
    [ngStyle]="customImageStyle">
  <!-- <user-placeholder *ngIf="!imgSrc" dimension="chat-dimension" [words]="firstLetters" style="margin: 0px 4px;">
    </user-placeholder> -->

  <div fxLayout="column">
    <p class="user-name">{{userName}}</p>
    <div class="welcomebox left" fxLayout="row">
      <div class="message" [innerHTML]="text" style="display: inline;"
        [ngStyle]="{'padding-right': closable ? '20px' : '2px'}"></div>
      <img *ngIf="closable" width="15" src="assets/img/icons/close-icon.png" (click)="clickClose()">
    </div>
  </div>
</div>
<div *ngIf="fromRight" fxLayout="row" fxLayoutAlign="end end" style="padding: 5px 10px" [ngStyle]="containerStyle">
  <div fxLayout="column">
    <p class="user-name">{{userName}}</p>
    <div class="welcomebox right" fxLayout="row" fxLayoutAlign="space-between end">
      <img *ngIf="closable" width="15" src="assets/img/icons/close-icon.png" (click)="clickClose()">

      <div class="message" [innerHTML]="text" [ngStyle]="{'padding-left': closable ? '20px' : '2px'}"></div>
    </div>
  </div>

  <img *ngIf="imgSrc" class="img-sender" placement="bottom" tooltip-class="custom-tooltip" [src]="imgSrc"
    [ngStyle]="customImageStyle">
  <!--  <user-placeholder *ngIf="!imgSrc" dimension="chat-dimension" [words]="firstLetters" style="margin: 0px 4px;">
    </user-placeholder> -->

</div>
