import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import * as smoothscroll from 'smoothscroll-polyfill';

@Component({
  selector: 'slick-section-content',
  templateUrl: './slick-section-content.component.html',
  styleUrls: ['./slick-section-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlickSectionContentComponent implements OnInit {
  @Input() sectionId: string;
  @Input() courseData: any;
  @Input() contentLength: number;
  @Input() preventShowArrows: boolean;
  @Input() darkBackground: boolean;
  @Input() isInLearningSystem: boolean;
  @Input() isContentBlocked: boolean;
  @Input() fullItemBlocked;
  @Input() isFromYourAppsHome: boolean;
  @Output() onContentBlockedClicked = new EventEmitter<{ itemId: string, webCourseType?: string, isItemLocked?: boolean, singleItemChild?, sectionObj?}>();
  cardClicked = {};
  clickY;
  scrollLeft;
  startX;
  leftLimit: boolean = true;

  constructor() {
  }

  ngOnInit() {
    this.sectionId = this.sectionId;
    this.courseData = this.courseData;
    this.contentLength = this.contentLength;
    this.darkBackground = this.darkBackground;
    smoothscroll.polyfill();
  }

  emitOnContentBlockedClicked() {
    const navigationData = {
      itemId: this.fullItemBlocked,
      isItemLocked: this.isContentBlocked,
      sectionObj: this.fullItemBlocked
    }
    this.onContentBlockedClicked.emit(navigationData);
  }

  scrollCards(elementId: string, direction: string) {
    const scrollDistance = 280;
    // Recupero l'elemento in cui c'Ã¨ lo scroll
    const courseCardWrapperElement = document.getElementById(elementId);
    if (courseCardWrapperElement) {
      const sl = courseCardWrapperElement.scrollLeft,
        cw = courseCardWrapperElement.scrollWidth;
      if (direction === 'right') {
        this.leftLimit = false;
        if ((sl + scrollDistance) >= cw) {
          courseCardWrapperElement.scrollTo({
            left: cw,
            behavior: 'smooth'
          });
        } else {
          courseCardWrapperElement.scrollTo({
            left: sl + scrollDistance,
            behavior: 'smooth'
          });
        }
      } else {
        if ((sl - scrollDistance) <= 0) {
          this.leftLimit = true;
          courseCardWrapperElement.scrollTo({
            left: 0,
            behavior: 'smooth'
          });
        } else {
          courseCardWrapperElement.scrollTo({
            left: sl - scrollDistance,
            behavior: 'smooth'
          });
        }
      }
    }
  }

  setCardClicked(elementId: string, value: boolean) {
    this.cardClicked[elementId] = value;
  }

  cardsMouseUp(elementId: string, e) {
    this.setCardClicked(elementId, false);
  }

  cardsMouseDown(elementId: string, e: MouseEvent) {
    this.setCardClicked(elementId, true);
    const courseCardWrapperElement = document.getElementById(elementId);
    if (courseCardWrapperElement) {
      this.startX = e.pageX - courseCardWrapperElement.offsetLeft;
      this.scrollLeft = courseCardWrapperElement.scrollLeft;
      this.clickY = e.x;
    }
  }

  cardsMouseMove(elementId: string, e) {
    if (!this.cardClicked[elementId]) return;
    e.preventDefault();
    const courseCardWrapperElement = document.getElementById(elementId);
    if (courseCardWrapperElement) {
      const x = e.pageX - courseCardWrapperElement.offsetLeft;
      const walk = (x - this.startX) * 1.5;
      courseCardWrapperElement.scrollLeft = this.scrollLeft - walk;
      if (courseCardWrapperElement.scrollLeft == 0) {
        this.leftLimit = true;
      } else {
        this.leftLimit = false;
      }
    }
  }

}
