import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { CourseItem } from 'atfcore-commonclasses/bin/classes/talent-farm';

import { UrlService } from 'src/app/shared/services/url.service';
import {
  handleArgumentsIds,
  handleFromRecord,
  handleNumRecords,
  handleSearchedText,
  handleSeniorities,
  handleSorting,
  handleTagIds,
} from 'src/app/shared/utils';
import { GLPShowcaseCardWithCount } from 'atfcore-commonclasses';

@Injectable()
export class CourseService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Retrieves available courses that matches the criteria
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {string[]} argumentsIds - Optional:  TagIds of Arguments to be used as filter
   * @param {string[]} softSkillIds - Optional
   * @param {string[]} technicalSkillIds - Optional
   * @param {string[]} seniorities - Optional
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @param {boolean} startedCoursesOnly - Retieves only the courses that has been started by the user
   * @return {number}
   */
  countCourses(
    tagId?: string,
    searchedText?: string,
    argumentsIds?: string | string[],
    seniorities?: string | string[],
    mySavedOnly?: boolean,
    startedCoursesOnly?: boolean,
    profiledForMe?: boolean
  ): Observable<SenecaResponse<number>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = startedCoursesOnly
      ? httpParams.append('startedCoursesOnly', 'true')
      : httpParams;
    httpParams = mySavedOnly
      ? httpParams.append('mySavedOnly', 'true')
      : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleTagIds(httpParams, tagId);
    httpParams = handleArgumentsIds(httpParams, argumentsIds);
    httpParams = handleSeniorities(httpParams, seniorities);
    if (profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true');
    }

    return this.http.get<SenecaResponse<number>>(
      this._mediatorUrl + 'count-courses',
      { params: httpParams }
    );
  }

  /**
   * Retrieves the specified course
   * @param {string} itemId - Required: Course's itemId
   * @return {CourseItem}
   */
  getCourseById(itemId: string): Observable<SenecaResponse<CourseItem>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<SenecaResponse<CourseItem>>(
      this._mediatorUrl + 'get-course-by-id',
      { params: httpParams }
    );
  }

  /**
   * Retrieves the specified course
   * @param {string} itemId - Required: Course's itemId
   * @return {CourseItem}
   */
  getOfcoursemeCollectionDetailsById(
    itemId: string
  ): Observable<SenecaResponse<CourseItem>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<SenecaResponse<CourseItem>>(
      this._mediatorUrl + 'get-ofcourseme-collection-details-by-id',
      { params: httpParams }
    );
  }

  /**
   * Retrieves available courses that matches the criteria
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string} sorting - Optional:  Flag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc) (default D if no filter, S otherwise)
   * @param {string[]} argumentsIds - Optional:  TagIds of Arguments to be used as filter
   * @param {string[]} softSkillIds - Optional
   * @param {string[]} technicalSkillIds - Optional
   * @param {string[]} seniorities - Optional
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @param {boolean} startedCoursesOnly - Retieves only the courses that has been started by the user
   * @return {CourseItem[]}
   */
  listCourses(
    tagId?: string,
    fromRecord?: number,
    numRecords?: number,
    searchedText?: string,
    argumentsIds?: string | string[],
    seniorities?: string | string[],
    mySavedOnly?: boolean,
    startedCoursesOnly?: boolean,
    sorting?: string,
    allData?: boolean,
    itemIdsToIgnore?: string,
    profiledForMe?: boolean,
    ignoreDeadlineOrder?: boolean
  ): Observable<SenecaResponse<CourseItem[]>> {
    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = handleSearchedText(httpParams, searchedText);
    if (sorting) {
      if (sorting == 'S') {
        httpParams = httpParams.append('profiledForMe', 'true');
      } else {
        httpParams = httpParams.append('orderBy', sorting);
      }
    }

    if (ignoreDeadlineOrder) {
      httpParams = httpParams.append('orderBy', 'D');
    }
    httpParams = mySavedOnly
      ? httpParams.append('mySavedOnly', 'true')
      : httpParams;
    httpParams = startedCoursesOnly
      ? httpParams.append('startedCoursesOnly', 'true')
      : httpParams;
    httpParams = handleTagIds(httpParams, tagId);
    httpParams = handleArgumentsIds(httpParams, argumentsIds);
    httpParams = handleSeniorities(httpParams, seniorities);

    if (profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true');
    }

    if (itemIdsToIgnore && itemIdsToIgnore.length) {
      httpParams = httpParams.append('itemIdsToIgnore', itemIdsToIgnore);
    }

    return this.http.get<SenecaResponse<CourseItem[]>>(
      this._mediatorUrl + 'list-courses',
      { params: httpParams }
    );
  }

  // Servizio che recupera i corsi che potresti completare
  getUserStartedCoursesList(fromRecord: number, numRecords: number, searchedText?: string, argumentsTags?: string[], sorting?: string, durationValue?: any, itemTypes?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            httpParams = httpParams.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }

    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      httpParams = httpParams.append('durationValue', JSON.stringify(durationValue));
    }

    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-started-items-to-complete/', {
      params: httpParams
    });
  }

  // Servizio che recupera i corsi che potresti completare
  glpGetSuggestedItemsByCategoriesPreferences(fromRecord: number, numRecords: number, onlyPlaylist?: boolean, searchedText?: string, argumentsTags?: string[], sorting?: string, durationValue?: any, itemTypes?, onlyProfiled?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    if (onlyPlaylist) {
      httpParams = httpParams.append('onlyPlaylist', 'true');
    }

    if (onlyProfiled) {
      httpParams = httpParams.append('onlyProfiled', 'true');
    }

    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            httpParams = httpParams.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }

    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      httpParams = httpParams.append('durationValue', JSON.stringify(durationValue));
    }

    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-suggested-items-by-categories-preferences', {
      params: httpParams
    });
  }

  // Servizio che recupera i corsi "inclusion"
  glpGetCoursesInclusionForCarousel(fromRecord: number, numRecords: number, searchedText?: string, argumentsTags?: string[], sorting?: string, durationValue?: any, itemTypes?) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());

    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            httpParams = httpParams.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }

    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      httpParams = httpParams.append('durationValue', JSON.stringify(durationValue));
    }

    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-courses-inclusion-for-carousel', {
      params: httpParams
    });
  }

  /**
   * Retrieves available courses that matches the criteria
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string} sorting - Optional:  Flag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc) (default D if no filter, S otherwise)
   * @param {string[]} argumentsIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @return {CourseItem[]}
   */
  listSuggestedCourses(
    tagId?: string,
    fromRecord?: number,
    numRecords?: number,
    searchedText?: string,
    argumentsIds?: string | string[],
    mySavedOnly?: boolean,
    sorting?: string
  ): Observable<SenecaResponse<CourseItem[]>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);
    httpParams = mySavedOnly
      ? httpParams.append('mySavedOnly', 'true')
      : httpParams;
    httpParams = handleArgumentsIds(httpParams, argumentsIds);
    httpParams = handleTagIds(httpParams, tagId);

    return this.http.get<SenecaResponse<CourseItem[]>>(
      this._mediatorUrl + 'list-suggested-courses',
      { params: httpParams }
    );
  }

  getOfCourseMeCollections(fromRecord: number, numRecords: number, searchedText?: string, argumentsTags?: string[], sorting?: string, durationValue?: any, itemTypes?, retrieveBookmark?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());

    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            httpParams = httpParams.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (retrieveBookmark) {
      httpParams = httpParams.append('retrieveBookmark', "true");
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }

    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      httpParams = httpParams.append('durationValue', JSON.stringify(durationValue));
    }

    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-ofcourseme-collections', {
      params: httpParams,
    }
    );
  }

  /**
   * 
   * @param fromRecord 
   * @param duration 
   * @param category 
   * @param orderBy 
   * @returns 
   */
  glpGetIdpCatalogCourses(fromRecord?: string, duration?: string[], category?: string | string[], orderBy?: string, searchTxt?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("fromRecord", fromRecord ? fromRecord : "0");
    httpParams = httpParams.append("numRecords", "12");

    // Se c'è un filtro per durata
    if (duration) {
      httpParams = httpParams.append("durationValue", JSON.stringify(duration));
    }

    // Se c'è un filto per ordinamento
    if (orderBy) {
      httpParams = httpParams.append("sorting", orderBy["tagId"]);
    }

    if (searchTxt) {
      httpParams = httpParams.append("searchedText", searchTxt);
    }

    // le categorie vanno inserite singolarmente e non come array quindi prima controllo se il valore passato è un array
    if (category && Array.isArray(category)) {
      // Faccio un ciclo per aggiungere ai param tutte le categorie selezionato 
      category.forEach(cat => {
        httpParams = httpParams.append("argumentsTags", cat["tagId"])
      });
    } else if (category && !Array.isArray(category)) {
      // Altrimenti appendo semplicemente la categoria 
      httpParams = httpParams.append("argumentsTags", category["tagId"])
    }

    // Eseguo la chiamata
    return this.http.get<SenecaResponse<void>>(
      this._mediatorUrl + 'glp-get-idp-catalog-courses', {
      params: httpParams
    });

  }


  /**
   * Save for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  saveCourseForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(
      this._mediatorUrl + 'save-course-for-later',
      { itemId }
    );
  }

  /**
   * Save for later the specified item for ofcourseme
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  saveOfcoursemeCourseForLater(
    itemId: string
  ): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(
      this._mediatorUrl + 'save-ofcourseme-collection-for-later',
      { itemId }
    );
  }

  /**
   * Remove for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  removeCourseFromSavedForLater(
    itemId: string
  ): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(
      this._mediatorUrl + 'remove-course-from-saved-for-later',
      { itemId }
    );
  }

  /**
   * Remove for later the specified item for ofcourseme
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  removeOfcoursemeCourseFromSavedForLater(
    itemId: string
  ): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(
      this._mediatorUrl + 'remove-ofcourseme-collection-from-saved-for-later',
      { itemId }
    );
  }

  // Compra un contenuto premium oppure un premio da riscattare
  reedemPrize(itemId: string) {
    return this.http.post<SenecaResponse<number>>(
      this._mediatorUrl + 'redeem-prize',
      { itemId: itemId }
    );
  }

  sendValutation(itemId: string, vote: number) {
    return this.http.post<SenecaResponse<CourseItem>>(
      this._mediatorUrl + 'judge-course',
      { itemId: itemId, vote: vote }
    );
  }

  // Scarica il certificato
  downloadCertificate(
    userId: string,
    itemId: string,
    editionId: string,
    asImage?: boolean
  ) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('stageId', itemId);
    httpParams = httpParams.append('getFullPath', 'false');
    if (editionId && editionId.length) {
      httpParams = httpParams.append('editionId', editionId);
    }
    if (asImage) {
      httpParams = httpParams.append('asImage', asImage.toString());
    }
    return this.http.get<SenecaResponse<string>>(
      this._mediatorUrl + 'get-user-initiative-certificate-for-public',
      {
        params: httpParams,
      }
    );
  }

  getDownloadTempFileUrl(filename: string, ssorqtp: string) {
    return (
      this._mediatorUrl +
      'download-temp-file?filename=' +
      filename +
      '&ssortkqp=' +
      ssorqtp
    );
  }

  getFeaturedCourses(
    fromRecord: number = 0,
    numRecords: number = 50,
    allData: boolean = false,
    onlyNotCompleted: boolean = false, searchedText?: string, argumentsTags?: string[], sorting?: string, durationValue?: any, itemTypes?
  ): Observable<SenecaResponse<GLPShowcaseCardWithCount>> {

    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord && fromRecord.toString());
    params = params.append('numRecords', numRecords && numRecords.toString());
    params = params.append('allData', allData.toString());

    if (onlyNotCompleted) {
      params = params.append('onlyNotCompleted', onlyNotCompleted.toString());
    }

    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            params = params.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          params = params.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (searchedText && searchedText.length) {
      params = params.append('searchedText', searchedText || '');
    }
    if (status) {
      params = params.append('status', status);
    }
    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          params = params.append('argumentsTags', argumentsTag);
        });
      } else {
        params = params.append('argumentsTags', argumentsTags);
      }
    }

    if (sorting) {
      params = params.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      params = params.append('durationValue', JSON.stringify(durationValue));
    }

    return this.http.get<SenecaResponse<GLPShowcaseCardWithCount>>(
      `${this._cm2MediatorUrl}glp-get-featured-courses`,
      { params }
    );
  }

  getTagsInspiredForUsers(
    fromRecord: number = 0,
    numRecords: number = 50,
    allData: boolean = false
  ): Observable<SenecaResponse<any>> {

    let params = new HttpParams();
    params = params.append('fromRecord', fromRecord && fromRecord.toString());
    params = params.append('numRecords', numRecords && numRecords.toString());
    params = params.append('allData', allData.toString());

    return this.http.get<SenecaResponse<any>>(
      `${this._cm2MediatorUrl}glp-get-tags-inspired-for-users`,
      { params }
    );
  }

  // Recupera corsi Learning System per la parte a destra della tabella nel My Learning System oppure nella ricerca globale se cè il cerca ovunque
  getLearningSystemCoursesList(fromRecord: number, numRecords: number, useSearch?: boolean, searchedText?: string, status?: string, argumentsTags?: string[], languages?: string, techSkillsOnly?: boolean, softSkillsOnly?: boolean, functionalAreas?: string[], clusters?: string[], itemTypes?: string[], levels?: string[], sorting?: string, createEngagement?: boolean, ignoreobjectTypeSpecializations?: string[], techCompetences?: string[], durationValue?: any) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (useSearch) {
      httpParams = httpParams.append('useSearch', useSearch && useSearch.toString());
    }
    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }
    if (languages && languages.length) {
      if (Array.isArray(languages)) {
        for (let i = 0; i < languages.length; i++) {
          if (languages[i] == 'en') {
            languages[i] = 'LANG_EN';
          }
          if (languages[i] == 'it') {
            languages[i] = 'LANG_IT';
          }
        }
        languages.forEach(language => {
          httpParams = httpParams.append('langs', language);
        });
      } else {
        if (languages == 'en') {
          languages = 'LANG_EN';
        } else if (languages == 'it') {
          languages = 'LANG_IT';
        }
        httpParams = httpParams.append('langs', languages);
      }
    }
    if (functionalAreas && functionalAreas.length) {
      if (Array.isArray(functionalAreas)) {
        functionalAreas.forEach(functionalArea => {
          httpParams = httpParams.append('functionalAreas', functionalArea);
        });
      } else {
        httpParams = httpParams.append('functionalAreas', functionalAreas);
      }
    }
    if (clusters && clusters.length) {
      if (Array.isArray(clusters)) {
        clusters.forEach(cluster => {
          httpParams = httpParams.append('clusters', cluster);
        });
      } else {
        httpParams = httpParams.append('clusters', clusters);
      }
    }
    if (techSkillsOnly) {
      httpParams = httpParams.append('techSkillsOnly', techSkillsOnly.toString());
    }
    if (softSkillsOnly) {
      httpParams = httpParams.append('softSkillsOnly', softSkillsOnly.toString());
    }
    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            httpParams = httpParams.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (techCompetences && techCompetences.length) {
      if (Array.isArray(techCompetences)) {
        techCompetences.forEach(techCompetence => {
          httpParams = httpParams.append('techCompetences', techCompetence);
        });
      } else {
        httpParams = httpParams.append('techCompetences', techCompetences);
      }
    }

    if (levels && levels.length) {
      if (Array.isArray(levels)) {
        levels.forEach(level => {
          httpParams = httpParams.append('levels', level);
        });
      } else {
        httpParams = httpParams.append('levels', levels);
      }
    }

    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      httpParams = httpParams.append('durationValue', JSON.stringify(durationValue));
    }
    if (createEngagement) {
      httpParams = httpParams.append('createEngagement', createEngagement.toString());
    }
    if (ignoreobjectTypeSpecializations && ignoreobjectTypeSpecializations.length) {
      if (Array.isArray(ignoreobjectTypeSpecializations)) {
        ignoreobjectTypeSpecializations.forEach(ignoreobjectTypeSpecialization => {
          httpParams = httpParams.append('ignoreobjectTypeSpecializations', ignoreobjectTypeSpecialization);
        });
      } else {
        httpParams = httpParams.append('ignoreobjectTypeSpecializations', ignoreobjectTypeSpecializations);
      }
    }
    return this.http.get<SenecaResponse<any>>(`${this._cm2MediatorUrl}glp-get-learning-system-items-and-courses`, {
      params: httpParams
    });
  }

  glpGetTopTenMostViewedCourseAndObjects(
    fromRecord: number = 0,
    numRecords: number = 50,
    allData: boolean = false, searchedText?: string, argumentsTags?: string[], sorting?: string, durationValue?: any, itemTypes?
  ): Observable<SenecaResponse<any>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
    httpParams = httpParams.append('allData', allData.toString());

    if (itemTypes && itemTypes.length) {
      if (Array.isArray(itemTypes)) {
        itemTypes.forEach(itemType => {
          if (itemType != 'OF_COURSE_ME') {
            httpParams = httpParams.append('objectTypeSpecializations', itemType);
          }
        });
      } else {
        if (itemTypes != 'OF_COURSE_ME') {
          httpParams = httpParams.append('objectTypeSpecializations', itemTypes);
        }
      }
    }

    if (searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    if (status) {
      httpParams = httpParams.append('status', status);
    }
    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }

    if (sorting) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if (durationValue && durationValue.length) {
      httpParams = httpParams.append('durationValue', JSON.stringify(durationValue));
    }

    return this.http.get<SenecaResponse<any>>(
      `${this._cm2MediatorUrl}glp-get-top-ten-most-viewed-course-and-objects`,
      { params: httpParams }
    );
  }

  // Recupera corsi dal web per il MyLearning e cerca globale
  getOfCourseMeCourses(fromRecord: number, numRecords: number, searchedText?: string, langs?: string, argumentsTags?: string[], retrieveBookmark?: boolean) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', fromRecord.toString());
    httpParams = httpParams.append('numRecords', numRecords.toString());
    if (searchedText) {
      httpParams = httpParams.append('searchedText', searchedText.toString());
    }
    if (retrieveBookmark) {
      httpParams = httpParams.append('retrieveBookmark', "true");
    }
    if (langs && langs.length) {
      if (Array.isArray(langs)) {
        for (let i = 0; i < langs.length; i++) {
          if (langs[i] == 'LANG_EN') {
            langs[i] = 'en';
          }
          if (langs[i] == 'LANG_IT') {
            langs[i] = 'it';
          }
        }
        langs.forEach(lang => {
          httpParams = httpParams.append('langCode', lang);
        });
      } else {
        if (langs == 'LANG_EN') {
          langs = 'en';
        } else if (langs == 'LANG_IT') {
          langs = 'it';
        }
        httpParams = httpParams.append('langCode', langs);
      }
    }

    if (argumentsTags && argumentsTags.length) {
      if (Array.isArray(argumentsTags)) {
        argumentsTags.forEach(argumentsTag => {
          httpParams = httpParams.append('argumentsTags', argumentsTag);
        });
      } else {
        httpParams = httpParams.append('argumentsTags', argumentsTags);
      }
    }
    return this.http.get<SenecaResponse<any>>(`${this._cm2MediatorUrl}glp-get-ofcourseme-courses`, {
      params: httpParams
    });
  }
}
