import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, Params, RoutesRecognized } from '@angular/router';
import { Subscription, Observable, throwError, of, combineLatest } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ItemsService, libraryItems, libraryContainers, onlineItems, getBaseUrlForRedirectFromItem } from '../services/items.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { CourseEditionService } from '../services/course-edition.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';
import { switchMap, catchError, take } from 'rxjs/operators';
import { ItemUtil } from 'src/app/home/item-details/models/item.model';
import { InitiativeUtils } from 'src/app/home/item-details/models/initiatives.model';
import { CourseEditionUtil, locationNameForCard, CourseEdition, CourseDaySchedule, CourseModuleUtil } from 'src/app/home/item-details/models/course.model';
import { CommonConstants, SenecaResponse, MapById, ReferenceTypes } from 'atfcore-commonclasses/bin/classes/common';
import { ItemTypes, ItemAttributeObjectTypes, ItemTakerEnrollStatusTypes, Item, ItemAttributeMaterialTypes, ItemFrontEndWrapper, ItemAttributeTypes, ItemChild, EnrichedItemTaker, ItemLang } from 'atfcore-commonclasses/bin/classes/item';
import { LikeTypes, Like } from 'atfcore-commonclasses/bin/classes/like';
import { Lang } from 'atfcore-commonclasses/bin/classes/anag';
import { sortByRules } from 'atfcore-commonclasses/bin/classes/utils';
import { Note } from 'atfcore-commonclasses/bin/classes/note';
import { EngagementDetailKeys } from 'atfcore-commonclasses/bin/classes/engagement';
import { ErrorCodes } from 'atfcore-commonclasses/bin/classes/errors';
import { RentStatusTypes } from 'atfcore-commonclasses/bin/classes/rent';
import { CourseManagerItem } from 'atfcore-commonclasses/bin/classes/coursemanager';
import { Survey, SurveyStatuses, SurveyAttributeTypes, SurveyTypes } from 'atfcore-commonclasses/bin/classes/survey';
import { JwtPayload, RequiredAuth } from 'atfcore-commonclasses/bin/classes/auth';
import { AnalyticsService, VirtualPageViewCustomDimensions } from 'src/app/shared/services/analytics.service';
import { CKEditorConfigLibraryObjects, GlpItem, objectTypesToDownload, objectTypesToOpen, PL_COVERS } from 'src/app/home/item-details/utils/item-details.utils';
import { ModalService } from 'src/app/core/modal/modal-services.component';
import { genericResponseSubscriber, MATERIAL_VISIBILITY_DELTA_HOURS } from 'src/commonclasses';
import { coursesCardMapper, getDecodedUrlParam } from 'src/app/shared/utils';
import { GlobalApplicationData } from 'src/app/shared/models/global-application-data.model';
import { PlatformService } from '../services/platform.service';
import { ScrollTo } from 'src/app/shared/services/scroll-to.service';
import { CourseDateCatalog } from 'atfcore-commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';


// Dato che il fe modifica i dasti ritornati dal be, segno in un type apposito tutte le varie modifiche
type FormattedCourseDateCatalog = CourseDateCatalog & {
  // Vengono aggiunte properties anche ai days
  days: Array<CourseDaySchedule & {
    formattedFullDate: string;
    formattedTimeSchedule: string;
  }>;
  customPlace: string;
  tutors: any[];
  teachers: any[];
  lastDayDate: string;
  firstDayDate: string;
  formattedStatus: string;
  formattedStopDate: string;
  isOnlineEvent: boolean;
  isConfirmed: boolean;
  isPresent: boolean;
  isCancelled: boolean;
  isInvited: boolean;
  isOnlineCourse: boolean;
  isExternalEvent: boolean;
  isCascadingMeeting: boolean;
  usedSeats: number
  seatsLimit: number;
  availableSeats: number;
  usedSeatsOB: number;
  seatsLimitOB: number;
  availableSeatsOB: number;
  stageItemType: string;
  isMorning: boolean;
  labelStatusToDisplay: string;
  callToAction: string;
  labelForSelectOption: string;
  subscriptionCustomMail: string;
  subscriptionCustomLink: string;
  subscriptionInfoMail: string;
}
@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss']
})
export class ItemDetailsComponent implements OnInit, OnDestroy {
  // Subscriptions
  getApplicationLang$: Subscription;
  getItemDetails$: Subscription;
  markNotificationAsReadByReference$: Subscription;
  toggleWishlist$: Subscription;
  addChilds$: Subscription;
  countItems$: Subscription;
  createLike$: Subscription;
  deleteLike$: Subscription;
  toggleBookmark$: Subscription;
  setWebCourseAsComplete$: Subscription;
  setWebCourseAsOpened$: Subscription;
  getItemNote$: Subscription;
  checkRentableStatus$: Subscription;
  checkMyRentableStatus$: Subscription;
  getRentHistory$: Subscription;
  getCertificationFile$: Subscription;
  suggestItemToUser$: Subscription;
  countUsersToSuggestItem$: Subscription;

  // Vari id nell'url
  itemId: string;
  projectId: string;
  wrapperInitiativeId: string;
  sectionId: string;
  editingProjectId: string;
  editingSectionId: string;
  breadcrumbs: any[] = [];
  itemToAddId: string;
  lpId: string;
  editingLearningPlanId: string;
  editingLpId: string;
  isDraft: boolean;

  // Loader
  isGettingItemDetails: boolean;

  // Altri parametri
  itemDetails: any; // Il type GlpItem non è corretto
  applicationLang: string;
  adminMode: boolean = false;
  isExternalEvent: boolean;
  isExternalCourseClass: boolean;
  isExternalCourseOnline: boolean;
  isExternalEventClass: boolean;
  isExternalEventOnline: boolean;
  isExternalCourse: boolean;
  isSyllabusCourse: boolean;
  isCatalogStage: boolean;
  isCourseWithLimitedSeats: boolean;
  isSurveyItem: boolean;
  isAssessment: boolean;
  isLearningPlan: boolean;
  isProject: boolean;
  isSection: boolean;
  isAtomicItem: boolean;
  itemTypeLabel: string;
  duration;
  iLikeThis: boolean;
  iDislikeThis: boolean;
  editions: any[];
  editionsSurvey: any[];
  certifiedEditions: any[];
  editionsListWithPastEditions: any[];
  editionsByPlace: any[];
  isVideo: boolean;
  isItemOtherOrPhysicalType: boolean;
  isImage: boolean;
  isPodcast: boolean;
  isBook: boolean;
  isEBook: boolean;
  isGraph: boolean;
  isMagazine: boolean;
  isDvd: boolean;
  isDocument: boolean;
  isOnlineCourse: boolean;
  isCourseOnlineStage: boolean;
  courseEndDate: string;
  loggedUser: JwtPayload;
  materials: any[];
  preworkMaterialsCounter: number = 0;
  classroomMaterialsCounter: number = 0;
  postworkMaterialsCounter: number = 0;
  totMaterialsCounter: number = 0;
  materialListNumRecords = 20;
  currentMaterialListPage = 1;
  currentMaterialListCounter = 0;
  isFetchingMaterialsFiles = true;
  selectedMaterialTab: string;
  isLoadingEditions: boolean = true;
  selectedPlace;
  selectedEditionForConfirmSubscription;
  selectedEditionForCancelSubscription;
  itemIdLinkedToCourse: string;
  stopDateCourseSubscriptionFormat = 'DD MMMM YYYY';
  timeFormat = 'HH:mm';
  idsAdded: string[] = [];
  teachersTutorsList: any = [];
  isGettingLikes: boolean;
  currentLike: Like;
  selectedTab;
  isGettingSurveys: boolean;
  isSurveyStarted: boolean;
  isSurveyStartedLibrary: boolean;
  isSurveyCertificable: boolean;
  isSurveyCertificableLibrary: boolean;
  isToggleWishlist: boolean;
  isToggleBookmark: boolean;
  likeCounter: {
    likes: number,
    dislikes: number
  };
  isOpenDamBtnDisabled: boolean;
  itemLinkedToCourse;
  isDownloadingFile: boolean;
  selectedMaterialForModal;
  attachmentUrl: string;
  isDownloadingExternalResource: boolean;
  globalApplicationData: GlobalApplicationData;
  hasMoreEditions: boolean = false;
  isWebinar: boolean = false;
  isCascadingMeeting: boolean;
  isUsingInternetExplorer: boolean;
  isGettingChilds: boolean;
  childObjects: any;
  childObjectTmp: GlpItem[];
  surveyMapForPlaylist: {
    [itemId: string]: {
      isSurveyStarted: boolean,
      isSurveyCertificable: boolean,
      isItemCertifiable: boolean
    }
  };
  isItemCertifiable: boolean;
  itemsInContainerCertified: number;
  childsNotCertifiedButCertifiables: number;
  totalChildsWithSurvey: number;
  certificationImageUrl: string;
  linkedinUrl: string;
  enableCertification: boolean;
  showPropedeuticalItemToThisModal: {
    propedeuticalObject: any,
    selectedItem: any
  };
  propedeuticalObject;
  attachmentList: Array<ItemChild & {
    name?: string;
    childObject: Item & {
      name?: string;
    }
  }>;
  hasTheUserTheCertificateAssigned: boolean;
  showItemCertificationLoader: boolean;
  certificationDate;
  youCanInterestedCoursesData: any;
  setPage1CanInterested: boolean;
  firstTimeResearchInterested: boolean = true;
  callOnlyInterestedCoursesService: boolean;
  isFetchingInterestedCourses: boolean;
  getInterestedCourses$: Subscription;
  firstInterestedCardInitDone: boolean;
  youCanInterestedCoursesShowAll: boolean;
  youCanInterestedCoursesPage: number;
  descriptionExpanded: boolean
  isGettingRentableStatus: boolean;
  isGettingRentHistory: boolean;
  isGettingNotes: boolean;
  itemNote: Note;
  selectedSecondaryAction;
  itemNoteTmp;
  secondaryActionOptions: any[];
  libraryItemsData: {
    libraryItemsFromRecord: number;
    libraryItemsNumRecords: number;
    libraryItemsCurrentPage: number;
    libraryItemsCounter: number;
    libraryItemsLoaded: number;
    libraryItems: any[];
    resetLibraryItems: boolean;
    selectedItemId: string;
    searchedText?: string;
    libraryItemLoaded: boolean;
  };
  isFetchingLibraryItems: boolean;
  isFetchingUsersToSuggest: boolean;
  usersToSuggestData: {
    usersToSuggestFromRecord: number,
    usersToSuggestNumRecords: number,
    usersToSuggestCurrentPage: number,
    usersToSuggestCounter: number,
    usersToSuggestLoaded: number,
    usersToSuggest: any[],
    resetUsersToSuggest: boolean,
    selectedUserId: string,
    searchedText: string
  };
  isEuropAssistanceUser: boolean;
  collectionId: string
  webCourseId: string
  isSetWebCourseAsComplete: boolean;
  webItemChildsData;
  itemChildsData;
  webinarCoursesByDay: any[];
  webinarSelectedDay;
  parentCollectionId: string;
  containerTitleOfCurrentItem: string
  clusterList: any[];
  isFetchingClusters: boolean;
  isBanneImageAlwaysVisible: boolean;
  hasToCollapseDetailsContent: boolean;
  isDetailsContentOpened: boolean;
  canAskForRent: boolean;
  canManageLearningPlan: boolean;
  isRentableAvaiable: boolean;
  isMyRentablePendingRequest: boolean;
  isRequestStillRejectable: boolean;
  itemRented: boolean;
  isRentConfirmed: boolean;
  rentHistoryList: any[];
  selectedPendingRequestRent;
  isAbortRentVisible: boolean;
  availableLangs: Lang[];
  defaultLang: string;
  langList: Lang[];
  langMap: { [key: string]: Lang };
  currentLang: Lang;
  defaultItemLang: ItemLang;
  currentItemLang: ItemLang;
  langInitForPlaylistDone: boolean = false;
  newPlaylistObj: Item;
  ckeConfig: any;
  additionalEnrollAttributes: any[];
  editionsCertifications: any[];
  isFetchingCoursesCertifications: boolean;
  translations: any;
  isGettingParentDetails: boolean;
  showBanner: boolean = false;
  bannerTotalItem: any;
  completedItems: number = 0;
  firstIncompleteId: any;
  totalLpProgress: number;
  firstIncompleteSectionId: any;
  showLpContinue: boolean;
  isKalturaPlayerVisible: boolean = false;
  privacyObject: any;
  isPlaylistPlan: boolean;
  specialProjectId: string;

  certificateUrl: string;
  mapNotShowCertificateBannerMessageOnDownload = new Map();
  mapNotShowCertificateBannerMessageOnShare = new Map();
  editionCertificationToShare;
  allCertificatesDownload: boolean;
  allCertificatesShare: boolean;

  isItemOnline: boolean = false;

  selectedEditionOfMainSelect;

  selectedCourseDateId: string;
  currentWebinarEditions: any = [];
  currentPlaceEditions: any = [];

  tabs;

  isLoadingValutation: boolean;
  surveySended: boolean = false;
  surveyPoints: number = 0;
  surveyPointsSelected: number = 0;
  sendValutation$;
  partialScore: number = 0;
  isItemCollection: boolean = false;
  isWebCollectionDetails: boolean = false;

  isSubscribedToAnEdition: boolean = false;
  firsSubscribedEdition = null;
  assessmentEditionsForSurveysByInitiativeEditionId: {};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemsService,
    private urlService: UrlService,
    private toastr: ToastrService,
    private store: Store<fromApp.AppState>,
    private surveyService: CourseEditionService,
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private modalService: ModalService,
    private translate: TranslateService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private scrollTo: ScrollTo,
    private redirectService: RedirectService
  ) {

    this.ckeConfig = CKEditorConfigLibraryObjects;
    this.isUsingInternetExplorer = this.isInternetExplorerBrowser();
    // Collegamento alla lingua di default
    const defaultLang$: Observable<string> = this.store.select(fromApp.getDefaultLang);
    // Recupero la lingua settata dallo store applicativo, per settare la lingua in cui eseguire le traduzioni
    let applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang);
    const availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    const globalApplicationData$: Observable<GlobalApplicationData> = this.store.select(fromApp.getGlobalApplicationData);
    //  const clusterlist$: Observable<any[]> = this.store.select(fromApp.getClusters);
    //  const isFetchingClusters$: Observable<boolean> = this.store.select(fromApp.isFetchingClusters);

    const combinedSelectes$ = combineLatest<any>(defaultLang$, availableLangs$, applicationLang$, loggedUser$, globalApplicationData$);
    this.getApplicationLang$ = combinedSelectes$.subscribe(([defaultLang, availableLangs, applicationLang, loggedUser, globalApplicationData]) => {
      this.applicationLang = applicationLang;
      this.defaultLang = defaultLang;
      this.availableLangs = availableLangs;
      // this.clusterList = clusterList;
      // this.isFetchingClusters = isFetchingClusters;

      this.loggedUser = loggedUser;

      if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
        for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
          let currentAuth = this.loggedUser.auths[i];
          if (currentAuth === RequiredAuth.LIBRARY_ADMIN_MANAGE_ITEM || currentAuth === RequiredAuth.LIBRARY_ASK_FOR_RENT) {
            this.canAskForRent = true;
          }
          if (currentAuth === RequiredAuth.LIBRARY_MANAGE_LEARNING_PLAN) {
            this.canManageLearningPlan = true;
          }
        }
        this.isEuropAssistanceUser = !!(loggedUser.user.userTenant.tenant === "EUASS_GRP");
      }
      this.globalApplicationData = globalApplicationData;
      // Setto la lingua
      if (applicationLang) {
        this.applicationLang = applicationLang;
        moment.locale(this.applicationLang);
      }
    });
  }

  ngOnInit() {
    this.itemService.getListCourseCertificates().subscribe((res) => {
    })
    this.route.params
      .subscribe(
        (params: Params) => {
          this.scrollTo.header();

          this.wrapperInitiativeId = params.wrapperInitiativeId;
          this.lpId = params.lpId;
          this.specialProjectId = params.specialProjectId;
          let appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
          if (appInitiatives) {
            appInitiatives.scrollTop = 0;
          }
          this.translate.get(
            [
              'itemDetailsPage.YOU_COMPLETED',
              'itemDetailsPage.LP_OBJECTS',
              'itemDetailsPage.PL_OBJECTS',
              'generic.INFORMATIONS',
              'itemDetailsPage.tabs.SURVEYS',
              'itemDetailsPage.tabs.MATERIALS',
              'generic.CERTIFICATIONS',
              'generic.ATTACHMENTS',
              'itemDetailsPage.tabs.MAP'
            ])
            .subscribe(translations => {
              this.translations = translations;
              this.resetData(params);
              this.getItemDetails();
            });
        }
      );
  };

  // Recupera il nome dell'eventuale oggetto Web contenitore
  getWebContainerTitle() {
    return new Promise<void>((resolve, reject) => {
      // Se ho il parent significa che sto guardando il corso web di una collezione, quindi recupero il suo titolo
      if (this.parentCollectionId) {
        let parentCollectionTitlePromise = this.getItemTitle(this.parentCollectionId);
        parentCollectionTitlePromise.then((collectionTitle: any) => {
          if (collectionTitle) {
            this.containerTitleOfCurrentItem = collectionTitle;
          }
          resolve();
        })
          .catch(() => {
            resolve();
          })
      } else {
        resolve();
      }
    })
  }

  // Recupera il nome dell'eventuale oggetto contenire
  getItemContainerTitle() {
    return new Promise<void>((resolve, reject) => {
      if (this.lpId || this.projectId) {
        // Recupero l'eventuale nome del contenitore (in questo caso il nome della Playlist)
        if (this.lpId) {
          let parentPlaylistTitlePromise = this.getItemTitle(this.lpId);
          parentPlaylistTitlePromise.then((playlistTitle: any) => {
            if (playlistTitle) {
              this.containerTitleOfCurrentItem = playlistTitle;
            }
            resolve();
          })
            .catch(() => {
              // Anche se non ho il nome del contenitore, continuo col flusso
              resolve();
            })
        }
        // Recupero l'eventuale nome del contenitore (in questo caso il nome del progetto, e poi della sezione)
        if (this.projectId) {
          let parentProjectTitlePromise = this.getItemTitle(this.projectId);
          parentProjectTitlePromise.then((projectTitle: any) => {
            if (projectTitle) {
              this.containerTitleOfCurrentItem = projectTitle;
              if (this.sectionId) {
                this.containerTitleOfCurrentItem = this.containerTitleOfCurrentItem + ' - ';
              }
            }
            if (this.sectionId) {
              let parentSectionTitlePromise = this.getItemTitle(this.sectionId);
              parentSectionTitlePromise.then((sectionTitle) => {
                if (sectionTitle) {
                  this.containerTitleOfCurrentItem = this.containerTitleOfCurrentItem + sectionTitle;
                }
                resolve();
              })
                .catch(() => {
                  // Anche se non ho il nome del contenitore, continuo col flusso
                  resolve();
                })
            } else {
              resolve();
            }
          })
            .catch(() => {
              // Anche se non ho il nome del contenitore, continuo col flusso
              resolve();
            })
        }
      } else {
        resolve();
      }
    });
  }

  // Recupero i dati dell'item
  getItemDetails() {
    this.isGettingItemDetails = true;

    if (this.collectionId || this.webCourseId) {
      // Si tratta di corsi dal web che non hanno nulla a che fare con le nostre piattaforme
      let serviceToUse;
      if (this.collectionId) {
        serviceToUse = this.platformService.glpGetOfCoursemeCollectionById(this.collectionId, true, true);
      } else {
        serviceToUse = this.platformService.glpGetOfCoursemeCourseById(this.webCourseId, true, true);
      }

      // Recupero il titolo dell'eventuale oggetto web contenitore
      const webContainerTitle = this.getWebContainerTitle();
      webContainerTitle.then(() => {
        this.getItemDetails$ = serviceToUse
          .subscribe((data) => {
            if (data.error) {
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + data.error));
              this.goBack();
            } else if (data.response) {
              this.duration = data.response.courseDuration || null;

              if (data.response.courseDuration && parseInt(data.response.courseDuration) > 0) {
                if (parseInt(data.response.courseDuration) > 60) {
                  this.duration = Math.floor(parseInt(data.response.courseDuration) / 60) + "h " + parseInt(data.response.courseDuration) % 60 + "m";
                } else {
                  this.duration = parseInt(data.response.courseDuration) % 60 + "m";
                }
              }

              data.response.title = data.response.courseTitle || data.response.collectionTitle || '';
              data.response.cardCoverUrl = data.response.courseImage || null;
              data.response.webItemChilds = data.response.courses || null;
              if (data.response.courses && data.response.courses.length) {

              }
              data.response.webCourseSyllabus = data.response.courseSyllabus || null;

              /* Rimuovo tutti gli eventuali tag html presenti
              if (data.response.webCourseSyllabus
                && data.response.webCourseSyllabus.length) {
                data.response.webCourseSyllabus = data.response.webCourseSyllabus.replace(/(<([^>]+)>)/gi, "");;
              } */

              this.itemDetails = data.response;
              this.isWebCollectionDetails = this.router.url.indexOf('webCollectionDetails') !== -1 ? true : false;

              if (this.isWebCollectionDetails && this.itemDetails?.courses?.length) {
                this.itemDetails.courses = coursesCardMapper(this.itemDetails.courses, this.translate);
              }

              if (this.itemDetails) {
                if (this.collectionId) {
                  this.itemTypeLabel = this.translate.instant('itemDetailsPage.generic_card.COLLECTION');
                } else {
                  this.itemTypeLabel = this.translate.instant('itemDetailsPage.generic_card.WEB_COURSE');
                }
                this.getLikes();
              }
            }

            this.partialScore = Math.round(this.itemDetails.judgement);

            this.isGettingItemDetails = false;
            this.isLoadingEditions = false;

            this.getTabs();

            // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
            const customDimentions: VirtualPageViewCustomDimensions = {
              id: this.collectionId || this.webCourseId,
              titleDetail: this.itemDetails.title,
              itemType: this.itemTypeLabel
            }
            const pageTitle = this.containerTitleOfCurrentItem ? this.containerTitleOfCurrentItem + " - " + this.itemDetails.title : this.itemDetails.title;
            this.analyticsService.sendVirtualPageViewEvent(this.router.url, pageTitle, customDimentions);
          },
            (err) => {
              this.goBack();
              this.toastr.error(this.translate.instant('errors.' + err.message));
              this.isGettingItemDetails = false;

            });
      })
    } else {
      let itemId = this.itemId || this.itemToAddId;

      // Devo gestire la data scadenza in tutte le pagine tranne nel learning system
      const ignoreDeadlinePassedError = this.router.url.indexOf('learningSystem') !== -1 ? true : false;

      this.isItemCollection = this.router.url.indexOf('itemCollectionDetails') !== -1 ? true : false;

      // Prima recupero il nome dell'eventuale contenitore associato. E' stata aggiunta in una promise perché bisogna attendere il nome dell'eventuale contenitore per utilizzarlo nel tracciamento di Google Tag Manager
      const containerTitlePromise = this.getItemContainerTitle();
      containerTitlePromise.then(() => {
        let service = !this.isItemCollection ? this.itemService.getItemForGlp(itemId, true, ignoreDeadlinePassedError) : this.itemService.getItemCollectionForGlp(itemId, true);
        this.getItemDetails$ = service
          .subscribe((response) => {
            if (response.error) {
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + response.error));
              this.goBack();
            } else if (response.response) {
              // se ci sono salvo i dati relativi alla privacy
              if (response.response.privacy) {
                this.privacyObject = response.response.privacy;
              }
              // Se sono in un item che fa parte di un learning plan o una playlist, recupero i dati del parent per il riepilogo
              this.getParentInfo(itemId, response, true);
              this.surveyPointsSelected = this.itemDetails?.myJudgement;
            } else {
              this.isGettingItemDetails = false;
            }
          },
            (err) => {
              this.goBack();
              this.toastr.error(this.translate.instant('errors.' + err.message));
              this.isGettingItemDetails = false;

            });
      })
    }
  }

  // Ritorna i dati di riepilogo dell'eventuale item parent
  getParentInfo(itemIdToFormat?, itemToFormat?: SenecaResponse<GlpItem>, showLoader?) {
    let parentDataPromise;
    if (this.projectId && this.sectionId) {
      if (showLoader) {
        this.isGettingParentDetails = true;
      }
      parentDataPromise = this.itemService.getItemForGlp(this.projectId, false, true).toPromise();
    } else if (this.lpId && this.itemId) {
      if (showLoader) {
        this.isGettingParentDetails = true;
      }

      parentDataPromise = this.itemService.getItemForGlp(this.lpId, false, true).toPromise();
    }
    if (parentDataPromise) {
      parentDataPromise.then((parentData) => {
        if (parentData && parentData.response) {
          parentData = parentData.response;
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
        // lavorare qua con i dati del parent
        // numero totale di oggetti della playlist / LP
        this.bannerTotalItem = 0;
        this.completedItems = 0;
        this.firstIncompleteId = null;
        this.firstIncompleteSectionId = null;
        // se ho i dati dell'oggetto contenitore, controllo gli itemChild
        // se è un LP allora il primo livello di itemChild sono le sezioni
        if (parentData.itemChilds && parentData.itemChilds.length) {
          let sections = parentData.itemChilds;
          // se è una playlist
          if ((this.lpId && this.itemId) || this.wrapperInitiativeId && !this.projectId) {
            this.bannerTotalItem = sections.length;
            let childOrder = -1;
            this.isPlaylistPlan = true;
            // non ho le sezioni come il learning plan, quindi gli oggetti sono al primo livello
            for (let i = 0; i < sections.length; i++) {
              // se ha l'oggetto associato
              if (sections[i].childObject) {
                let tmpItem = sections[i].childObject;
                if (tmpItem && tmpItem?.engagements?.length) {
                  let isItemComplete = tmpItem?.engagements?.filter((x) => x.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED);
                  if (!isItemComplete || (isItemComplete && !isItemComplete.length)) {
                    isItemComplete = false
                    if (!this.firstIncompleteId || (this.firstIncompleteId && sections[i].childOrder < childOrder)) {
                      this.firstIncompleteId = tmpItem.itemId;
                    }
                  } else if (isItemComplete && isItemComplete[0]) {
                    isItemComplete = true;
                    this.completedItems++;
                  }
                } else if (!this.firstIncompleteId || (this.firstIncompleteId && sections[i].childOrder < childOrder)) {
                  // Se non ho engagement potrebbe essere il prossimo oggetto da visualizzare nella playlist
                  this.firstIncompleteId = tmpItem.itemId;
                }
              }
            }

          } else {
            // Per ogni sezione mi salvo il numero di oggetti, salvo riferimento al primo non completo e controllo quanti oggetti sono completati
            let sectionChildOrder = 99999;
            let itemChildOrder = -1;

            for (let i = 0; i < sections.length; i++) {
              if (sections[i].childObject && sections[i].childObject.itemChilds && sections[i].childObject.itemChilds.length) {
                let section = sections[i].childObject;
                this.bannerTotalItem += sections[i].childObject.itemChilds.length;
                for (let j = 0; j < section.itemChilds.length; j++) {
                  let tmpItem = section.itemChilds[j] && section.itemChilds[j].childObject;
                  let tmpItemContainer: any = section.itemChilds[j];
                  if (tmpItem) {
                    let tmpCompleted = tmpItem.engagements?.filter((x: any) => x.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED);
                    let isItemComplete;
                    if (tmpCompleted && tmpCompleted.length) {
                      isItemComplete = true;
                    }
                    // prendo il primo incompleto
                    if (!isItemComplete &&
                      (!this.firstIncompleteId ||
                        (this.firstIncompleteId &&
                          (sectionChildOrder > sections[i].childOrder ||
                            (sectionChildOrder >= sections[i].childOrder && itemChildOrder <= tmpItemContainer.childOrder))
                        ))) {

                      sectionChildOrder = sections[i].childOrder;
                      itemChildOrder = tmpItemContainer.childOrder;
                      this.firstIncompleteId = tmpItem.itemId;
                      this.firstIncompleteSectionId = section.itemId;
                    }

                    if (isItemComplete) {
                      this.completedItems++;
                    }
                  }
                }
              }
            }

          }
        }
        if (this.bannerTotalItem) {
          this.totalLpProgress = Math.floor((this.completedItems / this.bannerTotalItem) * 100);
        }
        if (itemIdToFormat && itemToFormat) {
          this.formatItemData(itemIdToFormat, itemToFormat, true);
        }
      })
    } else {
      if (itemIdToFormat && itemToFormat) {
        this.formatItemData(itemIdToFormat, itemToFormat);
      }
    }
  }

  isPlaylistContainer() {
    if (this.itemDetails && this.itemDetails.itemType == ItemTypes.CONTAINER && this.itemDetails.objectType == "LEARNING_PLAN") {
      return true;
    }
    return false;
  }

  formatItemData(itemId: string, response: SenecaResponse<GlpItem>, parentDataLoader?: boolean) {
    this.itemDetails = response.response;
    if (this.itemDetails?.itemChilds?.length && (this.isItemCollection)) {
      for (let i = 0; i < this.itemDetails.itemChilds.length; i++) {
        this.itemDetails.itemChilds[i] = this.itemDetails.itemChilds[i].childObject;
      }
      this.itemDetails.itemChilds = coursesCardMapper(this.itemDetails.itemChilds, this.translate);
    }


    this.partialScore = Math.round(this.itemDetails.judgement);

    this.certificationDate = this.itemDetails.certificationDate;

    this.getLikes();

    // Se non sono admin, imposto come lette le eventuali modifiche
    if (!this.adminMode && this.itemDetails && this.itemDetails.itemId) {
      this.markNotificationAsReadByReference$ = this.itemService.markNotificationAsReadByReference(this.itemDetails.itemId, ItemTypes.ITEM, CommonConstants.APPLICATION_TRAINING_PLATFORM)
        .subscribe(data => {
          return;
        });
    };

    if (this.itemDetails.itemType === ItemAttributeObjectTypes.SECTION) {
      this.isSection = true;
    } else if (this.itemDetails.itemType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE) {
      this.isExternalCourseClass = true;
    } else if (this.itemDetails.itemType === ItemTypes.EXTERNAL_ONLINE_STAGE) {
      this.isExternalCourseOnline = true;
    } else if (this.itemDetails.itemType === ItemTypes.EVENT_CLASS_STAGE) {
      this.isExternalEventClass = true;
    } else if (this.itemDetails.itemType === ItemTypes.EVENT_ONLINE_STAGE) {
      this.isExternalEventOnline = true;
    }

    this.courseEndDate = this.itemDetails.deadlineDate && this.itemDetails.deadlineDate && moment(this.itemDetails.deadlineDate).format(this.translate.instant('generic.DATE_FORMAT'));


    this.isAtomicItem = ItemUtil.isAtomicItem(this.itemDetails);
    this.isLearningPlan = ItemUtil.isLearningPlan(this.itemDetails);
    this.isProject = ItemUtil.isProject(this.itemDetails);
    this.isImage = ItemUtil.isImage(this.itemDetails);
    this.isPodcast = ItemUtil.isPodcast(this.itemDetails);
    this.isBook = ItemUtil.isBook(this.itemDetails);
    this.isEBook = ItemUtil.isEBook(this.itemDetails);
    this.isGraph = ItemUtil.isGraph(this.itemDetails);
    this.isMagazine = ItemUtil.isMagazine(this.itemDetails);
    this.isDvd = ItemUtil.isDvd(this.itemDetails);
    this.isDocument = ItemUtil.isDocument(this.itemDetails);
    this.isVideo = ItemUtil.isVideo(this.itemDetails);
    this.isItemOtherOrPhysicalType = ItemUtil.isItemOtherOrPhysicalType(this.itemDetails);
    this.isOnlineCourse = InitiativeUtils.isOnlineInitiative(this.itemDetails.itemType);
    this.isCourseOnlineStage = InitiativeUtils.isOnlineInitiative(this.itemDetails.itemType);
    this.isCascadingMeeting = InitiativeUtils.isCascadingMeeting(this.itemDetails.itemType);
    this.isWebinar = InitiativeUtils.isWebinar(this.itemDetails.itemType);
    this.isExternalCourse = this.isExternalCourseClass || this.isExternalCourseOnline;
    this.isExternalEvent = this.isExternalEventClass || this.isExternalEventOnline;
    this.isAssessment = InitiativeUtils.isAssessment(this.itemDetails.itemType);
    this.isCatalogStage = InitiativeUtils.isCatalogStage(this.itemDetails.itemType);
    this.isSyllabusCourse = ItemUtil.isSyllabusCourse(this.itemDetails) || InitiativeUtils.isCourse(this.itemDetails.itemType) || this.isAssessment || this.isExternalCourse || this.isExternalEvent || this.isWebinar || this.isCatalogStage || this.isCascadingMeeting;
    this.isSurveyItem = ItemUtil.isSurveyItem(this.itemDetails);

    this.isItemOnline = !InitiativeUtils.isStageOnClass(this.itemDetails.itemType);

    this.itemIdLinkedToCourse = this.itemDetails.itemIdLinkedToCourse;

    // Dati per google analytics
    let translatedItemType = '';
    let translatedItemTypeSpecialization = '';
    const itemType = this.itemDetails.objectType || this.itemDetails.itemType;
    const itemTypeSpecialization = this.itemDetails.objectTypeSpecialization;
    if (this.isSyllabusCourse) {
      translatedItemType = this.translate.instant('itemDetailsPage.initiativeTypes.' + itemType);
    } else {
      if (itemType) {
        translatedItemType = this.translate.instant('itemDetailsPage.itemTypes.' + itemType);
      }
      if (itemTypeSpecialization) {
        translatedItemTypeSpecialization = this.translate.instant('itemDetailsPage.itemTypes.' + itemTypeSpecialization);
      }
    }

    this.isBanneImageAlwaysVisible = this.itemDetails.landingLayout && this.itemDetails.landingLayout === 'IMAGE_ALWAYS_VISIBLE';

    let typeToTranslate = this.itemDetails.objectTypeSpecialization || this.itemDetails.objectType || this.itemDetails.itemType;

    // Controllo custom per le etichette dei corsi di lingua. Per questi corsi va forzata la label webinar al posto di corso IDP
    if (this.itemDetails.argumentTitle === 'Lingue' && this.itemDetails.itemType === 'CATALOG_STAGE') {
      typeToTranslate = ItemTypes.EVENT_ONLINE_STAGE;
    }

    if (this.isSyllabusCourse) {
      // Se non ha l'immagine di background, prendo quella di default in base al cluster, così da uniformare il comportamento con quello che accade per le card
      if (!this.itemDetails.cardCoverUrl) {
        if (this.itemDetails.clusterColor) {
          this.itemDetails.cardCoverUrl = "../../../assets/img/img-card/img-clusters/" + this.itemDetails.clusterColor + '.png';
        } else if (this.itemDetails.itemType) {
          this.itemDetails.cardCoverUrl = "../../../assets/img/img-card/default-courses/" + this.itemDetails.itemType + '.png';
        }
      }
      this.itemTypeLabel = this.translate.instant('itemDetailsPage.initiativeTypes.' + typeToTranslate);

      this.duration = this.itemDetails.minutesValue || null;
      this.isGettingNotes = false;
      this.isGettingRentableStatus = false;
      this.isGettingRentHistory = false;
      if (this.itemDetails.syllabusId) {
        this.loadEditions(true, true);
      } else {
        this.isGettingItemDetails = false;
      }
    } else {
      this.itemDetails.isLearningPlan = ItemUtil.isLearningPlan(this.itemDetails);
      this.itemDetails.isProject = ItemUtil.isProject(this.itemDetails);

      this.isLoadingEditions = false;
      if (this.itemDetails.objectType == ItemAttributeObjectTypes.SCORM && (!this.itemDetails.itemType || this.itemDetails.itemType == "SCORM_FREE")) {
        this.itemTypeLabel = this.translate.instant('generic.itemTypes.SCORM_FREE_USER');
      } else {
        this.itemTypeLabel = this.translate.instant('generic.itemTypes.' + typeToTranslate);
      }


      if (this.itemDetails && this.itemDetails.minutesValue && parseInt(this.itemDetails.minutesValue) > 0) {
        if (parseInt(this.itemDetails.minutesValue) > 60) {
          this.duration = Math.floor(parseInt(this.itemDetails.minutesValue) / 60) + "h " + parseInt(this.itemDetails.minutesValue) % 60 + "m";
        } else {
          if (parseInt(this.itemDetails.minutesValue) == 60) {
            this.duration = '1h';
          } else {
            this.duration = parseInt(this.itemDetails.minutesValue) % 60 + "m";
          }
        }
      }
      if (!this.adminMode) {
        let getItemChildsInfoPromise = this.getItemChildsInfo(this.itemDetails);
        getItemChildsInfoPromise.then(() => {
          let getCertificationDataPromise = this.getCertificationData(this.itemDetails);
          getCertificationDataPromise.then(() => {
            // Formatto gli eventuali itemChild del learning plan (chiamato playlist)
            if (this.itemDetails.isLearningPlan && this.itemDetails.itemChilds && this.itemDetails.itemChilds) {
              this.itemDetails.itemChilds.sort((a: ItemChild, b: ItemChild) => {
                return b.childOrder - a.childOrder;
              });

              // Recupero i propedeuticReferenceIds degli itemChilds
              let propedeuticReferenceIds = ItemUtil.getPropedeuticReferenceIds(this.itemDetails.itemChilds, true);

              for (let childsCounter = 0, itemChildsLength = this.itemDetails.itemChilds.length; childsCounter < itemChildsLength; childsCounter++) {
                let currentItemChild: any = this.itemDetails.itemChilds[childsCounter];
                this.formatCourseCard(currentItemChild, propedeuticReferenceIds, this.itemDetails.itemChilds);
              }

              // Calcolo quanti oggetti della playlist sono stati completati
              let childOrder = -1;
              this.isPlaylistPlan = true;

              // this.itemDetails.itemChilds = this.itemDetails.itemChilds.reverse();
              // Nel caso in cui stia guardando una playlist da questa pagina, devo fare il reverse degli elementi
              // i childOrder arrivano invertiti, mentre in itemDetails arrivano correttamente
              if (window.location.href.includes("itemDetails")) {
                this.itemDetails.itemChilds = this.itemDetails.itemChilds.reverse();
              }
              // non ho le sezioni come il learning plan, quindi gli oggetti sono al primo livello
              for (let i = 0; i < this.itemDetails.itemChilds.length; i++) {
                // se ha l'oggetto associato
                if (this.itemDetails.itemChilds[i].childObject) {
                  let tmpItem = this.itemDetails.itemChilds[i].childObject;
                  let isItemComplete = tmpItem?.engagements?.filter((x) => x.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED);
                  if (isItemComplete && isItemComplete[0]) {
                    this.completedItems++;
                  }
                }
              }
              if (this.itemDetails?.itemChilds?.length && this.isPlaylistContainer() && !window.location.href.includes("itemCollectionDetails")) {
                this.itemDetails.itemChilds = coursesCardMapper(this.itemDetails.itemChilds, this.translate);
              }

            } else if (this.isProject && this.itemDetails.itemChilds && this.itemDetails.itemChilds.length) {
              // Calcolo quante sezioni sono completate
              for (let i = 0; i < this.itemDetails.itemChilds.length; i++) {
                if (this.itemDetails.itemChilds[i].childObject) {
                  let currentSection = this.itemDetails.itemChilds[i].childObject;

                  if (currentSection && currentSection.itemChilds && currentSection.itemChilds.length) {
                    for (let h = 0, sectionChildsLength = currentSection.itemChilds.length; h < sectionChildsLength; h++) {
                      let currentItemOfSection: any = currentSection.itemChilds[h];
                      if (currentItemOfSection && currentItemOfSection.isCompleted) {
                        this.completedItems++;
                      }
                    }
                  }

                }
              }
              /* Detto di un progetto: formatto gli oggetto aggiunti alla sezione
              let propedeuticReferenceIdsOfSection = ItemUtil.getPropedeuticReferenceIds(this.childObjects, true);
              for (let sectionsCounter = 0, sectionsLength = this.childObjects.length; sectionsCounter < sectionsLength; sectionsCounter++) {
                let currentSection: any = this.childObjects[sectionsCounter];
  
                if (currentSection && currentSection.itemChilds && currentSection.itemChilds.length) {
                  // Recupero i propedeuticReferenceIds degli itemChilds
                  let propedeuticReferenceIds = ItemUtil.getPropedeuticReferenceIds(currentSection.itemChilds, true);
  
                  currentSection.itemChilds.sort((a: ItemChild, b: ItemChild) => {
                    return b.childOrder - a.childOrder;
                  });
  
                  for (let childsCounter = 0, itemChildsLength = currentSection.itemChilds.length; childsCounter < itemChildsLength; childsCounter++) {
                    let currentItemChild: any = currentSection.itemChilds[childsCounter];
                    this.formatCourseCard(currentItemChild, propedeuticReferenceIds);
                  }
                }
              } */
            }

            let itemNotePromise = this.getItemNote();
            itemNotePromise.then(() => {
              if (this.itemDetails.hasRentableAttribute && this.canAskForRent) {
                // Verifica lo stato di prestabilità dell'oggetto
                let checkRentableStatusPromise = this.checkRentableStatus();
                checkRentableStatusPromise.then(() => {
                  // Recupero lo storico dell'utente loggato per vedere se la richiesta di prestito è ancora annullabile
                  let getRentHistoryPromise = this.getRentHistory(true);
                  getRentHistoryPromise.then(() => {
                    this.getTabs();
                    // Costruisco le eventuali seconde azioni (select)
                    this.isGettingItemDetails = false;
                    setTimeout(() => {
                      this.ref.detectChanges();
                    }, 100)
                    this.secondaryActionOptions = this.getSecondaryActionOptions();
                  })
                    .catch(() => {
                      this.isGettingItemDetails = false;
                    })
                })
                  .catch(() => {
                    this.isGettingItemDetails = false;
                  })
              } else {
                this.isGettingItemDetails = false;
                this.getTabs();
                setTimeout(() => {
                  this.ref.detectChanges();
                }, 100)
                // Costruisco le eventuali seconde azioni (select)
                this.secondaryActionOptions = this.getSecondaryActionOptions();
              }
            })
              .catch(() => {
                this.isGettingItemDetails = false;
              })
          })
            .catch(() => {
              this.isGettingItemDetails = false;
            })
        })
          .catch(() => {
            this.isGettingItemDetails = false;

          })
      } else {
        this.isGettingItemDetails = false;
      }
    }
    // se è un learning plan prendo l'id del primo oggetto non completato per il continua
    if ((this.isProject && !this.sectionId) || this.isLearningPlan) {
      this.firstIncompleteId = null;
      // per confrontare l'ordine delle sezioni
      let sectionChildOrder = 99999;
      let itemChildOrder = -1;
      if (this.itemDetails.itemChilds && this.itemDetails.itemChilds.length) {
        let sections = this.itemDetails.itemChilds;
        if (this.isLearningPlan) {
          for (let i = 0; i < sections.length; i++) {
            // PLAYLIST
            let tmpItem = sections[i] && sections[i].childObject;
            if (tmpItem) {
              let isItemComplete = tmpItem?.engagements?.filter((x) => x.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED);
              // prendo il primo incompleto
              if (isItemComplete && !isItemComplete.length &&
                (!this.firstIncompleteId || (this.firstIncompleteId && sectionChildOrder < sections[i].childOrder))) {
                sectionChildOrder = sections[i].childOrder;
                this.firstIncompleteId = tmpItem.itemId;
              }
            }

          }
        } else if (this.isProject) {
          for (let i = 0; i < sections.length; i++) {
            if (sections[i].childObject && sections[i].childObject.itemChilds && sections[i].childObject.itemChilds.length) {
              let section = sections[i].childObject;
              for (let j = 0; j < section.itemChilds.length; j++) {
                let tmpItem = section.itemChilds[j] && section.itemChilds[j].childObject;
                let tmpItemContainer: any = section.itemChilds[j];
                if (tmpItem) {
                  let isItemComplete = tmpItemContainer.isCompleted;

                  // prendo il primo incompleto
                  if (!isItemComplete &&
                    (!this.firstIncompleteId ||
                      (this.firstIncompleteId &&
                        (sectionChildOrder > sections[i].childOrder ||
                          (sectionChildOrder >= sections[i].childOrder && itemChildOrder <= tmpItemContainer.childOrder))
                      ))) {
                    sectionChildOrder = sections[i].childOrder;
                    itemChildOrder = tmpItemContainer.childOrder;
                    this.firstIncompleteId = tmpItem.itemId;

                    // AAAAAA this.firstIncompleteSectionId = sections[i].childObject.itemId;
                  }
                }
              }
            }
          }
        }

      }
    }
    if (this.itemDetails.embeddedPlayerCode) {
      // let curTime = moment().valueOf().toString();
      //this.itemDetails.embeddedPlayerCode = this.itemDetails.embeddedPlayerCode.replace('contentPlayer', 'contentPlayer' + curTime)
      this.isKalturaPlayerVisible = true;
    }

    if (parentDataLoader) {
      this.isGettingParentDetails = false;
    }
    if (this.itemTypeLabel.toLocaleLowerCase() == 'learning plan' || this.itemTypeLabel.toLocaleLowerCase() == 'playlist') {
      this.showLpContinue = true;
      if (this.itemTypeLabel.toLocaleLowerCase() == 'playlist') {
        this.isPlaylistPlan = true;
      }
    }
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    const customDimentions: VirtualPageViewCustomDimensions = {
      id: itemId,
      titleDetail: this.itemDetails.title,
      itemType: translatedItemType,
      itemTypeSpecialization: translatedItemTypeSpecialization,
      isMandatoryTraining: this.itemDetails.isMandatoryTraining
    }
    const pageTitle = this.containerTitleOfCurrentItem ? this.containerTitleOfCurrentItem + " - " + this.itemDetails.title : this.itemDetails.title;
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, pageTitle, customDimentions);
  }

  // Torna il numero di oggetti contenuti nelle sezioni del learning plan
  getSectionsItemsCounter(itemRef) {
    let counter = 0;
    let counterLabel = '';
    let totalMinutes = 0;

    if (itemRef && itemRef.itemChilds && itemRef.itemChilds.length) {
      for (let i = 0, sectionsLength = itemRef.itemChilds.length; i < sectionsLength; i++) {
        const currentSection = itemRef.itemChilds[i];

        if (currentSection && currentSection.childObject && currentSection.childObject.itemChilds) {
          for (let o = 0, childsLength = currentSection.childObject.itemChilds.length; o < childsLength; o++) {
            const currentItem = currentSection.childObject.itemChilds[o];
            totalMinutes = totalMinutes + (currentItem.minutesValue || 0);
            counter++;
          }
        }
      }
    }

    if (counter === 1) {
      counterLabel = 1 + ' ' + this.translate.instant('generic.OBJECT').toLowerCase();
    } else {
      counterLabel = counter + ' ' + this.translate.instant('generic.OBJECTS').toLowerCase();
    }

    if (totalMinutes === 1) {
      counterLabel = counterLabel + ' (' + totalMinutes + ' ' + this.translate.instant('generic.MINUTE').toLowerCase() + ')';
    } else {
      counterLabel = counterLabel + ' (' + totalMinutes + ' ' + this.translate.instant('generic.MINUTES').toLowerCase() + ')';
    }

    return counterLabel;
  }

  // Resetta i dati del componente
  resetData(params?: Params) {
    if (params) {
      this.itemId = getDecodedUrlParam(params['itemId']);
      this.collectionId = getDecodedUrlParam(params['collectionId']);
      this.webCourseId = getDecodedUrlParam(params['webCourseId']);
      this.parentCollectionId = getDecodedUrlParam(params['parentCollectionId']);
      this.wrapperInitiativeId = getDecodedUrlParam(params['wrapperInitiativeId']);
      this.projectId = getDecodedUrlParam(params['projectId']);
      this.sectionId = getDecodedUrlParam(params['sectionId']);
      this.editingProjectId = getDecodedUrlParam(params['editingProjectId']);
      this.editingSectionId = getDecodedUrlParam(params['editingSectionId']);
      this.itemToAddId = getDecodedUrlParam(params['itemToAddId']);
      this.lpId = getDecodedUrlParam(params['lpId']);
      this.editingLearningPlanId = getDecodedUrlParam(params['editingLearningPlanId']);
      this.editingLpId = getDecodedUrlParam(params['editingLpId']);
      const isDraftParam = getDecodedUrlParam(params['isDraft'])
      this.isDraft = (isDraftParam == "true");
    }

    this.tabs = null;

    this.isItemOnline = false;
    this.selectedEditionOfMainSelect = null;
    this.selectedCourseDateId = null;
    this.currentWebinarEditions = [];
    this.currentPlaceEditions = [];

    this.containerTitleOfCurrentItem = null;
    this.currentItemLang = null;
    this.langInitForPlaylistDone = false;

    this.hasToCollapseDetailsContent = false;
    this.isDetailsContentOpened = false;
    this.isRentableAvaiable = false;
    this.isMyRentablePendingRequest = false;
    this.isRequestStillRejectable = false;
    this.itemRented = false;
    this.isRentConfirmed = false;
    this.rentHistoryList = null;
    this.selectedPendingRequestRent = null;
    this.isAbortRentVisible = false;

    // loaded è a -1 altrimenti al primo avvio esce credendo di essere a fine lista
    // Dati contenenti le informazioni dei corsi che ti potrebbero interessare
    this.youCanInterestedCoursesData = {
      coursesFromRecord: 0,
      coursesNumRecords: 20,
      coursesCounter: 0,
      coursesLoaded: -1,
      courses: [],
      page: 1,
      resetCourses: false,
      selectedItem: null
    };

    this.webItemChildsData = {
      page: 1,
      itemsPerPage: 12
    };

    this.itemChildsData = {
      page: 1,
      itemsPerPage: 12
    };

    this.isSetWebCourseAsComplete = false;
    this.isBanneImageAlwaysVisible = false;

    // Controlla se sto recuperando gli item della Library
    this.isFetchingLibraryItems = false;
    // Dati contenenti le informazioni dei materiali library disponibili
    this.libraryItemsData = {
      libraryItemsFromRecord: 0,
      libraryItemsNumRecords: 20,
      libraryItemsCurrentPage: 1,
      libraryItemsCounter: 0,
      libraryItemsLoaded: 0,
      libraryItems: [],
      resetLibraryItems: false,
      selectedItemId: null,
      libraryItemLoaded: false
    };

    this.isGettingNotes = true;
    this.isGettingRentableStatus = true;
    this.isGettingRentHistory = true;
    this.itemNote = null;
    this.secondaryActionOptions = null;

    this.youCanInterestedCoursesData.coursesFromRecord = 0;

    this.descriptionExpanded = false;

    // Controlla se sto recuperando gli utenti a cui suggerire l'oggetto
    this.isFetchingUsersToSuggest = false;
    // Dati contenenti le informazioni degli utenti a cui suggerire l'oggetto
    this.usersToSuggestData = {
      usersToSuggestFromRecord: 0,
      usersToSuggestNumRecords: 20,
      usersToSuggestCurrentPage: 1,
      usersToSuggestCounter: 0,
      usersToSuggestLoaded: 0,
      usersToSuggest: [],
      resetUsersToSuggest: false,
      searchedText: '',
      selectedUserId: null
    };

    this.isExternalCourseClass = false;
    this.isExternalCourseOnline = false;
    this.isExternalEventClass = false;
    this.isExternalEventOnline = false;
    this.isExternalEvent = false;
    this.isExternalCourse = false;
    this.courseEndDate = null;
    this.isSurveyItem = false;
    this.isLoadingEditions = true;
    this.isAssessment = false;
    this.isGettingChilds = false;
    this.isItemCertifiable = false;
    this.showItemCertificationLoader = false;
    this.isSyllabusCourse = false;
    this.isCatalogStage = false;
    this.isCourseWithLimitedSeats = false;
    this.selectedMaterialForModal = null;
    this.hasTheUserTheCertificateAssigned = false;
    this.attachmentUrl = null;
    this.certificationDate = null;
    this.isSection = false;
    this.isVideo = false;
    this.isImage = false;
    this.isItemOtherOrPhysicalType = false;
    this.isPodcast = false;
    this.isBook = false;
    this.isEBook = false;
    this.isGraph = false;
    this.isMagazine = false;
    this.isDvd = false;
    this.isDocument = false;
    this.isOnlineCourse = false;
    this.isCourseOnlineStage = false;
    this.selectedTab = { id: "description", label: this.translations["generic.INFORMATIONS"] };
    this.isProject = false;
    this.isLearningPlan = false;
    this.isOpenDamBtnDisabled = null;
    this.likeCounter = {
      likes: null,
      dislikes: null
    };
    this.isAtomicItem = false;
    this.itemTypeLabel = null;
    this.duration = null;
    this.currentLike = null;
    this.iLikeThis = false;
    this.isDownloadingFile = false;
    this.iDislikeThis = false;
    this.isSurveyStarted = false;
    this.isSurveyStartedLibrary = false;
    this.isSurveyCertificable = false;
    this.isSurveyCertificableLibrary = false;
    this.selectedEditionForConfirmSubscription = null;
    this.selectedEditionForCancelSubscription = null;
    this.itemIdLinkedToCourse = null;
    if (this.editionsSurvey) {
      if (this.editionsSurvey.length) {
        this.editionsSurvey.length = 0;
      }
    } else {
      this.editionsSurvey = [];
    }
    if (this.editionsCertifications) {
      if (this.editionsCertifications.length) {
        this.editionsCertifications.length = 0;
      }
    } else {
      this.editionsCertifications = [];
    }
    if (this.certifiedEditions) {
      if (this.certifiedEditions.length) {
        this.certifiedEditions.length = 0;
      }
    } else {
      this.certifiedEditions = [];
    }
    if (this.editionsListWithPastEditions) {
      if (this.editionsListWithPastEditions.length) {
        this.editionsListWithPastEditions.length = 0;
      }
    } else {
      this.editionsListWithPastEditions = [];
    }
    if (this.childObjects) {
      if (this.childObjects.length) {
        this.childObjects.length = 0;
      }
    } else {
      this.childObjects = [];
    }
    this.webinarSelectedDay = null;
    if (this.webinarCoursesByDay) {
      if (this.webinarCoursesByDay.length) {
        this.webinarCoursesByDay.length = 0;
      }
    } else {
      this.webinarCoursesByDay = [];
    }
    if (this.childObjectTmp) {
      if (this.childObjectTmp.length) {
        this.childObjectTmp.length = 0;
      }
    } else {
      this.childObjectTmp = [];
    }
    this.itemLinkedToCourse = null;
    this.enableCertification = false;
    this.showPropedeuticalItemToThisModal = {
      propedeuticalObject: null,
      selectedItem: null
    }
    this.itemsInContainerCertified = 0;
    this.itemsInContainerCertified = 0;
    this.childsNotCertifiedButCertifiables = 0;
    this.totalChildsWithSurvey = 0;
    this.propedeuticalObject = null;
    this.certificationImageUrl = null;
    this.linkedinUrl = null;
    if (this.attachmentList) {
      if (this.attachmentList.length) {
        this.attachmentList.length = 0;
      }
    } else {
      this.attachmentList = [];
    }
  }

  // Resetta i dati delle options con le azioni secondarie
  resetSecondaryActions() {
    this.secondaryActionOptions = null;
    this.secondaryActionOptions = this.getSecondaryActionOptions();
    this.ref.detectChanges();
  }

  formatDateSchedule(courseDay) {
    let schedule = courseDay.timeSchedules;
    let formattedTime = '';
    let mergeSchedule = false;
    if (schedule[0] && schedule[0].startTime) {
      formattedTime = formattedTime + moment(schedule[0].startTime).format('HH:mm');
      // if (!schedule[0].endTime && schedule[1] && schedule[1].startTime && !schedule[1].endTime) {
      //     formattedTime = formattedTime + '-';
      //     formattedTime = formattedTime + moment(schedule[1].startTime).format('HH:mm');
      // } else
      if (!schedule[0].endTime && schedule[1] && !schedule[1].startTime && schedule[1].endTime) {
        formattedTime = formattedTime + '-';
        formattedTime = formattedTime + moment(schedule[1].endTime).format('HH:mm');
        mergeSchedule = true;
      } else if (schedule[0].endTime) {
        formattedTime = formattedTime + '-';
        formattedTime = formattedTime + moment(schedule[0].endTime).format('HH:mm');
      }
    }
    if (schedule[1] && !mergeSchedule) {
      if (!mergeSchedule) {
        formattedTime = formattedTime + ' | ';
      }
      if (schedule[1].startTime) {
        formattedTime = formattedTime + moment(schedule[1].startTime).format('HH:mm');
      }
      if (!schedule[1].startTime && schedule[1].endTime) {
        formattedTime = formattedTime + moment(schedule[1].endTime).format('HH:mm');
      } else {
        formattedTime = formattedTime + '-';
        formattedTime = formattedTime + moment(schedule[1].endTime).format('HH:mm');
      }
    }

    return formattedTime;
  }

  isPlaylistBtnInBannerVisibile() {
    return this.isPlaylistPlan && this.itemDetails && this.itemDetails.isCompleted && this.totalLpProgress != 100;
  }

  loadEditions(loadMaterials?: boolean, closeMainLoader?: boolean) {
    this.isLoadingEditions = true;
    this.itemService.getCourseEditions(this.itemDetails.syllabusId, true)
      .subscribe((editions: SenecaResponse<CourseDateCatalog[]>) => {
        if (editions.error) {
          if (closeMainLoader) {
            this.isGettingItemDetails = false;

          }
          this.isLoadingEditions = false;
          // Vedo se c'è la traduzione dell'errore
          this.toastr.error(this.translate.instant('errors.' + editions.error));
        } else {
          if (editions.response.length) {

            editions.response = editions.response.filter((x: any) => {
              return x.courseDateStatus != 'DRAFT';
            })
            // Ordino le edizioni, e loro date, per ordine temporale
            CourseEditionUtil.sortCourseEditionListForUser(editions.response);
            this.editions = <FormattedCourseDateCatalog[]>editions.response;

            for (let k = 0, editionsLength = editions.response.length; k < editionsLength; k++) {
              let currentEdition: FormattedCourseDateCatalog = <FormattedCourseDateCatalog>editions.response[k];
              currentEdition.customPlace = this.getEditionLocation(currentEdition);

              if (currentEdition.days && currentEdition.days.length) {
                let tmpTeachersTutors = [];
                let arrayLength = null;
                if (currentEdition.days.length < 4) {
                  arrayLength = currentEdition.days.length;
                } else {
                  arrayLength = 2;
                }
                for (let q = 0; q < arrayLength; q++) {
                  let currentDay = currentEdition.days[q];
                  currentDay.formattedFullDate = moment(currentDay.dayDate).format('ll');
                }
                for (let m = 0, daysLength = currentEdition.days.length; m < daysLength; m++) {
                  let currentDay = currentEdition.days[m];
                  currentDay.formattedTimeSchedule = this.formatDateSchedule(currentDay);
                  // Recupero la lista completa di docenti e tutor
                  if (!currentEdition.tutors) {
                    currentEdition.tutors = [];
                  }
                  if (!currentEdition.teachers) {
                    currentEdition.teachers = [];
                  }
                  if (currentDay.teachers && currentDay.teachers.length) {
                    currentEdition.teachers.push(...currentDay.teachers);
                    tmpTeachersTutors.push(...currentDay.teachers);
                  }
                  if (currentDay.tutors && currentDay.tutors.length) {
                    currentEdition.tutors.push(...currentDay.tutors);
                    tmpTeachersTutors.push(...currentDay.tutors)
                  }
                }
              }
              // Ultima e prima giornata dell'edizione
              const daysLength = currentEdition.days && currentEdition.days.length;
              currentEdition.lastDayDate = daysLength && currentEdition.days[daysLength - 1] && currentEdition.days[daysLength - 1].timeSchedules && currentEdition.days[daysLength - 1].timeSchedules.length && currentEdition.days[daysLength - 1].timeSchedules[currentEdition.days[daysLength - 1].timeSchedules.length - 1] && currentEdition.days[daysLength - 1].timeSchedules[currentEdition.days[daysLength - 1].timeSchedules.length - 1].endTime;
              currentEdition.firstDayDate = currentEdition.days && currentEdition.days[0] && currentEdition.days[0].timeSchedules && currentEdition.days[0].timeSchedules[0] && currentEdition.days[0].timeSchedules[0].startTime;
              // Se non c'è lo userStatus e l'utente può iscriversi o cancellarsi, do per scontato che tale utente sia invitato a livello di iniziativa e non di edizione (e quindi lo userStatus sulla currentEdition è mancante)
              if (!currentEdition.userStatus) {
                if (currentEdition.canCancel || currentEdition.canRegister || currentEdition.canRegisterOverbooking) {
                  currentEdition.userStatus = ItemTakerEnrollStatusTypes.USER_STATUS_INVITED;
                } else {
                  // Se è nel passato e non ho azioni, lo setto come Non iscritto
                  let now = new Date().toUTCString();
                  let isInThePast = moment((currentEdition.endDate || now)).isBefore(now) ? true : false;
                  if (isInThePast) {
                    currentEdition.userStatus = ItemTakerEnrollStatusTypes.USER_STATUS_INVITED;
                  }
                }
              }
              currentEdition.formattedStatus = currentEdition.userStatus && this.translate.instant('awWizard.invited.takerStatusesForUser.' + currentEdition.userStatus) || '';
              currentEdition.formattedStopDate = currentEdition.stopDate && moment(currentEdition.stopDate).subtract(1, 'h').format('DD/MM/YYYY') || '';
              currentEdition.isOnlineEvent = Boolean(currentEdition.endDate && currentEdition.onlineEventLinkTitle && currentEdition.onlineEventLink);
              if (!currentEdition.formattedStopDate && (currentEdition.isOnlineEvent || this.isOnlineCourse)) {
                // E' un evento online, quindi come data di scadenza faccio vedere la data di fine
                currentEdition.formattedStopDate = currentEdition.endDate && moment(currentEdition.endDate).format('DD/MM/YYYY') || '';
              }
              currentEdition.isConfirmed = currentEdition.userStatus && (currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED || currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_OVERBOOKING_CONFIRMED
                || currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED);
              currentEdition.isPresent = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT;
              currentEdition.isCancelled = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CANCELLED;
              currentEdition.isInvited = currentEdition.userStatus && currentEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED;
              // Salvo il fatto che si tratta di un online anche nell'edizione
              currentEdition.isOnlineCourse = this.isOnlineCourse || currentEdition.isOnlineEvent;
              currentEdition.isExternalEvent = this.isExternalEvent;
              currentEdition.isWebinar = this.isWebinar;
              currentEdition.isCascadingMeeting = this.isCascadingMeeting;
              // Calcolo i posti disponibili
              if (currentEdition.seats && currentEdition.seats.length) {
                currentEdition.usedSeats = currentEdition.seats[0].usedSeats;
                currentEdition.seatsLimit = currentEdition.seats[0].totalSeats;
                currentEdition.availableSeats = (!currentEdition.usedSeats && currentEdition.seatsLimit)
                  || (currentEdition.seatsLimit && currentEdition.usedSeats >= 0 && (currentEdition.seatsLimit - currentEdition.usedSeats)) || 0;
                currentEdition.usedSeatsOB = currentEdition.seats[0].obUsedSeats;
                currentEdition.seatsLimitOB = currentEdition.seats[0].obTotalSeats;
                currentEdition.availableSeatsOB = (!currentEdition.usedSeatsOB && currentEdition.seatsLimitOB)
                  || (currentEdition.seatsLimitOB && currentEdition.usedSeatsOB >= 0 && (currentEdition.seatsLimitOB - currentEdition.usedSeatsOB)) || 0;
              };
            }
            if (this.isOnlineCourse && editions.response && editions.response.length
              && editions.response[0].userStatus && (editions.response[0].userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED || editions.response[0].userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED)) {
              this.courseEndDate = editions.response[0].endDate && moment(editions.response[0].endDate).format(this.translate.instant('generic.DATE_FORMAT')) || '';
            }

            this.editionsListWithPastEditions = editions.response;
            this.editions = <FormattedCourseDateCatalog[]>editions.response;
            // Rimuovo le edizioni a cui non posso più iscrivermi (questo solo se non è un corso online, poichè esso non ha data di fine iscrizioni)
            if (!this.isOnlineCourse) {
              this.editions = this.editions.filter((edition: any) => {
                let now = new Date().toUTCString();
                // return (moment((edition.stopDate || now)).isAfter(now)) || edition.isPresent;
                return (moment((edition.stopDate || now)).isAfter(now));
              });
            }
            // Se è un corso online devo recuperare l'eventuale oggetto Library collegato
            if (this.isOnlineCourse && this.itemDetails.itemIdLinkedToCourse) {
              this.getLibraryItemLinkedToCourse();
            } else {
              this.isOpenDamBtnDisabled = false;
            }

            // Recupero i taker dell'utente
            let getUserTakersPromise = this.getUserTakers();
            getUserTakersPromise.then(() => {
              // Per ogni edizione, cerco se c'è una rilevazione agganciata
              let getAssessmentsOfEditionsPromise = this.getAssessmentsOfEditions(this.editionsListWithPastEditions);
              getAssessmentsOfEditionsPromise.then(() => {
                const assessmentIds = this.editions.map((edition: any) => edition.assessmentIds);
                if (assessmentIds && assessmentIds.length) {
                  // E' stato deciso che se sono in una iniziativa, in una tab isolata faccio vedere le sessioni (i questionari) disponibili
                  /*  if (!this.isAssessment) {
                      for (let r = 0, assessmentIdslength = assessmentIds.length; r < assessmentIdslength; r++) {
                        let currentAssessmentId = assessmentIds[r].itemId;
                        this.editions = this.editions.filter((currentConfirmedEdition: any) => {
                          return currentConfirmedEdition.stageItemId !== currentAssessmentId;
                        });
                      } */
                  //   } else {
                  // Se sono nel dettaglio di un assessment, devo rimuovere le altre edizioni e lasciare solo la sessione del questionario
                  if (this.isAssessment) {
                    this.editions = this.editions.filter((currentEdition: any) => {
                      return currentEdition.stageItemId === this.itemId;
                    });
                  }

                  // Potrebbe essere che si siano tolte edizioni, quindi ricalcolo la variabile
                  this.hasMoreEditions = !!this.editions && this.editions.length > 1;
                  //  }
                }

                if (this.isWebinar || (this.isCatalogStage && this.editions && this.editions[0] && this.editions[0].stageItemType == "WEBINAR")) {
                  if (this.editions && this.editions.length && this.editions.length > 1) {
                    this.groupEditions();
                  }
                } else {
                  this.editionsByPlace = CourseModuleUtil.formatEditionsByPlace(this.editions, true, this.isExternalCourse, this.translate);

                  if (this.editionsByPlace && this.editionsByPlace.length) {
                    // Ordino i luoghi in base all'edizione più vicina
                    this.editionsByPlace.sort(this.sortPlacesByFirstDayDate);

                    // Seleziono il primo luogo
                    //this.selectPlace(this.editionsByPlace[0], true);

                    let currentPlaceEditionsCopy = [];
                    this.editionsByPlace.forEach((day) => { currentPlaceEditionsCopy = currentPlaceEditionsCopy.concat(day.editions) });
                    this.currentPlaceEditions = [...currentPlaceEditionsCopy];

                    this.checkIsSubscribedToAnEdition(this.currentPlaceEditions);

                    currentPlaceEditionsCopy = this.currentPlaceEditions.filter(this.shouldShowEdition);
                    this.currentPlaceEditions = [...currentPlaceEditionsCopy];

                    if (this.selectedCourseDateId) {
                      for (let i = 0, editionsLength = this.editions.length; i < editionsLength; i++) {
                        if (this.editions[i].courseDateId === this.selectedCourseDateId) {
                          this.selectedEditionOfMainSelect = this.editions[i];
                          break;
                        }
                      }
                    } else {
                      if (this.isSubscribedToAnEdition) {
                        this.selectedEditionOfMainSelect = this.firsSubscribedEdition;
                      } else {
                        this.selectedEditionOfMainSelect = this.editions.find(this.shouldShowEdition);
                      }
                      this.selectedCourseDateId = this.selectedEditionOfMainSelect.courseDateId;
                    }
                  }
                }
                this.resetCertifications(this.editionsListWithPastEditions, loadMaterials, closeMainLoader);

                this.getTabs();
              })
                .catch(() => {
                  if (closeMainLoader) {
                    this.isGettingItemDetails = false;

                  }
                  this.isLoadingEditions = false;
                })
            })
              .catch(() => {
                if (closeMainLoader) {
                  this.isGettingItemDetails = false;

                }
                this.isLoadingEditions = false;
              });
          } else {
            if (loadMaterials) {
              this.loadAllCountMaterials()
                .then(() => {
                  this.loadUserMaterialList('MATERIAL_PRE_WORK', null);
                  this.getTabs();
                });
            } else {
              this.getTabs();
            }
            this.isLoadingEditions = false;
            if (closeMainLoader) {
              this.isGettingItemDetails = false;
            }
          }
        }
      }, (err) => {
        this.isLoadingEditions = false;
        if (closeMainLoader) {
          this.isGettingItemDetails = false;

        }
        if (err && err.message) {
          // Vedo se c'è la traduzione dell'errore
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
      })
  }

  resetCertifications(editionsRef, loadMaterials?, closeMainLoader?) {
    let getCertificationsOfEditionsPromise = this.searchCertificationsDone(editionsRef);
    getCertificationsOfEditionsPromise.then(() => {
      // Recupero le edizioni per le quali ho completato correttamente un questionario (oppure non è previsto) e lo statè presente, così da dare la possibilità all'utente di scaricare il certificato
      if (!this.isAssessment && this.editions && this.editions.length) {
        for (let e = 0, editionsToCheckLength = this.editions.length; e < editionsToCheckLength; e++) {
          let currentEditionToCheck: any = this.editions[e];
          // Verifico se il corso (esterno) possiede almeno una edizione con il numero di posti limitati
          if (this.isExternalCourse && currentEditionToCheck.itemWithLimitedSeats) {
            this.isCourseWithLimitedSeats = true;
          }
          if (currentEditionToCheck.isPresent) {
            if (currentEditionToCheck.assessmentIds && currentEditionToCheck.assessmentIds.length && this.editionsSurvey && this.editionsSurvey.length) {
              for (let editionsSurveyCounter = 0, editionsSurveyLength = this.editionsSurvey.length; editionsSurveyCounter < editionsSurveyLength; editionsSurveyCounter++) {
                let currentEditionSurvey = this.editionsSurvey[editionsSurveyCounter];
                for (let s = 0, assessmentIdsLength = currentEditionToCheck.assessmentIds.length; s < assessmentIdsLength; s++) {
                  const currentAssessmentId = currentEditionToCheck.assessmentIds[s];
                  if (currentEditionSurvey.stageItemId === currentAssessmentId.itemId) {
                    let editionOfAssessment = this.getEditionOfAssessment(currentEditionSurvey);
                    if (editionOfAssessment && editionOfAssessment.isSurveyPassed) {
                      this.certifiedEditions.push(currentEditionToCheck);
                    }
                  }
                }
              }
            } else {
              // Non è prevista una survey e lo stato è presente, quindi l'utente può scaricare il certificato
              this.certifiedEditions.push(currentEditionToCheck);
            }
          }
        }
      }

      // Per ogni edizione, verifico se l'utente deve accettare la privacy
      this.isCoursePrivacyToAccept(this.editions);
      if (loadMaterials) {
        this.loadAllCountMaterials()
          .then(() => {
            this.loadUserMaterialList('MATERIAL_PRE_WORK', null);
            this.getTabs();
          });
      }

      let getCoursesCertificationsPromise: any = this.getCoursesCertifications();
      getCoursesCertificationsPromise.then(() => {
        this.isLoadingEditions = false;
        if (closeMainLoader) {
          this.getTabs();
          this.isGettingItemDetails = false;
        }
      })
    })
      .catch(() => {
        if (closeMainLoader) {
          this.isGettingItemDetails = false;

        }
        this.isLoadingEditions = false;
      })
  }

  formatCourseCard(currentItemChild, propedeuticReferenceIds?: string[], otherChids?) {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (currentItemChild && currentItemChild.childObject) {
      currentItemChild.courseId = currentItemChild.childObject.itemId;
      currentItemChild.title = currentItemChild.childObject.title;
      setTimeout(() => {
        currentItemChild.typeTranslate = ItemUtil.getItemTypeLabel(currentItemChild.childObject, this.translate);
      })

      currentItemChild.type = ItemUtil.getItemObjectSubType(currentItemChild.childObject);

      if (libraryItems.includes(currentItemChild.type)) {
        currentItemChild.headerBackground = 'library-object';
        if (itemRef.isProject) {
          currentItemChild.headerTitleColor = 'black-text';
        } else {
          currentItemChild.headerTitleColor = 'white-text';
        }
        currentItemChild.isOnline = true;
      } else if (libraryContainers.includes(currentItemChild.type)) {
        currentItemChild.headerBackground = 'library-container';
        if (itemRef.isProject) {
          currentItemChild.headerTitleColor = 'black-text';
        } else {
          currentItemChild.headerTitleColor = 'white-text';
        }
        currentItemChild.isOnline = true;
      }

      let clusterName = '';
      let argumentName = '';
      let clusterColor = '';
      let clusterColorBackground = '';
      let minutesValue;
      let cardCover;
      let techCompetences = [];
      let softCompetences = [];
      let hasCardCoverAttribute = false;
      if (currentItemChild.childObject.itemAttributes && currentItemChild.childObject.itemAttributes.length) {
        for (let childsAttributesCounter = 0, itemChildAttributesLength = currentItemChild.childObject.itemAttributes.length; childsAttributesCounter < itemChildAttributesLength; childsAttributesCounter++) {
          let currentItemChildAttribute = currentItemChild.childObject.itemAttributes[childsAttributesCounter];
          if (currentItemChildAttribute.attributeType === ItemAttributeTypes.CLUSTERS
            && currentItemChildAttribute.crossReferenceObject) {
            clusterName = currentItemChildAttribute.crossReferenceObject.title;

            if (currentItemChildAttribute.crossReferenceObject.tagAttributes && currentItemChildAttribute.crossReferenceObject.tagAttributes.length) {
              for (let childsObjectAttributesCounter = 0, itemObjectChildAttributesLength = currentItemChildAttribute.crossReferenceObject.tagAttributes.length; childsObjectAttributesCounter < itemObjectChildAttributesLength; childsObjectAttributesCounter++) {
                let currentChildOjectRefAttribute = currentItemChildAttribute.crossReferenceObject.tagAttributes[childsObjectAttributesCounter];
                if (currentChildOjectRefAttribute.attributeType === "COLOR") {
                  clusterColor = currentChildOjectRefAttribute.attributeValue;
                  clusterColorBackground = 'bg-' + currentChildOjectRefAttribute.attributeValue;
                  break;
                }
              }
            }
          } else if (currentItemChildAttribute.attributeType === ItemAttributeTypes.ARGUMENTS && currentItemChildAttribute.crossReferenceObject && currentItemChildAttribute.crossReferenceObject.title) {
            argumentName = currentItemChildAttribute.crossReferenceObject.title;
          } else if (currentItemChildAttribute.attributeType === ItemAttributeTypes.TECH_COMPETENCES && currentItemChildAttribute.crossReferenceObject && currentItemChildAttribute.crossReferenceObject.title) {
            techCompetences.push(currentItemChildAttribute.crossReferenceObject.title);
          } else if (currentItemChildAttribute.attributeType === ItemAttributeTypes.SOFT_COMPETENCES && currentItemChildAttribute.crossReferenceObject && currentItemChildAttribute.crossReferenceObject.title) {
            softCompetences.push(currentItemChildAttribute.crossReferenceObject.title);
          } else if (currentItemChildAttribute.attributeType === ItemAttributeTypes.VALUE) {
            minutesValue = parseInt(currentItemChildAttribute.attributeValue);
          } else if (currentItemChildAttribute.attributeType === ItemAttributeTypes.CARD_COVER) {
            cardCover = currentItemChildAttribute.attributeValue;
            hasCardCoverAttribute = true;
          } else if (currentItemChildAttribute.attributeType === ItemAttributeTypes.CARD_COVER_GLP && !hasCardCoverAttribute) {
            cardCover = currentItemChildAttribute.attributeValue;
          }
        }
      }

      currentItemChild.competences = [...techCompetences, ...softCompetences];
      currentItemChild.minutesValue = minutesValue;
      currentItemChild.cardCover = cardCover;
      currentItemChild.clusterColor = clusterColor;
      currentItemChild.clusterName = clusterName;
      currentItemChild.argumentName = argumentName;
      currentItemChild.clusterColorBackground = clusterColorBackground;
      currentItemChild.completionPercentage = this.getItemPercentage(currentItemChild.childObject);

      if (currentItemChild.minutesValue && currentItemChild.minutesValue > 0) {
        if (currentItemChild.minutesValue >= 60) {
          currentItemChild.headerDetail = Math.floor(currentItemChild.minutesValue / 60) + "h " + ((currentItemChild.minutesValue % 60) > 0 ? currentItemChild.minutesValue % 60 + "m" : "");
        } else {
          currentItemChild.headerDetail = currentItemChild.minutesValue % 60 + "m"
        }
      }

      if (!currentItemChild.cardCover) {
        if (currentItemChild.clusterName) {
          currentItemChild.cardCover = "../../../assets/img/img-card/img-clusters/" + currentItemChild.clusterColor + '.png';
        } else if (currentItemChild.type) {
          currentItemChild.cardCover = "../../../assets/img/img-card/default-courses/" + currentItemChild.type + '.png';
        }
      }

      this.managePropedeuticAndMandatoryOnItemChild(currentItemChild, propedeuticReferenceIds, otherChids);
    }
  }

  // Gestisce le propedeuticità e obbligatorietà su un oggetto
  managePropedeuticAndMandatoryOnItemChild(currentItemChild, propedeuticReferenceIds, otherChilds?) {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    // Qualora gli Item di questi referenceId fossero già stati completati, li rimuovo
    if ((itemRef.isProject || itemRef.isLearningPlan) && !this.adminMode && ((propedeuticReferenceIds && propedeuticReferenceIds.length && otherChilds && otherChilds.length && currentItemChild.propedeuticReferenceId))) {
      currentItemChild.isItemLocked = true;
      // Scorro tutti gli id propedeutici (anche se mi aspetto ce ne sia solo uno)
      for (let i = 0; i < propedeuticReferenceIds.length; i++) {
        // Scorro tutte altre sezioni del progetto in cerca dell'id
        for (let k = 0; k < otherChilds.length; k++) {
          if (otherChilds[k] && otherChilds[k].childOject && otherChilds[k].childOject.itemId == propedeuticReferenceIds[i]
            // 2017/06/13 fix sblocco sezioni per referenceId propedeutico (attualmente ne viene gestito solo 1, quello su t_item_child)
            && currentItemChild.propedeuticReferenceId == propedeuticReferenceIds[i]) {

            let myPropeduticSection = otherChilds[k].childOject;
            if (myPropeduticSection.engagements && myPropeduticSection.engagements.length) {
              // Verifico se la sezione è stata marcata come consumata
              for (let f = 0; myPropeduticSection.engagements && f < myPropeduticSection.engagements.length; f++) {
                if (myPropeduticSection.engagements[f].eventName && myPropeduticSection.engagements[f].eventName == 'EVENT_ITEM_CONSUMED') {
                  currentItemChild.isItemLocked = false;
                  break;
                }
              }
            }
          }
        }
      }
    }

    // Gestione delle propedeuticità/obbligatorietà
    currentItemChild.mandatory = !!currentItemChild.mandatory;
    if (propedeuticReferenceIds && propedeuticReferenceIds.length) {
      for (let m = 0; m < propedeuticReferenceIds.length; m++) {
        if (currentItemChild.referenceId && propedeuticReferenceIds[m] == currentItemChild.referenceId) {
          currentItemChild.isPropedeutic = true;
          break;
        } else {
          currentItemChild.isPropedeutic = false;
        }
      }
    } else {
      currentItemChild.isPropedeutic = false;
    }

    if (currentItemChild.isPropedeutic || (currentItemChild.mandatory == 1 && currentItemChild.isPropedeutic)) {
      currentItemChild.showPropedeuticLabel = true;
    } else {
      currentItemChild.showPropedeuticLabel = false;
    }
    if (currentItemChild.mandatory == 1 || (currentItemChild.mandatory == 1 && currentItemChild.isPropedeutic)) {
      currentItemChild.showRequiredLabel = true;
    } else {
      currentItemChild.showRequiredLabel = false;
    }

    if (!this.adminMode && currentItemChild.propedeuticReferenceId && !currentItemChild.showRequiredLabel) {
      currentItemChild.isItemLocked = true;
    }
  }

  // Raggruppa le edizioni (più di una)
  groupEditions() {
    // Raggruppo i corsi recuperati per nome del giorno (nome + numero)
    for (let editionsCounter = 0, editionsCounterLength = this.editions.length; editionsCounter < editionsCounterLength; editionsCounter++) {
      let date: FormattedCourseDateCatalog = this.editions[editionsCounter];
      const dayName = moment(date.startDate).format('dddd') + ' ' + moment(date.startDate).format('DD/MM/YYYY');
      // Nel timeSlot, come data di fine, dovrò prendere l'orario di fine dell'utlima giornata (prima invece, nel pezzo commentato (riga 1665), viene presa la data di fine dell'ultima edizione)
      let endDateForTimeSlot = moment(date.endDate).toISOString();

      if (date && date.days && date.days.length) {
        const firstDay = date.days[0];

        if (firstDay && firstDay.timeSchedules && firstDay.timeSchedules.length && firstDay.timeSchedules[0].endTime) {
          endDateForTimeSlot = firstDay.timeSchedules[0].endTime;
        }
      }
      //const timeSlot = moment(date.startDate).format('HH:mm')  ' - ' + moment(date.endDate).format('HH:mm');
      const timeSlot = moment(date.startDate).format('HH:mm') + ' - ' + moment(endDateForTimeSlot).format('HH:mm');

      date.isMorning = moment(date.startDate).hour() < 12 ? true : false;
      // Decido quale etichetta di stato far vedere (iscritto, posti esauriti, posti totali) e l'azione
      //   if (this.isUser) {
      if (date.userStatus && date.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT) {
        // Utente presente
        date.labelStatusToDisplay = 'present';
        date.callToAction = 'DETAILS';
      } else if (date.isConfirmed) {
        // Utente iscritto
        date.labelStatusToDisplay = 'subscribed';
        // quindi la call to action è 'dettagli'
        date.callToAction = 'DETAILS';
      } else {
        if (!date.availableSeats && !date.availableSeatsOB) {
          // Non ho posti disponibili
          date.labelStatusToDisplay = 'noAvailableSeats';
          // quindi nessuna call to acion
        } else if ((date.canRegister || date.canRegisterOverbooking) && (date.isInvited || date.isCancelled)) {
          // Faccio vedere quanti posti sono ancora disponibili
          date.labelStatusToDisplay = 'availableSeats';
          // quindi la call to action è 'iscriviti'
          date.callToAction = 'SUBSCRIBE';
        }
      }

      let dayFound = false;
      for (let webinarCoursesCounter = 0, webinarCoursesByDayLength = this.webinarCoursesByDay.length; webinarCoursesCounter < webinarCoursesByDayLength; webinarCoursesCounter++) {
        let currentDay = this.webinarCoursesByDay[webinarCoursesCounter];
        if (currentDay && currentDay.dayLabel && currentDay.dayLabel === dayName) {
          if (date && ((date.availableSeats && date.availableSeats > 0) || (date.availableSeatsOB && date.availableSeatsOB > 0))) {
            currentDay.soldOut = false;
          }

          const labelForSelectOption = CourseModuleUtil.setWebinarCourseEditionOptionForSelect(date);
          date.labelForSelectOption = labelForSelectOption;

          currentDay.courses.push(date);
          dayFound = true;
          break;
        }
      }

      if (!dayFound) {
        let soldOut = true;
        if (date && ((date.availableSeats && date.availableSeats > 0) || (date.availableSeatsOB && date.availableSeatsOB > 0))) {
          soldOut = false;
        }

        const labelForSelectOption = CourseModuleUtil.setWebinarCourseEditionOptionForSelect(date);
        date.labelForSelectOption = labelForSelectOption;

        let newDay = {
          dayLabel: dayName,
          courses: [date],
          soldOut: soldOut
        }
        this.webinarCoursesByDay.push(newDay);
      }
    };

    if (!this.webinarSelectedDay) {
      this.webinarSelectedDay = this.webinarCoursesByDay[0];
    }

    if (this.webinarSelectedDay && this.webinarSelectedDay.courses && this.webinarSelectedDay.courses.length) {
      for (let m = 0, webinarCoursesLength = this.webinarSelectedDay.courses.length; m < webinarCoursesLength; m++) {
        let currentWebinarCourse = this.webinarSelectedDay.courses[m];

        for (let i = 0, editionsLength = this.editions.length; i < editionsLength; i++) {
          if (this.editions[i].courseDateId === currentWebinarCourse.courseDateId) {
            currentWebinarCourse = this.editions[i];
          }
        }
      }
    }

    //this.currentWebinarEditions = this.webinarSelectedDay.courses;

    let currentWebinarEditionsCopy = [];
    this.webinarCoursesByDay.forEach((day) => { currentWebinarEditionsCopy = currentWebinarEditionsCopy.concat(day.courses) });
    this.currentWebinarEditions = [...currentWebinarEditionsCopy];

    this.checkIsSubscribedToAnEdition(this.currentWebinarEditions);

    currentWebinarEditionsCopy = this.currentWebinarEditions.filter(this.shouldShowEdition);
    this.currentWebinarEditions = [...currentWebinarEditionsCopy];


    if (this.selectedCourseDateId) {
      for (let i = 0, editionsLength = this.editions.length; i < editionsLength; i++) {
        if (this.editions[i].courseDateId === this.selectedCourseDateId) {
          this.selectedEditionOfMainSelect = this.editions[i];
          break;
        }
      }
    } else {
      if (this.isSubscribedToAnEdition) {
        this.selectedEditionOfMainSelect = this.firsSubscribedEdition;
      } else {
        this.selectedEditionOfMainSelect = this.editions.find(this.shouldShowEdition);
      }
      this.selectedCourseDateId = this.selectedEditionOfMainSelect.courseDateId;
    }
  }

  // Cambia il giorno selezionato
  changeWebinarSelectedDay(selectedDay) {
    //TODO: questa non servirà più
    return;
    if (this.isLoadingEditions) {
      return;
    }
    this.webinarSelectedDay = selectedDay;
    this.currentWebinarEditions = null;

    if (this.webinarCoursesByDay && this.webinarCoursesByDay.length && this.webinarSelectedDay) {
      for (let i = 0, webinarCoursesByDayLength = this.webinarCoursesByDay.length; i < webinarCoursesByDayLength; i++) {
        let currentDay = this.webinarCoursesByDay[i];
        if (currentDay && currentDay.dayLabel && currentDay.dayLabel === this.webinarSelectedDay.dayLabel) {
          this.currentWebinarEditions = currentDay.courses;
          break;
        }
      }
    }

    // Resetto la selezione dell'edizione
    if (this.currentWebinarEditions) {
      this.selectedEditionOfMainSelect = this.currentWebinarEditions[0];
      this.selectedCourseDateId = this.selectedEditionOfMainSelect.courseDateId;
      // Da decommentare se si vuole resettare il certificato in base alla sola edizione selezionata
      // this.resetCertifications([this.selectedEditionOfMainSelect], true, true);
    }
  }

  // Seleziona un luogo
  selectPlace(place, automaticSelecting?: boolean) {
    return;
    // Se devo pre-selezionare significa che sono rientrato qui dopo il refresh dei corsi (ad esempio dop una iscrizione)
    if (automaticSelecting && this.selectedPlace && this.selectedPlace.placeId && this.editionsByPlace && this.editionsByPlace.length) {
      let currentSelectedPlaceId = this.selectedPlace.placeId;
      for (let y = 0, editionsByPlaceLength = this.editionsByPlace.length; y < editionsByPlaceLength; y++) {
        let currentEditionByPlace = this.editionsByPlace[y];
        if (currentEditionByPlace.placeId === currentSelectedPlaceId) {
          this.selectedPlace = currentEditionByPlace;
          break;
        }
      }
    } else {
      this.selectedPlace = place;
    }

    // Resetto la selezione dell'edizione
    if (this.selectedPlace && this.selectedPlace.editions && this.selectedPlace.editions[0]) {
      this.selectedEditionOfMainSelect = this.selectedPlace.editions[0];
      this.selectedCourseDateId = this.selectedEditionOfMainSelect.courseDateId;
      // Da decommentare se si vuole resettare il certificato in base alla sola edizione selezionata
      // this.resetCertifications([this.selectedEditionOfMainSelect], true, true);
    }
  }

  // Ordina le edizioni, raggruppate per luogo, in base alla edizione più vicina
  sortPlacesByFirstDayDate(a: any, b: any) {
    // E' sufficiente che prenda la prima edizione in quanto esse già sono ordinate
    if (a.editions && a.editions.length && b.editions && b.editions.length) {
      let aEditionFirstDayDate = a.editions[0].firstDayDate;
      let bEditionFirstDayDate = b.editions[0].firstDayDate;
      if (aEditionFirstDayDate < bEditionFirstDayDate) {
        return -1;
      }
      if (a.last_nom > bEditionFirstDayDate) {
        return 1;
      }
    }
    return 0;
  }

  // Apre una modale per condividere l'oggetto con altre persone
  openModalForShareItem() {
    // Apro la modale
    this.modalService.open('suggestItem');

    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendObjectShareStartEvent(this.itemDetails.itemId);
  }

  // Chiude la modale per suggerire l'oggetto
  closeModalForShareItem(confirm?: boolean) {
    this.modalService.close('suggestItem');
    this.libraryItemsData.searchedText = "";
    if (confirm) {
      this.isGettingItemDetails = true;


      let destinationUser;
      for (let m = 0, usersLength = this.usersToSuggestData.usersToSuggest.length; m < usersLength; m++) {
        let currentUser = this.usersToSuggestData.usersToSuggest[m];
        if (currentUser.userId === this.usersToSuggestData.selectedUserId) {
          destinationUser = currentUser;
          break;
        }
      }
      let serviceToUse;
      if (this.isSyllabusCourse) {
        serviceToUse = this.itemService.glpAddCourseSuggestion(destinationUser, this.itemDetails.itemId);
      } else {
        serviceToUse = this.itemService.glpAddItemSuggestion(destinationUser, this.itemDetails.itemId);
      }

      this.suggestItemToUser$ = serviceToUse
        .subscribe(data => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else {
            this.toastr.success(this.translate.instant(this.translate.instant('itemDetail.ITEM_CORRECTLY_SUGGESTED')));
          }
          this.deselectUsersToSuggestSelection();
          this.isGettingItemDetails = false;

          this.usersToSuggestData.resetUsersToSuggest = true;
          this.clearUsersToSuggestData();
          this.libraryItemsData.searchedText = "";
          if (this.countUsersToSuggestItem$) {
            this.countUsersToSuggestItem$.unsubscribe();
          }
          this.isFetchingUsersToSuggest = false;

          // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
          this.analyticsService.sendObjectShareDoneEvent(this.itemDetails.itemId);
        },
          (err) => {
            this.deselectUsersToSuggestSelection();
            this.isGettingItemDetails = false;

            this.usersToSuggestData.resetUsersToSuggest = true;
            this.clearUsersToSuggestData();
            this.libraryItemsData.searchedText = "";
            if (this.countUsersToSuggestItem$) {
              this.countUsersToSuggestItem$.unsubscribe();
            }
            this.isFetchingUsersToSuggest = false;
          });
    } else {
      this.deselectUsersToSuggestSelection();
    }
  }

  deselectUsersToSuggestSelection() {
    this.usersToSuggestData.selectedUserId = null;
  }

  selectedUserToSuggestChanged(user) {
    this.usersToSuggestData.selectedUserId = user.userId;
  }

  clearUsersToSuggestData(): void {
    this.usersToSuggestData.usersToSuggestLoaded = 0;
    this.usersToSuggestData.usersToSuggestFromRecord = 0;
    this.usersToSuggestData.usersToSuggestCurrentPage = 1;
    this.usersToSuggestData.usersToSuggest = [];
    this.usersToSuggestData.usersToSuggestCounter = 0;
  }

  // Recupera una lista di utenti a cui suggerire l'oggetto
  onGetUsersToSuggest(searchedText?: string, newSearch?: boolean) {
    if (!searchedText) {
      this.usersToSuggestData.resetUsersToSuggest = true;
      this.usersToSuggestData.searchedText = searchedText;
      this.clearUsersToSuggestData();
      if (this.countUsersToSuggestItem$) {
        this.countUsersToSuggestItem$.unsubscribe();
      }
      this.isFetchingUsersToSuggest = false;
      return of(new SenecaResponse(null, []));
    }
    // Avvio il loader
    this.isFetchingUsersToSuggest = true;
    if (newSearch || this.usersToSuggestData.searchedText) {
      searchedText = searchedText ? searchedText : "";
      this.usersToSuggestData.resetUsersToSuggest = true;
      this.usersToSuggestData.searchedText = searchedText;
      this.usersToSuggestData.usersToSuggestLoaded = 0;
      this.usersToSuggestData.usersToSuggestFromRecord = 0;
      this.usersToSuggestData.usersToSuggest = [];
      this.usersToSuggestData.usersToSuggestCounter = 0;
    } else {
      this.usersToSuggestData.resetUsersToSuggest = false;
    }
    if (this.countUsersToSuggestItem$) {
      this.countUsersToSuggestItem$.unsubscribe();
    }
    this.countUsersToSuggestItem$ = this.itemService.glpCountPersonsToSuggestItem(this.itemDetails.itemId, this.usersToSuggestData.searchedText)
      .pipe(
        switchMap(
          (usersCounter: SenecaResponse<number>) => {
            if (usersCounter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(usersCounter.error, null))
            } else {
              // Salvo il counter
              this.usersToSuggestData.usersToSuggestCounter = usersCounter.response;

              // Calcolo la paginazione
              let pageNum = 0;
              if (this.usersToSuggestData.usersToSuggestCurrentPage && this.usersToSuggestData.usersToSuggestNumRecords) {
                pageNum = this.usersToSuggestData.usersToSuggestCurrentPage;
              } else {
                pageNum = 0;
              }

              if (this.usersToSuggestData.usersToSuggestCounter) {
                return this.itemService.glpGetPersonsToSuggestItem(this.itemDetails.itemId, this.usersToSuggestData.searchedText, pageNum, this.usersToSuggestData.usersToSuggestNumRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          this.isFetchingUsersToSuggest = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return err;
        }),
        take(1)
      ).subscribe(
        (usersData: any) => {
          if (usersData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + usersData.error));
          } else {
            // Se ho i risultati, li formatto
            if (usersData.response && usersData.response.length) {
            }
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.usersToSuggestData.usersToSuggestLoaded += usersData.response && usersData.response.length;
            this.usersToSuggestData.usersToSuggest = usersData.response || [];
          }
          this.isFetchingUsersToSuggest = false;
        }
        , (err) => {
          this.isFetchingUsersToSuggest = false;
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  // Cambia la paginazione alla lista degli utenti da scegliere per condividere l'oggetto
  paginationUsersToSuggestItemChanged(newPage, searchedText?: string) {
    this.usersToSuggestData.usersToSuggestCurrentPage = newPage;
    this.onGetUsersToSuggest(searchedText, false);
  }

  // Verifica se una determinata tab è aperta
  isTabSelected(tabName) {
    return tabName && this.selectedTab && this.selectedTab.id && this.selectedTab.id === tabName;
  }

  // Apre una determinata tab
  openTab(selectedTab) {
    if (this.tabs && this.tabs.length) {
      for (let m = 0, tabsLength = this.tabs.length; m < tabsLength; m++) {
        const currentTab = this.tabs[m];

        if (selectedTab.id === currentTab.id) {
          this.selectedTab = currentTab;
          break;
        }
      }

      if (this.selectedTab.id === "description" && this.isSyllabusCourse) {
        this.isGettingItemDetails = true;
        setTimeout(() => {
          this.isGettingItemDetails = false;
        }, 100)
      }
    }
  }

  // Per ogni edizione controllo se l'utente deve accettare la privacy
  isCoursePrivacyToAccept(editions: Array<any>) {
    let promises = [];
    // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
    if (!editions) {
      editions = [];
    }
    let tmpList = editions.concat(this.itemDetails);
    for (let j = 0, editionsLength = tmpList.length; j < editionsLength; j++) {
      let currentEdition = tmpList[j];
      promises.push(new Promise((resolve: Function, reject: Function) => {
        const assessmentId = currentEdition.assessmentIds && currentEdition.assessmentIds[0] && currentEdition.assessmentIds[0].itemId;
        this.itemService.isCoursePrivacyToAccept(this.loggedUser.user.userId, assessmentId || currentEdition.courseDateId || currentEdition.itemId)
          .subscribe(
            (data: SenecaResponse<boolean>) => {
              if (data.error) {
                this.toastr.error(this.translate.instant('errors.' + data.error));
                reject();
              } else {
                currentEdition.hasToAcceptPrivacy = data.response;
                resolve();
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Torna l'edizione di un assessment
  getEditionOfAssessment(assessment) {
    // Se sono nel dettaglio di un assessment, 'editions' conterrà l'edizione della rilevazione; così, devo controllare nel contenitore contenente tutte le edizioni
    //let itemRef = this.isAssessment ? this.editionsListWithPastEditions : this.editions;
    let itemRef = this.editionsListWithPastEditions;
    if (assessment && itemRef && itemRef.length) {
      let editions = itemRef.filter((currentEdition: any) => {
        let addEdition;
        if (currentEdition && currentEdition.assessmentIds && currentEdition.assessmentIds.length) {
          for (let l = 0, idsLength = currentEdition.assessmentIds.length; l < idsLength; l++) {
            const currentId = currentEdition.assessmentIds[l] && currentEdition.assessmentIds[l].itemId;
            addEdition = currentId && (currentId === assessment.stageItemId || currentId === assessment.itemId);
            if (addEdition) {
              break;
            }
          }
        }
        return addEdition;
      });
      // Se è una rilevazione e non ho trovato nessuna iniziativa associata significa che è una rilevazione a sè stante
      if (this.isAssessment && (!editions || !editions.length) && itemRef && itemRef.length) {
        //  return itemRef[0];
        // I dati come isSurveyPassed ecc sono nell'itemDetails se sono nel dettaglio di una rilevazione a sé stante
        return this.itemDetails;
      }
      return editions && editions[0];
    }
  }

  openDeleteNoteModal() {
    this.modalService.open('deleteNote');
  }

  openCompleteWebCourseModal() {
    this.modalService.open('completeWebCourseModal');
  }

  closeCompleteWebCourseModal(confirm?) {
    this.modalService.close('completeWebCourseModal');

    if (confirm) {
      this.setWebCourseAsComplete();
    }
  }

  closeCreatePlaylistAddModal() {
    this.modalService.close('createPlaylistToAddItem');
    this.resetDataOnUserPlaylistCreation();
    this.langInitForPlaylistDone = false;
  }

  puppet(data: any) {
    // let addChildsPromise = this.addChilds(data.response.itemId, [this.itemDetails.itemId], false);
    // addChildsPromise.then(() => {
    //   this.resetDataOnUserPlaylistCreation();
    //   this.toastr.success(this.translate.instant(this.translate.instant('itemDetailsPage.ADDED_TO_LP')));
    //   this.isGettingItemDetails = false;
    // }).catch(() => {
    //   this.resetDataOnUserPlaylistCreation();
    //   this.isGettingItemDetails = false;
    // });
    this.itemService.addChilds(data.response.itemId, [this.itemDetails.itemId], false)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          if (data.error.indexOf('ER_DUP_ENTRY: Duplicate entry') !== -1) {
            this.toastr.error(this.translate.instant('errors.ITEM_ALREADY_ADDED'));
          } else {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          }
        } else {
          this.resetDataOnUserPlaylistCreation();
          this.toastr.success(this.translate.instant(this.translate.instant('itemDetailsPage.ADDED_TO_LP')));
          this.isGettingItemDetails = false;
        }
      }, (err) => {
        this.resetDataOnUserPlaylistCreation();
        this.isGettingItemDetails = false;
      });
  }

  closeCreatePlaylistToAddItemModal() {
    this.modalService.close('createPlaylistToAddItem');
    this.isGettingItemDetails = true;
    const coverUrl = PL_COVERS[Math.floor(Math.random() * PL_COVERS.length)];
    this.itemService.createLearningPlan(this.newPlaylistObj, false, false, coverUrl && coverUrl.url)
      .subscribe((data: SenecaResponse<any>) => {
        if (data.error) {
          this.isGettingItemDetails = false;
          // Mostro il toaster di errore
          this.toastr.error(this.translate.instant('errors.' + data.error));
          this.resetDataOnUserPlaylistCreation();
        } else {
          this.puppet(data);
        }
      },
        (err) => {
          this.isGettingItemDetails = false;
          this.resetDataOnUserPlaylistCreation();
          this.toastr.error(err.message);
        })

  }

  openCreatePlaylistToAddItemModal() {
    if (this.defaultLang && this.availableLangs && this.applicationLang && !this.langInitForPlaylistDone) {
      this.newPlaylistObj = <Item>{ itemLangs: [] };
      this.initLangs();
      this.initItemLangs();
      this.selectLang();
    }

    this.modalService.open('createPlaylistToAddItem');
  }

  resetDataOnUserPlaylistCreation() {
    this.newPlaylistObj.subTitle = "";
    this.newPlaylistObj.title = "";
    this.newPlaylistObj.description = "";
    this.currentItemLang.subTitle = "";
    this.currentItemLang.title = "";
    this.currentItemLang.description = "";
  }

  getItemLangInputSubtitle() {
    return this.translate.instant('newContainer.placeholders.INSERT_LEARNING_PLAN_TITLE') + (this.isItemLangTitlesEmpty() ? '*' : '');
  }

  onCurrentItemLangChanged(newVal?) {
    this.currentItemLang.subTitle = newVal;
  }

  onDefaultItemLangChanged(newVal?) {
    this.defaultItemLang.subTitle = newVal;
  }

  getCurrentItemLangDescSubtitle() {
    return this.translate.instant("newContainer.placeholders.INSERT_LEARNING_PLAN_SUBTITLE");
  }

  public isItemLangTitlesEmpty() {
    if (this.newPlaylistObj && this.newPlaylistObj.itemLangs && this.newPlaylistObj.itemLangs.length) {
      for (let i = 0; i < this.newPlaylistObj.itemLangs.length; i++) {
        let itemLang = this.newPlaylistObj.itemLangs[i];
        if (itemLang.langCode === this.defaultLang) {
          if (itemLang && itemLang.title && itemLang.title.length) {
            return false;
          }
        } else {
          if (itemLang && itemLang.title && itemLang.title.length) {
            return false;
          }
        }
      }
    }
    return true;
  }

  onDefaultItemLangTitleChanged(newVal?: string) {
    this.defaultItemLang.title = newVal;
  }

  onCurrentItemLangTitleChanged(newVal?: string) {
    this.currentItemLang.title = newVal;
  }

  private initItemLangs = () => {
    this.langList.forEach((currLang: any) => {
      let index: number = this.findItemLangIndex(currLang.langCode);
      if (index === -1) {
        this.newPlaylistObj.itemLangs.push({
          itemId: null,
          langCode: currLang.langCode,
          title: null,
          subTitle: null,
          description: null
        });
      }
    });

    // Recupero la lingua di default del sistema
    let index: number = this.findItemLangIndex(this.defaultLang);
    if (index !== -1) {
      this.defaultItemLang = this.newPlaylistObj.itemLangs[index];
    }
  }

  selectLang(lang?: Lang) {
    lang = lang || this.currentLang;

    let index: number = this.findItemLangIndex(lang.langCode);
    if (this.newPlaylistObj && this.newPlaylistObj.itemLangs && this.newPlaylistObj.itemLangs.length) {
      this.currentItemLang = this.newPlaylistObj.itemLangs[index];
    }
    // Fix per non far vedere 'null' in edit su ie
    if (this.currentItemLang && !this.currentItemLang.description) {
      this.currentItemLang.description = '';
    }
    this.currentLang = lang;

    this.langInitForPlaylistDone = true;
  }

  private getLangLabel(lang: string) {
    return this.translate.instant(`generic.langs.${lang}`);
  }

  private initLangs() {
    this.langList = [];
    this.langMap = {};

    this.availableLangs.forEach((one: any) => {
      if (one.langCode === this.defaultLang) {
        let curr = <Lang>{
          langCode: String(one.langCode),
          langDesc: this.getLangLabel(one.langCode),
          mandatory: one.mandatory === true,
          active: true
        };
        this.langList.push(curr);
        this.currentLang = curr;
      }
    });

    this.availableLangs.forEach((one: any) => {
      if (one.langCode !== this.defaultLang) {
        this.langList.push(<Lang>{
          langCode: String(one.langCode),
          langDesc: this.getLangLabel(one.langCode),
          mandatory: one.mandatory === true,
          active: true
        });
      }
    });

    this.langList.forEach((lang: Lang) => {
      this.langMap[lang.langCode] = lang;
    });
  }

  isDescriptionValorized() {
    if (this.isCurrentItemLangDefaultLang()) {
      if (this.currentItemLang && this.currentItemLang.description && this.currentItemLang.description.length) {
        return true;
      }
    } else {
      if (this.defaultItemLang && this.defaultItemLang.description && this.defaultItemLang.description.length) {
        return true;
      }
    }

    return false;
  }

  private findItemLangIndex(langCode: string) {
    let index: number = -1;
    if (this.newPlaylistObj && this.newPlaylistObj.itemLangs) {
      this.newPlaylistObj.itemLangs.forEach((one: ItemLang, idx: number) => {
        if (one.langCode === langCode) {
          index = idx;
        }
      });
    }
    return index;
  }

  public isCurrentItemLangDefaultLang(): boolean {
    return this.currentItemLang && this.defaultLang && this.currentItemLang.langCode === this.defaultLang;
  };

  // Toggle, nella modale per aggiungere un oggetto ad una playlist, che permette di creare una playlist nuova
  goToCreateNewPlaylist() {
    if (this.defaultLang && this.availableLangs && this.applicationLang && !this.langInitForPlaylistDone) {
      this.newPlaylistObj = <Item>{ itemLangs: [] };
      this.initLangs();
      this.initItemLangs();
      this.selectLang();
    }
    setTimeout(() => {
      let contentModal = document.getElementById("add-item-to-playlist-content-modal");
      if (contentModal) {
        contentModal.scrollTop = 0;
      }
    });
  }

  addChilds(selectedItemId: string, childsId: string[], isDraft: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.addChilds$ = this.itemService.addChilds(selectedItemId, childsId, isDraft)
        .subscribe(data => {
          if (data.error) {
            reject();
            if (data.error.indexOf('ER_DUP_ENTRY: Duplicate entry') !== -1) {
              this.toastr.error(this.translate.instant('errors.ITEM_ALREADY_ADDED'));
            } else {
              this.toastr.error(this.translate.instant('errors.' + data.error));
            }
          } else {
            resolve();
          }
        },
          (err) => {
            reject();
          });
    });
  }

  // Chiude la modale per aggiungere l'oggetto atomico ad una playlist esistente
  closeAddItemToExistingPlaylistModal(confirm?: boolean) {
    this.modalService.close('addItemToPlaylist');
    if (confirm) {
      this.isGettingItemDetails = true;

      // Creo il contenitore dei childs
      let childsId: Array<string> = [];

      // e ci aggiungo l'id dell'oggetto
      childsId.push(this.itemDetails.itemId);

      let addChildsPromise = this.addChilds(this.libraryItemsData.selectedItemId, childsId, false);
      addChildsPromise.then(() => {
        this.toastr.success(this.translate.instant(this.translate.instant('itemDetailsPage.ADDED_TO_LP')));
        this.deselectItemSelection();
        this.isGettingItemDetails = false;

      })
        .catch(() => {
          this.deselectItemSelection();
          this.isGettingItemDetails = false;

        });
    } else {
      this.deselectItemSelection();
    }
  }

  deselectItemSelection() {
    this.libraryItemsData.selectedItemId = null;
  }

  selectedItemOfOnlineCourseChanged(item) {
    this.libraryItemsData.selectedItemId = item.itemId;
  }

  // Pulisce gli oggetti della Library recuperati
  clearLibraryItemsData(): void {
    this.libraryItemsData.libraryItemsLoaded = 0;
    this.libraryItemsData.libraryItemsFromRecord = 0;
    this.libraryItemsData.libraryItemsCurrentPage = 1;
    this.libraryItemsData.libraryItems = [];
    this.libraryItemsData.libraryItemsCounter = 0;
  }

  // Recupera una lista di oggetti della Library
  onGetLibraryItems(searchedText?: string, newSearch?: boolean) {
    setTimeout(() => {
      let contentModal = document.getElementById("add-item-to-playlist-content-modal");
      if (contentModal) {
        contentModal.scrollTop = 0;
      }
    });

    // Avvio il loader
    this.isFetchingLibraryItems = true;
    if (newSearch) {
      searchedText = searchedText ? searchedText : "";
      this.libraryItemsData.resetLibraryItems = true;
      this.libraryItemsData.searchedText = searchedText;
      this.clearLibraryItemsData();
    } else {
      this.libraryItemsData.resetLibraryItems = false;
    }
    if (this.countItems$) {
      this.countItems$.unsubscribe();
    }
    this.countItems$ = this.itemService.countAllMyLearningPlans(this.libraryItemsData.searchedText)
      .pipe(
        switchMap(
          (itemsCounter: SenecaResponse<number>) => {
            if (itemsCounter.error) {
              // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
              return of(new SenecaResponse(itemsCounter.error, null))
            } else {
              // Salvo il counter
              this.libraryItemsData.libraryItemsCounter = itemsCounter.response;

              // Calcolo la paginazione
              let fromRecord = 0;
              if (this.libraryItemsData.libraryItemsCurrentPage && this.libraryItemsData.libraryItemsNumRecords) {
                fromRecord = (this.libraryItemsData.libraryItemsCurrentPage - 1) * this.libraryItemsData.libraryItemsNumRecords;
              } else {
                fromRecord = 0;
              }

              // Se sono previste offerte, le recupero
              if (this.libraryItemsData.libraryItemsCounter) {
                return this.itemService.getAllMyLearningPlans(this.libraryItemsData.searchedText, fromRecord, this.libraryItemsData.libraryItemsNumRecords);
              } else {
                // Torno un observable simulando una senecaResponse per continuare il flusso dello stream
                return of(new SenecaResponse(null, []));
              }
            }
          }
        )
        , catchError((err, caught) => {
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
          this.isFetchingLibraryItems = false;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return err;
        }),
        take(1)
      ).subscribe(
        (itemsData: SenecaResponse<any>) => {
          if (itemsData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + itemsData.error));
          } else {
            // Se ho i risultati, li formatto
            if (itemsData.response && itemsData.response.length) {
            }
            // Aggiungo i risultati alla lista, incrementando il numero di risultati ottenuti
            this.libraryItemsData.libraryItemLoaded += itemsData.response && itemsData.response.length;
            this.libraryItemsData.libraryItems = itemsData.response || [];
          }
          this.isFetchingLibraryItems = false;
        }
        , (err) => {
          this.isFetchingLibraryItems = false;
          if (err && err.message) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      );
  }

  // Cambia la paginazione alla lista degli Item della Library
  paginationLibraryItemsChanged(newPage) {
    this.libraryItemsData.libraryItemsCurrentPage = newPage;
    // Avvio una nuova ricerca
    this.onGetLibraryItems();
  }

  // Apre la modale per aggiungere l'oggetto atomico ad una playlist esistente
  openAddItemToExistingPlaylistModal() {
    this.modalService.open('addItemToPlaylist');
    this.onGetLibraryItems(null, true);
  }

  closeDeleteNoteModal(confirm?: boolean) {
    if (confirm) {
      this.isGettingNotes = true;
      this.platformService.deleteNote(this.itemNote.noteId)
        .subscribe(
          (data: SenecaResponse<any>) => {
            this.isGettingNotes = false;
            if (data.error) {
              // Vedo se c'è la traduzione dell'errore
              this.toastr.error(this.translate.instant('errors.' + data.error));
            } else {
              this.itemNote = null;
              this.toastr.success(this.translate.instant('generic.DATA_SAVED'));
              // Resetto le option
              this.resetSecondaryActions();
            }
            // Chiudo la modale
            this.modalService.close('deleteNote');
          }
          , catchError((err, caught) => {
            this.isGettingNotes = false;
            this.itemNoteTmp = null;
            // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
            return err;
          })
        )
    } else {
      this.modalService.close('deleteNote');
    }
  }

  // Apre la modale con la nota aggiunta/da creare all'item
  openItemNoteModal() {
    if (this.itemNote && this.itemNote.noteId) {
      this.itemNoteTmp = JSON.parse(JSON.stringify(this.itemNote));
    } else {
      this.itemNoteTmp = {
        noteId: null,
        title: '',
        noteType: ItemTypes.ITEM,
      }
    }
    this.modalService.open('itemNote');
  }

  // Apre la modale per richiedere il prestito
  openRequestRentModal() {
    this.modalService.open('requestRent');
  }

  // Chiude la modale che avverte l'utente che la richiesta del prestito è ancora pendente
  closeTheRequestIsStillPendingModal(confirm?: boolean) {
    if (confirm) {
      this.isGettingItemDetails = true;

      let rent = {
        referenceId: this.itemDetails.itemId
      }
      this.getItemNote$ = this.platformService.abortRent(this.selectedPendingRequestRent, RentStatusTypes.ABORTED, this.itemDetails.itemId).subscribe(
        (itemsData: SenecaResponse<boolean>) => {
          if (itemsData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + itemsData.error));
            this.isGettingItemDetails = false;

            this.selectedPendingRequestRent = null;
            this.modalService.close('theRequestIsStillPending');
          } else {
            this.toastr.success(this.translate.instant(this.translate.instant('itemDetailsPage.RENT_ABORTED')));
            this.rentHistoryList = null;
            this.isRentableAvaiable = true;

            let getRentHistoryPromise = this.getRentHistory(true);
            getRentHistoryPromise.then(() => {
              // Resetto le option
              this.resetSecondaryActions();
              this.isGettingItemDetails = false;

              this.selectedPendingRequestRent = null;
              this.modalService.close('theRequestIsStillPending');
            })
              .catch(() => {
                this.selectedPendingRequestRent = null;
                this.modalService.close('theRequestIsStillPending');
                this.isGettingItemDetails = false;

              })
          }
        }
        , catchError((err, caught) => {
          this.isGettingItemDetails = false;

          return err;
        })
      )
    } else {
      this.selectedPendingRequestRent = null;
      this.modalService.close('theRequestIsStillPending');
    }
  }

  // Apre una modale che avverte l'utente che la richiesta del prestito è ancora pendente
  theRequestIsStillPendingModal(selectedPendingRequestRent) {
    this.selectedPendingRequestRent = selectedPendingRequestRent;
    this.modalService.open('theRequestIsStillPending');
  }

  // Apre una modale che avverte l'utente che il prestito è già in atto
  openItemRentedModal() {
    this.modalService.open('itemRented');
  }
  closeItemRentedModal() {
    this.modalService.close('itemRented');
  }

  // Apre la modale del prestito già fatto
  rentRequested() {
    this.isAbortRentVisible = false;
    this.isGettingItemDetails = true;

    let rentHistoryPromise = this.getRentHistory(false, true);
    rentHistoryPromise.then(() => {
      this.isGettingItemDetails = false;

    })
      .catch(() => {
        this.isGettingItemDetails = false;

      })
  }

  // Apre la modale che avverte l'utente che il prestito non è disponibile
  openRequestRentNotAvailableModal() {
    this.modalService.open('requestRentNotAvailable');
  }

  // Chiude la modale che avverte l'utente che il prestito non è disponibile
  closeRequestRentNotAvailableModal() {
    this.modalService.close('requestRentNotAvailable');
  }

  // Chiude la modale per richiedere il prestito
  closeRequestRentModal(confirm?: boolean) {
    if (confirm) {
      this.isGettingItemDetails = true;

      let rent = {
        referenceId: this.itemDetails.itemId
      }
      this.getItemNote$ = this.platformService.createRent(rent, this.itemDetails.itemId).subscribe(
        (itemsData: SenecaResponse<boolean>) => {
          this.isGettingItemDetails = false;

          if (itemsData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + itemsData.error));
          } else {
            this.toastr.success(this.translate.instant(this.translate.instant('itemDetailsPage.REQUEST_SENT')));
            // Salvo il fatto che l'Item non è più disponibile
            this.isRentableAvaiable = false;
            // e che la richiesta l'ho fatta io
            this.isMyRentablePendingRequest = true;
            this.isRequestStillRejectable = true;
            // Resetto le option
            this.resetSecondaryActions();
          }
          // Chiudo la modale
          this.modalService.close('requestRent');
        }
        , catchError((err, caught) => {
          this.isGettingItemDetails = false;

          return err;
        })
      )
    } else {
      this.modalService.close('requestRent');
    }
  }

  closeItemNoteModal(confirm?: boolean) {
    if (confirm) {
      this.isGettingNotes = true;
      let serviceToUse;
      if (this.itemNoteTmp && this.itemNoteTmp.noteId) {
        serviceToUse = this.platformService.updateNote(this.itemNoteTmp.noteId, this.itemNoteTmp.title, this.itemNoteTmp.referenceId, ItemTypes.ITEM, this.itemDetails.itemId, this.itemDetails.originApplicationName, ReferenceTypes.ITEM);
      } else {
        serviceToUse = this.platformService.createNote(null, this.itemNoteTmp.title, this.itemNoteTmp.description, ItemTypes.ITEM, this.itemDetails.itemId, ReferenceTypes.ITEM, this.itemDetails.originApplicationName);
      }
      this.getItemNote$ = serviceToUse.subscribe(
        (itemsData: SenecaResponse<any>) => {
          this.isGettingNotes = false;
          this.itemNoteTmp = null;
          if (itemsData.error) {
            // Vedo se c'è la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + itemsData.error));
          } else {
            this.toastr.success(this.translate.instant(this.translate.instant('generic.DATA_SAVED')));
            this.itemNote = itemsData.response;
            // Resetto le option
            this.resetSecondaryActions();
          }
          // Chiudo la modale
          this.modalService.close('itemNote');
        }
        , catchError((err, caught) => {
          this.isGettingNotes = false;
          this.itemNoteTmp = null;
          // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
          return err;
        })
      )
    } else {
      this.itemNoteTmp = null;
      this.modalService.close('itemNote');
    }
  }

  // Ritorna la lista di tab
  getTabs() {
    let tabs = [{ id: "description", label: this.translate.instant("generic.INFORMATIONS") }];

    // Corso del CM2
    if (this.isSyllabusCourse) {
      // Questionari CM2
      const surveysTranslatedLabel = this.translations['itemDetailsPage.tabs.SURVEYS'] + ' (' + (this.editionsSurvey &&
        this.editionsSurvey.length || 0) + ')';

      tabs.push({
        id: "surveys",
        label: surveysTranslatedLabel
      })

      // Materiali CM2
      const materialsTranslatedLabel = this.translations['itemDetailsPage.tabs.MATERIALS'] + ' (' +
        ((this.preworkMaterialsCounter +
          this.classroomMaterialsCounter + this.postworkMaterialsCounter) || 0) + ')';

      tabs.push({
        id: "materials",
        label: materialsTranslatedLabel
      })

      // Certificati corsi CM2
      const certificationsTranslatedLabel = this.translations['generic.CERTIFICATIONS'] + ' (' + (this.editionsCertifications &&
        this.editionsCertifications.length ||
        0) + ')';

      tabs.push({
        id: "editionsCertifications",
        label: certificationsTranslatedLabel
      })
    }

    // Allegati del corso Library
    if (this.showAttachmentsTabContent()) {
      const libraryAttachmentsTranslatedLabel = this.translations['generic.ATTACHMENTS'] + ' (' + ((this.attachmentList &&
        this.attachmentList.length) ||
        0) + ')';

      tabs.push({
        id: "attachmentList",
        label: libraryAttachmentsTranslatedLabel
      })
    }

    // Mappa dell'unica edizione del corso CM2
    if (this.isSyllabusCourse && !this.hasMoreEditions && this.editions && this.editions[0] && !this.editions[0].isOnlineCourse && !this.editions[0].isWebinar) {
      const editionsMapTranslatedLabel = this.translations['itemDetailsPage.tabs.MAP'];

      tabs.push({
        id: "editionsMap",
        label: editionsMapTranslatedLabel
      })
    }

    this.tabs = tabs;
  }

  // Ritorna l'azione principale dell'unica edizione disponibile del cm2
  getUniqueEditionMainBtnAction() {
    const edition = this.editions && this.editions[0];
    if (edition) {
      if (this.isExternalCourse) {
        return 'SHOW_EXTERNAL_COURSE_DETAILS';
      } else if (edition.isOnlineCourse && !this.itemDetails.isDeadlinePassed) {
        // Corso online (cm2) che wrappa un item library (scorm o kaltura)
        if (this.itemLinkedToCourse && (this.itemLinkedToCourse.isDam || this.itemLinkedToCourse.isScorm) && (/*   Dopo il 10 giugno il player è stato spostato in alto quindi il pulsante principale di autoscorll è inutile this.isUserSubscribedToWrapperLibraryItem()  ||*/ this.canUserSubscribeToCourseThatWrapLibraryItem())) {
          if (this.itemLinkedToCourse.isImage) {
            return 'SEE_THE_WRAPPED_IMAGE';
          } else {
            return 'SUBSCRIBE_TO_ONLINE_COURSE';
          }
        } else if (this.itemLinkedToCourse && this.itemLinkedToCourse.isProject && this.itemLinkedToCourse.itemChilds && this.itemLinkedToCourse.itemChilds.length) {
          // Corso come progetto
          this.showLpContinue = true;
          return 'SHOW_SECTIONS';
        } else if (this.itemLinkedToCourse && this.itemLinkedToCourse.isLearningPlan && this.itemLinkedToCourse.itemChilds && this.itemLinkedToCourse.itemChilds.length) {
          this.showLpContinue = true;
          return 'SHOW_ITEMS';
        }
      }

      // Se è un'iniziativa, rimando alla tab questionari
      if (this.isAssessment && (edition.canRegister || this.itemDetails.isSurveyStarted)) {
        return 'GOTO_SURVEY'
      } else if (this.isAssessment && !edition.canRegister) {
        return;
      }

      // Dato che per gli EVENT_ONLINE_STAGE non compariva il pulsante di iscriviti, ho portato fuori dall'else questa condizione.
      // Verrà eseguita in caso di nessun return precedente
      if ((edition.canRegister || edition.canRegisterOverbooking) && (edition.isInvited || edition.isCancelled)) {
        // Se l'utente è invitato (oppure si è rifiutato ma può ancora iscriversi, se ad esempio l'iniziativa è pubblica): "Partecipa" (accetta l'invito)
        if (edition.isOnlineCourse && this.itemDetails.isDeadlinePassed && !edition.isOnlineEvent) {
          return;
        } else {
          return 'ACCEPT_EDITION_INVITE';
        }
      }
    }
  }

  // Gestisce il click sull'azione secondaria
  onSecondaryActionClicked(event) {
    this.selectedSecondaryAction = null;
    if (event && event.code) {
      if (event.code === 'NEW_NOTE' || event.code === 'VIEW_NOTE') {
        this.openItemNoteModal();
      } else if (event.code === 'DELETE_NOTE') {
        this.openDeleteNoteModal();
      } else if (event.code === 'ADD_TO_EXISTING_PLAYLIST') {
        this.openAddItemToExistingPlaylistModal();
      } else if (event.code === 'ADD_TO_NEW_PLAYLIST') {
        this.openCreatePlaylistToAddItemModal();
      } else if (event.code === 'REQUEST_RENT') {
        this.openRequestRentModal();
      } else if (event.code === 'RENT_NOT_AVAIABLE') {
        this.openRequestRentNotAvailableModal();
      } else if (event.code === 'RENT_REQUESTED') {
        this.rentRequested();
      } else if (event.code === 'ITEM_RENTED') {
        this.openItemRentedModal();
      } else if (event.code === 'SUGGEST_ITEM_TO_PERSON') {
        this.openModalForShareItem();
      }
    }
  }

  // Ritorna la lista delle secondi azioni
  getSecondaryActionOptions() {
    if (!this.isGettingNotes && this.itemDetails && this.itemDetails.itemId) {
      // Gestione oggetto Library singolo
      if (!this.isSyllabusCourse) {
        let options = [];

        if (!this.itemDetails.isMine) {
          options.push(
            { name: this.translate.instant('itemDetailsPage.SUGGEST'), titleName: this.translate.instant('itemDetailsPage.SUGGEST'), id: 1, code: 'SUGGEST_ITEM_TO_PERSON' }
          )
        }

        if (!this.itemNote) {
          // Non c'è la nota, quindi dò all'utente la possibilità di aggiungerla
          options.push(
            { name: this.translate.instant('itemDetailsPage.NEW_NOTE'), titleName: this.translate.instant('itemDetailsPage.NEW_NOTE'), id: (options.length + 1), code: 'NEW_NOTE' }
          )
        } else {
          options.push(
            { name: this.translate.instant('itemDetailsPage.NOTE'), titleName: this.translate.instant('itemDetailsPage.NOTE'), id: (options.length + 1), code: 'VIEW_NOTE' },
            { name: this.translate.instant('itemDetail.DELETE_NOTE_MODAL_TITLE'), titleName: this.translate.instant('itemDetail.DELETE_NOTE_MODAL_TITLE'), id: (options.length + 2), code: 'DELETE_NOTE' },
          )
        }

        // Aggiunta dell'oggetto atomico ad una Playlist
        if (this.canManageLearningPlan && this.isAtomicItem) {
          options.push(
            { name: this.translate.instant('itemDetail.ADD_TO_EXISTING_PLAYLIST'), titleName: this.translate.instant('itemDetailsPage.ADD_TO_EXISTING_PLAYLIST'), id: (options.length + 1), code: 'ADD_TO_EXISTING_PLAYLIST' },
            { name: this.translate.instant('itemDetail.ADD_TO_NEW_PLAYLIST'), titleName: this.translate.instant('itemDetailsPage.ADD_TO_NEW_PLAYLIST'), id: (options.length + 2), code: 'ADD_TO_NEW_PLAYLIST' }
          )
        }

        /*Gestione dei prestiti
        if (this.canAskForRent && this.itemDetails.hasRentableAttribute) {
          if (this.isRentableAvaiable && !this.itemRented) {
            // Item prestabile e disponibile
            options.push(
              { name: this.translate.instant('itemDetailsPage.REQUEST_RENT'), titleName: this.translate.instant('itemDetailsPage.REQUEST_RENT'), id: (options.length + 1), code: 'REQUEST_RENT' }
            )
          } else if (!this.isRentableAvaiable && !this.isMyRentablePendingRequest) {
            // Item prestabile ma non disponibile
            options.push(
              { name: this.translate.instant('itemDetailsPage.RENT_NOT_AVAIABLE'), titleName: this.translate.instant('itemDetailsPage.RENT_NOT_AVAIABLE'), id: (options.length + 1), code: 'RENT_NOT_AVAIABLE' }
            )
          } else if (!this.isRentableAvaiable && this.isMyRentablePendingRequest && this.itemRented) {
            // Item prestabile ma non disponibile in quanto già in prestito
            options.push(
              { name: this.translate.instant('itemDetailsPage.ITEM_RENTED'), titleName: this.translate.instant('itemDetailsPage.ITEM_RENTED'), id: (options.length + 1), code: 'ITEM_RENTED' }
            )
          } else if (!this.isRentableAvaiable && this.isMyRentablePendingRequest && !this.itemRented && this.isRequestStillRejectable) {
            // Item prestabile ma non disponibile (richiesta fatta dall'utente loggato)
            options.push(
              { name: this.translate.instant('itemDetailsPage.RENT_REQUESTED'), titleName: this.translate.instant('itemDetailsPage.RENT_REQUESTED'), id: (options.length + 1), code: 'RENT_REQUESTED' }
            )
          }
        } */

        return options;
      }
    }

    return;
  }

  // Ritorna l'azione principale dell'oggetto Library a sé stante
  getLibraryItemMainBtnAction() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (this.itemDetails && (this.itemDetails.itemId || this.webCourseId)) {
      //  const isVideo = (this.isSyllabusCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.isVideo) || this.isVideo;
      // Prima verifico se ho qualche azione riguardante le survey o il certificato, dato che sono le più importanti
      if (this.isThereSurveyToStart(this.itemDetails) && !this.isSurveyCertificableLibrary) {
        // Survey da iniziare
        return 'GO_TO_SURVEY';
      } else if (this.isThereSurveyToContinue(this.itemDetails) && !this.isSurveyStartedLibrary) {
        // Survey iniziata, e da continuare
        return 'CONTINUE_CERTIFICATION';
      } else if (this.webCourseId && this.itemDetails.courseUrl) {
        return 'ACCESS_TO_COURSE';
        // } else if (this.isVideo) {
        //   return 'WATCH_VIDEO';
      } else if (itemRef.isProject && itemRef.itemDetails && itemRef.itemDetails.itemChilds && itemRef.itemDetails.itemChilds.length) {
        return 'SHOW_SECTIONS';
      } else if (itemRef.isLearningPlan && itemRef.itemDetails && itemRef.itemDetails.itemChilds && itemRef.itemDetails.itemChilds.length) {
        return 'SHOW_ITEMS';
        //    } else if (this.isPodcast) {
        //      return 'LISTEN_TO_PODCAST';
      } else if (this.isBook && this.showAttachmentsTabContent()) {
        return 'DOWNLOAD_ATTACHMENTS';
      } else if (this.isEBook && this.showAttachmentsTabContent()) {
        return 'DOWNLOAD_THE_BOOK';
      } else if (this.isGraph && this.showAttachmentsTabContent()) {
        return 'DOWNLOAD_THE_GRAPH';
      } else if (this.isMagazine && this.showAttachmentsTabContent()) {
        return 'DOWNLOAD_ATTACHMENTS';
      } else if (this.isDvd && this.showAttachmentsTabContent()) {
        return 'DOWNLOAD_ATTACHMENTS';
      } else if (this.isDocument && this.showAttachmentsTabContent()) {
        return '';
        //    } else if (this.itemDetails.isScorm) {
        //      return 'PERFORM_SCORM';
        //    } else if (this.isImage) {
        //      return 'SEE_THE_IMAGE';
      }
    }
  }

  // Ritorna l'azione secondaria dell'oggetto Library
  getLibraryItemSecondBtnAction() {
    if (this.itemDetails && (this.itemDetails.itemId || this.webCourseId)) {
      let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
      if (this.webCourseId) {
        return;
      } else if ((this.isThereSurveyToStart(this.itemDetails) && !this.isSurveyCertificableLibrary) || (this.isThereSurveyToContinue(this.itemDetails) && !this.isSurveyStartedLibrary)) {
        // L'azione principale è una survey (continua oppure inizia) quindi il pulsante secondario diventa "guarda il video" o "svolgi il corso"
      /*  if (this.isVideo) {
          return 'WATCH_VIDEO';
        } else if (this.isPodcast) {
          return 'LISTEN_TO_PODCAST';
        } else */if (itemRef.isProject && itemRef.itemDetails.itemChilds && itemRef.itemDetails.itemChilds.length) {
          return 'SHOW_SECTIONS';
        } else if (itemRef.isLearningPlan && itemRef.itemDetails.itemChilds && itemRef.itemDetails.itemChilds.length) {
          return 'SHOW_ITEMS';
        } else if (this.isBook && this.showAttachmentsTabContent()) {
          return 'DOWNLOAD_ATTACHMENTS';
        } else if (this.isEBook && this.showAttachmentsTabContent()) {
          return 'DOWNLOAD_THE_BOOK';
        } else if (this.isGraph && this.showAttachmentsTabContent()) {
          return 'DOWNLOAD_THE_GRAPH';
        } else if (this.isMagazine && this.showAttachmentsTabContent()) {
          return 'DOWNLOAD_ATTACHMENTS';
        } else if (this.isDvd && this.showAttachmentsTabContent()) {
          return 'DOWNLOAD_ATTACHMENTS';
        } else if (this.isDocument && this.showAttachmentsTabContent()) {
          return 'DOWNLOAD_THE_DOCUMENT';
        }/* else if (this.itemDetails.isScorm) {
          return 'PERFORM_SCORM';
        } else if (this.isImage) {
          return 'SEE_THE_IMAGE';
        } */
      } else if (this.isVideo || this.itemDetails.isScorm || this.isImage || this.isPodcast || this.isBook || this.isEBook || this.isMagazine || this.isDvd || this.isGraph || this.isDocument) {
        // Pulsante correlati
        // return 'SUGGESTED_ITEMS_TO_THIS';
      }
    }
  }

  // Gestisce l'azione sul pulsante nel banner della Library
  onLibraryItemMainBtnActionClicked(action) {
    if (action) {
      const isContainer = this.itemDetails.isLearningPlan || this.itemDetails.isProject;
      if (action === 'GO_TO_SURVEY' || action === 'CONTINUE_CERTIFICATION') {
        this.goToCourseSurvey();
      } else if (action === 'DOWNLOAD_THE_BOOK' || action === 'DOWNLOAD_ATTACHMENTS' || action === 'DOWNLOAD_THE_DOCUMENT' || action === 'DOWNLOAD_THE_GRAPH') {
        // Apro la sezione degli allegati per scaricare il video
        this.openTab({ id: 'attachmentList' });
        // Ed eseguo un autoscroll in pagina
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        });
      } else if (action === 'WATCH_VIDEO' || action === 'LISTEN_TO_PODCAST' || action === 'PERFORM_SCORM' || action === 'SEE_THE_IMAGE') {
        // Autoscroll fino al player. Dopo il 10 giugno il player è stato spostato in alto quindi il pulsante principale di autoscorll è inutile
        //   this.scrollIntoPage('player-wrapper');
      } else if (action === 'SHOW_SECTIONS' || action === 'SHOW_ITEMS') {
        // Autoscroll fino ai contenuti del contenitore
        this.scrollIntoPage('items-of-container');
      } else if (action === 'ACCESS_TO_COURSE' && this.webCourseId && this.itemDetails.courseUrl) {
        this.setWebCourseAsOpenedAndAfterOpenIt();
      } else if (action === 'SET_WEB_COURSE_AS_COMPLETE' && this.webCourseId) {
        // Chiamo il servizio per settare come completato il corso web
        this.openCompleteWebCourseModal();
      } else if (action === 'SUGGESTED_ITEMS_TO_THIS') {
        // Autoscroll fino ai suggeriti
        this.scrollIntoPage('may-be-interested-on');
      }
    }
  }

  // Setta come aperto il corso dal web e poi apre il link
  setWebCourseAsOpenedAndAfterOpenIt() {
    this.isGettingItemDetails = true;
    this.setWebCourseAsOpened$ = this.itemService.glpConfirmOfcoursemeCourseOpenAfterOpenIt(this.webCourseId)
      .subscribe(data => {
        if (data.error) {
          // Mostro il toaster di errore
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else if (data.response) {
          this.toastr.success(this.translate.instant('generic.DATA_SAVED'));

          this.openCustomLink(this.itemDetails.courseUrl);
        }
        // Spengo il loader
        this.isGettingItemDetails = false;

      }, (err) => {
        this.isGettingItemDetails = false;

        this.toastr.error(this.translate.instant('errors.' + err));
      });
  }

  openCustomLink(link: string) {
    if (link && link.length) {
      link = link.trim();
      if (!link.match(/^https?:\/\//i)) {
        link = 'http://' + link;
      }

      // Invia un tracciamento a Google Tag Manager per Google Analytics per quanto riguarda l'apertura di un link extra piattaforma
      this.analyticsService.sendOutboundLinkEvent(link);
      window.open(link, '_blank');
    }
  }

  // Gestisce la progressione di Azure
  itemInProgress(data: { currentTime: number, totalTime: number }) {
    if (!this.adminMode && data.currentTime && data.totalTime && data.currentTime < data.totalTime) {
      if (this.isOnlineCourse && this.itemLinkedToCourse) {
        // Dato che box di azure può venire chiuso/aperto più volte e la posizione da cui partire viene recuperata solo con l'inizializzazione dell'item, devo aggiornare il valore manualmente
        //  this.itemLinkedToCourse.damPlayerSeekTo = data.currentTime;
        this.itemLinkedToCourse.completionPercentage = ((Math.floor(data.currentTime * 100 / data.totalTime)).toString());
      } else {
        // Dato che box di azure può venire chiuso/aperto più volte e la posizione da cui partire viene recuperata solo con l'inizializzazione dell'item, devo aggiornare il valore manualmente
        //  this.itemDetails.damPlayerSeekTo = data.currentTime;
        this.itemDetails.completionPercentage = ((Math.floor(data.currentTime * 100 / data.totalTime)).toString());
      }
      this.ref.detectChanges();
    }
  }

  // Gestisce la conclusione degli item di Azure
  itemConsumed(isConsumed: boolean) {
    if (isConsumed) {
      if (this.isSyllabusCourse && this.itemLinkedToCourse) {
        this.itemLinkedToCourse.completionPercentage = '100';
        this.itemLinkedToCourse.isConsumed = true;
        if (!this.adminMode && this.isSurveyCertificable && !this.itemLinkedToCourse.isCertified) {
          this.enableCertification = true;
        }
      } else {
        this.itemDetails.completionPercentage = '100';
        this.itemDetails.isConsumed = true;
        if (!this.adminMode && this.isSurveyCertificable && !this.itemDetails.isCertified) {
          this.enableCertification = true;
        }
      }
      this.isGettingItemDetails = true;
      this.ref.detectChanges();
      setTimeout(() => {
        this.itemDetails = null;
        this.itemLinkedToCourse = null;
        this.resetData();
        this.getItemDetails();
      }, 500);
    }
  }

  // Gestisce il click sulle azioni del banner per i corsi del cm2
  onCourseMainBtnActionClicked(action: string) {
    if (action) {
      if (action === 'ACCEPT_EDITION_INVITE') {
        // Apro la modale per accettare l'invito all'iscrizione
        this.openConfirmSubscriptionModal(this.editions[0]);
      } else if (action === 'GOTO_SURVEY') {
        // Vai alla tab questionari
        this.openTab({ id: "surveys" });
      } else if (action === 'SHOW_EXTERNAL_COURSE_DETAILS') {
        // Apro la modale per vederne i dettagli
        this.openConfirmSubscriptionModal(this.editions[0], true);
      } else if (action === 'DO_YOU_WANT_TO_REFUSE_INVITE') {
        // Apro la modale per il rifiuto dell'iscrizione
        this.openCancelSubscriptionModal(this.editions[0]);
      } else if (action === 'SHOW_SECTIONS' || action === 'SHOW_ITEMS') {
        // Autoscroll fino ai contenuti del contenitore
        this.scrollIntoPage('items-of-container');
      } else if (action === 'SUBSCRIBE_TO_ONLINE_COURSE' || action === 'SEE_THE_WRAPPED_IMAGE') {
        if (this.isUserSubscribedToWrapperLibraryItem()) {
          // Semplice scroll fino alla sezione dell'oggetto. // Dopo il 10 giugno il player è stato spostato in alto quindi il pulsante principale di autoscorll è inutile
          //this.scrollIntoPage('player-wrapper');
        } else if (this.canUserSubscribeToCourseThatWrapLibraryItem()) {
          // Iscrivo l'utente al corso online (che wrappa un item della library)
          let confirmUserLibraryPromise = this.confirmUserAndGoToLibrary(this.editions[0]);
          confirmUserLibraryPromise.then(() => {
            // Setto l'utente come registrato nell'unica edizione disponibile
            this.editions[0].isInvited = false;
            this.editions[0].isConfirmed = true;
            // Toaster di avviso
            this.toastr.success(this.translate.instant('generic.REGISTRATION_DONE'));
            /* Autoscroll // Autoscroll fino al player. Dopo il 10 giugno il player è stato spostato in alto quindi il pulsante principale di autoscorll è inutile
            if (!preventLibraryAutoScroll) {
              setTimeout(() => {
                this.scrollIntoPage('player-wrapper');
              }, 150)
            } */
          })
        }
      }
    }
  }

  // Apre la modale con i dettagli dell'unica edizione webinar disponibile
  openWebinarUniqueEditionDetails() {
    this.openConfirmSubscriptionModal(this.editions[0], true);
  }

  scrollIntoPage(elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        let elementPosition = element.offsetTop;
        let scrollDistance = elementPosition - 100;
        let appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
        if (appInitiatives) {
          if (this.isUsingInternetExplorer) {
            appInitiatives.scrollTop = scrollDistance;
          } else {
            appInitiatives.scrollTo({
              top: scrollDistance,
              behavior: 'smooth',
            });
          }
        }
      }
    })
  }

  // Torna l'edizione di un materiale di un corso del CM2
  getEditionOfCoureMaterial(parentId: string) {
    // Se sono nel dettaglio di un assessment, 'editions' conterrà l'edizione della rilevazione; così, devo controllare nel contenitore contenente tutte le edizioni
    let itemRef = this.isAssessment ? this.editionsListWithPastEditions : this.editions;
    if (parentId && itemRef && itemRef.length) {
      let editions = itemRef.filter((currentEdition: any) => {
        return currentEdition.courseDateId === parentId;
      });

      // Se è una rilevazione e non ho trovato nessuna iniziativa associata significa che è una rilevazione a sè stante
      if (this.isAssessment && (!editions || !editions.length) && itemRef && itemRef.length) {
        return itemRef[0];
      }
      return editions && editions[0];
    }
  }

  isInternetExplorerBrowser() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }
    return false;
  }

  // Per ogni edizione, se non è stato segnato negli engagement, controllo manualmente se è già stata fatta una verifica
  searchCertificationsDone(editionsListWithPastEditions: Array<any>) {
    let promises = [];
    // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
    if (!editionsListWithPastEditions) {
      editionsListWithPastEditions = [];
    }
    let tmpList = editionsListWithPastEditions.concat(this.itemDetails);

    for (let j = 0, editionsLength = tmpList.length; j < editionsLength; j++) {
      let currentEdition = tmpList[j];
      if (currentEdition.engagements && currentEdition.engagements.length) {
        for (let m = 0, engagementsLength = currentEdition.engagements.length; m < engagementsLength; m++) {
          let currentEngagement = currentEdition.engagements[m];
          if (currentEngagement.moduleName === ReferenceTypes.ITEM) {
            if (currentEngagement.eventName == ReferenceTypes.EVENT_COURSE_USER_PRESENT) {
              // L'item è stato concluso
              currentEdition.isConsumed = true;
            }
            if (currentEngagement.eventName === ReferenceTypes.EVENT_ITEM_CERTIFIED) {
              currentEdition.isItemAlreadyCertified = true;
              // dunque salvo la data
              currentEdition.certifiedDate = currentEngagement.creationDate;
            }
          }
        }
      }
      let responses = [];
      promises.push(new Promise((resolve: Function, reject: Function) => {
        // L'assessmentId è per le edizioni che hanno una (o più) survey associata,
        // courseDateId per chi non ha la survey, e itemId che rappresenta l'item a livello di iniziativa aggiunta con il concat precedente
        let idsPromises = [];
        let refIds = [];
        if (currentEdition.assessmentIds && currentEdition.assessmentIds) {

          for (let d = 0, assessmentIdsLength = currentEdition.assessmentIds.length; d < assessmentIdsLength; d++) {
            refIds.push(currentEdition.assessmentIds[d].itemId);
          }
        } else {
          refIds.push(currentEdition.courseDateId || currentEdition.itemId);
        }
        this.assessmentEditionsForSurveysByInitiativeEditionId = {};
        for (let f = 0, idsLength = refIds.length; f < idsLength; f++) {
          let currentRefId = refIds[f];
          idsPromises.push(new Promise((res: Function, rej: Function) => {
            this.itemService.getSurveys(this.loggedUser.user.userId, currentRefId)
              .subscribe(
                (data: SenecaResponse<Survey>) => {
                  responses.push(data.response);
                  if (data.error) {
                    this.toastr.error(this.translate.instant('errors.' + data.error));
                    rej();
                  } else {
                    currentEdition.isSurveyStarted = false;
                    currentEdition.isSurveyCertificable = false;
                    currentEdition.survey = data.response;
                    currentEdition.isSurveyNotRepeteable = false;
                    currentEdition.referenceId = data.response && data.response.referenceId;
                    if (data.response && data.response.surveyStatus) {
                      if (data.response.surveyAttributes && data.response.surveyAttributes.length && data.response.surveyAttributes.some(attr => attr.attributeType == SurveyAttributeTypes.IS_SURVEY_NOT_REPEATABLE)) {
                        currentEdition.isSurveyNotRepeteable = true;
                      }
                      if (data.response.surveyStatus === SurveyStatuses.STARTED) {
                        // Survey iniziata
                        currentEdition.isSurveyStarted = true;
                      } else if (data.response.surveyStatus === SurveyStatuses.VALID) {
                        // Se lo status è valido, significa che ho una survey certificabile
                        currentEdition.isSurveyCertificable = true;
                      } else if (data.response.surveyStatus === SurveyStatuses.PASSED && !currentEdition.isSurveyStarted) {
                        // Survey completata con successo
                        currentEdition.isSurveyPassed = true;
                        // Salvo la data in cui è stata fatta l'operazione
                        currentEdition.surveyPassedDate = data.response.modifyDate;
                      } else if (data.response.surveyStatus === SurveyStatuses.NOT_PASSED) {
                        // Survey completata ma senza raggiungere un punteggio sufficiente
                        currentEdition.isSurveyNotPassed = true;
                      }
                      currentEdition.surveyStatus = data.response.surveyStatus;
                      currentEdition.assessmentType = 'survey.OTHER';
                      if (currentEdition.courseId == currentRefId || currentEdition.stageItemId == currentRefId || currentEdition.stageItemId == data.response.referenceId) {
                        currentEdition.surveyStatus = data.response.surveyStatus;
                      }
                    }
                    if ((currentEdition.isSurveyNotPassed && currentEdition.isSurveyNotRepeteable) || currentEdition.isSurveyStarted || currentEdition.isSurveyPassed || currentEdition.isSurveyCertificable || currentEdition.isItemAlreadyCertified) {
                      let alreadyAdded = !!this.editionsSurvey.find(edition => {
                        return edition.courseDateId == currentEdition.courseDateId;
                      })
                      if (!alreadyAdded) {
                        if (this.isAssessment) {
                          //Controllo il numero di edizioni in modo da confrotarle col figlio stageItem
                          // Gestione dell'assessment
                          //Se l'edizione è quella legata allo stage, controllo le date di inizio e di fine dell'edizione, nel caso il corso fosse già terminato o ancora da iniziare, non mostro il questionario.
                          if (currentEdition.itemId == this.itemDetails.itemId && !moment(currentEdition.lastDayDate).isBefore(new Date().toUTCString()) && !moment(currentEdition.firstDayDate).isAfter(new Date().toUTCString())) {
                            this.editionsSurvey.push(currentEdition);
                          };
                        } else {
                          // Gestione di una iniziativa che ha un assessment collegato ad una delle sue edizioni
                          for (let i = 0; i < this.editionsListWithPastEditions.length; i++) {
                            let currentPastEdition = this.editionsListWithPastEditions[i];
                            if (currentPastEdition.assessmentIds && currentPastEdition.assessmentIds.length) {
                              // Ora cerco fra le edizioni, quella che realmente è la rilevazione
                              for (let m = 0; m < this.editionsListWithPastEditions.length; m++) {
                                let currentEditionPast = this.editionsListWithPastEditions[m];
                                let currentPastEditionAlreadyAdded = !!this.editionsSurvey.find(edition => {
                                  return edition.courseDateId == currentEditionPast.courseDateId;
                                })
                                let isValidDate = !moment(currentEditionPast.lastDayDate).isBefore(new Date().toUTCString()) && !moment(currentEditionPast.firstDayDate).isAfter(new Date().toUTCString());

                                for (let b = 0, assessmentIdsLength = currentPastEdition.assessmentIds.length; b < assessmentIdsLength; b++) {
                                  const currentAssessment = currentPastEdition.assessmentIds[b] && currentPastEdition.assessmentIds[b];
                                  if (isValidDate && currentAssessment && currentAssessment.itemId && currentAssessment.itemId === currentEditionPast.stageItemId
                                    && !currentPastEditionAlreadyAdded) {
                                    if (!currentEditionPast.formattedStopDate) {
                                      currentEditionPast.formattedStopDate = currentEditionPast.endDate && moment(currentEditionPast.endDate).format('DD/MM/YYYY') || '';
                                    }

                                    // Traduco la tipologia di assessment (altro, gradimento, apprendimento)
                                    let assessmentType = 'survey.OTHER';

                                    if (currentAssessment && currentAssessment.itemAttributes && currentAssessment.itemAttributes.length) {
                                      let notRepeable = currentAssessment.itemAttributes.filter((attribute: any) => attribute.attributeType === SurveyAttributeTypes.IS_SURVEY_NOT_REPEATABLE);
                                      if (notRepeable && notRepeable[0]) {
                                        currentEditionPast.isSurveyNotRepeteable = notRepeable[0].attributeValue == 'true';
                                      } else {
                                        currentEditionPast.isSurveyNotRepeteable = false;
                                      }
                                      for (let e = 0, assessmentAttributesLength = currentAssessment.itemAttributes.length; e < assessmentAttributesLength; e++) {
                                        if (currentAssessment.itemAttributes[e].attributeType && currentAssessment.itemAttributes[e].attributeType === ItemAttributeTypes.SURVEY_TYPE) {
                                          currentEditionPast.customStatus = true;
                                          assessmentType = 'survey.' + currentAssessment.itemAttributes[e].attributeValue;
                                          // break;
                                        }
                                        if (currentAssessment.itemAttributes[e].attributeType && currentAssessment.itemAttributes[e].attributeType === ItemAttributeTypes.STATUS) {
                                          if (currentAssessment.itemAttributes[e].attributeValue == SurveyStatuses.PASSED) {
                                            currentEditionPast.isSurveyPassed = true;
                                          } else if (currentAssessment.itemAttributes[e].attributeValue == SurveyStatuses.STARTED || currentAssessment.itemAttributes[e].attributeValue === "IN_PROGRESS") {
                                            currentEditionPast.isSurveyStarted = true;
                                          } else if (currentAssessment.itemAttributes[e].attributeValue == SurveyStatuses.NOT_PASSED) {
                                            currentEditionPast.isSurveyNotPassed = true;
                                          }
                                        }
                                      }
                                    }

                                    currentEditionPast.assessmentType = assessmentType;

                                    if (currentEditionPast.courseId == currentRefId || currentEditionPast.stageItemId == currentRefId || currentEditionPast.referenceId == data.response.referenceId) {
                                      currentEditionPast.surveyStatus = data.response.surveyStatus;
                                    }
                                    if (!currentEditionPast.surveyStatus) {
                                      for (let z = 0; z < responses.length; z++) {
                                        if (responses[z].referenceId == currentEditionPast.courseId) {
                                          currentEditionPast.surveyStatus = responses[z].surveyStatus;
                                        }
                                      }
                                    }
                                    this.editionsSurvey.push(currentEditionPast);
                                    // Tale edizione, per non trovarmela nella lista delle edizioni (bensì solo nelle sessioni), la tolgo
                                    for (let t = 0; t < this.editions.length; t++) {
                                      let alreadyAddedEdition: any = this.editions[t];
                                      if (alreadyAddedEdition.stageItemId && alreadyAddedEdition.stageItemId === currentEditionPast.stageItemId) {
                                        this.editions.splice(t, 1);
                                        break;
                                      }
                                    }
                                    break;
                                  }
                                }
                              }
                            }
                            /* Se l'edizione è quella legata allo stage, controllo le date di inizio e di fine dell'edizione, nel caso il corso fosse già terminato o ancora da iniziare, non mostro il questionario.
                            if (this.editionsListWithPastEditions[i].stageItemId == this.itemDetails.itemId &&
                              !moment(this.editionsListWithPastEditions[i].lastDayDate).isBefore(new Date().toUTCString()) && !moment(this.editionsListWithPastEditions[i].firstDayDate).isAfter(new Date().toUTCString())) {
                              this.editionsSurvey.push(currentEdition);
                            }; */
                          }
                        }
                      }
                    }
                    /*
                    if (currentEdition.itemId && currentEdition.itemId === this.itemDetails.itemId) {
                      this.itemDetails = JSON.parse(JSON.stringify(currentEdition));
                    } */
                    res();
                  }
                  // NON togliere, è ridondante ma lo status per qualce motivo si perde ed è necessario riassociarlo
                  if (this.editionsSurvey && this.editionsSurvey.length && responses && responses.length) {
                    for (let edition of this.editionsSurvey) {
                      for (let response of responses) {
                        if (response && edition.courseId == response.referenceId) {
                          edition.surveyStatus = response.surveyStatus;
                        }
                      }
                    }
                  }
                },
                (err) => {
                  this.toastr.error(this.translate.instant('errors.' + err.message));
                  rej();
                });
          }));
        }
        Promise.all(idsPromises).then(() => {
          resolve();
        })
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  isContentOfProjectsVisible() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (!this.isGettingItemDetails && itemRef && itemRef.isProject && itemRef.itemChilds && itemRef.itemChilds.length) {
      return true;
    }
    return false;
  }

  isContentOfPlaylistVisible() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (!this.isGettingItemDetails && itemRef && itemRef.isLearningPlan && itemRef.itemChilds && itemRef.itemChilds.length) {
      return true;
    }
    return false;
  }

  isYouCanInterestedOnSliderVisible() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (!this.collectionId && itemRef && !itemRef.isLearningPlan && !itemRef.isProject) {
      return true;
    }
    return false;
  }

  showCovidAlert() {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (itemRef && !itemRef.isLearningPlan && !itemRef.isProject && !this.collectionId && !this.webCourseId && !this.isSyllabusCourse && this.canAskForRent
      && this.itemDetails && this.itemDetails.hasRentableAttribute
      ||
      (this.isSyllabusCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.hasRentableAttribute)) {
      return true;
    }

    return false;
  }

  // Formatta le date unendole tramite il luogo
  formatEditionsByPlace(courseEditionList: any[]) {
    const editionsByPlace = [];

    const places: any = {};

    // raggruppare per il luogo delle giornate dell'edizione
    courseEditionList.forEach((courseEdition) => {
      if (courseEdition.days && courseEdition.days.length) {
        for (let e = 0, daysLength = courseEdition.days.length; e < daysLength; e++) {
          let courseDay = courseEdition.days[e];
          // Ricavo l'id per la mappa
          const placeId = courseDay.location && ((courseDay.location.address || courseDay.location.city) && (courseDay.location.address || courseDay.location.city).id);

          // Ricavo il nome della città
          const cityName = courseDay.location && ((courseDay.location.place || courseDay.location.city) && (courseDay.location.place || courseDay.location.city).id);

          // Ricavo la via
          const roomTitle = courseDay.location && courseDay.location.room && courseDay.location.room.title;

          // Dall'edizione, tolgo tutte le giornate che non abbiano il placeId corrente
          let copiedEdition = JSON.parse(JSON.stringify(courseEdition));

          for (let j = copiedEdition.days.length - 1; j >= 0; j--) {
            let currentDay = copiedEdition.days[j];
            const currentPlaceId = currentDay.location && ((currentDay.location.address || currentDay.location.city) && (currentDay.location.address || currentDay.location.city).id);
            if (currentPlaceId !== placeId) {
              copiedEdition.days.splice(j, 1);
            }
          }

          if (placeId && places[placeId]) {
            let alreadyAdded;
            if (places[placeId].editions && places[placeId].editions.length) {
              for (let k = 0, addedEditionsLength = places[placeId].editions.length; k < addedEditionsLength; k++) {
                let currentEditionAdded = places[placeId].editions[k];
                if (currentEditionAdded.courseDateId === copiedEdition.courseDateId) {
                  alreadyAdded = true;
                  break;
                }
              }
            }
            if (!alreadyAdded) {
              places[placeId].editions.push(copiedEdition);
            }
          } else if (placeId && !places[placeId]) {
            places[placeId] = { editions: [], cityName: cityName, roomTitle: roomTitle };
            places[placeId].editions.push(copiedEdition);
          }
        }
      }
    });

    // Trasformo il tutto in un array (per poi poterlo ordinare)
    for (const key in places) {
      if (places.hasOwnProperty(key)) {
        const element = places[key];

        let dates = [];

        // Per ogni luogo ricavo tutte le giornate dell'edizione affinché si possa costruire la card
        const editionsOfPlace = element.editions;
        if (editionsOfPlace && editionsOfPlace.length) {
          let index = 1;
          element.editions.forEach((courseEdition) => {
            courseEdition.days.forEach((courseDay: CourseDaySchedule) => {
              if (courseDay.dayDate) {
                const feDate = CourseEditionUtil.getItemDetailDayCard(index, courseEdition, courseDay, this.isExternalCourse);
                dates.push(feDate);
              }
            })
            index++;
          })
        }

        // Costruisco l'oggetto completo
        editionsByPlace.push({
          placeId: key,
          cityName: element.cityName,
          dates: dates,
          roomTitle: element.roomTitle,
          editionsLength: (editionsOfPlace && editionsOfPlace.length || 0),
          editions: editionsOfPlace
        });
      }
    }

    // ordino per placeId
    return editionsByPlace.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'placeId' }]));
  }

  loadAllCountMaterials() {
    let promises = [];
    const materialTypes = [ItemAttributeMaterialTypes.MATERIAL_CLASSROOM, ItemAttributeMaterialTypes.MATERIAL_POST_WORK];

    for (let l = 0, typesLength = materialTypes.length; l < typesLength; l++) {
      let currentMaterialType = materialTypes[l];

      promises.push(new Promise((resolve: Function, reject: Function) => {
        let classroom = currentMaterialType === ItemAttributeMaterialTypes.MATERIAL_CLASSROOM;
        let postwork = currentMaterialType === ItemAttributeMaterialTypes.MATERIAL_POST_WORK;

        /* Codice se si vuole recuperare i materiali di tutte le edizioni
        let itemIds = [this.itemDetails.itemId, ...this.getAllDistinctEditions().filter((edition: CourseEdition) => {
        if (classroom) {
          return moment().utc().isSameOrAfter(moment(edition.firstDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours'));
        } else if (postwork) {
          return moment().utc().isSameOrAfter(moment(edition.lastDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours'));
        }
        return false;
        }).map((edition: any) => edition.courseDateId)];*/

        let materialEditionId;
        let editionRef = this.selectedEditionOfMainSelect ? this.selectedEditionOfMainSelect : (this.editions && this.editions[0]);

        if (editionRef &&
          (
            (classroom && moment().utc().isSameOrAfter(moment(editionRef.firstDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours')))
            ||
            (postwork && moment().utc().isSameOrAfter(moment(editionRef.lastDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours')))
          )) {
          materialEditionId = editionRef.courseDateId
        }

        let itemIds = [this.itemDetails.itemId];

        if (materialEditionId) {
          itemIds.push(materialEditionId);
        }

        this.itemService.countUserMaterialsForParent(this.loggedUser.user.userId, itemIds, [currentMaterialType])
          .subscribe(data => {
            if (data.error) {
              // Mostro il toaster di errore
              this.toastr.error(this.translate.instant('errors.' + data.error));
              reject();
            } else {
              if (classroom) {
                this.classroomMaterialsCounter = classroom ? data.response : this.classroomMaterialsCounter;
              } else {
                this.postworkMaterialsCounter = postwork ? data.response : this.postworkMaterialsCounter;
              }
              resolve();
            }
          })
      }));
    }
    return Promise.all(promises);
  }

  // Track del download del certificato
  trackDownloadCertificate(editionId: string) {
    this.itemService.trackDownloadCertificate(editionId)
      .subscribe((data: any) => {
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.mapNotShowCertificateBannerMessageOnDownload.set(editionId, true);
        }
      },
        (err) => {
          this.toastr.error(err.message);
        })
  }

  // Track dello share del certificato
  trackShareCertificate(editionId: string) {
    this.itemService.trackShareCertificate(editionId)
      .subscribe((data: any) => {
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.mapNotShowCertificateBannerMessageOnShare.set(editionId, true);
        }
      },
        (err) => {
          this.toastr.error(err.message);
        })
  }

  // Funziona che dice di non far vedere il banner del certificato
  notShowBannerOfCertificateMessage() {
    if (this.allCertificatesDownload) {
      return true;
    } else if (this.allCertificatesShare) {
      return true;
    } else {
      let mapDownloadHasFalseValues = false;
      let mapShareHasFalseValues = false;
      this.mapNotShowCertificateBannerMessageOnDownload && this.mapNotShowCertificateBannerMessageOnDownload.forEach((value: boolean, key: string) => {
        if (!value) {
          mapDownloadHasFalseValues = true;
        }
      });

      this.mapNotShowCertificateBannerMessageOnShare && this.mapNotShowCertificateBannerMessageOnShare.forEach((value: boolean, key: string) => {
        if (!value) {
          mapShareHasFalseValues = true;
        }
      });

      if (!mapDownloadHasFalseValues) {
        return true;
      } else if (!mapShareHasFalseValues) {
        return true;
      } else {
        return false;
      }
    }
  }

  downloadCourseCertificates(editionId: string, asImageForMessage?: boolean) {
    this.isFetchingCoursesCertifications = true;
    this.itemService.generateCourseCertificates(editionId, [this.loggedUser.user.userId], true, false, this.isEuropAssistanceUser)
      .subscribe((data: any) => {
        if (data.error) {
          this.isFetchingCoursesCertifications = false;
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else if (data.response) {
          let getTokenFromKeyPromise = this.getTokenFromSsortkqp();
          getTokenFromKeyPromise.then((ssorqtp: any) => {
            if (asImageForMessage) {
              this.certificateUrl = this.itemService.getDownloadTempFileUrl(data.response, ssorqtp);
              this.isFetchingCoursesCertifications = false;
            } else {
              let downloadUrl = this.itemService.getDownloadTempFileUrl(data.response, ssorqtp);
              this.isFetchingCoursesCertifications = false;
              setTimeout(() => {
                document.location.assign(downloadUrl);
              }, 500)
            }
          })

        } else {
          this.isFetchingCoursesCertifications = false;
        }
      },
        (err) => {
          this.toastr.error(err.message);
          this.isFetchingCoursesCertifications = false;
        })
  }

  getCoursesCertifications() {
    this.isFetchingCoursesCertifications = true;
    return new Promise((resolve: Function, reject: Function) => {
      this.itemService.getEditionIdsWhereUserHasCertificateByStageIid(this.loggedUser.user.userId, this.itemDetails.itemId)
        .subscribe(data => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
            this.isFetchingCoursesCertifications = false;
            resolve();
          } else {
            if (this.editionsListWithPastEditions && this.editionsListWithPastEditions.length && data.response && data.response.length) {
              let certificationsDetails = [];
              for (let i = 0, resLength = data.response.length; i < resLength; i++) {
                let currentCertification = data.response[i];
                for (let e = 0, editionsToCheckLength = this.editionsListWithPastEditions.length; e < editionsToCheckLength; e++) {
                  let currentEdition: any = this.editionsListWithPastEditions[e];
                  if (currentEdition.courseDateId && currentEdition.courseDateId === currentCertification.editionId) {
                    const certificationDetail = {
                      releaseDate: currentCertification.releaseDate,
                      linkedinUrl: this.itemDetails.enableCertificationExternalSharing ? this.globalApplicationData.applicationContext + `rest-api/coursemanager-mediator/certificate/share/optimized-html/${this.loggedUser.user.userId}/${this.itemDetails.itemId}/${currentCertification.editionId}` : null,
                      ...currentEdition
                    };

                    certificationsDetails.push(certificationDetail);
                    break;
                  }
                }
              }

              this.editionsCertifications = certificationsDetails;

              if (this.editionsCertifications && this.editionsCertifications.length) {
                this.getCertificationsLinkedinUrls(this.editionsCertifications);
              }

              for (let i = 0; i < this.editionsCertifications.length; i++) {
                this.mapNotShowCertificateBannerMessageOnDownload.set(this.editionsCertifications[i].courseDateId, false);
                this.mapNotShowCertificateBannerMessageOnShare.set(this.editionsCertifications[i].courseDateId, false)
              }

              if (this.editionsCertifications && this.editionsCertifications.length && this.itemDetails.editionIdsWithDownloadedCertificate && this.itemDetails.editionIdsWithDownloadedCertificate.length) {
                if (this.itemDetails.editionIdsWithDownloadedCertificate.length == this.editionsCertifications.length) {
                  this.allCertificatesDownload = true;
                } else {
                  this.allCertificatesDownload = false;
                }
              }

              if (this.editionsCertifications && this.editionsCertifications.length && this.itemDetails.editionIdsWithSharedCertificate && this.itemDetails.editionIdsWithSharedCertificate.length) {
                if (this.itemDetails.editionIdsWithSharedCertificate.length == this.editionsCertifications.length) {
                  this.allCertificatesShare = true;
                } else {
                  this.allCertificatesShare = false;
                }
              }

              this.isFetchingCoursesCertifications = false;
              resolve();
            } else {
              this.isFetchingCoursesCertifications = false;
              resolve();
            }
          }
        },
          (err) => {
            this.isFetchingCoursesCertifications = false;
            resolve();
          });
    })
  }

  // Recupera gli url dei certificati di linkedin
  getCertificationsLinkedinUrls(certificationsDetails) {
    //let promises: Array<any> = [];
    for (let j = 0, dataLength = certificationsDetails.length; j < dataLength; j++) {
      let currentCertification = certificationsDetails[j];
      currentCertification.linkedinUrl = this.globalApplicationData.applicationContext + `rest-api/coursemanager-mediator/certificate/share/optimized-html/${this.loggedUser.user.userId}/${this.itemDetails.itemId}/${currentCertification.courseDateId}`;
    }
    /*promises.push(new Promise((resolve: Function, reject: Function) => {
      this.itemService.getPublicCertificate(this.loggedUser.user.userId, currentCertification.courseDateId)
        .subscribe((data) => {
          currentCertification.linkedinUrl = data.response;
          resolve();
        }, (err) => {
          reject();
          this.toastr.error(this.translate.instant('errors.' + err));
        });
    }))
  }
  return Promise.all(promises);*/
  }

  // Verifica se mostrare il Box con l'immagine dell'oggetto library immagine (wrappato da un corso cm2)
  showPlayerOfWrappedImage() {
    return this.itemLinkedToCourse && this.itemLinkedToCourse.isDam && this.itemLinkedToCourse.itemId && this.itemLinkedToCourse.isImage && this.itemLinkedToCourse.damObjectUrl;
  }

  // Verifica se mostrare il Kaltura Player quando non è un'immagine (corso library wrappato da un corso cm2)
  showPlayerOfWrappedDam() {

    return this.itemLinkedToCourse && this.itemLinkedToCourse.isDam && this.itemLinkedToCourse.itemId && !this.itemLinkedToCourse.isImage;
  }

  // Verifica se mostrare il box con lo Scorm (corso library wrappato da un corso cm2)
  showPlayerOfWrapperScorm() {
    return this.itemLinkedToCourse && this.itemLinkedToCourse.isScorm;
  }

  // Verifica se mostrare il Box con l'immagine dell'oggetto library immagine (corso library isolato)
  showPlayerOfImage() {
    return !this.itemLinkedToCourse && this.itemDetails && this.itemDetails.isDam && this.itemDetails.itemId && this.isImage && this.itemDetails.damObjectUrl;
  }

  // Verifica se mostrare il Kaltura Player
  showPlayerOfDam() {
    return !this.itemLinkedToCourse && this.itemDetails && this.itemDetails.isDam && !this.isImage;
  }

  // Verifica se mostrare lo scorm
  showPlayerOfScorm() {
    return !this.itemLinkedToCourse && this.itemDetails && this.itemDetails.isScorm;
  }

  // Verifica se mostrare il contenitore dei player (kaltura o scorm)
  showPlayerBoxContainer() {
    let hasContent = this.showPlayerOfWrappedImage() || this.showPlayerOfWrappedDam() ||
      this.showPlayerOfWrapperScorm() || this.showPlayerOfImage() || this.showPlayerOfDam() || this.showPlayerOfScorm();

    let defaultConditions = !this.isLearningPlan && this.itemDetails && !this.itemDetails.hasRentableAttribute && !this.isProject && !this.collectionId && !this.webCourseId && (!this.isSyllabusCourse || (this.isSyllabusCourse && this.itemLinkedToCourse && !this.itemLinkedToCourse.hasRentableAttribute));

    if (this.isOnlineCourse && this.itemDetails.isDeadlinePassed) {
      return false;
    }
    return defaultConditions && hasContent;
  }

  isMaterialToOpen(materialItem: any) {
    return objectTypesToOpen.includes(materialItem && materialItem.item && materialItem.item.typology);
  }

  isMaterialToDownload(materialItem: any) {
    return objectTypesToDownload.includes(materialItem && materialItem.item && materialItem.item.typology);
  }

  isMaterialToDownloadDAM(materialItem: any) {
    return objectTypesToDownload.includes(materialItem && materialItem.item && materialItem.item.subtype);
  }

  toggleKalturaPlayerModal(materialItem?) {
    if (materialItem) {
      this.selectedMaterialForModal = materialItem;
      this.selectedMaterialForModal.azureImage = this.selectedMaterialForModal.mimeType && this.selectedMaterialForModal.mimeType.includes("image") ? this.selectedMaterialForModal.damObjectUrl : null;
      this.modalService.open('kalturaPlayerModal');
    } else {
      this.modalService.close('kalturaPlayerModal');
      this.selectedMaterialForModal = null;
    }
  }

  downloadMultimediaItem(materialItem) {
    const item = materialItem && materialItem.item;
    if (materialItem && materialItem.item && materialItem.item.itemChilds && materialItem.item.itemChilds.length) {
      let damAttachment = materialItem.item.itemChilds.find(child => !!child.childObject && child.childObject.itemType == ItemTypes.DAM_ATTACHMENT);
      if (damAttachment) {
        let externalObjectId = ItemUtil.getAttributeValue(damAttachment.childObject, ItemAttributeTypes.EXTERNAL_OBJECT_ID);
        if (externalObjectId) {
          let getAttachmentUrlPromise = ItemUtil.getAttachmentUrl(externalObjectId, this.adminMode, item, null, false, false, this.itemService, this.toastr);
          getAttachmentUrlPromise.then((response: any) => {
            if (response) {
              // Salvo l'url
              this.attachmentUrl = response;
              const ext = this.attachmentUrl && this.attachmentUrl.substr(this.attachmentUrl.lastIndexOf('.') + 1);

              const isKaltura = response && response.includes("https://www.kaltura.com");
              let name = isKaltura ? null : ((item.title || item.name) + '.' + ext);

              // E concludo recuperando il file
              let downloadUrl = ItemUtil.getExternalResourceName(this.attachmentUrl, name, this.globalApplicationData);
              this.isDownloadingExternalResource = false;
              setTimeout(() => {
                window.location.assign(downloadUrl);
              }, 500)
            }
          })
        } else {
          this.toastr.error(this.translate.instant('errors.' + ErrorCodes.OBJECT_NOT_FOUND));
        }
      }
    }
  }

  openCourseEditionDetails(materialItem, editionId) {
    return;
  }

  // Scarica un allegato
  getAttachmentUrl(attachment) {
    if (this.isDownloadingExternalResource) {
      return;
    }

    // Avvio il loader
    this.attachmentUrl = null;
    this.isDownloadingExternalResource = true;

    let attachmentId: string = null;
    let forcedItemIdEngagement: string = null;
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    let isItemOtherType: boolean = ItemUtil.isOtherTypeItem(itemRef);
    // Recuper l'id dell'allegato, che si trova come 'EXTERNAL_OBJECT_ID' fra i suoi attributi
    for (let i = 0; i < attachment.childObject.itemAttributes.length; i++) {
      if (attachment.childObject.itemAttributes[i].attributeType === ItemAttributeTypes.EXTERNAL_OBJECT_ID) {
        attachmentId = attachment.childObject.itemAttributes[i].attributeValue;
        forcedItemIdEngagement = attachment.childObject.itemAttributes[i].itemId;
        break;
      }
    }

    // Se non sono amministratore, in qualsiasi caso devo generare l'evento consumed quando si scarica un allegato
    let forceCreateConsumedEngagement: boolean = false;
    if (!this.adminMode) {
      forceCreateConsumedEngagement = true;
    }

    if (isItemOtherType) {
      forceCreateConsumedEngagement = null;
      forcedItemIdEngagement = null;
    }

    let getAttachmentUrlPromise = ItemUtil.getAttachmentUrl(attachmentId, this.adminMode, this.itemDetails, forcedItemIdEngagement, forceCreateConsumedEngagement, isItemOtherType, this.itemService, this.toastr);
    getAttachmentUrlPromise.then((response: any) => {
      // Salvo l'url
      this.attachmentUrl = response;
      const ext = this.attachmentUrl && this.attachmentUrl.substr(this.attachmentUrl.lastIndexOf('.') + 1);
      const isKaltura = response && response.includes("https://www.kaltura.com");
      let name = isKaltura ? null : ((attachment.childObject.title || attachment.childObject.name) + '.' + ext);
      // E concludo recuperando il file
      let downloadUrl = ItemUtil.getExternalResourceName(this.attachmentUrl, name, this.globalApplicationData);
      this.isDownloadingExternalResource = false;
      setTimeout(() => {
        // Scarico il file
        window.location.assign(downloadUrl);

        // Abilito la certificazione
        this.checkEnableCertificaion();
      }, 600)
    })
      .catch(() => {
        this.isDownloadingExternalResource = false;
      })
  }

  downloadMultimediaItemDAM(materialItem) {
    if (materialItem) {
      this.isDownloadingFile = true;
      let externalObjectIdAttr = ItemUtil.getAttributeByKey(materialItem.itemChilds[0].childObject, ItemAttributeTypes.EXTERNAL_OBJECT_ID);
      let externalObjectId = externalObjectIdAttr && externalObjectIdAttr.attributeValue;
      if (externalObjectId) {
        let getAttachmentUrlPromise = ItemUtil.getAttachmentUrl(externalObjectId, this.adminMode, materialItem, (materialItem && materialItem.itemId), true, true, this.itemService, this.toastr);
        getAttachmentUrlPromise.then((response: any) => {
          if (response) {
            // Salvo l'url
            this.attachmentUrl = response;
            const ext = this.attachmentUrl && this.attachmentUrl.substr(this.attachmentUrl.lastIndexOf('.') + 1);
            const isKaltura = response && response.includes("https://www.kaltura.com");
            let name = isKaltura ? null : ((materialItem.title || materialItem.name) + '.' + ext);

            // E concludo recuperando il file
            let downloadUrl = ItemUtil.getExternalResourceName(this.attachmentUrl, name, this.globalApplicationData);
            this.isDownloadingExternalResource = false;
            setTimeout(() => {
              window.location.assign(downloadUrl);
            }, 500)

          }
        })
      }
      this.resetData();
      this.getItemDetails();
    }
  }

  // Verifica se mancano i materiali nella tab selezionata
  public areMissingMaterialsInSelectedTab(): boolean {
    let areMissing: boolean = true;
    if (this.selectedMaterialTab) {
      if ((this.selectedMaterialTab === 'MATERIAL_PRE_WORK' && this.preworkMaterialsCounter)
        || (this.selectedMaterialTab === 'MATERIAL_CLASSROOM' && this.classroomMaterialsCounter)
        || (this.selectedMaterialTab === 'MATERIAL_POST_WORK' && this.postworkMaterialsCounter)
      ) {
        areMissing = false;
      }
    }
    return areMissing;
  }

  // Verifica se mostrare il blocco contenente l'informazione di materiali non trovati
  public showNoMaterialFoundLabel(): boolean {
    let isVisible = false;

    // I materiali possono esserci, ma non nella tab correntemente selezionata
    if (!this.isFetchingMaterialsFiles && (
      (!this.materials || !this.materials.length) || this.areMissingMaterialsInSelectedTab()
    )) {
      isVisible = true;
    }
    return isVisible;
  }

  getPlaceName(editionsByPlace) {
    return `${editionsByPlace.place} (${editionsByPlace.editions.length})`;
  }

  getAllDistinctEditions(): CourseEdition[] {
    return (this.editions || []).concat((this.editionsListWithPastEditions || [])).reduce((prev, curr) => {
      return prev.includes(curr) ? prev : prev.concat([curr]);
    }, []);
  }

  loadUserMaterialList(materialType: string, pageToLoad?: number) {
    this.currentMaterialListPage = !!pageToLoad ? pageToLoad : 1;
    this.selectedMaterialTab = materialType;
    this.isFetchingMaterialsFiles = true;
    let prework = materialType === ItemAttributeMaterialTypes.MATERIAL_PRE_WORK;
    let classroom = materialType === ItemAttributeMaterialTypes.MATERIAL_CLASSROOM;
    let postwork = materialType === ItemAttributeMaterialTypes.MATERIAL_POST_WORK;

    /* Codice se si vuole recuperare i materiali di tutte le edizioni
    let itemIds = [this.itemDetails.itemId, ...this.getAllDistinctEditions().filter((edition: CourseEdition) => {
      if (prework) {
        return true;
      } else if (classroom) {
        return moment().utc().isSameOrAfter(moment(edition.firstDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours'));
      } else if (postwork) {
        return moment().utc().isSameOrAfter(moment(edition.lastDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours'));
      }
      return false;
    }).map((edition: any) => edition.courseDateId)];*/

    let materialEditionId;
    let editionRef = this.selectedEditionOfMainSelect ? this.selectedEditionOfMainSelect : (this.editions && this.editions[0]);

    if (editionRef &&
      (prework
        ||
        (classroom && moment().utc().isSameOrAfter(moment(editionRef.firstDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours')))
        ||
        (postwork && moment().utc().isSameOrAfter(moment(editionRef.lastDayDate).subtract(MATERIAL_VISIBILITY_DELTA_HOURS, 'hours')))
      )) {
      materialEditionId = editionRef.courseDateId
    }

    let itemIds = [this.itemDetails.itemId];

    if (materialEditionId) {
      itemIds.push(materialEditionId);
    }

    if (!this.materials) {
      this.materials = [];
    } else {
      this.materials.length = 0;
    }

    this.itemService.countUserMaterialsForParent(this.loggedUser.user.userId, itemIds, [materialType]).pipe(
      switchMap((senecaResponse: SenecaResponse<number>) => {
        if (senecaResponse && senecaResponse.error) {
          return throwError(senecaResponse.error)
        }
        this.currentMaterialListCounter = senecaResponse.response;
        this.preworkMaterialsCounter = prework ? this.currentMaterialListCounter : this.preworkMaterialsCounter;
        this.classroomMaterialsCounter = classroom ? this.currentMaterialListCounter : this.classroomMaterialsCounter;
        this.postworkMaterialsCounter = postwork ? this.currentMaterialListCounter : this.postworkMaterialsCounter;

        if (senecaResponse.response) {
          // Calcolo la paginazione
          let fromRecord = 0;
          if (this.currentMaterialListPage) {
            fromRecord = (this.currentMaterialListPage - 1) * this.materialListNumRecords;
          } else {
            fromRecord = 0;
          }
          return this.itemService.listUserMaterialsForParent(this.loggedUser.user.userId, fromRecord, this.materialListNumRecords, itemIds, [materialType]);
        }
        return of(new SenecaResponse(null, null));
      })
    ).subscribe((senecaResponse: SenecaResponse<ItemFrontEndWrapper[]>) => {
      if (senecaResponse && senecaResponse.error) {
        this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
      }
      // Recupero le etichette per la tipologia
      this.itemService.setItemTypologyTranslations(senecaResponse.response);
      this.getTabs();
      this.materials = senecaResponse.response && senecaResponse.response.map((itemWrap) => {
        return {
          ...itemWrap,
          materialType: ItemUtil.getAttributeValue(itemWrap.item, ItemAttributeTypes.MATERIAL_TYPE),
          objectType: ItemUtil.getAttributeValue(itemWrap.item, ItemAttributeTypes.OBJECT_TYPE)
        }
      }) || [];
      this.isFetchingMaterialsFiles = false;
    }, (err) => {
      this.isFetchingMaterialsFiles = false;
      this.toastr.error(this.translate.instant('errors.' + err));
    });
  }

  // Recupera i taker dell'utente
  getUserTakers() {
    return new Promise<void>((resolve, reject) => {
      const editionIds = this.editions.map(edition => edition.courseDateId);
      if (editionIds && editionIds.length) {
        this.itemService.listUserTakersForCourse(<CourseManagerItem.ListUserTakersForPublic>{
          userId: this.loggedUser.user.userId,
          editionItemIds: editionIds,
          stageItemId: this.itemDetails.itemId,
          allData: true
        })
          .subscribe(
            (senecaResponse: SenecaResponse<EnrichedItemTaker[]>) => {
              if (senecaResponse.error) {
                // Vedo se c'è la traduzione dell'errore
                this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
                reject();
              } else {
                const takers = senecaResponse.response;
                let allUserEnroll = takers && takers
                  .map(taker => taker.takerEnrolls)
                  .reduce((flat, arr) => flat.concat(arr), []) || [];

                this.hasMoreEditions = !!this.editions && this.editions.length > 1;

                this.editions.forEach((edition: any) => {
                  let takerEnroll = allUserEnroll.find(enroll => enroll && enroll.itemId === edition.courseDateId);
                  edition.usedTakes = (takerEnroll && takerEnroll.usedTakes) || 0;

                  // Se non c'è più di una edizione, eseguo un raggruppamento delle giornate per data
                  if (!this.hasMoreEditions || (this.hasMoreEditions && this.isOnlineCourse)) {
                    edition.datesByMonth = this.getCourseEditionDatesByMounth(edition);
                  }
                });

                resolve();
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      } else {
        resolve();
      }
    });
  }

  getCourseEditionDatesByMounth(courseEdition) {
    // raggruppo le giornate per mese
    // la chiave è anno-mese, value: Date[]
    const months = {};
    const monthsArr = [];
    courseEdition.days.forEach((courseDay) => {
      let startTime = null;
      /*  if (courseEdition.isOnlineCourse || this.isAssessment || this.isWebinar) {
          startTime = courseDay.firstAndLastTimeSchedule.startTime;
        } else {
          startTime = (courseDay.morningTimeSchedule && courseDay.morningTimeSchedule.startTime)
            || (courseDay.afternoonTimeSchedule && courseDay.afternoonTimeSchedule.startTime);
        } */
      startTime = courseDay.timeSchedules && (
        (courseDay.timeSchedules[1] && courseDay.timeSchedules[1].startTime)
        || (courseDay.timeSchedules[0] && courseDay.timeSchedules[0].startTime)
      );

      if (startTime) {
        const key = moment(startTime).startOf('month').valueOf();
        months[key] = !!months[key] ? months[key] : [];
        months[key].push({
          date: moment(startTime)
        });
      }
    })
    for (const key in months) {
      if (months.hasOwnProperty(key)) {
        const elements = months[key];
        monthsArr.push({
          yearMonth: moment(parseInt(key)).locale('it'),
          dates: elements
        });
      }
    }
    return monthsArr;
  }

  // Recupera le survey (rilevazioni) associate alle edizioni
  getAssessmentsOfEditions(editionsListWithPastEditions: Array<any>) {
    let promises = [];
    // Oltre che la survey nelle edizioni devo cercarla anche a livello di iniziativa; per questo, aggiungo temporaneamente all'array di edizioni anche l'iniziativa (poi la rimuovo)
    if (!editionsListWithPastEditions) {
      editionsListWithPastEditions = [];
    }
    for (let j = 0, editionsLength = editionsListWithPastEditions.length; j < editionsLength; j++) {
      let currentEdition = editionsListWithPastEditions[j];
      promises.push(new Promise((resolve: Function, reject: Function) => {
        this.itemService.getEditionAssessmentsForPublic(this.loggedUser.user.userId, currentEdition.courseDateId)
          .subscribe(
            (assessmentData: SenecaResponse<Item[]>) => {
              if (assessmentData.error) {
                // Vedo se c'è la traduzione dell'errore
                this.toastr.error(this.translate.instant('errors.' + assessmentData.error));
                reject();
              } else if (assessmentData && assessmentData.response && assessmentData.response.length) {
                for (let k = 0, surveysLength = assessmentData.response.length; k < surveysLength; k++) {
                  const currentSurvey = assessmentData.response[k];

                  if (!currentEdition.assessmentIds) {
                    currentEdition.assessmentIds = [];
                  }

                  if (currentSurvey.referenceId && currentSurvey.referenceId === currentEdition.courseDateId) {
                    currentEdition.assessmentIds.push(currentSurvey);
                  }
                }
                resolve();
              } else {
                currentEdition.assessmentIds = null;
                resolve();
              }
            },
            (err) => {
              this.toastr.error(this.translate.instant('errors.' + err.message));
              reject();
            });
      }));
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  getLocationNameForCard(courseDayLocation) {
    return (courseDayLocation.place || courseDayLocation.city || courseDayLocation.address) && (courseDayLocation.place || courseDayLocation.city || courseDayLocation.address).id
  }

  getEditionLocation(edition) {
    if (edition.days && edition.days.length) {
      let courseDay = edition.days[0];
      return locationNameForCard(courseDay.location);
    }
    return '';
  }

  // Ricarica le edizioni
  reloadEditions() {
    this.selectedCourseDateId = null;
    this.editions.length = 0;
    if (this.editionsListWithPastEditions && this.editionsListWithPastEditions.length) {
      this.editionsListWithPastEditions.length = 0;
    }
    if (this.editionsSurvey && this.editionsSurvey.length) {
      this.editionsSurvey.length = 0;
    }
    if (this.certifiedEditions && this.certifiedEditions.length) {
      this.certifiedEditions.length = 0;
    }
    if (this.webinarCoursesByDay && this.webinarCoursesByDay.length) {
      this.webinarCoursesByDay.length = 0;
    }
    this.loadEditions();
  }

  // Gestisce il click su una card
  onCallToActionClicked(selectedDate) {
    if (selectedDate && selectedDate.callToAction) {
      let courseEdition;
      for (let k = 0, editionsLength = this.editions.length; k < editionsLength; k++) {
        if (this.editions[k].courseDateId === selectedDate.editionId || this.editions[k].courseDateId === selectedDate.courseDateId) {
          courseEdition = this.editions[k];
          break;
        }
      }
      if (selectedDate.callToAction === 'SUBSCRIBE') {
        // Apro la modale per l'iscrizione
        this.openConfirmSubscriptionModal(courseEdition);
      } else if (selectedDate.callToAction === 'DETAILS') {
        this.openSubscriptionDetailsModal(courseEdition)
      }
    }
  }

  isMapsEnabled(courseEdition, idx) {
    if (courseEdition) {
      let asd = courseEdition.days && courseEdition.days.find((schedule, index) => idx == index && !!schedule && !!schedule.location && !!schedule.location.locationLat);
      return courseEdition.days && courseEdition.days.find((schedule, index) => idx == index && !!schedule && !!schedule.location && !!schedule.location.locationLat);
    }
    return false;
  }

  openMaps(courseEdition, index: number) {
    courseEdition.showMaps = true;
    if (courseEdition && courseEdition.days[index]) {
      const location = courseEdition.days[index].location;
      if (location && location.locationLat && location.locationLong) {
        courseEdition.mapData = {
          lat: location.locationLat,
          lng: location.locationLong
        }
      }
    }
  }

  isSubscribeEnabled(edition) {
    if (edition.isOnlineCourse || (edition.isWebinar || (this.isCatalogStage && this.editions && this.editions[0] && this.editions[0].stageItemType == 'WEBINAR'))) {
      return edition && (edition.isInvited
        || edition.isCancelled)
        && moment(edition.lastDayDate).isSameOrAfter(new Date().toUTCString());
    } else {
      return edition && (edition.isInvited
        || edition.isCancelled)
        && moment(edition.endDate).isAfter(new Date().toUTCString());
    }
  }

  // Deve essere valorizzato il numero se è presente il check sull'abilitazione della prenotazione
  isMaxUsableTakesValid(edition) {
    if (this.isSubscribeEnabled(edition) &&
      edition.maxUsableTakes &&
      !edition.usedTakes) {
      return false;
    }

    return true && this.validSubscriptionData();
  }

  // Cambia il valore dello usedTakes
  changeTakesValue(action: string, edition) {
    if (edition) {
      if (action === 'increment') {
        // Devo assicurarmi che il numero non sia maggiore del più piccolo numero fra i posti disponibili e quelli massimi settati dal back office per i posti prenotazione
        let maxVal = Math.min(edition.maxUsableTakes, (edition.availableSeats + edition.availableSeatsOB));
        if (edition.usedTakes < maxVal) {
          edition.usedTakes++;
          if (edition.usedTakes > 1) {
            this.additionalEnrollAttributes.push({ attributeType: "ENROLLED_PERSON_NAME", attributeValue: "" });
          }
        } else {
          return;
        }
      }
      if (action === 'decrement' && edition.usedTakes > 1) {
        edition.usedTakes--;
        this.additionalEnrollAttributes.pop();
      }
      this.changeUsedTakes(edition, edition.usedTakes);
    }
  }

  // Aggiorna il campo di testo dei partecipanti
  onParticipantNameChanged(text, attribute) {
    attribute.attributeValue = text;
  }

  // Cambia il valore al check sulla privacy
  coursePrivacyAcceptedChanged(edition) {
    edition.coursePrivacyAccepted = !edition.coursePrivacyAccepted;
  }

  // Apre la pagina della privacy del course
  openCoursePrivacy(edition) {
    CourseModuleUtil.openPrivacy('takers/coursePrivacy/' + edition.initiativeId, this.urlService);
  }

  openPrivacyPdf() {
    this.itemService.getDeliberationUploadUrl(this.loggedUser.user.userId, this.privacyObject.privacyUploadId)
      .subscribe((data) => {
        if (data && data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          let getTokenFromKeyPromise = this.getTokenFromSsortkqp();
          getTokenFromKeyPromise.then((ssorqtp: any) => {
            let downloadUrl = this.urlService.getExternalResourceName(data.response, this.privacyObject.privacyFileName);
            setTimeout(() => {
              window.open(downloadUrl, '_blank')
            }, 200)
          })
        }
      }, (err) => {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      })
  }


  dismissConfirmCourseSubscription() {
    this.selectedEditionForConfirmSubscription = null;
    this.modalService.close('confirmCourseSubscription');
  }

  confirmCourseSubscriptionModal() {
    this.modalService.close('confirmCourseSubscription');

    if (this.isSubscribeEnabled(this.selectedEditionForConfirmSubscription)) {
      this.subscribeToCourseEdition(this.selectedEditionForConfirmSubscription);
    } else {
      this.openCancelSubscriptionModal(this.selectedEditionForConfirmSubscription);
    }
  }

  validSubscriptionData() {
    let valid = true;
    if (this.additionalEnrollAttributes && this.additionalEnrollAttributes.length) {
      for (let i = 0; i < this.additionalEnrollAttributes.length; i++) {
        valid = valid && !this.hasInvalidReservationName(this.additionalEnrollAttributes[i]);
      }
    }
    return valid;
  }

  hasInvalidReservationName(reservation: { attributeValue: string }) {
    let invalidName = !reservation || !reservation.attributeValue || this.checkRegExp(reservation.attributeValue);
    /* if (invalidName && reservation && reservation.attributeValue) {
        setTimeout(() => {
            reservation.attributeValue = "";
        }, 150);
    } */
    return invalidName;
  }

  checkRegExp(x: any) {
    if (x.viewModel || x.viewModel == "") {
      if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.viewModel.trim()) && x.viewModel.trim().length > 1) {
        return false;
      } else {
        return true;
      }
    } else {
      if (/^[A-Za-z\'\`\s\/^[a-z\u00E0-\u00FC]+$\]*$/g.test(x.trim()) && x.trim().length > 1) {
        return false;

      } else {
        return true;
      }
    }
  }

  // Apre una modale per chiedere conferma sulla rimozione della iniziativa selezionate
  openConfirmSubscriptionModal(edition, justInReadMode?: boolean) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForConfirmSubscription = { ...edition };
    this.selectedEditionForConfirmSubscription.usedTakes = 0;

    if (justInReadMode) {
      this.selectedEditionForConfirmSubscription.cancelBtnLabel = this.translate.instant('generic.CLOSE');
    } else {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('awWizard.datesAndPlaces.SUBSCRIBE');
      this.selectedEditionForConfirmSubscription.cancelBtnLabel = this.translate.instant('generic.CANCEL');
    }
    this.selectedEditionForConfirmSubscription.stopDate = moment(this.selectedEditionForConfirmSubscription.stopDate);
    this.additionalEnrollAttributes = [];
    this.modalService.open('confirmCourseSubscription');
  }

  secondConfirmationCourseSubscriptionModal() {
    this.modalService.close('confirmCourseSubscription');
  }

  // Apre una modale per chiedere conferma sulla rimozione della iniziativa selezionate
  openSubscriptionDetailsModal(edition) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForConfirmSubscription = { ...edition };
    //Poichè i corsi Online e gli eventi esterni non hanno una stopDate(fine iscrizione) inserisco la endDate nello stopDate per non ricevere un invalid date nel dettaglio dell'item
    //e sottrago un'ora a front-end poichè senza restituisce la data con un'ora in più (es. db 12/01/2019 ore 23.00 -> fe 13/01/2019 ore 00.00)
    if (this.isOnlineCourse || this.isExternalEvent) {
      this.selectedEditionForConfirmSubscription.stopDate = moment(this.selectedEditionForConfirmSubscription.endDate).subtract(1, 'h');
    } else {
      this.selectedEditionForConfirmSubscription.stopDate = moment(this.selectedEditionForConfirmSubscription.stopDate).subtract(1, 'h');
    }

    if (!this.selectedEditionForConfirmSubscription.isConfirmed) {
      this.selectedEditionForConfirmSubscription.confirmSecondBtnLabel = this.translate.instant('generic.CLOSE');
    } else {
      this.selectedEditionForConfirmSubscription.confirmBtnLabel = this.translate.instant('takers.courseSubscription.modal.cancelSubscription.TITLE');
    }
    this.selectedEditionForConfirmSubscription.cancelBtnLabel = this.selectedEditionForConfirmSubscription.confirmSecondBtnLabel ? null : this.translate.instant('generic.CANCEL')

    this.selectedEditionForConfirmSubscription.participants = "";
    if (this.selectedEditionForConfirmSubscription.takerEnrollAttributes && this.selectedEditionForConfirmSubscription.takerEnrollAttributes.length) {
      for (let i = 0; i < this.selectedEditionForConfirmSubscription.takerEnrollAttributes.length; i++) {
        this.selectedEditionForConfirmSubscription.participants += this.selectedEditionForConfirmSubscription.takerEnrollAttributes[i].attributeValue;
        if (i + 1 < this.selectedEditionForConfirmSubscription.takerEnrollAttributes.length) {
          this.selectedEditionForConfirmSubscription.participants += ", "
        }
      }
    }
    this.modalService.open('confirmCourseSubscription');
  }

  dismissCancelSubscriptionModal() {
    this.modalService.close('cancelCourseSubscription');
    this.selectedEditionForCancelSubscription = null;
  }

  openCancelSubscriptionModal(edition) {
    // Archivio l'iniziativa che mi è stata passata
    this.selectedEditionForCancelSubscription = edition;
    this.selectedEditionForCancelSubscription.cancelMessage = '';
    this.modalService.open('cancelCourseSubscription');
  }

  confirmCancelSubscriptionModal() {
    this.modalService.close('cancelCourseSubscription');
    this.cancelSubscription(this.selectedEditionForCancelSubscription, this.selectedEditionForCancelSubscription.cancelMessage);
    this.selectedEditionForCancelSubscription = null;
  }

  cancelSubscription(edition, cancelMessage: string) {
    edition.cancelSubscriptionNote = cancelMessage;
    this.cancelParticipation(edition, cancelMessage);
  }

  onCancelMessageChanged(newVal?) {
    if (this.selectedEditionForCancelSubscription) {
      this.selectedEditionForCancelSubscription.cancelMessage = newVal;
    }
  }

  onNoteTitleChanged(newVal?) {
    if (this.itemNoteTmp) {
      this.itemNoteTmp.title = newVal;
    }
  }

  // Cancella la partecipazione ad una edizione
  cancelParticipation(courseEdition, eventData) {
    this.isLoadingEditions = true;
    const statusNote = !!eventData && typeof eventData == 'string' ? eventData : '';
    this.itemService.userSelfCancel(this.loggedUser.user.userId, courseEdition.courseDateId, statusNote, true)
      .subscribe(genericResponseSubscriber(this, (senecaResponse) => {
        this.toastr.success(this.translate.instant('generic.DATA_SAVED'));
        if (this.isWebinar && this.isInternetExplorerBrowser()) {
          window.location.reload();
        } else {
          this.reloadEditions();
        }
      }, (senecaResponse) => {
        if (senecaResponse.error) {
          this.isLoadingEditions = false;
          this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
        }
      }));
  }

  changeUsedTakes(courseEdition, newValue) {
    if (courseEdition && courseEdition.courseDateId) {
      courseEdition.usedTakes = newValue;
    }
  }

  // ISCRIZIONE
  subscribeToCourseEdition(courseEdition) {
    if (this.isOnlineCourse && !courseEdition.isOnlineEvent) {
      this.confirmUserAndGoToLibrary(courseEdition);
    } else {
      this.isLoadingEditions = true;
      this.itemService.userSelfConfirm(this.loggedUser.user.userId, courseEdition.courseDateId, true, courseEdition.usedTakes, courseEdition.coursePrivacyAccepted, this.additionalEnrollAttributes)
        .subscribe(genericResponseSubscriber(this, (senecaResponse) => {
          this.toastr.success(this.translate.instant('generic.DATA_SAVED'));
          if (this.isWebinar && this.isInternetExplorerBrowser()) {
            window.location.reload();
          } else {
            this.reloadEditions();
          }
        }, (senecaResponse) => {
          this.isLoadingEditions = false;
          if (senecaResponse.error) {
            this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
          }
        }));
    }
  }

  // Conferma l'iscrizione e porta alla library
  confirmUserAndGoToLibrary(courseEdition) {
    return new Promise<void>((resolve, reject) => {
      this.itemService.userSelfConfirm(this.loggedUser.user.userId, courseEdition.courseDateId, false, courseEdition.usedTakes, courseEdition.coursePrivacyAccepted)
        .subscribe((data) => {
          if (data && data.response) {
            resolve();
          } else {
            reject();
          }

          //  this.resetData();
          //  this.getItemDetails();

        }, (senecaResponse) => {
          reject();
          if (senecaResponse.error) {
            this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
          }
        });
    });
  }

  // Recuepra i like
  getLikes() {
    let refId;
    let referenceType;

    if (this.collectionId || this.webCourseId) {
      refId = this.collectionId || this.webCourseId;
      referenceType = ReferenceTypes.OFCOURSEME;
    } else {
      refId = this.itemId || this.itemToAddId;
      referenceType = ItemTypes.ITEM;
    }

    this.isGettingLikes = true;
    let getLikesCountPromise = ItemUtil.getLikesCountsByTypesAndReference(refId, referenceType, LikeTypes.VOTE, this.itemService, this.toastr);
    getLikesCountPromise.then((objData: any) => {
      // Salvo i like
      this.likeCounter.likes = objData.likes;

      // Salvo i dislike
      this.likeCounter.dislikes = objData.dislikes;
      // Recupero i miei like
      let getMyLikesCountPromise = ItemUtil.getMyLikeByTypeAndReference(refId, referenceType, LikeTypes.VOTE, this.itemService, this.toastr);
      getMyLikesCountPromise.then((resultData: any) => {
        if (resultData) {
          this.currentLike = resultData.currentLike;
          this.iLikeThis = resultData.iLikeThis;
          this.iDislikeThis = resultData.iDislikeThis;
        }
        this.isGettingLikes = false;

      })
        .catch(() => {
          this.isGettingLikes = false;
        })
    })
      .catch(() => {
        this.isGettingLikes = false;
      })
  }

  // Crea un like/dislike
  createLike(toAdd: string) {
    if (this.isGettingLikes) {
      return;
    }
    // Avvio il loader della sezione like/unlike
    this.isGettingLikes = true;
    // Preparo i dati del nuovo like
    let newLike = {
      likeType: LikeTypes.VOTE,
      likeSign: toAdd
    }

    let referenceId;
    let referenceType;
    if (this.collectionId || this.webCourseId) {
      referenceId = this.collectionId || this.webCourseId;
      referenceType = ReferenceTypes.OFCOURSEME;
    } else {
      referenceId = this.itemId || this.itemToAddId;
      referenceType = ItemTypes.ITEM;
    }

    this.createLike$ = this.itemService.createLike(newLike, referenceId, referenceType, CommonConstants.APPLICATION_TRAINING_PLATFORM, CommonConstants.APPLICATION_TRAINING_PLATFORM)
      .subscribe(data => {
        // Se ci sono errori, li mostro e torno alla lista dei template
        if (data.error) {
          this.isGettingLikes = false;
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          // Salvo il nuovo like
          this.currentLike = data.response;

          let googleTagManagerLikeType = '';

          // Salvo quale like è stato aggiunto
          if (toAdd == "+") {
            this.iLikeThis = true;
            googleTagManagerLikeType = "like";
          } else if (toAdd == "-") {
            this.iDislikeThis = true;
            googleTagManagerLikeType = "not like";
          }

          // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
          this.analyticsService.sendLikeUnLikeEvent(googleTagManagerLikeType, referenceId);

          // Aggiorno le informazioni sul conteggio totale
          this.getLikes();
        }
      }, (err) => {
        this.isGettingLikes = false;
        this.toastr.error(this.translate.instant('errors.' + err));
      });
  }

  // Verifica se c'è almeno un dato nella tab descrizione degli oggetti di Library isolati
  hasLibraryDescriptionTabAtLeastOneData() {
    if (this.itemDetails) {
      if (
        (this.itemDetails.techCompetences && this.itemDetails.techCompetences.length)
        || (this.itemDetails.softCompetences && this.itemDetails.softCompetences.length)
        || (this.itemDetails.description && this.itemDetails.description.length)
        //  || (this.itemDetails.subtitle && this.itemDetails.subtitle.length)
        || this.itemDetails.isSoftSkill || this.itemDetails.isTechSkill
        || (this.itemDetails.argumentTitle && this.itemDetails.argumentTitle.length)
        || (this.itemDetails.functionalAreaTag && this.itemDetails.functionalAreaTag.length)
        // || (this.itemDetails.adminTag && this.itemDetails.adminTag.length)
        || (this.itemDetails.levelTag && this.itemDetails.levelTag.length)
        || (this.itemDetails.streamTag && this.itemDetails.streamTag.length)
        || (this.itemDetails.lang && this.itemDetails.lang.length)
        || (this.itemDetails.linkUrls && this.itemDetails.linkUrls.length)
        || (this.itemDetails.suggestedList && this.itemDetails.suggestedList.length)
        || (this.itemDetails.author && this.itemDetails.author.length)
        || (this.itemDetails.editor && this.itemDetails.editor.length)
        || (this.itemDetails.publishYear && this.itemDetails.publishYear.length)
        || (this.itemDetails.publishLocation && this.itemDetails.publishLocation.length)
        || (this.itemDetails.publishCountry && this.itemDetails.publishCountry.length)
        || (this.itemDetails.editionYear && this.itemDetails.editionYear.length)
        || (this.itemDetails.isbn && this.itemDetails.isbn.length)
        || (this.itemDetails.director && this.itemDetails.director.length)
        || (this.itemDetails.shelf && this.itemDetails.shelf.length)
        || (this.itemDetails.quantity && this.itemDetails.quantity.length)
        || (this.itemDetails.collocation && this.itemDetails.collocation.length)
        || (this.itemDetails.tutor && this.itemDetails.tutor.length)
        || (this.itemDetails.helpdeskEmail && this.itemDetails.helpdeskEmail.length)
        || (this.itemDetails.tutorEmail && this.itemDetails.tutorEmail.length)) {
        return true;
      }
    }

    return false;
  }

  // Verifica se c'è almeno un dato nella tab descrizione dei corsi cm2
  hasCoursesDescriptionTabAtLeastOneData() {
    if (this.itemDetails) {
      if (this.isSyllabusCourse && this.itemDetails.courseSyllabus) {
        if ((this.itemDetails.pmList && this.itemDetails.pmList.length)
          || this.itemDetails.courseSyllabus.AGENDA
          || this.itemDetails.courseSyllabus.AIMS
          || this.itemDetails.courseSyllabus.PREREQS
          || this.itemDetails.courseSyllabus.APPROACH
          || this.itemDetails.courseSyllabus.ARGUMENTS
          || (this.isExternalCourseClass)
          || this.itemDetails.courseSyllabus.PARTNER
          || (this.itemDetails.courseSyllabus.TECH_COMPETENCES && this.itemDetails.courseSyllabus.TECH_COMPETENCES.length)
          || (this.itemDetails.courseSyllabus.SOFT_COMPETENCES && this.itemDetails.courseSyllabus.SOFT_COMPETENCES.length)
          || (this.itemDetails.courseSyllabus.WAVE && this.itemDetails.courseSyllabus.WAVE.length)
          || (!this.hasMoreEditions && this.editions && this.editions[0] && (this.editions[0].subscriptionCustomLink
            || this.editions[0].subscriptionCustomMail || this.editions[0].subscriptionInfoMail || (this.editions[0].teachers && this.editions[0].teachers.length) || (this.editions[0].tutors && this.editions[0].tutors.length)))
        ) {
          return true;
        }
      } else {

      }
    }

    return false;
  }

  // Verifica se c'è almeno un dato nella tab descrizione dei corsi dal web
  hasWebCourseDescriptionTabAtLeastOneData() {
    if (this.itemDetails) {
      if ((this.itemDetails.webCourseSyllabus && this.itemDetails.webCourseSyllabus.length)
        || (this.itemDetails.courseHostingPlatform && this.itemDetails.courseHostingPlatform)) {
        return true;
      }
    }

    return false;
  }

  // Verifica se l'utente può iscriversi al corso cm2 che wrappa un oggetto library
  canUserSubscribeToCourseThatWrapLibraryItem() {
    return this.itemDetails && this.itemLinkedToCourse && this.editions && this.editions[0] && (this.editions[0].canRegister || this.editions[0].canRegisterOverbooking) && (this.editions[0].isInvited || this.editions[0].isCancelled);
  }

  // Verifica se l'utente deve ancora iscriversi prima di aprire l'oggetto Library wrappato dal corso del cm2
  isUserSubscribedToWrapperLibraryItem() {
    let isCorrectlySubscribed = this.itemDetails && this.itemLinkedToCourse && this.editions && this.editions[0] && this.editions[0].isOnlineCourse && (this.editions[0].isConfirmed || this.editions[0].isPresent);
    return isCorrectlySubscribed;
  }

  // Gestisce l'aggiuna e la rimozione dai preferiti
  onToggleBookmark(course: any, forceCourseId: boolean) {
    if (course.isSwitchingBookmark) {
      return;
    }

    course.isSwitchingBookmark = true;
    let serviceToUse = null;

    if (course.isBookmarked || course.childObject?.isBookmarked) {
      serviceToUse = this.authService.removeCourseFromBookmarks((forceCourseId && course.courseId) || course.syllabusId || course.itemId || course.courseId || this.collectionId || this.webCourseId, course.originApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
    } else {
      serviceToUse = this.authService.addCourseToBookmarks((forceCourseId && course.courseId) || course.syllabusId || course.itemId || course.courseId || this.collectionId || this.webCourseId, course.originApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
      // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
      this.analyticsService.sendObjectBookmarkedEvent((forceCourseId && course.courseId) || course.syllabusId || course.itemId || this.collectionId || this.webCourseId);
    };

    serviceToUse
      .subscribe(data => {
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          course.isBookmarked = !course.isBookmarked;
          if (course.childObject) {
            course.childObject.isBookmarked = !course.childObject.isBookmarked;
          }
        }
        course.isSwitchingBookmark = false;
      }, (err) => {
        course.isSwitchingBookmark = false;
        this.toastr.error(this.translate.instant('errors.' + err));
      });
  }

  // Switch aggiungi/rimuovi dai preferiti
  toggleBookmark() {
    if (this.isToggleBookmark) {
      return;
    }

    this.isToggleBookmark = true;
    let serviceToUse = null;

    if (this.itemDetails.isBookmarked) {
      serviceToUse = this.authService.removeCourseFromBookmarks(this.itemDetails.syllabusId || this.itemDetails.itemId || this.collectionId || this.webCourseId, this.itemDetails.originApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
    } else {
      serviceToUse = this.authService.addCourseToBookmarks(this.itemDetails.syllabusId || this.itemDetails.itemId || this.collectionId || this.webCourseId, this.itemDetails.originApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
      // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
      this.analyticsService.sendObjectBookmarkedEvent(this.itemDetails.syllabusId || this.itemDetails.itemId || this.collectionId || this.webCourseId);
    };

    this.toggleBookmark$ = serviceToUse
      .subscribe(data => {
        if (data.error) {
          // Mostro il toaster di errore
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.itemDetails.isBookmarked = !this.itemDetails.isBookmarked;
        }
        // Spengo il loader
        this.isToggleBookmark = false;
      }, (err) => {
        this.isToggleBookmark = false;
        this.toastr.error(this.translate.instant('errors.' + err));
      });
  }

  getProgressPercentage() {
    let percentage = this.translate.instant('itemDetailsPage.PROGRESS') + ': ';

    if (this.webCourseId) {
      percentage = percentage + this.translate.instant('itemDetailsPage.NOT_COMPLETED');
    } else if (this.itemDetails) {
      percentage = percentage + (this.itemDetails.completionPercentage || 0) + '%';
    }
    return percentage;
  }

  // Segna come fatto il corso dal web
  setWebCourseAsComplete() {
    if (this.isSetWebCourseAsComplete) {
      return;
    }

    this.isSetWebCourseAsComplete = true;
    this.isGettingItemDetails = true;

    this.setWebCourseAsComplete$ = this.itemService.glpConfirmOfcoursemeCourseCompletion(this.webCourseId)
      .subscribe(data => {
        if (data.error) {
          // Mostro il toaster di errore
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else if (data.response) {
          this.toastr.success(this.translate.instant('generic.DATA_SAVED'));
          this.itemDetails.isCompleted = true;
        }
        // Spengo il loader
        this.isSetWebCourseAsComplete = false;
        this.isGettingItemDetails = false;

      }, (err) => {
        this.isSetWebCourseAsComplete = false;
        this.isGettingItemDetails = false;

        this.toastr.error(this.translate.instant('errors.' + err));
      });
  }

  // Recupera le note dell'item
  getItemNote() {
    return new Promise<void>((resolve, reject) => {
      this.isGettingNotes = true;
      this.getItemNote$ = this.platformService.findNotes(false, ItemTypes.ITEM, 0, 1, [this.itemDetails.itemId])
        .subscribe(data => {
          if (data.error) {
            // Mostro il toaster di errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
            reject();
          } else {
            this.itemNote = data && data.response && data.response[0];
            resolve();
          }
          // Spengo il loader
          this.isGettingNotes = false;
        }, (err) => {
          reject();
          this.isGettingNotes = false;
          this.toastr.error(this.translate.instant('errors.' + err));
        });
    });
  }

  // Verifica lo stato del rent
  checkRentableStatus() {
    return new Promise<void>((resolve, reject) => {
      this.isGettingRentableStatus = true;
      this.checkRentableStatus$ = this.platformService.hasReferenceOpenRents(this.itemDetails.itemId)
        .subscribe(data => {
          if (data.error) {
            // Mostro il toaster di errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
            reject();
          } else {
            // Se la risposta è true, significa che c'è almeno un prestito in corso e che quindi l'oggetto non è prestibile
            if (data.response == true) {
              // Segnalo che l'oggetto non è disponibile
              this.isRentableAvaiable = false;

              // Verifico se la richiesta di prestito l'ha eseguita l'utente loggato
              this.checkMyRentableStatus$ = this.platformService.hasReferenceOpenRents(this.itemDetails.itemId, true)
                .subscribe(data => {
                  if (data.error) {
                    // Mostro il toaster di errore
                    this.toastr.error(this.translate.instant('errors.' + data.error));
                    reject();
                  } else {
                    // Se la risposta è true, significa che c'è almeno un prestito in corso e che quindi l'oggetto non è prestibile
                    if (data.response == true) {
                      this.isMyRentablePendingRequest = true;
                    }
                    resolve();
                  }
                  // Spengo il loader
                  this.isGettingRentableStatus = false;
                }, (err) => {
                  reject();
                  this.isGettingRentableStatus = false;
                  this.toastr.error(this.translate.instant('errors.' + err));
                });
            } else {
              this.isRentableAvaiable = true;
              resolve();
            }
          }
          // Spengo il loader
          this.isGettingRentableStatus = false;
        }, (err) => {
          reject();
          this.isGettingRentableStatus = false;
          this.toastr.error(this.translate.instant('errors.' + err));
        });
    });
  }

  // Recupera lo storico dei prestiti
  getRentHistory(onlyForMe?: boolean, checkStillPendingRequestOfUser?: boolean) {
    return new Promise<void>((resolve, reject) => {
      this.isGettingRentHistory = true;
      this.getRentHistory$ = this.platformService.getRentsByAny(this.itemDetails.itemId, true, onlyForMe)
        .subscribe(data => {
          if (data.error) {
            // Mostro il toaster di errore
            this.toastr.error(this.translate.instant('errors.' + data.error));
            reject();
          } else {
            // Salvo i risultati della ricerca
            this.rentHistoryList = data.response;
            // Adesso che ho recuperato lo storico, devo recuperare anche le informazioni dell'utente che ha effettuato l'azione nei vari cambi di stato
            if (this.rentHistoryList && this.rentHistoryList.length) {
              // Ordino lo storico per data decrescente
              this.rentHistoryList.sort((a: any, b: any) => {
                if (a.creationDate > b.creationDate) {
                  return -1;
                } else if (a.creationDate < b.creationDate) {
                  return 1;
                } else {
                  return 0;
                }
              })
              for (let i = 0; i < this.rentHistoryList.length; i++) {
                if (this.rentHistoryList[i].rentStatuses.length) {
                  // Ordino gli stati per data decrescente
                  this.rentHistoryList[i].rentStatuses.sort((a: any, b: any) => {
                    if (a.creationDate > b.creationDate) {
                      return -1;
                    } else if (a.creationDate < b.creationDate) {
                      return 1;
                    } else {
                      return 0;
                    }
                  })

                  if (this.rentHistoryList[0].rentStatuses[0].statusType == RentStatusTypes.ASKED) {
                    this.rentHistoryList[0].rentStatuses[0].statusTypeLabel = this.translate.instant('itemDetailsPage.ASKED');
                    this.isRequestStillRejectable = true;
                    if (checkStillPendingRequestOfUser) {
                      this.isRentConfirmed = false;
                      this.theRequestIsStillPendingModal(this.rentHistoryList[i]);
                    }
                  } else if (this.rentHistoryList[0].rentStatuses[0].statusType == RentStatusTypes.CONFIRMED) {
                    this.rentHistoryList[0].rentStatuses[0].statusTypeLabel = this.translate.instant('itemDetailsPage.CONFIRMED');
                    this.isRequestStillRejectable = true;
                    if (checkStillPendingRequestOfUser) {
                      this.isRentConfirmed = true;
                      this.theRequestIsStillPendingModal(this.rentHistoryList[i]);
                    }
                  } else if (this.rentHistoryList[0].rentStatuses[0].statusType == RentStatusTypes.REFUSED) {
                    this.rentHistoryList[0].rentStatuses[0].statusTypeLabel = this.translate.instant('itemDetailsPage.REFUSED');
                  } else if (this.rentHistoryList[0].rentStatuses[0].statusType == RentStatusTypes.ABORTED) {
                    this.rentHistoryList[0].rentStatuses[0].statusTypeLabel = this.translate.instant('itemDetailsPage.ABORTED');
                  } else if (this.rentHistoryList[0].rentStatuses[0].statusType == RentStatusTypes.RETURNED) {
                    this.rentHistoryList[0].rentStatuses[0].statusTypeLabel = this.translate.instant('itemDetailsPage.RETURNED');
                  } else if (this.rentHistoryList[0].rentStatuses[0].statusType == RentStatusTypes.RETIRED) {
                    this.rentHistoryList[0].rentStatuses[0].statusTypeLabel = this.translate.instant('itemDetailsPage.RETIRED');
                    this.itemRented = true;
                  }
                }
              }
            }
            resolve();
          }
          // Spengo il loader
          this.isGettingRentHistory = false;
        }, (err) => {
          reject();
          this.isGettingRentHistory = false;
          this.toastr.error(this.translate.instant('errors.' + err));
        });
    });
  }

  // Esegue il toggle alla wishlist
  toggleWishlist() {
    if (this.isToggleWishlist) {
      return;
    }

    this.isToggleWishlist = true;

    const referenceId = this.itemDetails.syllabusId || this.itemId || this.itemToAddId || this.collectionId || this.webCourseId;

    if (!this.itemDetails.addedToWishlist) {
      // Se non è in wishlist significa che lo sto aggiungendo, pertanto invio un evento di tracciamento a Google Tag Manager per Google Analytics
      this.analyticsService.sendObjectWishlistedEvent(referenceId);
    }

    this.toggleWishlist$ = this.itemService.toggleCourseInWishlist(referenceId)
      .subscribe(data => {
        this.isToggleWishlist = false;
        // Se ci sono errori, li mostro e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          this.itemDetails.addedToWishlist = !this.itemDetails.addedToWishlist;

          if (this.itemDetails.addedToWishlist) {
            this.itemDetails.totalWishlist++;
          } else {
            this.itemDetails.totalWishlist--;
          }
        }
      }, (err) => {
        this.isToggleWishlist = false;
        this.toastr.error(this.translate.instant('errors.' + err));
      });
  }

  // Rimuove un like/dislike
  deleteLike(toRemove: string, toAdd?: string) {
    // Avvio il loader della sezione like/unlike
    this.isGettingLikes = true;
    this.deleteLike$ = this.itemService.deleteLike(this.currentLike.likeId)
      .subscribe(data => {
        // Se ci sono errori, li mostro e torno alla lista dei template
        if (data.error) {
          this.isGettingLikes = false;
          this.toastr.error(this.translate.instant('errors.' + data.error));
        } else {
          // Pulisco il like oramai cancellato
          this.currentLike = null;

          // Salvo quale like è stato tolto
          if (toRemove == "+") {
            this.iLikeThis = false;
          } else if (toRemove == "-") {
            this.iDislikeThis = false;
          }

          // e, se richiesto, aggiungo il like nuovo
          if (toAdd) {
            this.isGettingLikes = false;
            this.createLike(toAdd);
          } else {
            // Aggiorno le informazioni sul conteggio totale
            this.getLikes();
          }
        }
      })
  }

  // Click aggiunge/rimuove un like
  like() {
    if (this.isGettingLikes) {
      return;
    }
    if (this.iDislikeThis && !this.iLikeThis) {
      // Se c'è il pulsante di dislike, lo tolgo e aggiungo il like
      this.deleteLike("-", "+");
    } else if (!this.iDislikeThis && this.iLikeThis) {
      // Se c'è il pulsante di like attivo, significa che voglio rimuovere il like
      this.deleteLike("+");
    } else if (!this.iDislikeThis && !this.iLikeThis) {
      // Altrimenti, aggiungo semplicemente il like
      this.createLike("+");
    }

    return;
  }

  // Click aggiunge/rimuove un dislike
  dislike() {
    if (this.isGettingLikes) {
      return;
    }
    if (!this.iDislikeThis && this.iLikeThis) {
      // Se c'è il pulsante di like, lo tolgo e aggiungo il dislike
      this.deleteLike("+", "-");
    } else if (this.iDislikeThis && !this.iLikeThis) {
      // Se c'è il pulsante di dislike attivo, significa che voglio rimuovere il dislike
      this.deleteLike("-");
    } else if (!this.iDislikeThis && !this.iLikeThis) {
      // Altrimenti, aggiungo semplicemente il dislike
      this.createLike("-");
    }

    return;
  }

  // Ritorna lo stato dell'utente relativamente alla survey di un'edizione associata
  getAssessmentOfEditionCompletitionStatus(itemRef, index) {
    let statusLabel;
    if (itemRef) {
      if (index && itemRef.assessmentIds && itemRef.assessmentIds.length) {
        index = index.split('survey.')[1];
        let assessmentTmp;
        for (let i = 0; i < itemRef.assessmentIds.length; i++) {
          let tmp = itemRef.assessmentIds[i].itemAttributes.filter((attr) => attr.attributeType == ItemAttributeTypes.SURVEY_TYPE && attr.attributeValue == index);
          if (tmp && tmp[0]) {
            assessmentTmp = itemRef.assessmentIds[i];
            break;
          }
        }
        if (assessmentTmp && assessmentTmp.itemAttributes && assessmentTmp.itemAttributes.length) {
          let status = assessmentTmp.itemAttributes.filter((attr) => attr.attributeType === ItemAttributeTypes.STATUS);
          if (status && status[0]) {
            status = status[0];
            statusLabel = this.translate.instant('generic.surveyStatuses.' + status.attributeValue);
            return statusLabel;
          }
        }
      }
      if (!itemRef.isSurveyPassed) {
        if (itemRef.isSurveyNotPassed && itemRef.isSurveyNotRepeteable) {
          // Questionario non passato
          statusLabel = this.translate.instant('generic.surveyStatuses.NOT_PASSED');
          this.isSurveyStarted = true;
        } else if (!itemRef.isSurveyStarted) {
          // Questionario da iniziare
          statusLabel = this.translate.instant('generic.surveyStatuses.TO_START');
          this.isSurveyCertificable = true;
        } else {
          // Questionario da continuare
          statusLabel = this.translate.instant('generic.surveyStatuses.STARTED');
          this.isSurveyStarted = true;
        }
      } else {
        // Questionario concluso e completato con successo
        statusLabel = this.translate.instant('generic.surveyStatuses.COMPLETED');
        // Se è completato e ho la data, aggiungo la data di completamento
        if (itemRef.surveyPassedDate) {
          statusLabel = statusLabel + ' (' + moment(itemRef.surveyPassedDate).format('LL') + ')';
        }
      }
    }
    return statusLabel;
  }


  // Recupera l'oggetto Library collegato al corso online
  getLibraryItemLinkedToCourse() {
    this.getItemDetails$ = this.itemService.getItemForGlp(this.itemIdLinkedToCourse, true, null, {
      // Devo ignorare il tenant dell'oggetto collegato
      ignoreTenant: true
    })
      .subscribe((linkedItem) => {
        if (linkedItem.error) {
          // Vedo se c'è la traduzione dell'errore
          this.toastr.error(this.translate.instant('errors.' + linkedItem.error));
          this.goBack();
        } else if (linkedItem.response) {
          if (linkedItem.response) {
            linkedItem.response.isLearningPlan = ItemUtil.isLearningPlan(linkedItem.response);
            linkedItem.response.isProject = ItemUtil.isProject(linkedItem.response);
            linkedItem.response.isImage = ItemUtil.isImage(linkedItem.response);
            linkedItem.response.isVideo = ItemUtil.isVideo(linkedItem.response);
            linkedItem.response.isPodcast = ItemUtil.isPodcast(linkedItem.response);
            linkedItem.response.isBook = ItemUtil.isBook(linkedItem.response);
            linkedItem.response.isEBook = ItemUtil.isEBook(linkedItem.response);
            linkedItem.response.isGraph = ItemUtil.isGraph(linkedItem.response);
            linkedItem.response.isMagazine = ItemUtil.isMagazine(linkedItem.response);
            linkedItem.response.isDvd = ItemUtil.isDvd(linkedItem.response);
            linkedItem.response.isDocument = ItemUtil.isDocument(linkedItem.response);
            linkedItem.response.isItemOtherOrPhysicalType = ItemUtil.isItemOtherOrPhysicalType(linkedItem.response);
            this.itemLinkedToCourse = linkedItem.response;

            if (this.itemLinkedToCourse?.embeddedPlayerCode) {
              this.isKalturaPlayerVisible = true;
            }

            let getItemChildsInfoPromise = this.getItemChildsInfo(this.itemLinkedToCourse);
            getItemChildsInfoPromise.then(() => {
              let getCertificationDataPromise = this.getCertificationData(this.itemLinkedToCourse);
              getCertificationDataPromise.then(() => {

                if (this.itemLinkedToCourse.isLearningPlan && this.itemLinkedToCourse.itemChilds && this.itemLinkedToCourse.itemChilds) {

                  this.itemLinkedToCourse.itemChilds.sort((a: ItemChild, b: ItemChild) => {
                    return b.childOrder - a.childOrder;
                  });

                  // Recupero i propedeuticReferenceIds degli itemChilds
                  let propedeuticReferenceIds = ItemUtil.getPropedeuticReferenceIds(this.itemLinkedToCourse.itemChilds, true);

                  for (let childsCounter = 0, itemChildsLength = this.itemLinkedToCourse.itemChilds.length; childsCounter < itemChildsLength; childsCounter++) {
                    let currentItemChild: any = this.itemLinkedToCourse.itemChilds[childsCounter];
                    this.formatCourseCard(currentItemChild, propedeuticReferenceIds, this.itemLinkedToCourse.itemChilds);
                  }

                  // numero totale di oggetti della playlist / LP
                  this.bannerTotalItem = 0;
                  this.completedItems = 0;
                  this.firstIncompleteId = null;
                  this.firstIncompleteSectionId = null;
                  // se è una playlist
                  let childOrder = -1;
                  this.bannerTotalItem = this.itemLinkedToCourse.itemChilds.length;
                  // non ho le sezioni come il learning plan, quindi gli oggetti sono al primo livello
                  for (let i = 0; i < this.itemLinkedToCourse.itemChilds.length; i++) {
                    // se ha l'oggetto associato
                    if (this.itemLinkedToCourse.itemChilds[i].childObject) {
                      let tmpItem = this.itemLinkedToCourse.itemChilds[i].childObject;
                      let isItemComplete = tmpItem.engagements.filter((x) => x.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED);
                      if (!isItemComplete || !isItemComplete.length) {
                        if (!this.firstIncompleteId || (this.firstIncompleteId && this.itemLinkedToCourse.itemChilds[i].childOrder > childOrder)) {
                          this.firstIncompleteId = tmpItem.itemId;
                          childOrder = this.itemLinkedToCourse.itemChilds[i].childOrder;
                        }
                      } else if (isItemComplete && isItemComplete.length) {
                        this.completedItems++;
                      }
                    }
                  }

                  if (this.bannerTotalItem) {
                    this.totalLpProgress = Math.floor((this.completedItems / this.bannerTotalItem) * 100);
                  }
                  // showBanner = true?
                } else if (this.itemLinkedToCourse.isProject && this.childObjects && this.childObjects.length) {

                } else if (this.itemLinkedToCourse && this.itemLinkedToCourse.itemChilds && this.itemLinkedToCourse.itemChilds.length) {
                  if (this.itemLinkedToCourse.itemChilds && this.itemLinkedToCourse.itemChilds.length) {
                    let sections = this.itemLinkedToCourse.itemChilds;
                    // Per ogni sezione mi salvo il numero di oggetti, salvo riferimento al primo non completo e controllo quanti oggetti sono completati
                    let sectionChildOrder = 99999;
                    let itemChildOrder = -1;

                    for (let i = 0; i < sections.length; i++) {
                      if (sections[i].childObject && sections[i].childObject.itemChilds && sections[i].childObject.itemChilds.length) {
                        let section = sections[i].childObject;

                        this.bannerTotalItem += sections[i].childObject.itemChilds.length;
                        for (let j = 0; j < section.itemChilds.length; j++) {
                          let tmpItem = section.itemChilds[j] && section.itemChilds[j].childObject;

                          let tmpItemContainer: any = section.itemChilds[j];
                          if (tmpItem) {
                            let tmpCompleted = tmpItem.engagements?.filter((x: any) => x.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED);
                            let isItemComplete;
                            if (tmpCompleted && tmpCompleted.length) {
                              isItemComplete = true;
                            }
                            // prendo il primo incompleto
                            if (!isItemComplete &&
                              (!this.firstIncompleteId ||
                                (this.firstIncompleteId &&
                                  (sectionChildOrder > sections[i].childOrder ||
                                    (sectionChildOrder >= sections[i].childOrder && itemChildOrder <= tmpItemContainer.childOrder))
                                ))) {
                              sectionChildOrder = sections[i].childOrder;
                              itemChildOrder = tmpItemContainer.childOrder;
                              this.firstIncompleteId = tmpItem.itemId;
                            }
                            if (isItemComplete) {
                              this.completedItems++;
                            }
                          }
                        }
                      }
                    }
                  }
                  if (this.bannerTotalItem) {
                    this.totalLpProgress = Math.floor((this.completedItems / this.bannerTotalItem) * 100);
                  }
                }
              })
                .catch((e) => {
                  console.log(e);
                })
            })
              .catch((e) => {
                console.log(e);
              })
          }

        }
      }, (err) => {
        this.goBack();
        this.toastr.error(this.translate.instant('errors.' + err.message));
        this.isGettingItemDetails = false;

      });
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp() {
    return new Promise((resolve, reject) => {
      this.authService.crateRetrieveTokenAfterLogin().subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          reject();
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err) => {
          reject();
        })
    }
    )
  }

  // Recupera l'immagine del certificato
  getCertificationImage() {
    if (this.hasTheUserTheCertificateAssigned || (this.itemDetails && this.itemDetails.isCourseCertificationEnabled)) {
      this.showItemCertificationLoader = true;
      let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
      this.getCertificationFile$ = this.itemService.getCertificate(itemRef.itemId, true, false, true)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.response) {
            let getTokenFromKeyPromise = this.getTokenFromSsortkqp();
            getTokenFromKeyPromise.then((ssorqtp: any) => {
              let downloadUrl = ItemUtil.getDownloadTempFileUrl(data.response, this.globalApplicationData, null, ssorqtp);
              // Salvo l'url dell'immagine
              this.certificationImageUrl = downloadUrl;
              let linkedinUrl = this.globalApplicationData.applicationContext + `rest-api/coursemanager-mediator/certificate/share/optimized-html/${this.loggedUser.user.userId}/${this.itemDetails.itemId}/${itemRef.itemId}`;
              // Salvo l'url dell'immagine
              this.linkedinUrl = linkedinUrl;
              this.showItemCertificationLoader = false;
            })
          } else {
            this.showItemCertificationLoader = false;
          }

        }
          , catchError((err, caught) => {
            this.showItemCertificationLoader = false;
            // Torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
            return err;
          })
        )
    }
  }

  // Scarica il certificato di un oggetto atomico aggiunto ad un contenitore
  downloadCertificateItem(itemIdForCertificate) {
    if (this.showItemCertificationLoader) {
      return;
    }
    this.showItemCertificationLoader = true;
    // let itemIdForCertificate = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? (this.editions && this.editions.length && this.editions[0] && this.editions[0].courseDateId) : (this.itemDetails && this.itemDetails.itemId);
    let containerId = this.isOnlineCourse && this.itemLinkedToCourse ? (this.itemLinkedToCourse.itemId) : (this.lpId || this.projectId || this.itemId);

    this.getCertificationFile$ = this.itemService.downloadCertificateItem(itemIdForCertificate, true, this.adminMode, containerId)
      .subscribe(data => {
        // Se ci sono errori, li mostor e torno alla lista dei template
        if (data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
          this.showItemCertificationLoader = false;
        } else {
          let filename = data.response;

          let getTokenFromKeyPromise = this.getTokenFromSsortkqp();
          getTokenFromKeyPromise.then((ssorqtp: any) => {
            let downloadUrl = ItemUtil.getDownloadTempFileUrl(filename, this.globalApplicationData, null, ssorqtp);

            setTimeout(() => {
              // Scarico il file
              window.location.assign(downloadUrl);
              document.location.assign(downloadUrl);
              this.showItemCertificationLoader = false;
            }, 500);

            // Faccio un refresh dei dati
            this.resetData();
            this.getItemDetails();
          })
        }
      },
        (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.showItemCertificationLoader = false;
        });
  }

  // Scarica il certificato
  downloadCertification() {
    if (this.hasTheUserTheCertificateAssigned) {
      this.showItemCertificationLoader = true;
      let itemIdForCertificate = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? (this.editions && this.editions.length && this.editions[0] && this.editions[0].courseDateId) : (this.itemDetails && this.itemDetails.itemId);
      this.getCertificationFile$ = this.itemService.getCertificate(itemIdForCertificate)
        .subscribe(data => {
          // Se ci sono errori, li mostor e torno alla lista dei template
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
            this.showItemCertificationLoader = false;
          } else {
            let downloadUrl = data.response;
            setTimeout(() => {
              // Scarico il file
              window.location.assign(downloadUrl);
              document.location.assign(downloadUrl);
              this.showItemCertificationLoader = false;
            }, 500);
          }
        })
    }
  }

  goToCourseSurvey(editionSurvey?: any, withRegisterLogic?: boolean) {
    this.isGettingItemDetails = true;
    let baseUrl = this.getBaseUrlForRedirect();
    if (withRegisterLogic) {
      let edition = this.editionsSurvey.find(edition => edition.courseId === editionSurvey.courseId);
      // passo courseId o itemId come fallback per le survey stand alone
      this.router.navigate([baseUrl + 'survey', edition.courseId ?? edition.itemId, edition.courseId ?? edition.itemId], { relativeTo: this.route, queryParams: { customItemIdRedirect: this.itemDetails.itemId } });
      this.isGettingItemDetails = false;
      // let edition = withRegisterLogic && this.editions.find(edition => edition.stageItemId === editionSurvey.itemId);
    } else if ((this.isSyllabusCourse && this.itemLinkedToCourse) || !this.isSyllabusCourse) {
      const isSyllabusCourseWithWithItemLinkedToCourse = this.isSyllabusCourse && this.itemLinkedToCourse;
      if (isSyllabusCourseWithWithItemLinkedToCourse || !this.isSyllabusCourse) {
        let currentItemId = (this.itemLinkedToCourse && this.itemLinkedToCourse.itemId) || this.itemDetails.itemId;
        // Se si tratta di un corso cm2 che ingloba un corso library, e il corso cm2 ha una rilevazione, devo andare su quest'ultima.
        // In caso contrario, cioè se il corso del cm2 che ingloba un corso library non ha una rilevazione, significa che devo andare all'itemSurvey, cioè al questionario associato al corso Library
        if (isSyllabusCourseWithWithItemLinkedToCourse && editionSurvey && editionSurvey.courseId && editionSurvey.stageItemType === ItemTypes.ASSESSMENT) {
          this.router.navigate([baseUrl + 'survey', editionSurvey.courseId, editionSurvey.courseId], { relativeTo: this.route, queryParams: { customItemIdRedirect: this.itemDetails.itemId } });
        } else {
          if (this.lpId) {
            this.router.navigate([baseUrl + 'itemSurvey', this.lpId, currentItemId], { relativeTo: this.route });
          } else {
            this.router.navigate([baseUrl + 'itemSurvey', this.itemLinkedToCourse ? this.itemLinkedToCourse.itemId : this.itemDetails.itemId], { relativeTo: this.route });
          }
        }
        this.isGettingItemDetails = false;
      } else if (this.isAssessment) {
        this.router.navigate([baseUrl + 'survey', editionSurvey.itemId, editionSurvey.itemId], { relativeTo: this.route });
        this.isGettingItemDetails = false;
      } else if (!this.isAssessment && this.isSyllabusCourse && editionSurvey.courseId) {
        this.router.navigate([baseUrl + 'survey', editionSurvey.courseId, editionSurvey.courseId], { relativeTo: this.route, queryParams: { customItemIdRedirect: this.itemDetails.itemId } });
        this.isGettingItemDetails = false;
      }
    } else {
      this.toastr.error(this.translate.instant('errors.CANNOT_GO_TO_SURVEY'));
      this.isGettingItemDetails = false;
    }
  }

  // Apre un corso web contenuto nella collezione web
  openWebCourseOfCollection(item) {
    this.router.navigate(['../../webCourseOfCollectionDetails', this.collectionId, item.courseId], { relativeTo: this.route });
  }

  getBaseUrlForRedirect() {
    return getBaseUrlForRedirectFromItem(this.router.url);
  }

  // Porta alla pagina di dettaglio di un oggetto suggerito
  openSuggestedItem(navigationData: { itemId: string, webCourseType?: string }): void {
    if (navigationData && navigationData.itemId) {
      let baseUrl = this.getBaseUrlForRedirect();

      baseUrl = baseUrl + 'itemDetails';
      this.router.navigate([baseUrl, navigationData.itemId]);
    }
  }

  // Apre una modale che indica quale elemneto è propedeutico per aprire quello selezionato
  showPropedeuticalItemToThisChild(objs) {
    this.propedeuticalObject = null;
    this.propedeuticalObject = ItemUtil.getPropedeuticalItemToThisChild(objs.selectedItem, objs.item);

    // Apro la modale
    this.modalService.open('showPropedeuticalToThis');
  }

  showPropedeuticalItemToThis(selectedItem, sectionObjOfSelectedItem?) {
    let itemRef;
    let refItem = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    if (refItem.isProject && sectionObjOfSelectedItem) {
      // Sto aprendo l'oggetto propedeutico di un oggetto di una sezione di un progetto, quindi l'item di riferimento dell'oggetto è la sezione
      itemRef = sectionObjOfSelectedItem;
    } else {
      itemRef = refItem;
    }
    this.propedeuticalObject = null;
    this.propedeuticalObject = ItemUtil.getPropedeuticalItemToThis(selectedItem, itemRef.itemChilds);

    // Apro la modale
    this.modalService.open('showPropedeuticalToThis');
  }

  // Chiude la modale di propedeuticità
  closePropedeuticalModal() {
    this.propedeuticalObject = null;
    this.modalService.close('showPropedeuticalToThis');
  }

  // Apre la modale che indica quale elemneto è propedeutico per aprire quello selezionato
  openShowPropedeuticalItemToThisModal(selectedItem: ItemChild) {
    let itemRef = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? this.itemLinkedToCourse : this.itemDetails;
    this.showPropedeuticalItemToThisModal.selectedItem = selectedItem;
    // Cerco il primo oggetto propedeutico
    this.showPropedeuticalItemToThisModal.propedeuticalObject = ItemUtil.getFirstPropedeuticalItem(itemRef, selectedItem);
    // Se il mio item propedeutico possiede un altro item propedeutico, lo cerco
    if (this.showPropedeuticalItemToThisModal.propedeuticalObject && this.showPropedeuticalItemToThisModal.propedeuticalObject.propedeuticReferenceId) {
      ItemUtil.searchPropedeuticalObject(itemRef, this.showPropedeuticalItemToThisModal.propedeuticalObject);
    }

    // Apro la modale
    this.modalService.open('showPropedeuticalItemToThisModal');
  }

  // Chiude la modale che indica quale elemneto è propedeutico per aprire quello selezionato
  closeShowPropedeuticalItemToThisModal() {
    // Apro la modale
    this.modalService.close('showPropedeuticalItemToThisModal');
  }

  // Porta alla pagina di dettaglio di un oggetto contenuto nella Plyalist
  openPlaylistItemDetails(navigationData: { itemId: string, webCourseType?: string, isItemLocked?: boolean, singleItemChild?}): void {
    if (navigationData && navigationData.itemId) {
      if (navigationData.isItemLocked) {
        // Devo aprire una modale contenente l'oggetto propedeutico per quello cliccato
        if (navigationData.singleItemChild && navigationData.singleItemChild.childObject) {
          this.showPropedeuticalItemToThis(navigationData.singleItemChild.childObject);
        }
      } else if (navigationData && navigationData.singleItemChild && navigationData.singleItemChild.type && navigationData.singleItemChild.type === ItemTypes.CERTIFICATE_ITEM) {
        // Devo scaricare il certificato
        this.downloadCertificateItem(navigationData.singleItemChild.referenceId)
      } else {
        let baseUrl = this.getBaseUrlForRedirect();

        baseUrl = baseUrl + 'itemDetails';

        const isWrappedOnlineCourse = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? true : false;

        if (isWrappedOnlineCourse) {
          this.router.navigate([baseUrl, this.itemLinkedToCourse.itemId, navigationData.itemId, this.itemId]);
        } else {
          this.router.navigate([baseUrl, this.itemId, navigationData.itemId]);
        }
      }
    }
  }

  // Gestisce il click sulla sezione bloccata
  onSectionBlockedClicked(navigationData: { itemId: string, webCourseType?: string, isItemLocked?: boolean, singleItemChild?, sectionObj?}) {
    if (navigationData && navigationData.itemId && navigationData.sectionObj && navigationData.sectionObj.isItemLocked) {
      // Devo aprire una modale contenente l'oggetto propedeutico per quello cliccato
      this.showPropedeuticalItemToThis(navigationData.sectionObj.childObject);
    }
  }

  // Porta alla pagina di dettaglio di un oggetto contenuto nella sezione di un progetto
  openSectionItemDetails(navigationData: { itemId: string, webCourseType?: string, isItemLocked?: boolean, singleItemChild?, sectionObj?}): void {
    if (navigationData && navigationData.itemId) {
      if (navigationData.sectionObj.isItemLocked) {
        // Sezione bloccata, quindi mostro la sezione propedeutica
        this.onSectionBlockedClicked({ itemId: navigationData.sectionObj.itemId, sectionObj: navigationData.sectionObj });
        return;
      } else if (navigationData.isItemLocked) {
        // Oggetto contenuto nella sezione bloccato: devo aprire una modale contenente l'oggetto propedeutico per quello cliccato
        if (navigationData.singleItemChild && navigationData.singleItemChild.childObject) {
          this.showPropedeuticalItemToThis(navigationData.singleItemChild.childObject, navigationData.sectionObj.childObject);
        }
      } else if (navigationData && navigationData.singleItemChild && navigationData.singleItemChild.type && navigationData.singleItemChild.type === ItemTypes.CERTIFICATE_ITEM) {
        // Devo scaricare il certificato
        this.downloadCertificateItem(navigationData.singleItemChild.referenceId)
      } else {
        let baseUrl = this.getBaseUrlForRedirect();

        baseUrl = baseUrl + 'itemDetailSec';

        const isWrappedOnlineCourse = this.isOnlineCourse && this.itemLinkedToCourse && this.itemLinkedToCourse.itemId ? true : false;

        if (isWrappedOnlineCourse) {
          this.router.navigate([baseUrl, this.itemLinkedToCourse.itemId, navigationData.singleItemChild.itemId, navigationData.itemId, this.itemDetails.itemId]);
        } else {
          this.router.navigate([baseUrl, this.itemDetails.itemId, navigationData.singleItemChild.itemId, navigationData.itemId]);
        }

      }
    }
  }

  isSectionMandatoryOrPropedeutical(section) {
    return section && (section.showPropedeuticLabel || section.showRequiredLabel)
  }

  getItemChildsInfo(refItem: GlpItem) {
    return new Promise<void>((resolve, reject) => {
      if (refItem && refItem.itemChilds) {
        if (refItem.isProject) {
          if (refItem.itemChilds && refItem.itemChilds.length) {
            // Detto di un progetto: formatto gli oggetto aggiunti alla sezione
            let propedeuticReferenceIdsOfSection = ItemUtil.getPropedeuticReferenceIds(refItem.itemChilds, true);

            for (let d = 0; d < refItem.itemChilds.length; d++) {
              let currentSection = refItem.itemChilds[d];
              if (currentSection.childObject) {
                currentSection.childObject.description = currentSection.childObject.description && currentSection.childObject.description.replace(/(\r\n|\n|\r)/gm, "<br />");

                // Gestisco la propedeuticità/obbligatorietà nella sezione
                this.managePropedeuticAndMandatoryOnItemChild(currentSection, propedeuticReferenceIdsOfSection, refItem.itemChilds);

                // Formatto gli oggetti aggiunti alla sezione
                if (currentSection && currentSection.childObject && currentSection.childObject.itemChilds && currentSection.childObject.itemChilds.length) {
                  // Recupero i propedeuticReferenceIds degli itemChilds
                  let propedeuticReferenceIds = ItemUtil.getPropedeuticReferenceIds(currentSection.childObject.itemChilds, true);

                  currentSection.childObject.itemChilds.sort((a: ItemChild, b: ItemChild) => {
                    return b.childOrder - a.childOrder;
                  });

                  for (let childsCounter = 0, itemChildsLength = currentSection.childObject.itemChilds.length; childsCounter < itemChildsLength; childsCounter++) {
                    let currentItemChild: any = currentSection.childObject.itemChilds[childsCounter];

                    let progressData = ItemUtil.getPercentageAndCertificationData(currentItemChild);
                    currentItemChild.isCompleted = progressData && progressData.isConsumed;
                    currentItemChild.isVerified = progressData && progressData.isItemAlreadyCertified;

                    this.formatCourseCard(currentItemChild, propedeuticReferenceIds, currentSection.childObject.itemChilds);
                  }
                }
              }
            }
          }

          resolve();
        } else if (refItem.isLearningPlan) {
          // Contiene la lista di figli del learning plan (chiamato playlist) obbligatori ma che non hanno il certified poiché, in tal caso, controllo lo stato delle loro potenziali survey
          let mandatoryChildsNotCertified: ItemChild[] = [];
          let mandatoryItems = 0;
          let consumedMandatoryItems = 0;

          for (let k = 0; k < refItem.itemChilds.length; k++) {
            let currentObj: any = refItem.itemChilds[k];
            let isMandatory = !!currentObj.mandatory;

            if (currentObj && currentObj.childObject) {
              currentObj.itemTypeLabel = ItemUtil.getItemTypeLabel(currentObj.childObject, this.translate);
              let isVerified = currentObj.childObject.isVerified;
              // Se non è certificato ed è obbligatorio, lo aggiungo alla lista degli figli di cui controllerò lo stato delle eventuali survey
              if (isMandatory && !isVerified) {
                mandatoryChildsNotCertified.push(currentObj);
              }
            }
            if (currentObj.childObject && isMandatory) {
              mandatoryItems++;
              if (currentObj.childObject.isConsumed) {
                consumedMandatoryItems++;
              }
            }
            let progressData = ItemUtil.getPercentageAndCertificationData(currentObj);

            currentObj.isCompleted = progressData && progressData.isConsumed;
            currentObj.isVerified = progressData && progressData.isItemAlreadyCertified;
          }
          if (mandatoryItems == consumedMandatoryItems) {
            this.isItemCertifiable = true;
          }

          if (mandatoryChildsNotCertified && mandatoryChildsNotCertified.length) {
            let childSurveysPromise = ItemUtil.getSurveyStatusOfItemChildsData(this.loggedUser.user.userId, mandatoryChildsNotCertified, this.itemService, this.toastr, this.translate, this.childsNotCertifiedButCertifiables);
            childSurveysPromise.then((data) => {
              this.totalChildsWithSurvey = this.childsNotCertifiedButCertifiables + this.itemsInContainerCertified;
              resolve();
            })
              .catch(() => {
                resolve();
              })
          } else {
            resolve();
            this.totalChildsWithSurvey = this.itemsInContainerCertified;
          }
        } else {
          for (let k = 0; k < refItem.itemChilds.length; k++) {
            if (refItem.isLearningPlan) {
              // Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente per ricavare la percentuale
              ItemUtil.getPercentageAndCertificationDataOfItemChildOfSection(refItem.itemChilds[k])
            }
            if (refItem.itemChilds[k].childObject && (refItem.itemChilds[k].childObject.itemType === ItemTypes.ATTACHMENT ||
              refItem.itemChilds[k].childObject.itemType === ItemTypes.DAM_ATTACHMENT)) {
              if (!this.attachmentList) {
                this.attachmentList = [];
              }
              if (!this.isDownloadingFile) {
                this.attachmentList.push(refItem.itemChilds[k]);
              }
              this.isDownloadingFile = false;
            }
          }
          this.isGettingChilds = false;
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  // Recupera il titolo di un oggetto
  private getItemTitle(itemId) {
    return new Promise((resolve, reject) => {
      // Per i corsi dal web non c'è un servizio apposito solo per il titolo, ma devo recuperare l'interno oggetto
      if (this.parentCollectionId) {
        this.platformService.glpGetOfCoursemeCollectionById(itemId, true, true)
          .subscribe(titleData => {
            // Se ci sono errori, li mostor e torno alla lista dei template
            if (titleData.error) {
              this.toastr.error(this.translate.instant('errors.' + titleData.error));
              reject();
            } else {
              resolve(titleData.response && titleData.response.collectionTitle || '');
            }
          });
      } else {
        this.itemService.getItemTitle(itemId)
          .subscribe(titleData => {
            // Se ci sono errori, li mostor e torno alla lista dei template
            if (titleData.error) {
              this.toastr.error(this.translate.instant('errors.' + titleData.error));
              reject();
            } else {
              resolve(titleData.response);
            }
          });
      }
    });
  }

  // Verifica se mostrare la tab degli allegati
  showAttachmentsTabContent() {
    if (this.attachmentList && this.attachmentList.length && ((this.isSyllabusCourse && this.itemLinkedToCourse && !this.itemLinkedToCourse.isItemOtherOrPhysicalType)
      || (this.itemDetails && !this.itemDetails.isItemOtherOrPhysicalType))) {
      return true;
    }

    return false;
  }

  // Recupera i child dell'item
  getItemChildsByIds(itemChilds: ItemChild[]) {
    let promises: Array<any> = [];
    this.childObjects = [];
    if (!this.childObjectTmp) {
      this.childObjectTmp = [];
    }
    if (!this.childObjectTmp.length && itemChilds && itemChilds.length) {
      for (let j = 0, childsLength = itemChilds.length; j < childsLength; j++) {
        let currentChild = itemChilds[j];
        promises.push(new Promise((resolve: Function, reject: Function) => {
          this.itemService.getItemForGlp(currentChild.referenceId)
            .subscribe((childData) => {
              // Aggiungo l'elemento nell'array temporaneo
              this.childObjectTmp[j] = childData && childData.response;
              resolve();
            }, (err) => {
              reject();
              this.toastr.error(this.translate.instant('errors.' + err));
            });
        }))
      }
    }
    // Risolvo le promesse coi dati recuperati
    return Promise.all(promises);
  }

  // Verifica se ci sono survey da iniziare
  isThereSurveyToStart(itemRef) {
    //bisogna specificare il caso degli oggetti questionario poichè a questionario completato non vi è isCertified = true ma bensì il isConsumed = true quindi fino a completamento isConsumed = false
    if (itemRef.itemType === 'SURVEY_ITEM') {
      if (itemRef && !itemRef.isConsumed && this.isSurveyCertificable && !itemRef.isCertified && (this.isItemCertifiable || this.enableCertification)) {
        return true;
      }
      return false;
    } else {
      if (itemRef && itemRef.isConsumed && (this.isSurveyCertificable || this.isSurveyCertificableLibrary) && !itemRef.isCertified && (this.isItemCertifiable || this.enableCertification)) {
        return true;
      }
      return false;
    }
  }

  // Verifica se ci sono survey da continuare
  isThereSurveyToContinue(itemRef) {
    //bisogna specificare il caso degli oggetti questionario poichè a questionario completato non vi è isCertified = true ma bensì il isConsumed = true quindi fino a completamento isConsumed = false
    if (itemRef.itemType === 'SURVEY_ITEM') {
      if ((itemRef && !itemRef.isConsumed && (this.isSurveyStarted) && !itemRef.isCertified && this.isItemCertifiable)) {
        return true;
      }
      return false;
    } else {
      if ((itemRef && itemRef.isConsumed && (this.isSurveyStarted || this.isSurveyStartedLibrary) && !itemRef.isCertified && this.isItemCertifiable)) {
        return true;
      }
      return false;
    }
  }

  // Verifica se il certificato è stato ottenuto
  isCertificationObtained() {
    if (!this.adminMode &&
      (this.itemLinkedToCourse && this.hasTheUserTheCertificateAssigned &&
        (this.itemLinkedToCourse.isCertified || (this.itemLinkedToCourse.isConsumed && !this.isThereSurveyToStart(this.itemLinkedToCourse) && !this.isThereSurveyToContinue(this.itemLinkedToCourse))))) {
      return true;
    }
    return false;
  }

  getCertificationData(refItem: GlpItem) {
    return new Promise<void>((resolve, reject) => {
      if (refItem) {
        // Scorro gli oggetti aggiunti alla sezione, e se hanno bisogno di un oggetto propedeutico associato verifico se quest'ultimo è stato consumato; in tal caso, l'oggetto risulterà sbloccato
        ItemUtil.checkIfChildsAreNotDisabled(refItem.itemChilds);

        if (refItem.isLearningPlan || refItem.isProject) {
          // Per il momento non c'è la certificazione come nel corporate per gli oggetti atomici della library, quindi posso saltare questo passaggio
          resolve();
          //se non è ne una playlist ne un learningPlan vuol dire che è un corso quindi per non ricevere una response=null devo chiamare lo stesso servizio adattato per non controllare gli itemChilds poichè non ne ha
        } else if (!this.adminMode) {
          // Se è un oggetto atomico, abilito la possibilità di verificare l'item
          this.isItemCertifiable = true;
          // Se è un'immagine ed è la prima volta che entro nell'item, i servizi avranno già creato l'engagement quindi vedo se posso abilitare la certificazione
          if (refItem.isImage) {
            this.checkEnableCertificaion();
          };
          this.isGettingSurveys = true;
          let refId = refItem.itemId ? refItem.itemId : this.itemToAddId;
          let surveyPromise = ItemUtil.getSurveyStatusData(this.loggedUser.user.userId, refId, this.itemService, this.toastr, null);
          surveyPromise.then((data: any) => {
            if (data) {
              this.isSurveyStarted = data.isSurveyStarted;
              this.isSurveyCertificable = data.isSurveyCertificable;
            }
            resolve();
            this.isGettingSurveys = false;
          })
            .catch(() => {
              resolve();
              this.isGettingSurveys = false;
            })
        }
        // Recupero il certificato
        //  this.getCertificationImage();
      } else {
        resolve();
      }
    });
  }

  // Verifica se abilitare la certificazione
  checkEnableCertificaion(refreshPage?: boolean) {
    if (!this.adminMode) {
      this.isGettingItemDetails = false;

      let isForLinkedItem = this.itemLinkedToCourse && this.isOnlineCourse ? true : false;
      // Metto un piccolo ritardo per consentire all'engagement di crearsi
      setTimeout(() => {
        this.toastr.success(this.translate.instant('generic.DATA_SAVED'));

        if (refreshPage) {
          sessionStorage.removeItem("token");
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
          });
        }

        let id = isForLinkedItem ? (this.itemLinkedToCourse.itemId) : (this.itemId || this.itemToAddId);
        this.itemService.getItemForGlp(id)
          .subscribe((data) => {
            if (data && data.response) {
              // Se sono in un item che fa parte di un learning plan o una playlist, recupero i dati del parent per il riepilogo
              this.getParentInfo();

              if (isForLinkedItem) {
                this.itemLinkedToCourse.isConsumed = data.response.isConsumed;
                this.itemLinkedToCourse.isCertified = data.response.isCertified;
                this.itemLinkedToCourse.completionPercentage = data.response.completionPercentage;
              } else {
                this.itemDetails.isConsumed = data.response.isConsumed;
                this.itemDetails.isCertified = data.response.isCertified;
                this.certificationDate = data.response.certificationDate;
                this.itemDetails.completionPercentage = data.response.completionPercentage;
              }
            }

            // Se non ho una certificazione eseguita la cerco
            if (!this.itemDetails.isCertified) {
              this.isGettingSurveys = true;
              let refId = isForLinkedItem ? (this.itemLinkedToCourse.itemId) : (this.itemId ? this.itemId : this.itemToAddId);
              let surveyPromise = ItemUtil.getSurveyStatusData(this.loggedUser.user.userId, refId, this.surveyService, this.toastr);
              surveyPromise.then((data: any) => {
                if (data) {
                  this.isSurveyStarted = data.isSurveyStarted;
                  this.isSurveyCertificable = data.isSurveyCertificable;
                }
                this.isGettingSurveys = false;
              })
                .catch(() => {
                  this.isGettingSurveys = false;
                })
            } else if (!this.adminMode) {
              // Recupero il certificato
              //   this.getCertificationImage();
            };

            // Se l'elemento è certificabile e non è già stata conclusa nè iniziata alcuna certificazione, abilito la possibilità di iniziarne una nuova
            if (isForLinkedItem) {
              if (!this.adminMode && this.itemLinkedToCourse.isConsumed && this.isSurveyCertificable && !this.itemLinkedToCourse.isCertified) {
                this.enableCertification = true;
              }
            } else {
              if (!this.adminMode && this.itemDetails.isConsumed && this.isSurveyCertificable && !this.itemDetails.isCertified) {
                this.enableCertification = true;
              }
            }
            this.isGettingItemDetails = false;

          })
        // metto un block-ui perchè la scrittura dell'engagement potrebbe richiedere anche 2 secondi
      }, 1000);
    }
  }

  // Corsi che ti potrebbbero interessare
  clearInterestedCoursesData(): void {
    this.youCanInterestedCoursesData.coursesLoaded = 0;
    this.youCanInterestedCoursesData.coursesFromRecord = 0;
    this.youCanInterestedCoursesData.courses = [];
    this.youCanInterestedCoursesData.coursesCounter = 0;
  }

  webItemChildsPageChanged(page: number) {
    this.webItemChildsData.page = page;
  }

  itemChildsPageChanged(page: number) {
    this.itemChildsData.page = page;
  }

  // Recupera la lista di corsi che ti potrebbbero interessare
  onGetInterestedCourses(searchedText?: string, newSearch?: boolean) {
    // Se sono arrivato alla fine della lista, non faccio nulla
    if (!newSearch && this.youCanInterestedCoursesData.coursesLoaded >= this.youCanInterestedCoursesData.coursesCounter) {
      return;
    }
    // Avvio il loader
    this.isFetchingInterestedCourses = true;
    if (newSearch) {
      searchedText = searchedText ? searchedText : "";
      this.youCanInterestedCoursesData.resetCourses = true;
      this.youCanInterestedCoursesData.searchedText = searchedText;
      this.clearInterestedCoursesData();
    } else {
      this.youCanInterestedCoursesData.resetCourses = false;
    }

    if (this.getInterestedCourses$) {
      this.getInterestedCourses$.unsubscribe();
    }
    this.getInterestedCourses$ = this.itemService.getSuggestedCoursesList(this.youCanInterestedCoursesData.coursesFromRecord, this.youCanInterestedCoursesData.coursesNumRecords, this.webCourseId || (this.itemDetails && this.itemDetails.itemId))
      .subscribe(
        (youCanInterestedCoursesData: any) => {
          if (youCanInterestedCoursesData.error) {
            // Vedo se c'Ã¨ la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + youCanInterestedCoursesData.error));
          } else {
            this.youCanInterestedCoursesData.coursesCounter = youCanInterestedCoursesData.response.rowsCount;
            this.youCanInterestedCoursesData.coursesLoaded += youCanInterestedCoursesData && youCanInterestedCoursesData.response.rows && youCanInterestedCoursesData.response.rows.length;
            if (youCanInterestedCoursesData && youCanInterestedCoursesData.response && youCanInterestedCoursesData.response.rows.length) {
              for (let i = 0, itemsLength = youCanInterestedCoursesData.response.rows.length; i < itemsLength; i++) {
                let currentItem = youCanInterestedCoursesData.response.rows[i];
                if (currentItem) {
                  if (libraryItems.includes(currentItem.type)) {
                    currentItem.headerBackground = 'library-object';
                    currentItem.headerTitleColor = 'white-text';
                  } else if (libraryContainers.includes(currentItem.type)) {
                    currentItem.headerBackground = 'library-container';
                    currentItem.headerTitleColor = 'white-text';
                  } else if (currentItem.type && (currentItem.type.indexOf('ONLINE') > 0 || onlineItems.includes(currentItem.type))) {
                    currentItem.headerBackground = 'online-courses';
                    currentItem.headerTitleColor = 'black-text';
                    if (currentItem.courseDuration) {
                      currentItem.headerDetail = currentItem.courseDuration;
                    } else {
                      currentItem.isCM = true;
                      currentItem.activeStars = [];
                      for (let i = 0; i < 5; i++) {
                        currentItem.activeStars.push({});
                      }
                      if (currentItem.avgLikingScore) {
                        let activeStarsNumber = Math.round(currentItem.avgLikingScore);
                        for (let i = 0; i < (activeStarsNumber - 1); i++) {
                          currentItem.activeStars[i].isActive = true;
                        }

                      }
                    }
                  } else {
                    currentItem.headerBackground = 'class-courses';
                    currentItem.headerTitleColor = 'black-text';
                    currentItem.isCM = true;
                    currentItem.activeStars = [];
                    for (let i = 0; i < 5; i++) {
                      currentItem.activeStars.push({});
                    }
                    if (currentItem.avgLikingScore) {
                      let activeStarsNumber = Math.round(currentItem.avgLikingScore);
                      for (let i = 0; i < (activeStarsNumber - 1); i++) {
                        currentItem.activeStars[i].isActive = true;
                      }

                    }
                  }
                  if (currentItem.clusterColor && currentItem.clusterColor.length) {
                    currentItem.clusterColorBackground = 'bg-' + currentItem.clusterColor;
                  }
                  if (currentItem.competences) {
                    currentItem.competences = currentItem.competences.join('·');
                  }
                  currentItem.typeTranslate = this.translate.instant('generic.itemTypes.' + currentItem.type);
                  if (currentItem.minutesValue && currentItem.minutesValue > 0) {
                    if (currentItem.minutesValue > 60) {
                      currentItem.headerDetail = Math.floor(currentItem.minutesValue / 60) + "h " + currentItem.minutesValue % 60 + "m"
                    } else {
                      currentItem.headerDetail = currentItem.minutesValue % 60 + "m"
                    }
                  }
                  if (libraryContainers.includes(currentItem.type) && currentItem.numberOfChildObject && currentItem.numberOfChildObject > 0) {
                    if (currentItem.numberOfChildObject === 1) {
                      currentItem.headerDetail = currentItem.numberOfChildObject + " " + this.translate.instant("itemDetailsPage.generic_card.OBJECT");
                    } else {
                      currentItem.headerDetail = currentItem.numberOfChildObject + " " + this.translate.instant("itemDetailsPage.generic_card.OBJECTS");
                    }
                  } else {
                    // Per documenti
                    currentItem.numberOfChildObject = 0;
                  }
                  if (!currentItem.cardCover) {
                    if (currentItem.clusterName) {
                      currentItem.cardCover = "../../../assets/img/img-card/img-clusters/" + currentItem.clusterColor + '.png';
                    } else if (currentItem.type) {
                      currentItem.cardCover = "../../../assets/img/img-card/default-courses/" + currentItem.type + '.png';
                    }
                  }
                }
              }
            }
            this.youCanInterestedCoursesData.courses = this.youCanInterestedCoursesData.courses.concat(youCanInterestedCoursesData.response.rows);
            this.isFetchingInterestedCourses = false;
            this.firstInterestedCardInitDone = true;
            //   this.cdr.detectChanges();
          }
        }
        , (err) => {
          this.isFetchingInterestedCourses = false;
          this.firstInterestedCardInitDone = true;
          if (err && err.message) {
            // Vedo se c'Ã¨ la traduzione dell'errore
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
      )
  }

  getItemPercentage(item) {
    if (item) {
      let percentageItem = 0;
      // Data dell'evento EVENT_ITEM_STARTED
      let dateEventItemStarted: any = null;
      // Data dell'evento EVENT_ITEM_CONSUMED
      let dateEventItemConsumed: any = null;
      // Percentuale di avanzamento, cioè quella fornita dall'EVENT_ITEM_STARTED
      let percentageEventItemStarted: any = null;

      // Se possiede la scormRegistration, prendo da qui la percentuale di avanzamento
      let hasScormRegistration: boolean = item.scormRegistration ? true : false;
      if (hasScormRegistration) {
        percentageItem = item.scormRegistration.score;
      }

      // Se possiede l'itemRegistration (quindi non è uno scorm ma è un oggetto Kaltura), prendo da qui la percentuale di avanzamento
      let hasItemRegistration: boolean = item.item && item.item.itemRegistration ? true : false;
      if (hasItemRegistration) {
        percentageItem = item.item.itemRegistration.currentPrecentage;
      }

      // Verifico gli engagement, cioè le azioni dell'utente sull'Item corrente
      if (item.engagements && item.engagements.length) {
        for (let d = 0; d < item.engagements.length; d++) {
          let currentEngagement = item.engagements[d];

          if (currentEngagement.moduleName == ItemTypes.ITEM) {
            // Attualmente, la percentuale di avanzamento (o percentuale degli oggetti) è data dalla percentuale più recente fra quella dell'item consumed e quella dell'item started. Quindi la più recente, va mostrata. Di conseguenza, devo recuperarle entrambe e, poi, confrontarle
            if (currentEngagement.eventName == ReferenceTypes.EVENT_ITEM_CONSUMED) {
              // Item concluso. Salvo la data in cui è stato creato tale engagement
              dateEventItemConsumed = currentEngagement.creationDate;
              // L'item è stato concluso
              //     this.$scope.isConsumed = true;

              //     if (this.$scope.isLearningPlan || item.itemType == ItemTypes.SCORM_FREE || item.itemType == ItemTypes.SCORM_INVITE || item.itemType == ItemTypes.DAM_ITEM) {
              // Se è un oggetto atomico e ha un engagement 'consumed' significa che posso abilitare la certificazione
              //       this.$scope.isItemCertifiable = true;
              //     }
            }

            if (!hasItemRegistration && !hasScormRegistration && currentEngagement.eventName == ReferenceTypes.EVENT_ITEM_STARTED && currentEngagement.engagementDetails) {
              // Item iniziato. Salvo la data in cui è stato creato tale engagement e la percentuale
              dateEventItemStarted = currentEngagement.creationDate;
              for (let z = 0; z < currentEngagement.engagementDetails.length; z++) {
                let currentEngagementDetail = currentEngagement.engagementDetails[z];
                if (currentEngagementDetail.detailKey == EngagementDetailKeys.PERCENTAGE) {
                  percentageEventItemStarted = currentEngagementDetail.detailValue;
                  if (isNaN(percentageEventItemStarted)) {
                    percentageEventItemStarted = "0";
                  }
                  break;
                }
              }
            }

            if (currentEngagement.eventName == ReferenceTypes.EVENT_ITEM_OPEN) {
              // L'item è stato aperto
              //   this.$scope.isItemOpen = true;
            }

            // Verifico se l'oggetto è già stato verificato
            if (currentEngagement.eventName == ReferenceTypes.EVENT_ITEM_CERTIFIED) {
              //     this.$scope.isItemAlreadyCertified = true;
              // dunque salvo la data
              //     this.$scope.certifiedDate = item.engagements[d].creationDate;
            }
          }
        }
      }

      // Se ho sia l'engagement STARTED che CONSUMED, devo recuperare il più recente
      if (!hasScormRegistration && !hasItemRegistration) {
        if (dateEventItemStarted && dateEventItemConsumed) {
          let startedTime = new Date(dateEventItemStarted).getTime();
          let consumedTime = new Date(dateEventItemConsumed).getTime();
          if (startedTime > consumedTime) {
            // E' più recente l'evento started, quindi visualizzo a front-end la sua percentuale
            percentageItem = percentageEventItemStarted;
          } else {
            // Altrimenti la setto io al 100%
            percentageItem = 100;
          }
        } else if (dateEventItemStarted && !dateEventItemConsumed) {
          percentageItem = percentageEventItemStarted;
        } else if (!dateEventItemStarted && dateEventItemConsumed) {
          percentageItem = 100;
        }
      }

      return percentageItem;
    }
  }

  goToLPFromItem() {
    let urlObject = this.router.url.split('/');
    let url = '';
    if (this.wrapperInitiativeId) {
      let indexOfItem = -1;
      for (let i = 1; i < urlObject.length - 1; i++) {
        if (urlObject[i] == 'itemDetailSec' || urlObject[i] == 'itemDetails') {
          indexOfItem = i;
        }
      }

      for (let i = 1; i < indexOfItem; i++) {
        if (urlObject[i] === 'itemDetailSec') {
          url += 'itemDetails/'
        } else {
          url += urlObject[i] + '/';
        }
      }
      url += 'itemDetails/' + urlObject[urlObject.length - 1];
    } else if (!this.wrapperInitiativeId && this.lpId) {
      for (let i = 1; i < urlObject.length - 1; i++) {
        if (urlObject[i] === 'itemDetailSec') {
          url += 'itemDetails/'
        } else {
          url += urlObject[i] + '/';
        }
      }
    } else if (!this.wrapperInitiativeId) {
      for (let i = 1; i < urlObject.length - 2; i++) {
        if (urlObject[i] === 'itemDetailSec') {
          url += 'itemDetails/'
        } else {
          url += urlObject[i] + '/';
        }
      }
    }
    this.router.navigate([url]);
  }

  goToNextItemInLp() {
    this.isGettingItemDetails = true;
    const currentUrl = this.router.url;
    let urlObject = currentUrl.split('/');
    let url = '';

    if (this.wrapperInitiativeId) {
      if (this.projectId) {
        if (this.firstIncompleteSectionId) {
          for (let i = 1; i < urlObject.length - 3; i++) {
            url += urlObject[i] + '/';
          }
          url += this.firstIncompleteSectionId + '/' + this.firstIncompleteId + '/' + urlObject[urlObject.length - 1];
        } else {
          for (let i = 1; i < urlObject.length - 2; i++) {
            url += urlObject[i] + '/';
          }
          url += this.firstIncompleteId + '/';
          url += urlObject[urlObject.length - 1];
        }
      } else {
        for (let i = 1; i < urlObject.length - 2; i++) {
          url += urlObject[i] + '/';
        }
        url += this.firstIncompleteId + '/';
        url += urlObject[urlObject.length - 1];
      }

    } else if (this.firstIncompleteSectionId) {
      for (let i = 1; i < urlObject.length - 2; i++) {
        url += urlObject[i] + '/';
      }
      url += this.firstIncompleteSectionId + '/' + this.firstIncompleteId;
    } else if (!this.wrapperInitiativeId) {
      for (let i = 1; i < urlObject.length - 1; i++) {
        url += urlObject[i] + '/';
      }
      url += this.firstIncompleteId;
    }
    const newUrl = '/' + url;
    if (currentUrl === newUrl) {
      // Se l'url è uguale a quello di prima significa che non ho altri oggetti da vedere dentro questa sezione. Pertanto torno al dettaglio del learning plan
      this.goBack();
    } else {
      this.isGettingItemDetails = true;
      this.ref.detectChanges();

      setTimeout(() => {
        this.itemDetails = null;
        this.itemLinkedToCourse = null;
        this.resetData();
        this.router.navigate([url])
      }, 700);
    }
  }

  getProgressItemOfLp() {
    if (this.isPlaylistPlan) {
      return this.translations['itemDetailsPage.YOU_COMPLETED'] + ' ' + this.completedItems + '/' + this.bannerTotalItem + ' ' + this.translations['itemDetailsPage.PL_OBJECTS'];
    } else {
      return this.translations['itemDetailsPage.YOU_COMPLETED'] + ' ' + this.completedItems + '/' + this.bannerTotalItem + ' ' + this.translations['itemDetailsPage.LP_OBJECTS'];
    }
  }

  // Verifica se mostrare il pulsante di avvia/continua learning plan
  isStartOrContinueLpVisible() {
    return this.itemDetails && !this.isPlaylistPlan && (
      (this.itemDetails.isCompleted && this.totalLpProgress < 100)
      || (this.isAtomicItem && this.itemDetails.completionPercentage < 100)
    )
    return (!this.isPlaylistPlan && this.itemDetails && this.itemDetails.isCompleted && this.totalLpProgress != 100) ||
      (this.itemDetails && (this.isAtomicItem && !this.isPlaylistPlan) && this.itemDetails.completionPercentage && this.totalLpProgress && this.totalLpProgress != 100);
  }

  // Porta al learning system
  goToLearningSystem() {
    this.router.navigate(['platform/learningSystem/main']);
  }

  ngOnDestroy() {
    if (this.sendValutation$) {
      this.sendValutation$.unsubscribe();
    }
    if (this.getApplicationLang$) {
      this.getApplicationLang$.unsubscribe();
    }
    if (this.suggestItemToUser$) {
      this.suggestItemToUser$.unsubscribe();
    }
    if (this.getItemDetails$) {
      this.getItemDetails$.unsubscribe();
    }
    if (this.createLike$) {
      this.createLike$.unsubscribe();
    }
    if (this.toggleWishlist$) {
      this.toggleWishlist$.unsubscribe();
    }
    if (this.addChilds$) {
      this.addChilds$.unsubscribe();
    }
    if (this.countItems$) {
      this.countItems$.unsubscribe();
    }
    if (this.deleteLike$) {
      this.deleteLike$.unsubscribe();
    }
    if (this.getCertificationFile$) {
      this.getCertificationFile$.unsubscribe();
    }
    if (this.toggleBookmark$) {
      this.toggleBookmark$.unsubscribe();
    }
    if (this.setWebCourseAsComplete$) {
      this.setWebCourseAsComplete$.unsubscribe();
    }
    if (this.setWebCourseAsOpened$) {
      this.setWebCourseAsOpened$.unsubscribe();
    }
    if (this.countUsersToSuggestItem$) {
      this.countUsersToSuggestItem$.unsubscribe();
    }
    if (this.getItemNote$) {
      this.getItemNote$.unsubscribe();
    }
    if (this.checkRentableStatus$) {
      this.checkRentableStatus$.unsubscribe();
    }
    if (this.checkMyRentableStatus$) {
      this.checkMyRentableStatus$.unsubscribe();
    }
    if (this.getRentHistory$) {
      this.getRentHistory$.unsubscribe();
    }
  };

  goBack() {
    // Controllo se ho il link della pagina precedente, altrimenti torno in home (es se arrivo da link diretto al corso)
    let tmp = localStorage.getItem("backRedirect");
    if (tmp) {
      this.router.navigate([tmp]);
    } else {
      this.redirectService.goToHome();
    }
  }

  // Apri modale condividi certificato messaggio
  openShareModalCertificateMessage(editionCertification) {
    this.editionCertificationToShare = editionCertification;
    this.downloadCourseCertificates(this.editionCertificationToShare.courseDateId, true);
    this.modalService.open("shareCertificateMessage");
  }

  /**
   * Send the feedback of the survey
   */
  sendFeedbackSurvey() {
    this.isLoadingValutation = true;

    //const itemRef = this.itemLinkedToCourse ? this.itemLinkedToCourse : this.itemDetails;
    const itemRef = this.itemDetails;

    if (this.sendValutation$) {
      this.sendValutation$.unsubscribe();
    }

    let refId = itemRef.itemId || this.collectionId || this.webCourseId;

    this.sendValutation$ = this.itemService.sendValutation(refId, this.surveyPointsSelected)
      .subscribe(
        (data: SenecaResponse<any>) => {
          if (data.error) {
            this.surveySended = false;
            this.toastr.error(this.translate.instant("errors." + data.error));
          } else {
            this.surveySended = true;
            itemRef.judgement = Math.round(data.response.judgement);
            itemRef.myJudgement = data.response?.myJudgement;
            this.partialScore = Math.round(data.response.judgement);
          }
          this.isLoadingValutation = false;
        }, (err) => {
          this.isLoadingValutation = false;
          this.surveySended = false;
          if (err) {
            this.toastr.error(err.message || err);
          }
        }
      );
  }

  // Apri modale condividi certificato messaggio
  closeShareModalCertificateMessage() {
    this.editionCertificationToShare = null;
    this.modalService.close("shareCertificateMessage");
  }

  // Copia il testo per la condivisione del certificato nei social. Contiene anche gli spazi tra il testo e gli hashtag finali.
  copyShareCertificateText() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';

    const textToCopy = this.translate.instant('itemDetailsPage.SHARE_MODAL_MESSAGE.SHARE_CERTIFICATION_1') + '\n\n' + this.translate.instant('itemDetailsPage.SHARE_MODAL_MESSAGE.SHARE_CERTIFICATION_2');
    selBox.value = textToCopy;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastr.success(this.translate.instant('itemDetailsPage.SHARE_MODAL_MESSAGE.TEXT_COPIED'));
  }

  // Inserito solo perchè il template lo richiede ma la funzione mancava, ritorno array vuoto in quanto non so cosa possa servire
  getWebinarSelectedDaySlots() {
    return [];
  }

  onSelectedEditionOfMainSelect(edition: any): void {
    this.selectedEditionOfMainSelect = edition;
  }

  onSelectedCourseDateId(selectedCourseDateId: string): void {
    this.selectedCourseDateId = selectedCourseDateId;
  }

  onSurveySended(surveySended: boolean): void {
    this.surveySended = surveySended;
  }

  onSurveyPoints(surveyPoints: number): void {
    this.surveyPoints = surveyPoints;
  }

  onSurveyPointsSelected(surveyPointsSelected: number): void {
    this.surveyPointsSelected = surveyPointsSelected;
  }

  /** Ritorna l'url da utilizzare per lo sfondo dell'header */
  getItemPageHeaderBackgoundImage(): string {
    return `url(${this.itemDetails?.cardCoverUrl || (this.collectionId ? 'assets/img/img-card/default-courses/OF_COURSE_ME_COLLECTION.png' : 'assets/img/pl.png')})`;
  }

  checkIsSubscribedToAnEdition(editionsList) {

    let firsSubscribedEdition = editionsList.find(e => e.labelStatusToDisplay === "subscribed");

    if (firsSubscribedEdition) {
      this.isSubscribedToAnEdition = true;
      this.firsSubscribedEdition = firsSubscribedEdition;
    } else {
      this.isSubscribedToAnEdition = false;
      this.firsSubscribedEdition = null;
    }
  }

  shouldShowEdition(el) {
    // if(this.isSubscribedToAnEdition && this.firsSubscribedEdition.courseDateId == el.courseDateId){
    if (el.labelStatusToDisplay === "subscribed") {
      //se sono iscritto, la mostro
      return true;
    } else {
      //altrimenti la mostro solo se ci sono posti disponibili
      return (el.availableSeats > 0 || el.availableSeatsOB > 0);
    }
  }
}
