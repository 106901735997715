import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "input-search",
  templateUrl: "./input-search.component.html",
  styleUrls: ["./input-search.component.scss"],
})
export class InputSearchComponent implements OnInit, AfterViewInit {

  _isHeaderSearch: boolean;
  @Input() set isHeaderSearch(value: boolean | string) {
    this._isHeaderSearch = coerceBooleanProperty(value);
  };

  _forHome: boolean;
  @Input() set forHome(value: boolean | string) {
    this._forHome = coerceBooleanProperty(value);
  };

  _headerDarkMood: boolean;
  @Input() set headerDarkMood(value: boolean | string) {
    this._headerDarkMood = coerceBooleanProperty(value);
  };

  @Input() placeholder: string;
  @Input() bindValue: string;
  @Input() isForLeaderboard: boolean;

  _disabled: boolean;
  @Input() set disabled(value: boolean | string) {
    this._disabled = coerceBooleanProperty(value);
  };

  _lighter: boolean;
  @Input() set lighter(value: boolean | string) {
    this._lighter = coerceBooleanProperty(value);
  };
  @Input() attrName?: string;
  @Input() attrAriaLabel: string;

  @Output() onStartSearch = new EventEmitter();
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onBlurEvent: EventEmitter<any> = new EventEmitter();

  _id: string;
  @Input() id: string | number;

  get showClear(): boolean {
    return !this._isHeaderSearch && this.inputIsFilled;
  }

  get inputIsFilled(): boolean {
    return this.bindValue !== undefined && this.bindValue !== null && this.bindValue.length > 0;
  }

  constructor() { }

  emitOnModelChanged(event?) {
    if (this.onModelChanged && this.onModelChanged instanceof EventEmitter) {
      this.onModelChanged.emit(event);
    }
  }

  onSubmit() {
    if (this.onStartSearch) {
      this.onStartSearch.emit(this.bindValue);
    }
  }

  ngOnInit() {
    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "search-input-" + this.id;
  }

  ngAfterViewInit() {
    if (this._isHeaderSearch) {
      this.focusOnInput();
    }
  }

  /**
   * Clear the text
   */
  clearText() {
    this.bindValue = undefined;
    if (this.onModelChanged) {
      this.onModelChanged.emit(undefined);
    }
    if (this.onStartSearch) {
      this.onStartSearch.emit(undefined);
    }
    this.focusOnInput();
  }

  /**
   * Focus on the input
   */
  focusOnInput() {
    setTimeout(() => {
      const element: HTMLElement = document.getElementById(this._id);
      if (element) {
        element.focus();
      }
    }, 0);
  }

}
