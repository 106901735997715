import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { JwtPayload } from "atfcore-commonclasses/bin/classes/auth";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import * as fromApp from '../../ngrx/app.reducers';

import { PlayerEventState } from "./kaltura-player.component";
import { UrlService } from "src/app/shared/services/url.service";

@Injectable()
export class PlayerService {
  private _mediatorUrl: string;
  private _loggedUser: JwtPayload;

  constructor(private store: Store<fromApp.AppState>, private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();

    this.store.select(fromApp.getLoggedUser).subscribe(
      (loggedUser) => {
        this._loggedUser = loggedUser;
      }
    );
  }

  /**
 * Create a new engagement for the item passed based upon DAM Player notifications
 * @param {string} itemId - The item viewed by the user
 * @param {string} event - The triggered event
 * @param {number} totalTime - The item maximum running time
 * @param {number} currentTime - The item actual time
 * @return null
 */
  updatePlayerStatus(itemId: string, event: PlayerEventState, totalTime: number, currentTime: number, viewedSeconds?: number): Observable<SenecaResponse<null>> {
    let userId = this._loggedUser.user.userId;
    return this.http.post<SenecaResponse<null>>(
      this._mediatorUrl + `glp-update-status-from-player/${itemId}/${event}/${totalTime}/${currentTime}`, {
      userId,
      viewedSeconds
    }
    );
  }
}
