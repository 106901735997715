import { HttpParams } from "@angular/common/http";
import * as moment from "moment";

import { OnDemandItem } from "atfcore-commonclasses/bin/classes/onDemandItem";
import { JwtPayload, RequiredAuth } from "atfcore-commonclasses/bin/classes/auth";
import { Tag, TagInfo } from "atfcore-commonclasses/bin/classes/tag";
import { ProfilingTag } from "generali";

import { BackType } from "./components/back-button/back-button.component";
import { FilterItem } from "./components/select/select.component";
import { ScreenSize } from "./interfaces/screen.interface";
import { ItemAttributeObjectTypes, ItemTakerEnrollStatusTypes, ItemTypes } from "atfcore-commonclasses/bin/classes/item";
import { Observable, ObservableInput } from "rxjs";
import { switchMap, take, filter } from "rxjs/operators";
import { ItemAttributeTypes, ReferenceTypes } from "atfcore-commonclasses";

// Pulisce i query params da un path param. Quindi se in input riceve "439234-234?ssoToken=test", torna "439234-234"
export function getDecodedUrlParam(param: string): string {
  const decodedParam = param && decodeURIComponent(param);
  return decodedParam && decodedParam.split('?')[0];
}

export function formatWebCoursesCollections(courses, translate) {
  if (courses && courses.length) {
    for (let i = 0, itemsLength = courses.length; i < itemsLength; i++) {
      let currentItem = courses[i];
      if (currentItem) {
        currentItem.title = currentItem.title || currentItem.courseTitle || currentItem.collectionTitle || '';
        currentItem.numberOfChildObject = (currentItem.contentLength || currentItem.numberOfChildObject || 0);
        if (currentItem.numberOfChildObject === 1) {
          currentItem.numberOfChildObject = currentItem.numberOfChildObject + ' ' + translate.instant("generic.COLLECTION_ITEM").toUpperCase();
        } else {
          currentItem.numberOfChildObject = currentItem.numberOfChildObject + ' ' + translate.instant("generic.COLLECTION_ITEMS").toUpperCase();
        }

        // Da decommentare per i tag
        //currentItem.arguments = [{ title: "Test 1" }, { title: "Test 2" }];

        currentItem.collectionLabel = translate.instant("generic.itemTypes.OF_COURSE_ME_COLLECTION").toUpperCase();
        currentItem.coverImage = 'assets/img/cover/courses/Corsi_Mob.jpg';

        currentItem.typeTranslate = translate.instant('itemDetailsPage.generic_card.COLLECTION');
        if (currentItem.competences) {
          currentItem.competences = currentItem.competences.join('·');
        }
        if (!currentItem.itemImage) {
          if (!libraryItems.includes(currentItem.itemType) && !libraryContainers.includes(currentItem.itemType)) {
            if (currentItem.clusterName) {
              currentItem.itemImage = "../../../assets/img/img-card/img-clusters/" + currentItem.clusterColor + '.png';
            } else if (currentItem.itemType) {
              currentItem.itemImage = "../../../assets/img/img-card/default-courses/" + currentItem.itemType + '.png';
            }
          }
        }

        currentItem.contentLabel = currentItem.numberOfChildObject;
      }
    }
  }
}

// Torna il tag dell'area professionale
export function getProfessionalTag(tag: Tag): ProfilingTag {
  // Imposto l'icona in base alla tipologia
  let src = 'assets/icons/profilation/icon-chat.svg';
  let srcClicked = 'assets/icons/profilation/icon-chat.svg';

  if (tag) {
    if (tag.tagId === 'TALENT_FARM_BIG_DATA_INSURTECH') {
      src = 'assets/icons/profilation/gear-big-data.svg';
      srcClicked = 'assets/icons/profilation/gear-big-data-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_CHANGE_MANAGEMENT') {
      src = 'assets/icons/profilation/gear-cambio-linea.svg';
      srcClicked = 'assets/icons/profilation/gear-cambio-linea-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_IT_CYBERSECURITY') {
      src = 'assets/icons/profilation/gear-cybersec.svg';
      srcClicked = 'assets/icons/profilation/gear-cybersec-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_COMMUNICATION_SUSTAINABILITY') {
      src = 'assets/icons/profilation/gear-megafono.svg';
      srcClicked = 'assets/icons/profilation/gear-megafono-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_FINANCE_CONTROL_FUNCTIONS') {
      src = 'assets/icons/profilation/gear-finance-control.svg';
      srcClicked = 'assets/icons/profilation/gear-finance-control-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_OPERATIONS') {
      src = 'assets/icons/profilation/gear-operations.svg';
      srcClicked = 'assets/icons/profilation/gear-operations-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_SALES_PROFESSIONAL_FIELD') {
      src = 'assets/icons/profilation/gear-anticipi-agenti.svg';
      srcClicked = 'assets/icons/profilation/gear-anticipi-agenti-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_MARKETING_CUSTOMER_EXPERIENCE_DESIGN') {
      src = 'assets/icons/profilation/gear_pianifica.svg';
      srcClicked = 'assets/icons/profilation/gear_pianifica_white.svg';
    } else if (tag.tagId === 'TALENT_FARM_HUMAN_RESOURCES') {
      src = 'assets/icons/profilation/gear-team.svg';
      srcClicked = 'assets/icons/profilation/gear-team-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_INSURANCE_FIELD') {
      src = 'assets/icons/profilation/gear-copertura.svg';
      srcClicked = 'assets/icons/profilation/gear-copertura-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_LEGAL_COMPLIANCE') {
      src = 'assets/icons/profilation/gear-bilancia-in-equilibirio.svg';
      srcClicked = 'assets/icons/profilation/gear-bilancia-in-equilibirio-white.svg';
    }
  }

  return {
    selected: false,
    title: tag.title,
    id: tag.tagId,
    src: src,
    srcClicked: srcClicked
  };
}

export function getSocialLoginConfig(urlService) {
  // config di dev/default
  let config = {
    facebook: {
      clientId: "1225772800925403"
    },
    google: {
      clientId: "426387533671-630skuta58m22fcir0n0lf47tsklf776.apps.googleusercontent.com"
    },
    linkedIn: {
      clientId: "77rxxo08cp34uk",
      authorize: true,
      lang: 'en_US',
      scope: "r_liteprofile r_emailaddress w_member_social",
      callBackUrl: "linkedIn.html"
    },
    twitter: {
      consumerKey: "9t9bmJ6LCxFEiZrM9jkysulkU",
      callBackUrl: "twitter.html"
    }
  };
  if (urlService.isStaging()) {
    config = {
      ...config,
      facebook: {
        clientId: "1663292757147526"
      },
      google: {
        clientId: "245340040125-aq6o0t0nlnm88thldolrhc601e1bltfd.apps.googleusercontent.com"
      },
      linkedIn: {
        ...config.linkedIn,
        clientId: "77rxxo08cp34uk",
      },
      twitter: {
        consumerKey: "CNXJVY0GWocievbFgpyC0h2pG",
        callBackUrl: "twitter.html"
      }
    }
  }
  else if (urlService.isProduction()) {
    config = {
      ...config,
      facebook: {
        clientId: "343276282859257"
      },
      google: {
        clientId: "309193715389-v6ht50v3n2cteflipl9hhdm875hr0ruo.apps.googleusercontent.com"
      },
      linkedIn: {
        ...config.linkedIn,
        clientId: "77o5izhupcrc0i"
      },
      twitter: {
        consumerKey: "VMkc0wcizJoH7oXGvUFu9JZUT",
        callBackUrl: "twitter.html"
      }
    }
  }
  return config;
}

// Torna il tag delle categorie per la profilazione
export function getCategoriesTag(tag: TagInfo) {
  // Imposto l'icona in base alla tipologia
  let src = 'assets/icons/profilation/icon-chat.svg';
  let srcClicked = 'assets/icons/profilation/icon-chat.svg';

  if (tag) {
    if (tag.tagId === 'TECHNICAL_AND_BUSINESS_SKILLS') {
      src = 'assets/icons/profilation/icon_competenze.svg';
      srcClicked = 'assets/icons/profilation/icon_competenze_white.svg';
    } else if (tag.tagId === 'CUSTOMER_CULTURE') {
      src = 'assets/icons/profilation/icon_cultura_cliente.svg';
      srcClicked = 'assets/icons/profilation/icon_cultura_cliente_white.svg';
    } else if (tag.tagId === 'DATA_SCIENCE_AND_NEW_TECHNOLOGY') {
      src = 'assets/icons/profilation/icon_new_tech.svg';
      srcClicked = 'assets/icons/profilation/icon_new_tech_white.svg';
    } else if (tag.tagId === 'DIVERSITY_AND_INCLUSION') {
      src = 'assets/icons/profilation/icon_D&I.svg';
      srcClicked = 'assets/icons/profilation/icon_D&I_white.svg';
    } else if (tag.tagId === 'MANDATORY_TRAINING') {
      src = 'assets/icons/profilation/icon_formazione.svg';
      srcClicked = 'assets/icons/profilation/icon_formazione_white.svg';
    } else if (tag.tagId === 'DEVISE_AN_ONLINE_BRAND_COMMUNICATION_PLAN') {
      src = 'assets/icons/profilation/gear-operations.svg';
      srcClicked = 'assets/icons/profilation/gear-operations-white.svg';
    } else if (tag.tagId === 'IT_AND_SOFTWARE') {
      src = 'assets/icons/profilation/icon_software.svg';
      srcClicked = 'assets/icons/profilation/icon_software_white.svg';
    } else if (tag.tagId === 'LEADERSHIP_AND_MANAGEMENT') {
      src = 'assets/icons/profilation/icon_leadership.svg';
      srcClicked = 'assets/icons/profilation/icon_leadership_white.svg';
    } else if (tag.tagId === 'LANGUAGES') {
      src = 'assets/icons/profilation/icon_lingue.svg';
      srcClicked = 'assets/icons/profilation/icon_lingue_white.svg';
    } else if (tag.tagId === 'NEW_WORKING_METHODS') {
      src = 'assets/icons/profilation/icon_lavoro.svg';
      srcClicked = 'assets/icons/profilation/icon_lavoro_white.svg';
    } else if (tag.tagId === 'PERSONAL_DEVELOPMENT') {
      src = 'assets/icons/profilation/icon_sviluppo_personale.svg';
      srcClicked = 'assets/icons/profilation/icon_sviluppo_personale_white.svg';
    } else if (tag.tagId === 'WORK_LIFE_BALANCE_AND_WELL_BEING') {
      src = 'assets/icons/profilation/gear-bilancia-in-equilibirio.svg';
      srcClicked = 'assets/icons/profilation/gear-bilancia-in-equilibirio-white.svg';
    }
  }

  return {
    selected: false,
    title: tag.title,
    id: tag.tagId,
    tagId: tag.tagId,
    src: src,
    srcClicked: srcClicked,
    hintText: tag.hintText,
    description: tag.description
  };
}

// Torna il tag di un interesse
export function getInterestTag(tag: Tag): ProfilingTag {
  // Imposto l'icona in base alla tipologia
  let src = 'assets/icons/profilation/icon-chat.svg';
  let srcClicked = 'assets/icons/profilation/icon-chat.svg';

  if (tag.tagId) {
    if (tag.tagId === 'TALENT_FARM_APPS') {
      src = 'assets/icons/profilation/gear-app.svg';
      srcClicked = 'assets/icons/profilation/gear-app-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_ATTUALITA') {
      src = 'assets/icons/profilation/gear-attualita.svg';
      srcClicked = 'assets/icons/profilation/gear-attualita-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_BIG_DATA_ANALYTICS') {
      src = 'assets/icons/profilation/gear-analytics.svg';
      srcClicked = 'assets/icons/profilation/gear-analytics-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_CAREER_TIPS') {
      src = 'assets/icons/profilation/gear-career.svg';
      srcClicked = 'assets/icons/profilation/gear-career-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_CODING') {
      src = 'assets/icons/profilation/gear-coding.svg';
      srcClicked = 'assets/icons/profilation/gear-coding-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_ECOLOGIA') {
      src = 'assets/icons/profilation/gear-protezione.svg';
      srcClicked = 'assets/icons/profilation/gear-protezione-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_ECONOMIA_FINANZA') {
      src = 'assets/icons/profilation/gear-economia.svg';
      srcClicked = 'assets/icons/profilation/gear-economia-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_GAMING') {
      src = 'assets/icons/profilation/gear-gaming.svg';
      srcClicked = 'assets/icons/profilation/gear-gaming-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_GENDER_BALANCE') {
      src = 'assets/icons/profilation/gear-clienti.svg';
      srcClicked = 'assets/icons/profilation/gear-clienti-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_INCLUSION') {
      src = 'assets/icons/profilation/gear-network.svg';
      srcClicked = 'assets/icons/profilation/gear-network-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_INSURANCE') {
      src = 'assets/icons/profilation/gear-copertura.svg';
      srcClicked = 'assets/icons/profilation/gear-copertura-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_INTELLIGENZA_ARTIFICIALE') {
      src = 'assets/icons/profilation/gear-ia.svg';
      srcClicked = 'assets/icons/profilation/gear-ia-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_INVESTIMENTI') {
      src = 'assets/icons/profilation/gear-invest.svg';
      srcClicked = 'assets/icons/profilation/gear-invest-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_IOT') {
      src = 'assets/icons/profilation/gear-iot-aree.svg';
      srcClicked = 'assets/icons/profilation/gear-iot-aree-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_LAVORI_DIGITALI_DEL_FUTURO') {
      src = 'assets/icons/profilation/gear-prodotto-tecnologico.svg';
      srcClicked = 'assets/icons/profilation/gear-prodotto-tecnologico-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_MULTICULTURALITA') {
      src = 'assets/icons/profilation/gear-multicult.svg';
      srcClicked = 'assets/icons/profilation/gear-multicult-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_ONLINE_TRENDS_INFLUENCER') {
      src = 'assets/icons/profilation/gear-trends.svg';
      srcClicked = 'assets/icons/profilation/gear-trends-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_ROBOTICA') {
      src = 'assets/icons/profilation/gear-chatbot.svg';
      srcClicked = 'assets/icons/profilation/gear-chatbot-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_SALES') {
      src = 'assets/icons/profilation/gear-anticipi-agenti.svg';
      srcClicked = 'assets/icons/profilation/gear-anticipi-agenti-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_SCOPRI_GENERALI') {
      src = 'assets/icons/profilation/gear-scopri-generali.svg';
      srcClicked = 'assets/icons/profilation/gear-scopri-generali-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_SOCIAL_MEDIA') {
      src = 'assets/icons/profilation/gear-condividi.svg';
      srcClicked = 'assets/icons/profilation/gear-condividi-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_SOSTENIBILITÀ') {
      src = 'assets/icons/profilation/gear-collegamento.svg';
      srcClicked = 'assets/icons/profilation/gear-collegamento-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_TECH_TRENDS') {
      src = 'assets/icons/profilation/gear-tech-trends.svg';
      srcClicked = 'assets/icons/profilation/gear-tech-trends-white.svg';
    } else if (tag.tagId === 'TALENT_FARM_WELLNESS') {
      src = 'assets/icons/profilation/gear-wellness.svg';
      srcClicked = 'assets/icons/profilation/gear-wellness-white.svg';
    }
  }

  return {
    selected: false,
    title: tag.title,
    id: tag.tagId,
    src: src,
    srcClicked: srcClicked
  };
}

// Torna la misura dello schermo
export function getScreenSizeCode(): string {
  const screenWidth = window.innerWidth;
  let sSize;
  if (screenWidth < 599) {
    sSize = ScreenSize.XS;
  } else if (screenWidth >= 600 && screenWidth < 959) {
    sSize = ScreenSize.SM;
  } else if (screenWidth >= 960 && screenWidth < 1279) {
    sSize = ScreenSize.MD;
  } else if (screenWidth >= 1280 && screenWidth < 1919) {
    sSize = ScreenSize.LG;
  } else if (screenWidth >= 1920) {
    sSize = ScreenSize.XL;
  }

  return sSize;
}

// Ritorna la data di nascita dell'utente
export function getUserBirthday(jwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.birthDate) || '';
}

// Ritorna il CAP dell'utente
export function getUserCap(jwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.zipCode) || '';
}

// Ritorna il titolo di studio dell'utente
export function getUserEducation(jwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.degree);
}

// Ritorna il gender dell'utente
export function getUserGender(jwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.gender);
}

export function getUserQualification(jwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.qualification);
}

export function getUserFirstContact(jwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.firstContact);
}

// Ritorna l'avatar di un utente o, in alternativa, un placeholder
export function getUserAvatarUrl(jwtPayload: JwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.userOptions && jwtPayload.user.userOptions.avatarImage)
    || ('assets/img/avatar-placeholder.png');
}

// Ritorna l'immagine di copertina di un utente o, in alternativa, un placeholder
export function getUserCoverUrl(jwtPayload: JwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.userOptions && jwtPayload.user.userOptions.profileCoverImage)
    || ('assets/icons/covers/cover-1.png');
}

export function dataURItoBlob(dataURI: string): Blob {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export function getCompany(jwtPayload: JwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.socAppartenenza) || '';
}

// Ritorna l'avatar di un utente o, in alternativa, un placeholder
export function getUserAvatar(jwtPayload: JwtPayload): string {
  if (jwtPayload && jwtPayload.user && jwtPayload.user.userOptions && jwtPayload.user.userOptions.avatarImage) {
    return jwtPayload.user.userOptions.avatarImage;
  } else if (jwtPayload && jwtPayload.user && jwtPayload.user.gender == "M" || jwtPayload && jwtPayload.user && jwtPayload.user.gender == "F") {
    if (jwtPayload && jwtPayload.user && jwtPayload.user.gender == "M") {
      return 'assets/icons/placeholder.svg';
    } else {
      return 'assets/icons/placeholder_female.svg';
    }
  } else {
    return 'assets/icons/icon-profile.svg';
  }
}

// Ritorna il nome di un utente o, in alternativa, un placeholder
export function getUserForename(jwtPayload: JwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.forename) || '';
}

// Ritorna il cognome di un utente o, in alternativa, un placeholder
export function getUserSurname(jwtPayload: JwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.surname) || '';
}

// Ritorna il nome e il cognome di un utente o, in alternativa, un placeholder
export function getUserFullName(jwtPayload: JwtPayload): string {
  return jwtPayload && jwtPayload.user && jwtPayload.user.forename && jwtPayload.user.surname ?
    (jwtPayload.user.forename + ' ' + jwtPayload.user.surname) : '';
}

// Ritorna la mail di un utente o, in alternativa, un placeholder
export function getUserEmail(jwtPayload: JwtPayload): string {
  return (jwtPayload && jwtPayload.user && jwtPayload.user.email) || '';
}

/**
 * Get the difference from now to the date
 * Use of moment.js
 * @param date Date
 */
export function getTimeFromNow(date: Date): string {
  return moment(date).fromNow();
}

export function getOnDemandItem(title: string, itemImage: string): OnDemandItem {
  return {
    itemId: "48acfe3b-2195-4953-b2c1-bde45cae4101",
    itemType: "DAM_ITEM",
    objectType: "VIDEO",
    objectTypeSpecialization: "", // non viene passato
    title: title,
    subTitle: null,
    description: null,
    active: true, // non viene passato un boolean ma un numero
    durationInMinutes: 50,
    originApplicationName: "", // non viene passato
    creationDate: new Date("2021-03-16T08:29:57.000Z"),
    modifyDate: new Date("2021-03-16T09:10:46.000Z"),
    itemImage: itemImage,
    savedForLater: false,
    currentPrecentage: 0,
    arguments: [
      {
        tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
        title: "Emotion"
      },
      {
        tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
        title: "Food"
      }, {
        tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
        title: "Emotion"
      },
      {
        tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
        title: "Food"
      }
    ]
  };
}

export function getBlogPost(title: string, postImage: string): any {
  return {
    itemId: "f5a8c62c-40be-4e19-afe5-e496995dce67",
    title: title,
    subTitle: "Con questo fanno 2!",
    postImage: postImage,
    description: "<p>Secondo testo completo con un <br>a capo e anche <strong>grassetto</strong> ovunque<br>serva. Ammesso che serva.",
    active: true, // non viene passato un boolean ma un numero
    originApplicationName: "", // non viene passato
    creationDate: new Date("2021-03-01T18:33:07.000Z"),
    modifyDate: new Date("2021-03-01T18:36:31.000Z"),
    articlePreview: '',
    itemType: '',
    highlighted: false,
    seniority: [],
    visibility: '',
    arguments: [
      {
        tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
        title: "Emotion"
      },
      {
        tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
        title: "Food"
      }, {
        tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
        title: "Emotion"
      },
      {
        tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
        title: "Food"
      }
    ]
  };
}

export function getDateWithoutSeconds(value: any): any {
  if (!value) {
    return null;
  }
  if (typeof value === 'string') {
    return new Date(new Date(value).setSeconds(0, 0));
  }
  if (value instanceof Date) {
    return new Date(value.setSeconds(0, 0));
  }
  if (moment.isMoment(value)) {
    const newDate = moment(value).set('second', 0).set('millisecond', 0);
    return newDate;
  }
}

export function convertModelToUTC(value: any): string {
  const _date = getDateWithoutSeconds(value);
  if (_date && _date instanceof Date) {
    return new Date(_date.toUTCString()).toISOString();
  }
  if (_date && moment.isMoment(_date)) {
    let newDateVar = _date.utc().format();
    return new Date(newDateVar).toISOString();
  }
  return null;
}

/**
 * Set the arguments ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleArgumentsIds(httpParams: HttpParams, list: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'argumentsIds', list);
}

/**
 * Set the tag ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleTagIds(httpParams: HttpParams, list: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'tagIds', list);
}

/**
 * Set the job types
 * @param httpParams HttpParams to append the data
 * @param jobTypes List of job types
 * @returns HttpParams with appended list if the data are presents
 */
export function handleJobTypes(httpParams: HttpParams, jobTypes: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'jobTypes', jobTypes);
}

/**
 * Set the locations
 * @param httpParams HttpParams to append the data
 * @param locations List of locations
 * @returns HttpParams with appended list if the data are presents
 */
export function handleLocations(httpParams: HttpParams, locations: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'locations', locations);
}

/**
 * Set the event types
 * @param httpParams HttpParams to append the data
 * @param eventTypes List of event types
 * @returns HttpParams with appended list if the data are presents
 */
export function handleEventTypes(httpParams: HttpParams, eventTypes: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'eventTypes', eventTypes);
}

/**
 * Set the fromRecord
 * @param httpParams HttpParams to append the data
 * @param fromRecord From record
 */
export function handleFromRecord(httpParams: HttpParams, fromRecord: number): HttpParams {
  return fromRecord || fromRecord === 0 ? httpParams.append('fromRecord', (fromRecord && fromRecord.toString())) : httpParams;
}

/**
 * Set the numRecords
 * @param httpParams HttpParams to append the data
 * @param numRecords Number of records
 */
export function handleNumRecords(httpParams: HttpParams, numRecords: number): HttpParams {
  return numRecords ? httpParams.append('numRecords', (numRecords && numRecords.toString())) : httpParams;
}

/**
 * Set the days
 * @param httpParams HttpParams to append the data
 * @param days Days used for the analysis
 */
export function handleDays(httpParams: HttpParams, days: number): HttpParams {
  return days ? httpParams.append('days', (days && days.toString())) : httpParams;
}

/**
 * Set the maxDays
 * @param httpParams HttpParams to append the data
 * @param maxDays Number of days
 */
export function handleMaxDays(httpParams: HttpParams, maxDays: number): HttpParams {
  return maxDays ? httpParams.append('maxDays', (maxDays && maxDays.toString())) : httpParams;
}

/**
 * Set the searchedText
 * @param httpParams HttpParams to append the data
 * @param searchedText Searched text
 */
export function handleSearchedText(httpParams: HttpParams, searchedText: string): HttpParams {
  return searchedText ? httpParams.append('searchedText', searchedText || '') : httpParams;
}

/**
 * Set the searchedText
 * @param httpParams HttpParams to append the data
 * @param sorting Sorting
 */
export function handleSorting(httpParams: HttpParams, sorting: string): HttpParams {
  return sorting ? httpParams.append('sorting', sorting || '') : httpParams;
}

/**
 * Set the durationFilter
 * @param httpParams HttpParams to append the data
 * @param durationFilter Duration filter
 */
export function handleDurationFilter(httpParams: HttpParams, durationFilter: string): HttpParams {
  return durationFilter ? httpParams.append('durationFilter', durationFilter || '') : httpParams;
}

/**
 * Set the object type seniorities ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleSeniorities(httpParams: HttpParams, list: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'seniorities', list);
}

/**
 * Set the object type specializations ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleObjectTypeSpecializations(httpParams: HttpParams, list: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'objectTypeSpecializations', list);
}

/**
 * Set the object type ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleObjectTypes(httpParams: HttpParams, list: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'objectTypes', list);
}

/**
 * Set the child of items id in & configuration
 * @param httpParams HttpParams to append the data
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleChildOfItemId(httpParams: HttpParams, list: string | string[]): HttpParams {
  return handleCustomList(httpParams, 'childOfItemId', list);
}

/**
 * Set the arguments ids in & configuration
 * @param httpParams HttpParams to append the data
 * @param paramName Name of custom parameter
 * @param list List of arguments
 * @returns HttpParams with appended list if the data are presents
 */
export function handleCustomList(httpParams: HttpParams, paramName: string, list: string | string[]): HttpParams {
  if (list && list.length && Array.isArray(list)) {
    list && list.forEach(type => {
      httpParams = httpParams.append(paramName, type);
    });
  } else if (list && list.length && !Array.isArray(list)) {
    httpParams = httpParams.append(paramName, list);
  }

  return httpParams;
}

export type ContentArea = 'MEET' | 'TECH' | 'PEOPLE' | 'SUST' | 'FINANCE';

export function handleContentAreas(httpParams: HttpParams, contentArea?: string): HttpParams {
  if (contentArea) {
    const contentAreaId: string[] = contentAreaIds(contentArea);
    return contentAreaId.length ? handleCustomList(httpParams, 'contentAreaIds', contentAreaId) : httpParams;
  } else return httpParams;
}

export function contentAreaIds(contentArea?: string): string[] {
  if (contentArea) {
    switch (contentArea) {
      case 'MEET':
        return [
          'TALENT_FARM_SCOPRI_GENERALI'
        ];
      case 'TECH':
        return [
          'TALENT_FARM_APPS',
          'TALENT_FARM_BIG_DATA_ANALYTICS',
          'TALENT_FARM_CODING',
          'TALENT_FARM_LAVORI_DIGITALI_DEL_FUTURO',
          'TALENT_FARM_GAMING',
          'TALENT_FARM_INTELLIGENZA_ARTIFICIALE',
          'TALENT_FARM_IOT',
          'TALENT_FARM_ROBOTICA',
          'TALENT_FARM_TECH_TRENDS'
        ];
      case 'PEOPLE':
        return [
          'TALENT_FARM_APPS',
          'TALENT_FARM_ATTUALITA',
          'TALENT_FARM_CAREER_TIPS',
          'TALENT_FARM_LAVORI_DIGITALI_DEL_FUTURO',
          'TALENT_FARM_GAMING',
          'TALENT_FARM_GENDER_BALANCE',
          'TALENT_FARM_INCLUSION',
          'TALENT_FARM_MULTICULTURALITA',
          'TALENT_FARM_ONLINE_TRENDS_INFLUENCER',
          'TALENT_FARM_TECH_TRENDS'
        ];
      case 'SUST':
        return [
          'TALENT_FARM_ECOLOGIA',
          'TALENT_FARM_GENDER_BALANCE',
          'TALENT_FARM_MULTICULTURALITA',
          'TALENT_FARM_SOCIAL_MEDIA',
          'TALENT_FARM_WELLNESS'
        ];
      case 'FINANCE':
        return [
          'TALENT_FARM_LAVORI_DIGITALI_DEL_FUTURO',
          'TALENT_FARM_ECONOMIA_FINANZA',
          'TALENT_FARM_INSURANCE',
          'TALENT_FARM_INVESTIMENTI',
          'TALENT_FARM_SALES'
        ];
      default:
        return [];
    }
  } else return [];
}

/**
 * Get a list of categories filter items
 * @param {TagInfo[]} categories Categories
 * @returns {FilterItem[]} list of filters for select with select ALL
 */
export function getCategories(categories: TagInfo[]): FilterItem[] {
  let categoryFilters: FilterItem[] = [];
  categories.map((x, index) => {
    categoryFilters.push({
      id: x.tagId,
      text: x.title
    });
  });
  return categoryFilters;
}

/**
 * Get a list of sorting for events
 * @returns {FilterItem[]} list of filters for select
 */
export function getSortings(): FilterItem[] {
  return [
    { id: 'D', text: 'generic.filtersOrderBy.D' },
    { id: 'T', text: 'generic.filtersOrderBy.T' }
  ];
}

export function getSortingsArticles(): FilterItem[] {
  return [
    { id: 'M', text: 'generic.filtersOrderBy.D' },
    { id: 'T', text: 'generic.filtersOrderBy.T' }
  ];
}

export function isOfTypeBackType(back: string): back is BackType {
  return [
    'HOMEPAGE',
    'BACK',
    'NEWS',
    'EVENTS',
    'JOBS',
    'COURSES'
  ].includes(back);
}

export function windowPopupSocial(url: string) {
  // Calculate the position of the popup so
  // it’s centered on the screen.
  let left = (screen.width - 770) / 2;
  let top = (screen.height - 570) / 2;

  window.open(
    url,
    "",
    "menubar=no,toolbar=no,status=no,width=770,height=570,top=" + top + ",left=" + left + ",scrollbars=no;resizable=no"
  );
}

export function socialSharerString(socialName: string) {
  let url = "";
  switch (socialName) {
    case 'TWITTER':
      url = "https://twitter.com/intent/tweet?url=";
      break;
    case 'FACEBOOK':
      url = "https://www.facebook.com/sharer/sharer.php?u=";
      break;
      break;
    case 'TELEGRAM':
      url = "https://t.me/share/url?url=";
      break;
    case 'WHATSAPP':
      url = "https://api.whatsapp.com/send?text=";
      break;
  }
  return url;
}

export function socialSharerStringText(socialName: string) {
  let url = "";
  switch (socialName) {
    case 'TWITTER':
      url = "&text=";
      break;
    case 'FACEBOOK':
      url = "&quote=";
      break;
    case 'TELEGRAM':
      url = "&text=";
      break;
    case 'WHATSAPP':
      url = "&text=";
      break;
  }
  return url;
}



export const libraryItems: string[] = ['BOOK', 'GRAPHS', 'LEARNING', 'MAGAZINE', 'SCORM', 'MAGAZINES', 'PLAN', 'PODCAST', 'BOOKS', 'DOCUMENT', 'DVD', 'EBOOK', 'ELEARNING', 'GRAPH', 'IMAGE', 'VIDEO', 'SURVEY', 'SURVEY_ITEM', 'CERTIFICATE_ITEM'];
export const libraryContainers: string[] = ['LEARNING_PLAN', 'PROJECT', 'SECTION', 'CONTAINER'];
export const onlineItems: string[] = ['ASSESSMENT', 'WEBINAR'];

export const SEARCHABLE_ITEM_TYPES: string[] = [
  ItemTypes.ITEM,
  ItemTypes.CONTAINER,
  ItemTypes.ITEMS_COLLECTION,
  ItemTypes.DAM_ITEM,
  ItemTypes.COURSE_SYLLABUS,
  ItemTypes.SCORM_FREE,
  ItemTypes.SCORM_INVITE
];
export const LIBRARY_ITEM_TYPES: string[] = [
  ItemTypes.ITEM,
  ItemTypes.CONTAINER,
  ItemTypes.ITEMS_COLLECTION,
  ItemTypes.DAM_ITEM,
  ItemTypes.SCORM_FREE,
  ItemTypes.SCORM_INVITE
];
export const LIBRARY_CONTAINERS_ITEM_TYPES: string[] = [
  ItemTypes.CONTAINER
];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS: string[] = [
  ItemAttributeObjectTypes.PODCAST,
  ItemAttributeObjectTypes.MAGAZINE,
  ItemAttributeObjectTypes.GRAPH,
  ItemAttributeObjectTypes.DOCUMENT,
  ItemAttributeObjectTypes.ELEARNING,
  'SCORM',
  ItemAttributeObjectTypes.VIDEO,
  ItemAttributeObjectTypes.IMAGE,
  ItemAttributeObjectTypes.PROJECT,
  ItemAttributeObjectTypes.LEARNING_PLAN,
  ItemAttributeObjectTypes.EBOOK,
  ItemTypes.CERTIFICATE_ITEM,
  ItemAttributeObjectTypes.SURVEY
];
export const LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS_WITHOUTH_CONTAINRS: string[] = [
  ItemAttributeObjectTypes.PODCAST,
  ItemAttributeObjectTypes.MAGAZINE,
  ItemAttributeObjectTypes.GRAPH,
  ItemAttributeObjectTypes.DOCUMENT,
  ItemAttributeObjectTypes.ELEARNING,
  ItemTypes.CERTIFICATE_ITEM,
  'SCORM',
  ItemAttributeObjectTypes.VIDEO,
  ItemAttributeObjectTypes.IMAGE,
  ItemAttributeObjectTypes.EBOOK,
  ItemAttributeObjectTypes.SURVEY
];

export function onceIfNotEmpty<T>(project?: (value: T, index?: number) => ObservableInput<T>) {
  // notice that we return a function here.. 
  return function switchOnceImplementation(source: Observable<T>): Observable<T> {
    return source.pipe(filter(val => !!val), take(1));
  }
}

// Formatta una di card (deve contenere oggetti della stessa tipologia)
export function coursesCardMapper(courses, translate) {
  if (courses && courses.length) {
    let formattedCourses = [];
    for (let i = 0, coursesLength = courses.length; i < coursesLength; i++) {
      let currentCourse = courses[i];
      if (isCm2Course(currentCourse)) {
        // Corsi del cm2
        formattedCourses.push(formatCm2CourseCard(currentCourse, translate));
      } else if (isWebCollection(currentCourse)) {
        // Collezioni dal web
        formattedCourses.push(formatWebCollectionCard(currentCourse, translate));
      } else if (isWebCourse(currentCourse)) {
        // Corso dal web
        formattedCourses.push(formatWebCourse(currentCourse, translate));
      } else if (isLibraryItem(currentCourse)) {
        // Oggetti library
        formattedCourses.push(formatLibraryItem(currentCourse, translate));
      } else {
        formattedCourses.push(formatLibraryItem(currentCourse, translate));
      }
    }

    return formattedCourses;
  } else {
    return [];
  }
}

export function formatWebCourse(course, translate) {
  const formattedItem = {
    ...course,
    coverImage: getCardCover(course),
    title: course.courseTitle || course.title || '',
    courseDuration: getDuration(course, translate),
    arguments: [],
    courseType: 'OfCourseMeCourse',
    userStatus: 'TO_START',
    // 'Online', 'Milano Piazza Tre Torri, 1', ..
    where: '',
    isBookmarked: course.isBookmarked,
    numberOfChildObject: getNumberOfChildObject(course, translate),
    cardType: 'item-of-web-collection',
    isCompleted: course.isCompleted
  };

  return formattedItem;
}

// Formatta una card della collezione dal web
export function formatWebCollectionCard(course, translate) {
  const formattedItem = {
    ...course,
    // Le collezioni dal web hanno sempre la stessa immagine di placeholder
    coverImage: `../../../assets/img/img-card/default-courses/OF_COURSE_ME_COLLECTION.png`,
    // Devo creare anche una seconda property in quanto la librarygenerali utilizza questa
    itemImage: `../../../assets/img/img-card/default-courses/OF_COURSE_ME_COLLECTION.png`,
    title: course.title, // truncateAndReplaceWithDots(course.title, 23),
    courseDuration: translate.instant('generic.DURATION_NOT_AVAILABLE'),
    arguments: [],
    courseType: getTranslatedType(course, translate),
    userStatus: 'TO_START',
    where: '',
    isBookmarked: course.isBookmarked,
    numberOfChildObject: getNumberOfChildObject(course, translate),
    cardType: 'web-collection'
  };

  return formattedItem;
}

// Formatta i dati per le card del cm2
export function formatCm2CourseCard(course, translate) {
  const { userStatus, iconSrc } = getCm2UserStatusAndIcon(course, translate);
  // Le chips delle card top ten sono piccole, quindi devo tagliare il testo
  if (course.isTopTenCourse) {
    if (course.argumentName && course.argumentName.length && course.argumentName.length > 17) {
      course.argumentName = course.argumentName.slice(0, 17) + '...';
    }
  }
  const formattedItem = {
    ...course,
    coverImage: getCardCover(course),
    title: course.title,  //title: truncateAndReplaceWithDots(course.title, 23),
    courseDuration: getDuration(course, translate),
    arguments: course.argumentName
      ? [{ tagId: course.argumentName, title: course.argumentName }]
      : [],
    courseType: getTranslatedType(course, translate),
    userStatus,
    where: getPlace(course, translate),
    isBookmarked: course.isBookmarked ?? false,
    numberOfChildObject: getNumberOfChildObject(course, translate),
    iconSrc,
    cardType: 'cm2-course'
  };

  return formattedItem;
}

// Formatta i dati per le card dei corsi library
export function formatLibraryItem(course, translate) {
  const { userStatus, iconSrc } = getLibraryItemStatusAndIcon(course, translate);

  const type = course.type || course.itemType || course.courseType;
  const isAtomic = isLibraryAtomicItem(type) || libraryItems.includes(course.type);
  const isContainer = libraryContainers.includes(type);

  // Costruisco la stringa che contiene il numero di oggetti completati (ad es. "3/4 Oggetti completati")
  let completedItemsDesc;
  if (isContainer) {
    completedItemsDesc = getcCompletedItemsDesc(course, translate)
  }

  // Costruisco la durata (se è un oggetto atomico, troverò i minuti)
  let formattedCourseDuration;
  if (isAtomic) {
    if (course.minutesValue && parseInt(course.minutesValue) > 0) {
      formattedCourseDuration = translate.instant('generic.DURATION') + ' ';
      if (parseInt(course.minutesValue) > 60) {
        const parsedMin = parseInt(course.minutesValue) % 60;
        const parsedHours = Math.floor(parseInt(course.minutesValue) / 60);
        formattedCourseDuration = formattedCourseDuration + getHoursLabel(parsedHours, translate) + ' ' + getMinutesLabel(parsedMin, translate);
      } else {
        if (parseInt(course.minutesValue) == 60) {
          formattedCourseDuration = formattedCourseDuration + getHoursLabel(1, translate);
        } else {
          const parsedMin = parseInt(course.minutesValue) % 60;
          formattedCourseDuration = formattedCourseDuration + getMinutesLabel(parsedMin, translate);
        }
      }
    }
  } else if (isContainer) {
    formattedCourseDuration = course.containerDuration;
  }

  const isPlaylist = isLearningPlan(type);
  let contentLabel;
  if (isPlaylist) {
    contentLabel = getContentLabel(course.numberOfChildObject || 0, translate);
  }

  const formattedItem = {
    ...course,
    coverImage: getCardCover(course),
    title: course.title, //title: truncateAndReplaceWithDots(course.title, 30),
    arguments: course.argumentName
      ? [{ tagId: course.argumentName, title: course.argumentName }]
      : [],
    courseType: getTranslatedType(course, translate),
    userStatus,
    where: getPlace(course, translate),
    isBookmarked: course.isBookmarked ?? false,
    numberOfChildObject: getNumberOfChildObject(course, translate),
    isAtomicItem: isAtomic,
    isContainerItem: isContainer,
    collectionLabel: translate.instant("generic.COLLECTION").toUpperCase(),
    contentLabel,
    completedItemsDesc,
    courseDuration: formattedCourseDuration,
    iconSrc,
    cardType: isPlaylist ? 'playlist-item' : 'library-item'
  };
  return formattedItem;
}

export function getcCompletedItemsDesc(course, translate): string {
  let completedItemsDesc = '';

  if (course) {
    const totalItemsCount = (course && course.numberOfChildObject) || 0;
    const completedChildCount = (course && course.completedChildCount) || 0;
    const itemsCompletedDesc = translate.instant('generic.ITEMS_COMPLETED');

    completedItemsDesc = completedItemsDesc + completedChildCount + '/' + totalItemsCount + ' ' + itemsCompletedDesc;
  }

  return completedItemsDesc;
}

export function getPlace(course, translate): string {
  const itemType = course && (course.itemType || course.type || course.courseType);
  const isOnline = isOnlineInitiative(itemType) || isWebinar(itemType) || isLibraryItem(course);

  if (isOnline) {
    return 'Online';
  } else {
    const locations = (course?.editions?.reduce((acc, curr) => {
      return acc.concat(curr?.days.map((day) => day?.location));
    }, [])) || [];

    return truncateAndReplaceWithDots(
      locations.join(', '), 42
    );
  }
}

export function getNumberOfChildObject(course, translate) {
  let numberOfChildObject = 0;

  if (course) {
    const type = course.type || course.itemType || course.courseType;
    if (libraryContainers.includes(type) && course.numberOfChildObject && course.numberOfChildObject > 0) {
      if (course.numberOfChildObject === 1) {
        course.headerDetail = course.numberOfChildObject + ' ' + translate.instant('generic_card.OBJECT');
      } else {
        course.headerDetail = course.numberOfChildObject + ' ' + translate.instant('generic_card.OBJECTS');
      }
    } else {
      course.numberOfChildObject = 0;
    }
  }

  return numberOfChildObject;
}

export function getContentLabel(val, translate) {
  let childsLabel = '';

  if (val === 1) {
    childsLabel = val + ' ' + translate.instant('generic.CONTENT').toUpperCase();
  } else {
    childsLabel = val + ' ' + translate.instant('generic.CONTENTS').toUpperCase();
  }

  return childsLabel;
}

export function getTranslatedType(course, translate): string {
  let translatedType = '';

  const type = course.type || course.itemType || course.courseType;

  // Oggetti di tipo SCORM e object_type_specialization SCORM_FREE vanno mostrati come corsi online
  if (course.type == ItemAttributeObjectTypes.SCORM && (course.libraryType == "SCORM_FREE" || !course.libraryType)) {
    translatedType = translate.instant('generic.itemTypes.SCORM_FREE_USER');
  } else if (type) {
    translatedType = translate.instant('generic.itemTypes.' + type);
  }

  return translatedType;
}

export function getDuration(course, translate): string {
  let formattedDuration = '';
  if (course) {
    if (course.courseDuration) {
      formattedDuration = translate.instant('generic.DURATION') + ' ' + course.courseDuration;
    } else if (course.minutesValue && course.minutesValue > 0) {
      let durationTime;
      if (course.minutesValue > 60) {
        durationTime = Math.floor(course.minutesValue / 60) + 'h ' + course.minutesValue % 60 + 'm';
      } else {
        if (parseInt(course.minutesValue) == 60) {
          durationTime = '1h';
        } else {
          durationTime = course.minutesValue % 60 + "m";
        }
      }

      if (durationTime) {
        formattedDuration = translate.instant('generic.DURATION') + ' ' + durationTime;
      }
    }
  }

  return formattedDuration;
}

// Ritorna lo stato dell'utente (e anche la relativa icona) di un corso del cm2
export function getCm2UserStatusAndIcon(course, translate): {
  userStatus: string;
  iconSrc: string;
} {
  let iconSrc = '';
  const courseType = course.courseType || course.type || course.itemType;
  const isOnlineCourse = isOnlineInitiative(courseType);

  let translationRef;

  if (isOnlineCourse) {
    translationRef = 'userStatusesForUser.COURSE.';
  } else {
    translationRef = 'userStatusesForUser.ONLINE.';
  }

  if (course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_COMPLETED
    || course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PREREGISTERED
    || course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED
    || course.userStatus === "USER_STATUS_VIEWED"
    || course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_OVERBOOKING_CONFIRMED
    || course.userStatus === "USER_STATUS_INSERTED"
    || course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT
    || course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_SPECTATOR_CONFIRMED
    || course.userStatus === "INS"
    || course.userStatus === "CONF"
    || course.userStatus === "PRESENT"
    || course.userStatus === "PREREGISTERED"
    || course.userStatus === "REGISTERED") {
    iconSrc = 'assets/icons/green-check.svg';
  } else if (course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_INVITED
    || course.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_STARTED
    || course.userStatus === "INVITED"
    || course.userStatus === "SUSP"
    || course.userStatus === "OB-SUSP"
    || course.userStatus === "OB-CONF"
    || course.userStatus === "SP-INS"
    || course.userStatus === "OB-PREREGISTERED"
    || course.userStatus === "SP-CONF"
  ) {
    iconSrc = 'assets/icons/orange-check.svg';
  } else {
    iconSrc = 'assets/icons/icon-minus-outline-new.svg';
  }

  const userStatus = course.userStatus ? translate.instant(translationRef + course.userStatus) : null;

  return {
    userStatus,
    iconSrc
  };
}

// Ritorna lo stato dell'utente (e anche la relativa icona) di un corso library
export function getLibraryItemStatusAndIcon(course, translate): {
  userStatus: string;
  iconSrc: string;
} {
  let iconSrc = '';
  let translationRef = 'generic.libraryItemStatuses.';
  let userStatus;
  if (!!course.userStatus) {
    if (course.userStatus === "COMPLETED") {
      iconSrc = 'assets/icons/green-check.svg';
    } else if (course.userStatus === "STARTED" || course.userStatus === "IN_PROGRESS") {
      iconSrc = 'assets/icons/orange-check.svg';
    } else {
      iconSrc = 'assets/icons/icon-minus-outline-new.svg';
    }
    userStatus = translate.instant(translationRef + course.userStatus);
  } else {
    if (course?.childObject?.engagements?.length) {
      let engagements = course.childObject.engagements;
      let completed = engagements.find(e => e.eventName === ReferenceTypes.EVENT_ITEM_CONSUMED);
      if (completed) {
        iconSrc = 'assets/icons/green-check.svg';
        userStatus = translate.instant(translationRef + "COMPLETED");
      } else {
        let started = engagements.find(e => e.eventName === ReferenceTypes.EVENT_ITEM_STARTED);
        if (started) {
          iconSrc = 'assets/icons/orange-check.svg';
          userStatus = translate.instant(translationRef + "STARTED");
        } else {
          iconSrc = 'assets/icons/icon-minus-outline-new.svg';
          userStatus = translate.instant(translationRef + "TO_START");
        }
      }
    } else {
      iconSrc = 'assets/icons/icon-minus-outline-new.svg';
      userStatus = translate.instant(translationRef + "TO_START");
    }
  }
  // const userStatus = translate.instant(translationRef + course.userStatus);
  // debugger
  return {
    userStatus,
    iconSrc
  };
}

export function getMinutesLabel(val, translate) {
  let textLabel = '';

  if (val == 1) {
    textLabel = textLabel + val + ' ' + translate.instant('generic.MINUTE').toLowerCase();
  } else {
    textLabel = textLabel + val + ' ' + translate.instant('generic.MINUTES').toLowerCase();
  }

  return textLabel;
}

export function getHoursLabel(val, translate) {
  let textLabel = '';

  if (val == 1) {
    textLabel = textLabel + val + ' ' + translate.instant('generic.HOUR').toLowerCase();
  } else {
    textLabel = textLabel + val + ' ' + translate.instant('generic.HOURS').toLowerCase();
  }

  return textLabel;
}

export function getCardCover(course) {
  if (course) {
    let alreadyAddedImg = course.courseImage || course.cardCover;
    if (!alreadyAddedImg && !libraryItems.includes(course.type) && !libraryContainers.includes(course.type)) {
      if (course.isWeLearn) {
        alreadyAddedImg = "../../../assets/img/img-card/learning-plan/learning-plan-4.png";
      } else if (course.clusterName) {
        alreadyAddedImg = '../../../assets/img/img-card/img-clusters/' + course.clusterColor + '.png';
      } else {
        alreadyAddedImg = '../../../assets/img/img-card/default-courses/' + course.type + '.png';
      }
    }

    return alreadyAddedImg;
  }
}

export function truncateAndReplaceWithDots(text: string, maxChars: number): string {
  if (text && (text.length > maxChars)) {
    return text.substring(0, maxChars - 3) + '...';
  }
  return text;
}

// Controlla se è un corso del cm2
export function isCm2Course(course) {
  if (course) {
    let courseRef;
    if (course.constructor === Array) {
      courseRef = course[0];
    } else {
      courseRef = course;
    }

    if (courseRef) {
      const itemType = courseRef.itemType || courseRef.type || courseRef.courseType;
      return isSyllabusCourse(itemType) || isCourse(itemType) || isAssessment(itemType) || isExternalCourse(itemType) || isExternalEvent(itemType) || isWebinar(itemType) || isCatalogStage(itemType) || isCascadingMeeting(itemType);
    }
  }
}

// Controlla se è un corso dal web
export function isWebCourse(course) {
  if (course) {
    let courseRef;
    if (course.constructor === Array) {
      courseRef = course[0];
    } else {
      courseRef = course;
    }

    if (courseRef) {
      return 'courseHostingPlatform' in courseRef || (courseRef.itemType && courseRef.itemType === "OF_COURSE_ME_COURSE");
    }
  }
}

// Controlla se una collezione dal web
export function isWebCollection(course) {
  if (course) {
    let courseRef;
    if (course.constructor === Array) {
      courseRef = course[0];
    } else {
      courseRef = course;
    }

    if (courseRef) {
      return 'collectionId' in courseRef;
    }
  }
}

export function isLibraryItem(course) {
  if (course) {
    let courseRef;
    if (course.constructor === Array) {
      courseRef = course[0];
    } else {
      courseRef = course;
    }

    if (courseRef) {
      const itemType = courseRef.itemType || courseRef.type || courseRef.courseType || courseRef.objectTypeSpecialization || courseRef.objectType || getItemObjectSubType(courseRef);
      return isLibraryAtomicItem(itemType) || isLearningPlan(itemType) || isProject(itemType) || isImage(itemType) || isPodcast(itemType) || isBook(itemType) || isEBook(itemType) || isGraph(itemType) || isMagazine(itemType) || isDvd(itemType) || isDocument(itemType) || isVideo(itemType) || isItemOtherOrPhysicalType(itemType) || isSurveyItem(itemType) || isCertificateItem(itemType);
    }
  }
}

// Verifica se la tipologia è di tipo 'altro' (ebook, sitografia, documento)
export function isOtherType(itemType: string) {
  if (
    itemType &&
    (itemType === ItemAttributeObjectTypes.EBOOK ||
      itemType === ItemAttributeObjectTypes.GRAPH ||
      itemType === ItemAttributeObjectTypes.DOCUMENT)
  ) {
    return true;
  }

  return false;
}

export function isCertificateItem(itemType) {
  return itemType === ItemTypes.CERTIFICATE_ITEM;
}

export function isSurveyItem(itemType) {
  return itemType && itemType === ItemTypes.SURVEY_ITEM;
}

// Verifica se l'oggetto rientra nella tipologia di un oggetto fisico o "altro"
export function isItemOtherOrPhysicalType(itemType) {
  return isPhysicalItem(itemType) || isOtherTypeItem(itemType);
}

// Verifica se l'oggetto rientra nella tipologia "altro", quindi se è un ebook, un documento o una sitografia
export function isOtherTypeItem(itemType) {
  return (
    itemType &&
    (itemType === ItemAttributeObjectTypes.EBOOK ||
      itemType == ItemAttributeObjectTypes.GRAPH ||
      itemType == ItemAttributeObjectTypes.DOCUMENT)
  );
}

// Verifica se l'oggetto rientra nella tipologia dell'immagine
export function isImage(itemType) {
  return itemType === ItemAttributeObjectTypes.IMAGE;
}

// Verifica se l'oggetto rientra nella tipologia podcast
export function isPodcast(itemType) {
  return itemType === ItemAttributeObjectTypes.PODCAST;
}

// Verifica se l'oggetto rientra nella tipologia del video
export function isVideo(itemType) {
  return itemType === ItemAttributeObjectTypes.VIDEO;
}

// Verifica se l'oggetto rientra nella tipologia dei progetti
export function isProject(itemType) {
  return itemType === ItemAttributeObjectTypes.PROJECT;
}

// Verifica se l'oggetto rientra nella tipologia dei learning plan
export function isLearningPlan(itemType) {
  return itemType == ItemAttributeObjectTypes.LEARNING_PLAN;
}

// Verifica se l'oggetto rientra nella tipologia di oggetti fisici, quindi se è un libro, un dvd o una rivista
export function isPhysicalItem(itemType) {
  return (
    itemType &&
    (itemType === ItemAttributeObjectTypes.BOOK ||
      itemType == ItemAttributeObjectTypes.DVD ||
      itemType == ItemAttributeObjectTypes.MAGAZINE)
  );
}

// Verifica se l'oggetto rientra nella tipologia di DVD
export function isDvd(itemType) {
  return itemType && itemType === ItemAttributeObjectTypes.DVD;
}

// Verifica se l'oggetto rientra nella tipologia di documento
export function isDocument(itemType) {
  return itemType && itemType === ItemAttributeObjectTypes.DOCUMENT;
}

// Verifica se l'oggetto rientra nella tipologia di rivista
export function isMagazine(itemType) {
  return itemType && itemType === ItemAttributeObjectTypes.MAGAZINE;
}

// Verifica se l'oggetto rientra nella tipologia di libro
export function isBook(itemType) {
  return itemType && itemType === ItemAttributeObjectTypes.BOOK;
}

// Verifica se l'oggetto rientra nella tipologia di un eBook
export function isEBook(itemType) {
  return itemType && itemType === ItemAttributeObjectTypes.EBOOK;
}

// Verifica se l'oggetto rientra nella tipologia di una sitografia
export function isGraph(itemType) {
  return itemType && itemType === ItemAttributeObjectTypes.GRAPH;
}

// Verifica se l'oggetto rientra nella tipologia dei multimedia (video, immagine, podcast)
export function isMultimediaItem(itemType: string) {
  if (
    itemType &&
    (itemType === ItemAttributeObjectTypes.VIDEO ||
      itemType == ItemAttributeObjectTypes.IMAGE ||
      itemType == ItemAttributeObjectTypes.PODCAST)
  ) {
    return true;
  }
  return false;
}

export function isLibraryAtomicItem(itemType: string): boolean {
  return itemType && LIBRARY_ITEM_OBJECT_TYPE_SPECIALIZATIONS_WITHOUTH_CONTAINRS.includes(itemType);
}

export function getAttributeByKey(item, key: string) {
  if (!item || !item.itemAttributes) {
    return null;
  }

  for (
    let i = 0, attrsLength = item.itemAttributes.length;
    i < attrsLength;
    i++
  ) {
    const currentAttribute = item.itemAttributes[i];
    if (
      currentAttribute &&
      currentAttribute.attributeType &&
      currentAttribute.attributeType === key
    ) {
      return currentAttribute;
    }
  }
  return null;
}

export function getItemObjectSubType(item) {
  let objectTypeSpecializationAttribute = getAttributeByKey(
    item,
    ItemAttributeTypes.OBJECT_TYPE_SPECIALIZATION
  );
  let objectTypeAttribute = getAttributeByKey(
    item,
    ItemAttributeTypes.OBJECT_TYPE
  );
  let itemType = objectTypeAttribute && objectTypeAttribute.attributeValue;
  let subtype =
    objectTypeSpecializationAttribute &&
    objectTypeSpecializationAttribute.attributeValue;
  if (!subtype && itemType) {
    subtype = itemType;
  }
  return subtype;
}

export function isExternalCourse(itemType: string): boolean {
  return itemType && (itemType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE || itemType === ItemTypes.EXTERNAL_ONLINE_STAGE);
}

export function isExternalEvent(itemType: string): boolean {
  return itemType && (itemType === ItemTypes.EVENT_CLASS_STAGE || itemType === ItemTypes.EVENT_ONLINE_STAGE);
}

export function isSyllabusCourse(itemType: string): boolean {
  return itemType && itemType === ItemTypes.COURSE_SYLLABUS;
}

export function isOnlineInitiative(initiativeType: string): boolean {
  return isOnlineCourseOrEvent(initiativeType) || initiativeType === ItemTypes.ASSESSMENT;
}

export function isOnlineEvent(initiativeType: string): boolean {
  return initiativeType === ItemTypes.EVENT_ONLINE_STAGE;
}

export function isOnlineCourseOrEvent(initiativeType: string): boolean {
  return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE;
}

export function hasLinkedLibraryItem(initiativeType: string): boolean {
  return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_ONLINE_STAGE;
}

export function isAssessment(initiativeType: string): boolean {
  return initiativeType === ItemTypes.ASSESSMENT;
}

export function isCatalogStage(initiativeType: string): boolean {
  return initiativeType === "CATALOG_STAGE";
}

export function isEvent(initiativeType: string): boolean {
  return initiativeType === ItemTypes.EVENT_ONLINE_STAGE || initiativeType === ItemTypes.EVENT_CLASS_STAGE;
}

export function isCourse(initiativeType: string): boolean {
  return initiativeType === ItemTypes.COURSE_ONLINE_STAGE || initiativeType === ItemTypes.COURSE_CLASS_STAGE || initiativeType === ItemTypes.EXTERNAL_ONLINE_STAGE || initiativeType === ItemTypes.EXTERNAL_COURSE_CLASS_STAGE;
}

export function isCourseOnlineStage(initiativeType: string): boolean {
  return initiativeType === ItemTypes.COURSE_ONLINE_STAGE;
}

export function isWebinar(initiativeType: string): boolean {
  return initiativeType === ItemTypes.WEBINAR;
}

export function isCascadingMeeting(initiativeType: string): boolean {
  return initiativeType === ItemTypes.CASCADING_MEETING;
}

export function isStage(initiativeType: string): boolean {
  return isAssessment(initiativeType) || isCourse(initiativeType) || isEvent(initiativeType) || isWebinar(initiativeType);
}

export function hasValuesAndBehavioursAccess(loggedUser) {
  let canView: boolean = false;
  if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
    for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
      let currentAuth = loggedUser.auths[i];
      if (currentAuth === RequiredAuth.COURSEMANAGER_VALUES_AND_BEHAVIOURS) {
        canView = true;
        break;
      }
    }
    return canView;
  }
}
