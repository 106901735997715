import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemAttributeTypes, SurveyAttributeTypes, SurveyTypes } from 'atfcore-commonclasses';
import { GlpItem } from '../utils/item-details.utils';

@Component({
  selector: 'app-surveys-tab',
  templateUrl: './surveys-tab.component.html',
  styleUrls: ['./surveys-tab.component.scss']
})
export class SurveysTabComponent {

  /** Se true, i dati stanno ancora venendo recuperati */
  @Input() isGettingItemDetails: boolean = false;

  @Input() isLoadingEditions: boolean = false;

  @Input() editionsSurvey: any[];
  @Input() editionsListWithPastEditions: any[];

  @Input() isAssessment: boolean;
  @Input() itemDetails: GlpItem;

  @Input() stopDateCourseSubscriptionFormat: string;

  @Output() onGoToCourseSurvey = new EventEmitter<any>();

  constructor(private translate: TranslateService) { }

  editionTrackBy(index, item) {
    return (item.itemId);
  }


  /** Torna l'edizione di un assessment */
  getEditionOfAssessment(assessment) {
    // Se sono nel dettaglio di un assessment, 'editions' conterrà l'edizione della rilevazione; così, devo controllare nel contenitore contenente tutte le edizioni
    //let itemRef = this.isAssessment ? this.editionsListWithPastEditions : this.editions;
    let itemRef = this.editionsListWithPastEditions;
    if (assessment && itemRef && itemRef.length) {
      let editions = itemRef.filter((currentEdition: any) => {
        let addEdition;
        if (currentEdition && currentEdition.assessmentIds && currentEdition.assessmentIds.length) {
          for (let l = 0, idsLength = currentEdition.assessmentIds.length; l < idsLength; l++) {
            const currentId = currentEdition.assessmentIds[l] && currentEdition.assessmentIds[l].itemId;
            addEdition = currentId && (currentId === assessment.stageItemId || currentId === assessment.itemId);
            if (addEdition) {
              break;
            }
          }
        }
        return addEdition;
      });
      // Se è una rilevazione e non ho trovato nessuna iniziativa associata significa che è una rilevazione a sè stante
      if (this.isAssessment && (!editions || !editions.length) && itemRef && itemRef.length) {
        //  return itemRef[0];
        // I dati come isSurveyPassed ecc sono nell'itemDetails se sono nel dettaglio di una rilevazione a sé stante
        return this.itemDetails;
      }
      return editions && editions[0];
    }
  }

  getAssementEditionDetailsText(edition) {
    return this.getEditionOfAssessment(edition).customPlace ||
      this.getEditionOfAssessment(edition).onlineEventLinkTitle || '--';
  }

  // Ritorna l'etichetta di ripetibilità
  getAssessmentOfEditionNotRepeateableLabel(itemRef, index) {
    if (itemRef && itemRef.survey && itemRef.survey.surveyType && itemRef.survey.surveyType === SurveyTypes.QUALITY_OF_SERVICE_SURVEY) {
      return "-";
    }

    if (itemRef && itemRef.survey && itemRef.survey.surveyAttributes && itemRef.survey.surveyAttributes.length) {
      for (let attribute of itemRef.survey.surveyAttributes) {
        if (attribute.attributeType == SurveyAttributeTypes.IS_SURVEY_NOT_REPEATABLE && attribute.attributeValue == 'true') {
          return this.translate.instant("generic.NO");
        }
      }
    } else if (index && itemRef && itemRef.assessmentIds && itemRef.assessmentIds.length) {
      index = index.split('survey.')[1];
      let assessmentTmp;
      for (let i = 0; i < itemRef.assessmentIds.length; i++) {
        if (itemRef.assessmentIds[i] && itemRef.assessmentIds[i].itemAttributes && itemRef.assessmentIds[i].itemAttributes.length) {
          let tmp = itemRef.assessmentIds[i].itemAttributes.filter((attr) => attr.attributeType == ItemAttributeTypes.SURVEY_TYPE && attr.attributeValue == index);
          if (tmp && tmp[0]) {
            assessmentTmp = itemRef.assessmentIds[i];
            break;
          }
        }
      }
      if (assessmentTmp && assessmentTmp.itemAttributes && assessmentTmp.itemAttributes.length) {
        let isSurveyNotRepeteable = assessmentTmp.itemAttributes.filter((attr) => attr.attributeType === ItemAttributeTypes.IS_SURVEY_NOT_REPEATABLE);
        if (isSurveyNotRepeteable && isSurveyNotRepeteable[0]) {
          isSurveyNotRepeteable = isSurveyNotRepeteable[0];
          if (isSurveyNotRepeteable) {
            return this.translate.instant("generic.NO");
          }
        }
      }
    }
    return this.translate.instant("generic.YES");
  }

  goToCourseSurvey(editionSurvey: any) {
    this.onGoToCourseSurvey.emit(editionSurvey);
  }
}
