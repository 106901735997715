import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'custom-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomButtonComponent implements OnInit {

  @Input() type?: string;
  @Input() text?: string;
  @Input() disabled?: boolean;
  @Input() ariaLabel?: string;
  @Input() textUppercase?: boolean;
  @Input() whiteSpaceNormal?: boolean;
  @Input() noBorder?: boolean;
  @Input() iconLeft: string = '';

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      const element = document.getElementById(this._id);
      if (element) {
        element.setAttribute("tabindex", value);
      }
    }
  };

  _importance: string = "primary";
  @Input() set importance(value: string) {
    if (value && value.length) {
      this._importance = value;
    } else {
      this._importance = 'primary';
    }

    this.getBtnClasses();
  };

  _additionalContainerClasses;
  @Input() set additionalContainerClasses(value: string) {
    if (value) {
      this._additionalContainerClasses = value;
    }
  };

  @Input() points: number | any = 0;

  _id: string;
  @Input() id: string | number;

  @Output('onClick') onButtonClicked: EventEmitter<any> = new EventEmitter();

  buttonClasses: string = '';

  constructor(
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    if (!this.type) {
      this.type = 'button';
    }
    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "custom-button-" + this.id;

    this.getBtnClasses();
  }

  getBtnClasses() {
    this.buttonClasses = this._importance + ' ' + this._additionalContainerClasses + ' ' + (this.noBorder ? 'no-border' : '');
  }

  emitOnButtonClicked(data?) {
    if (!this.disabled) {
      this.onButtonClicked.emit(data);
    }
  }
}
