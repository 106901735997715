<!-- Tab dettaglio corso cm2 -->
<ng-container *ngIf="isSyllabusCourse">
    <div class="course-details" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="start">

        <div class="course-page-tab-info-text" fxLayout="column">
            <!-- Modalità di iscrizione (solo per i corsi esterni con posti limitati) -->
            <div *ngIf="itemDetails && isExternalCourse" class="content-section-wrapper subscription-mode-title"
                fxLayout="column">
                <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <p tabindex="0" translate="itemDetailsPage.SUBSCRIPTION_MODE"></p>
                </div>
                <div class="section-content">
                    <span tabindex="0"
                        [innerHTML]="('itemDetailsPage.EXTERNAL_COURSE_DEFAULT_TEXT_DESCRIPTION' | translate) | safeHtml: 'html'"></span>
                    <span tabindex="0"
                        [innerHTML]="('itemDetailsPage.EXTERNAL_COURSE_SECOND_DEFAULT_TEXT_DESCRIPTION' | translate) | safeHtml: 'html'"></span>
                    <span tabindex="0" *ngIf="isCourseWithLimitedSeats"
                        [innerHTML]="('itemDetailsPage.EXTERNAL_COURSE_DEFAULT_NOTE_TEXT_DESCRIPTION' | translate)  | safeHtml: 'html'"></span>
                </div>
            </div>

            <!-- Programma -->
            <div *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.AGENDA"
                class="content-section-wrapper agenda-title margin-bottom24" fxLayout="column">
                <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <p tabindex="0" translate="itemDetailsPage.contents.PROGRAM"></p>
                </div>
                <div class="section-content">
                    <span tabindex="0" [innerHTML]="itemDetails.courseSyllabus.AGENDA | safeHtml: 'html'"></span>
                </div>
            </div>

            <!-- Obiettivi -->
            <div *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.AIMS"
                class="content-section-wrapper agenda-title margin-bottom24" fxLayout="column">
                <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <p tabindex="0" translate="itemDetailsPage.contents.OBJECTIVES"></p>
                </div>
                <div class="section-content">
                    <span tabindex="0" [innerHTML]="itemDetails.courseSyllabus.AIMS | safeHtml: 'html'"></span>
                </div>
            </div>

            <!-- A chi si rivolge -->
            <div *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.PREREQS"
                class="content-section-wrapper agenda-title margin-bottom24" fxLayout="column">
                <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <p tabindex="0" translate="itemDetailsPage.contents.TO_WHOM"></p>
                </div>
                <div class="section-content">
                    <span tabindex="0" [innerHTML]="itemDetails.courseSyllabus.PREREQS | safeHtml: 'html'"></span>
                </div>
            </div>

            <!-- Quando termina -->
            <!-- Informazioni su entro quando svolgere il corso online. Non si vede qualora ci fosse il codice IT valorizzato (nel Syllabus) -->
            <div *ngIf="showStartEndDate() && isSyllabusCourse && (!hasMoreEditions && (isOnlineCourse || (isWebinar || (isCatalogStage && editions && editions[0] && editions[0].stageItemType == 'WEBINAR'))) || (hasMoreEditions && isOnlineCourse)) && editions && editions[0]"
                class="content-section-wrapper agenda-title margin-bottom24" fxLayout="column">
                <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <p tabindex="0" translate="itemDetailsPage.END_DUE"></p>
                </div>
                <div class="section-content">
                    <span tabindex="0">{{ ('itemDetailsPage.PERFORM_UNTIL' | translate) + ' ' + (editions[0].endDate |
                        amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat) + '.' }}</span>
                </div>
            </div>
        </div>

        <!-- Box di destra -->
        <div class="right-box">
            <!-- Box con i contenuti della Playlist del corso online -->
            <div class="course-online-container-item hide-lt-md" *ngIf="isContentOfPlaylistVisible">
                <ng-container [ngTemplateOutlet]="playlistChildsList" [ngTemplateOutletContext]="{
      item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails,
      itemLinkedToCourse: itemLinkedToCourse && itemLinkedToCourse.itemId}"></ng-container>
            </div>

            <!-- Box con i contenuti di un progetto del corso online -->
            <div class="course-online-container-item hide-lt-md" *ngIf="isContentOfProjectsVisible">
                <ng-container [ngTemplateOutlet]="projectChildsList" [ngTemplateOutletContext]="{
      item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails,
      itemLinkedToCourse: itemLinkedToCourse && itemLinkedToCourse.itemId}"></ng-container>
            </div>

            <div class="course-details-container" fxLayout="column">
                <!-- Durata -->
                <div *ngIf="duration" fxFlexOffset="24px" fxFlexOffset.xs="20px" fxLayout="row"
                    fxLayoutAlign="start center">
                    <svg-icon src="../../../assets/icons/icon-clock.svg"></svg-icon>
                    <h6 fxFlexOffset="12px">{{ duration }}</h6>
                </div>

                <!-- Tipologia -->
                <div *ngIf="itemTypeLabel" fxFlexOffset="24px" fxFlexOffset.xs="20px" fxLayout="row"
                    fxLayoutAlign="start center">
                    <svg-icon src="../../../assets/icons/icon-map-pin.svg"></svg-icon>
                    <h6 fxFlexOffset="12px">{{ itemTypeLabel || '' }}</h6>
                </div>

                <!-- Informazioni sull'edizione selezionata -->
                <ng-container *ngIf="!isOnlineCourse || isWebinar">
                    <ng-container *ngIf="selectedEditionOfMainSelect || (editions && editions[0])"
                        [ngTemplateOutlet]="selectedEditionInfo" [ngTemplateOutletContext]="{
          item: selectedEditionOfMainSelect || editions[0]
        }"></ng-container>
                </ng-container>

                <!-- Numero di posti disponibili dell'edizione selezionata -->
                <div *ngIf="selectedEditionOfMainSelect" fxFlexOffset="24px" fxFlexOffset.xs="20px" fxLayout="row"
                    fxLayoutAlign="start start">
                    <svg-icon src="../../../assets/icons/people-icon.svg"></svg-icon>
                    <div fxLayout="column" class="margin-left12">
                        <h6 *ngIf="selectedEditionOfMainSelect.availableSeats">{{
                            selectedEditionOfMainSelect.availableSeats + ' ' +
                            ( selectedEditionOfMainSelect.availableSeats === 1 ?
                            ('itemDetailsPage.AVAILABLE_SEAT' | translate | lowercase) :
                            ('itemDetailsPage.AVAILABLE_SEATS' | translate | lowercase))
                            }}</h6>
                        <h6 *ngIf="selectedEditionOfMainSelect.availableSeatsOB">{{
                          selectedEditionOfMainSelect.availableSeatsOB + ' ' +
                          ( selectedEditionOfMainSelect.availableSeatsOB === 1 ?
                          ('itemDetailsPage.AVAILABLE_SEAT_OVERBOOKING' | translate | lowercase) :
                          ('itemDetailsPage.AVAILABLE_SEATS_OVERBOOKING' | translate | lowercase))
                          }}</h6>
                        <p *ngIf="(!selectedEditionOfMainSelect.availableSeats || selectedEditionOfMainSelect.availableSeats == 0) &&
                        (!selectedEditionOfMainSelect.availableSeatsOB || selectedEditionOfMainSelect.availableSeatsOB == 0)"
                            fxFlexOffset="5px">{{ 'itemDetailsPage.NO_AVAILABLE_SEAT' | translate }}</p>
                         <p *ngIf="!selectedEditionOfMainSelect.availableSeatsOB || selectedEditionOfMainSelect.availableSeatsOB == 0"
                            fxFlexOffset="5px">{{ 'itemDetailsPage.NO_AVAILABLE_SEAT_OVERBOOK' | translate }}</p>
                    </div>
                </div>

                <!-- Scelta della giornata e del webinar -->
                <div *ngIf="!isGettingItemDetails && itemDetails && (isWebinar || (isCatalogStage && editions && editions[0] && editions[0].stageItemType == 'WEBINAR')) &&
          ((webinarCoursesByDay && webinarCoursesByDay.length) || isLoadingEditions)" fxFlexOffset.xs="20px"
                    fxLayout="column" fxLayoutAlign="center start">
                    <!-- Select per scegliere la data -->
                    <!-- <ng-container *ngIf="webinarCoursesByDay && webinarCoursesByDay.length && webinarSelectedDay">
                        <h5 class="lighter margin-top40" translate="onlineMeeting.SELECT_DAY"></h5>
                        <custom-select class="margin-top12 course-edition-select full-width" [placeholderLabel]="''"
                            [selectedValue]="webinarSelectedDay" (onSelectionChanged)="changeWebinarSelectedDay($event)"
                            [items]="webinarCoursesByDay" bindValue="dayLabel" bindLabel="dayLabel"
                            [selectedOptionMaxLength]="28">
                        </custom-select>
                    </ng-container> -->

                    <!-- Select per scegliere l'ora e l'orario -->
                    <ng-container
                        *ngIf="webinarSelectedDay && selectedCourseDateId && currentWebinarEditions && currentWebinarEditions.length && (itemDetails?.multipleEditionAttendance || !isSubscribedToAnEdition)">
                        <h5 class="lighter margin-top40">{{ 'onlineMeeting.SELECT_EDITION' | translate }}</h5>
                        <custom-select class="margin-top12 course-edition-select height-webinar-select full-width" placeholderLabel=""
                            [selectedValue]="selectedEditionOfMainSelect"
                            (onSelectionChanged)="selectWebinarEdition($event)" [items]="currentWebinarEditions"
                            bindValue="courseDateId" bindLabel="labelForSelectOption" [isCm2EditionsSelect]="true"
                            [isSecondSelectEdition]="true" [selectedOptionMaxLength]="37">
                        </custom-select>
                    </ng-container>
                </div>

                <!-- Scelta luogo ed edizione (solo se ho più di una edizione) -->
                <div *ngIf="isSyllabusCourse && hasMoreEditions && currentPlaceEditions && currentPlaceEditions.length && currentPlaceEditions.length > 0
            && !isLoadingEditions && (itemDetails?.multipleEditionAttendance || !isSubscribedToAnEdition)" fxFlexOffset.xs="20px" fxLayout="column" fxLayoutAlign="center start">
                    <!-- Select per scegliere il luogo dell'edizione -->
                    <!-- <h5 class="lighter margin-top40" translate="itemDetailsPage.edition.CHOOSE_PLACE"></h5>
                    <custom-select class="margin-top12 course-edition-select full-width" [placeholderLabel]="''"
                        [selectedValue]="selectedPlace" (onSelectionChanged)="selectPlace($event)"
                        [items]="editionsByPlace" bindValue="placeId" bindLabel="placeId" [disabled]="isLoadingEditions"
                        [selectedOptionMaxLength]="28">
                    </custom-select> -->

                    <!-- Select per scegliere l'edizione del luogo selezionato -->
                    <h5 *ngIf="currentPlaceEditions.length > 0" class="lighter margin-top40">{{('itemDetailsPage.edition.CHOOSE_EDITION' | translate)}}</h5>
                    <custom-select *ngIf="currentPlaceEditions.length > 0"
                        class="margin-top12 course-edition-select height-md full-width" placeholderLabel=""
                        [selectedValue]="selectedEditionOfMainSelect"
                        (onSelectionChanged)="selectEditionFromSelect($event)" [items]="currentPlaceEditions"
                        bindValue="courseDateId" bindLabel="labelForSelectOption" [disabled]="isLoadingEditions"
                        [isCm2EditionsSelect]="true" [isSecondSelectEdition]="true" [selectedOptionMaxLength]="58">
                    </custom-select>
                </div>

                <!-- Metodologia e approccio -->
                <div *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.APPROACH" fxFlexOffset.xs="20px"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.contents.APPROACH"></h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <h6 [innerHTML]="itemDetails.courseSyllabus.APPROACH | safeHtml: 'html'"></h6>
                    </div>
                </div>

                <!-- Partner (con chi) -->
                <div *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.PARTNER" fxFlexOffset.xs="20px"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.contents.WITH_WHOM"></h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <h6 [innerHTML]="itemDetails.courseSyllabus.PARTNER | safeHtml: 'html'"></h6>
                    </div>
                </div>

                <!-- Lista di tech skill -->
                <ng-container
                    *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.TECH_COMPETENCES && itemDetails.courseSyllabus.TECH_COMPETENCES.length">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.contents.TECH_COMPETENCES"></h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <generali-badge *ngFor="let techCompetence of itemDetails.courseSyllabus.TECH_COMPETENCES"
                            [title]="techCompetence" type="white">
                        </generali-badge>
                    </div>
                </ng-container>

                <!-- Lista di soft skill -->
                <ng-container
                    *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.SOFT_COMPETENCES && itemDetails.courseSyllabus.SOFT_COMPETENCES.length">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.contents.SOFT_COMPETENCES"></h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <generali-badge *ngFor="let softCompetence of itemDetails.courseSyllabus.SOFT_COMPETENCES"
                            [title]="softCompetence" type="white">
                        </generali-badge>
                    </div>
                </ng-container>

                <!-- Lista di categorie associate (attualmente solo una) -->
                <ng-container *ngIf="itemDetails && itemDetails.argumentTitle && itemDetails.argumentTitle.length">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.chips.TOPICS">
                    </h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <generali-badge [title]="itemDetails.argumentTitle" type="white">
                        </generali-badge>
                    </div>
                </ng-container>

                <!-- Lista di Wave -->
                <!-- <ng-container
                    *ngIf="itemDetails.courseSyllabus && itemDetails.courseSyllabus.WAVE && itemDetails.courseSyllabus.WAVE.length">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.contents.WAVE">
                    </h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">

                        <generali-badge *ngFor="let wave of itemDetails.courseSyllabus.WAVE" [title]="wave"
                            type="white">
                        </generali-badge>
                    </div>
                </ng-container> -->

                <!-- Link di iscrizione, se presente nell'unica edizione disponibile  -->
                <div *ngIf="!hasMoreEditions && editions && editions[0] && editions[0].subscriptionCustomLink"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="generic.SUBSCRIPTION_LINK">
                    </h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <p class="cursor-pointer text-underline nomargin-nopadding" tabindex="0"
                            (click)="openCustomLink(editions[0].subscriptionCustomLink)">{{
                            editions[0].subscriptionCustomLink
                            }}</p>
                    </div>
                </div>

                <!-- Email di iscrizione, se presente nell'unica edizione disponibile  -->
                <div *ngIf="!hasMoreEditions && editions && editions[0] && editions[0].subscriptionCustomMail"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="generic.SUBSCRIPTION_MAIL">
                    </h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <h6>
                            <a class="email trunc" [tooltip]="editions[0].subscriptionCustomMail"
                                href="mailto:{{editions[0].subscriptionCustomMail}}">{{
                                editions[0].subscriptionCustomMail || ''
                                }}
                            </a>
                        </h6>
                    </div>
                </div>

                <!-- Email di informazioni, se presente nell'unica edizione disponibile  -->
                <div *ngIf="!hasMoreEditions && editions && editions[0] && editions[0].subscriptionInfoMail"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px" translate="generic.INFORMATION_MAIL">
                    </h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <h6>
                            <a class="email trunc" [tooltip]="editions[0].subscriptionInfoMail"
                                href="mailto:{{editions[0].subscriptionInfoMail}}">{{
                                editions[0].subscriptionInfoMail || '' }}
                            </a>
                        </h6>
                    </div>
                </div>

                <!-- Docenti delle giornate dell'unica edizione disponibile  -->
                <div *ngIf="!hasMoreEditions && editions && editions[0] && editions[0].teachers && editions[0].teachers.length"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px" translate="generic.TEACHERS">
                    </h5>
                    <div class="full-width" fxFlexOffset="12px">
                        <div *ngFor="let teacher of editions[0].teachers; let first = first;" fxLayout="column">
                            <h6 [ngClass]="{ 'margin-top15' : !first }">{{ (teacher.reference &&
                                teacher.reference.surname ||
                                '') + ' ' + (teacher.reference &&
                                teacher.reference.forename || '') + ' ' }}</h6>
                            <h6>
                                <a class="email trunc" [tooltip]="teacher.reference.email"
                                    *ngIf="teacher.reference && teacher.reference.email"
                                    href="mailto:{{teacher.reference.email}}">{{
                                    teacher.reference.email || '' }}
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>

                <!-- Tutor delle giornate dell'unica edizione disponibile  -->
                <div *ngIf="!hasMoreEditions && editions && editions[0] && editions[0].tutors && editions[0].tutors.length"
                    fxLayout="column" fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px" translate="generic.TUTORS">
                    </h5>
                    <div class="full-width" fxFlexOffset="12px">
                        <div *ngFor="let tutor of editions[0].tutors; let first = first;" fxLayout="column">
                            <h6 [ngClass]="{ 'margin-top15' : !first }">{{ (tutor.reference && tutor.reference.surname
                                ||
                                '') + ' ' + (tutor.reference &&
                                tutor.reference.forename || '') + ' ' }}</h6>
                            <h6>
                                <a class="email trunc" [tooltip]="tutor.reference.email"
                                    *ngIf="tutor.reference && tutor.reference.email"
                                    href="mailto:{{tutor.reference.email}}">{{
                                    tutor.reference.email || '' }}
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>

                <!-- Titolo/link dell'evento online -->
                <div *ngIf="showTitleAndLinkOfOnlineEvent()" fxLayout="column" fxLayoutAlign="center start">
                    <h5 *ngIf="editions[0].onlineEventLinkTitle && editions[0].onlineEventLinkTitle.length"
                        class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px">{{ editions[0].onlineEventLinkTitle
                        }}</h5>

                    <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <button *ngIf="editions[0].onlineEventLink && editions[0].onlineEventLink.length" tabindex="0"
                            class="cursor-pointer text-underline nomargin-nopadding"
                            (click)="openCustomLink(editions[0].onlineEventLink)">
                            {{ editions[0].onlineEventLink || ''}}
                        </button>
                    </div>
                </div>

                <!-- Tutor delle giornate dell'unica edizione disponibile  -->
                <div *ngIf="itemDetails.pmList && itemDetails.pmList.length" fxLayout="column"
                    fxLayoutAlign="center start">
                    <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                        translate="itemDetailsPage.contents.PM_LIST">
                    </h5>
                    <div class="full-width" fxFlexOffset="12px">
                        <div *ngFor="let pm of itemDetails.pmList; let first = first;" fxLayout="column">
                            <h6 [ngClass]="{ 'margin-top15' : !first }">{{pm.surname + ' ' + pm.forename + ' ' }}</h6>
                            <h6>
                                <a class="email trunc" [tooltip]="pm.email" *ngIf="pm.email"
                                    href="mailto:{{pm.email}}">{{pm.email}}
                                </a>
                            </h6>
                        </div>
                    </div>
                </div>

                <!-- Valutazione -->
                <ng-container [ngTemplateOutlet]="valuationBox" [ngTemplateOutletContext]="{
        item: itemDetails
      }" class="hide-lt-md"></ng-container>
            </div>

        </div>
    </div>
</ng-container>

<!-- Tab dettaglio oggetto library isolato -->
<ng-container *ngIf="!isGettingParentDetails && itemDetails && !isSyllabusCourse && !collectionId">
    <div class="course-details" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="start">

        <!-- Descrizione oggetti Library -->
        <div *ngIf="!webCourseId" class="course-page-tab-info-text" fxLayout="column">
            <div *ngIf="itemDetails && itemDetails.description && itemDetails.description.length"
                class="content-section-wrapper" fxLayout="column">
                <div class="section-content">
                    <p tabindex="0" [innerHTML]="itemDetails.description | safeHtml: 'html'" class="description-text">
                    </p>
                </div>
            </div>
        </div>

        <!-- Descrizione corsi dal web -->
        <div *ngIf="webCourseId" class="course-page-tab-info-text web-course-details" fxLayout="column">
            <!-- Syllabus -->
            <div *ngIf="itemDetails.webCourseSyllabus" class="content-section-wrapper" fxLayout="column">
                <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <p tabindex="0" translate="itemDetailsPage.contents.PROGRAM"></p>
                </div>
                <div class="section-content">
                    <span tabindex="0" [innerHTML]="itemDetails.webCourseSyllabus | safeHtml: 'html'"></span>
                </div>
            </div>

        </div>

        <div fxLayout="column">
            <!-- Box con i contenuti della Playlist -->
            <div class="hide-lt-md full-width">
                <ng-container *ngIf="isContentOfPlaylistVisible" [ngTemplateOutlet]="playlistChildsList"
                    [ngTemplateOutletContext]="{
          item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails
        }" class="hide-lt-md"></ng-container>

                <!-- Box con i contenuti di un progetto -->
                <ng-container *ngIf="isContentOfProjectsVisible" [ngTemplateOutlet]="projectChildsList"
                    [ngTemplateOutletContext]="{
          item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails
        }" class="hide-lt-md"></ng-container>
            </div>

            <!-- Box di destra -->
            <div fxLayout="row">
                <div class="course-details-container" fxLayout="column">
                    <!-- Oggetti completati della playlist (oggetti singoli) o del progetto (oggetti delle sezioni) -->
                    <div *ngIf="isContentOfPlaylistVisible || isContentOfProjectsVisible" class="margin-bottom24"
                        fxLayout="row" fxLayoutAlign="start center">
                        <svg-icon src="../../../assets/icons/notification/icon-flag.svg"></svg-icon>
                        <h6 *ngIf="!itemLinkedToCourse" fxFlexOffset="12px">{{ (completedItems || 0) + '/' +
                            getItemChildrenCount(itemDetails) }}
                        </h6>
                        <h6 *ngIf="itemLinkedToCourse && isOnlineCourse" fxFlexOffset="12px">{{ (completedItems || 0) +
                            '/' +
                            getItemChildrenCount(itemLinkedToCourse) }}
                        </h6>
                    </div>

                    <!-- Durata -->
                    <div *ngIf="duration" fxLayout="row" fxLayoutAlign="start center">
                        <svg-icon src="../../../assets/icons/icon-clock.svg"></svg-icon>
                        <h6 fxFlexOffset="12px">{{ duration }}</h6>
                    </div>

                    <!-- Tipologia (online/in aula) -->
                    <div *ngIf="webCourseId" fxFlexOffset="24px" fxFlexOffset.xs="20px" fxLayout="row"
                        fxLayoutAlign="start center">
                        <svg-icon src="../../../assets/icons/icon-map-pin.svg"></svg-icon>
                        <h6 fxFlexOffset="12px">{{ itemTypeLabel || '' }}</h6>
                    </div>

                    <!-- Tipologia (online/in aula) -->
                    <div *ngIf="!webCourseId" fxFlexOffset="24px" fxFlexOffset.xs="20px" fxLayout="row"
                        fxLayoutAlign="start center">
                        <svg-icon src="../../../assets/icons/icon-map-pin.svg"></svg-icon>
                        <h6 fxFlexOffset="12px">{{ isItemOnline ? ('generic.ONLINE' |
                            translate) : ('generic.IN_ROOM' | translate) }}</h6>
                    </div>

                    <!-- Piattaforma del corso dal web -->
                    <ng-container *ngIf="webCourseId && itemDetails && itemDetails.courseHostingPlatform">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="generic.WEB_COURSE_PLATFORM"></h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <span tabindex="0"
                                [innerHTML]="itemDetails.courseHostingPlatform | safeHtml: 'html'"></span>
                        </div>
                    </ng-container>

                    <!-- Lista di tech skill oggetti library -->
                    <ng-container
                        *ngIf="itemDetails && itemDetails.techCompetences && itemDetails.techCompetences.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.contents.TECH_COMPETENCES"></h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge *ngFor="let techCompetence of itemDetails.techCompetences"
                                [title]="techCompetence" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Lista di soft skill oggetti Library -->
                    <ng-container
                        *ngIf="itemDetails && itemDetails.softCompetences && itemDetails.softCompetences.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.contents.SOFT_COMPETENCES"></h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge *ngFor="let softCompetence of itemDetails.softCompetences"
                                [title]="softCompetence" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Approccio (tech/soft, oggetto library) -->
                    <!-- <div *ngIf="itemDetails.isSoftSkill || itemDetails.isTechSkill" class="content-section-wrapper" fxLayout="column">
    <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="circle"></div>
      <p tabindex="0" translate="itemDetailsPage.contents.ITEM_APPROACH"></p>
    </div>
    <div class="section-content">
      <p tabindex="0" *ngIf="itemDetails.isSoftSkill" translate="editItem.approachList.SOFT_SKILLS"></p>
      <p tabindex="0" *ngIf="itemDetails.isTechSkill" translate="editItem.approachList.TECH_SKILLS"></p>
    </div>
  </div> -->


                    <!-- Lista di categorie/argomenti associate (attualmente solo una) -->
                    <ng-container *ngIf="itemDetails && itemDetails.argumentTitle && itemDetails.argumentTitle.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.chips.TOPICS">
                        </h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge [title]="itemDetails.argumentTitle" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Tag cluster (oggetto library)
  <div
    *ngIf="itemDetails.clustersTags && itemDetails.clustersTags.length"
    class="content-section-wrapper" fxLayout="column">
    <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="circle"></div>
      <p translate="itemDetailsPage.chips.TOPICS"></p>
    </div>
    <div class="section-content">
      <span *ngFor="let clustersTag of itemDetails.clustersTags">
        {{clustersTag}}
      </span>
    </div>
  </div> -->

                    <!-- Aree funzionali (oggetto library). Attualmente al massimo 1, quindi non è un array -->
                    <ng-container *ngIf="itemDetails.functionalAreaTag && itemDetails.functionalAreaTag.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.chips.FUNCTIONAL_AREAS">
                        </h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge [title]="itemDetails.functionalAreaTag" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Tag liberi (oggetto library)
  <div *ngIf="itemDetails.adminTag && itemDetails.adminTag.length" class="content-section-wrapper"
    fxLayout="column">
    <div class="section-title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <div class="circle"></div>
      <p translate="itemDetailsPage.chips.ADMIN_TAGS"></p>
    </div>
    <div class="section-content">
      <span>
        {{itemDetails.adminTag || ''}}
      </span>
    </div>
  </div> -->

                    <!-- Livello (oggetto library) -->
                    <ng-container *ngIf="itemDetails.levelTag && itemDetails.levelTag.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.chips.LEVELS">
                        </h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge [title]="itemDetails.levelTag" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Stream (oggetto library). Attualmente al massimo 1, quindi non sarà un array -->
                    <ng-container *ngIf="itemDetails.streamTag && itemDetails.streamTag.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.STREAM_TAG">
                        </h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge [title]="itemDetails.streamTag" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Lingue (oggetto library) -->
                    <ng-container *ngIf="itemDetails.lang && itemDetails.lang.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.LANGS">
                        </h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <generali-badge *ngFor="let langTag of itemDetails.lang" [title]="langTag" type="white">
                            </generali-badge>
                        </div>
                    </ng-container>

                    <!-- Link (oggetto library) -->
                    <ng-container *ngIf="itemDetails.linkUrls && itemDetails.linkUrls.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetail.EXTERNAL_LINKS">
                        </h5>

                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <button tabindex="0" class="cursor-pointer text-underline nomargin-nopadding"
                                (click)="openCustomLink(linkUrl.url)" *ngFor="let linkUrl of itemDetails.linkUrls">
                                {{linkUrl.externalLinkName || linkUrl.url || ''}}
                            </button>
                        </div>
                    </ng-container>

                    <!-- Suggerito da (oggetto library) -->
                    <ng-container *ngIf="itemDetails.suggestedList && itemDetails.suggestedList.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetail.SUGGESTED_BY">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0"
                                *ngFor="let suggestedPerson of itemDetails.suggestedList; let last = last;">
                                {{ (((suggestedPerson.surname + ' ') || '') + (suggestedPerson.forename || '')) + (last
                                ? '' : ',')
                                }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Autore (oggetto library) -->
                    <ng-container *ngIf="itemDetails.author && itemDetails.author.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.AUTHOR">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0" *ngFor="let author of itemDetails.author">
                                {{author}}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Editore (oggetto library) -->
                    <ng-container *ngIf="itemDetails.editor && itemDetails.editor.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.PUBLISHER">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0" *ngFor="let editor of itemDetails.editor">
                                {{editor}}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Anno di pubblicazione (oggetto library) -->
                    <ng-container *ngIf="itemDetails.publishYear && itemDetails.publishYear.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.PUBLISH_YEAR">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.publishYear || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Luogo di pubblicazione (oggetto library) -->
                    <ng-container *ngIf="itemDetails.publishLocation && itemDetails.publishLocation.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.PUBLISH_LOCATION">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.publishLocation || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Paese di pubblicazione (oggetto library) -->
                    <ng-container *ngIf="itemDetails.publishCountry && itemDetails.publishCountry.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.PUBLISH_COUNTRY">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.publishCountry || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Anno edizione (oggetto library) -->
                    <ng-container *ngIf="itemDetails.editionYear && itemDetails.editionYear.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.EDITION_YEAR">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.editionYear || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- ISBN (oggetto library) -->
                    <ng-container *ngIf="itemDetails.isbn && itemDetails.isbn.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px" translate="itemDetail.ISBN">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.isbn || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Regia (oggetto library) -->
                    <ng-container *ngIf="itemDetails.director && itemDetails.director.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.DIRECTOR">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.director || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Catalogo (oggetto library) -->
                    <ng-container *ngIf="itemDetails.shelf && itemDetails.shelf.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.SHELF">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.shelf || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Quantità (oggetto library) -->
                    <ng-container *ngIf="itemDetails.quantity && itemDetails.quantity.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.QUANTITY">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.quantity || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Collocazione (oggetto library) -->
                    <ng-container *ngIf="itemDetails.collocation && itemDetails.collocation.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.COLLOCATION">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.collocation || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Tutor (oggetto library) -->
                    <ng-container *ngIf="itemDetails.tutor && itemDetails.tutor.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.TUTORS">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6 tabindex="0">
                                {{ itemDetails.tutor || '' }}
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Email di supporto per anomalie -->
                    <ng-container *ngIf="itemDetails.helpdeskEmail && itemDetails.helpdeskEmail.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.SUPPORT_MAILS_FOR_PROBLEMS">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6>
                                <span tabindex="0" translate="itemDetailsPage.FOR_COURSE_SUPPORT_WRITE_TO"></span>
                                <a class="email trunc" [tooltip]="itemDetails.helpdeskEmail"
                                    href="mailto:{{itemDetails.helpdeskEmail}}">{{
                                    itemDetails.helpdeskEmail || '' }}
                                </a>
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Email di supporto per informazioni -->
                    <ng-container *ngIf="itemDetails.tutorEmail && itemDetails.tutorEmail.length">
                        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px"
                            translate="itemDetailsPage.SUPPORT_MAILS">
                        </h5>
                        <div class="full-width" fxFlexOffset="12px" fxFlexOffset.xs="4px" fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <h6>
                                <span tabindex="0" translate="itemDetailsPage.FOR_COURSE_INFORMATIONS_WRITE_TO"></span>
                                <a class="email trunc" [tooltip]="itemDetails.tutorEmail"
                                    href="mailto:{{itemDetails.tutorEmail}}">{{ itemDetails.tutorEmail ||
                                    '' }}
                                </a>
                            </h6>
                        </div>
                    </ng-container>

                    <!-- Valutazione -->
                    <ng-container [ngTemplateOutlet]="valuationBox" [ngTemplateOutletContext]="{
            item: itemDetails
          }" class="hide-lt-md"></ng-container>
                </div>
            </div>
        </div>

    </div>
</ng-container>

<!-- Lista di oggetti contenuti nel progetto -->
<ng-template #valuationBox let-item="item">
    <div fxLayout="column" fxLayoutAlign="center start">
        <h5 class="lighter" fxFlexOffset="40px" fxFlexOffset.xs="32px" translate="course-page.detail.RATINGS">
        </h5>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="12px" fxFlexOffset.xs="16px"
            (click)="openFeedbackSurvey()">
            <!-- Stelline valutazione -->
            <div fxLayout="row" fxLayoutAlign="start center">
                <svg-icon class="rating cursor-pointer" *ngFor="let i of [1,2,3,4,5]"
                    [fxFlexOffset]="i === 1 ? '0px' : '6px'"
                    [src]="'../../../assets/icons/icon-rating-star' + (i <= partialScore ? '' : '-empty') + '.svg'">
                </svg-icon>
            </div>
            <!-- Punteggio valutazione -->
            <h6 fxFlexOffset="12px" class="ratings lighter">
                {{ partialScore || 0 }}</h6>
        </div>

        <!-- Il tuo voto -->
        <h6 *ngIf="itemDetails && itemDetails.myJudgement && itemDetails.myJudgement > 0" class="black margin-top10">
            <ng-container *ngIf="itemDetails.myJudgement === 1">
                {{ 'news-page.YOUR_RATING' | translate}}
            </ng-container>
            <ng-container *ngIf="itemDetails.myJudgement !== 1">
                {{ 'news-page.YOUR_RATINGS' | translate: { value: itemDetails.myJudgement || 0 } }}
            </ng-container>
        </h6>
    </div>
</ng-template>

<!-- Modale per inviare un feedback del corso -->
<app-modal class="not-destructive-modal" id="feedbackSurvey">
    <div class="container" fxLayout="column" fxLayoutAlign="start">
        <div fxHide.gt-xs [fxShow.gt-xs]="!surveySended" fxLayout="row" fxLayoutAlign="end center">
            <!-- Icona chiudi -->
            <img role="button" title="Close course code modal" (click)="closeFeedbackSurvey()" height="19" width="17"
                src="../../../assets/icons/icon-times.svg" class="cursor-pointer">
        </div>

        <div *ngIf="!surveySended" fxFlex fxLayout="column" fxLayoutAlign="space-between center">
            <div fxFlex.xs class="full-width" fxLayout="column" fxLayoutAlign="start center">

                <!-- Feedback survey -->
                <h4 fxFlexOffset="19px" fxFlexOffset.xs="16px" class="center lighter"
                    translate="course-page.modal.survey.TITLE"></h4>

                <p fxFlexOffset="78px" fxFlexOffset.xs="80px">
                    <span class="lighter" translate="course-page.modal.survey.DESCRIPTION"></span>
                    <span> {{ itemDetails?.title }}</span>
                    <span class="lighter" translate="course-page.modal.survey.DESCRIPTION2"> </span>
                </p>

                <div fxFlexOffset="32px" fxLayout="row" fxLayoutAlign="start center" (mouseleave)="setSurveyPoints(0)">
                    <svg-icon class="rating cursor-pointer" *ngFor="let i of [1,2,3,4,5]"
                        [fxFlexOffset]="i === 1 ? '0px' : '17px'" src="../../../assets/icons/icon-rating-star-empty.svg"
                        (mouseenter)="setSurveyPoints(i)" [ngClass]="{'fill': fillStar(i)}"
                        (click)="setSurveyPointsSelected(i)">
                    </svg-icon>
                </div>

            </div>

            <!-- Conferma -->
            <custom-button ngClass.xs="full-width" fxFlexOffset="10px" [text]="('generic.CONFIRM') | translate"
                (onClick)="sendFeedbackSurvey()" [disabled]="surveyPointsSelected === 0">
            </custom-button>

        </div>

        <div *ngIf=" surveySended" fxFlex fxLayout="column" fxLayoutAlign="space-between center">

            <div fxFlex.xs class="full-width" fxLayout="column" fxLayoutAlign="start center">

                <!-- Fatto! -->
                <h4 fxFlexOffset="36px" fxFlexOffset.xs="16px" class="center lighter" translate="generic.DONE"></h4>

                <!-- Icona successo -->
                <svg-icon fxFlexOffset="20px" fxFlexOffset.xs="24px" src="../../../assets/icons/badge/icon-check.svg">
                </svg-icon>

                <!-- Descrizione successo survey -->
                <h6 fxFlexOffset="32px" fxFlexOffset.xs="24px" class="success-survey lighter"
                    translate="course-page.modal.survey.DONE_DESCRIPTION">
                </h6>

            </div>

            <!-- Torna al corso -->
            <custom-button ngClass.xs="full-width" [text]="('course-page.action.BACK_TO_COURSE') | translate"
                (onClick)="closeFeedbackSurvey()">
            </custom-button>

        </div>

    </div>

</app-modal>
