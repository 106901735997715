import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import { UrlService } from "src/app/shared/services/url.service";

@Injectable()
export class OfCourseMeService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Create the engagement of completion for the ofcourseme courseid passed
   * @param {string} courseId - Required for the action: The course id to retrieve
   * @return {boolean} true if is all ok
   */
  confirmOfcoursemeCourseCompletion(courseId: string): Observable<SenecaResponse<boolean>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('courseId', courseId);

    return this.http.get<SenecaResponse<boolean>>(this._mediatorUrl + 'confirm-ofcourseme-course-completion', { params: httpParams });
  }

  /**
   * Creates an engagement to track to OfCourseMe course's opening performed by the user
   * @param {string} courseId - Required: OfCourseMe course's id
   * @return {boolean} True if no error were thrown
   */
  trackOfcoursemeCourseOpening(courseId: string): Observable<SenecaResponse<boolean>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('courseId', courseId);

    return this.http.get<SenecaResponse<boolean>>(this._mediatorUrl + 'track-ofcourseme-course-opening', { params: httpParams });
  }

}
