import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Observable, from, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { UserAcknowledges } from 'atfcore-commonclasses/bin/classes/anag';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import * as CoreActions from '../../core/ngrx/core.actions';
import * as fromApp from '../../ngrx/app.reducers';
import * as ProfileActions from '../../profile/ngrx/profile.actions';
import * as AuthActions from './auth.actions';

import { JwtPayload, MapById } from 'atfcore-commonclasses';
import { AnagService } from 'src/app/core/services/anag.service';
import { LangsService } from 'src/app/core/services/langs.service';
import { WelcomeService } from 'src/app/core/services/welcome.service';
import { DeviceType } from 'src/app/shared/interfaces/common.interface';
import { authControl } from 'src/app/shared/models/global-application-data.model';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AuthService } from '../services/auth.service';

const jwtDecode = require("jwt-decode");

// Injectable perché abbiamo bisogno di importare le action e il routing
@Injectable()
export class AuthEffects {
  // actions$ col dollaro per marcare il fatto che è un Observable
  constructor(private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private anagService: AnagService,
    private welcomeService: WelcomeService,
    private langsService: LangsService,
    private redirectService: RedirectService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService) {
    const redirectUrl$: Observable<string> = this.store.select(fromApp.getRedirectUrl);
    redirectUrl$.subscribe(
      (redirectUrl) => {
        this.redirectUrl = redirectUrl;
      });
  }

  // Token intero recuperato dai servizi
  token: string;
  // Tiny recuperato dai servizi
  tinyToken: string;
  // Eventuale url richiesto
  redirectUrl: string;

  isTaker: boolean;
  isSso: boolean;
  tinyTokenObj;

  @Effect()
  authLogin$ = this.actions$.pipe(
    ofType(AuthActions.DO_LOGIN),
    // Ho bisogno del payload dell'action, e lo estraggo con il map()
    map((action: AuthActions.DoLogin) => {
      this.isTaker = false;
      // Grazie al map, il payload sarà wrappato in un nuovo Observable, così da poter far in seguito la concatenazione di altri operatori
      return action.payload;
    })
    // Provo a fare il login. Lo switchMap riceve il contenuto dell'Observable che viene passato, quindi in questo caso il payload
    , switchMap((authData: {
      email: string, password: string,
      isTaker?: boolean,
      isSso?: boolean
    }) => {
      this.isTaker = authData.isTaker;
      this.isSso = authData.isSso;

      // Prima di chiamare il servizio per il login, identifico l'userAgent e il tipo di device dell'utente
      const deviceInfo = this.deviceService.getDeviceInfo();
      const userAgent = deviceInfo && deviceInfo.userAgent;
      let deviceType: DeviceType;
      if (this.deviceService.isMobile()) {
        // Salvo il fatto che è uno smartphone
        deviceType = DeviceType.SMARTPHONE;
      } else if (this.deviceService.isTablet()) {
        // Salvo il fatto che è un tablet
        deviceType = DeviceType.TABLET;
      } else if (this.deviceService.isDesktop()) {
        // Salvo il fatto che è un computer desktop
        deviceType = DeviceType.DESKTOP;
      }

      if (this.isTaker) {
        if (authData.isSso) {
          // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
          return from(this.authService.getJWTToken(authData.email));
        } else {
          return from(this.authService.loginTaker(authData.email, deviceType, userAgent));
        }
      } else {
        return from(this.authService.login(authData.email, authData.password, deviceType, userAgent));
      }
    }),
    switchMap((tinyTokenObj: SenecaResponse<string>) => {
      if (tinyTokenObj.error) {
        throw new Error(tinyTokenObj.error);
      }

      this.tinyTokenObj = tinyTokenObj;

      if (!this.isTaker) {
        return from(this.authService.getJWTToken(tinyTokenObj.response));
      } else {
        return of(tinyTokenObj);
      }
    })
    , mergeMap(
      (tinyTokenObj: SenecaResponse<any>) => {
        let actionsContainer = [];
        if (this.isTaker) {
          if (tinyTokenObj.error) {
            throw new Error(tinyTokenObj.error);
          }
          if (!tinyTokenObj.error && !tinyTokenObj.response) {
            throw new Error("USER_NOT_FOUND");
          }
          if (this.isSso) {
            if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl]);
            } else {
              let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
              if (!storageRedirectUrl) {
                this.toastr.warning(
                  this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                );
              } else {
                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
              }
            }
            let decodedJwt: JwtPayload = jwtDecode(tinyTokenObj.response);
            const _loggedUser = new JwtPayload();
            _loggedUser.user = decodedJwt.user;
            let updatedUser = {
              loggedUser: _loggedUser,
            };

            actionsContainer.push(
              {
                type: CoreActions.SET_APPLICATION_LANG,
                payload: decodedJwt.user.userOptions.langCode,
              },
              {
                type: AuthActions.SET_IS_TAKER,
                payload: true,
              },
              {
                type: AuthActions.SET_TOKEN, // salvo il token
                payload: tinyTokenObj.response,
              },
              {
                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                payload: { isTaker: this.isTaker },
              },
              {
                type: CoreActions.REMOVE_REDIRECT_URL,
              },
              {
                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                payload: updatedUser,
              },
              {
                type: AuthActions.CHECK_WELCOME_PAGE
              }
            );
          } else {
            let langToUse = tinyTokenObj.response.userOptions.langCode;
            // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
            this.langsService.useLanguage(langToUse);
            const _loggedUser = new JwtPayload();
            _loggedUser.user = tinyTokenObj.response;

            let updatedUser = {
              loggedUser: _loggedUser,
            };

            sessionStorage.setItem("takerCid", tinyTokenObj.response.cid);

            actionsContainer.push(
              {
                type: CoreActions.SET_APPLICATION_LANG,
                payload: langToUse,
              },
              {
                type: CoreActions.GET_CLUSTERS,
              },
              {
                type: CoreActions.GET_CATEGORIES,
              },
              {
                type: AuthActions.SET_IS_TAKER,
                payload: "true",
              },
              {
                type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
                payload: {
                  isTaker: this.isTaker,
                },
              },
              {
                type: ProfileActions.UPDATE_USER, // setto l'utente come autenticato
                payload: updatedUser,
              },
              {
                type: AuthActions.CHECK_WELCOME_PAGE
              }
            );
            if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl]);
            } else {
              let storageRedirectUrl = sessionStorage.getItem("redirectUrl");
              if (!storageRedirectUrl) {
                this.toastr.warning(
                  this.translate.instant("warnings.PLEASE_ACCESS_WITH_URL")
                );
                // this.router.navigate([sessionStorage.getItem('redirectUrl')]);
              } else {
                this.router.navigate([sessionStorage.getItem("redirectUrl")]);
              }
            }
          }
        } else {
          // In questo caso il tinyTokenObj passato al merge non è più il tinyToken ma è il token completo
          let fullToken = tinyTokenObj.response;
          let authObject: any = {};
          let decodedJwt = fullToken && jwtDecode(fullToken);

          authObject = authControl(decodedJwt && decodedJwt.auths);

          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
          } else {
            this.router.navigate(["/"]);
          }
          actionsContainer.push(
            {
              type: AuthActions.SET_TOKEN, // salvo il token
              payload: this.tinyTokenObj.response,
            },
            {
              type: AuthActions.SET_USER_AUTHENTICATED, // setto l'utente come autenticato
              payload: {
                isTaker: this.isTaker,
                isManager: authObject.isManager,
                isAdmin: authObject.isAdmin,
              },
            },
            {
              type: CoreActions.REMOVE_REDIRECT_URL,
            },
            {
              type: CoreActions.START_RENEW_TOKEN_POLLING,
            }
          );
        }

        return actionsContainer;
      })
    , catchError((err, caught) => this.catchError(err, caught))
  );

  // Dobbiamo esplicitare il fatto che non eseguiamo nessun dispatch. Ovviamente ngrx ha bisogno di lasciare fluire l'Observable in entrata (questo il motivo per cui utilizziamo il do() al posto del subscribe())
  // @Effect({ dispatch: false })
  @Effect()
  authLogout$ = this.actions$.pipe(
    ofType(AuthActions.DO_LOGOUT),
    switchMap(() => {
      return this.authService.logout();
    }),
    map((logout: SenecaResponse<null>) => {
      if (logout.error) {
        throw (new Error(logout.error));
      } else {
        if (localStorage.getItem('showTourHeader')) {
          localStorage.removeItem('showTourHeader')
        }

        this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
        this.store.dispatch(new CoreActions.RemoveRedirectUrl());
        this.store.dispatch(new ProfileActions.CancelLoggedUser());
        this.store.dispatch(new AuthActions.Logout);
        this.redirectService.goToLocalLogin();
        return new AuthActions.LogoutFinished;
      }
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  );

  @Effect()
  authLogoutNoRedirect$ = this.actions$.pipe(
    ofType(AuthActions.DO_LOGOUT_NO_REDIRECT),
    switchMap(() => {
      return this.authService.logout();
    }),
    map((logout: SenecaResponse<null>) => {
      if (logout.error) {
        throw (new Error(logout.error));
      } else {
        if (localStorage.getItem('showTourHeader')) {
          localStorage.removeItem('showTourHeader')
        }
        this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
        this.store.dispatch(new CoreActions.RemoveRedirectUrl());
        this.store.dispatch(new ProfileActions.CancelLoggedUser());
        this.store.dispatch(new AuthActions.Logout);
        return new AuthActions.LogoutFinished;
      }
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  );

  @Effect()
  authTokenLogin$ = this.actions$.pipe(
    ofType(AuthActions.DO_TOKEN_LOGIN),
    map((action: AuthActions.DoTokenLogin) => {
      // Grazie al map, il payload sarà wrappato in un nuovo Observable, così da poter far in seguito la concatenazione di altri operatori
      return action.payload;
    }),
    switchMap((payload: { identityToken: string }) => {
      if (payload.identityToken) {
        this.tinyToken = payload.identityToken;
        return this.authService.validateJwtToken(payload.identityToken);
      } else {
        throwError(new Error('TOKEN_NOT_FOUND'));
      }
    }),
    map((tokenObj: SenecaResponse<boolean>) => {
      if (tokenObj.error) {
        throw (new Error(tokenObj.error));
      } else if (tokenObj && tokenObj.response) {
        // Salvo il tiny token. Salvo questo perché è quello che mi servirà nelle chiamate rest, e che utilizzerà quindi l'interceptor
        // Se è valido salvo il tiny token
        this.store.dispatch(new AuthActions.SetToken(this.tinyToken));
        this.store.dispatch(new AuthActions.SetUserAuthenticated);
        return this.store.dispatch(new ProfileActions.SetLoggedSocialUser(tokenObj.response));
      }
    }),
    withLatestFrom(this.store.select(fromApp.getLoggedUser)),
    map(([action, loggedUser]) => {
      // Se ho settato l'utente loggato (e quindi decodificato il token) posso settare la lingua di sistema con quella scelta dall'utente
      let langToUse = this.langsService.getUserLang(loggedUser.user);
      // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
      this.langsService.useLanguage(langToUse);
      return new CoreActions.SetApplicationLang(langToUse);
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  );

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedGet$ = this.actions$.pipe(
    ofType(AuthActions.RETRIEVE_USER_ACKNOWLEDGES),
    switchMap(() => {
      return from(this.anagService.getAllUserAcknowledges());
    }),
    map((data: SenecaResponse<UserAcknowledges>) => {
      if (data.error) {
        throw (new Error(data.error));
      } else {
        return new AuthActions.SetUserAcknowledges(data.response);
      }
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  );

  // Effect che aggiorna la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedUpdate$ = this.actions$.pipe(
    ofType(AuthActions.UPDATE_USER_ACKNOWLEDGES),
    switchMap((action: any) => {
      return from(this.anagService.updateUserAcknowledges(action.payload));
    }),
    map(
      (data: any) => {
        if (data) {
          if (data.error) {
            // Catturo l'errore
            throw (new Error(data.error));
          } else {
            return new AuthActions.RetrieveUserAcknowledges();
          }
        }
      }
    ),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  );

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  @Effect()
  redirectAfterLogin$ = this.actions$.pipe(
    ofType(AuthActions.CHECK_WELCOME_PAGE),
    switchMap(() => {
      return from(this.welcomeService.isProfilingComplete());
    }),
    map((data: SenecaResponse<boolean>) => {
      if (data.error) {
        throw (new Error(data.error));
      } else {
        const sessionStorageUrl = sessionStorage.getItem('redirectUrl');
        const redirectAfterSignIn = sessionStorage.getItem('redirectAfterSignIn');

        if (redirectAfterSignIn) {
          sessionStorage.removeItem('redirectAfterSignIn');
          // Faccio in modo che i query params vengano propagati anche al redirect verso il login, così che non vadano persi.
          // Questi possono includere i parametri "utm_*" di Google Analytics.
          const queryParams: MapById<number | string> = getQueryParams(redirectAfterSignIn);
          const redirectAfterSignInWithoutParams = redirectAfterSignIn.split("?")?.[0];
          this.router.navigate([redirectAfterSignInWithoutParams], {
            queryParams: queryParams
          });
        } else if (sessionStorageUrl) {
          // Faccio in modo che i query params vengano propagati anche al redirect verso il login, così che non vadano persi.
          // Questi possono includere i parametri "utm_*" di Google Analytics.
          const queryParams: MapById<number | string> = getQueryParams(sessionStorageUrl);
          const sessionStorageUrlWithoutParams = sessionStorageUrl.split("?")?.[0];
          this.router.navigate([sessionStorageUrlWithoutParams], {
            queryParams: queryParams
          });
        }

        const urlToConsider = this.redirectUrl || redirectAfterSignIn || sessionStorageUrl;

        if (!data.response) {
          this.redirectService.goToFirstIntro();
        } else {
          if (urlToConsider) {
            // Faccio in modo che i query params vengano propagati anche al redirect verso il login, così che non vadano persi.
            // Questi possono includere i parametri "utm_*" di Google Analytics.
            const queryParams: MapById<number | string> = getQueryParams(urlToConsider);
            const urlToConsiderWithoutParams = urlToConsider.split("?")?.[0];
            this.router.navigate([urlToConsiderWithoutParams], {
              queryParams: queryParams
            });
          } else {
            this.redirectService.goToHome();
          }
          this.store.dispatch(new CoreActions.RemoveRedirectUrl());
        }
        return;
      }
    }),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  );

  /**
   * General function for catch errors
   * @param err
   * @param caught
   */
  catchError = (err, caught) => {
    this.store.dispatch(new CoreActions.HideApplicationLoader({ closeManualLoader: true }));
    // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e
    // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
    // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
    // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
    // con il valore tornato dal catch.
    // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
    if (err && err.message) {
      this.toastr.error(this.translate.instant('errors.' + err.message));
      // this.modalService.open('successModal');
    }
    // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
    return caught;
  }

}

function getQueryParams(url: string): MapById<string | number> {
  const vars: string[] = url.split("?")?.[1]?.split("&");
  const queryParams = vars?.reduce((obj, str) => {
    const [key, value] = str.split("=");
    obj[key] = value;
    return obj;
  }, {});
  return queryParams;
}
