import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'custom-select-header',
  templateUrl: './select-header.component.html',
  styleUrls: ['./select-header.component.scss']
})
export class SelectHeaderComponent implements OnInit, AfterViewInit {
  @Input() labelForId: string;
  @Input() formTitle?: string;
  @Input() closeOnSelect?: string;
  @Input() parameterNameOfSelectedItem?: string;
  @Input() typetosearch?: string;
  @Input() noDataFoundLabel?: string;
  @Input() startSearchLabel?: string;
  @Input() avatarImageAccessibilityText?: string;
  @Input() loadingData?: string;
  @Input() placeholderLabel?: string;
  @Input() disabled?: boolean;
  @Input() searchable?: boolean;
  @Input() required?: boolean;
  @Input() virtualScroll?: boolean;
  @Input() bindValue?: any;
  @Input() bindModel?: any;
  @Input() getSelectedItemCustomTitle?: Function;
  @Input() containerClass?: string | string[];
  @Input() label: string;
  @Input() ariaLabelText: string;
  @Input() idForInput: string;
  @Input() addTag?: boolean;
  @Input() loading?: boolean;
  @Input() clearOnBackspace?: boolean;
  @Input() hideSelected?: boolean;
  @Input() typeahead?;
  @Input() multiple?: boolean;
  @Input() areUsersList?: boolean;
  @Input() areGroupsList?: boolean;
  @Input() areMailAndNameList?: boolean;
  @Input() areMailtemplateList?: boolean;
  @Input() bindLabel: string;
  @Input() dropdownPosition?: string;
  @Input() lables: Array<any>;
  @Input() selectClass: string;
  @Input() inputWrapperClass?: string;
  @Output() addItem = new EventEmitter();
  @Output() onBindModelChanged = new EventEmitter();
  @Output() onSelectionChanged = new EventEmitter();
  @Output() removeItem = new EventEmitter();
  @Output() scrollToEnd = new EventEmitter<any>();
  @Output() close = new EventEmitter();
  @Input() errorRequiredText?: string;
  @Input() forceShowError?: boolean;
  @Input() attrAriaLabel?: string;
  @Input() weightSelection?: boolean;
  @Input() markFirst?: boolean;
  // lista dei risultati
  @Input() items: Array<any>;
  @Input() ieFlexProblem: boolean;

  @ViewChild('alloySelect') alloySelect;

  filter: string;
  resetResultList: boolean;
  focused: boolean;

  constructor(
    private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  resolveItemTitle(item: any, isForSelectedItem?: boolean) {
    if (this.weightSelection && isForSelectedItem) {
      return item.title + ' (' + (item.attributeWeight || 0) + ')';
    } else if (this.lables && this.lables.length) {
      for (let i = 0; i < this.lables.length; i++) {
        if (this.lables[i].id == item) {
          return this.bindLabel ? this.lables[i].text : this.lables[i].text;
        }
      }
    } else if (this.parameterNameOfSelectedItem) {
      return item[this.parameterNameOfSelectedItem];
    } else if (this.areGroupsList) {
      return item.title;
    } else if (typeof item === 'string' || typeof item === 'number') {
      return item;
    } else {
      return item.name || '';
    }
  }

  ngAfterViewInit() {
    if (this.attrAriaLabel) {
      setTimeout(() => {
        if (this.alloySelect && this.alloySelect.searchInput) {
          this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "aria-label", this.attrAriaLabel);
        }
      })
    }
  }

  compareWith(item, selected) {
    return item.id === selected.id;
  };

  emitRemoveItem(event: any): void {
    this.removeItem.emit(event);
  }

  emitAddItem(selectedTag: any): void {
    if (this.addItem && this.addItem instanceof EventEmitter) {
      this.addItem.emit(selectedTag);
    }
  }

  emitOnClose(): void {
    this.focused = true;
    if (this.close && this.close instanceof EventEmitter) {
      this.close.emit();
    }
  }

  emitOnSelectionChanged(data: any): void {
    if (this.onSelectionChanged && this.onSelectionChanged instanceof EventEmitter) {
      this.onSelectionChanged.emit(data);
    }
  }

  emiOnModelChanged(data?: any): void {
    if (this.onBindModelChanged && this.onBindModelChanged instanceof EventEmitter) {
      this.onBindModelChanged.emit(data);
    }
  }

  emitOnScrollToEnd(data?: any): void {
    if (this.scrollToEnd && this.scrollToEnd instanceof EventEmitter) {
      this.scrollToEnd.emit(data);
    }
  }

  // Torna l'avatar di un utente
  getUserAvatar(selectedUser): string {
    return (selectedUser && selectedUser.userOptions && selectedUser.userOptions.avatarImage) || (selectedUser && selectedUser && selectedUser.chiaveSesso == "M" ? 'assets/img/placeholder.png' : 'assets/img/placeholder_female.png');
  }
}
