<div class="tab-container" [ngClass]="{ 'profileTab': isProfileTab }" fxLayout="column" fxLayoutAlign="center center">
  <!-- TABS -->
  <div fxShow [fxHide.lt-md]="!_forceFullShow" class="tabs" fxLayout="row" fxLayoutAlign="start start">
    <!-- Button -->
    <button *ngFor="let tab of tabs" [ngClass]="{ active: currentTabId == tab.id }" class="tab-element" fxLayout="row"
      fxLayoutAlign="center center" (click)="selectTab(tab)">
      <!-- Div for border-button -->
      <div class="tab-button" fxLayout="row" fxLayoutAlign="center center">
        <h6 class="bold">{{ (tab.label | translate) }}</h6>
        <generali-badge *ngIf="tab.points && tab.points > 0" fxFlexOffset="8px" fxFlexOffset.xs="6px" type="primary"
          [title]="tab.points.toString()"></generali-badge>
      </div>
    </button>
  </div>
  <div fxHide [fxShow.lt-md]="!_forceFullShow" class="tabs-select">
    <custom-select labelToTranslate type="tab" [(selectedValue)]="selectedTab" bindValue="label" bindLabel="label"
      (onSelectionChanged)="selectTab($event)" [items]="tabs" arrowIconSrc="../../../../assets/icons/icon-arrows.svg">
    </custom-select>
  </div>
  <!-- Contenuto delle tab -->
  <div class="tab-content" fxLayout="column" fxLayoutAlign="center center">
    <ng-content></ng-content>
  </div>
</div>