<ng-container *ngIf="!isGettingItemDetails">
    <div class="scroll-table">
        <table *ngIf="!isLoadingEditions && editionsSurvey && editionsSurvey.length" class="item-detail-table"
            role="grid">
            <thead>
                <tr class="calendar-section-table-header text-header-table">
                    <th tabindex="0" translate="generic.LINKED_EDITION" role="columnheader" class="text-uppercase">
                    </th>
                    <th tabindex="0" role="columnheader" translate="generic.STATUS" class="text-uppercase"></th>
                    <th tabindex="0" role="columnheader" translate="itemDetailsPage.TYPOLOGY" class="text-uppercase">
                    </th>
                    <th tabindex="0" role="columnheader" translate="itemDetailsPage.surveys.IS_REPEATABLE"
                        class="text-uppercase"></th>
                    <th tabindex="0" role="columnheader" translate="itemDetailsPage.surveys.STOP_DATE"
                        class="text-uppercase">
                    </th>
                    <th tabindex="0" role="columnheader" translate="generic.SINGLE_DETAIL"
                        class="text-uppercase text-center">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr role="row" *ngFor="let edition of editionsSurvey; let i = index; trackBy editionTrackBy">
                    <!-- Iniziativa associata -->
                    <td role="cell">
                        <span *ngIf="getEditionOfAssessment(edition)">
                            <!-- Luogo dell'edizione associata -->
                            <div>
                                <p tabindex="0" class="bold-text">
                                    {{ getAssementEditionDetailsText(edition)}}
                                </p>
                            </div>
                            <!-- Giornata dell'edizione associata -->
                            <div *ngFor="let day of getEditionOfAssessment(edition).days | orderByDate">
                                <p class="margin-top6">{{ day.formattedFullDate }}</p>
                                <!-- Orari della singola giornata -->
                                <p class="schedule-time">
                                    <span tabindex="0" [hidden]="!schedule.startTime || !schedule.endTime"
                                        *ngFor="let schedule of day.timeSchedules; let first = first; let last = last">
                                        {{ (schedule.startTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' |
                                        translate))
                                        + ' - ' + (schedule.endTime | amLocale:'it' | amDateFormat:
                                        ('generic.TIME_FORMAT' |
                                        translate))
                                        + ( !last && day.timeSchedules.length > 1 ? ' / ' : '' ) }}
                                    </span>
                                </p>
                            </div>
                        </span>
                    </td>
                    <td role="cell">
                        <p tabindex="0" *ngIf="getEditionOfAssessment(edition) && edition.surveyStatus"
                            [ngClass]="{ 'subscribed-status-label' : getEditionOfAssessment(edition).isSurveyPassed }">
                            {{ ('generic.surveyStatuses.' + edition.surveyStatus) | translate }}
                            <!--{{ getAssessmentOfEditionCompletitionStatus(getEditionOfAssessment(edition), edition.assessmentType) }}-->
                        </p>
                    </td>
                    <td role="cell">
                        <p *ngIf="edition.assessmentType" tabindex="0">
                            {{ edition.assessmentType | translate }}</p>
                        <p *ngIf="!edition.assessmentType" tabindex="0">
                            {{ 'itemDetailsPage.surveys.SURVEY' | translate }}</p>
                    </td>
                    <td role="cell">
                        <p tabindex="0">
                        <p>{{ getAssessmentOfEditionNotRepeateableLabel(getEditionOfAssessment(edition),
                            edition.assessmentType)}}
                        </p>
                    </td>
                    <td role="cell">
                        <p tabindex="0" *ngIf="edition.deadlineDate">
                            {{edition.deadlineDate | amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat}}
                        </p>
                        <p tabindex="0" *ngIf="!edition.deadlineDate && edition.endDate">
                            {{edition.endDate | amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat}}</p>
                    </td>
                    <!-- Redirect alla rilevazione -->
                    <td role="cell">
                        <p tabindex="0" class="open-survey-btn" (click)="goToCourseSurvey(edition)">
                            {{ 'generic.GO' | translate }}</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Nessun questionario trovato -->
    <div *ngIf="!isLoadingEditions && (!editionsSurvey || !editionsSurvey.length)" fxLayout="row"
        class="full-width materials-container" fxLayoutAlign="center center">
        <p tabindex="0" class="margin-bottom0" translate="generic.NO_DATA_FOUND">
        </p>
    </div>

</ng-container>