/*
 * Servizio che gestisce le chiamate ai servizi relative ai Tag
*/
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Upload } from 'atfcore-commonclasses/bin/classes/upload';
import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { UrlService } from 'src/app/shared/services/url.service';
import { dataURItoBlob } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Retrieve file data and send it to core for upload
   * @param {Form} form - form data with file data
   * @return {Upload} Retrieved file's information with URL
   */
  uploadAvatarBase64(avatarBase64: string, filelName?: string): any {
    const blob = dataURItoBlob(avatarBase64);
    const form = new FormData();
    form.append("file", blob);
    form.append("filename", filelName || "new-avatar.png");
    return this.http.post<SenecaResponse<Upload>>(this._cm2MediatorUrl + 'upload-avatar', form);
  }

  /**
   * Retrieve file data and send it to core for upload
   * @param {Form} form - form data with file data
   * @return {Upload} Retrieved file's information with URL
   */
  uploadCover(avatarBase64: string, filelName?: string): any {
    const blob = dataURItoBlob(avatarBase64);
    const form = new FormData();
    form.append("file", blob);
    form.append("filename", filelName || "new-cover.png");
    return this.http.post<SenecaResponse<Upload>>(this._mediatorUrl + 'upload-profile-cover', form);
  }


  uploadAvatar(file: File, fileName?: string): any {
    const form = new FormData();
    form.append("file", file);
    form.append("filename", fileName || "new-avatar.png");
    return this.http.post<SenecaResponse<Upload>>(this._cm2MediatorUrl + 'upload-avatar', form);
  }


}
