<div class="item-details-content">
  <div class="bg-item">
    <div class="itemDetails" fxLayout="column">
      <div class="header-survey">
        <div class="content">
          <div fxLayout="row" fxLayoutAlign="start center">
            <custom-button importance="primary-small" text="{{'generic.BACK_SURVEY' | translate | uppercase }}"
              (onClick)="backToItemDetails()">
            </custom-button>
            <h5 tabindex="0">{{ 'generic.itemTypes.SURVEY' | translate }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxfxLayout="row" class="survey content">
    <div class="survey-wrapper">
      <div class="inviteBoxBottom" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Domande precedenti-->
        <div>
          <custom-button *ngIf="!prevDisabled" importance="secondary" text="{{ 'survey.BACK' | translate }}"
            [disabled]="!btnEnabled()" (onClick)="prevItem()">
          </custom-button>
        </div>
        <!-- Domande successive -->
        <div>
          <custom-button *ngIf="!nextDisabled" importance="primary" text="{{ 'survey.NEXT' | translate }}"
            [disabled]="!btnEnabled()" (onClick)="nextItem()">
          </custom-button>
        </div>
        <!-- Completa il test -->
        <custom-button *ngIf="currentPos + 1 == totalGroups && isSurveyEditable" importance="primary"
          text="{{ 'generic.CONFIRM' | translate }}" [disabled]="!btnEnabled()" (onClick)="openConfirmSurveyModal()">
        </custom-button>
      </div>

      <!-- Loader -->
      <ng-template #fetchingDataLoader>
        <generali-loader></generali-loader>
      </ng-template>

      <div *ngIf="initiativeLoaded; else fetchingDataLoader" class="inviteBoxBottom" fxfxLayout="row"
        fxLayoutAlign="space-between center">
        <div class="main-wrapper" fxFlexFill>
          <div fxLayout="row" fxLayoutAlign="start center" class="control-panel-wrapper center-box width-auto"
            fxFlexFill layout-wrap>
            <div class="control-panel-obj-detail md-whiteframe-1dp no-padding no-margin" fxFlexFill>
              <div class="control-panel-top-bar"></div>
              <div class="control-panel-top-obj-detail bg-white">
                <div class="control-panel-title">
                  <!-- Titolo del questionario -->
                  <div *ngIf="currentSurveyInfo && currentSurveyInfo.title">
                    <h5 tabindex="0" class="bold-text margin-botom8">{{ currentSurveyInfo.title }}</h5>
                    <p tabindex="0" class="margin-botom16" translate="survey.ANSWER_TO_ALL_QUESTIONS"></p>
                  </div>
                  <!-- Sottotitolo del questionario -->
                  <div *ngIf="currentSurveyInfo && currentSurveyInfo.subTitle">
                    <h4 tabindex="0">{{
                      currentSurveyInfo.subTitle }}</h4>
                  </div>
                </div>
                <div class="clearer clearfix"></div>
                <!-- Toolbar -->
                <div class="progressbar" fxLayout="row" fxLayoutAlign="start center" fxFlexFill>
                  <p tabindex="0" class="bold-text to-lowercase margin-right15">{{currentPos + 1}} <span
                      translate="survey.OF"></span>
                    {{totalGroups}}</p>
                  <progress-bar fxFlex [value]="percentageProgressLinear + percentageOfTotalQuestions"></progress-bar>
                </div>
              </div>

              <div fxLayout="column" fxLayoutAlign="center start">
                <div layout-xs="column" fxLayout="row" fxLayoutAlign="start center" layout-wrap fxFlexFill>
                  <form name="surveyQuestionsForm" class="form-validation" fxFlexFill>
                    <div class="box-group">
                      <div fxLayout="row">
                        <div class="survey-infobox-container">
                          <p tabindex="0" fxLayout="row" class="btn-container">
                            {{selectedGroup && selectedGroup.itemText}}
                          </p>
                          <div tabindex="0" fxLayout="row" *ngIf="groupHint" class="btn-container">
                            {{groupHint}}
                          </div>
                        </div>
                      </div>

                      <div class="space"></div>

                      <!-- Costruzione domande dinamiche -->
                      <div fxLayoutAlign="start center" *ngFor="let question of questions; let iquest = index"
                        fxFlexFill>
                        <!-- textbox -->
                        <div class="full-width" fxLayout="column" fxLayoutAlign="start start"
                          *ngIf="question && question.answerType === 'T'">
                          <h6 tabindex="0" class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.textAnswer}">
                            {{question.itemText}}<span
                              *ngIf="question.answerMandatory == 'true' && !question.textAnswer">*</span>
                          </h6>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div tabindex="0" fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="margin-top10" fxLayout="column" fxFlexFill>
                            <div class="md-block inline-block md-icon-left" fxFlexFill>
                              <input [(ngModel)]="question.textAnswer" name="inputText" type="text" maxlength="4000"
                                [disabled]="!isSurveyEditable" [required]="question.answerMandatory == 'true'"
                                class="margin0">
                            </div>
                          </div>
                        </div>

                        <!-- numberbox -->
                        <div class="full-width" fxLayout="column" fxLayoutAlign="start start"
                          *ngIf="question && question.answerType === 'N'">
                          <h6 tabindex="0" class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.numericAnswer}">
                            {{question.itemText}}
                            <span *ngIf="question.answerMandatory == 'true' && !question.numericAnswer">*</span>
                          </h6>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div tabindex="0" fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="margin-top10" fxLayout="column">
                            <div class="md-block inline-block md-icon-left no-margin">
                              <input [(ngModel)]="question.numericAnswer" type="number" name="inputNumber"
                                [disabled]="!isSurveyEditable" [required]="question.answerMandatory == 'true'"
                                class="no-margin">
                            </div>
                          </div>
                        </div>

                        <!-- extended text -->
                        <div class="full-width" fxLayout="column" layout-xs="column" fxLayoutAlign="start start"
                          fxFlexFill *ngIf="question && question.answerType === 'E'">
                          <h6 tabindex="0" class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.textAnswer}">
                            {{question.itemText}}<span
                              *ngIf="question.answerMandatory == 'true' && !question.textAnswer">*</span>
                          </h6>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div tabindex="0" fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="margin-top10" fxLayout="column">
                            <div class="md-block inline-block md-icon-left" fxFlexFill>
                              <textarea [(ngModel)]="question.textAnswer" maxlength="4000" rows="2"
                                aria-label="Inserire testo" md-select-on-focus="" [disabled]="!isSurveyEditable"
                                name="inputTextArea" [required]="question.answerMandatory == 'true'"></textarea>
                            </div>
                          </div>
                        </div>

                        <!-- radio -->
                        <div *ngIf="question && question.answerType === 'S'">
                          <div class="full-width">
                            <h6 tabindex="0" class="question-title"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selectedAnswerIds}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selectedAnswerIds">*</span>
                            </h6>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div tabindex="0" fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div *ngFor="let answer of question.answers; let i = index;"
                              class="md-radio survey-single-check">
                              <input type="radio" id="{{'opt' + iquest + '_' + i}}"
                                name="{{'options' + iquest + '_' +  i}}" [disabled]="!isSurveyEditable"
                                [(ngModel)]="question.selectedAnswerIds" value="{{answer.answerId}}">
                              <label for="{{'opt' + iquest + '_' + i}}">{{answer.itemText}}</label>
                            </div>
                          </div>
                        </div>

                        <!-- select -->
                        <div fxLayout="row" layout-xs="column" fxLayoutAlign="center center"
                          *ngIf="question && question.answerType === 'C'">
                          <div class="full-width" fxLayout="column">
                            <h6 tabindex="0" class="question-title"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selectedAnswerIds}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selectedAnswerIds">*</span>
                            </h6>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div tabindex="0" fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>

                            <custom-select [isOnSurvey]="true" class="margin-top10" [placeholderLabel]="''"
                              [(selectedValue)]="question.selectedAnswerIds" [items]="question.answers"
                              (onSelectionChanged)="onSelectedSurveyOptions($event, question)" bindValue="answerId"
                              [disabled]="!isSurveyEditable" bindLabel="itemText">
                            </custom-select>
                          </div>
                        </div>

                        <!-- multi-checkbox -->
                        <div *ngIf="question && question.answerType === 'M'">
                          <div fxLayout="column" class="full-width single-checklist-container margin-top10">
                            <p tabindex="0" class="question-title margin-bottom10"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selected}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selected">*</span>
                            </p>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div tabindex="0" fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div class="md-checkbox margin-top10"
                              *ngFor="let answer of question.answers; let i = index;">
                              <input id="inputCheckboxMultiple{{answer.answerId}}"
                                name="inputCheckboxMultiple{{answer.answerId}}" type="checkbox"
                                [disabled]="!isSurveyEditable" [(ngModel)]="answer.selected">
                              <label for="inputCheckboxMultiple{{answer.answerId}}">
                                {{answer.itemText}}
                              </label>
                            </div>
                          </div>
                        </div>

                        <!-- single-checkbox -->
                        <div *ngIf="question && question.answerType === 'X'">
                          <div fxLayout="column" class="full-width single-checklist-container margin-bottom10">
                            <h6 tabindex="0" class="question-title">
                              {{ question.itemText }}
                            </h6>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div tabindex="0" fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div class="md-checkbox margin-top10">
                              <input type="checkbox" id="inputCheckbox" [disabled]="!isSurveyEditable"
                                name="inputCheckbox" [(ngModel)]="question.selected">
                              <label for="inputCheckbox">
                              </label>
                            </div>
                          </div>
                        </div>

                        <!-- date -->
                        <div class="full-width" fxLayout="column" layout-xs="column" fxLayoutAlign="start start"
                          fxFlexFill *ngIf="question && question.answerType === 'D'">
                          <h6 tabindex="0" class="question-title"
                            [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.dateAnswer}">
                            {{ question.itemText }}<span
                              *ngIf="question.answerMandatory == 'true' && !question.dateAnswer">*</span>
                          </h6>
                          <div fxLayout="row" *ngIf="question.itemHint">
                            <div tabindex="0" fxLayout="column">
                              {{question.itemHint}}</div>
                          </div>
                          <div class="col-xs-12 m-b-sm m-l-sm">
                            <div class="m-xs table-like">
                              <div class="table-cell-like vertical-center">
                                <datepicker [showLoader]="!isSurveyEditable" class="full-width"
                                  [(inputValue)]="question.dateAnswer">
                                </datepicker>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Inidice di gradimento -->
                        <div fxLayout="row" layout-xs="column" fxLayoutAlign="center center"
                          *ngIf="question && question.answerType === 'F'" fxFlexFill>
                          <div class="full-width" fxLayout="column" fxFlexFill>
                            <h6 tabindex="0" class="question-title"
                              [ngClass]="{'text-primary-1' : question.answerMandatory == 'true' && !question.selectedAnswerIds}">
                              {{ question.itemText }}<span
                                *ngIf="question.answerMandatory == 'true' && !question.selectedAnswerIds">*</span>
                            </h6>
                            <div fxLayout="row" *ngIf="question.itemHint">
                              <div tabindex="0" fxLayout="column">
                                {{question.itemHint}}</div>
                            </div>
                            <div fxLayout="row" class="full-width margin-top20" fxLayoutAlign="space-between start">
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_1.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking1" name="liking1" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="1">
                                  <label for="liking1"></label>
                                </div>
                                <span translate="survey.liking.FIRST"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_2.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking2" name="liking2" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="2">
                                  <label for="liking2"></label>
                                </div>
                                <span translate="survey.liking.SECOND"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_3.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking3" name="liking3" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="3">
                                  <label for="liking3"></label>
                                </div>
                                <span translate="survey.liking.THIRD"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_4.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking4" name="liking4" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="4">
                                  <label for="liking4"></label>
                                </div>
                                <span translate="survey.liking.FOURTH"></span>
                              </div>
                              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="20">
                                <svg-icon src="assets/icons/liking_5.svg"></svg-icon>
                                <div class="md-radio">
                                  <input type="radio" id="liking5" name="liking5" [disabled]="!isSurveyEditable"
                                    [(ngModel)]="question.numericAnswer" value="5">
                                  <label for="liking5"></label>
                                </div>
                                <span tabindex="0" translate="survey.liking.FIFTH"></span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="margin-top24px">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <!-- Domande precedenti-->
        <div>
          <custom-button *ngIf="!prevDisabled" importance="secondary" text="{{ 'survey.BACK' | translate }}"
            [disabled]="!btnEnabled()" (onClick)="prevItem()">
          </custom-button>
        </div>
        <!-- Domande successive -->
        <div>
          <custom-button *ngIf="!nextDisabled" importance="primary" text="{{ 'survey.NEXT' | translate }}"
            [disabled]="!btnEnabled()" (onClick)="nextItem()">
          </custom-button>
        </div>
        <!-- Completa il test -->
        <custom-button *ngIf="currentPos + 1 == totalGroups && isSurveyEditable" importance="primary"
          text="{{ 'generic.CONFIRM' | translate }}" [disabled]="!btnEnabled()" (onClick)="openConfirmSurveyModal()">
        </custom-button>
      </div>
    </div>
  </div>
</div>

<!-- Modale per chiedere conferma sul completamento della Survey -->
<app-modal class="not-destructive-modal" id="confirmSurvey">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeConfirmSurveyModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="survey.CLOSE" tabindex="-1">
      </h4>
    </div>
    <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
      <p tabindex="0" translate="survey.CONFIRM_CLOSE_ONE"></p>
    </div>

    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla l'operazione -->
      <custom-button importance="secondary" text="{{'modals.CANCEL_BTN' | translate}}"
        (onClick)="closeConfirmSurveyModal()">
      </custom-button>
      <div fxHide.gt-xs class="space15"></div>
      <!-- Conferma l'operazione -->
      <custom-button importance="primary" text="{{'modals.CONFIRM_BTN' | translate}}"
        (onClick)="closeConfirmSurveyModal(true);">
      </custom-button>
    </div>
  </div>
</app-modal>