<div class="custom-select full-height" [ngClass]="type + ' ' + customContainerClass" fxLayout="row"
  fxLayoutAlign="start center">
  <ng-select [virtualScroll]="_virtualScroll" [bindValue]="bindValue" [bindLabel]="_bindLabel" [items]="items"
    [placeholder]="placeholderLabel" [multiple]="_multiple" (add)="emitAddItem($event)" [closeOnSelect]="true"
    (remove)="emitRemoveItem($event)" (change)="emitOnSelectionChanged($event)" [(ngModel)]="_selectedValue"
    (ngModelChange)="emiOnModelChanged($event)" [dropdownPosition]="dropdownPosition" (blur)="emitOnBlur($event)"
    (scrollToEnd)="emitOnScrollToEnd($event)" (close)="emitOnClose()" [addTag]="addTag" aria-label="header-search"
    [clearOnBackspace]="clearOnBackspace" [searchable]="_searchable" [hideSelected]="_hideSelected" [loading]="_loading"
    [compareWith]="compareWith" [disabled]="disabled" [markFirst]="markFirst" [hideSelected]="false"
    [ngClass]="{'in-special-project': inProjectDetailSelect}" [typeahead]="typeahead" #alloySelect>

    <!-- Selected label -->
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <h6 *ngIf="!isSecondSelectEdition && !areUsersList && !areGroupsList && !isMultipleSelectionVisible" class="item"
        [ngClass]="noWhitespace ? 'no-whitespace' : ''">
        {{_labelToTranslate
        ? sliceAndConcatDots((labelValue(item) | translate))
        : sliceAndConcatDots(labelValue(item))
        }}
      </h6>
      <h6 *ngIf="isSecondSelectEdition" class="item"
        [ngClass]="noWhitespace ? 'no-whitespace' : ''"
        [innerHTML]="sliceAndConcatDots(labelValue(item))">
      </h6>
      <div *ngIf="isMultipleSelectionVisible" fxLayout="row" fxLayoutAlign="start center" style="padding: 5px 10px;" fxLayourGap="8px">
        <p>{{resolveItemTitle(item, true)}}</p>
        <!-- ho dovuto aggiungere un div dove aggangiare il click per risolvere un problema con ng-select, si rompeva non trovando il .classname sull'elemento SVG al click della X -->
        <div (click)="clear(item);" style="max-height: 19px; margin-left: 7px;">
          <svg-icon aria-hidden="true" src="assets/icons/icon-trash.svg"></svg-icon>
        </div>
      </div>
    </ng-template>
    <!-- Template per l'inizio della ricerca -->
    <ng-template ng-typetosearch-tmp>
      <div class="ng-option disabled">{{ startSearchLabel || 'Inizia una ricerca' }}</div>
    </ng-template>
    <!-- Template per i dati non trovati -->
    <ng-template ng-notfound-tmp let-searchTerm="filter">
      <div class="ng-option disabled">{{ noDataFoundLabel || 'Nessun dato trovato' }}</div>
    </ng-template>
    <!-- Template per il caricamento dei risultati -->
    <ng-template ng-loadingtext-tmp>
      <div class="ng-option disabled">{{ loadingDataLabel || 'Caricamento in corso' }}</div>
    </ng-template>

    <!-- Template per i singoli item nelle options -->
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div *ngIf="!areUsersList" class="option-container" (click)="emitOnOptionClicked(item)">
        <h6 *ngIf="!isCm2EditionsSelect">{{_labelToTranslate ? (labelValue(item) | translate) : labelValue(item)}}</h6>
        <h6 class="cm2-option" *ngIf="isCm2EditionsSelect" [innerHTML]="labelValue(item) | safeHtml: 'html'"></h6>
      </div>
      <!-- Template per i singoli item nelle options (se è una lista di utenti) -->
      <div *ngIf="areUsersList">
        <div class="select-options-card">
          <div class="card-body" fxLayout="row">
            <div class="full-width" fxLayout="row">
              <div class="margin-right10">
                <img class="img-avatar-coursemanager-home small-avatar" [src]="getUserAvatar(item)"
                  alt="{{ avatarImageAccessibilityText }}" title="{{ avatarImageAccessibilityText }}">
              </div>
              <div fxLayout="column" class="padding-right25">
                <p class="bold-text margin-bottom0">{{item.forename}} {{item.surname}}</p>
                <h5>{{item.defTpDip}}</h5>
                <h5 class="bold-text">{{item.cid}}</h5>
                <h5>{{item.email}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-select>
  <!-- Arrow with compability for IE -->
  <div class="cursor-pointer select-with-checkbox-arrow" fxLayout="column" fxLayoutAlign="center center"
    (click)="alloySelect.handleArrowClick()">
    <svg-icon [ngClass]="{'opened': alloySelect.isOpen}" [src]="arrowIconSrc"></svg-icon>
  </div>
</div>