/*
 * Servizio che gestisce le chiamate ai servizi relative ai Tag
*/
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SenecaResponse, Tag } from 'atfcore-commonclasses';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { UrlService } from 'src/app/shared/services/url.service';
import { buildAutocompleteServiceKey, findCachedResult, storeCachedResult } from 'src/commonclasses';
import * as fromApp from '../../ngrx/app.reducers';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import * as CoreActions from '../ngrx/core.actions';

@Injectable({
    providedIn: 'root'
})
export class TagService {
    applicationData: GlobalApplicationData;
    cacheMap: any;
    private _mediatorUrl: string;

    constructor(private store: Store<fromApp.AppState>,
        private urlService: UrlService,
        private http: HttpClient) {
        this._mediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
        this.cacheMap = {};
    }

    // Recupera una lista di Tag
    findTags(allData?: string, fromRecord?: string | number, numRecords?: string | number, type?: string, title?: string, useCache?: boolean, fromCM?: string) {
        const serviceCacheKey = buildAutocompleteServiceKey('findTags', false, fromRecord, numRecords, type, title, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, title);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('fromRecord', fromRecord && fromRecord.toString());
        httpParams = httpParams.append('numRecords', numRecords && numRecords.toString());
        httpParams = httpParams.append('allData', allData);
        httpParams = httpParams.append('type', type);
        httpParams = httpParams.append('title', title || '');
        httpParams = httpParams.append('fromCM', fromCM);
        return this.http.get<SenecaResponse<Tag[]>>(this._mediatorUrl + 'find-tags', {
            params: httpParams
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, title);
            }
            return senecaResponse;
        }));
    }

    getClusters(): Observable<Tag[]> {
        return this.store.select(fromApp.getClusters)
            .pipe(
                take(1),
                switchMap((clusters: Tag[]) => {
                    if (!clusters || !clusters.length) {
                        // Se non ho cluster, li recupero dai servizi remoti
                        this.store.dispatch(new CoreActions.GetClusters());
                    }
                    return this.store.select(fromApp.getClusters);
                })
            );
    }

    // Crea i tags
    createTags(tags: Tag[]): Observable<SenecaResponse<Tag[]>> {
        return this.http.post<SenecaResponse<Tag[]>>(this._mediatorUrl + 'create-grouped-report-tags', {
            tags: tags
        });
    }

    // Recupera i tag in base agli Ids passati al servizio 
    getTagsByIds(tagIds: string[]): Observable<SenecaResponse<Tag[]>> {
        let httpParams = new HttpParams();
        if (tagIds && tagIds.length) {
            tagIds.forEach(tagIds => {
                httpParams = httpParams.append('tagIds', tagIds);
            });
        }
        return this.http.get<SenecaResponse<Tag[]>>(this._mediatorUrl + 'get-tags-by-ids', {
            params: httpParams
        });
    }
}
