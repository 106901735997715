/*
 * Componente per la pagina della privacy
*/

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-delete-my-data',
    templateUrl: './delete-my-data.component.html',
    styleUrls: ['./delete-my-data.component.scss']
})
export class DeleteMyDataComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
