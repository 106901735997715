import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { LoginGuard } from './shared/services/login-guard.service';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { ErrorPageComponent } from './core/error/error-page.component';
import { FirstIntroductionComponent } from './auth/first-introduction/first-introduction.component';
import { SigninComponent } from './auth/signin/signin.component';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { UtmCampaignComponent } from './auth/utmCampaign/utm-campaign.component';
import { TermsOfUseComponent } from './core/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from './core/privacy/privacy.component';
import { DeleteMyDataComponent } from './core/delete-my-data/delete-my-data.component';
import { PrivacySecurityComponent } from './core/privacySecurity/privacy-security.component';
import { CanDeactivateConfirmDataService } from './auth/services/can-deactivate-confirm-data.service';
import { LocalLoginComponent } from './auth/local-login/local-login.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginSamlErrorComponent } from './auth/login/login-saml-error.component';
import { LogoutSsoCompletedComponent } from './auth/login/logout-sso-completed.component';
import { SupportPageComponent } from './support-page/support-page.component';
import { SpecialProjectsGuard } from './auth/services/special-projects-guard.service';
import { ManageHeroHomeGuard } from './auth/services/manage-hero-home-guard.service';
import { ManageInspiredTagsGuard } from './auth/services/manage-inspired-tags-guard.service';
import { ManageFaqGuard } from './auth/services/manage-faq-guard.service';
import { ValuesAndBehavioursGuard } from './shared/services/values-and-behaviours-guard.service';
import { TenantGuard } from './shared/services/tenant-guard.service';
import { WomenInsuranceNetworkGuard } from './shared/services/women-insurance-network-guard.service';

const appRoutes: Routes = [
  { path: 'utmCampaign', component: UtmCampaignComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard, CompatibilityGuard, TenantGuard] },
  { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'collectionList', loadChildren: () => import('./collectionList/collectionList.module').then(m => m.CollectionListModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'faculty', loadChildren: () => import('./faculty/faculty.module').then(m => m.FacultyModule), canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'first-intro', component: FirstIntroductionComponent, canActivate: [AuthGuard, CompatibilityGuard, TenantGuard] },
  { path: 'first-intro-home', component: FirstIntroductionComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'first-intro-mail-confirmation', component: FirstIntroductionComponent, canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'localLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSamlError', component: LoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'logoutSsoCompleted', component: LogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'confirmPrivacyData', component: SigninComponent, canActivate: [AuthGuard, CompatibilityGuard], canDeactivate: [CanDeactivateConfirmDataService] },
  { path: 'recoveryUserPassword/:userId/:token', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'emailConfirmation/:userId/:token', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'compatibilityError', component: CompatibilityErrorComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'privacySecurity', component: PrivacySecurityComponent },
  { path: 'termsOfUse', component: TermsOfUseComponent },
  { path: 'deleteMyData', component: DeleteMyDataComponent },
  { path: 'support', component: SupportPageComponent, canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'specialProjects', loadChildren: () => import('./special-projects/special-projects.module').then(m => m.SpecialProjectsModule), canActivate: [AuthGuard, CompatibilityGuard, SpecialProjectsGuard] },
  { path: 'manageHeroHome', loadChildren: () => import('./manage-hero-home/manage-hero-home.module').then(m => m.ManageHeroHomeModule), canActivate: [AuthGuard, CompatibilityGuard, ManageHeroHomeGuard] },
  { path: 'manageInspiredTags', loadChildren: () => import('./manage-inspired-tags/manage-inspired-tags.module').then(m => m.ManageInspiredTagsModule), canActivate: [AuthGuard, CompatibilityGuard, ManageInspiredTagsGuard] },
  { path: 'manageFaq', loadChildren: () => import('./manage-faq/manage-faq.module').then(m => m.ManageFaqModule), canActivate: [AuthGuard, CompatibilityGuard, ManageFaqGuard] },
  { path: 'valuesAndBehaviours', loadChildren: () => import('./valuesAndBehaviours/valuesAndBehaviours.module').then(m => m.ValuesAndBehavioursModule), canActivate: [ValuesAndBehavioursGuard] },
  { path: 'womenInsuranceNetwork', loadChildren: () => import('./women-insurance-network/women-insurance-network.module').then(m => m.WomenInsuranceNetworkModule), canActivate: [WomenInsuranceNetworkGuard] },
  { path: 'trainingInfograph', loadChildren: () => import('./trainingInfograph/trainingInfograph.module').then(m => m.TrainingInfographModule) },
  { path: 'error', component: ErrorPageComponent },
  { path: '403', component: PageNotAuthorizedComponent },
  { path: '', redirectTo: 'home/main', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
