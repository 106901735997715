/*
 * Servizio che gestisce le chiamate ai servizi relative ai Tag
*/
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { UrlService } from 'src/app/shared/services/url.service';
import { TalentFarmSenecaResponse } from 'atfcore-commonclasses';

@Injectable({
  providedIn: 'root'
})
export class ShareService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  // Recupera lo short url del job da condividere nei social
  getShortUrlJob(itemId: string) {

    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-job-short-link-for-sharing', { itemId: itemId });
  }

  // Recupera lo short url del corso da condividere nei social
  getShortUrlCourse(itemId: string) {

    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-course-short-link-for-sharing', { itemId: itemId });
  }

  // Recupera lo short url dell'evento da condividere nei social
  getShortUrlEvent(itemId: string) {

    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-event-short-link-for-sharing', { itemId: itemId });
  }

  // Recupera lo short url della new da condividere nei social
  getShortUrlNew(itemId: string) {

    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-news-short-link-for-sharing', { itemId: itemId });
  }

  // Recupera lo short url della sfida da condividere nei social
  getShortUrlChallenge(itemId: string) {

    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-challenge-short-link-for-sharing', { itemId: itemId });
  }

  // Recupera lo short url del premio da condividere nei social
  getShortUrlPrize(itemId: string) {

    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-prize-short-link-for-sharing', { itemId: itemId });
  }

  // Registra la condivisione del job esternamente
  recordJobShareOnSocial(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-job-sharing', { itemId: itemId });
  }

  // Registra la condivisione del corso esternamente
  recordCourseShareOnSocial(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-course-sharing', { itemId: itemId });
  }

  // Registra la condivisione dell'evento esternamente
  recordEventShareOnSocial(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-event-sharing', { itemId: itemId });
  }

  // Registra la condivisione della new esternamente
  recordNewShareOnSocial(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-news-sharing', { itemId: itemId });
  }

  // Registra la condivisione della sfida esternamente
  recordChallengeShareOnSocial(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-challenge-sharing', { itemId: itemId });
  }

  // Registra la condivisione del premio esternamente
  recordPrizeShareOnSocial(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-prize-sharing', { itemId: itemId });
  }

  // Registra la condivisione del certificato del corso
  trackCourseCertificateSharing(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-course-certificate-sharing', { itemId: itemId });
  }

  // Registra la condivisione del certificato dell'evento
  trackEventCertificateSharing(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<any>>(this._mediatorUrl + 'track-event-certificate-sharing', { itemId: itemId });
  }

  // Funzione condivisa dagli oggetti per condividerli in linkedin
  shareUrlOnLinkedin(url: string, label: string) {
    const genericUrl = encodeURIComponent(url);
    const genericSummary = encodeURIComponent(label);
    const goto = 'http://www.linkedin.com/shareArticle?mini=true' +
      '&url=' + genericUrl +
      '&summary=' + genericSummary +
      '&source=' + "GoGenerali";
    const left = (screen.width - 770) / 2;
    const top = (screen.height - 570) / 2;
    const params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left + ",scrollbars=no;resizable=no";
    /* window.open(goto, "LinkedIn", params); */
    return goto;
  }
}
