<div class="space40"></div>

<div class="content theme-primary" *ngIf="isExternalCallbackUrl">
  <div class="header-img">
    <div class="logo-home" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="start start">
        <img height="70" width="100,44" src="../../../assets/img/logo_generali.svg">
      </div>
    </div>
  </div>
</div>

<div class="theme-primary" *ngIf="pageReady">
  <div class="space20"></div>

  <div class="privacy-title-container" fxLayoutAlign="start center">
  <div fxLayoutAlign="start center" class="privacy-title">
    <h2>Termini e condizioni generali di utilizzo
      della Piattaforma LearningUP
    </h2>
  </div>
  </div>
  <div class="space20"></div>
  <h5><b>A. Premessa</b></h5>
  <br>
  <h5><b>I</b> presenti <b>termini e condizioni</b> (i “<b>Termini e Condizioni”</b>) disciplinano l’accesso alla
    Piattaforma LearningUP (la “<b>Piattaforma”</b>) e la fruizione dei contenuti caricati sulla stessa
    Piattaforma.<br><br>
    La Piattaforma è la nuova piattaforma digitale integrata di Generali Italia S.p.A. (“<b>Generali</b>”
    o la “<b>Società</b>”) rivolta ai candidati e aspiranti candidati a posizioni lavorative aperte presso
    la stessa Generali e altre le altre società del Gruppo con sede in Italia (gli “<b>Utenti</b>”).<br><br>
    Generali mette a disposizione degli Utenti diversi contenuti, anche editoriali, tra l’altro,
    finalizzati a offrire agli stessi Utenti:
    <ul>
      <li>
        Supporto all’orientamento al mondo del lavoro
      </li>
      <li>
        Ingaggio in sfide e valutazioni per misurare le proprie competenze
      </li>
      <li>
        Una maggiore conoscenza dell’ambiente lavorativo del Gruppo Generali, anche
        tramite l’incontro e/ confronto con personale di Generali
      </li>
      <li>
        Opportunità di sviluppo competenze attraverso accesso a contenuti formativi.
      </li>
    </ul>
    Tramite la Piattaforma, l’Utente può visualizzare le posizioni lavorative aperte presso
    Generali e le altre società del Gruppo con sede in Italia e inviare la propria candidatura,
    tramite reindirizzamento alla Piattaforma Taleo, di proprietà di Generali.<br><br>
    La registrazione alla Piattaforma è consentita a tutti i maggiori di 16 anni.<br><br>
    <b>B. La Società</b>
    <br><br>
    La Piattaforma è gestita e mantenuta da <b>Generali Italia S.p.A.</b>, società soggetta a
    direzione e coordinamento di Assicurazioni Generali S.p.A. e appartenente al Gruppo
    Assicurativo Generali iscritto all’Albo dei gruppi assicurativi n. 026.
    <ul>
      <li>
        Generali Italia S.p.A., titolare del marchio registrato, ha:
      </li>
      <li>
        Sede legale: in Via Marocchesa, 14 – 31021 Mogliano Veneto (TV),
      </li>
      <li>
        Sedi operative in: Piazza Tre Torri 1 – 20145 Milano (MI); Via Bissolati, 23 – 00187
        Roma (RM); e Via Giuseppe Mazzini, 53 10123 Torino (TO).
      </li>
      <li>
        Capitale Sociale: Euro 1.618.628.450,00 interamente versato.
      </li>
      <li>
        C.F. e iscr. nel Registro Imprese di Treviso-Belluno n. 00409920584 - P.IVA 01333550323
      </li>
      <li>
        Pec: <a href="mailto:generaliitalia@pec.generaligroup.com">generaliitalia@pec.generaligroup.com</a>
      </li>
    </ul>
    <b>C. Proprietà della Piattaforma e dei contenuti</b>
    <br><br>
    La Piattaforma e qualsiasi contenuto presente nella Piattaforma, o a cui esso rimanda,
    inclusi (senza limitazione) loghi, domain names o altri segni distintivi, dati, illustrazioni,
    testi, contenuti audio-video o altri contenuti multimediali sono - ove non diversamente
    indicato - di esclusiva proprietà della Società o di altra società del Gruppo Generali o
    comunque dei soggetti che hanno attribuito a dette società il diritto di utilizzo.<br><br>
    La Società e/o le sue controllate, controllanti e/o affiliate e/o società in qualsiasi modo
    collegate o connesse si riservano il diritto di intraprendere azioni legali a propria tutela
    contro qualsiasi contraffazione dei loro diritti di proprietà intellettuale.<br><br>
    Tutti i diritti non espressamente conferiti dai presenti termini e condizioni restano in capo
    alla Società o ai suoi licenzianti, fornitori, editori, titolari o altri fornitori di servizi.<br><br>
    <b>D. Modalità di registrazione</b>
    <br><br>
    L’Utente <b>non registrato</b> potrà esclusivamente visualizzare tutte le preview dei contenuti
    caricati sulla Piattaforma. La Società potrà decidere di rendere accessibili alcuni contenuti
    selezionati anche agli Utenti non registrati.<br><br>
    Se si desidera accedere ai contenuti, ricevere la newsletter e accedere a tutte le
    funzionalità della Piattaforma, è necessario <b>procedere alla registrazione</b> secondo le
    procedure indicata.<br><br>
    Al momento della registrazione l’Utente dovrà inserire i seguenti dati:
    <ul>
      <li>
        Indirizzo e-mail (a meno che l’Utente non preferisca in alternativa registrarsi tramite il
        Social Log-in messo a disposizione);
      </li>
      <li>
        Password (appositamente creata per la Piattaforma, se l’Utente decide di registrarsi
        tramite e-mail, oppure la password di accesso al social network selezionato, se
        l’Utente preferisce questa modalità di registrazione)
      </li>
      <li>
        Data di nascita
      </li>
      <li>
        Genere (le opzioni saranno, rispettivamente: “Femminile”, “Maschile”, “Non mi
        riconosco i nessuna delle due opzioni precedenti”, “Preferisco non rispondere”)
      </li>
      <li>
        Percorso di studi
      </li>
      <li>
        Modalità con cui è venuto a conoscenza della Piattaforma.
      </li>
    </ul>
    L’indirizzo e-mail e la password costituiranno le credenziali di accesso dell’Utente (le
    “<b>Credenziali di Accesso</b>”). Le Credenziali di Accesso consentiranno all’Utente di
    accedere alla propria area riservata (“<b>Area Riservata</b>”). L’Utente potrà in ogni momento
    cambiare le Credenziali di Accesso seguendo le indicazioni riportate nella propria Area
    Riservata.<br><br>
    L’Utente che abbia interesse ad accedere a <b>(i)</b> contenuti prioritizzati in base alle proprie
    preferenze, nonché a <b>(ii)</b> iniziative di gamification, potrà rilasciare il consenso alla
    profilazione, indicando i propri interessi, anche in base al proprio ambito professionale.<br><br>
    L’Utente è il solo e unico responsabile della custodia e del corretto utilizzo delle proprie
    Credenziali di Accesso. L’Utente si impegna a comunicare tempestivamente alla Società
    all’indirizzo <a href="mailto:supporto.applicativi@alloy.it">gogenerali@generali.com</a> lo smarrimento, la sottrazione o qualsiasi uso non
    autorizzato da parte di terzi delle proprie Credenziali di Accesso e/o ogni altra violazione
    della propria Area Riservata di cui venisse a conoscenza, impegnandosi fin d’ora a
    modificare immediatamente le Credenziali di Accesso tramite la procedura indicata nella
    propria Area Riservata. L’Utente si impegna, inoltre, a tenere la Società indenne e
    manlevata da ogni conseguenza dannosa o pregiudizio che dovesse derivare alla Società
    o a terzi dal non corretto utilizzo, dallo smarrimento o dalla sottrazione delle Credenziali di
    Accesso.<br><br>
    È consentita all’Utente la creazione di un unico profilo associato alla sua persona. L’Utente accetta di fornire alla Società informazioni veritiere e complete e di mantenere i
    dati presenti nella propria Area Riservata aggiornati. L’Utente potrà essere considerato
    responsabile qualora abbia fornito alla Società informazioni non veritiere o incomplete. Nel
    caso in cui la Società sospetti o verifichi che le informazioni fornite dal Cliente non siano
    veritiere o siano incomplete, la stessa si riserva la facoltà di sospendere – totalmente o
    parzialmente – l’Area Riservata del Cliente e/o i Servizi attivati.<br><br>
    <b>E. Uso della Piattaforma</b>
    <br><br>
    L'Utente si impegna a non utilizzare la Piattaforma in modo improprio. A titolo meramente
    esemplificativo, è vietato usare la Piattaforma con modalità o per finalità non consentite
    dalla legge, per diffondere virus informatici, compiere attività che possano compromettere
    la sicurezza della Piattaforma. È altresì vietato partecipare a iniziative di gamification con modalità fraudolente, sleali e/o improprie, come, a titolo meramente esemplificativo, creare e utilizzare più di un profilo associato alla stessa persona fisica. A questo proposito, la Società si riserva il diritto di verificare a propria discrezione (i) l’identità dell’Utente che dovesse chiedere il riscatto dei punti accumulati, e (ii) le modalità con cui i punti siano stati accumulati dall’Utente, e di subordinare l’erogazione dei premi a tali verifiche. La Società si riserva altresì il diritto di non erogare i premi qualora dovesse verificare che l’Utente abbia accumulato i relativi punti con modalità fraudolente, sleali e/o improprie.
    <br><br>
    <b><i>Non può essere fatto alcun uso dei contenuti presenti nella Piattaforma da parte di
        terzi senza il preventivo consenso scritto dei relativi titolari. È espressamente
        vietata la riproduzione, con qualsiasi mezzo, anche elettronico, dei contenuti,
        nonché eventuali operazioni di modifica, pubblicazione, traduzione, trasmissione,
        distribuzione o caricamento, in modo totale o parziale.</i></b>
    <br><br>
    L’accesso e l’utilizzo della Piattaforma non implicano pertanto alcun diritto a copiare o a
    utilizzare alcuno dei contenuti o dei diritti di proprietà intellettuale anche diversi da quelli
    precedentemente citati che sono contenuti nel, o che permettono il funzionamento della Piattaforma stessa, anche se
    non espressamente menzionati. È vietato ogni tipo di
    decompilazione o reverse engineering della Piattaforma e dei suoi meccanismi di
    funzionamento, così come qualsiasi forma di automatic grabbing o di uso di qualsiasi
    strumento informatico di estrazione automatizzata o non di testo e/o dati.
    <br><br>
    <b><i>L’uso di qualsiasi contenuto della Piattaforma è da intendersi strettamente
        personale e riservato per l’Utente, senza che questi possa condividerlo o fruirlo con
        altri soggetti non autorizzati.</i></b>
    <br><br>
    Per accedere a determinati contenuti (es. frequentare una videolezione) l’Utente registrato
    potrà essere reindirizzato su piattaforme di società terze, su cui Generali non ha alcun
    controllo e di cui non potrà essere chiamata a rispondere.
    <br><br>
    <b>F. Contenuti forniti dagli Utenti</b>
    <br><br>
    La Società consente agli Utenti maggiorenni di caricare, condividere o offrire i propri
    contenuti sulla Piattaforma.<br><br>
    All’atto di fornire contenuti alla Piattaforma, l’Utente dichiara di essere legalmente
    autorizzato a farlo e conferma che detti contenuti non violano norme di legge e/o diritti di
    terzi.<br><br>
    <b><i>L’Utente riconosce ed accetta che fornendo contenuti propri alla Piattaforma
        concede alla Società a titolo gratuito il diritto non esclusivo di utilizzare i contenuti
        all’interno della stessa Piattaforma, e di elaborare i contenuti con fini di operatività e
        manutenzione.</i></b>
    <br><br>
    L’Utente risponde in via esclusiva dei contenuti (a titolo meramente esemplificativo, foto,
    video, elaborati, ecc.) caricati, pubblicati, condivisi o comunque forniti alla Piattaforma (il
    “<b>Materiale</b>”), indipendentemente dal fatto che la pubblicazione del Materiale sia stata
    eventualmente approvata dalla Società. L’Utente riconosce che l’eventuale approvazione
    del Materiale, che Generali si riserva di dare, seguirà ad un mero controllo circa la
    coerenza dello stesso Materiale rispetto allo scopo per il quale sarà richiesto il
    caricamento. Inoltre, la Società si riserva il diritto di rimuovere, cancellare, bloccare o
    rettificare detti contenuti a propria discrezione e di negare senza preavviso all’utente che li
    ha caricati l’accesso alla Piattaforma se:
    <ul>
      <li>
        ha ricevuto un reclamo in relazione a tale contenuto;
      </li>
      <li>
        ha ricevuto una notifica di violazione dei diritti di proprietà intellettuale;
      </li>
      <li>
        ha ricevuto ordine dell’Autorità in tal senso; o
      </li>
      <li>
        sia stato fatto presente alla Società che tali contenuti possono rappresentare un
        rischio per gli altri utenti, per i terzi o per la disponibilità della Piattaforma.
      </li>
    </ul>
    La rimozione, cancellazione, il blocco o la rettifica dei contenuti non giustificano alcuna
    pretesa di risarcimento, rimborso o indennizzo in capo agli utenti che hanno fornito tali
    contenuti.<br><br>
    Gli Utenti accettano di mantenere indenne la Società da e contro ogni pretesa avanzata
    e/o danno sofferto a causa di contenuti da essi forniti a o offerti tramite la Piattaforma.
    <br><br>
    <b>G. Disclaimer e limitazione di responsabilità</b>
    <br><br>
    I contenuti presenti sulla Piattaforma sono offerti da Generali agli Utenti a scopo
    puramente informativo e/o educativo. Le informazioni ivi contenute sono messe a
    disposizione degli Utenti senza garanzia alcuna, soggette a modifica o a livelli variabili di
    accuratezza. Le valutazioni delle competenze degli Utenti sono effettuate da Generali sulla
    base di parametri quanto più oggettivi possibile, ma non hanno alcun valore certificativo,
    né potranno essere utilizzate dagli Utenti per scopi estranei alla fruizione della Piattaforma
    e/o condivise con terzi.
    <br><br>
    <b><i>Si prega di notare che né l’iscrizione alla Piattaforma, né la partecipazione a eventi,
        challenges, o valutazioni, né la fruizione dei contenuti formativi rappresenta né
        comporta alcuna garanzia di assunzione presso le società il Gruppo Generali.</i></b>
    <br><br>
    <b><i>Generali e le altre società appartenenti al Gruppo Generali non saranno responsabili
        nei confronti dell'utente o di terzi per errori, omissioni, interruzioni, cancellazioni,
        difetti, ritardi operativi o di trasmissione, guasti delle linee di comunicazione, furto,
        distruzione, accesso non autorizzato o alterazione di qualsiasi contenuto inerente la
        Piattaforma.</i></b>
    <br><br>
    <b>H. Trattamento dei dati personali</b>
    <br><br>
    La Società dichiara e garantisce che ogni trattamento di dati personali sarà effettuato nel
    pieno rispetto della disciplina, tempo per tempo, applicabile al trattamento dei dati
    personali, quale a titolo esemplificativo e non esaustivo il Regolamento UE n. 679 del 27
    aprile 2016 (“GDPR”), e in conformità con quanto previsto nella documentazione privacy
    letta e accettata dall’Utente.
    <br><br>
    <b>I. Legge Applicabile e Foro Competente</b>
    <br><br>
    I presenti Termini e Condizioni sono soggetti alla legge italiana.
    <br><br>
    Qualsiasi controversia civile dovesse insorgere tra la Società e l’Utente relativamente al
    presente rapporto sarà devoluta alla competenza territoriale del luogo in cui l’Utente ha la 
    residenza o il domicilio elettivo dell’Utente.
    <br><br>
    <b>J. Richieste di assistenza</b>
    <br><br>
    L’Utente potrà rivolgere le proprie richieste di assistenza relative all’accesso alla
    Piattaforma e alla visualizzazione dei suoi contenuti all’indirizzo di posta elettronica
    <a href="mailto:supporto.applicativi@alloy.it">gogenerali@generali.com</a>.
  </h5>
  <div class="space20"></div>
  <div class="space20"></div>
</div>
