import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DropdownPosition } from "@ng-select/ng-select";
import { FilterItem } from "../select/select.component";

@Component({
  selector: "select-icon",
  templateUrl: "./select-icon.component.html",
  styleUrls: ["./select-icon.component.scss"]
})
export class SelectIconComponent implements OnInit {
  @Input() dropdownPosition?: DropdownPosition = 'bottom';
  @Input() placeholder: string = '';
  @Input() iconSrc: string;
  _inputValue: FormControl;
  @Input() set inputValue(value: FormControl) {
    if (value) {
      this._inputValue = value;
      if (this._inputValue.value) {
        this.onSelectionChanged(this._inputValue.value);
      }
    };
  };

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      const element = document.getElementById(this._id);
      if (element) {
        element.setAttribute("tabindex", value);
      }
    }
  };

  @Input() items: FilterItem[];

  _type: string;
  @Input() set type(value: string) {
    this._type = value || 'text';
  }

  _showLoader: boolean;
  @Input() set showLoader(value: boolean | string) {
    this._showLoader = coerceBooleanProperty(value);
  };

  _id: string;
  @Input() id: string | number;

  get inputIsFilled(): boolean {
    return this._inputValue !== undefined && this._inputValue !== null && this._inputValue.value;
  }

  constructor() { }

  ngOnInit() {

    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "select-icon-" + this.id;

  }

  /**
   * Clear the text
   */
  clearText() {
    this._inputValue.setValue(undefined);
    this.focusOnInput();
  }

  /**
   * Focus on the input
   */
  focusOnInput() {
    setTimeout(() => {
      const element: HTMLElement = document.getElementById(this._id);;
      element.focus();
    }, 0);
  }

  onSelectionChanged(item: FilterItem) {
    this._inputValue.setValue(item);
    this._inputValue.updateValueAndValidity();
  }

}
