import { NgModule, Injectable } from '@angular/core';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlMomentDateTimeModule } from '@busacca/ng-pick-datetime';

import { SharedModule } from '../shared/shared.module';
import { UrlService } from '../shared/services/url.service';
import { AuthService } from '../auth/services/auth.service';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { CompatibilityGuard } from '../shared/services/compatibility-guard.service';
import { LoginGuard } from '../shared/services/login-guard.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CompatibilityErrorComponent } from './compatibility-error/compatibility-error.component';
import { LangsService } from './services/langs.service';
import { ErrorPageComponent } from './error/error-page.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SupportComponent } from './support/support.component';
import { RedirectService } from '../shared/services/redirect.service';
import { ForumPageComponent } from './forum-page/forum-page.component';
import { ForumMockService } from './services/forum-mock.service';
import { PageNotAuthorizedComponent } from './page-not-authorized/page-not-authorized.component';
import { JobMockService } from './services/job-mock.service';
import { CourseMockService } from './services/course-mock.service';
import { AnagService } from './services/anag.service';
import { BlogService } from './services/blog.service';
import { ItemService } from './services/item.service';
import { ProfilingService } from './services/profiling.service';
import { TaleoService } from './services/taleo.service';
import { UploadService } from '../shared/services/upload.service';
import { CourseService } from './services/course.service';
import { EventsService } from './services/events.service';
import { ScormService } from './scorm-player/scorm.service';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PrivacySecurityComponent } from './privacySecurity/privacy-security.component';
import { FAQService } from './services/faq.service';
import { DeleteMyDataComponent } from './delete-my-data/delete-my-data.component';
import { ProfileService } from './services/profile.service';
import { WelcomeService } from './services/welcome.service';
import { PlayerService } from './kaltura-player/player.service';
import { OfCourseMeService } from './services/ofcourseme.service';
import { ChallengesService } from './services/challenges.service';
import { CanDeactivateConfirmDataService } from '../auth/services/can-deactivate-confirm-data.service';
import { PrizeService } from './services/prize.service';
import { ShareService } from '../shared/services/share.service';
import { CategoriesService } from './services/categories.service';
import { TagService } from './services/tag.service';
import { CourseEditionService } from '../home/services/course-edition.service';
import { AgmCoreModule } from '@agm/core';
import { SpecialProjectsGuard } from '../auth/services/special-projects-guard.service';
import { ManageHeroHomeGuard } from '../auth/services/manage-hero-home-guard.service';
import { ManageInspiredTagsGuard } from '../auth/services/manage-inspired-tags-guard.service';
import { ManageFaqGuard } from '../auth/services/manage-faq-guard.service';
import { ValuesAndBehavioursGuard } from '../shared/services/values-and-behaviours-guard.service';
import { TenantGuard } from '../shared/services/tenant-guard.service';
import { WomenInsuranceNetworkGuard } from '../shared/services/women-insurance-network-guard.service';

export const MY_NATIVE_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY hh:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  upSecondLabel = 'Aggiungi un secondo';
  downSecondLabel = 'Un secondo in meno';
  upMinuteLabel = 'Aggiungi un minuto';
  downMinuteLabel = 'Un minuto in meno';
  upHourLabel = 'Aggiungi un\'ora';
  downHourLabel = 'Un\'ora in meno';
  prevMonthLabel = 'Mese precedente';
  nextMonthLabel = 'Prossimo mese';
  prevYearLabel = 'Anno precedente';
  nextYearLabel = 'Prossimo anno';
  prevMultiYearLabel = '21 anni precedenti';
  nextMultiYearLabel = 'Prossimi 21 anni';
  switchToMonthViewLabel = 'Cambia visuale';
  switchToMultiYearViewLabel = 'Scegli mese e anno';
  cancelBtnLabel = 'Annulla';
  setBtnLabel = 'Conferma';
  rangeFromLabel = 'Da';
  rangeToLabel = 'A';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
}

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PageNotFoundComponent,
    PageNotAuthorizedComponent,
    CompatibilityErrorComponent,
    TermsOfUseComponent,
    DeleteMyDataComponent,
    PrivacyComponent,
    PrivacySecurityComponent,
    SupportComponent,
    ErrorPageComponent,
    ForumPageComponent
  ],
  imports: [
    SharedModule,
    AgmCoreModule,
    OwlMomentDateTimeModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    ErrorPageComponent,
    ForumPageComponent,
    OwlMomentDateTimeModule
  ],
  providers: [
    UrlService,
    AuthService,
    AnagService,
    AuthGuard,
    SpecialProjectsGuard,
    CanDeactivateConfirmDataService,
    RedirectService,
    CompatibilityGuard,
    TenantGuard,
    SupportComponent,
    LoginGuard,
    LangsService,
    CourseMockService,
    JobMockService,
    ForumMockService,
    BlogService,
    ItemService,
    ProfilingService,
    UploadService,
    ShareService,
    TaleoService,
    CourseService,
    ChallengesService,
    EventsService,
    ScormService,
    FAQService,
    ProfileService,
    PrizeService,
    CategoriesService,
    WelcomeService,
    PlayerService,
    TagService,
    OfCourseMeService,
    CourseEditionService,
    CourseService,
    ManageHeroHomeGuard,
    ManageInspiredTagsGuard,
    ManageFaqGuard,
    ValuesAndBehavioursGuard,
    WomenInsuranceNetworkGuard,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'it' },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl }
  ]
})
export class CoreModule { }
