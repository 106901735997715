<div id="profilation-page-anchor"></div>
<generali-carousel [preventOverflowMarginOnRight]="true" #profilationCarousel [fromHero]="true" [isFullWindow]="true"
  noScroll hidePoints sectionId="profilation-carousel" [contentLength]="getContentLengthCarousel()"
  [scrollToRight]="scrollToSkill.asObservable()" [(index)]="carouselIndex" (onScrollEvent)="onScrollEvent($event)">

  <!-- Dati fissi in pagina -->
  <ng-container>

    <!-- Chiudi -->
    <svg-icon class="profilation-close cursor-pointer" src="../../../assets/icons/icon-close-light.svg" height="40"
      width="40" (click)="closeProfilationEvent()">
    </svg-icon>

  </ng-container>

  <!-- Terza pagina - Categorie -->
  <div *ngIf="!showOnlySeniority && !showOnlyInterests" class="profilation profilation-interests" fxLayout="column"
    fxLayoutAlign="start center" [ngClass]="{ 'auto-height' : showOnlyInterests }">

    <!-- Logo generali -->
    <div class="profilation-logo" fxLayout="column" fxLayoutAlign="center center">
      <img *ngIf="!isEuropAssistanceUser" ngClass.xs="logo-mobile" alt="LearningUp" height="90" layout="column"
        src="/assets/icons/learning_up_logo.png">
      <img *ngIf="isEuropAssistanceUser" ngClass.xs="logo-mobile" alt="IoApprendo" height="90" layout="column"
        src="/assets/icons/logo-ioApprendo.png">
    </div>

    <div fxFlexOffset="68px" fxFlexOffset.xs="40px" class="profilation-interests-container" fxLayout="column"
      fxLayoutGap="60px">

      <div fxLayout="column" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="start center">
          <!-- Scegli le categorie -->
          <h4 *ngIf="!isInWelcomePage" class="center bold white" tabindex="-1"
            translate="profilation.SELECT_CATEGORIES"></h4>
          <h4 *ngIf="isInWelcomePage && isEuropAssistanceUser" class="center bold white" tabindex="-1"
            translate="profilation.WELCOME_IOAPPRENDO"></h4>
          <h4 *ngIf="isInWelcomePage && !isEuropAssistanceUser" class="center bold white" tabindex="-1"
            translate="profilation.WELCOME_LUP"></h4>
          <h5 fxFlexOffset="7px" fxFlexOffset.xs="4px" translate="profilation.CATEGORY_INFO"></h5>
        </div>

        <div *ngIf="categories && categories.length" class="profilation-profilings" fxLayoutAlign.xs="center start"
          fxLayout="row wrap" fxLayoutGap="52px grid">
          <generali-profiling *ngFor="let category of categories" [tag]="category"
            (onInfoModalOpen)="onCategoryInfoModalOpen($event)" [showInfoModal]="category.description">
          </generali-profiling>
        </div>
      </div>

      <!-- Continua -->
      <div fxFlexOffset="32px" class="profilation-button" fxLayout="column">
        <custom-button [text]="'profilation.CUSTOMIZE' | translate" [disabled]="disableConfirmCategoriesButton"
          (onClick)="confirmCategories()"></custom-button>
        <div fxFlexOffset="48px"></div>
      </div>

    </div>

  </div>

</generali-carousel>

<modal-coin-content id="modal-coin" (closeFunction)="closeCoinModal()" [coinsNumber]="coinsNumber"></modal-coin-content>

<!--  Modale che mostra l'oggetto propedeutico a quello selezionato -->
<app-modal class="informative-modal" id="selected-tag-info-modal">
  <div *ngIf="selectedTagToShowInfo" class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeCategoryInfoModalOpen()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" tabindex="-1">{{ selectedTagToShowInfo.title }}</h4>
    </div>

    <div fxLayoutAlign="space-between center" fxLayout="column" class="full-height">
      <div fxLayout="column" fxLayoutAlign="start center">
        <p tabindex="0">{{ selectedTagToShowInfo.description || '' }}</p>
      </div>

      <!-- Chiude la modale -->
      <custom-button importance="primary" text="{{'generic.CLOSE' | translate | uppercase }}"
        (onClick)="closeCategoryInfoModalOpen();">
      </custom-button>
    </div>
  </div>
</app-modal>