import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'custom-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class CustomInputComponent implements OnInit {
  @ViewChild('inputEl') inputEl;
  @ViewChild('checkboxEl') checkboxEl;
  @ViewChild('formInputElement') formInputElement;
  @Input() bindValue;
  @Input() pattern?;
  @Input() showIcon?: boolean;
  @Input() isNotEmpty?; // Se l'input ha già un testo e lo seleziono tutto e lo cancello l'onModelChanged non veniva azionato
  @Input() attrName?: string;
  @Input() errorPatternText?: string;
  @Input() id?: string;
  @Input() checkboxText?: string;
  @Input() autocompleteText?: string;
  @Input() inputClass?: string;
  @Input() min?: number;
  @Input() max?: number;
  @Input() step?: number;
  @Input() errorRequiredText?: string;
  @Input() errorMinLengthText?: string;
  @Input() errorMaxLengthText?: string;
  @Input() errorForbiddenText?: string;
  @Input() disabled?: boolean;
  @Input() checked?: boolean;
  @Input() required?: boolean;
  @Input() subtitle?: string;
  @Input() inputContainerClass?: string;
  @Input() inputWrapperClass?: string;
  @Input() title?: string;
  @Input() placeholder?: string;
  @Input() attrAriaLabel: string;
  @Input() type: string;
  @Input() isGlobalResearchInput: string;
  @Input() disableIconClicked: boolean;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();
  @Output() onIconClicked: EventEmitter<any> = new EventEmitter();
  @Input() isFacultyInput: boolean;
  @Input() maxlength: number = 52428;
  previousBindValue: string;

  constructor() { }

  ngOnInit() {
  }

  emitOnModelChanged(data?) {
    if (this.onModelChanged && this.onModelChanged instanceof EventEmitter) {
      if (this.type == "text") {
        if (this.isGlobalResearchInput) {
          let isFocused = this.formInputElement && this.formInputElement.nativeElement && this.formInputElement.nativeElement === document.activeElement;
          if (isFocused) {
            this.onModelChanged.emit(data);
          }
        } else {
          if (this.isFacultyInput) {
            this.onModelChanged.emit(data);
          } else {
            let isFocused = this.formInputElement && this.formInputElement.nativeElement && this.formInputElement.nativeElement === document.activeElement;
            if (isFocused && ((data && data.length) || (this.previousBindValue && this.previousBindValue.length) || this.isNotEmpty)) {
              this.onModelChanged.emit(data);
            }
            this.previousBindValue = data;
          }
        }
      }
      else {
        this.onModelChanged.emit(data);
      }
    }
  }

  emitOnIconClicked(data?) {
    if (this.onIconClicked && this.onIconClicked instanceof EventEmitter) {
      this.onIconClicked.emit(data);
    }
  }
}
