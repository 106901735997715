/*
 * Reducer per l'autenticazione, triggherate ogniqualvolta viene eseguito il dispatch di un'action, per l'update dello state
 *
*/

import { UserAcknowledges } from 'atfcore-commonclasses/bin/classes/anag';

import * as AuthActions from './auth.actions';

export interface AuthState {
  token: string;
  authenticated: boolean;
  loginFinished: boolean;
  userAcknowledges: UserAcknowledges;
  welcomePageShowed: boolean;
  isTaker: boolean;
  isManager: boolean;
  isAdmin: boolean;
  isManagerGlp: boolean;
  isGaiGlp: boolean;
  isHrbpGlp: boolean;
  isFetchingGlpAuths: boolean;
}

// State iniziale
const initialState: AuthState = {
  token: null,
  authenticated: false,
  loginFinished: false,
  userAcknowledges: null,
  welcomePageShowed: false,
  isTaker: false,
  isManager: false,
  isAdmin: false,
  isManagerGlp: false,
  isGaiGlp: false,
  isHrbpGlp: false,
  isFetchingGlpAuths: false
};

// E' necessario utilizzare una funzione normale, le Arrow function di ES6 in questo caso non funzionano. Lo state è passato automaticamente da Ngrx, e ne approfittiamo per dargli un valore iniziale.
// Alla fine la funzione tornerà il nuovo state, una nuova copia aggiornata. Infatti, grazie all'immutabilità dello state, in ogni "case" viene sempre tornato un nuovo oggetto.
export function authReducer(state = initialState, action) {
  // Tramite lo switch, identifico di quale azione è stato fatto il dispatch
  switch (action.type) {
    case (AuthActions.DO_LOGIN):
      return {
        ...state,
        loginFinished: false
      };
    case (AuthActions.DO_LOGIN_FINISHED):
      return {
        ...state,
        loginFinished: true
      };
    case (AuthActions.SET_USER_AUTHENTICATED):
      return {
        ...state,
        authenticated: true,
        anonymous: false,
        isTaker: action.payload && action.payload.isTaker,
        isManager: action.payload && action.payload.isManager,
        isAdmin: action.payload && action.payload.isAdmin
      };
    case (AuthActions.SET_USER_ACKNOWLEDGES):
      return {
        ...state,
        userAcknowledges: action.payload
      };
    case (AuthActions.LOGOUT):
      // Rimuovo il token dal session storage
      if (sessionStorage.getItem('token')) {
        sessionStorage.removeItem('token');
      }
      return {
        ...state,
        token: null,
        authenticated: false,
        isTaker: false,
        isManager: false,
        isAdmin: false
      };
    case (AuthActions.SET_IS_TAKER):
      return {
        ...state,
        isTaker: true
      };
    case (AuthActions.SET_IS_MANAGER):
      return {
        ...state,
        isManager: true
      };
    case (AuthActions.SET_IS_ADMIN):
      return {
        ...state,
        isAdmin: true
      };
    case (AuthActions.RETRIEVE_GLP_AUTHS):
      return {
        ...state,
        isFetchingGlpAuths: true
      };
    case (AuthActions.IS_FETCHING_GLP_AUTHS_FINISHED):
      return {
        ...state,
        isFetchingGlpAuths: false
      };
    case (AuthActions.SET_IS_MANAGER_GLP):
      return {
        ...state,
        isManagerGlp: action.payload
      };
    case (AuthActions.SET_TOKEN):
      sessionStorage.setItem('token', action.payload);
      return {
        ...state,
        token: action.payload
      };
    case (AuthActions.WELCOME_PAGE_SHOWED):
      return {
        ...state,
        welcomePageShowed: true
      };
    default:
      return state;
  }
}

export const getToken = (state: AuthState) => state.token;
export const getUserAcknowledges = (state: AuthState) => state.userAcknowledges;
export const isAuthenticated = (state: AuthState) => state.authenticated;
export const loginFinished = (state: AuthState) => state.loginFinished;
export const isWelcomePageShowed = (state: AuthState) => state.welcomePageShowed;
export const isTaker = (state: AuthState) => state.isTaker;
export const isManagerGlp = (state: AuthState) => state.isManagerGlp;
export const isAdmin = (state: AuthState) => state.isAdmin;
export const isManager = (state: AuthState) => state.isManager;
export const isFetchingGlpAuths = (state: AuthState) => state.isFetchingGlpAuths;