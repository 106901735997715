<div class="dropdown" fxLayout="row">
  <p [ngClass]="{ 'advanced': _advanced }" (click)="toggleDDContent()" *ngIf="!!label && labelPosition == 'left'">{{
    label }}</p>
  <svg-icon (click)="toggleDDContent()" class="dropIcon" [ngClass]="{ 'advanced-svg': _advanced }"
    src="assets/img/gear-meatball-menu.svg"></svg-icon>
  <p [ngClass]="{ 'advanced': _advanced }" (click)="toggleDDContent()"
    *ngIf="!!label && !labelPosition || labelPosition == 'right'">{{ label }}</p>
  <div #dropdowncontent class="dropdown-content {{ contentClass }}" [ngClass]="{ 'opened': opened }">
    <ng-content select="[dropdown-menu-item]">
    </ng-content>
  </div>
</div>
