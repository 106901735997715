import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { FilterItem } from '../select/select.component';

@Component({
  selector: 'custom-select-with-checkbox',
  templateUrl: './select-with-checkbox.component.html',
  styleUrls: ['./select-with-checkbox.component.scss']
})
export class SelectWithCheckboxComponent implements OnInit, AfterViewInit {

  _closeOnSelect: boolean;
  @Input() set closeOnSelect(value: boolean | string) {
    this._closeOnSelect = coerceBooleanProperty(value);
  };

  @Input() noDataFoundLabel?: string;
  @Input() startSearchLabel?: string;
  @Input() loadingDataLabel?: string;
  @Input() placeholderLabel?: string;
  @Input() selectAllLabel?: string;

  /**
   * Select all
   */
  _hasSelectAll: boolean;
  @Input() set hasSelectAll(value: boolean | string) {
    this._hasSelectAll = coerceBooleanProperty(value);
  };

  /**
   * Allow to search for value.
   */
  _searchable: boolean;
  @Input() set searchable(value: boolean | string) {
    this._searchable = coerceBooleanProperty(value);
  };

  /**
   * Permette di resettare una select (NON multioption) in caso in cui venga deselezionata la voce già selezionata.
   */
  _resetOnUnselect: boolean;
  @Input() set resetOnUnselect(value: boolean | string) {
    this._resetOnUnselect = coerceBooleanProperty(value);
  };

  /**
   * Enable virtual scroll for better performance when rendering a lot of data
   */
  _virtualScroll: boolean;
  @Input() set virtualScroll(value: boolean | string) {
    this._virtualScroll = coerceBooleanProperty(value);
  };

  /**
   * Object property to use for selected model. By default binds to whole object.
   */
  @Input() bindValue?: string;

  /**
   * Values selected
   */
  _selectedValue: any;
  @Input()
  set selectedValue(value: FilterItem | FilterItem[]) {
    this._selectedValue = value;
  }
  get selectedValue(): FilterItem | FilterItem[] {
    return this._multiple ? this._selectedValue : [this._selectedValue];
  };
  @Output() selectedValueChange = new EventEmitter<any[]>();

  /**
   * Allows to create custom options.
   */
  @Input() addTag?: boolean | ((term: string) => any | Promise<any>);

  /**
   * You can set the loading state from the outside (e.g. async items loading)
   */
  _loading: boolean;
  @Input() set loading(value: boolean | string) {
    this._loading = coerceBooleanProperty(value);
  };

  /**
   * Clear selected values one by one when clicking backspace.
   */
  @Input() clearOnBackspace: boolean = true;

  @Input() isCoinSelect: boolean;

  _hideSelected: boolean;
  @Input() set hideSelected(value: boolean | string) {
    this._hideSelected = coerceBooleanProperty(value);
  };

  _multiple: boolean;
  @Input() set multiple(value: boolean | string) {
    this._multiple = coerceBooleanProperty(value);
  };

  /**
   * Object property to use for label.
   */
  @Input() bindLabel: string = 'text';

  /**
   * Set the dropdown position on open
   */
  @Input() dropdownPosition?: DropdownPosition = 'bottom';

  @Input() attrAriaLabel?: string;

  /**
   * Marks first item as focused when opening/filtering.
   */
  @Input() markFirst: boolean = true;

  /**
   * List of items
   */
  @Input() items: FilterItem[] | any;

  _id: string;
  @Input() id: string | number;
  @Input() allLabel: string;

  @Output() onBindModelChanged = new EventEmitter<any>();

  /**
   * Fired on model change. Outputs whole model
   */
  @Output() onSelectionChanged = new EventEmitter<any>();

  /**
   * Fired when item is added while [multiple]="true". Outputs added item
   */
  @Output() addItem = new EventEmitter<any>();

  /**
   * Fired when item is removed while [multiple]="true"
   */
  @Output() removeItem = new EventEmitter<any>();

  /**
   * Fired when scrolled to the end of items. Can be used for loading more items in chunks.
   */
  @Output() scrollToEnd = new EventEmitter<any>();

  /**
   * Fired on select dropdown open
   */
  @Output() open = new EventEmitter();

  /**
   * Fired on select dropdown close
   */
  @Output() close = new EventEmitter();

  @ViewChild('alloySelect') alloySelect: NgSelectComponent;

  @Input() _isSelectedAll: boolean = true;

  labelForPlaceholder: string;

  constructor(private renderer: Renderer2) {

  }

  ngOnInit(): void {
    this.labelForPlaceholder = this.placeholderLabel + ': ' + this.allLabel;
    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "select-with-checkbox-" + this.id;

    if (!this._multiple && this._selectedValue && this._selectedValue.length > 0) {
      this._selectedValue = this._selectedValue[0];
    }
  }

  ngAfterViewInit() {
    if (this.attrAriaLabel) {
      setTimeout(() => {
        if (this.alloySelect && this.alloySelect.searchInput) {
          this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "aria-label", this.attrAriaLabel);
        }
      })
    }
  }

  ngDoCheck() {
    if (this.alloySelect?.itemsList?.selectedItems && this.alloySelect?.itemsList?.selectedItems.length == 1) {
      this.labelForPlaceholder = this.placeholderLabel + ': ' + this.alloySelect.itemsList.selectedItems[0].label;
    } else {
      this.labelForPlaceholder = this.placeholderLabel;
      if (this.alloySelect?.itemsList?.selectedItems.length === this.alloySelect?.itemsList?.items.length) {
        this.labelForPlaceholder = this.placeholderLabel + ': ' + this.allLabel;
      }
    }
  }

  resolveItemTitle(item: any, isForSelectedItem?: boolean) {
    return item[this.bindLabel];
  }

  selectAll() {
    this.labelForPlaceholder = this.placeholderLabel + ': ' + this.allLabel;
    this._selectedValue = this.items.map(x => x);
    this._isSelectedAll = true;
    this.selectedValueChange.emit(this._selectedValue);
    this.onSelectionChanged.emit(this._selectedValue);
  }

  unselectAll() {
    this.labelForPlaceholder = this.placeholderLabel + ': ' + this.allLabel;
    this._selectedValue = [];
    this._isSelectedAll = false;
    this.selectedValueChange.emit([]);
    this.onSelectionChanged.emit([]);
  }

  compareWith(item, selected) {
    if (this.bindValue) {
      return item[this.bindValue] === selected[this.bindValue];
    } else if (this.bindLabel) {
      return item[this.bindLabel] === selected[this.bindLabel];
    }
  };

  emitRemoveItem(item: any): void {
    if (this.removeItem && this.removeItem instanceof EventEmitter) {
      if (this.alloySelect.itemsList.selectedItems && this.alloySelect.itemsList.selectedItems.length == 0) {
        this.labelForPlaceholder = this.placeholderLabel + ': ' + this.allLabel;
      } else {
        if (this.alloySelect.itemsList.selectedItems && this.alloySelect.itemsList.selectedItems.length == 1) {
          this.labelForPlaceholder = this.placeholderLabel + ': ' + this.alloySelect.itemsList.selectedItems[0].label;
        } else {
          this.labelForPlaceholder = this.placeholderLabel;
        }
      }
      this.removeItem.emit(item);
    }
  }

  emitAddItem(item: any): void {
    if (this.addItem && this.addItem instanceof EventEmitter) {
      let selectedItemsLength = this.alloySelect.itemsList.selectedItems && this.alloySelect.itemsList.selectedItems.length;
      if (selectedItemsLength == this.items.length) {
        this.labelForPlaceholder = this.placeholderLabel + ': ' + this.allLabel;
      } else {
        if (this.isCoinSelect && (selectedItemsLength == 1 || selectedItemsLength == 2) || selectedItemsLength == 1) {
          if (selectedItemsLength == 2 && this.isCoinSelect) {
            this.labelForPlaceholder = this.placeholderLabel + ': ' + this.alloySelect.itemsList.selectedItems[1].label;
          } else {
            this.labelForPlaceholder = this.placeholderLabel + ': ' + this.alloySelect.itemsList.selectedItems[0].label;
          }
        } else {
          this.labelForPlaceholder = this.placeholderLabel;
        }
      }
      this.addItem.emit(item);
    }
  }

  emitOnOpen(): void {
    const [first] = this.alloySelect.itemsList.selectedItems;
    // Scroll to the first item selected
    if (first) {
      this.alloySelect.itemsList.markItem(first);
    }
    if (this.open && this.open instanceof EventEmitter) {
      this.open.emit();
    }
  }

  emitOnClose(): void {
    if (this.close && this.close instanceof EventEmitter) {
      this.close.emit();
    }
  }

  emitOnSelectionChanged(data: any): void {
    if (this._multiple && data) {
      this._isSelectedAll = this.items.length === data.length;
    }
    this.selectedValueChange.emit(data);
    if (this.onSelectionChanged && this.onSelectionChanged instanceof EventEmitter) {
      this.onSelectionChanged.emit(data);
    }
  }

  emiOnModelChanged(data?: any): void {
    if (this._multiple && data) {
      this._isSelectedAll = this.items.length === data.length;
    }
    if (this.onBindModelChanged && this.onBindModelChanged instanceof EventEmitter) {
      this.onBindModelChanged.emit(data);
    }
  }

  emitOnScrollToEnd(data?: any): void {
    if (this.scrollToEnd && this.scrollToEnd instanceof EventEmitter) {
      this.scrollToEnd.emit(data);
    }
  }


  onChangeValue(isAdd: boolean) {
    if(this._resetOnUnselect && !this._multiple && !isAdd){

      //uso un setTimeout per renderlo async, perchè viene intercettato da ng-select. Trovare una soluzione migliore.
      setTimeout(() => {this.alloySelect.clearModel()}, 50);

    }
  }

}
