import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(duration: unknown, unit: 'min' | 'hour'): string {
    let _duration;

    if(!this.isTypeofDurationValid(duration)) {
      throw new Error('Duration is not a number or string. Typeof duration is : ' + typeof duration);
    }

    if(!this.isTypeofUnitValid(unit)) {
      throw new Error('Unit is not valid');
    }

    _duration = this.getDurationAsNumber(duration);

    return `${_duration} ${this.singularOrPlural(_duration, unit)}`;

  }


  private isTypeofDurationValid(duration: unknown): boolean {
    return typeof duration === 'number' || typeof duration === 'string';
  }

  private isTypeofUnitValid(unit: unknown): boolean {
    return typeof unit === 'string' && (unit === 'min' || unit === 'hour');
  }

  private getDurationAsNumber(duration: unknown): number {
    if(typeof duration === 'number') {
      return duration;
    } else if(typeof duration === 'string') {
      try {
        return parseInt(duration, 10);
      } catch(e) {
        throw new Error('Duration parameter cannot be converted to a number');
      }
    } else {
      throw new Error('Duration is not a number or string. Typeof duration is : ' + typeof duration);
    }
  }

  private singularOrPlural(duration: number, unit: 'min' | 'hour'): string {
    if(unit === 'min') {
      return duration === 1 ? this.translate.instant('generic.MINUTE') : this.translate.instant('generic.MINUTES');
    } else if(unit === 'hour') {
      return duration === 1 ? this.translate.instant('generic.HOUR') : this.translate.instant('generic.HOURS');
    } else {
      throw new Error('Unit is not valid');
    }
  }

}
