import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';

import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from 'src/app/shared/services/url.service';
import { onceIfNotEmpty } from '../utils';
import { isEuass } from '../models/global-application-data.model';

@Injectable()
export class TenantGuard implements CanActivate {
    constructor(private store: Store<fromApp.AppState>,
        private urlService: UrlService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
            .pipe(
                filter((isAuthenticated) => isAuthenticated),
                switchMap(() => {
                    return this.store.select(fromApp.getLoggedUser);
                }),
                onceIfNotEmpty(),
                map(loggedUser => {
                    if (loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant) {
                        /*
                         * Questo codice serviva a effettuare un redirect alla GLP 2020, con traduzione dei link, per EuropAssistance che è entrata a bordo nella GLP 2022 dopo le altre
                        // EURA
                        let isEuassTenant = false;
                        isEuassTenant = isEuass(loggedUser && loggedUser.user && loggedUser.user.userTenant && loggedUser.user.userTenant.tenant);
                        if (isEuassTenant) {
                            // Il tenant dell'utente loggato è di EURA, quindi vado nella sottocartella /euass/
                            const applicationContext = this.urlService.getGLPURL();
                            let suffix = "/platform/home";
                            let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                            if (sessionStorageRedirectUrl) {
                                suffix = sessionStorageRedirectUrl;
                            }
                            if(suffix.indexOf('home/main') !== -1) {
                                suffix = suffix.replace('home/main', 'platform/home');
                            }
                            window.location.href = applicationContext + "/euass/#" + suffix;
                            return false;
                        }
                        */
                    }
                    return true;
                })
            )
    }
}