/*
 * Servizio che gestisce le chiamate ai servizi per le lingue
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { Lang, User } from "atfcore-commonclasses/bin/classes/anag";
import { Item, ItemLang } from "atfcore-commonclasses/bin/classes/item";
import { GeneraliTranslateService } from "generali";

import { UrlService } from "../../shared/services/url.service";

@Injectable()
export class LangsService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService,
    private http: HttpClient,
    private translate: TranslateService,
    private generaliTranslate: GeneraliTranslateService) {
    this._mediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  // Recupera le lingue disponibili a sistema
  getAvailableLangs(): Observable<SenecaResponse<Lang[]>> {
    return this.http.get<SenecaResponse<Lang[]>>(this._mediatorUrl + 'get-all-langs');
  }

  // Recupera la lingua di default del browser
  getBrowserLang(): string {
    return this.translate.getBrowserLang();
  }

  // Lingua attualmente in uso recuperata dal Token
  getUserLang = (user: User): string => {
    return user && user && user.userOptions && user.userOptions.langCode ? user.userOptions.langCode.substring(0, 2) : "it";
  }

  // Metodo che cambia la lingua e le relative traduzioni all'appplicazione
  useLanguage(language: string): void {
    // Imposto la lingua anche per la libreria generali in asincrono per accertarsi che venga aggiunto e non sovrascritto
    this.translate.use(language).subscribe(() => {
      this.generaliTranslate.init(language);
    });
  }

  // Trova l'indice di una lingua all'interno di un Item
  findItemLangIndex: (langCode: string, item: Item) => number = (langCode, item) => {
    let index: number = -1;
    if (item && item.itemLangs) {
      item.itemLangs.forEach((one: ItemLang, idx: number) => {
        if (one.langCode === langCode) {
          index = idx;
        }
      });
    }
    return index;
  }
}
