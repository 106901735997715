import { Component } from '@angular/core';

@Component({
  selector: '[menu-item]',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: block;
        height: 40px;
        width: 100%;
        background: transparent;
        border: none;
      }

      :host:hover {
        background: rgba(0, 0, 0, 0.04);
      }
    `,
  ],
})
export class MenuItemComponent {}
