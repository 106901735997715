import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
import { hasValuesAndBehavioursAccess, onceIfNotEmpty } from '../utils';

@Injectable()
export class ValuesAndBehavioursGuard implements CanActivate {
    constructor(private store: Store<fromApp.AppState>) {
    }

    // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
            .pipe(
                filter((isAuthenticated) => isAuthenticated),
                switchMap(() => {
                    return this.store.select(fromApp.getLoggedUser);
                }),
                onceIfNotEmpty(),
                map(loggedUser => {
                    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
                        return hasValuesAndBehavioursAccess(loggedUser);
                    }

                    return false;
                })
            )
    }
}