<ng-container *ngIf="!isLoadingData">
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M1.22 7.908c2.161-3.097 4.962-4.72 7.769-4.72 3.296 0 6.135 2.147 7.81 4.734v.001a1.067 1.067 0 0 1 0 1.154c-1.673 2.62-4.493 4.736-7.81 4.736-3.353 0-6.12-2.111-7.789-4.724a1.063 1.063 0 0 1 .02-1.179v-.002zm.874.606.001.002C3.637 10.93 6.11 12.75 8.99 12.75c2.847 0 5.372-1.828 6.916-4.245l.001-.002v-.005C14.356 6.105 11.811 4.25 8.99 4.25c-2.37 0-4.876 1.371-6.895 4.264z"
      fill="#6E6F71" />
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9 6.375a2.125 2.125 0 1 0 0 4.25 2.125 2.125 0 0 0 0-4.25zM5.812 8.5a3.187 3.187 0 1 1 6.375 0 3.187 3.187 0 0 1-6.374 0z"
      fill="#6E6F71" />
  </svg>

  <p>{{ views + getViewsLabel() }}</p>
</ng-container>

<ng-container *ngIf="isLoadingData">
  <div class="loader" fxLayout="column" fxLayoutAlign="center start">
    <svg-icon src="assets/icons/loader.svg">
    </svg-icon>
  </div>
</ng-container>