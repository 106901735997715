<div *ngIf="inputValue && !noFormControl" class="datepicker-container" fxLayout="row" fxLayoutAlign="start center"
  [ngClass]="{'disabled': inputValue.disabled}">

  <input-placeholder tabindex="-1" *ngIf="_showLoader" fxFlexFill></input-placeholder>

  <svg-icon [owlDateTimeTrigger]="dt2" tabindex="-1" src="assets/icons/user/icon-date.svg"></svg-icon>

  <input #datePickerInput fxFlex [owlDateTimeTrigger]="dt2" [id]="_id" fxFlexOffset="12px"
    attr.aria-label="{{ placeholder | translate }}" [owlDateTime]="dt2" placeholder="{{ placeholder | translate }}"
    [formControl]="inputValue" [min]="minDate || _newDate" (dateTimeChange)="updateModel($event);" [max]="maxDate"
    [hidden]="_showLoader" [tabindex]="_tabIndex"
    [ngClass]="{'filled': inputIsFilled, 'invalid': inputValue.dirty && inputValue.invalid}">

  <owl-date-time #dt2 [firstDayOfWeek]="optionsDate.firstDayOfWeek" [disabled]="inputValue.disabled"
    [pickerMode]="pickerMode" [pickerType]="pickerType" (afterPickerClosed)="cleanOpenedStyle()"></owl-date-time>
</div>

<div *ngIf="noFormControl" class="datepicker-container" fxLayout="row" fxLayoutAlign="start center">
  <input-placeholder tabindex="-1" *ngIf="_showLoader" fxFlexFill></input-placeholder>

  <svg-icon [owlDateTimeTrigger]="dt2" tabindex="-1" src="assets/icons/user/icon-date.svg"></svg-icon>

  <input #datePickerInput fxFlex [owlDateTimeTrigger]="dt2" [id]="_id" fxFlexOffset="12px"
    attr.aria-label="{{ placeholder | translate }}" [owlDateTime]="dt2" placeholder="{{ placeholder | translate }}"
    [(ngModel)]="inputValue" [min]="minDate || _newDate" (dateTimeChange)="updateModel($event);" [max]="maxDate"
    [hidden]="_showLoader" [tabindex]="_tabIndex" [ngClass]="{'filled': inputIsFilled}">

  <owl-date-time #dt2 [firstDayOfWeek]="optionsDate.firstDayOfWeek" [pickerMode]="pickerMode" [pickerType]="pickerType"
    (afterPickerClosed)="cleanOpenedStyle()"></owl-date-time>
</div>