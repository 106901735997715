import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import { SectionTab } from 'src/app/shared/interfaces/tab.interface';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { getUserAvatar, getUserForename } from 'src/app/shared/utils';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { ProfileService, UserActivity } from '../services/profile.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { RequiredAuth } from 'atfcore-commonclasses/bin/classes/auth';
import { UrlService } from "src/app/shared/services/url.service";
import { authControl } from 'src/app/shared/models/global-application-data.model';
import { SenecaResponse } from 'atfcore-commonclasses';
import { ItemService } from '../services/item.service';
import { ItemsService } from 'src/app/home/services/items.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseSubscriberComponent implements OnInit {
  isAuthenticated: boolean;
  searchActive: boolean = false;
  searchedText: string;

  isEuropAssistanceUser: boolean = false;

  showAccountPanel: boolean = false;
  isLoadingNotifications: boolean = true;
  showNotificationPanel: boolean = false;

  avatar: string;
  userForename: string;
  authObject;

  get hasDarkBackground(): boolean {
    return false;
    // return this.isThisCurrentPage('events') || this.isThisCurrentPage('event');
  }

  readonly headerTabs: SectionTab[] = [
    {
      title: 'generic.HOME',
      link: '/home/main'
    },
    {
      title: 'generic.FEATURED',
      link: '/home/viewAll/FEATURED_COURSES/featuredCoursesPage'
    },
    {
      title: 'generic.COLLECTIONS',
      link: '/collectionList/collectionListHome'
    },
    {
      title: 'generic.SELECTED_FOR_YOU',
      link: '/home/viewAll/COURSES_FOR_YOU/coursesForYouPage'
    },
    {
      title: 'generic.SUPPORT',
      link: '/support'
    }
  ];

  userActivities: UserActivity[] = [];

  _headerHasNotification = false;
  loggedUser: any;

  isPlaceholderIcon: boolean = false;
  isUnreadActivities: boolean = false;
  isLoadingUnreadActivities: boolean;

  countNotifications$: Subscription;
  libraryUrl;

  isManager: boolean;
  isGai: boolean;
  isHrbp: boolean;

  constructor(
    private urlService: UrlService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private itemsService: ItemsService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>) {
    super();
  }

  ngOnInit() {
    this.isNetworkDashboardVisible();
    // Combito tutti gli observable
    combineLatest([
      this.store.select(fromApp.getLoggedUser),
      this.store.select(fromApp.isAuthenticated),
      this.store.select(fromApp.getApplicationLang)
    ])
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        ([loggedUser, isAuthenticated, applicationLang]) => {

          this.isAuthenticated = isAuthenticated;
          if (isAuthenticated && loggedUser) {
            this.userForename = getUserForename(loggedUser);
            this.avatar = getUserAvatar(loggedUser);
            this.loggedUser = loggedUser;
            this.authObject = authControl(this.loggedUser && this.loggedUser.auths);
            this.countLatestUnreadActivities();
            this.countNotifications$ = interval(30000).subscribe(x => {
              this.countLatestUnreadActivities();
            });

            this.isEuropAssistanceUser = !!(this.loggedUser.user.userTenant.tenant === "EUASS_GRP");

            if(!this.isEuropAssistanceUser) {
              this.headerTabs[4] = {
                title: 'generic.FACULTY',
                link: '/faculty/facultyWho'
              }
            }
          }

          if ((!isAuthenticated || !loggedUser) && this.countNotifications$) {
            this.countNotifications$.unsubscribe();
          }

          if (applicationLang) {
            moment.locale(applicationLang);
          }
        });
  }

  // Auth glp per learning dashboard
  isNetworkDashboardVisible() {
    this.itemsService.isNetworkDashboardVisible()
      .subscribe(
        (data: any) => {
          if (data.error) {
            this.toastr.error(this.translate.instant('errors.' + data.error));
          } else if (data.response) {
            this.isManager = data.response.isManager;
            this.isGai = data.response.isGai;
            this.isHrbp = data.response.isHrbp;
          }
        })
  }

  countLatestUnreadActivities() {
    if (this.isAuthenticated && this.loggedUser) {
      this.isLoadingUnreadActivities = true;

      this.profileService.countMyUnreadActivities(true)
        .pipe(takeUntil(this.unsubscribe$.asObservable()))
        .subscribe(
          (result) => {
            if (result.error) {
              this.toastr.error(this.translate.instant("errors." + result.error));
            } else if (result && result.response) {
              this.isUnreadActivities = true;
            } else {
              this.isUnreadActivities = false;
            }
            this.isLoadingUnreadActivities = false;
          }, (err) => {
            if (err) {
              this.toastr.error(err.message || err);
            }
            this.isLoadingUnreadActivities = false;
          });
    }
  }

  getUserAvatar(): string {
    if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.userOptions && this.loggedUser.user.userOptions.avatarImage) {
      return this.loggedUser.user.userOptions.avatarImage;
    } else if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.gender == "M" || this.loggedUser && this.loggedUser.user && this.loggedUser.user.gender == "F") {
      this.isPlaceholderIcon = true;
      if (this.loggedUser && this.loggedUser.user && this.loggedUser.user.gender == "M") {
        return 'assets/icons/placeholder.svg';
      } else {
        return 'assets/icons/placeholder_female.svg';
      }
    } else {
      this.isPlaceholderIcon = false;
      return 'assets/icons/icon-profile.svg';
    }
  }

  trackById(index: any, item: any) {
    return item.id;
  }

  trackByTitle(index: any, item: any) {
    return item.title;
  }

  // Porta alla home page lato utente
  goToHome(): void {
    this.redirectService.goToHome();
  }

  //#region SEARCH

  /**
   * Search function
   * @param newValue New searched value
   */
  onSearchedTextChanged(newValue?: string) {
    this.searchedText = newValue;
  }

  // Porta alla pagina di profilo
  goToProfile(): void {
    this.showAccountPanel = false;
    this.redirectService.goToProfile();
  }

  // Porta alla pagina faculty
  goToFaculty(): void {
    this.showAccountPanel = false;
    this.redirectService.goToFaculty();
  }

  goToEditModalProfile() {
    this.showAccountPanel = false;
    this.redirectService.goToProfileModalOpened();
  }
  /**
   * Search function
   */
  onInputSubmit() {
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendSearchEvent(this.searchedText || '');
    this.onInputBlur();

    // Sono già nella pagina di ricerca, quindi devo fare un refresh
    if (this.router.url.indexOf('search/homeSearch') !== -1) {
      const params = { searchedText: this.searchedText || '' };
      sessionStorage.setItem('searchParams', JSON.stringify(params));
      setTimeout(() => {
        window.location.reload();
      }, 100)
    } else {
      const params = { searchedText: this.searchedText || '' };
      sessionStorage.setItem('searchParams', JSON.stringify(params));

      this.redirectService.goToSearch();
    }
  }

  goToSearch() {
    this.redirectService.goToSearch();
  }

  /**
   * When the blur event happen on search function
   */
  onInputBlur() {
    this.searchActive = false;
  }

  /**
   * Ricerca
   */
  showSearch(): void {
    this.searchedText = null;
    this.searchActive = true;
  }

  /**
   * Show the modal search section
   * Mobile navigation
   */
  goToMobileSearch(): void {
    // Salvo la pagina in cui sono (affinché poi premendo la X nell'header io possa tornare in questa pagina)
    sessionStorage.setItem('redirectAfterMobileSearchClosed', this.router.url);
    this.redirectService.goToMobileSearch();
  }

  closeModalSearch(): void {
    const redirectAfterMobileSearchClosed = sessionStorage.getItem('redirectAfterMobileSearchClosed');

    if (redirectAfterMobileSearchClosed) {
      this.router.navigate([redirectAfterMobileSearchClosed]);
      sessionStorage.removeItem('redirectAfterMobileSearchClosed');
    } else {
      this.redirectService.goToHome();
    }
  }

  //#endregion

  //#region ACCOUNT

  /**
   * Open account dialog
   */
  openAccountDialog() {
    this.showAccountPanel = true;
    setTimeout(() => {
      const element: HTMLElement = document.getElementById('headerSearchAccount');;
      element.focus();
    }, 0);
  }

  /**
   * With the blur event hide the account dialog
   * target: Desktop
   * @param event Event of blur
   */
  onBlurAccount(event?) {
    this.showAccountPanel = false;
  }

  // Vai all'edit items della Library
  goToLibraryEditItem() {
    // Tolgo i dati sporchi di eventuali precedenti redirect
    if (sessionStorage.getItem('ngStorage-identityToken')) {
      sessionStorage.removeItem('ngStorage-identityToken');
    }
    if (sessionStorage.getItem('ngStorage-adminMode')) {
      sessionStorage.removeItem('ngStorage-adminMode');
    }
    if (sessionStorage.getItem('ngStorage-originalRequestedURL')) {
      sessionStorage.removeItem('ngStorage-originalRequestedURL');
    }
    if (sessionStorage.getItem('ngStorage-originalRequestedParams')) {
      sessionStorage.removeItem('ngStorage-originalRequestedParams');
    }
    if (sessionStorage.getItem('ngStorage-networkGai')) {
      sessionStorage.removeItem('ngStorage-networkGai');
    }
    if (sessionStorage.getItem('ngStorage-networkPersonal')) {
      sessionStorage.removeItem('ngStorage-networkPersonal');
    }

    this.libraryUrl = this.getLibraryUrl();
  }

  canManageSpecialProjects() {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.GTP_MANAGE_SPECIAL_PROJECTS) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageSlidershowHome() {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.TRAININGPLATFORM_BACKOFFICE_HOME_SLIDESHOW) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageInspiredTags() {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.TRAININGPLATFORM_BACKOFFICE_MANAGE_TAG_GET_INSPIRED) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  canManageFaq() {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.TRAININGPLATFORM_FAQ_ADMIN) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  //#endregion

  //#region NOTIFICATION

  /**
   * Get the difference from now to the date
   * Use of moment.js
   * @param date Date
   */
  getTimeFromNow(date: string): string {
    return moment(date).fromNow();
  }

  /**
   * Open notification dialog
   */
  openNotificationDialog() {
    this.getUserNotifications();
  }

  /**
   * With the blur event hide the notification dialog
   * target: Desktop
   * @param event Event of blur
   */
  onBlurNofification(event?) {
    this.showNotificationPanel = false;
  }

  onNotificationClick(notification): void {
    if (notification) {

      if (notification.notificationStatus == "TO_READ") {
        this.authService.markNotificationAsRead(notification.notificationQueueId)
          .subscribe(
            (itemsData: any) => {
              if (itemsData.error) {
                this.toastr.error(this.translate.instant('errors.' + itemsData.error));
              } else {
                window.location.href = notification.action;
              }
            })
      } else {
        window.location.href = notification.action;
      }
    }
    this.showNotificationPanel = false;
  }

  // Recupera gli aggiornamenti (prima tab)
  getUserNotifications() {
    this.showNotificationPanel = true;
    this.isLoadingNotifications = true;

    this.profileService.getMyLatestActivities(0, 50, true)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (result) => {
          if (result.error) {
            this.toastr.error(this.translate.instant("errors." + result.error));
          } else {
            this.userActivities = result.response;

            setTimeout(() => {
              const element: HTMLElement = document.getElementById('headerSearchNotification');;
              element.focus();
            }, 0);
          }
          this.isLoadingNotifications = false;
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
          this.isLoadingNotifications = false;
        });
  }

  headerHasNotification(): void {
    // this._headerHasNotification = false;
    // if (this.notificationValues && this.notificationValues.length) {
    //   let hasNotification = false;
    //   for (let i = 0; i < this.notificationValues.length; i++) {
    //     if (this.notificationValues[i].newNotification) {
    //       hasNotification = true;
    //       break;
    //     }
    //   }
    //   this._headerHasNotification = hasNotification;
    // }
  }

  //#endregion

  goToLocalLogin() {
    this.redirectService.goToLocalLogin();
  }

  /**
   * Apre il menu
   */
  goToMobileMenuPage(): void {
    sessionStorage.setItem('redirectAfterMobileSearchClosed', this.router.url);
    this.redirectService.goToMobileMenuPage();
  }

  // Verifica in quale pagina sono
  isThisCurrentPage(page: string) {
    return page && this.router && this.router.url && this.router.url.indexOf(page) !== -1;
  }

  // Se posso entrare sul Coursemanager edit corsi
  isUserAuthorizedOnEditCoursemanager() {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.COURSEMANAGER_MANAGE_COURSES) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Se posso entrare sulla Library edit oggetti
  isUserAuthorizedOnEditLibrary() {
    let canView: boolean = false;
    if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length) {
      for (let i = 0, authsLength = this.loggedUser.auths.length; i < authsLength; i++) {
        let currentAuth = this.loggedUser.auths[i];
        if (currentAuth === RequiredAuth.LIBRARY_ADMIN_MANAGE_ITEM) {
          canView = true;
          break;
        }
      }
      return canView;
    }
  }

  // Esegue il logout
  logout() {
    const redirectAfterMobileSearchClosed = sessionStorage.getItem('redirectAfterMobileSearchClosed');
    if (redirectAfterMobileSearchClosed) {
      sessionStorage.removeItem('redirectAfterMobileSearchClosed');
    }

    this.redirectService.removeSessionStorageSearchData();

    this.store.dispatch(new AuthActions.DoLogout());
  }

  goToEditCoursemanagerCourse() {
    let skipSsoParam: string = "";
    this.authService.isSsoEnabledForLoggedUser()
      .subscribe((hasSsoData) => {
        if (hasSsoData.error) {
        } else {
          if (!hasSsoData.response) {
            skipSsoParam = skipSsoParam + '?skipSso=true';
          }
          const courseManagerUrl = this.urlService.getCoursemanagerUrl();
          // Invia un tracciamento a Google Tag Manager per Google Analytics per quanto riguarda l'apertura di un link extra piattaforma
          this.analyticsService.sendOutboundLinkEvent(courseManagerUrl.url + '/#/initiatives' + skipSsoParam);
          window.open(courseManagerUrl.url + '/#/initiatives' + skipSsoParam);
        }
      })
  }

  // Ottiene l'url della Library
  getLibraryUrl() {
    const token: string = sessionStorage.token;

    let skipSsoParam: string = "";
    this.authService.isSsoEnabledForLoggedUser()
      .subscribe((hasSsoData: any) => {
        if (hasSsoData.error) {
        } else {
          if (!hasSsoData.response) {
            skipSsoParam = skipSsoParam + '?skipSso=true';
          }

          this.authService.getUserMainApplicationData(token)
            .subscribe((data: any) => {
              if (data.error) {
              } else {
                let libraryAppEnabled = data && data.response;
                if (libraryAppEnabled) {
                  let libraryUrl: any = null;

                  // Recupero l'url della Library in base all'autorizzazione dell'utente
                  if (libraryAppEnabled === "GEN_GLP" || libraryAppEnabled === "GEN-GLP" || libraryAppEnabled === "GEN_LIB" || libraryAppEnabled === "GEN-LIB" || libraryAppEnabled === "GTEL_LIB" || libraryAppEnabled === "GTEL-LIB") {
                    libraryUrl = this.urlService.getLibraryURL();
                  } else if (libraryAppEnabled === "ALL_LIB" || libraryAppEnabled === "ALL-LIB") {
                    libraryUrl = this.urlService.getLibraryAlleanzaURL();
                  } else if (libraryAppEnabled === "DAS_LIB" || libraryAppEnabled === "DAS-LIB") {
                    libraryUrl = this.urlService.getLibraryDasURL();
                  };
                  // Invia un tracciamento a Google Tag Manager per Google Analytics per quanto riguarda l'apertura di un link extra piattaforma
                  this.analyticsService.sendOutboundLinkEvent(libraryUrl + '/#/app/library/homeAdmin/' + skipSsoParam);
                  window.open(libraryUrl + '/#/app/library/homeAdmin/' + skipSsoParam);
                }
              }
            })
        }
      })
  }

  onTabClick(tab: SectionTab) {
    // Se si vuole fare in modo di salvare i dati della ricerca (perché ad es. l'utente dalla vetrina va alle collezioni, e poi quando preme il tasto back vuole rivedere i filtri che aveva selezionato) questa funzione va commentata
    this.redirectService.removeSessionStorageSearchData();
    this.router.navigate([tab.link]);
  }

  isLinkActive(link: string) {
    if (link && link.length) {
      return this.router.url.includes(link);
    }
  }

  goToFacultyBackoffice() {
    this.authService.createRetrieveTokenAfterLogin(true)
      .subscribe((senecaResponse: SenecaResponse<string>) => {
        if (senecaResponse?.error || !senecaResponse?.response) {
        } else {
          const ssortkqp = senecaResponse.response;
          const glpUrl = this.urlService.getGLPURL();
          // Invia un tracciamento a Google Tag Manager per Google Analytics per quanto riguarda l'apertura di un link extra piattaforma
          this.analyticsService.sendOutboundLinkEvent(glpUrl + "/old/#/app/gtp/facultyAdmin/home2022");
          window.open(`${glpUrl}/old/#/app/gtp/facultyAdmin/home2022?ssortkqp=${ssortkqp}`);
        }
      })
  }

  // Vai alla learning dashboard
  goToLearningDashboard() {
    this.authService.createRetrieveTokenAfterLogin(true)
      .subscribe((senecaResponse: SenecaResponse<string>) => {
        if (senecaResponse?.error || !senecaResponse?.response) {
        } else {
          const ssortkqp = senecaResponse.response;
          const glpUrl = this.urlService.getGLPURL();
          // Invia un tracciamento a Google Tag Manager per Google Analytics per quanto riguarda l'apertura di un link extra piattaforma
          this.analyticsService.sendOutboundLinkEvent(glpUrl + "/old/#/app/gtp/newDashboard2022");
          window.open(`${glpUrl}/old/#/app/gtp/newDashboard2022?ssortkqp=${ssortkqp}`);
        }
      })
  }
}
