<div [ngClass]="inputWrapperClass" class="input-wrapper">
  <label *ngIf="title" class="control-label" for="searchinput">{{ title }}</label>
  <div [ngClass]="inputContainerClass">
    <div class="input-wrapper-icon" [ngClass]="{ 'show-icon' : showIcon }">
      <input [hidden]="type && type === 'checkbox'" [type]="type" [attr.name]="attrName" [(ngModel)]="bindValue"
        (ngModelChange)="emitOnModelChanged($event)" [pattern]="pattern" [placeholder]="placeholder"
        [attr.aria-label]="attrAriaLabel" [ngClass]="inputClass" [disabled]="disabled" [required]="required"
        #inputEl="ngModel" id="{{ id || '' }}" (keyup.enter)="emitOnIconClicked($event)" [min]="min" [max]="max"
        [step]="step" [maxlength]="maxlength" autocomplete="{{ autocompleteText || 'autocomplete-input' }}"
        #formInputElement>

      <!--<label *ngIf="type && type === 'checkbox'" for="{{ id }}">
        <span>{{ checkboxText }}</span>
      </label>-->

      <label class="container-checkbox" [hidden]="type && type !== 'checkbox'" for="{{ id }}">{{ checkboxText }}
        <input type="checkbox" [disabled]="disabled" [required]="required" [checked]="checked"
          (ngModelChange)="emitOnModelChanged($event)" [placeholder]="placeholder" [attr.aria-label]="attrAriaLabel"
          #checkboxEl="ngModel" id="{{ id || '' }}" [(ngModel)]="bindValue">
        <span class="checkmark"></span>
      </label>

      <!--<label class="container-radio">One
        <input type="radio" checked="checked" name="radio">
        <span class="checkmark"></span>
      </label>-->

      <div [ngClass]="{'pointerevents-none': disableIconClicked}" *ngIf="showIcon" fxLayout="row"
        fxLayoutAlign="center center" (click)="emitOnIconClicked($event)" class="search-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M11.5684 10.449L15.7656 14.6328C15.8379 14.7053 15.8936 14.7885 15.9326 14.8772C16.0615 15.1704 16.0049 15.5251 15.7646 15.7656C15.6074 15.9221 15.4014 16 15.1953 16C14.9902 16 14.7842 15.9221 14.627 15.7656L10.4238 11.5753C9.33008 12.415 7.96484 12.9205 6.48047 12.9205C2.90723 12.9205 0 10.0223 0 6.46021C0 2.89819 2.90723 0 6.48047 0C10.0537 0 12.9609 2.89819 12.9609 6.46021C12.9609 7.9657 12.4375 9.34924 11.5684 10.449ZM6.48145 1.60254C3.79492 1.60254 1.6084 3.78186 1.6084 6.46033C1.6084 9.13867 3.79492 11.318 6.48145 11.318C9.16797 11.318 11.3545 9.13867 11.3545 6.46033C11.3545 3.78186 9.16797 1.60254 6.48145 1.60254Z"
            fill="#3C3C3C" />
        </svg>
      </div>
    </div>

    <p *ngIf="subtitle" class="help-block">{{ subtitle }}</p>
  </div>
  <div fxLayout="row" *ngIf="inputEl && inputEl.invalid && (inputEl.dirty || inputEl.touched)"
    class="input-error-message">
    <div *ngIf="inputEl.errors.required">
      {{ errorRequiredText }}
    </div>
    <div *ngIf="inputEl.errors.minlength">
      {{ errorMinLengthText }}
    </div>
    <div *ngIf="inputEl.errors.maxlength">
      {{ errorMaxLengthText }}
    </div>
    <div *ngIf="inputEl.errors.forbiddenName">
      {{ errorForbiddenText }}
    </div>
    <div *ngIf="inputEl.errors.pattern">
      {{ errorPatternText }}
    </div>
  </div>
  <div fxLayout="row" *ngIf="checkboxEl && checkboxEl.invalid && (checkboxEl.dirty || checkboxEl.touched)"
    class="input-error-message">
    <div *ngIf="checkboxEl.errors.required">
      {{ errorRequiredText }}
    </div>
    <div *ngIf="checkboxEl.errors.minlength">
      {{ errorMinLengthText }}
    </div>
    <div *ngIf="checkboxEl.errors.maxlength">
      {{ errorMaxLengthText }}
    </div>
    <div *ngIf="checkboxEl.errors.forbiddenName">
      {{ errorForbiddenText }}
    </div>
  </div>
</div>