<!-- Header desktop - Immagine di sfondo e tasti azione -->
<div class="item-page-header" [ngStyle]="{ 'background-image': getItemPageHeaderBackgoundImage() }">
  <div *ngIf="itemDetails" class="item-page-header-content" fxLayout="column" fxLayoutAlign="start start">
    <!-- Indietro -->
    <div (click)="goBack()" class="back-btn-wrapper" fxLayout="row" fxLayoutAlign="center center">
      <svg-icon src="../../../assets/icons/icon-back.svg"></svg-icon>
      <p translate="generic.BACK_ITEM_DETAILS"></p>
    </div>

    <div fxShow class="title-actions-wrapper">
      <div class="title-wrapper" fxLayout="column">
        <!-- Nome dell'oggetto contenitore -->
        <p tabindex="0" class="margin-bottom5" *ngIf="containerTitleOfCurrentItem">{{ containerTitleOfCurrentItem }}</p>
        <!-- Titolo dell'oggetto -->
        <h1 class="title" tabindex="-1">{{ itemDetails?.title || '' }}</h1>
        <!-- Tipologia dell'oggetto -->
        <p class="type-title" tabindex="0" tabindex="-1">{{ itemTypeLabel || '' }}</p>
        <!-- Sottotitolo dell'oggetto -->
        <p class="subtitle" tabindex="0" *ngIf="itemDetails && itemDetails.subtitle"
          [innerHTML]="itemDetails.subtitle | safeHtml: 'html'">
        </p>

        <!-- Gestione iscrizioni (multiedizioni) -->
        <div *ngIf="!isLoadingEditions && itemDetails" class="multiedition-status-label">
          <div
            *ngIf="selectedEditionOfMainSelect?.labelStatusToDisplay && selectedEditionOfMainSelect.labelStatusToDisplay == 'subscribed'"
            fxLayout="row" fxLayoutAlign="start center">
            <svg-icon class="margin-right10 icon-check" src="../../../assets/icons/badge/icon-check.svg"></svg-icon>
            <h6 fxLayout="row" fxLayoutAlign="start center">{{'itemDetailsPage.edition.SUBSCRIBED' | translate}}.</h6>
            <h6 (click)="openCancelSubscriptionModal(selectedEditionOfMainSelect)" class="link unsubscribe-link"
              translate="itemDetailsPage.edition.UNSUBSCRIBE"></h6>
          </div>
        </div>

        <!-- Avanzamento -->
        <div
          *ngIf="!isGettingItemDetails && itemDetails && !collectionId && (!isSyllabusCourse || (isSyllabusCourse && (itemDetails.isCompleted || itemDetails.isVerified))) && !isPlaylistPlan"
          fxLayout="row" fxLayoutAlign="start center" class="progress-status-label">
          <div fxLayout="row" *ngIf="itemDetails.isVerified || itemDetails.isCompleted">
            <svg-icon class="margin-right10 icon-check" src="../../../assets/icons/badge/icon-check.svg"></svg-icon>
            <!-- Completato -->
            <h6 fxLayout="row" fxLayoutAlign="start center"
              *ngIf="itemDetails.isCompleted && !itemDetails.isVerified && (!isSyllabusCourse || isAssessment)">
              {{ 'itemDetailsPage.generic_card.COMPLETED' | translate }}</h6>
            <!-- Verificato -->
            <h6 fxLayout="row" fxLayoutAlign="start center" *ngIf="itemDetails.isVerified">{{
              'itemDetailsPage.generic_card.VERIFIED' |
              translate }}</h6>
            <!-- Frequentato -->
            <h6 fxLayout="row" fxLayoutAlign="start center"
              *ngIf="itemDetails.isCompleted && isSyllabusCourse && !isAssessment">
              {{ 'itemDetailsPage.generic_card.TAKE_PART' | translate }}</h6>
          </div>
          <ng-container *ngIf="!itemDetails.isCompleted && !itemDetails.isVerified && !webCourseId">
            <h6 fxLayout="row" fxLayoutAlign="start center">{{ getProgressPercentage() }}
            </h6>
          </ng-container>
          <ng-container *ngIf="!itemDetails.isCompleted && webCourseId">
            <h6 class="confirm-web-course-completion" fxLayout="row" fxLayoutAlign="start center"
              (click)="onLibraryItemMainBtnActionClicked('SET_WEB_COURSE_AS_COMPLETE')">{{
              'generic.SET_WEB_COURSE_AS_COMPLETE' | translate }}
            </h6>
          </ng-container>
        </div>

      </div>

      <!-- Lista di azioni-->
      <div *ngIf="!isGettingItemDetails && !isLoadingEditions && itemDetails" fxLayout="row"
        fxLayoutAlign="start center">
        <item-details-header-actions [isSyllabusCourse]="isSyllabusCourse" [editions]="editions"
          [isOnlineCourse]="isOnlineCourse" [isExternalCourse]="isExternalCourse" [isWebinar]="isWebinar"
          (scrollIntoPage)="scrollIntoPage($event)" [isPlaylistPlan]="isPlaylistPlan" [completedItems]="completedItems"
          (onCourseMainBtnActionClicked)="onCourseMainBtnActionClicked($event)"
          (selectedEditionCallToActionClicked)="onCallToActionClicked($event)"
          [selectedEditionOfMainSelect]="selectedEditionOfMainSelect"
          (onSecondaryActionClicked)="onSecondaryActionClicked($event)"
          (onLibraryItemMainBtnActionClicked)="onLibraryItemMainBtnActionClicked($event)"
          [libraryItemSecondBtnAction]="getLibraryItemSecondBtnAction()"
          [libraryItemMainBtnAction]="getLibraryItemMainBtnAction()" [iDislikeThis]="iDislikeThis"
          [isBookmarked]="itemDetails.isBookmarked" [iLikeThis]="iLikeThis" (onLike)="like()" (onDislike)="dislike()"
          (toggleWishlist)="toggleWishlist()" [wishlistEnabled]="itemDetails.wishlistEnabled"
          [secondaryActionOptions]="secondaryActionOptions" [completionPercentage]="itemDetails.completionPercentage"
          [addedToWishlist]="itemDetails.addedToWishlist" [collectionId]="collectionId" [webCourseId]="webCourseId"
          (toggleBookmark)="toggleBookmark()" [uniqueEditionMainBtnAction]="getUniqueEditionMainBtnAction()"
          [showLpContinue]="showLpContinue" [firstIncompleteId]="firstIncompleteId"
          (openWebinarUniqueEditionDetails)="openWebinarUniqueEditionDetails()" [isCatalogStage]="isCatalogStage"
          [isExternalCourse]="isExternalCourse"></item-details-header-actions>
      </div>
    </div>

    <!-- header corso
    <item-details-header-social (goBack)="goBack()" [isGettingItemDetails]="isGettingItemDetails" [duration]="duration"
      [itemDetails]="itemDetails" [isSyllabusCourse]="isSyllabusCourse" [hasMoreEditions]="hasMoreEditions"
      [editions]="editions" [collectionId]="collectionId" [itemTypeLabel]="itemTypeLabel" [webCourseId]="webCourseId"
      [iLikeThis]="iLikeThis" [iDislikeThis]="iDislikeThis" (onLike)="like()" (onDislike)="dislike()"
      (toggleBookmark)="toggleBookmark()" (toggleWishlist)="toggleWishlist()" [likeCounter]="likeCounter">
    </item-details-header-social>  -->
  </div>
</div>

<ng-container *ngIf="!isGettingItemDetails && !isGettingParentDetails && !isLoadingEditions">
  <!-- Continua LP playlist sezione nuova per oggetti che fanno parte di un learning plan -->
  <div class="info-header-wrapper lp-banner" *ngIf="!isGettingParentDetails && showBanner && !isGettingItemDetails">
    <div [ngStyle]="{'margin': isSurveyItem ? '25px 0 25px 0' : '25px 0 -58px 0'}" class="learning-plan-object-info"
      fxLayout.gt-sm="row" fxLayoutGap.gt-sm="24px" fxLayoutAlign.gt-sm="space-between stretch" fxLayout="column"
      fxLayoutAlign="center center" fxLayoutGap="16px">
      <div class="text-content-wrapper" fxLayout="column" fxLayoutAlign="space-evenly start" fxLayoutGap="8px">
        <p class="title" *ngIf="!isPlaylistPlan" translate="itemDetailsPage.PART_OF_LP"></p>
        <p class="title" *ngIf="isPlaylistPlan" translate="itemDetailsPage.PART_OF_PL"></p>
        <div class="text-progress" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <p>{{ getProgressItemOfLp() }} <span (click)="goToLPFromItem()" class="link"
              translate="itemDetailsPage.GO_TO_DETAIL"> </span></p>
        </div>
      </div>
      <div class="progress-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <p class="lp-progress" *ngIf="!isPlaylistPlan" translate="itemDetailsPage.LP_PROGRESS"></p>
        <p class="lp-progress" *ngIf="isPlaylistPlan" translate="itemDetailsPage.PL_PROGRESS"></p>
        <p class="percentage">{{ totalLpProgress }}%</p>
      </div>
      <div class="progress-wrapper" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="isStartOrContinueLpVisible() || isPlaylistBtnInBannerVisibile()">
        <!-- Pulsante LP -->
        <ng-container *ngIf="isStartOrContinueLpVisible()">
          <custom-button importance="primary" noMargin="true" (onClick)="goToNextItemInLp()"
            [text]="('itemDetailsPage.CONTINUE_LP' | translate | uppercase)"
            [ariaLabel]="('itemDetailsPage.CONTINUE_LP' | translate | uppercase)"
            [disabled]="itemDetails.completionPercentage < 100">
          </custom-button>
        </ng-container>
        <!-- Pulsante Playlist -->
        <ng-container *ngIf="isPlaylistBtnInBannerVisibile()">
          <custom-button importance="primary" noMargin="true" (onClick)="goToNextItemInLp()" text="{{ (itemDetails.completionPercentage ? ('itemDetailsPage.CONTINUE_PL' | translate | uppercase) : ('itemDetailsPage.START_PL' | translate | uppercase))
        }}"
            ariaLabel="{{ (itemDetails.completionPercentage ? ('itemDetailsPage.CONTINUE_PL' | translate | uppercase) : ('itemDetailsPage.START_PL' | translate | uppercase)) }}">
          </custom-button>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Certificazioni-->
  <div class="info-header-wrapper certificate"
    *ngIf="!isFetchingCoursesCertifications && editionsCertifications && editionsCertifications.length && !notShowBannerOfCertificateMessage()">
    <div class="info-header-content certificates" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start start">
      <span>
        <p class="display-inline" translate="generic.GOT_CERTIFIED_ADVICE"></p>
        <p class="display-inline cursor-pointer text-underline" (click)="openTab({ id:'editionsCertifications'} )"
          translate="generic.CLICK_HERE_CERTIFICATE_SECTION"></p>
      </span>
    </div>
  </div>

  <div *ngIf="isFetchingCoursesCertifications">
    <generali-loader></generali-loader>
  </div>
</ng-container>

<!-- Scorm/Kaltura (se oggetto library oppure corso del cm2 che wrappa un oggetto library) - e non è un oggetto prestabile -->
<ng-container *ngIf="!isGettingItemDetails">
  <div *ngIf="showPlayerBoxContainer()" id="player-wrapper" class="player-wrapper"
    [ngClass]="{ 'isScorm': (itemDetails && itemDetails.isScorm) || (itemLinkedToCourse && itemLinkedToCourse.isScorm) }">
    <!-- Box con l'immagine dell'oggetto library immagine (wrappato da un corso cm2) -->
    <ng-container *ngIf="showPlayerOfWrappedImage()">
      <div class="kaltura-player-wrapper">
        <!-- Placeholder per quando l'utente deve ancora iscriversi -->
        <div fxLayout="row"
          *ngIf="!isUserSubscribedToWrapperLibraryItem() && canUserSubscribeToCourseThatWrapLibraryItem()"
          class="subscribe-before-access-to-player-container" fxLayoutAlign="center center">
          <div *ngIf="getUniqueEditionMainBtnAction()">
            <!-- Azione primaria -->
            <custom-button importance="player-placeholder-btn" noMargin="true"
              (onClick)="onCourseMainBtnActionClicked(getUniqueEditionMainBtnAction())"
              text="{{ getUniqueEditionMainBtnAction() && (('itemDetailsPage.buttons.' + getUniqueEditionMainBtnAction()) | translate) }}"
              ariaLabel="{{ getUniqueEditionMainBtnAction() && (('itemDetailsPage.buttons.' + getUniqueEditionMainBtnAction()) | translate) }}">
            </custom-button>
          </div>
        </div>

        <!-- Player quando è sbloccato -->
        <div fxLayout="row" class="subscribe-before-access-to-player-container"
          *ngIf="isUserSubscribedToWrapperLibraryItem()"
          [ngStyle]="{ 'background-image': itemLinkedToCourse.damObjectUrl ? 'url(' + itemLinkedToCourse.damObjectUrl + ')' : '' }"
          fxLayoutAlign="center center">
        </div>
      </div>

    </ng-container>

    <!-- Kaltura Player quando non è un'immagine (corso library wrappato da un corso cm2) -->
    <ng-container *ngIf="showPlayerOfWrappedDam()">
      <div class="kaltura-player-wrapper">
        <!-- Placeholder per quando l'utente deve ancora iscriversi -->
        <div fxLayout="row"
          *ngIf="!isUserSubscribedToWrapperLibraryItem() && canUserSubscribeToCourseThatWrapLibraryItem()"
          class="subscribe-before-access-to-player-container" fxLayoutAlign="center center">
          <div *ngIf="getUniqueEditionMainBtnAction()">
            <!-- Azione primaria -->
            <custom-button importance="player-placeholder-btn" noMargin="true"
              (onClick)="onCourseMainBtnActionClicked(getUniqueEditionMainBtnAction())"
              text="{{ getUniqueEditionMainBtnAction() && (('itemDetailsPage.buttons.' + getUniqueEditionMainBtnAction()) | translate) }}"
              ariaLabel="{{ getUniqueEditionMainBtnAction() && (('itemDetailsPage.buttons.' + getUniqueEditionMainBtnAction()) | translate) }}">
            </custom-button>
          </div>
        </div>

        <!-- Player quando è sbloccato -->
        <div *ngIf="isUserSubscribedToWrapperLibraryItem()">
          <ng-container *ngIf="!isGettingItemDetails">
            <kaltura-player (videoInProgress)="itemInProgress($event)" (itemConsumed)="itemConsumed($event)"
              [itemId]="itemLinkedToCourse.itemId" [playerVisible]="isKalturaPlayerVisible"
              [seekTo]="itemLinkedToCourse.damPlayerSeekTo" [kalturaScript]="itemLinkedToCourse.embeddedPlayerCode">
            </kaltura-player>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- Scorm (corso library wrappato da un corso cm2) -->
    <ng-container *ngIf="showPlayerOfWrapperScorm()">
      <div [ngClass]="{ 'kaltura-player-wrapper': !itemLinkedToCourse.isScorm }">
        <!-- Placeholder per quando l'utente deve ancora iscriversi -->
        <div fxLayout="row"
          *ngIf="!isUserSubscribedToWrapperLibraryItem() && canUserSubscribeToCourseThatWrapLibraryItem()"
          class="subscribe-before-access-to-player-container" [ngClass]="{ 'isScorm': itemLinkedToCourse.isScorm }"
          fxLayoutAlign="center center">
          <div *ngIf="getUniqueEditionMainBtnAction()">
            <!-- Azione primaria -->
            <custom-button importance="player-placeholder-btn" noMargin="true"
              (onClick)="onCourseMainBtnActionClicked(getUniqueEditionMainBtnAction())"
              text="{{ getUniqueEditionMainBtnAction() && (('itemDetailsPage.buttons.' + getUniqueEditionMainBtnAction()) | translate) }}"
              ariaLabel="{{ getUniqueEditionMainBtnAction() && (('itemDetailsPage.buttons.' + getUniqueEditionMainBtnAction()) | translate) }}">
            </custom-button>
          </div>
        </div>

        <!-- Player quando è sbloccato -->
        <div class="scorm-player-box-bg-placeholder" [ngClass]="{ 'isScorm': itemLinkedToCourse.isScorm }"
          *ngIf="isUserSubscribedToWrapperLibraryItem()" fxLayout="row" fxLayoutAlign="center center">
          <ng-container *ngIf="!isGettingItemDetails">
            <scorm-player [stageId]="itemDetails.itemId" [editions]="editions" [itemWrapper]="itemLinkedToCourse"
              [scormId]="itemLinkedToCourse.scormId" [playerVisible]="true"
              (checkEnableCertificaion)="checkEnableCertificaion(true)"></scorm-player>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <!-- Box con l'immagine dell'oggetto library immagine (corso library isolato) -->
    <ng-container *ngIf="showPlayerOfImage()">
      <div class="kaltura-player-wrapper">
        <div fxLayout="row" class="subscribe-before-access-to-player-container"
          [ngStyle]="{ 'background-image': itemDetails.damObjectUrl ? 'url(' + itemDetails.damObjectUrl + ')' : '' }"
          fxLayoutAlign="center center">
        </div>
      </div>
    </ng-container>
    <!-- Kaltura Player (corso library isolato) -->
    <ng-container *ngIf="showPlayerOfDam()">
      <div *ngIf="!isDocument" class="kaltura-player-wrapper scorm-player-box-bg-placeholder" fxLayout="row"
        fxLayoutAlign="center center">
        <ng-container *ngIf="!isGettingItemDetails">
          <kaltura-player (videoInProgress)="itemInProgress($event)" (itemConsumed)="itemConsumed($event)"
            [itemId]="itemDetails.itemId" [playerVisible]="!isGettingItemDetails" [seekTo]="itemDetails.damPlayerSeekTo"
            [kalturaScript]="itemDetails.embeddedPlayerCode">
          </kaltura-player>
        </ng-container>
      </div>

      <div *ngIf="isDocument" class="kaltura-player-wrapper background-document-item" fxLayout="row"
        fxLayoutAlign="center center">
        <ng-container *ngIf="!isGettingItemDetails">
          <custom-button importance="primary" noMargin="true"
            (onClick)="onLibraryItemMainBtnActionClicked('DOWNLOAD_THE_DOCUMENT')"
            text="{{ 'generic.OPEN_DOCUMENT' | translate | uppercase }}"
            ariaLabel="{{ 'generic.OPEN_DOCUMENT' | translate | uppercase }}">
          </custom-button>
        </ng-container>
      </div>
    </ng-container>
    <!-- Scorm (corso library isolato) -->
    <ng-container *ngIf="showPlayerOfScorm()">
      <div class="kaltura-player-wrapper scorm-player-box-bg-placeholder" [ngClass]="{ 'isScorm': itemDetails.isScorm }"
        fxLayout="row" fxLayoutAlign="center center">
        <ng-container *ngIf="!isGettingItemDetails">
          <scorm-player [projectId]="projectId" [editions]="editions" [itemWrapper]="itemDetails" [playerVisible]="true"
            [scormId]="itemDetails.scormId" (checkEnableCertificaion)="checkEnableCertificaion(true)">
          </scorm-player>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- Anchor per lo scroll -->
<div id="course-tab-anchor"></div>

<!-- Loader generico -->
<div class="web-collection-items-container" fxLayout="column" *ngIf="!collectionId && isGettingItemDetails">
  <generali-loader></generali-loader>
</div>

<!-- Lista di oggetti contenuti nel LP/playlist lato mobile -->
<ng-container
  *ngIf="!isGettingItemDetails && !isGettingParentDetails && isTabSelected('description') && itemDetails && !isSyllabusCourse && !collectionId">
  <div class="course-details show-lt-md" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="start">
    <div fxLayout="column">
      <!-- Box con i contenuti della Playlist -->
      <ng-container *ngIf="isContentOfPlaylistVisible()" [ngTemplateOutlet]="playlistChildsList"
        [ngTemplateOutletContext]="{
        item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails
      }" class="show-lt-md"></ng-container>
      <!-- Box con i contenuti di un progetto -->
      <ng-container *ngIf="isContentOfProjectsVisible()" [ngTemplateOutlet]="projectChildsList"
        [ngTemplateOutletContext]="{
item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails
}"></ng-container>
    </div>
  </div>
</ng-container>

<!-- Lista di oggetti contenuti nel LP/playlist del corso cm2 online lato mobile -->
<ng-container
  *ngIf="!isGettingItemDetails && isTabSelected('description') && isSyllabusCourse && (isContentOfPlaylistVisible() || isContentOfProjectsVisible())">
  <div class="course-details show-lt-md" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="start">
    <div fxLayout="row">
      <div class="course-details-container mobile" fxLayout="column">
        <!-- Box con i contenuti della Playlist del corso online -->
        <div class="course-online-container-item" *ngIf="isContentOfPlaylistVisible()">
          <ng-container [ngTemplateOutlet]="playlistChildsList" [ngTemplateOutletContext]="{
            item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails,
            itemLinkedToCourse: itemLinkedToCourse && itemLinkedToCourse.itemId
          }"></ng-container>
        </div>

        <!-- Box con i contenuti di un progetto del corso online -->
        <div class="course-online-container-item" *ngIf="isContentOfProjectsVisible()">
          <ng-container [ngTemplateOutlet]="projectChildsList" [ngTemplateOutletContext]="{
            item: itemLinkedToCourse && itemLinkedToCourse.itemId ? itemLinkedToCourse : itemDetails,
            itemLinkedToCourse: itemLinkedToCourse && itemLinkedToCourse.itemId
          }"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div *ngIf="!collectionId  && (!isPlaylistPlan || lpId)" id="tab-container" class="tab-container" fxLayout="column"
  fxLayoutAlign="center center">
  <!-- Lista delle tab desktop -->
  <div fxShow class="tabs" fxLayout="row" fxLayoutAlign="start start" fxHide.lt-md>
    <button *ngFor="let tab of tabs" role="tab" [ngClass]="{ active: selectedTab && selectedTab.id === tab.id }"
      class="tab-element" fxLayout="row" fxLayoutAlign="center center" (click)="openTab(tab)">
      <div class="tab-button" fxLayout="row" fxLayoutAlign="center center">
        <h6 class="bold to-uppercase">{{ tab.label }}</h6>
      </div>
    </button>
  </div>

  <!-- Lista delle tab mobile -->
  <div fxHide fxShow.lt-md class="tabs-select">
    <custom-select type="tab" [selectedValue]="selectedTab" bindValue="label" bindLabel="label"
      (onOptionClicked)="openTab($event)" [items]="tabs" arrowIconSrc="../../../../assets/icons/icon-arrows.svg">
    </custom-select>
  </div>

  <!-- Contenuto delle tab -->
  <div class="tab-content" fxLayout="column" fxLayoutAlign="center center">
    <div class="page-padding" fxLayout="column">

      <!-- Tab delle informazioni -->
      <app-description-tab *ngIf="!isGettingItemDetails && isTabSelected('description')"
        [isSyllabusCourse]="isSyllabusCourse" [isExternalCourse]="isExternalCourse"
        [isCourseWithLimitedSeats]="isCourseWithLimitedSeats" [hasMoreEditions]="hasMoreEditions"
        [isSubscribedToAnEdition]="isSubscribedToAnEdition" [isOnlineCourse]="isOnlineCourse"
        [isCourseOnlineStage]="isCourseOnlineStage" [isWebinar]="isWebinar" [isCatalogStage]="isCatalogStage"
        [editions]="editions" [itemDetails]="itemDetails"
        [stopDateCourseSubscriptionFormat]="stopDateCourseSubscriptionFormat"
        [isContentOfPlaylistVisible]="isContentOfPlaylistVisible()" [playlistChildsList]="playlistChildsList"
        [itemLinkedToCourse]="itemLinkedToCourse" [isContentOfProjectsVisible]="isContentOfProjectsVisible()"
        [duration]="duration" [itemTypeLabel]="itemTypeLabel"
        [selectedEditionOfMainSelect]="selectedEditionOfMainSelect" [isGettingItemDetails]="isGettingItemDetails"
        [webinarCoursesByDay]="webinarCoursesByDay" [isLoadingEditions]="isLoadingEditions"
        [webinarSelectedDay]="webinarSelectedDay" [selectedCourseDateId]="selectedCourseDateId"
        [projectChildsList]="projectChildsList" [selectedEditionInfo]="selectedEditionInfo"
        [currentWebinarEditions]="currentWebinarEditions" [selectedPlace]="selectedPlace"
        [currentPlaceEditions]="currentPlaceEditions" [editionsByPlace]="editionsByPlace"
        [isGettingParentDetails]="isGettingParentDetails" [collectionId]="collectionId" [webCourseId]="webCourseId"
        [completedItems]="completedItems" [isItemOnline]="isItemOnline" [partialScore]="partialScore"
        [surveySended]="surveySended" [surveyPoints]="surveyPoints" [surveyPointsSelected]="surveyPointsSelected"
        (onChangeWebinarSelectedDay)="changeWebinarSelectedDay($event)" (onSelectPlace)="selectPlace($event)"
        (onOpenCustomLink)="openCustomLink($event)" (onSendFeedbackSurvey)="sendFeedbackSurvey()"
        (onSelectedEditionOfMainSelect)="onSelectedEditionOfMainSelect($event)"
        (onSelectedCourseDateId)="onSelectedCourseDateId($event)" (onSurveySended)="onSurveySended($event)"
        (onSurveyPoints)="onSurveyPoints($event)" (onSurveyPointsSelected)="onSurveyPointsSelected($event)">
      </app-description-tab>

      <!-- Tab dei questionari -->
      <app-surveys-tab *ngIf="isTabSelected('surveys')" [isGettingItemDetails]="isGettingItemDetails"
        [isLoadingEditions]="isLoadingEditions" [editionsSurvey]="editionsSurvey"
        [editionsListWithPastEditions]="editionsListWithPastEditions" [isAssessment]="isAssessment"
        [itemDetails]="itemDetails" [stopDateCourseSubscriptionFormat]="stopDateCourseSubscriptionFormat"
        (onGoToCourseSurvey)="goToCourseSurvey($event, true)"></app-surveys-tab>

      <!-- Tab degli allegati di un oggetto Library -->
      <ng-container *ngIf="!isGettingItemDetails && isTabSelected('attachmentList')">
        <div class="scroll-table">
          <table class="item-detail-table" role="grid">
            <thead>
              <tr class="calendar-section-table-header text-header-table text-uppercase">
                <th tabindex="0" fxFlex="90" class="full-width" role="columnheader" translate="generic.NAME"></th>
                <th tabindex="0" fxFlex="10" role="columnheader" translate="generic.DOWNLOAD_ATTACHMENT"></th>
              </tr>
            </thead>
            <tbody>
              <tr role="row" *ngFor="let attachments of attachmentList">
                <!-- Nome dell'allegato -->
                <td fxFlex="90" role="cell">
                  <p tabindex="0" class="padding-right15 ful-width" *ngIf="attachments && attachments.childObject">
                    {{attachments.childObject.title || attachments.childObject.name}}</p>
                </td>
                <!-- Scarica allegato -->
                <td fxFlex="10" role="cell" class="cursor-pointer">
                  <button (click)="getAttachmentUrl(attachments)" class="no-style-button">
                    <svg-icon src="assets/icons/icon-download.svg"></svg-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>

      <!-- Tab materiali -->
      <ng-container *ngIf="!isGettingItemDetails && isSyllabusCourse && isTabSelected('materials')">
        <div class="full-width materials-container">
          <!-- Lista delle tab dei materiali -->
          <div class="tabs materials" fxLayout="row" fxLayoutAlign="start start">
            <!-- Pre work -->
            <button role="tab" [ngClass]="{ active: selectedMaterialTab && selectedMaterialTab == 'MATERIAL_PRE_WORK' }"
              class="tab-element" fxLayout="row" fxLayoutAlign="center center"
              (click)="loadUserMaterialList('MATERIAL_PRE_WORK')">
              <div class="tab-button" fxLayout="row" fxLayoutAlign="center center">
                <h6 class="bold to-uppercase">{{ ('itemDetailsPage.materials.categoryTypes.PREWORK_MATERIAL' |
                  translate)
                  + ' (' +
                  preworkMaterialsCounter + ')' }} </h6>
              </div>
            </button>
            <!-- Materiale d'aula -->
            <button role="tab"
              [ngClass]="{ active: selectedMaterialTab && selectedMaterialTab == 'MATERIAL_CLASSROOM' }"
              class="tab-element" fxLayout="row" fxLayoutAlign="center center"
              (click)="loadUserMaterialList('MATERIAL_CLASSROOM')">
              <div class="tab-button" fxLayout="row" fxLayoutAlign="center center">
                <h6 class="bold to-uppercase">{{ ('itemDetailsPage.materials.categoryTypes.ROOM_MATERIAL' | translate)
                  + ' (' +
                  classroomMaterialsCounter + ')' }} </h6>
              </div>
            </button>
            <!-- Post work -->
            <button role="tab"
              [ngClass]="{ active: selectedMaterialTab && selectedMaterialTab == 'MATERIAL_POST_WORK' }"
              class="tab-element" fxLayout="row" fxLayoutAlign="center center"
              (click)="loadUserMaterialList('MATERIAL_POST_WORK')">
              <div class="tab-button" fxLayout="row" fxLayoutAlign="center center">
                <h6 class="bold to-uppercase">{{ ('itemDetailsPage.materials.categoryTypes.POST_ROOM_MATERIAL' |
                  translate)
                  + ' (' +
                  postworkMaterialsCounter + ')' }} </h6>
              </div>
            </button>
          </div>

          <!-- Loader dei materiali -->
          <div *ngIf="isFetchingMaterialsFiles" class="material-loader">
            <generali-loader></generali-loader>
          </div>

          <div class="materials-card-container"
            *ngIf="!isFetchingMaterialsFiles && !areMissingMaterialsInSelectedTab()">
            <div class="material-card" *ngFor="let materialItem of materials | slice:0:materialListNumRecords | paginate: { id: 'materialFiles',
              itemsPerPage: materialListNumRecords,
              currentPage: currentMaterialListPage,
              totalItems: currentMaterialListCounter }" fxLayoutAlign="space-between start" fxLayout="column">
              <h4 class="title">{{ materialItem && materialItem.item && materialItem.item.title || '' }}</h4>
              <div class="type">
                <p *ngIf="materialItem && materialItem.item && materialItem.item.typeTranslation">{{
                  materialItem.item.typeTranslation }}</p>
              </div>

              <div>
                <button *ngIf="isMaterialToOpen(materialItem)" class="link-action no-style-button"
                  translate="generic.SHOW" (click)="toggleKalturaPlayerModal(materialItem)"></button>
                <button *ngIf="isMaterialToDownload(materialItem)" class="link-action no-style-button"
                  translate="generic.DOWNLOAD" (click)="downloadMultimediaItem(materialItem)"></button>
              </div>
            </div>
          </div>

          <!-- Nessun materiale trovato -->
          <div *ngIf="!isFetchingMaterialsFiles && areMissingMaterialsInSelectedTab()" class="no-material-found">
            <p tabindex="0" class="margin-bottom0" translate="generic.NO_DATA_FOUND">
            </p>
          </div>
        </div>
      </ng-container>

      <!-- Tab certificati delle edizioni del CM2 -->
      <ng-container *ngIf="!isGettingItemDetails && isSyllabusCourse && isTabSelected('editionsCertifications')">
        <!-- Loader dei certificati -->
        <div *ngIf="isFetchingCoursesCertifications">
          <generali-loader></generali-loader>
        </div>

        <div class="scroll-table">
          <table class="item-detail-table" role="grid"
            *ngIf="!isFetchingCoursesCertifications && editionsCertifications && editionsCertifications.length">
            <thead>
              <tr class="calendar-section-table-header text-header-table text-uppercase">
                <th role="columnheader">
                  <span class="text-uppercase" tabindex="0" translate="generic.LINKED_EDITION"></span>
                </th>
                <th role="columnheader" fxHide fxShow.gt-xs>
                  <span tabindex="0" translate="generic.CERTIFICATION_REALEASE_DATE"></span>
                </th>
                <th role="columnheader" class="text-center">
                  <span tabindex="0" translate="itemDetailsPage.ACTIONS"></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr role="row" *ngFor="let editionsCertification of editionsCertifications">
                <td role="cell">
                  <span *ngIf="editionsCertification">
                    <!-- Luogo dell'edizione associata -->
                    <div>
                      <p tabindex="0" class="bold-text">
                        {{editionsCertification.customPlace || editionsCertification.onlineEventLinkTitle}}
                      </p>
                    </div>
                    <!-- Giornata dell'edizione associata -->
                    <div *ngFor="let day of editionsCertification.days | orderByDate">
                      <p class="margin-top6">{{ day.formattedFullDate }}</p>
                      <!-- Orari della singola giornata -->
                      <p class="schedule-time">
                        <span tabindex="0" [hidden]="!schedule.startTime || !schedule.endTime"
                          *ngFor="let schedule of day.timeSchedules; let first = first; let last = last">
                          {{ (schedule.startTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' |
                          translate))
                          + ' - ' + (schedule.endTime | amLocale:'it' | amDateFormat: ('generic.TIME_FORMAT' |
                          translate))
                          + ( !last && day.timeSchedules.length > 1 ? ' / ' : '' ) }}
                        </span>
                      </p>
                    </div>
                  </span>
                </td>
                <td role="cell">
                  <div *ngIf="editionsCertification.releaseDate">
                    <p>{{ (editionsCertification.releaseDate | amLocale:'it' | amDateFormat: 'DD MMMM YYYY') }}</p>
                  </div>
                </td>
                <td role="cell">
                  <div fxLayout="row" class="full-width" fxLayoutAlign="center center">
                    <!-- Share su linkedin -->
                    <!-- <button class="no-style-button cursor-pointer" linkedinShare
                        [linkedinShareUrl]="editionsCertification.linkedinUrl">
                        <svg-icon class="linkedin-icon" src="assets/icons/linkedin.svg"></svg-icon>
                      </button> -->
                    <button class="no-style-button cursor-pointer"
                      (click)="openShareModalCertificateMessage(editionsCertification)"
                      *ngIf="editionsCertification && editionsCertification.linkedinUrl">
                      <svg-icon class="linkedin-icon" src="assets/icons/linkedin.svg"></svg-icon>
                    </button>
                    <!-- Download -->
                    <button class="no-style-button cursor-pointer"
                      (click)="downloadCourseCertificates(editionsCertification.courseDateId); trackDownloadCertificate(editionsCertification.courseDateId)">
                      <svg-icon class="download-icon" src="assets/icons/icon-download.svg"></svg-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Nessun certificato disponibile -->
        <div *ngIf="!isFetchingCoursesCertifications && (!editionsCertifications || !editionsCertifications.length)"
          fxLayout="row" class="full-width materials-container" fxLayoutAlign="center center">
          <p tabindex="0" class="margin-bottom0" translate="generic.NO_DATA_FOUND">
          </p>
        </div>
      </ng-container>

      <!-- Tab con le mappe delle giornate dell'unica edizione del CM2 -->
      <ng-container
        *ngIf="!isGettingItemDetails && isSyllabusCourse && editionsByPlace && editionsByPlace[0] && isTabSelected('editionsMap')">
        <div class="full-width map-container">
          <div *ngFor="let uniqueEdition of editionsByPlace[0].dates" fxFill fxLayout="column">
            <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
              <div *ngIf="uniqueEdition && uniqueEdition.itemId" fxFill fxLayout="column" fxLayoutAlign="start start">
                <!-- Titolo custom -->
                <h6 tabindex="0" *ngIf="uniqueEdition.customTitle">
                  {{uniqueEdition.customTitle}}</h6>
                <h6 tabindex="0" *ngIf="uniqueEdition.customSubtitle">
                  {{uniqueEdition.customSubtitle }}</h6>
                <h6 tabindex="0">{{ uniqueEdition.dayDate | amLocale:'it' | amDateFormat:
                  stopDateCourseSubscriptionFormat }}</h6>
                <h6 tabindex="0"><b>{{ uniqueEdition.location.address.id }}</b></h6>
                <h6 [hidden]="!schedule.startTime || !schedule.endTime"
                  *ngFor="let schedule of uniqueEdition.timeSchedules">
                  <span tabindex="0">{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{
                    schedule.endTime |
                    amLocale:'it' | amDateFormat: timeFormat }}</span>
                </h6>
                <h6 tabindex="0">{{ uniqueEdition.location.streetNumber &&
                  uniqueEdition.location.streetNumber.title }}</h6>
                <h6 tabindex="0">{{ (
                  'itemDetailsPage.datesAndPlaces.placeholders.ROOM' |
                  translate) + " " + (uniqueEdition.location.room &&
                  uniqueEdition.location.room.title) }}</h6>
                <h6 tabindex="0">{{ uniqueEdition.location.building &&
                  uniqueEdition.location.building.title }}</h6>
              </div>
            </div>
            <agm-map *ngIf="uniqueEdition.mapData != null" class="course-subscription-map"
              [latitude]="uniqueEdition.mapData.lat" [longitude]="uniqueEdition.mapData.lng" [disableDefaultUI]="false"
              [zoomControl]="false">
              <agm-marker [latitude]="uniqueEdition.mapData.lat" [longitude]="uniqueEdition.mapData.lng" label="A"
                [markerDraggable]="true">
              </agm-marker>
            </agm-map>

            <p tabindex="0" *ngIf="uniqueEdition.mapData == null" class="margin-bottom40"
              translate="itemDetailsPage.DATA_FOR_GOOGLE_MAPS_NOT_AVAILABLE">
            </p>
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</div>

<!-- Loader della lista di oggetti web contenuti nella collezione web -->
<div class="web-collection-items-container" fxLayout="column" *ngIf="collectionId && isGettingItemDetails">
  <generali-loader></generali-loader>
</div>

<!-- Lista di oggetti web contenuti nella collezione web -->
<div class="web-collection-items-container" fxLayout="column"
  *ngIf="collectionId && !isGettingItemDetails && itemDetails && itemDetails.courses && itemDetails.courses.length">
  <div fxLayout="column" class="view-all-container">
    <div fxFlexOffset="28px" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <div fxFlexOffset="10px" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
        <generali-card [id]="'item-of-web-collection-' + i"
          [translatedCourseType]="'generic.itemTypes.OF_COURSE_ME_COURSE' | translate"
          *ngFor="let webItemChild of itemDetails.courses; let i = index;" [card]="webItemChild"
          type="item-of-web-collection" (onCardClick)="openWebCourseOfCollection($event)"
          (onToggleBookmark)="onToggleBookmark($event, false)" [isSwitchingBookmark]="webItemChild.isSwitchingBookmark"
          [isBookmarked]="webItemChild.isBookmarked">
        </generali-card>
      </div>

    </div>
  </div>
</div>

<!-- Lista di oggetti contenuti nella playlist in forma di card -->
<div class="web-collection-items-container" fxLayout="column"
  *ngIf="(isPlaylistPlan && !lpId) && !isGettingItemDetails && itemDetails && itemDetails.itemChilds && itemDetails.itemChilds.length">
  <div fxLayout="column" class="view-all-container">
    <div fxFlexOffset="28px" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
      <div fxFlexOffset="10px" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.xs="center center">
        <generali-card *ngFor="let itemChild of itemDetails.itemChilds; let i = index;" [card]="itemChild"
          [translatedCourseType]="('generic.itemTypes.' + itemChild.type) | translate" type="cm2-course" (onCardClick)="openPlaylistItemDetails({
            itemId: itemChild.courseId,
            isItemLocked: itemChild.isItemLocked,
            singleItemChild: itemChild })" (onToggleBookmark)="onToggleBookmark($event, true)"
          [isSwitchingBookmark]="itemChild.isSwitchingBookmark"
          [isBookmarked]="itemChild.isBookmarked || itemChild.childObject?.isBookmarked" [titleOnlyOneRow]="true">
        </generali-card>
      </div>

    </div>
  </div>
</div>

<!-- Lista di edizioni del Webinar -->
<div *ngIf="false" id="editions-of-webinar" class="webinar-editions-body-panel course-content-wrapper">
  <div class="bg-webinar-content" fxLayout="column" fxLayoutAlign="start start">
    <!-- Titolo e separatore -->
    <div class="choose-edition-title-wrapper" fxLayout="column" fxLayoutAlign="start start">
      <h2 tabindex="0" translate="itemDetailsPage.edition.CHOOSE_EDITION"></h2>
      <div class="separator">
        <svg-icon src='../../../assets/icons/edition-separator.svg'></svg-icon>
      </div>
      <!-- Informazioni -->
      <p tabindex="0"
        [innerHTML]="('itemDetailsPage.edition.CHOOSE_EDITION_WEBINAR_INFO' | translate) | safeHtml: 'html'"></p>
    </div>

    <!-- Primo step -->
    <div class="step-wrapper" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
      <div class="step-title-wrapper" fxLayout="row" fxLayoutGap="8px">
        <div class="step-number" fxLayoutAlign="center center">
          <p tabindex="0">1</p>
        </div>
        <p><span translate="onlineMeeting.SELECT_DAY"></span> <strong></strong></p>
      </div>
      <!-- Contenitore date  -->
      <div *ngIf="webinarCoursesByDay && webinarCoursesByDay.length && !isGettingItemDetails"
        class="step-content-wrapper" fxLayout="row">
        <button *ngFor="let day of webinarCoursesByDay"
          [ngClass]="{ 'active': day.dayLabel === webinarSelectedDay, 'disabled' : isLoadingEditions, 'isSoldOut' : day.soldOut }"
          (click)="changeWebinarSelectedDay(day.dayLabel)" class="place-cards-wrapper" fxLayout="row" fxLayoutGap="8px"
          fxLayoutAlign="center center">
          <svg-icon src="../../../assets/icons/icon-gps.svg"></svg-icon>
          <div fxLayout="column" fxLayoutAlign="start start">
            <p><span tabindex="0" [innerHTML]="day.dayLabel | safeHtml: 'html'"></span></p>
            <p *ngIf="day.soldOut" class="not-more-available-seats" translate="onlineMeeting.NOT_MORE_AVAILABLE_SEATS">
            </p>
          </div>
        </button>
      </div>
    </div>

    <!--Step 2 -->

    <div class="step-wrapper" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
      <div class="step-title-wrapper" fxLayout="row" fxLayoutGap="8px">
        <div class="step-number" fxLayoutAlign="center center">
          <p tabindex="0">2</p>
        </div>
        <p><span tabindex="0" translate="onlineMeeting.SELECT_DAY_AND_HOUR"></span></p>
      </div>

      <!-- Lista dei corsi della giornata selezionata -->
      <div class="step-content-wrapper" [hidden]="isLoadingEditions" fxLayout="row" fxLayoutGap="16px"
        *ngIf="webinarSelectedDay && webinarCoursesByDay && webinarCoursesByDay.length">
        <div *ngFor="let slot of getWebinarSelectedDaySlots()" fxLayout="column" fxLayoutGap="24px"
          fxLayoutAlign="start start">
          <div fxLayout="row wrap" fxLayoutGap="16px" fxLayoutAlign="start start">
            <div *ngFor="let course of slot.courses" class="card-streaming"
              [ngClass]="{ 'sold-out-bg': course && course.labelStatusToDisplay === 'noAvailableSeats' }">
              <div class="second-row" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                <svg-icon src="assets/img/time-streaming.svg"></svg-icon>
                <div fxLayout="column">
                  <p tabindex="0" *ngIf="!isLoadingEditions" class="bold-text">{{ slot.timeSlot }}</p>
                  <p tabindex="0" *ngIf="course.isMorning && !isLoadingEditions" class="subtitle"
                    translate="generic.MORNING"></p>
                  <p tabindex="0" *ngIf="!course.isMorning && !isLoadingEditions" class="subtitle"
                    translate="generic.AFTERNOON">
                  </p>
                  <!-- Placeholder -->
                  <div *ngIf="isLoadingEditions">
                    <generali-loader></generali-loader>
                  </div>
                </div>
              </div>
              <div class="third-row" fxLayout="row" fxLayoutAlign="space-between center">
                <!-- Placeholder -->
                <div *ngIf="isLoadingEditions" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                  <generali-loader></generali-loader>
                </div>
                <!-- Posti disponibili -->
                <div *ngIf="!isLoadingEditions && course.labelStatusToDisplay === 'availableSeats'" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap="8px">
                  <svg-icon src="assets/img/people.svg"></svg-icon>
                  <p tabindex="0"><strong>{{ (course.availableSeats || 0) + ' ' }}</strong>
                    {{ course.availableSeats === 1 ? ('onlineMeeting.AVAILABLE_SEAT' | translate) :
                    ('onlineMeeting.AVAILABLE_SEATS' | translate) }}
                  </p>
                  <p tabindex="0"><strong>{{ (course.availableSeatsOB || 0) + ' ' }}</strong>
                    {{ course.availableSeatsOB === 1 ? ('onlineMeeting.AVAILABLE_SEAT_OVERBOOK' | translate) :
                    ('onlineMeeting.AVAILABLE_SEATS_OVERBOOK' | translate) }}
                  </p>
                </div>
                <!-- Numero di persone che si sono iscritte -->
                <div *ngIf="!isLoadingEditions && course.labelStatusToDisplay === 'subscribedPersons'" fxLayout="row"
                  fxLayoutAlign="start center" fxLayoutGap="8px">
                  <svg-icon src="assets/img/people.svg"></svg-icon>
                  <p tabindex="0"><strong>{{ (course.usedSeats || 0) + ' ' }}</strong>
                    {{ course.usedSeats === 1 ? ('onlineMeeting.SUBSCRIBED_PERSON' | translate) :
                    ('onlineMeeting.SUBSCRIBED_PERSONS' | translate) }}
                  </p>
                  <p tabindex="0"><strong>{{ (course.usedSeatsOB || 0) + ' ' }}</strong>
                    {{ course.usedSeatsOB === 1 ? ('onlineMeeting.SUBSCRIBED_PERSON_OVERBOOK' | translate) :
                    ('onlineMeeting.SUBSCRIBED_PERSONS_OVERBOOK' | translate) }}
                  </p>
                </div>
                <!-- Posti esauriti -->
                <div *ngIf="!isLoadingEditions && course.labelStatusToDisplay === 'noAvailableSeats'"
                  class="sold-out-label" fxLayout="row" fxLayoutAlign="start center">
                  <svg-icon src="assets/img/people.svg"></svg-icon>
                  <p tabindex="0" translate="onlineMeeting.NOT_MORE_AVAILABLE_SEATS"></p>
                </div>
                <!-- Ti sei iscritto -->
                <div *ngIf="!isLoadingEditions && course.labelStatusToDisplay === 'subscribed'" class="signed-up-label"
                  fxLayout="row" fxLayoutAlign="start center">
                  <svg-icon src="assets/img/signed-up.svg"></svg-icon>
                  <p tabindex="0" translate="onlineMeeting.YOU_ARE_SUBSCRIBED"></p>
                </div>
                <!-- Ti sei iscritto -->
                <div *ngIf="!isLoadingEditions && course.labelStatusToDisplay === 'present'" class="signed-up-label"
                  fxLayout="row" fxLayoutAlign="start center">
                  <svg-icon src="assets/img/signed-up.svg"></svg-icon>
                  <p tabindex="0" translate="onlineMeeting.PRESENT"></p>
                </div>
                <button tabindex="0" *ngIf="course.callToAction" [ngClass]="{ 'disabled' : isLoadingEditions }"
                  (click)="onCallToActionClicked(course)" class="call-to-action no-style-button">
                  {{ ('onlineMeeting.callToActions.' + course.callToAction) | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Placeholder qualora stessi caricando la lista dei corsi -->
      <div *ngIf="!isLoadingEditions" class="step-content-wrapper" fxLayout="row" fxLayoutGap="16px">
        <generali-loader></generali-loader>
      </div>
    </div>
  </div>
</div>

<!-- Se oggetto library oppure corso del cm2 che wrappa un oggetto library ed è un oggetto prestabile e l'utente può fare richieste di prestito -->
<div *ngIf="showCovidAlert()" id="player-wrapper" class="player-wrapper" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="covid-rentable-box-alert">
      <div tabindex="0" class="box-alert-title" translate="generic.ALERT"></div>
      <div tabindex="0" class="box-alert-text" translate="generic.COVID_ALERT_TEXT"></div>
    </div>
  </div>
</div>

<!-- Attestato, visibile solo se è un oggetto Library
<div class="certificate-wrapper" *ngIf="!isGettingItemDetails && !isSyllabusCourse">
  <div class="certificate-content disabled" fxLayout="column" fxLayout.gt-sm="row">
    <div class="certificate-data" fxLayout="column" fxLayoutAlign="start start">
      <div class="certificate-title" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <div class="circle"></div>
        <p translate="itemDetailsPage.certificate.CERTIFICATE"></p>
      </div>
      <div class="certificate-description">
        <p translate="itemDetailsPage.certificate.CERTIFICATE_DESCR"></p>
      </div>
      <div class="certificate-download" fxLayout="row" fxLayoutAlign="center center">
        <p translate="itemDetailsPage.certificate.CERTIFICATE_DOWNLOAD"></p>
      </div>
    </div>
    <div class="certificate-image" fxLayoutAlign="center center">
      <svg-icon src="../../../assets/img/certificate-placeholder.svg"></svg-icon>
    </div>
  </div>
</div> -->

<!-- Conferma iscrizione -->
<app-modal class="not-destructive-modal" id="confirmCourseSubscription">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div
      *ngIf="isExternalCourse || (selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.cancelBtnLabel)"
      class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="dismissConfirmCourseSubscription()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h6 class="center lighter margin-bottom40" translate="takers.courseSubscription.modal.confirmSubscription.TITLE"
        tabindex="-1">
      </h6>
    </div>

    <div class="content-modal edition-content-modal" fxLayout="row" fxLayoutAlign="start start">
      <div
        *ngIf="selectedEditionForConfirmSubscription && !selectedEditionForConfirmSubscription.isOnlineCourse || selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.isOnlineEvent"
        class="md-dialog-content full-width">
        <!-- Dettaglio edizione  -->
        <div *ngIf="!selectedEditionForConfirmSubscription.showMaps && selectedEditionForConfirmSubscription"
          class="full-width">
          <h6 tabindex="0" translate="generic.SUMMARY"></h6>
          <!-- Titolo custom  -->
          <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.customTitle">
            {{selectedEditionForConfirmSubscription.customTitle}}</h6>
          <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.customSubtitle">
            {{selectedEditionForConfirmSubscription.customSubtitle}}</h6>
          <h6 tabindex="0"
            *ngIf="selectedEditionForConfirmSubscription.availableSeats && !selectedEditionForConfirmSubscription.isOnlineEvent">
            <b>{{ ('itemDetailsPage.NUMBER_AVAILABLE_SEATS' | translate) + ': ' }}</b>
            {{ selectedEditionForConfirmSubscription.availableSeats }}
          </h6>
          <h6 tabindex="0"
            *ngIf="selectedEditionForConfirmSubscription.availableSeatsOB && !selectedEditionForConfirmSubscription.isOnlineEvent">
            <b>{{ ('itemDetailsPage.NUMBER_AVAILABLE_SEATS_OVERBOOK' | translate) + ': ' }}</b>
            {{ selectedEditionForConfirmSubscription.availableSeatsOB }}
          </h6>
          <h6 tabindex="0"
            *ngIf="(!selectedEditionForConfirmSubscription.availableSeats || selectedEditionForConfirmSubscription.availableSeats == 0) && !selectedEditionForConfirmSubscription.isOnlineEvent">
            <b class="text-primary-1" translate="generic.NO_AVAILABLE_SEAT"></b>
          </h6>
          <h6 tabindex="0"
            *ngIf="(!selectedEditionForConfirmSubscription.availableSeatsOB || selectedEditionForConfirmSubscription.availableSeatsOB == 0) && !selectedEditionForConfirmSubscription.isOnlineEvent">
            <span translate="itemDetailsPage.NO_AVAILABLE_SEAT_OVERBOOK"></span>
          </h6>
          <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.durationDaysLabel">
            <b>{{ ('itemDetailsPage.DURATION' | translate) + ': ' }}</b>
            {{ selectedEditionForConfirmSubscription.durationDaysLabel }}
          </h6>
          <h6 tabindex="0"
            *ngIf="selectedEditionForConfirmSubscription.teachers && selectedEditionForConfirmSubscription.teachers.length">
            <b>{{ ('itemDetailsPage.TEACHERS' | translate) + ': ' }}</b>
            <span *ngFor="let teacher of selectedEditionForConfirmSubscription.teachers; let last = last;">{{
              teacher.title + (!last ? ', ' : '' ) }}</span>
          </h6>
          <h6 tabindex="0"
            *ngIf="selectedEditionForConfirmSubscription.tutors && selectedEditionForConfirmSubscription.tutors.length">
            <b>{{ ('itemDetailsPage.TUTORS' | translate) + ': ' }}</b><span
              *ngFor="let tutor of selectedEditionForConfirmSubscription.tutors; let last = last;">{{ tutor.title +
              (!last
              ? ', ' : '' ) }}</span>
          </h6>
          <h6 tabindex="0"
            *ngIf="selectedEditionForConfirmSubscription.stopDate && !selectedEditionForConfirmSubscription.isOnlineEvent">
            <b>{{ ('itemDetailsPage.END_DATE_SUBSCRIPTION' | translate) + ': ' }}</b>{{
            (selectedEditionForConfirmSubscription.stopDate |
            amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat) + ' ' }} <span translate="generic.HOURS"
              class="to-lowercase"></span>{{
            ' ' + (selectedEditionForConfirmSubscription.stopDate |
            amLocale:'it' | amDateFormat: timeFormat) }}
          </h6>
          <h6 tabindex="0"
            *ngIf="selectedEditionForConfirmSubscription.startDate && selectedEditionForConfirmSubscription.isOnlineEvent">
            <b>{{ ('itemDetailsPage.END_DATE_SUBSCRIPTION' | translate) + ': ' }}</b>{{
            (selectedEditionForConfirmSubscription.startDate |
            amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat) + ' ' }} <span translate="generic.HOURS"
              class="to-lowercase"></span>{{
            ' ' + (selectedEditionForConfirmSubscription.startDate |
            amLocale:'it' | amDateFormat: timeFormat) }}
          </h6>
          <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.subscriptionCustomLink">
            <b>{{ ('itemDetailsPage.SUBSCRIPTION_LINK' | translate) + ': ' }}</b>
            {{ selectedEditionForConfirmSubscription.subscriptionCustomLink }}
          </h6>
          <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.subscriptionCustomMail">
            <b>{{ ('itemDetailsPage.SUBSCRIPTION_MAIL' | translate ) + ': ' }}</b>
            <a class="link-action black" style="word-break: break-all" target="_blank"
              href="{{ selectedEditionForConfirmSubscription.subscriptionCustomMail }}">{{
              selectedEditionForConfirmSubscription.subscriptionCustomMail }}</a>
          </h6>
          <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.subscriptionInfoMail">
            <b>{{ ('itemDetailsPage.INFORMATION_MAIL' | translate ) + ': ' }}</b>
            <a class="link-action black" style="word-break: break-all" target="_blank"
              href="{{ selectedEditionForConfirmSubscription.subscriptionInfoMail }}">{{
              selectedEditionForConfirmSubscription.subscriptionInfoMail }}</a>
          </h6>
          <div *ngFor="let courseDay of selectedEditionForConfirmSubscription.days; let i = index" class="dayDetail"
            fxLayout="column" fxLayoutAlign="start start">
            <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
              <h4 tabindex="0"
                *ngIf="selectedEditionForConfirmSubscription.days && selectedEditionForConfirmSubscription.days.length && selectedEditionForConfirmSubscription.days.length > 1">
                {{ ('itemDetailsPage.datesAndPlaces.DAY_DATE' | translate) }} {{ i + 1 }}</h4> <a
                *ngIf="isMapsEnabled(selectedEditionForConfirmSubscription, i)"
                (click)="openMaps(selectedEditionForConfirmSubscription, i)">{{ 'itemDetailsPage.SHOW_MAP' |
                translate}}</a>
            </div>
            <hr>
            <div class="dayDetailWrapper" fxFill fxLayout="column" fxLayoutAlign="start start">
              <h6 tabindex="0">
                <b>{{ courseDay.dayDate | amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat }}</b>
              </h6>
              <h4 tabindex="0"
                *ngIf="courseDay.location && courseDay.location.address && courseDay.location.address.id">
                {{ courseDay.location.address.id }}</h4>
              <!--h6 tabindex="0" [hidden]="!schedule.startTime || !schedule.endTime"
              *ngFor="let schedule of courseDay.timeSchedules">
              <span>{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{ schedule.endTime |
              amLocale:'it' | amDateFormat: timeFormat }}</span-->
              <h6 tabindex="0" [hidden]="!courseDay.formattedTimeSchedule">
                <span>{{ courseDay.formattedTimeSchedule }}</span>
              </h6>
              <h6 tabindex="0"
                *ngIf="courseDay && courseDay.location && courseDay.location.streetNumber && courseDay.location.streetNumber.title">
                {{ courseDay.location.streetNumber.title }}</h6>
              <h6 tabindex="0"
                *ngIf="courseDay && courseDay.location && courseDay.location.room && courseDay.location.room.title">
                {{ ('itemDetailsPage.datesAndPlaces.placeholders.ROOM' | translate) + " " +
                (courseDay.location.room.title
                || '')
                }}
              </h6>
              <h6 tabindex="0"
                *ngIf="courseDay && courseDay.location && courseDay.location.building && courseDay.location.building.title">
                {{ courseDay.location.building.title }}</h6>
            </div>
          </div>
          <!-- Input con nomi partecipanti  -->
          <div
            *ngIf="selectedEditionForConfirmSubscription.isConfirmed && selectedEditionForConfirmSubscription.takerEnrollAttributes && selectedEditionForConfirmSubscription.takerEnrollAttributes.length"
            class="full-width margin-top16 margin-bottom16" id="partecipants" fxLayout="column"
            fxLayoutAlign="start start">
            <h5 class="margin-top20" translate="generic.RESERVED_SEATS"></h5>
            <div fxLayout="row" fxLayoutGap="8px">
              <p><b translate="generic.SEATS_NUMBER"></b></p>
              <p>{{ selectedEditionForConfirmSubscription.usedTakes }}</p>
            </div>
            <div class="full-width" fxLayout="row" fxLayoutGap="8px">
              <p><b translate="generic.SEATS_ADDED"></b></p>
              <p>{{ selectedEditionForConfirmSubscription.participants }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="selectedEditionForConfirmSubscription.showMaps" fxFill fxLayout="column">
          <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
            <h6 tabindex="0" (click)="selectedEditionForConfirmSubscription.showMaps = false">
              <a class="link-action black" style="word-break: break-all">{{ '< Indietro' }}</a>
            </h6>
          </div>
          <div *ngIf="!!selectedEditionForConfirmSubscription.days[itemId]" class="space20"></div>
          <div class="margin-bottom20" fxLayout="column" fxLayoutAlign="start start">
            <div *ngIf="!!selectedEditionForConfirmSubscription.days[itemId]" fxFill fxLayout="column"
              fxLayoutAlign="start start">
              <!-- Titolo custom  -->
              <h3 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.customTitle">
                {{selectedEditionForConfirmSubscription.customTitle}}</h3>
              <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.customSubtitle">
                {{selectedEditionForConfirmSubscription.customSubtitle }}</h6>
              <h4 tabindex="0">{{ selectedEditionForConfirmSubscription.days[itemId].dayDate | amLocale:'it' |
                amDateFormat:
                stopDateCourseSubscriptionFormat }}</h4>
              <h6 tabindex="0"><b>{{ selectedEditionForConfirmSubscription.days[itemId].location.address.id }}</b>
              </h6>
              <h6 tabindex="0" [hidden]="!schedule.startTime || !schedule.endTime"
                *ngFor="let schedule of selectedEditionForConfirmSubscription.days[itemId].timeSchedules">
                <span>{{ schedule.startTime | amLocale:'it' | amDateFormat: timeFormat }} - {{ schedule.endTime |
                  amLocale:'it' | amDateFormat: timeFormat }}</span>
              </h6>
              <h6 tabindex="0">{{ selectedEditionForConfirmSubscription.days[itemId].location.streetNumber &&
                selectedEditionForConfirmSubscription.days[itemId].location.streetNumber.title }}</h6>
              <h6 tabindex="0" *ngIf="selectedEditionForConfirmSubscription.days[itemId].location.room &&
            selectedEditionForConfirmSubscription.days[itemId].location.room.title">{{ (
                'itemDetailsPage.datesAndPlaces.placeholders.ROOM' |
                translate) + " " + selectedEditionForConfirmSubscription.days[itemId].location.room.title }}</h6>
              <h6 tabindex="0">{{ selectedEditionForConfirmSubscription.days[itemId].location.building &&
                selectedEditionForConfirmSubscription.days[itemId].location.building.title }}</h6>
            </div>
          </div>
          <agm-map *ngIf="selectedEditionForConfirmSubscription.mapData != null" class="course-subscription-map"
            [latitude]="selectedEditionForConfirmSubscription.mapData.lat"
            [longitude]="selectedEditionForConfirmSubscription.mapData.lng" [disableDefaultUI]="false"
            [zoomControl]="false">
            <agm-marker [latitude]="selectedEditionForConfirmSubscription.mapData.lat"
              [longitude]="selectedEditionForConfirmSubscription.mapData.lng" label="A" [markerDraggable]="true">
            </agm-marker>
          </agm-map>

          <p tabindex="0" *ngIf="selectedEditionForConfirmSubscription.mapData == null"
            translate="generic.DATA_FOR_GOOGLE_MAPS_NOT_AVAILABLE"></p>
        </div>

        <!-- Riserva posti  -->
        <div fxFill *ngIf="selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.maxUsableTakes && !selectedEditionForConfirmSubscription.isOnlineCourse && !selectedEditionForConfirmSubscription.isWebinar && isSubscribeEnabled(selectedEditionForConfirmSubscription)
          && !selectedEditionForConfirmSubscription.showMaps" fxLayout="column" fxLayoutAlign="center center"
          class="modal-body-container margin-top20">
          <div class="full-width">
            <div fxLayoutAlign="start center" fxLayout="row" class="margin-bottom5">
              <h6 tabindex="0">
                {{selectedEditionForConfirmSubscription.enableUsableTakesHint ||
                ('itemDetailsPage.datesAndPlaces.ENABLE_MAX_USABLE_TAKES_DEFAULT_HINT' | translate)}}
              </h6>
            </div>
            <div fxLayoutAlign="start center" fxLayout="row">
              <button class="no-style-button add-participants"
                (click)="changeTakesValue('decrement', selectedEditionForConfirmSubscription)">
                <svg-icon class="zoom-icon" src="assets/icons/icon-remove.svg">
                </svg-icon>
              </button>
              <p tabindex="0" class="margin-right10 margin-left10">{{ selectedEditionForConfirmSubscription.usedTakes
                }}
              </p>
              <button class="no-style-button add-participants"
                (click)="changeTakesValue('increment', selectedEditionForConfirmSubscription)">
                <svg-icon class="zoom-icon" src="assets/icons/icon-add.svg">
                </svg-icon>
              </button>
            </div>

            <div class="full-width" fxLayout="column" fxLayoutAlign="start start"
              *ngIf="selectedEditionForConfirmSubscription.usedTakes > 1">
              <div *ngFor="let participant of additionalEnrollAttributes" class="margin-top16 min-width230"
                fxLayout="column" fxLayoutAlign="start start">
                <custom-input [type]="'text'" [bindValue]="participant.attributeValue" autocompleteText0="off"
                  inputContainerClass="regular-dimension" inputClass="basic-input" class="full-width"
                  [attrAriaLabel]="'generic.PARTICIPANT_NAME' | translate"
                  (onModelChanged)="onParticipantNameChanged($event, participant)"
                  [placeholder]="'generic.PARTICIPANT_NAME' | translate">
                </custom-input>
                <i class="margin-top4" translate="generic.VALID_NAME"
                  *ngIf="hasInvalidReservationName(participant)"></i>
              </div>
            </div>
          </div>
        </div>

        <!-- Accetta Privacy  -->
        <div fxFill
          *ngIf="!privacyObject && selectedEditionForConfirmSubscription && isSubscribeEnabled(selectedEditionForConfirmSubscription) && selectedEditionForConfirmSubscription.hasToAcceptPrivacy && !selectedEditionForConfirmSubscription.showMaps"
          fxLayout="column" fxLayoutAlign="center center" class="modal-body-container margin-bottom20">
          <div class="full-width" fxLayout="column">
            <div fxLayoutAlign="start center" fxLayout="row">
              <div class="md-checkbox margin-top10 on-survey-checkbox">
                <input id="acceptCoursePrivacy" name="acceptCoursePrivacy" type="checkbox"
                  [(ngModel)]="selectedEditionForConfirmSubscription.coursePrivacyAccepted">
                <label class="survey-radio" for="acceptCoursePrivacy">
                  {{ 'takers.courseSubscription.ACCEPT_PRIVACY' | translate }}
                </label>
              </div>
            </div>
            <div (click)="openCoursePrivacy(selectedEditionForConfirmSubscription)" fxLayoutAlign="start center"
              fxLayout="row">
              <span tabindex="0" class="italic underline cursor-pointer"
                translate="takers.courseSubscription.OPEN_PRIVACY_PAGE"></span>
            </div>
          </div>
        </div>

        <!-- Accetta Privacy Nuova (iniziativa, pdf) -->
        <div fxFlexFill
          *ngIf="!!privacyObject && selectedEditionForConfirmSubscription && isSubscribeEnabled(selectedEditionForConfirmSubscription) && selectedEditionForConfirmSubscription.hasToAcceptPrivacy && !selectedEditionForConfirmSubscription.showMaps"
          fxLayout="column" fxLayoutAlign="center center" class="modal-body-container margin-top20 margin-bottom20">
          <div class="full-width" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <div fxLayoutAlign="start center" fxLayout="row">
              <custom-input id="acceptCoursePrivacy" [type]="'checkbox'" autocompleteText="off"
                [bindValue]="selectedEditionForConfirmSubscription.coursePrivacyAccepted"
                (onModelChanged)="coursePrivacyAcceptedChanged(selectedEditionForConfirmSubscription)"
                [attrName]="'acceptCoursePrivacy'" name="acceptCoursePrivacy" [attrAriaLabel]="'' | translate"
                checkboxText="">
              </custom-input>
            </div>
            <div class="privacy-label">
              <span>{{('takers.courseSubscription.DECLARE_PRIVACY' | translate ) }}
                <span class="italic underline cursor-pointer" (click)="openPrivacyPdf()"
                  translate="takers.courseSubscription.INFO_PRIVACY"></span>
              </span>
            </div>
          </div>
        </div>

      </div>


    </div>
    <!-- Azioni dell'edizione del corso esterno  -->
    <div *ngIf="isExternalCourse" class="actions" fxLayout="column" fxLayoutAlign="center end"
      fxLayoutAlign.gt-xs="center end">
      <custom-button importance="primary" [text]="'generic.CLOSE' | translate"
        (onClick)="dismissConfirmCourseSubscription()">
      </custom-button>
    </div>
    <!-- Azioni dell'edizione del corso non esterno  -->
    <div *ngIf="!isExternalCourse" class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla  -->
      <custom-button
        *ngIf="selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.cancelBtnLabel"
        importance="secondary" [text]="selectedEditionForConfirmSubscription.cancelBtnLabel"
        (onClick)="dismissConfirmCourseSubscription()">
      </custom-button>

      <!-- Conferma  -->
      <custom-button *ngIf="selectedEditionForConfirmSubscription && (selectedEditionForConfirmSubscription.isOnlineCourse
  || (selectedEditionForConfirmSubscription ||
  (selectedEditionForConfirmSubscription.availableSeats && selectedEditionForConfirmSubscription.availableSeats != 0)))
   && selectedEditionForConfirmSubscription.confirmBtnLabel" importance="primary"
        [text]="selectedEditionForConfirmSubscription.confirmBtnLabel"
        [disabled]="(!selectedEditionForConfirmSubscription.isOnlineCourse && !isMaxUsableTakesValid(selectedEditionForConfirmSubscription)
      && ((isSubscribeEnabled(selectedEditionForConfirmSubscription)) || !isSubscribeEnabled(selectedEditionForConfirmSubscription))) || (selectedEditionForConfirmSubscription.hasToAcceptPrivacy && !selectedEditionForConfirmSubscription.coursePrivacyAccepted)"
        (onClick)="confirmCourseSubscriptionModal()">
      </custom-button>

      <!-- Secondo tasto di Conferma (opzionale)  -->
      <custom-button
        *ngIf="selectedEditionForConfirmSubscription && (selectedEditionForConfirmSubscription.isOnlineCourse || (selectedEditionForConfirmSubscription && selectedEditionForConfirmSubscription.availableSeats && selectedEditionForConfirmSubscription.availableSeats != 0)) && selectedEditionForConfirmSubscription.confirmSecondBtnLabel"
        importance="secondary" [text]="selectedEditionForConfirmSubscription.confirmSecondBtnLabel"
        [disabled]="!selectedEditionForConfirmSubscription.isOnlineCourse && !isMaxUsableTakesValid(selectedEditionForConfirmSubscription)"
        (onClick)="secondConfirmationCourseSubscriptionModal()">
      </custom-button>
    </div>
  </div>
</app-modal>

<!--  Modale di cancellazione iscrizione  -->
<app-modal class="not-destructive-modal" id="cancelCourseSubscription">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="dismissCancelSubscriptionModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="takers.courseSubscription.modal.cancelSubscription.TITLE"
        tabindex="-1">
      </h4>
    </div>

    <div *ngIf="selectedEditionForCancelSubscription" class="content-modal" fxLayout="row" fxLayoutAlign="start start">
      <div class="md-dialog-content full-width">
        <!-- Se sono iscritto  -->
        <p tabindex="0"
          *ngIf="selectedEditionForCancelSubscription.isInvited && !selectedEditionForCancelSubscription.isConfirmed"
          translate="takers.courseSubscription.modal.cancelSubscription.DO_YOU_WANT_TO_REFUSE_SUBSCRIPTION"></p>
        <!-- Se sono confermato  -->
        <p tabindex="0" *ngIf="selectedEditionForCancelSubscription.isConfirmed"
          translate="takers.courseSubscription.modal.cancelSubscription.DO_YOU_WANT_TO_CANCEL_SUBSCRIPTION"></p>
        <custom-textarea [bindValue]="selectedEditionForCancelSubscription.cancelMessage"
          title="{{ 'takers.courseSubscription.modal.cancelSubscription.REFUSE_EXPLANATION' | translate }}"
          (onModelChanged)="onCancelMessageChanged($event)"
          [placeholder]="'takers.courseSubscription.modal.cancelSubscription.CANCELLATION_MESSAGE' | translate"
          [attrAriaLabel]="'takers.courseSubscription.modal.cancelSubscription.CANCELLATION_MESSAGE' | translate"
          inputContainerClass="regular-dimension cancelSubscriptionMessage">
        </custom-textarea>
      </div>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla  -->
      <custom-button importance="secondary" text="{{ 'generic.CANCEL' | translate }}"
        (onClick)="dismissCancelSubscriptionModal()">
      </custom-button>
      <!-- Conferma  -->
      <custom-button importance="primary" text="{{ 'generic.CONFIRM' | translate }}"
        (onClick)="confirmCancelSubscriptionModal()">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che mostra il player di Azure per il material allegato ad un corso del cm2  -->
<app-modal class="informative-modal" id="kalturaPlayerModal">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="toggleKalturaPlayerModal()">
      </svg-icon>
    </div>

    <div *ngIf="selectedMaterialForModal && selectedMaterialForModal.item" class="modal-title full-width" fxLayout="row"
      fxLayoutAlign="center center">
      <p class="center lighter margin-bottom40" tabindex="-1">{{ selectedMaterialForModal.item.title ||
        ('modals.kalturaPlayerModal.TITLE' | translate) }}</p>
    </div>

    <div
      *ngIf="!isGettingItemDetails && selectedMaterialForModal && selectedMaterialForModal.item && selectedMaterialForModal.item.itemId"
      class="content-modal" fxLayout="column" fxLayoutAlign="start start">
      <kaltura-player playerVisible="true" [itemId]="selectedMaterialForModal.item.itemId"
        [kalturaScript]="selectedMaterialForModal.embeddedPlayerCode">
      </kaltura-player>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <div></div>
      <!-- Chiude la modale  -->
      <custom-button importance="primary" text="{{'generic.CLOSE' | translate}}"
        (onClick)="toggleKalturaPlayerModal();">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che avverte l'utente che il prestito è già in atto  -->
<app-modal class="informative-modal" id="itemRented">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeRequestRentNotAvailableModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.RENT_REQUEST" tabindex="-1">
      </h4>
    </div>

    <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
      <h6 tabindex="0">{{ ('itemDetail.ITEM_ALREADY_RENTED' | translate) }}</h6>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <div></div>
      <!-- Chiude la modale  -->
      <custom-button importance="primary" text="{{'generic.OK' | translate}}" [disabled]="isGettingItemDetails"
        (onClick)="closeItemRentedModal();">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che avverte l'utente che il prestito non è disponibile  -->
<app-modal class="informative-modal" id="requestRentNotAvailable">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeRequestRentNotAvailableModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.RENT_REQUEST" tabindex="-1">
      </h4>
    </div>

    <div class="content-modal" fxLayout="column" fxLayoutAlign="start start">
      <h6 tabindex="0">{{ ('itemDetail.RENT_REQUEST_NOT_AVAILABLE' | translate) }}</h6>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <div></div>
      <!-- Chiude la modale  -->
      <custom-button importance="primary" text="{{'generic.OK' | translate}}" [disabled]="isGettingItemDetails"
        (onClick)="closeRequestRentNotAvailableModal();">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che avverte l'utente che la richiesta del prestito è ancora pendente  -->
<app-modal class="destructive-modal" id="theRequestIsStillPending">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeTheRequestIsStillPendingModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.RENT_REQUEST" tabindex="-1">
      </h4>
    </div>

    <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
      <div *ngIf="!isAbortRentVisible" class="md-dialog-content full-width">
        <!-- Testo informativo richiesta non ancora confermata  -->
        <h6 tabindex="0" *ngIf="!isRentConfirmed" translate="itemDetailsPage.REQUEST_STILL_PENDING"></h6>
        <!-- Testo informativo richiesta confermata ma non ancora ritirato l'oggetto  -->
        <h6 tabindex="0" *ngIf="isRentConfirmed" translate="itemDetailsPage.ITEM_NOT_RETIRED"></h6>
      </div>
      <div *ngIf="isAbortRentVisible" class="md-dialog-content full-width">
        <!-- Annulla richiesta di prestito  -->
        <h6 tabindex="0" translate="itemDetailsPage.CONFIRM_ABORT_REQUEST"></h6>
      </div>
    </div>
    <!-- Azioni per conferma l'annullamento del prestito  -->
    <div *ngIf="isAbortRentVisible" class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Indietro  -->
      <custom-button [disabled]="isGettingItemDetails" importance="primary" [text]="'generic.BACK' | translate"
        (onClick)="isAbortRentVisible = false">
      </custom-button>
      <!-- Conferma l'operazione -->
      <custom-button [disabled]="isGettingItemDetails" importance="secondary" [text]="'generic.CONFIRM' | translate"
        (onClick)="closeTheRequestIsStillPendingModal(true)">
      </custom-button>
    </div>
  </div>
  <!-- Azioni per annullare l'operazione oppure per fare lo switch della conferma dell'annullamento del prestito  -->
  <div *ngIf="!isAbortRentVisible" class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
    fxLayoutAlign.gt-xs="space-between center">
    <!-- Toggle per la conferma dell'annullamento  -->
    <custom-button importance="primary" [text]="'itemDetail.ABORT_REQUEST' | translate"
      (onClick)="isAbortRentVisible = true">
    </custom-button>
    <!-- Chiude la modale  -->
    <custom-button importance="secondary" [text]="'generic.OK' | translate"
      (onClick)="closeTheRequestIsStillPendingModal()">
    </custom-button>
  </div>
</app-modal>

<!-- Modale con il dettaglio di una nota (oppure la creazione) -->
<app-modal class="not-destructive-modal" id="itemNote">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeItemNoteModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.NOTE" tabindex="-1">
      </h4>
    </div>

    <div *ngIf="itemNoteTmp" class="content-modal full-width" fxLayout="row" fxLayoutAlign="start start">
      <custom-textarea class="full-width" [bindValue]="itemNoteTmp.title"
        title="{{ 'itemDetail.INSERT_NOTE' | translate }}" (onModelChanged)="onNoteTitleChanged($event)"
        [placeholder]="'generic.TEXT' | translate" [attrAriaLabel]="'generic.TEXT' | translate"
        inputContainerClass="regular-dimension cancelSubscriptionMessage">
      </custom-textarea>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Chiude la nota  -->
      <custom-button importance="secondary" [text]="'generic.CANCEL' | translate" (onClick)="closeItemNoteModal()">
      </custom-button>

      <div fxHide.gt-xs class="space15"></div>

      <!-- Conferma l'operazione  -->
      <custom-button importance="primary" [disabled]="!itemNoteTmp || !itemNoteTmp.title || !itemNoteTmp.title.length"
        text="{{'generic.CONFIRM' | translate}}" (onClick)="closeItemNoteModal(true)">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale per confermare la richietsa di prestito  -->
<app-modal class="informative-modal" id="requestRent">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeRequestRentModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.RENT_REQUEST" tabindex="-1">
      </h4>
    </div>

    <div *ngIf="itemDetails" class="content-modal full-width" fxLayout="row" fxLayoutAlign="start start">
      <h5 tabindex="0">{{ ('itemDetail.RENT_REQUEST_CONFIRM' | translate) + ' ' + itemDetails.title + '?' }}</h5>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <custom-button importance="secondary" [text]="'generic.CANCEL' | translate" (onClick)="closeRequestRentModal()">
      </custom-button>

      <div fxHide.gt-xs class="space15"></div>

      <!-- Conferma l'operazione  -->
      <custom-button [disabled]="isGettingItemDetails" importance="primary" text="{{'generic.CONFIRM' | translate}}"
        (onClick)="closeRequestRentModal(true)">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che chiede la conferma prima di cancellare la nota  -->
<app-modal class="destructive-modal" id="deleteNote">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeDeleteNoteModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetail.DELETE_NOTE_MODAL_TITLE" tabindex="-1">
      </h4>
    </div>

    <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
      <p tabindex="0" translate="itemDetail.DELETE_NOTE_MODAL_DESCRIPTION"></p>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Chiude la nota  -->
      <custom-button importance="secondary" [text]="'generic.CANCEL' | translate" (onClick)="closeDeleteNoteModal()">
      </custom-button>

      <div fxHide.gt-xs class="space15"></div>

      <!-- Conferma l'operazione  -->
      <custom-button importance="primary" text="{{'generic.CONFIRM' | translate}}"
        (onClick)="closeDeleteNoteModal(true)">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che chiede la conferma prima di cancellare la nota  -->
<app-modal class="destructive-modal" id="completeWebCourseModal">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeCompleteWebCourseModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetail.DID_YOU_COMPLETE_THE_ITEM_MODAL_TITLE"
        tabindex="-1">
      </h4>
    </div>

    <div class="content-modal" fxLayout="row" fxLayoutAlign="start start">
      <p tabindex="0" translate="itemDetail.DID_YOU_COMPLETE_THE_ITEM_MODAL_BODY"></p>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Chiude la nota  -->
      <custom-button importance="secondary" [text]="'generic.CANCEL' | translate"
        (onClick)="closeCompleteWebCourseModal()">
      </custom-button>

      <div fxHide.gt-xs class="space15"></div>

      <!-- Conferma l'operazione  -->
      <custom-button importance="primary" text="{{'generic.CONFIRM' | translate}}"
        (onClick)="closeCompleteWebCourseModal(true)">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che permette di cercare una persona a cui suggerire l'oggetto -->
<app-modal class="not-destructive-modal" id="suggestItem">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeModalForShareItem()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.SUGGEST" tabindex="-1">
      </h4>
    </div>

    <div *ngIf="itemDetails" class="content-modal" fxLayout="row" fxLayoutAlign="start start">
      <div fxLayout="column" class="full-width">
        <!-- Testo di ricerca per gli utenti -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center" class="full-width choose-new-supplier-modal-container">
          <form #usersToSuggestSearchedText="ngForm" class="full-width new-suppliers-to-choose-text-filter-form">
            <custom-input *ngIf="usersToSuggestData" [type]="'text'" autocompleteText="off"
              [bindValue]="usersToSuggestData.searchedText" (onModelChanged)="onGetUsersToSuggest($event, true)"
              [placeholder]="'itemDetail.TYPE_USER_NAME_TO_SUGGEST' | translate"
              [attrAriaLabel]="'itemDetail.TYPE_USER_NAME_TO_SUGGEST' | translate"
              inputContainerClass="regular-dimension input-training-passport">
            </custom-input>
          </form>
        </div>

        <div fxLayout="column">
          <div *ngIf="isFetchingUsersToSuggest">
            <generali-loader></generali-loader>
          </div>

          <!-- Lista delle persone -->
          <div class="margin-top35"
            *ngIf="!isFetchingUsersToSuggest && usersToSuggestData && usersToSuggestData.usersToSuggest && usersToSuggestData.usersToSuggest.length">
            <table role="grid" class="item-detail-table">
              <thead>
                <tr>
                  <th fxFlex="20" tabindex="0" role="columnheader" class="select-syllabus-column"
                    translate="generic.SELECT"></th>
                  <th fxFlex="80" tabindex="0" role="columnheader" class="creation-date-syllabus-column"
                    translate="itemDetailsPage.PERSON"></th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" *ngFor="let userToSuggest of usersToSuggestData.usersToSuggest | slice:0:usersToSuggestData.usersToSuggestNumRecords | paginate: { id: 'usersToSuggest',
        itemsPerPage: usersToSuggestData.usersToSuggestNumRecords,
        currentPage: usersToSuggestData.usersToSuggestCurrentPage,
        totalItems: usersToSuggestData.usersToSuggestCounter }; let i = index">
                  <td fxFlex="20" role="cell">
                    <div *ngIf="usersToSuggestData" class="md-radio padding-left16">
                      <input
                        [disabled]="userToSuggest && (userToSuggest.itemAlreadySuggested || !userToSuggest.haveTakerVisibility)"
                        type="radio" name="{{'userToSuggest' + i}}" [ngModel]="usersToSuggestData.selectedUserId"
                        value="{{userToSuggest.userId}}">
                      <label for="{{'userToSuggest' + i}}"
                        (click)="selectedUserToSuggestChanged(userToSuggest)"></label>
                    </div>
                  </td>
                  <td fxFlex="80" role="cell">
                    <p tabindex="0" class="bold-text">
                      {{ (userToSuggest && userToSuggest.surname) + ' ' + (userToSuggest && userToSuggest.forename) }}
                    </p>
                    <h5 tabindex="0"><a *ngIf="userToSuggest" href="mailto:{{ userToSuggest.email }}">{{
                        userToSuggest.email }}</a>
                    </h5>
                    <div *ngIf="userToSuggest && userToSuggest.itemAlreadySuggested">
                      <p tabindex="0" style="font-style: italic; margin-top: 5px;"
                        translate="itemDetailsPage.ALREADY_SUGGESTED"></p>
                    </div>
                    <div *ngIf="userToSuggest && !userToSuggest.haveTakerVisibility">
                      <p tabindex="0" style="font-style: italic; margin-top: 5px;"
                        translate="itemDetailsPage.TAKER_WITHOUT_TAKER_VISIBILITY"></p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Etichetta che indica che non sono stati trovati utenti -->
          <div fxLayout="row" class="margin-bottom30 margin-top10"
            *ngIf="(!isFetchingUsersToSuggest && (!usersToSuggestData || !usersToSuggestData.usersToSuggest || !usersToSuggestData.usersToSuggest.length)) && usersToSuggestData.searchedText">
            <div class="gray-info-row-container">
              <div fxLayout="row" class="btn-container">
                <p tabindex="0" class="button-label margin-bottom0" translate="itemDetailsPage.NO_PERSON_FOUND">
                </p>
              </div>
            </div>
          </div>
          <div fxLayout="row" class="margin-bottom30 margin-top10"
            *ngIf="(!isFetchingUsersToSuggest && (!usersToSuggestData || !usersToSuggestData.usersToSuggest || !usersToSuggestData.usersToSuggest.length)) && !usersToSuggestData.searchedText">
            <div class="gray-info-row-container">
              <div fxLayout="row" class="btn-container">
                <p tabindex="0" class="button-label margin-bottom0" translate="itemDetail.FIND_USERS_BY_NAME">
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer con la paginazione -->
        <div *ngIf="usersToSuggestData && usersToSuggestData.usersToSuggest && usersToSuggestData.usersToSuggest.length"
          fxLayout="row" fxLayoutAlign="center center">
          <table-pagination [forceDisabled]="isFetchingUsersToSuggest" [id]="'usersToSuggest'"
            (pageChanged)="paginationUsersToSuggestItemChanged($event, usersToSuggestData.searchedText)">
          </table-pagination>
        </div>
        <div class="space20"></div>
      </div>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla l'operazione -->
      <custom-button importance="secondary" text="{{'generic.CANCEL' | translate}}"
        (onClick)="closeModalForShareItem()">
      </custom-button>
      <div fxHide.gt-xs class="space15"></div>
      <!-- Conferma l'operazione -->
      <custom-button importance="primary" text="{{'generic.CONFIRM' | translate}}"
        [disabled]="!usersToSuggestData || !usersToSuggestData.selectedUserId"
        (onClick)="closeModalForShareItem(true);">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che mostra l'oggetto propedeutico a quello selezionato -->
<app-modal class="informative-modal" id="showPropedeuticalItemToThisModal">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeShowPropedeuticalItemToThisModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="card.modal.PREREQUISITE" tabindex="-1">
      </h4>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <svg-icon class="big-lock-icon" src="../../../assets/icons/lock.svg"></svg-icon>
    </div>

    <div class="content-modal" fxLayout="column" fxLayoutAlign="start center">
      <h5 tabindex="0"><span translate="card.modal.PREREQUITE_INFO"></span>: </h5>
      <div class="space15"></div>
      <h4 tabindex="0" class="text-uppercase">
        {{ showPropedeuticalItemToThisModal && showPropedeuticalItemToThisModal.propedeuticalObject &&
        showPropedeuticalItemToThisModal.propedeuticalObject.childObject &&
        (showPropedeuticalItemToThisModal.propedeuticalObject.childObject.title ||
        showPropedeuticalItemToThisModal.propedeuticalObject.childObject.name) }}
      </h4>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <div></div>
      <custom-button importance="primary" dimension="btn-small" [text]="'generic.OK' | translate"
        (onClick)="closeShowPropedeuticalItemToThisModal()"></custom-button>
    </div>
  </div>
</app-modal>

<!--  Modale che mostra l'oggetto propedeutico a quello selezionato -->
<app-modal class="informative-modal" id="showPropedeuticalToThis">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closePropedeuticalModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="card.modal.PREREQUISITE" tabindex="-1">
      </h4>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
      <svg-icon class="big-lock-icon" src="../../../assets/icons/lock.svg"></svg-icon>
    </div>

    <div fxLayoutAlign="space-between center" fxLayout="column" class="full-height">
      <div fxLayout="column" fxLayoutAlign="start center">
        <p tabindex="0">{{ ('card.modal.PREREQUITE_INFO' | translate) + ':' }}</p>
        <div class="space15"></div>
        <p tabindex="0" class="bold-text">
          {{ propedeuticalObject && propedeuticalObject.childObject && (propedeuticalObject.childObject.title ||
          propedeuticalObject.childObject.name) }}
        </p>
      </div>

      <!-- Chiude la modale -->
      <custom-button importance="primary" text="{{'generic.OK' | translate | uppercase }}"
        (onClick)="closePropedeuticalModal();">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che permette di cercare una playlist a cui aggiungere l'item -->
<app-modal class="not-destructive-modal" id="addItemToPlaylist">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeAddItemToExistingPlaylistModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="generic.itemTypes.LEARNING_PLAN" tabindex="-1">
      </h4>
    </div>

    <div *ngIf="itemDetails" class="content-modal" id="add-item-to-playlist-content-modal" fxLayout="column"
      fxLayoutAlign="start start">
      <div class="margin-bottom10 margin-top10 full-width" fxLayout="column">
        <p tabindex="0" translate="itemDetailsPage.CHOOSE_YOUR_PLAYLIST"></p>
      </div>
      <div fxLayout="column" class="full-width">
        <!-- Testo di ricerca per gli item  -->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center" class="full-width choose-new-supplier-modal-container">
          <form #learningPlanSearchedText="ngForm" class="full-width new-suppliers-to-choose-text-filter-form">
            <custom-input *ngIf="libraryItemsData" [type]="'text'" autocompleteText="off"
              [bindValue]="libraryItemsData.searchedText" (onModelChanged)="onGetLibraryItems($event, true)"
              [placeholder]="'itemDetailsPage.TYPE_ITEM_NAME' | translate"
              [attrAriaLabel]="'itemDetailsPage.TYPE_ITEM_NAME' | translate"
              inputContainerClass="regular-dimension input-training-passport">
            </custom-input>
          </form>
        </div>

        <div fxLayout="column">
          <div *ngIf="isFetchingLibraryItems">
            <generali-loader></generali-loader>
          </div>

          <!-- Item selezionato -->
          <!-- Lista degli oggetti (se non ho ancora selezionato un Item) -->
          <div class="margin-top35"
            *ngIf="!isFetchingLibraryItems && libraryItemsData && libraryItemsData.libraryItems && libraryItemsData.libraryItems.length">
            <table role="grid" class="item-detail-table">
              <thead>
                <tr>
                  <th tabindex="0" role="columnheader" class="select-syllabus-column" translate="generic.SELECT"></th>
                  <th tabindex="0" role="columnheader" translate="generic.TITLE"></th>
                  <th tabindex="0" role="columnheader" class="creation-date-syllabus-column"
                    translate="generic.CREATION_DATE"></th>
                </tr>
              </thead>
              <tbody>
                <tr role="row" *ngFor="let item of libraryItemsData.libraryItems | slice:0:libraryItemsData.libraryItemsNumRecords | paginate: { id: 'libraryItems',
        itemsPerPage: libraryItemsData.libraryItemsNumRecords,
        currentPage: libraryItemsData.libraryItemsCurrentPage,
        totalItems: libraryItemsData.libraryItemsCounter }; let i = index">
                  <td role="cell">
                    <div *ngIf="libraryItemsData" class="md-radio padding-left16">
                      <input type="radio" name="{{'learningPlan' + i}}" [ngModel]="libraryItemsData.selectedItemId"
                        value="{{item.itemId}}">
                      <label for="{{'learningPlan' + i}}" (click)="selectedItemOfOnlineCourseChanged(item)"></label>
                    </div>
                  </td>
                  <td tabindex="0" role="cell">
                    {{ item.title }}
                  </td>
                  <td tabindex="0" role="cell">
                    {{ item.creationDate | amLocale:'it' | amUtc | amDateFormat:
                    ('generic.DATE_FORMAT' | translate) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Etichetta che indica che non sono stati trovati item -->
          <div fxLayout="row" class="margin-bottom30 margin-top10"
            *ngIf="!isFetchingLibraryItems && (!libraryItemsData || !libraryItemsData.libraryItems || !libraryItemsData.libraryItems.length)">
            <div class="gray-info-row-container">
              <div fxLayout="row" class="btn-container">
                <p tabindex="0" class="button-label margin-bottom0" translate="generic.NO_DATA_FOUND">
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Footer con la paginazione -->
        <div *ngIf="libraryItemsData && libraryItemsData.libraryItems && libraryItemsData.libraryItems.length"
          fxLayout="row" fxLayoutAlign="center center">
          <table-pagination [forceDisabled]="isFetchingLibraryItems" [id]="'libraryItems'"
            (pageChanged)="paginationLibraryItemsChanged($event)"></table-pagination>
        </div>
        <div class="space20"></div>
      </div>

    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla l'operazione -->
      <custom-button importance="secondary" text="{{'generic.CANCEL' | translate}}"
        (onClick)="closeAddItemToExistingPlaylistModal()">
      </custom-button>
      <div fxHide.gt-xs class="space15"></div>
      <!-- Conferma l'operazione di aggiunta item alla Plyalist selezionata -->
      <custom-button importance="primary" text="{{'generic.CONFIRM' | translate}}"
        [disabled]="!libraryItemsData || !libraryItemsData.selectedItemId"
        (onClick)="closeAddItemToExistingPlaylistModal(true);">
      </custom-button>
    </div>
  </div>
</app-modal>

<!-- Modale che permette di creare una nuova playlist a cui aggiungere l'oggetto atomico -->
<app-modal class="not-destructive-modal" id="createPlaylistToAddItem">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeCreatePlaylistAddModal()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="generic.itemTypes.LEARNING_PLAN" tabindex="-1">
      </h4>
    </div>

    <div *ngIf="itemDetails" class="content-modal" fxLayout="column" fxLayoutAlign="start start">
      <!-- Form di creazione nuova playlist -->
      <div class="margin-bottom10 margin-top10 full-width" fxLayout="column">
        <p tabindex="0" translate="itemDetailsPage.CREATE_PLAYLIST_USER_HINT"></p>
      </div>

      <!-- Titolo -->
      <div fxLayout="column" fxFill fxLayoutAlign="space-between center"
        class="playlist-new-title-container full-width choose-new-supplier-modal-container">
        <div fxLayout="row" fxFill class="full-width new-suppliers-to-choose-text-filter-form">
          <div class="full-width" fxLayout="row" *ngIf="isCurrentItemLangDefaultLang() && currentItemLang">
            <custom-input id="itemLangTitle" [type]="'text'" [bindValue]="currentItemLang.title"
              title="{{ getItemLangInputSubtitle() }}" (onModelChanged)="onCurrentItemLangTitleChanged($event)"
              [attrName]="'itemLangTitle'" name="itemLangTitle" [required]="true" autocompleteText="off"
              [placeholder]="'generic.TITLE' | translate" [attrAriaLabel]="'generic.TITLE' | translate"
              inputContainerClass="regular-dimension input-training-passport" inputClass="basic-input"
              class="full-width">
            </custom-input>
          </div>
          <div class="full-width" fxLayout="row"
            *ngIf="!isCurrentItemLangDefaultLang() && !currentItemLang && defaultItemLang">
            <custom-input id="itemTitle" [type]="'text'" [bindValue]="defaultItemLang.title"
              title="{{ getItemLangInputSubtitle() }}" (onModelChanged)="onDefaultItemLangTitleChanged($event)"
              [attrName]="'itemTitle'" name="itemTitle" [required]="true" [placeholder]="'generic.TITLE' | translate"
              [attrAriaLabel]="'generic.TITLE' | translate" autocompleteText="off"
              inputContainerClass="regular-dimension input-training-passport" inputClass="basic-input"
              class="full-width">
            </custom-input>
          </div>
        </div>
      </div>

      <!-- Sottotitolo -->
      <div fxFill fxLayout="row" class="playlist-new-subtitle-container">
        <div class="full-width" *ngIf="isCurrentItemLangDefaultLang() || (currentItemLang && currentItemLang.subTitle)">
          <custom-textarea [bindValue]="currentItemLang.subTitle" title="{{ getCurrentItemLangDescSubtitle() }}"
            (onModelChanged)="onCurrentItemLangChanged($event)" [placeholder]="'generic.SUBTITLE' | translate"
            [attrAriaLabel]="'generic.SUBTITLE' | translate" inputContainerClass="full-textarea" [subtitle]="">
          </custom-textarea>
        </div>
        <div class="full-width" *ngIf="!isCurrentItemLangDefaultLang() && currentItemLang && !currentItemLang.subTitle">
          <custom-textarea [bindValue]="defaultItemLang.subTitle" title="{{ getCurrentItemLangDescSubtitle() }}"
            (onModelChanged)="onDefaultItemLangChanged($event)" [placeholder]="'generic.SUBTITLE' | translate"
            [attrAriaLabel]="'generic.SUBTITLE' | translate" inputContainerClass="full-textarea">
          </custom-textarea>
        </div>
      </div>

      <!-- Descrizione -->
      <div class="margin-top70" fxFill fxLayout="column" fxLayoutAlign="start start">
        <div class="title-input full-width">
          <p tabindex="0" class="margin-bottom8">{{ ('newContainer.placeholders.INSERT_LEARNING_PLAN_DESCRIPTION' |
            translate) + (isDescriptionValorized() ?
            ''
            : '*') }}</p>
        </div>

        <div fxFill fxLayout="row" *ngIf="ckeConfig">
          <div class="regular-dimension"
            *ngIf="isCurrentItemLangDefaultLang() || (currentItemLang && currentItemLang.description)">
            <ckeditor #ckeditor [(ngModel)]="currentItemLang.description" [config]="ckeConfig">
            </ckeditor>
          </div>
          <div class="regular-dimension"
            *ngIf="!isCurrentItemLangDefaultLang() && currentItemLang && !currentItemLang.description">
            <ckeditor #ckeditor [(ngModel)]="currentItemLang.description" [config]="ckeConfig">
            </ckeditor>
          </div>
        </div>
      </div>
    </div>
    <div class="actions" fxLayout.gt-xs="row" fxLayout="column" fxLayoutAlign="center center"
      fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla l'operazione -->
      <custom-button importance="secondary" text="{{'generic.CANCEL' | translate}}"
        (onClick)="closeCreatePlaylistAddModal()">
      </custom-button>
      <div fxHide.gt-xs class="space15"></div>
      <!-- Conferma la creazione di una nuova Playlist -->
      <custom-button importance="primary" text="{{'itemDetail.CREATE' | translate}}"
        [disabled]="isItemLangTitlesEmpty()|| !isDescriptionValorized() || isGettingItemDetails"
        (onClick)="closeCreatePlaylistToAddItemModal()">
      </custom-button>
    </div>
  </div>
</app-modal>

<!--  Modale di messaggio condivisione -->
<app-modal class="not-destructive-modal share-certificate-message" id="shareCertificateMessage">
  <div class="container" fxLayout="column" fxLayoutAlign="start center">
    <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <svg-icon class="close-modal-icon" src="assets/icons/icon-close-light.svg" height="40" width="40"
        (click)="closeShareModalCertificateMessage()">
      </svg-icon>
    </div>

    <div class="modal-title full-width" fxLayout="row" fxLayoutAlign="center center">
      <h4 class="center lighter margin-bottom40" translate="itemDetailsPage.SHARE_MODAL_MESSAGE.TITLE" tabindex="-1">
      </h4>
    </div>

    <div class="divider margin-top16"></div>
    <div *ngIf="!isFetchingCoursesCertifications" class="share-certificate-message" fxLayout="column"
      fxLayoutAlign="start start">
      <img class="certificate-img" [src]="certificateUrl">
      <p translate="itemDetailsPage.SHARE_MODAL_MESSAGE.YOU_CAN_COPY_MESSAGE"></p>
      <div fxLayout="column" fxLayoutAlign="center start" class="message-to-copy-container">
        <p translate="itemDetailsPage.SHARE_MODAL_MESSAGE.SHARE_CERTIFICATION_1"></p>
        <p translate="itemDetailsPage.SHARE_MODAL_MESSAGE.SHARE_CERTIFICATION_2" class="bold-text margin-top30"></p>
      </div>
      <button class="copy-text-share-linkedin" (click)="copyShareCertificateText()">
        <p translate="itemDetailsPage.SHARE_MODAL_MESSAGE.COPY_TEST_TO_PASTE_ON_LINKEDIN"></p>
      </button>
    </div>

    <div class="full-width" *ngIf="isFetchingCoursesCertifications">
      <generali-loader></generali-loader>
    </div>
    <div *ngIf="editionCertificationToShare" class="actions" fxLayout.gt-xs="row" fxLayout="column"
      fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center">
      <!-- Annulla -->
      <custom-button importance="secondary" [text]="'generic.CANCEL' | translate"
        (onClick)="closeShareModalCertificateMessage()">
      </custom-button>
      <!-- Condividi -->
      <div linkedinShare [linkedinShareUrl]="editionCertificationToShare.linkedinUrl">
        <custom-button importance="primary" dimension="btn-regular" [text]="'generic.SHARE' | translate"
          (click)="trackShareCertificate(editionCertificationToShare.courseDateId)">
        </custom-button>
      </div>
    </div>
  </div>
</app-modal>

<!-- Lista di oggetti contenuti nella playlist -->
<ng-template #playlistChildsList let-item='item' let-itemLinkedToCourse="itemLinkedToCourse">
  <div class="lp-box-container" fxLayout="row">
    <div class="course-details-container" fxLayout="column"
      [ngClass]="{ 'addVerticalScrollbar' : item && item.itemChilds && item.itemChilds.length && item.itemChilds.length > 10 }">
      <!-- Oggetti completati della playlist -->
      <div class="padding-35-left-right" fxLayout="column" fxLayoutAlign="center start">
        <h6 class="lp-items-childs-title">{{ (item.itemChilds.length || 0)
          +
          ' ' +
          (item.itemChilds.length === 1 ? ('generic.OBJECT' | translate | lowercase) :
          ('generic.OBJECTS'
          |
          translate | lowercase))
          }}</h6>
        <p class="lp-items-childs-subtitle" translate="generic.SELECT_PL_OBJECT_TO_START"></p>
      </div>

      <div class="lp-childs-header-separator"></div>

      <!-- Oggetti contenuti nella playlist -->
      <div fxLayout="column" fxLayoutAlign="center start">
        <div *ngFor="let itemChild of item.itemChilds; let last = last;" fxLayout="row"
          class="item-child-row full-width" (click)="openPlaylistItemDetails({
        itemId: itemChild.courseId,
        isItemLocked: itemChild.isItemLocked,
        singleItemChild: itemChild })"
          [ngClass]="{ 'with-border-bottom' : !last, 'itemLinkedToCourse': itemLinkedToCourse }">

          <!-- Completato/verificato/bloccato -->
          <div class="icons-container">
            <svg-icon class="icon-check-small" *ngIf="(itemChild.isCompleted || itemChild.isVerified); else arrowRight"
              src="../../../assets/icons/green-check.svg"></svg-icon>

            <ng-template #arrowRight>
              <svg-icon *ngIf="itemChild.isItemLocked" src="../../../assets/icons/lock.svg"></svg-icon>
              <svg-icon *ngIf="!itemChild.isItemLocked" src="../../../assets/icons/icon-arrow-right.svg">
              </svg-icon>
            </ng-template>
          </div>

          <div fxLayout="column" class="full-width min-width-100">
            <div fxLayout="row" fxLayoutAlign="space-between start" class="item-child-title-and-header full-width">
              <!-- Titolo -->
              <div fxLayout="row" class="full-width">
                <p class="item-child-title">{{ itemChild.title }}</p>
              </div>

              <!-- Durata -->
              <div fxLayout="row">
                <p class="item-child-header-title">{{ itemChild.headerDetail }}</p>
              </div>
            </div>

            <!-- Oggetto propedeutico/obbligatorio -->
            <div *ngIf="!itemChild.isItemLocked && (itemChild.showPropedeuticLabel || itemChild.showRequiredLabel)"
              fxLayout="row">
              <p *ngIf="itemChild.showPropedeuticLabel" class="item-child-propedeutic" translate="generic.PROPEDEUTIC">
              </p>
              <p *ngIf="itemChild.showRequiredLabel" class="item-child-mandatory" translate="generic.MANDATORY"></p>
            </div>

            <!-- Oggetto bloccato -->
            <div *ngIf="itemChild.isItemLocked" fxLayout="row">
              <p class="item-child-propedeutic" translate="generic.BLOCKED"></p>
              <p class="item-locked-label" translate="itemDetailsPage.HOW_CAN_I_UNBLOCK_IT"></p>
            </div>

            <!-- Linea di progressione -->
            <!-- Se serve, questa è la percentuale di avanzamento: <p tabindex="0">{{completionPercentage}}%</p>-->
            <div *ngIf="!itemChild.isItemLocked" class="percentage-data-line" #itemChildPercentageDataLine
              fxLayout="row" fxLayoutAlign="start center">
              <div class="percentage-data-line-completed"
                [ngStyle]="{'width': ((itemChild.completionPercentage || 0) ) +'%'}">
              </div>
            </div>
          </div>
          <!-- Linea di separazione tra un oggetto e l'altro-->
          <!--  <div class="item-child-end-line full-width"></div> -->
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Edizione selezionata del corso del cm2 -->
<ng-template #selectedEditionInfo let-item='item'>
  <div fxFlexOffset="24px" fxFlexOffset.xs="20px" fxLayout="row" fxLayoutAlign="start start">
    <svg-icon src="../../../assets/icons/icon-calendar.svg"></svg-icon>

    <div fxLayout="column" class="editions-container">
      <div *ngFor="let courseDay of item.days; let i = index; let first = first" fxLayout="column"
        fxLayoutAlign="start start" [ngClass]="{ 'margin-top20' : !first }">
        <div fxFill fxLayout="row" fxLayoutAlign="space-between center">
          <h6 tabindex="0" class="margin-bottom10" *ngIf="item.days && item.days.length && item.days.length > 1">
            {{ ('itemDetailsPage.datesAndPlaces.DAY_DATE' | translate) }} {{ i + 1 }}</h6>
        </div>
        <div fxFill fxLayout="column" fxLayoutAlign="start start">
          <h6 class="margin-bottom4" tabindex="0">
            <b>{{ courseDay.dayDate | amLocale:'it' | amDateFormat: stopDateCourseSubscriptionFormat }}</b>
          </h6>
          <h6 class="margin-bottom4" tabindex="0"
            *ngIf="courseDay.location && courseDay.location.address && courseDay.location.address.id">
            {{ courseDay.location.address.id }}</h6>
          <h6 class="margin-bottom4" tabindex="0" [hidden]="!courseDay.formattedTimeSchedule">
            <span>{{ courseDay.formattedTimeSchedule }}</span>
          </h6>
          <h6 class="margin-bottom4" tabindex="0"
            *ngIf="courseDay && courseDay.location && courseDay.location.streetNumber && courseDay.location.streetNumber.title">
            {{ courseDay.location.streetNumber.title }}</h6>
          <h6 class="margin-bottom4" tabindex="0" *ngIf="courseDay && courseDay.selectedRoom">
            {{ ('itemDetailsPage.datesAndPlaces.placeholders.ROOM' | translate) + " " +
            (courseDay.selectedRoom
            || courseDay.location.room.title || '')
            }}
          </h6>
          <h6 class="margin-bottom4" tabindex="0"
            *ngIf="courseDay && courseDay.location && courseDay.location.building && courseDay.location.building.title">
            {{ courseDay.location.building.title }}</h6>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<!-- Lista di oggetti contenuti nel progetto -->
<ng-template #projectChildsList let-item='item' let-itemLinkedToCourse="itemLinkedToCourse">
  <div class="lp-box-container" fxLayout="row">
    <div class="course-details-container" fxLayout="column"
      [ngClass]="{ 'addVerticalScrollbar' : item && item.itemChilds && item.itemChilds.length && item.itemChilds.length > 10 }">
      <div class="padding-35-left-right" fxLayout="column" fxLayoutAlign="center start">
        <!-- Numero degli oggetti -->
        <h6 class="lp-items-childs-title">{{ getSectionsItemsCounter(item) }}</h6>
        <p class="lp-items-childs-subtitle" translate="itemDetailsPage.SELECT_MODULE_TO_START"></p>
      </div>

      <!-- Lista delle sezioni -->
      <div *ngIf="item.itemChilds && item.itemChilds.length">
        <div *ngFor="let section of item.itemChilds; let lastSection = last;" fxLayout="column"
          fxLayoutAlign="center start">
          <div class="lp-childs-header-separator"></div>
          <div fxLayout="row" class="item-child-row full-width"
            [ngClass]="{ 'with-border-bottom' : !lastSection, 'itemLinkedToCourse': itemLinkedToCourse }">
            <!-- Completato/verificato/bloccato -->
            <div>
              <svg-icon class="icon-check-small" *ngIf="(section.isCompleted || section.isVerified); else arrowRight"
                src="../../../assets/icons/green-check.svg"></svg-icon>

              <ng-template #arrowRight>
                <div class="section-svg-icon" *ngIf="section.isItemLocked">
                  <svg-icon src="../../../assets/icons/lock.svg"></svg-icon>
                </div>
              </ng-template>
            </div>

            <div fxLayout="column" class="full-width min-width-100">
              <div fxLayout="row" fxLayoutAlign="space-between start" class="item-child-title-and-header full-width">
                <!-- Titolo -->
                <div fxLayout="column" class="full-width">
                  <p class="item-child-title uppercase"
                    [ngClass]="{ 'margin-bottom25': !section.isItemLocked && !section.childObject.subTitle && !isSectionMandatoryOrPropedeutical(section) }">
                    {{
                    section.childObject.title }}</p>
                  <p *ngIf="section.childObject.subTitle"
                    [ngClass]="{ 'margin-bottom25': !section.isItemLocked && !isSectionMandatoryOrPropedeutical(section) }"
                    class="item-child-subtitle">{{ section.childObject.subTitle }}
                  </p>

                  <!-- Sezione bloccata -->
                  <p *ngIf="section.isItemLocked" (click)="onSectionBlockedClicked({
                  itemId: section.itemId,
                  sectionObj: section })" class="item-locked-label margin-bottom25 cursor-pointer"
                    translate="itemDetailsPage.HOW_CAN_I_UNBLOCK_SECTION"
                    [ngClass]="{ 'margin-bottom25': !isSectionMandatoryOrPropedeutical(section) }"></p>

                  <!-- Sezione propedeutica/obbligatoria -->
                  <div *ngIf="isSectionMandatoryOrPropedeutical(section)" class="margin-bottom25" fxLayout="row">
                    <p *ngIf="section.showPropedeuticLabel" class="item-child-propedeutic"
                      translate="generic.PROPEDEUTIC">
                    </p>
                    <p *ngIf="section.showRequiredLabel" class="item-child-mandatory" translate="generic.MANDATORY"></p>
                  </div>
                </div>

              </div>

              <!-- Lista degli oggetti contenuti nella sezione -->
              <div *ngFor="let itemChild of section.childObject.itemChilds;" fxLayout="row"
                class="item-child-row item-section-row full-width cursor-pointer" (click)="openSectionItemDetails({
        itemId: itemChild.childObject.itemId,
        isItemLocked: itemChild.isItemLocked,
        singleItemChild: itemChild,
        sectionObj: section })" [ngClass]="{ 'itemLinkedToCourse': itemLinkedToCourse }">

                <!-- Completato/verificato/bloccato -->
                <div class="icons-container">
                  <svg-icon class="icon-check-small"
                    *ngIf="(itemChild.isCompleted || itemChild.isVerified); else arrowRight"
                    src="../../../assets/icons/green-check.svg"></svg-icon>

                  <ng-template #arrowRight>
                    <svg-icon *ngIf="itemChild.isItemLocked" src="../../../assets/icons/lock.svg"></svg-icon>
                    <svg-icon *ngIf="!itemChild.isItemLocked" src="../../../assets/icons/icon-arrow-right.svg">
                    </svg-icon>
                  </ng-template>
                </div>

                <div fxLayout="column" class="full-width min-width-100">
                  <div fxLayout="row" fxLayoutAlign="space-between start"
                    class="item-child-title-and-header full-width">
                    <!-- Titolo -->
                    <div fxLayout="row" class="full-width">
                      <p class="item-child-title">{{ itemChild.title }}</p>
                    </div>

                    <!-- Durata -->
                    <div fxLayout="row">
                      <p class="item-child-header-title">{{ itemChild.headerDetail }}</p>
                    </div>
                  </div>

                  <!-- Oggetto propedeutico/obbligatorio -->
                  <div
                    *ngIf="!itemChild.isItemLocked && (itemChild.showPropedeuticLabel || itemChild.showRequiredLabel)"
                    fxLayout="row">
                    <p *ngIf="itemChild.showPropedeuticLabel" class="item-child-propedeutic"
                      translate="generic.PROPEDEUTIC">
                    </p>
                    <p *ngIf="itemChild.showRequiredLabel" class="item-child-mandatory" translate="generic.MANDATORY">
                    </p>
                  </div>

                  <!-- Oggetto bloccato -->
                  <div *ngIf="itemChild.isItemLocked" fxLayout="row">
                    <p class="item-child-propedeutic" translate="generic.BLOCKED"></p>
                    <p class="item-locked-label" translate="itemDetailsPage.HOW_CAN_I_UNBLOCK_IT"></p>
                  </div>
                  <!-- Linea di progressione -->
                  <!-- Se serve, questa è la percentuale di avanzamento: <p tabindex="0">{{completionPercentage}}%</p>-->
                  <div *ngIf="!itemChild.isItemLocked" class="percentage-data-line" #itemChildPercentageDataLine
                    fxLayout="row" fxLayoutAlign="start center">
                    <div class="percentage-data-line-completed"
                      [ngStyle]="{'width': ((itemChild.completionPercentage || 0) ) +'%'}">
                    </div>
                  </div>
                </div>
                <!-- Linea di separazione tra un oggetto e l'altro-->
                <!--  <div class="item-child-end-line full-width"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>