import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemAttributeInitiativeTypes, CourseTypes, ItemTypes } from 'atfcore-commonclasses';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { parseBoolean } from 'src/commonclasses';

@Component({
  selector: 'slick-section-header',
  templateUrl: './slick-section-header.component.html',
  styleUrls: ['./slick-section-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlickSectionHeaderComponent {
  @Input() sectionId: string;
  @Input() sectionTitle: string;
  @Input() sectionSubtitle: string;
  @Input() sectionDescription: string;
  @Input() resultsNumber: number;
  @Input() orderPresent: boolean;
  @Input() order: string;
  @Input() linkText: string;
  @Input() filterPresent: boolean;
  @Input() filter: string;
  @Input() sectionUnderDescription: string;
  @Input() isInLearningSystem: boolean;
  // Colori elementi del componente (red-border, green-border, white-border, orange-border)
  @Input() titleWrapperClass: string;
  @Input() showAll: boolean;
  @Input() colorClusterDetail;
  @Input() isFetchingYouAreInvitedCourses;
  @Input() counterTypesYouAreInvited: any;
  @Input() isFetchingYouAreInvitedCoursesCountersFilters: boolean;
  @Input() isFromYouAreInvitedMyLearning: boolean;
  @Input() isFromDetailClusterOnLS: boolean;
  @Input() isLoadingResults: boolean;
  @Input() goBackLabel: boolean;
  @Input() contentLength: number;
  @Input() showRequiredLabel: boolean;
  @Input() showPropedeuticLabel: boolean;
  @Input() isContentBlocked: boolean;
  @Input() contentBlockedDescription: string;
  @Input() isFilterHeader: boolean;
  @Input() fullItemBlocked;
  @Input() isFacultyOrder: boolean;
  @Input() isFromDIStudy: boolean;

  @Output() showAllClicked = new EventEmitter<any>();
  @Output() searchYouAreInvitedCourses = new EventEmitter<any>();
  @Output() goBackButton = new EventEmitter<any>();
  @Output() onContentBlockedClicked = new EventEmitter<{ itemId: string, webCourseType?: string, isItemLocked?: boolean, singleItemChild?, sectionObj?}>();
  @Output() orderFacultyCourses = new EventEmitter<any>();

  youAreInvitedTypeList: any[];
  selectedYouAreInvitedType: any;
  @Input() facultyOrderList: any[];
  @Input() selectedOrder: any;


  constructor(
    private redirectService: RedirectService,
    private translate: TranslateService
  ) {

    this.translate.get(
      [
        'typesYouAreInvited.ALL',
        'typesYouAreInvited.OBBLIGATORY',
        'typesYouAreInvited.ISTITUTIONAL',
        'typesYouAreInvited.ONLINE',
        'typesYouAreInvited.ROOM',
        'typesYouAreInvited.WEB',
        'typesYouAreInvited.SURVEYS'
      ])
      .subscribe(translations => {
        /* if(this.isFromYouAreInvitedMyLearning) { */
        this.youAreInvitedTypeList = [
          { name: translations['typesYouAreInvited.ALL'], code: 'ALL' },
          { name: translations['typesYouAreInvited.OBBLIGATORY'], code: "REQUIRED", keyForCount: "REQUIRED" },
          { name: translations['typesYouAreInvited.ISTITUTIONAL'], code: ItemAttributeInitiativeTypes.INSTITUTIONAL, keyForCount: 'ISTITUTIONAL' },
          { name: translations['typesYouAreInvited.ONLINE'], code: CourseTypes.ONLINE, keyForCount: CourseTypes.ONLINE },
          { name: translations['typesYouAreInvited.ROOM'], code: CourseTypes.CLASS, keyForCount: CourseTypes.CLASS },
          { name: translations['typesYouAreInvited.SURVEYS'], code: ItemTypes.ASSESSMENT, keyForCount: ItemTypes.ASSESSMENT }
        ];
        // Seleziono la prima option
        this.selectedYouAreInvitedType = this.youAreInvitedTypeList && this.youAreInvitedTypeList[0];
        /*    } */
      });
  }

  ngOnInit() {
    this.showAll = parseBoolean(this.showAll);
    this.sectionId = this.sectionId;
    this.sectionTitle = this.sectionTitle;
    this.resultsNumber = this.resultsNumber;
    this.orderPresent = this.orderPresent;
    this.contentLength = Number(this.contentLength);
    if (this.orderPresent) {
      this.sectionSubtitle = this.sectionSubtitle;
      this.order = this.order;
    }
    this.sectionDescription = this.sectionDescription;
    this.linkText = this.linkText;
    // In standby
    // this.filterPresent = this.filterPresent || true;
    // if (this.filterPresent) {
    //   this.filterText = this.translate.instant('itemDetailsPage.section_header.FILTER_FOR');
    //   this.filter = this.filter || 'tutti';
    // }
  }

  emitOnContentBlockedClicked() {
    const navigationData = {
      itemId: this.fullItemBlocked,
      isItemLocked: this.isContentBlocked,
      sectionObj: this.fullItemBlocked
    }
    this.onContentBlockedClicked.emit(navigationData);
  }

  onShowAllClicked() {
    if (!this.showAll) {
      this.showAll = true;
    }
    this.showAllClicked.emit();
  }

  onTypeYouAreInvitedSelectChange() {
    this.searchYouAreInvitedCourses.emit(this.selectedYouAreInvitedType);
  }

  onFacultyOrderSelectChange() {
    this.orderFacultyCourses.emit(this.selectedOrder);
  }

  onGetBackFromDetail() {
    this.goBackButton.emit();
  }

  counterTypesYouAreInvitedNotExist() {
    if (this.counterTypesYouAreInvited && Object.keys(this.counterTypesYouAreInvited).length) {
      return false;
    } else {
      return true;
    }
  }
}
