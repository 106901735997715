import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SectionTab } from 'src/app/shared/interfaces/tab.interface';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from '../modal/modal-services.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  isDesktop: boolean;

  //#region TABS

  goToGeneric = () => {

  }

  goToHome = () => {
    this.redirectService.goToHomeGenerali();
  }

  goToPrivacy = () => {
    this.redirectService.goToPrivacy();
  }

  goToPrivacySecurity = () => {
    this.redirectService.goToPrivacySecurity();
  }

  goToTermsOfUse = () => {
    this.redirectService.goToTermsOfUse();
  }

  //#endregion

  //#region SOCIALS



  //#endregion

  readonly socialButtons: SectionTab[] = [
    {
      title: 'Twitter',
      redirect: 'https://twitter.com/generaliitalia',
      srcIcon: '../../../assets/icons/social/twitter.svg'
    }, {
      title: 'YouTube',
      redirect: 'https://www.youtube.com/user/GruppoGeneraliItalia',
      srcIcon: '../../../assets/icons/social/youtube.svg'
    }, {
      title: 'Instagram',
      redirect: 'https://www.instagram.com/generaliitalia/?hl=it',
      srcIcon: '../../../assets/icons/social/instagram.svg'
    }, {
      title: 'Facebook',
      redirect: 'https://www.facebook.com/GeneraliItalia/',
      srcIcon: '../../../assets/icons/social/facebook.svg'
    }, {
      title: 'LinkedIn',
      redirect: 'https://www.linkedin.com/company/generaliitalia/',
      srcIcon: '../../../assets/icons/social/linkedin.svg'
    }, {
      title: 'Spotify',
      redirect: 'https://open.spotify.com/show/44MZVTxTI9JMYfmMNQSxAz/',
      srcIcon: '../../../assets/icons/social/spotify-icon.svg'
    }
  ];

  readonly firstFooterTabs: SectionTab[] = [
    {
      title: 'footer.menu.HOME'
    }, {
      title: 'footer.menu.FEATURED'
    }, {
      title: 'footer.menu.COLLECTIONS'
    }, {
      title: 'footer.menu.FOR_YOU'
    }, {
      title: 'footer.menu.FAQ'
    }, {
      title: 'footer.menu.PROFILE'
    }
  ];

  constructor(
    private modalService: ModalService,
    private redirectService: RedirectService,
    deviceService: DeviceDetectorService,
    private analyticsService: AnalyticsService) {

    this.isDesktop = deviceService.isDesktop();
  }

  openLink(item) {
    this.redirectService.removeSessionStorageSearchData();
    if (item.title === 'footer.menu.HOME') {
      this.redirectService.goToHome();
    }
    else if (item.title === 'footer.menu.FEATURED') {
      this.redirectService.goToFeaturedCourses();
    }
    else if (item.title === 'footer.menu.COLLECTIONS') {
      this.redirectService.goToCollectionsHome();
    }
    else if (item.title === 'footer.menu.FOR_YOU') {
      this.redirectService.goToSelectedForYou();
    }
    else if (item.title === 'footer.menu.FAQ') {
      this.redirectService.goToSupport();
    }
    else if (item.title === 'footer.menu.PROFILE') {
      this.redirectService.goToProfile();
    }
  }

  trackByTitle(index: any, item: any) {
    return item.title;
  }

}
