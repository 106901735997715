import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnInit, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';

import { ModalService } from './modal-services.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})

export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() isWithModalBodyClass: boolean;
  @Input() modalBodyClass: string;

  _loginRequired: boolean;
  @Input() set loginRequired(value: boolean | string) {
    this._loginRequired = coerceBooleanProperty(value);
  };

  _isAuthenticated: boolean;
  @Input() set isAuthenticated(value: boolean | string) {
    this._isAuthenticated = coerceBooleanProperty(value);
  };

  @Output() onModalClose = new EventEmitter<any>();

  private element: any;

  constructor(private modalService: ModalService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    let modal = this;
    // Devo assicurarmi di avere l'id della modale
    if (!this.id) {
      return;
    }

    // Affinché venga resa visibile ovunque, la appendo appena dopo la chiusura del </body>
    document.body.appendChild(this.element);

    // Chiudo la modale al click sullo sfondo
    this.element.addEventListener('click', (e: any) => {
      if (e.target.className === 'modal') {
        if (<string>typeof this.onModalClose != "null" &&
          typeof this.onModalClose != "undefined") {
          this.onModalClose.emit()
        }

        modal.close();
      }
    });

    // Aggiungo la modale al servizio affinché venga resa accessibile dai vari componenti
    this.modalService.add(this);
  }

  // Quando la direttiva è distrutta, tolgo la modale dal servizio
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (typeof this.element.remove === 'function') {
      this.element.remove();
    } else {
      this.element.parentNode.removeChild(this.element);
    }
  }

  // Apre la modale
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
  }

  // Chiude la modale
  close(): void {
    if (<string>typeof this.onModalClose != "null" &&
      typeof this.onModalClose != "undefined") {
      this.onModalClose.emit()
    }

    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
  }
}
