<!-- Wishlist e preferiti -->
<div class="margin-bottom10" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.xs="start start">
  <custom-button additionalContainerClasses="item-details-actions-btn primary" *ngIf="isWishlistBtnVisible()"
    iconLeft="./assets/icons/icon-heart-filled.svg" importance="{{ _addedToWishlist ? 'wishlist-on' : 'wishlist-off' }}"
    text="{{ _addedToWishlist ? ('itemDetail.ADDED_TO_BOOKMARKS'| translate | uppercase) : ('itemDetail.ADD_TO_BOOKMARKS'| translate | uppercase) }}"
    (onClick)="toggleWishlist.emit()">
  </custom-button>
  <custom-button additionalContainerClasses="item-details-actions-btn primary" *ngIf="isBookmarktBtnVisible()"
    iconLeft="./assets/icons/icon-heart-filled.svg" importance="{{ _isBookmarked ? 'wishlist-on' : 'wishlist-off' }}"
    text="{{ _isBookmarked ? ('itemDetail.ADDED_TO_BOOKMARKS'| translate | uppercase)  : ('itemDetail.ADD_TO_BOOKMARKS'| translate | uppercase) }}"
    (onClick)="toggleBookmark.emit()">
  </custom-button>
</div>

<!-- Unica azione di un corso cm2 pluriedizione (ossia pulsante con scroll alla sezione delle edizioni) -->
<div class="margin-bottom10" fxLayout="row"
  *ngIf="isSyllabusCourse && hasMoreEditions && (!isOnlineCourse || isWebinar) && _selectedEditionOfMainSelect">
  <!-- Ti sei iscritto - Iscrizione effettuata -->
  <custom-button class="margin-right10" [disabled]="true" additionalContainerClasses="item-details-actions-btn"
    importance="primary"
    *ngIf="_selectedEditionOfMainSelect.labelStatusToDisplay && _selectedEditionOfMainSelect.labelStatusToDisplay === 'subscribed'"
    text="{{'itemDetailsPage.edition.SUBSCRIBED' | translate | uppercase}}"
    ariaLabel="{{'itemDetailsPage.edition.SUBSCRIBED' | translate | uppercase}}">
  </custom-button>

  <!-- Presente -->
  <custom-button class="margin-right10" [disabled]="true" additionalContainerClasses="item-details-actions-btn"
    importance="primary"
    *ngIf="_selectedEditionOfMainSelect.labelStatusToDisplay && _selectedEditionOfMainSelect.labelStatusToDisplay === 'present'"
    text="{{'itemDetailsPage.edition.PRESENT' | translate | uppercase}}"
    ariaLabel="{{'itemDetailsPage.edition.PRESENT' | translate | uppercase}}">
  </custom-button>

  <!-- Azione -->
  <custom-button additionalContainerClasses="item-details-actions-btn" importance="primary"
    *ngIf="_selectedEditionOfMainSelect.callToAction && _selectedEditionOfMainSelect.callToAction !== 'DETAILS'"
    (onClick)="emitSelectedEditionCallToActionClicked(_selectedEditionOfMainSelect)" text="{{ ('itemDetailsPage.edition.callToActions.' + _selectedEditionOfMainSelect.callToAction) |
        translate | uppercase }}">
  </custom-button>
</div>

<!-- Azioni per i corsi CM2 che non hanno più di una edizione -->
<div *ngIf="showOneEditonBtnContainer()" fxLayout="row">
  <!-- Azione secondaria (solo per webinar mono edizione: fa vedere i dettagli. Questo perché sotto le giornate non ci starebbero le ore)
    <custom-button class="margin-right10"
        *ngIf="isWebinar || (isCatalogStage && editions && editions[0] && editions[0].stageItemType == 'WEBINAR')"
        additionalContainerClasses="item-details-actions-btn" importance="action"
        (onClick)="emitOpenWebinarUniqueEditionDetails()" text="{{ 'generic.DETAILS' | translate | uppercase }}"
        ariaLabel="{{ 'generic.DETAILS' | translate }}">
    </custom-button> -->
  <!-- Azione secondaria -->
  <custom-button class="margin-right10" additionalContainerClasses="item-details-actions-btn"
    *ngIf="uniqueEditionSecondBtnAction" additionalContainerClasses="item-details-actions-btn" importance="action"
    (onClick)="emitOnCourseMainBtnActionClicked(uniqueEditionSecondBtnAction)"
    text="{{ uniqueEditionSecondBtnAction && (('itemDetailsPage.buttons.' + uniqueEditionSecondBtnAction) | translate | uppercase )  }}"
    ariaLabel="{{ uniqueEditionSecondBtnAction && (('itemDetailsPage.buttons.' + uniqueEditionSecondBtnAction) | translate )  }}">
  </custom-button>
  <!-- Azione primaria -->
  <custom-button additionalContainerClasses="item-details-actions-btn"
    *ngIf="_uniqueEditionMainBtnAction && !showLpContinue" importance="primary"
    (onClick)="emitOnCourseMainBtnActionClicked(_uniqueEditionMainBtnAction)"
    text="{{ _uniqueEditionMainBtnAction && (('itemDetailsPage.buttons.' + _uniqueEditionMainBtnAction) | translate | uppercase) }}"
    ariaLabel="{{ _uniqueEditionMainBtnAction && (('itemDetailsPage.buttons.' + _uniqueEditionMainBtnAction) | translate | uppercase) }}">
  </custom-button>
  <!-- continua LP da corso fake -->
  <ng-container *ngIf="showLpContinue && firstIncompleteId">
    <custom-button additionalContainerClasses="item-details-actions-btn" *ngIf="isPlaylistPlan" importance="primary"
      (onClick)="scrollToNextItem()"
      [text]="completedItems ? ('itemDetailsPage.CONTINUE_PL' | translate | uppercase) : ('itemDetailsPage.START_PL' | translate | uppercase)"
      ariaLabel="{{ (completedItems ? ('itemDetailsPage.CONTINUE_PL' | translate | uppercase) : ('itemDetailsPage.START_PL' | translate | uppercase)) }}">
    </custom-button>
  </ng-container>
</div>

<!-- Azioni per gli oggetti Library -->
<ng-container *ngIf="!isSyllabusCourse">
  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px" fxLayoutAlign.xs="start start">

    <!-- Azioni secondarie
        <custom-select *ngIf="secondaryActionOptions && secondaryActionOptions.length"
            placeholderLabel="{{ 'itemDetailsPage.buttons.ACTIONS' | translate | uppercase }}"
            labelForId="select-options-select-id" attrAriaLabel="{{ 'itemDetailsPage.buttons.ACTIONS' | translate }}"
            (onOptionClicked)="emitOnSecondaryActionClicked($event)" [items]="secondaryActionOptions" bindValue="code"
            bindLabel="name" customContainerClass="itemDetailsSecondaryActions">
        </custom-select> -->

    <ng-container *ngIf="showLpContinue && firstIncompleteId">
      <custom-button additionalContainerClasses="item-details-actions-btn" *ngIf="!isPlaylistPlan" importance="primary"
        (onClick)="scrollToNextItem()"
        [text]="completionPercentage || completedItems ? ('itemDetailsPage.CONTINUE_LP' | translate | uppercase) : ('itemDetailsPage.START_LP' | translate | uppercase)"
        ariaLabel="{{ (completionPercentage || completedItems ? ('itemDetailsPage.CONTINUE_LP' | translate | uppercase) : ('itemDetailsPage.START_LP' | translate | uppercase)) }}">
      </custom-button>
      <!--
                Rimosso pulsante di "start/continua playlist"
                <custom-button additionalContainerClasses="item-details-actions-btn" *ngIf="isPlaylistPlan"
                importance="primary" (onClick)="scrollToNextItem()"
                [text]="completionPercentage || completedItems ? ('itemDetailsPage.CONTINUE_PL' | translate | uppercase) : ('itemDetailsPage.START_PL' | translate | uppercase)"
                ariaLabel="{{ (completionPercentage || completedItems ? ('itemDetailsPage.CONTINUE_PL' | translate | uppercase) : ('itemDetailsPage.START_PL' | translate | uppercase)) }}">
            </custom-button>-->
    </ng-container>

    <!-- Azione secondaria -->
    <custom-button additionalContainerClasses="item-details-actions-btn" *ngIf="_libraryItemSecondBtnAction"
      importance="action" (onClick)="emitOnLibraryItemMainBtnActionClicked(_libraryItemSecondBtnAction)"
      text="{{ _libraryItemSecondBtnAction && (('itemDetailsPage.' + _libraryItemSecondBtnAction) | translate | uppercase )  }}"
      ariaLabel="{{ _libraryItemSecondBtnAction && (('itemDetailsPage.' + _libraryItemSecondBtnAction) | translate )  }}">
    </custom-button>
    <!-- Azione primaria -->
    <custom-button additionalContainerClasses="item-details-actions-btn" *ngIf="_libraryItemMainBtnAction"
      importance="primary" (onClick)="emitOnLibraryItemMainBtnActionClicked(_libraryItemMainBtnAction)"
      text="{{ _libraryItemMainBtnAction && (('itemDetailsPage.' + _libraryItemMainBtnAction) | translate | uppercase) }}"
      ariaLabel="{{ _libraryItemMainBtnAction && (('itemDetailsPage.' + _libraryItemMainBtnAction) | translate | uppercase) }}">
    </custom-button>
  </div>
</ng-container>

<!-- Altre azioni
<div class="margin-top10" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign.xs="start start">
    <custom-button additionalContainerClasses="item-details-actions-btn" class="margin-right10"
        iconLeft="./assets/icons/icon-thumbs-up.svg" importance="{{ iLikeThis ? 'primary' : 'action' }}"
        text="{{ 'itemDetailsPage.LIKE' | translate | uppercase }}" (onClick)="emitOnLike()">
    </custom-button>
    <custom-button additionalContainerClasses="item-details-actions-btn" iconLeft="./assets/icons/icon-thumbs-down.svg"
        importance="{{ iDislikeThis ? 'primary' : 'action' }}"
        text="{{ 'itemDetailsPage.NOT_LIKE' | translate | uppercase }}" (onClick)="emitOnDislike()">
    </custom-button>
</div> -->