import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { Tag, TagInfo } from "atfcore-commonclasses/bin/classes/tag";
import { TalentFarmOutput, TalentFarmSenecaResponse } from "atfcore-commonclasses/bin/classes/talent-farm";

import { UrlService } from "src/app/shared/services/url.service";
import { handleFromRecord, handleNumRecords, handleSearchedText, handleSorting } from "src/app/shared/utils";

@Injectable()
export class ProfilingService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Function that returns the list of interests of the user.
   * @return {TalentFarmOutput.GetMyProfilingData} The interest list is a list of Argument Preferences Tag
   */
  getMyProfilingData(): Observable<SenecaResponse<TalentFarmOutput.GetMyProfilingData>> {
    return this.http.get<SenecaResponse<TalentFarmOutput.GetMyProfilingData>>(this._mediatorUrl + 'get-my-profiling-data');
  }

  // Count delle categorie selezionabili dall'utente per la profilazione
  countCategories(searchedText?: string) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = handleSearchedText(httpParams, searchedText);

    return this.http.get<SenecaResponse<Tag[]>>(this._cm2MediatorUrl + 'glp-count-categories', { params: httpParams });
  }

  // Count delle categorie selezionabili dall'utente per la profilazione
  listCategories(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string,
    sorting?: string): Observable<SenecaResponse<TagInfo[]>> {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = allData ? httpParams.append('allData', 'true') : httpParams;
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);

    return this.http.get<SenecaResponse<TagInfo[]>>(this._cm2MediatorUrl + 'glp-list-categories', { params: httpParams });
  }

  /**
   * Function that receive all available interests in platform
   * @param {string} searchedText - Optional: The text to filter company's name
   * @param {boolean} allData - Optional: If passed with true value, all data will be returned (fromRecord and numRecords are not required)
   * @param {number} fromRecord - Required: The number of the first record to load (exclusive - 0 to start from the first)
   * @param {number} numRecords - Required: The number of records to load
   * @param {string} sorting - Required: TFlag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc)- Default T
   * @return {Tag[]} The list of interests
   */
  listOnlineCoursesContentAreas(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string,
    sorting?: string): Observable<SenecaResponse<Tag[]>> {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = allData ? httpParams.append('allData', 'true') : httpParams;
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);

    return this.http.get<SenecaResponse<Tag[]>>(this._mediatorUrl + 'list-online-courses-content-areas', { params: httpParams });
  }

  /**
   * Function that receive all available interests in platform
   * @param {string} searchedText - Optional: The text to filter company's name
   * @param {boolean} allData - Optional: If passed with true value, all data will be returned (fromRecord and numRecords are not required)
   * @param {number} fromRecord - Required: The number of the first record to load (exclusive - 0 to start from the first)
   * @param {number} numRecords - Required: The number of records to load
   * @param {string} sorting - Required: TFlag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc)- Default T
   * @return {Tag[]} The list of interests
   */
  listContentAreas(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string,
    sorting?: string): Observable<SenecaResponse<Tag[]>> {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = allData ? httpParams.append('allData', 'true') : httpParams;
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);

    return this.http.get<SenecaResponse<Tag[]>>(this._mediatorUrl + 'list-content-areas', { params: httpParams });
  }

  /**
   * Function that receive all available interests in platform
   * @param {string} searchedText - Optional: The text to filter company's name
   * @param {boolean} allData - Optional: If passed with true value, all data will be returned (fromRecord and numRecords are not required)
   * @param {number} fromRecord - Required: The number of the first record to load (exclusive - 0 to start from the first)
   * @param {number} numRecords - Required: The number of records to load
   * @param {string} sorting - Required: TFlag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc)- Default T
   * @return {Tag[]} The list of interests
   */
  listProfessionalFields(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string,
    sorting?: string): Observable<SenecaResponse<Tag[]>> {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = allData ? httpParams.append('allData', 'true') : httpParams;
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);

    return this.http.get<SenecaResponse<Tag[]>>(this._mediatorUrl + 'list-professional-fields', { params: httpParams });
  }

  /**
   * Function that sets the list of interests of the user. The interest list should be a list of Argument Preferences Tag Ids.
   * All peviuos ids will be removed and replaced by these ones.
   * @param {string[]} contentAreaIds - Optional: TagIds of Argument Preferencess to be set as interests. If not passed, all old preferences will be removed
   * @param {string[]} professionalFieldIds - Optional: TagIds of professional fields to be set as interests. If not passed, all old preferences will be removed
   * @param {string} seniority - Optional: User's selected seniority
   * @param {boolean} newsletterSubscribed - Optional: if true the user subscribes newsletter, if false or not set the user will be usubscribed
   * @return {null}
   */
  setMyProfilingData(contentAreaIds?: string[], professionalFieldIds?: string[], seniority?,
    newsletterSubscribed?: boolean): Observable<TalentFarmSenecaResponse<null>> {
    return this.http.post<TalentFarmSenecaResponse<null>>(this._mediatorUrl + 'set-my-profiling-data', {
      contentAreaIds,
      professionalFieldIds,
      seniority,
      newsletterSubscribed
    });
  }

  // Salva le categorie selezionate dall'utente
  setMyCategoriesPreferences(categoriesIds?): Observable<TalentFarmSenecaResponse<null>> {
    return this.http.post<TalentFarmSenecaResponse<null>>(this._cm2MediatorUrl + 'glp-set-my-categories-preferences', {
      categoriesIds
    });
  }

  deleteProfile() {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'delete-my-profile', {
    });
  }

}
