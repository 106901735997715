import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import * as AuthActions from '../ngrx/auth.actions';

import { TalentFarmSeniorityLevel } from 'atfcore-commonclasses/bin/classes/talent-farm';
import { ProfilingTag, CardType, GeneraliCarouselComponent } from 'generali';

import * as fromApp from '../../ngrx/app.reducers';

import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ScrollTo } from 'src/app/shared/services/scroll-to.service';
import { SliderComponent } from 'src/app/shared/components/slider/slider.component';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SortingType } from 'src/app/shared/interfaces/common.interface';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { ProfilingService } from 'src/app/core/services/profiling.service';
import { getCategoriesTag, getInterestTag, getProfessionalTag } from 'src/app/shared/utils';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserAcknowledges, ItemTypes } from 'atfcore-commonclasses';
import { AuthService } from '../services/auth.service';
import { ModalService } from 'src/app/core/modal/modal-services.component';
import { PrizeService } from 'src/app/core/services/prize.service';

export interface ProfilationValue {
  seniority: number,
  slideText: string,
  profilationChips: ProfilingTag[],
  professionalChips: ProfilingTag[]
}

@Component({
  selector: 'profilation',
  templateUrl: './profilation.component.html',
  styleUrls: ['./profilation.component.scss']
})
export class ProfilationComponent extends BaseSubscriberComponent implements OnInit {
  @Input() isProfile?: boolean;
  @Input() showOnlyInterests: boolean = false;
  @Input() showOnlyProfessional: boolean = false;
  @Input() showOnlySeniority: boolean = false;
  @Input() selectedProfessionalAmbits?: any[] = [];
  @Input() selectedProfilationChips?: any[] = [];
  @Input() selectedSlideValue?: number = 0;

  categories: any[] = [];

  scrollToRight: Subject<void> = new Subject<void>();
  scrollToLeft: Subject<void> = new Subject<void>();
  showLeftArrow: boolean;
  showRightArrow: boolean;
  @ViewChild("badgesInfoCarouselPremiums") badgesInfoCarouselPremiums: GeneraliCarouselComponent;
  slideValue: number = 1;
  seniorityLevel: TalentFarmSeniorityLevel;
  slideText: string = "profilation.profile.EMPTY";
  slideFigure: string;
  onSlideEvent = new EventEmitter<number>();
  userAcknowledges: UserAcknowledges | null;

  scrollToSkill: Subject<void> = new Subject<void>();

  carouselIndex: number = 0;
  carouselIndexPremium: number = 0;

  @Output() profilationCompleted = new EventEmitter<ProfilationValue>();

  /**
   * Fired on modal close
   */
  @Input('onOpenModal') onOpenModal$: Observable<void> = new Observable();

  _isHomepage?: boolean;
  @Input() set isHomepage(value: boolean | string) {
    this._isHomepage = coerceBooleanProperty(value);
  }

  consentFormObbligatory: FormGroup;

  get privacyObbligatory() {
    return this.consentFormObbligatory && this.consentFormObbligatory.get('privacyObbligatory') as FormControl;
  }

  /**
   * Fired on modal close
   */
  @Output() onCloseModal = new EventEmitter<void>();

  @ViewChild('profilationCarousel') profilationCarousel: GeneraliCarouselComponent;
  @ViewChild('profilationSlider') profilationSlider: SliderComponent;

  professionalChips: ProfilingTag[] = [];
  profilationChips: ProfilingTag[] = [];
  isWithObbligatoryAck: boolean;
  coinsNumber: number;

  isEuropAssistanceUser: boolean = false;

  isLoading: boolean;
  userAwards: any[] = [];
  userAwardsCount: number;
  showAllPremiums: boolean;

  get disableInterestsButton(): boolean {
    if (!this.isProfile || this.showOnlyInterests) {
      return this.profilationChips.filter(x => x.selected).length === 0;
    } else if (!this.isProfile || this.showOnlyProfessional) {
      return this.professionalChips.filter(x => x.selected).length === 0;
    } else return !this.slideValue || this.slideValue === 1;
  }

  get disableProfessionalButton(): boolean {
    return this.professionalChips.filter(x => x.selected).length === 0;
  }

  get disableConfirmCategoriesButton(): boolean {
    return this.categories.filter(x => x.selected).length === 0;
  }

  get disableSeniorityButton(): boolean {
    return this.slideValue === 1 && !this.selectedSlideValue;
  }

  isInWelcomePage: boolean;
  selectedTagToShowInfo: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private redirectService: RedirectService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private scrollTo: ScrollTo,
    private profilingService: ProfilingService,
    private toastr: ToastrService,
    private authService: AuthService,
    private modalService: ModalService,
    private prizeService: PrizeService,
    private router: Router) {
    super();
    if (!this.selectedSlideValue) {
      this.setProfilationSlider();
    }

    this.consentFormObbligatory = new FormGroup({
      'privacyObbligatory': new FormControl(false, Validators.requiredTrue)
    });
    this.store.select(fromApp.getUserAcknowledges)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe((userAcknowledges) => {
        this.userAcknowledges = userAcknowledges as UserAcknowledges | null;
        if (this.userAcknowledges && this.userAcknowledges.data && this.userAcknowledges.data['TALENT_FARM_USAGE_ANALYTICS_PRIVACY_ACK']) {
          this.isWithObbligatoryAck = true;
        }
      });
  }

  ngOnInit() {

    this.scrollTo.header();

    if (this.selectedSlideValue) {
      this.senioritySelected();
    }

    this.store.select(fromApp.getCategories)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe((categories) => {
        if (categories && categories.length) {
          this.categories = [];

          this.getAlreadySelectedCategories(categories);
        }
      });

    this.onOpenModal$
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(() => {

        if (!this.showOnlyInterests && !this.showOnlyProfessional && !this.showOnlySeniority) {
          // this.getAwardsCount();
          this.isInWelcomePage = true;
        }
        this.profilationCarousel.toIndexNoSmooth(0);

        // Waiting that the modal is loaded
        let timeout = setTimeout(() => {
          if (this.profilationSlider) {
            this.profilationSlider.calculateElementWidth();
            clearInterval(timeout);
          }

          if (this.showOnlyInterests) {
            // Sono al primo step con la scelta della seniority, quindi avanzo di slider mandando un evento ad analytics
            if (!this.isProfile) {
              this.analyticsService.sendRegistrationSeniorityChoosedEvent(this.translate.instant(this.slideText));
            } else {
              this.analyticsService.sendProfileSeniorityChoosedEvent(this.translate.instant(this.slideText));
            }

            this.scrollToSkill.next();
            this.carouselIndex = 1;
          }
        }, 200);
      });

    this.store.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe((loggedUser) => {

        // Per evitare problemi nel caso in cui il token non sia ancora impostato
        // Ex: Al fist-intro
        if (loggedUser) {
          this.getAvailableJobsTypes();
          // this.listContentAreas();
          this.store.dispatch(new AuthActions.RetrieveUserAcknowledges());
          this.isEuropAssistanceUser = !!(loggedUser.user.userTenant.tenant === "EUASS_GRP");
        }
      });
  }

  getAvailableJobsTypes() {
    this.profilingService.listProfessionalFields(true)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(data => {

        this.professionalChips = [];

        if (data.error) {
          this.toastr.error(this.translate.instant("errors." + data.error));
        } else if (data.response && data.response.length) {

          data.response.forEach(tag => {
            this.professionalChips.push(getProfessionalTag(tag));
          });
          if (this.selectedProfessionalAmbits && this.selectedProfessionalAmbits.length) {
            for (let i = 0; i < this.professionalChips.length; i++) {
              let professionalChip = this.professionalChips[i];
              for (let q = 0; q < this.selectedProfessionalAmbits.length; q++) {
                let professionalChipSelected = this.selectedProfessionalAmbits[q];
                if (professionalChip.id == professionalChipSelected.id) {
                  professionalChip.selected = true;
                }
              }
            }
          }
        }
      }, (err) => {
        if (err) {
          this.toastr.error(err.message || err);
        }
      });
  }

  // Gestisce il click sul tooltip
  onInfoTooltipClicked(selectedTooltip) {
  }

  // Apre una modale con la descrizione della categoria
  onCategoryInfoModalOpen(tag) {
    this.selectedTagToShowInfo = tag;
    this.modalService.open("selected-tag-info-modal");
  }

  closeCategoryInfoModalOpen() {
    this.selectedTagToShowInfo = null;
    this.modalService.close("selected-tag-info-modal");
  }

  listContentAreas() {
    this.profilingService.listContentAreas(true, null, null, null, SortingType.TITLE_ASC)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(data => {

        this.profilationChips = [];

        if (data.error) {
          this.toastr.error(this.translate.instant("errors." + data.error));
        } else if (data.response && data.response.length) {

          data.response.forEach(tag => {
            this.profilationChips.push(getInterestTag(tag));
          });
          if (this.selectedProfilationChips && this.selectedProfilationChips.length) {
            for (let i = 0; i < this.profilationChips.length; i++) {
              let profilationChip = this.profilationChips[i];
              for (let q = 0; q < this.selectedProfilationChips.length; q++) {
                let profilationChipSelected = this.selectedProfilationChips[q];
                if (profilationChip.id == profilationChipSelected.id) {
                  profilationChip.selected = true;
                }
              }
            }
          }
        }
      }, (err) => {
        if (err) {
          this.toastr.error(err.message || err);
        }
      });
  }

  // Ritorna le categorie selezionate
  getAlreadySelectedCategories(allCategories) {
    this.authService.getMyCategories()
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        selectedCategoriesRes => {
          if (selectedCategoriesRes.error) {
            this.toastr.error(this.translate.instant("errors." + selectedCategoriesRes.error));
          } else {
            allCategories.forEach(tag => {
              this.categories.push(getCategoriesTag(tag));
            });

            const selectedCategories = selectedCategoriesRes.response;

            if (selectedCategories && selectedCategories.length) {
              for (let i = 0; i < this.categories.length; i++) {
                let currentCategory = this.categories[i];
                for (let q = 0; q < selectedCategories.length; q++) {
                  let currentSelectedCategory = selectedCategories[q];
                  if (currentCategory.tagId == currentSelectedCategory.tagId) {
                    currentCategory.selected = true;
                  }
                }
              }
            }
          }
        }, err => {
          if (err && err.message) {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        });
  }

  getContentLengthCarousel() {
    if (this.isWithObbligatoryAck) {
      if (this.showOnlySeniority || this.showOnlyInterests || this.showOnlyProfessional) {
        return 2;
      } else {
        return 5;
      }
    } else {
      if (this.showOnlySeniority || this.showOnlyInterests || this.showOnlyProfessional) {
        return 3;
      } else {
        return 6;
      }
    }
  }

  getWidthCarouselPercentage() {
    if (this.isWithObbligatoryAck) {
      return ((this.carouselIndex + 1) * (this.showOnlySeniority || this.showOnlyInterests || this.showOnlyProfessional ? 50 : 20))
    } else {
      return ((this.carouselIndex + 1) * (this.showOnlySeniority || this.showOnlyInterests || this.showOnlyProfessional ? 33.33 : 16.66))
    }
  }

  /**
   * Scroll in cima
   */
  onScrollEvent(index: number) {
    if (this.isProfile) {
      return;
    }
    this.carouselIndex = index;
    let timeout = setTimeout(() => {
      this.scrollTo.element("profilation-page-anchor");
      clearInterval(timeout);
    }, 0);
  }

  onScrollEventPremium(index: number) {
    this.carouselIndexPremium = index;
  }

  /**
   * Close of the profilation
   */
  closeProfilationEvent() {
    this.onCloseModal.next();
  }

  /**
   * Go back to home
   */
  goToHome() {
    this.redirectService.goToHome();
  }

  /**
   * Called when the profilation is completed
   */
  completeProfilation() {
      if (!this._isHomepage && !this.isProfile) {
        this.redirectService.goToHome();
      }
      this.profilationCompleted.emit();
  }

  viewAllTap() {
    this.showAllPremiums = true;
  }

  // Conferma le categorie
  confirmCategories() {
    // Ambito di interesse. Per ogni ambito invio un tracciamento ad analytics
    const selectedCategories: string[] = this.categories.filter(x => x.selected).map(x => x.tagId);

    if (selectedCategories && selectedCategories.length) {
      for (let m = 0, selectedCategoriesLegnth = selectedCategories.length; m < selectedCategoriesLegnth; m++) {
        if (!this.isProfile) {
          this.analyticsService.sendRegistrationAmbitoDiInteresseChoosedEvent(selectedCategories[m]);
        } else {
          this.analyticsService.sendProfileAmbitoDiInteresseChoosedEvent(selectedCategories[m]);
        }
      }
    }

    this.profilingService.setMyCategoriesPreferences(selectedCategories)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(data => {
        if (data.error) {
          this.toastr.error(this.translate.instant("errors." + data.error));
        } else {
          //  L'utente ha concluso la profilazione, quindi mando un evento ad analytics
          if (!this.isProfile) {
            this.analyticsService.sendProfilingCompletedEvent();
          }
          if (!this._isHomepage && !this.isProfile) {
            this.redirectService.goToHome();
          }
          this.completeProfilation();
          this.store.dispatch(new AuthActions.RetrieveUserAcknowledges());
        }
      }, (err) => {
        if (err) {
          this.toastr.error(err.message || err);
        }
      });
  }

  // Conferma uno step di personalizzazione
  confirmPersonalization() {
    if (this.isProfile) {
      // Se sono nel profilo, confermo l'azione salvando i dati
      this.completeProfilation();
    } else {
      switch (this.carouselIndex) {
        case 0:
          // Consenso privacy
          if (this.isWithObbligatoryAck) {
            this.scrollToSkill.next();
            break;
          } else {
            // Rimossa chiamata a setMyPrivacyData
            this.scrollToSkill.next();
            break;
          }

        case 1:
          // Esperienza su Misura
          this.scrollToSkill.next();
          break;
        case 2:
          // Premi
          this.scrollToSkill.next();
          break;
        case 3:
          // Ambito di interesse. Per ogni ambito invio un tracciamento ad analytics
          const selectedProfessionalChips: string[] = this.professionalChips.filter(x => x.selected).map(x => x.title);

          if (selectedProfessionalChips && selectedProfessionalChips.length) {
            for (let m = 0, selectedProfessionalChipsLegnth = selectedProfessionalChips.length; m < selectedProfessionalChipsLegnth; m++) {
              if (!this.isProfile) {
                this.analyticsService.sendRegistrationAmbitoDiInteresseChoosedEvent(selectedProfessionalChips[m]);
              } else {
                this.analyticsService.sendProfileAmbitoDiInteresseChoosedEvent(selectedProfessionalChips[m]);
              }
            }
          }

          this.scrollToSkill.next();
          break;
        case 4:
          if (this.isWithObbligatoryAck) {
            // Sono al primo step con la scelta della seniority, quindi avanzo di slider mandando un evento ad analytics
            if (!this.isProfile) {
              this.analyticsService.sendRegistrationSeniorityChoosedEvent(this.translate.instant(this.slideText));
            } else {
              this.analyticsService.sendProfileSeniorityChoosedEvent(this.translate.instant(this.slideText));
            }

            const interestIds: string[] = this.profilationChips.filter(x => x.selected).map(x => x.id);
            const professionalIds: string[] = this.professionalChips.filter(x => x.selected).map(x => x.id);

            let slideValue = (this.slideValue != 1) ? this.slideValue : this.selectedSlideValue;

            this.profilingService.setMyProfilingData(interestIds, professionalIds, slideValue)
              .pipe(takeUntil(this.unsubscribe$.asObservable()))
              .subscribe(data => {
                if (data.error) {
                  this.toastr.error(this.translate.instant("errors." + data.error));
                } else {
                  if (data.earnedCoins) {
                    this.coinsNumber = data.earnedCoins;
                    this.modalService.open("modal-coin");
                  }
                  this.completeProfilation();
                  this.store.dispatch(new AuthActions.RetrieveUserAcknowledges());
                }
              }, (err) => {
                if (err) {
                  this.toastr.error(err.message || err);
                }
              });
            break;
          } else {
            // Interessi. Per ogni interesse invio un tracciamento ad analytics
            const selectedInterests: string[] = this.profilationChips.filter(x => x.selected).map(x => x.title);

            if (selectedInterests && selectedInterests.length) {
              for (let z = 0, interestsLength = selectedInterests.length; z < interestsLength; z++) {
                if (!this.isProfile) {
                  this.analyticsService.sendRegistrationInteresseChoosedEvent(selectedInterests[z]);
                } else {
                  this.analyticsService.sendProfileInteresseChoosedEvent(selectedInterests[z]);
                }
              }
            }

            this.scrollToSkill.next();
            break;
          }
        case 5:
          // Sono al primo step con la scelta della seniority, quindi avanzo di slider mandando un evento ad analytics

          if (!this.isProfile) {
            this.analyticsService.sendRegistrationSeniorityChoosedEvent(this.translate.instant(this.slideText));
          } else {
            this.analyticsService.sendProfileSeniorityChoosedEvent(this.translate.instant(this.slideText));
          }

          const interestIds: string[] = this.profilationChips.filter(x => x.selected).map(x => x.id);
          const professionalIds: string[] = this.professionalChips.filter(x => x.selected).map(x => x.id);

          let slideValue = (this.slideValue != 1) ? this.slideValue : this.selectedSlideValue;

          this.profilingService.setMyProfilingData(interestIds, professionalIds, slideValue)
            .pipe(takeUntil(this.unsubscribe$.asObservable()))
            .subscribe(data => {
              if (data.error) {
                this.toastr.error(this.translate.instant("errors." + data.error));
              } else {
                if (data.earnedCoins) {
                  this.coinsNumber = data.earnedCoins;
                  this.modalService.open("modal-coin");
                }
                //  L'utente ha concluso la profilazione, quindi mando un evento ad analytics
                if (!this.isProfile) {
                  this.analyticsService.sendProfilingCompletedEvent();
                }
                this.completeProfilation();
                this.store.dispatch(new AuthActions.RetrieveUserAcknowledges());
              }
            }, (err) => {
              if (err) {
                this.toastr.error(err.message || err);
              }
            });
          break;
      }
    }
  }

  senioritySelected() {
    switch (this.selectedSlideValue) {
      case 1:
        this.seniorityLevel = undefined;
        this.slideText = "profilation.profile.EMPTY";
        this.slideFigure = undefined;
        break;
      case 2:
        this.seniorityLevel = TalentFarmSeniorityLevel.STUDYING;
        this.slideText = "profilation.profile.STUDYING";
        this.slideFigure = "../../assets/img/profilation/profilation-student.svg";
        break;
      case 3:
        this.seniorityLevel = TalentFarmSeniorityLevel.LOOKING_FOR_A_JOB;
        this.slideText = "profilation.profile.LOOKING_FOR_A_JOB";
        this.slideFigure = "../../assets/img/profilation/profilation-degree.svg";
        break;
      case 4:
        this.seniorityLevel = TalentFarmSeniorityLevel.LESS_THAN_2_YEARS;
        this.slideText = "profilation.profile.LESS_THAN_2_YEARS";
        this.slideFigure = "../../assets/img/profilation/profilation-junior.svg";
        break;
      case 5:
        this.slideText = "profilation.profile.FROM_2_TO_4_YEARS";
        this.seniorityLevel = TalentFarmSeniorityLevel.FROM_2_TO_4_YEARS;
        this.slideFigure = "../../assets/img/profilation/profilation-professional.svg";
        break;
      case 6:
        this.seniorityLevel = TalentFarmSeniorityLevel.MORE_THAN_4_YERS;
        this.slideText = "profilation.profile.MORE_THAN_4_YERS";
        this.slideFigure = "../../assets/img/profilation/profilation-manager.svg";
        break;
    }
  }

  /**
   * Set the text and image of profilation based on slider
   */
  setProfilationSlider() {
    this.onSlideEvent.subscribe(slideValue => {
      this.slideValue = slideValue;
      switch (slideValue) {
        case 1:
          this.seniorityLevel = undefined;
          this.slideText = "profilation.profile.EMPTY";
          this.slideFigure = undefined;
          break;
        case 2:
          this.seniorityLevel = TalentFarmSeniorityLevel.STUDYING;
          this.slideText = "profilation.profile.STUDYING";
          this.slideFigure = "../../assets/img/profilation/profilation-student.svg";
          break;
        case 3:
          this.seniorityLevel = TalentFarmSeniorityLevel.LOOKING_FOR_A_JOB;
          this.slideText = "profilation.profile.LOOKING_FOR_A_JOB";
          this.slideFigure = "../../assets/img/profilation/profilation-degree.svg";
          break;
        case 4:
          this.seniorityLevel = TalentFarmSeniorityLevel.LESS_THAN_2_YEARS;
          this.slideText = "profilation.profile.LESS_THAN_2_YEARS";
          this.slideFigure = "../../assets/img/profilation/profilation-junior.svg";
          break;
        case 5:
          this.slideText = "profilation.profile.FROM_2_TO_4_YEARS";
          this.seniorityLevel = TalentFarmSeniorityLevel.FROM_2_TO_4_YEARS;
          this.slideFigure = "../../assets/img/profilation/profilation-professional.svg";
          break;
        case 6:
          this.seniorityLevel = TalentFarmSeniorityLevel.MORE_THAN_4_YERS;
          this.slideText = "profilation.profile.MORE_THAN_4_YERS";
          this.slideFigure = "../../assets/img/profilation/profilation-manager.svg";
          break;
      }
    });
  }

  closeCoinModal() {
    this.modalService.close("modal-coin");
    this.coinsNumber = 0;
  }

  // Recupera il count dei premi
  getAwardsCount() {

    this.isLoading = true;
    if (this.userAwards && this.userAwards.length) {
      this.userAwards.length = 0;
    }

    this.prizeService.countPrizes()
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (coursesCount) => {
          if (coursesCount.error) {
            this.toastr.error(this.translate.instant("errors." + coursesCount.error));
            this.isLoading = false;
          } else {
            this.userAwardsCount = coursesCount.response;

            if (this.userAwardsCount) {
              this.getAwards();
            } else {
              this.isLoading = false;
            }
          }
        }, (err) => {
          this.isLoading = false;
          if (err && err.message) {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        });
  }

  // Recupera i premi
  getAwards() {
    this.isLoading = true;
    if (this.userAwards && this.userAwards.length) {
      this.userAwards.length = 0;
    }

    this.prizeService.getPrizes(true)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(
        (data) => {
          if (data.error) {
            this.toastr.error(this.translate.instant("errors." + data.error));
          } else if (data.response) {
            this.userAwards = data.response;
          }
          this.isLoading = false;
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
          this.isLoading = false;
        });
  }

  onCardClick(type: CardType, card: any) {
    this.redirectService.goToCardSection(type, card);
  }

  onCardClickPremium(card: any) {
    let type;
    if (card.itemType == ItemTypes.TALENT_FARM_EVENT_CLASS_STAGE || card.itemType == ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE) {
      type = 'general-event';
    } else {
      type = 'course';
    }

    this.redirectService.goToCardSection(type, card);
  }

  onTagClickPremium(card: any) {

    let type;
    if (card.itemType == ItemTypes.TALENT_FARM_EVENT_CLASS_STAGE || card.itemType == ItemTypes.TALENT_FARM_EVENT_ONLINE_STAGE) {
      type = 'event';
    } else {
      type = 'course';
    }

    switch (type) {
      case 'course':
        this.router.navigate(['/home/courses/' + card.tagId]);
        break;
      case 'event':
        this.router.navigate(['/home/events/' + card.tagId]);
        break;
    }
  }

  onTagClick(card: any, type: CardType) {
    switch (type) {
      case 'blog':
        this.router.navigate(['/home/insight/' + card.title]);
        break;
      case 'job':
        this.router.navigate(['/home/jobs/' + card.tagId]);
        break;
      case 'course':
        this.router.navigate(['/home/courses/' + card.tagId]);
        break;
      case 'general-event':
        this.router.navigate(['/home/events/' + card.tagId]);
        break;
      case 'challenge':
        break;
    }
  }

  checkVisibilityGalleryArrows(): void {
    if (this.badgesInfoCarouselPremiums) {
      this.showLeftArrow = this.carouselIndexPremium > 0;
      this.showRightArrow = this.carouselIndexPremium < (2 - 1);
    } else {
      this.showLeftArrow = false;
      this.showRightArrow = false;
    }
  }

  scrollToRightBadgeInfo() {
    this.scrollToRight.next();
  }

  scrollToLeftBadgeInfo() {
    this.scrollToLeft.next();
  }
}
