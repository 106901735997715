<div fxLayoutAlign="center center" fxLayout="row" class="slider-container">
<div fxShow fxHide.xs *ngIf="_value != 1" class="carousel-arrow" (click)="scrollLeft()" fxLayout="column"
        fxLayoutAlign="center center">
        <svg-icon class="left-icon-slider" src="assets/icons/icon-arrow-left-go.svg">
        </svg-icon>
      </div>
<div class="slider">
  <div class="slider-full" [ngStyle]="{'width.px': _stepLength}">
  </div>
  <input #ref id="slider-input" class="slider-input" type="range" min="1" [max]="_steps" steps="6" [value]="_value"
    (input)="changeValue($event)" (change)="changeValue($event)" />
</div>
<div fxShow fxHide.xs *ngIf="_value != 6" class="carousel-arrow" (click)="scrollRight()" fxLayout="column"
        fxLayoutAlign="center center">
        <svg-icon class="right-icon-slider" src="assets/icons/icon-arrow-right-go.svg">
        </svg-icon>
      </div>
