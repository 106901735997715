/*
 * Azioni per il Core Module
*/

import { Action } from '@ngrx/store';
import { Tag, TagInfo } from 'atfcore-commonclasses/bin/classes/tag';
import { GlobalApplicationData } from '../../shared/models/global-application-data.model';

// Setta il prefisso applicativo (context) e le altre varibili del server
export const SET_CORE_APPLICATION_DATA = 'SET_CORE_APPLICATION_DATA';

// Pulisce il prefisso applicativo (context) e le altre varibili del server
export const REMOVE_CORE_APPLICATION_DATA = 'REMOVE_CORE_APPLICATION_DATA';

// Recupera le lingue disponibili a sistema
export const GET_AVAILABLE_LANGS = 'GET_AVAILABLE_LANGS';

// Setta come completato il caricamento delle lingue disponibili
export const GET_AVAILABLE_LANGS_FINISHED = 'GET_AVAILABLE_LANGS_FINISHED';

// Setta la lingua di default
export const SET_DEFAULT_LANG = 'SET_DEFAULT_LANG';

// Salva l'url richiesto prima di eseguire il redirect alla pagina di login
export const SAVE_REDIRECT_URL = 'SAVE_REDIRECT_URL';

// Mostra il loader dell'applicazione
export const SHOW_APPLICATION_LOADER = 'SHOW_APPLICATION_LOADER';

// Nasconde il loader dell'applicazione
export const HIDE_APPLICATION_LOADER = 'HIDE_APPLICATION_LOADER';

// Cancella l'url di redirect dopo il login
export const REMOVE_REDIRECT_URL = 'REMOVE_REDIRECT_URL';

// Inizia il polling per il renew del token
export const START_RENEW_TOKEN_POLLING = 'START_RENEW_TOKEN_POLLING';

// Setta la lingua dell'applicazione
export const SET_APPLICATION_LANG = 'SET_APPLICATION_LANG';

// Cancella la lingua dell'applicazione
export const REMOVE_APPLICATION_LANG = 'REMOVE_APPLICATION_LANG';

// Recupera la grandezza dello schermo
export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE";

// Recupera le categorie
export const GET_CATEGORIES = "GET_CATEGORIES";

// Imposta le categorie
export const SET_CATEGORIES = "SET_CATEGORIES";

// Recupera l'id da fare il share sui social
export const GET_SHARE_ID = "GET_SHARE_ID";

// Imposta l'id da fare il share sui social
export const SET_SHARE_ID = "SET_SHARE_ID";

// Recupera il type da fare il share sui social
export const GET_SHARE_TYPE = "GET_SHARE_TYPE";

// Imposta il type da fare il share sui social
export const SET_SHARE_TYPE = "SET_SHARE_TYPE";

// Recupera i Cluster
export const GET_CLUSTERS = 'GET_CLUSTERS';

// Setta come completato il caricamento dei cluster, e li salva nello Store applicativo
export const SET_CLUSTERS = 'SET_CLUSTERS';

export class SetCoreApplicationData implements Action {
  readonly type = SET_CORE_APPLICATION_DATA;

  // Payload dell'azione
  constructor(public payload: GlobalApplicationData) { }
}

export class SaveRedirectUrl implements Action {
  readonly type = SAVE_REDIRECT_URL;

  // Payload dell'azione, che è l'url richiesto
  constructor(public payload: string) { }
}

export class SetApplicationLang implements Action {
  readonly type = SET_APPLICATION_LANG;

  // Payload dell'azione, che contiene l'abbreviazione della lingua scelta (es 'it')
  constructor(public payload: string) { }
}

export class SetDefaultLang implements Action {
  readonly type = SET_DEFAULT_LANG;

  constructor(public payload: string) { }
}

export class ShowApplicationLoader implements Action {
  readonly type = SHOW_APPLICATION_LOADER;
  // flag per poter gestire il loader dell'applicazione manualmente e non tramite interceptor
  constructor(public payload?: { forceManualLoaderHandling: boolean }) { }
}

export class HideApplicationLoader implements Action {
  readonly type = HIDE_APPLICATION_LOADER;
  // flag per poter gestire il loader dell'applicazione manualmente e non tramite interceptor
  constructor(public payload?: { closeManualLoader: boolean }) { }
}

export class GetAvailableLangs implements Action {
  readonly type = GET_AVAILABLE_LANGS;
}

export class RemoveApplicationLang implements Action {
  readonly type = REMOVE_APPLICATION_LANG;
}

export class GetAvailableLangsFinished implements Action {
  readonly type = GET_AVAILABLE_LANGS_FINISHED;
}

export class StartRenewTokenPolling implements Action {
  readonly type = START_RENEW_TOKEN_POLLING;
  constructor(public payload?: { redirectUrl?: string, forceRefreshUser?: boolean }) { }
}

export class SetClusters implements Action {
  readonly type = SET_CLUSTERS;

  constructor(public payload: Tag[]) { }
}

export class GetClusters implements Action {
  readonly type = GET_CLUSTERS;
}

export class RemoveCoreApplicationData implements Action {
  readonly type = REMOVE_CORE_APPLICATION_DATA;
}

export class RemoveRedirectUrl implements Action {
  readonly type = REMOVE_REDIRECT_URL;
}

export class SetScreenSize implements Action {
  readonly type = SET_SCREEN_SIZE;
}

export class GetCategories implements Action {
  readonly type = GET_CATEGORIES;
}

export class SetCategories implements Action {
  readonly type = SET_CATEGORIES;
  constructor(public payload: TagInfo[]) { }
}

// Definisco un tipo custom per eseguire il bundle delle azioni in un singolo export
export type CoreActions = SetCoreApplicationData
  | GetAvailableLangs
  | GetAvailableLangsFinished
  | RemoveCoreApplicationData
  | SaveRedirectUrl
  | ShowApplicationLoader
  | SetDefaultLang
  | HideApplicationLoader
  | SetClusters
  | GetClusters
  | StartRenewTokenPolling
  | SetApplicationLang
  | RemoveApplicationLang
  | RemoveRedirectUrl
  | SetScreenSize
  | GetCategories
  | SetCategories;
