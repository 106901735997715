<div style="height: 100vh" fxLayout="row">
  <div fxLayout="column" fxLayoutAlign="center center" fxFill>
    <div class="logo-container" fxLayout="column" fxLatyoutAlign="center center">
      <img width="140" class="logoRegular cursor-pointer" src="assets/img/generali/logo-generali.svg">
    </div>
    <h4 class="center">
      LearningUP
    </h4>
    <div fxLayout="row" fxLayoutAlign="center center">
    </div>
    <h4 class="center">Caricamento in corso
    </h4>
  </div>
</div>
