import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { ChatService } from "src/app/shared/components/chat/chat.service";
import { Subscription } from "rxjs";

@Component({
  selector: "chatsingleuser",
  templateUrl: "./chat-single-user.component.html",
  styleUrls: ["./chat-single-user.component.scss"],
})
export class ChatSingleUserComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() message: string;
  @Input() time: string;
  @Input() firstLetters: string;
  @Input() imageSrc: string;
  @Input() received = true;
  @Input() queue: string;
  @Input() isDesktop: boolean = false;

  @Output() onUserClicked = new EventEmitter<any>();

  count: number = 0;
  private subscription: Subscription;

  constructor(private _chatService: ChatService) {}

  ngOnInit(): void {
    this.subscription = this._chatService
      .watchQueueCounter(this.queue)
      .subscribe((data) => (this.count = data));
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  emitOnItemClicked() {
    this.onUserClicked.emit();
  }
}
