<ngx-guided-tour skipText="{{ 'tutorials.SKIP' | translate }}" nextText="{{ 'tutorials.NEXT' | translate }}"
  closeText="{{ 'tutorials.CLOSE' | translate }}" backText="{{ 'tutorials.BACK' | translate }}"
  doneText="{{ 'tutorials.DONE' | translate }}">
</ngx-guided-tour>

<app-header id="app-header" *ngIf="!(isFetchingLangs$ | async) && showHeader && isAuthenticated">
</app-header>

<div [hidden]="showApplicationLoader">
  <router-outlet *ngIf="!(isFetchingLangs$ | async)"></router-outlet>
</div>

<!-- Loader dell'applicazione, del caricamento delle pagine e del recupero delle lingue disponibili, o ogniqualvolta il valore "showApplicationLoader " nello Store è vero -->
<div [hidden]="!showApplicationLoader && !(isFetchingLangs$ | async)" class="app-loading">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <!-- Testo personalizzato -->
    <p>Caricamento in corso</p>
  </div>
  <div class="logo"></div>
</div>

<!-- <app-footer *ngIf="!(isFetchingLangs$ | async) && showFooter && showFooterTime">
</app-footer> -->

<app-modal class="modal-login" id="modalLogin">
  <div class="container" fxLayout="column" fxLayoutAlign="start">

    <div fxLayout="row" fxLayoutAlign="end center">
      <!-- Icona chiudi -->
      <img role="button" title="Close event code modal" (click)="closeModalLogin()" height="19" width="17"
        src="../assets/icons/icon-times.svg" class="cursor-pointer">
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">

      <div fxFlex.xs class="full-width" fxLayout="column" fxLayoutAlign="start center">
        <h4 fxFlexOffset="19px" fxFlexOffset.xs="16px" class="center lighter" translate="login.modal.TITLE"></h4>
        <h6 class="lighter center" fxFlexOffset="40px" translate="login.modal.DESCRIPTION"></h6>
      </div>

      <div class="full-width" fxLayout="row" fxLayout.xs="column-reverse" fxLayout.md="column-reverse"
        fxLayoutAlign="center center">

        <!-- REGISTRATI -->
        <custom-button ngClass.xs="full-width" [text]="('login.modal.CONFIRM_BTN') | translate"
          (onClick)="goToLocalLogin()">
        </custom-button>
      </div>

    </div>

  </div>
</app-modal>

<app-modal id="switch-browser-modal" (onModalClose)="closeSwitchBrowserModal()">
  <div class="switch-browser-modal">
    <div class="inner-modal-close">
      <svg-icon id="close-icon-modal" tabindex="0" src="assets/img/assets-IE-noworking-modal/icon-close.svg"
        tabindex="0" (click)="closeSwitchBrowserModal()">
      </svg-icon>
    </div>
    <div class="wrapper-gif">
      <img class="gif" src="assets/img/assets-IE-noworking-modal/loader.gif">
    </div>
    <h1>Cambia browser per una migliore esperienza</h1>
    <p>Internet Explorer non permette una completa fruizione della piattaforma.<br>
      Utilizza un browser diverso come:</p>
    <div class="icon-browser-wrapper">
      <div class="browser-icon-wrapper">
        <img src="assets/img/assets-IE-noworking-modal/chrome.png">
        <p>Chrome</p>
      </div>
      <div class="browser-icon-wrapper">
        <img src="assets/img/assets-IE-noworking-modal/edge.png">
        <p>Edge</p>
      </div>
    </div>
  </div>
</app-modal>