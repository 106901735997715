<div [id]="_id" class="circle-percentage" [ngClass]="('p' + percentage)">
  <p>
    <span>{{percentage}}</span>
    <span class="percentage">%</span>
  </p>
  <div class="slice">
    <div class="bar" [ngClass]="{'red': percentage <= 50}"></div>
    <div class="fill"></div>
  </div>
</div>
