/*
*    Dichiarazione dei moduli comuni e delle direttive
*/

import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@busacca/ng-pick-datetime';
import { GeneraliModule } from 'generali';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DropZoneDirective } from './directives/drop.directive';
import { KalturaPlayerComponent } from '../core/kaltura-player/kaltura-player.component';
import { DropdownMenuItemDirective } from '../core/dropdown-menu/dropdown-menu-item.directive';
import { DropdownMenuComponent } from '../core/dropdown-menu/dropdown-menu.component';
import { ModalComponent } from '../core/modal/modal.component';
import { DatepickerComponent } from '../core/datepicker/datepicker.component';
import { ScormPlayerComponent } from '../core/scorm-player/scorm-player.component';
import { InputPlaceholderComponent } from '../core/placeholders/input/input-placeholder.component';
import { InputSearchComponent } from '../core/input-search/input-search.component';
import { InputIconComponent } from '../core/input-icon/input-icon.component';
import { CheckboxComponent } from '../core/checkbox/checkbox.component';
import { ModalBadgeComponent } from '../core/modal-badge/modal-badge.component';
import { RadioComponent } from '../core/radio/radio.component';
import { InputCodeComponent } from '../core/input-code/input-code.component';

import { ProfilationComponent } from '../auth/profilation/profilation.component';

import { BaseComponent } from './components/base-component.component';
import { BaseSubscriberComponent } from './components/base-subscriber.component';
import { BaseFormComponent } from './components/base-form.component';
import { TabComponent } from './components/tab/tab.component';
import { CustomButtonComponent } from './components/button/button.component';
import { SliderComponent } from './components/slider/slider.component';
import { SVGNotificationComponent } from './components/svg-notification/svg-notification.component';
import { SelectWithCheckboxComponent } from './components/select-with-checkbox/select-with-checkbox.component';
import { SelectComponent } from './components/select/select.component';
import { SelectListComponent } from './components/select-list/select-list.component';
import { LongTextComponent } from './components/long-text/long-text.component';
import { CirclePercentageComponent } from './components/circle-percentage/circle-percentage.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { SelectIconComponent } from './components/select-icon/select-icon.component';
import { ModalBadgeContentComponent } from '../core/modal-badge-content/modal-badge-content.component';
import { ModalCoinContentComponent } from '../core/modal-coin-content/modal-coin-content.component';
import { PaginationComponent } from '../core/pagination/pagination.component';
import { GenericListPlaceholderComponent } from './components/generic-list-placeholder/generic-list-placeholder.component';
import { ChatSingleUserComponent } from './components/chat-single-user/chat-single-user.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatMessageComponent } from './components/chat-message/chat-message.component';
import { ExpandableListComponent } from './components/expandable-list/expandable-list.component';
import { SelectHeaderComponent } from './components/select-header/select-header.component';
import { PageViewsCounterComponent } from './page-views-counter/page-views-counter.component';
import { ReplacePlaceholderPipe } from './pipes/replace-placeholder.pipe';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { DurationPipe } from './pipes/duration.pipe';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { TruncateFilenamePipe } from './pipes/truncate-filename.pipe';
import { MenuDirective } from './components/menu.directive';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { TimeFromNowPipe } from './pipes/time-from-now.pipe';
import { CustomInputComponent } from '../home/components/input/input.component';
import { ItemDetailsComponent } from '../home/item-details/item-details.component';
import { SurveyComponent } from '../home/survey/survey.component';
import { SurveyResultComponent } from '../home/survey-result/survey-result.component';
import { CourseCardListItemComponent } from '../home/components/course-card-list-item/course-card-list-item.component';
import { SlickSectionContentComponent } from '../home/components/slick-section-content/slick-section-content.component';
import { SlickSectionHeaderComponent } from '../home/components/slick-section-header/slick-section-header.component';
import { ArraySortPipe } from './pipes/order-by.pipe';
import { CutStringPipe } from './pipes/cut-string.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { ItemDetailsHeaderActionsComponent } from '../home/components/item-details-header-actions/item-details-header-actions.component';
import { TextareaComponent } from '../home/components/textarea/textarea.component';
import { LinkedInShareComponent } from '../home/components/linkedin-share/linkedin-share.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { AgmCoreModule } from '@agm/core';
import { SearchHomeComponent } from './components/search-home/search-home.component';
import { ViewAllComponent } from './components/view-all/view-all.component';
import { SurveysTabComponent } from '../home/item-details/surveys-tab/surveys-tab.component';
import { DescriptionTabComponent } from '../home/item-details/description-tab/description-tab.component';

@NgModule({
  declarations: [
    ChatSingleUserComponent,
    ChatComponent,
    ChatMessageComponent,
    SafeHtmlPipe,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    ModalComponent,
    DropdownMenuComponent,
    DropdownMenuItemDirective,
    KalturaPlayerComponent,
    ScormPlayerComponent,
    DatepickerComponent,
    TabComponent,
    BaseFormComponent,
    LoaderComponent,
    InputPlaceholderComponent,
    CustomButtonComponent,
    SliderComponent,
    CheckboxComponent,
    ModalBadgeComponent,
    TruncatePipe,
    InputSearchComponent,
    InputIconComponent,
    SelectIconComponent,
    SVGNotificationComponent,
    ProfilationComponent,
    SelectWithCheckboxComponent,
    SelectComponent,
    SelectListComponent,
    RadioComponent,
    LongTextComponent,
    InputCodeComponent,
    DropZoneDirective,
    CirclePercentageComponent,
    BackButtonComponent,
    ModalBadgeContentComponent,
    ModalCoinContentComponent,
    PaginationComponent,
    GenericListPlaceholderComponent,
    ExpandableListComponent,
    SelectHeaderComponent,
    PageViewsCounterComponent,
    ReplacePlaceholderPipe,
    CollectionCardComponent,
    CourseCardComponent,
    DurationPipe,
    DragAndDropComponent,
    ProgressBarComponent,
    TruncateFilenamePipe,
    MenuDirective,
    MenuItemComponent,
    TimeFromNowPipe,
    CustomInputComponent,
    ItemDetailsComponent,
    SurveyComponent,
    SurveyResultComponent,
    CourseCardListItemComponent,
    CourseCardComponent,
    SlickSectionContentComponent,
    SlickSectionHeaderComponent,
    ArraySortPipe,
    CutStringPipe,
    OrderByDatePipe,
    ItemDetailsHeaderActionsComponent,
    TextareaComponent,
    LinkedInShareComponent,
    SearchHomeComponent,
    ViewAllComponent,
    SurveysTabComponent,
    DescriptionTabComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxPaginationModule,
    FlexLayoutModule,
    TranslateModule.forChild(),
    MomentModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    AngularSvgIconModule,
    GeneraliModule.forRoot({ libraryStyle: 'GO_GENERALI' }),
    CKEditorModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBZ25MNayUTrWKe9uvvPj3DyoihFuGpCvY'
    })
  ],
  exports: [
    ChatSingleUserComponent,
    ChatComponent,
    ChatMessageComponent,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalComponent,
    SafeHtmlPipe,
    MomentModule,
    NgSelectModule,
    DropdownMenuComponent,
    InputPlaceholderComponent,
    LoaderComponent,
    FlexLayoutModule,
    TabComponent,
    DropdownMenuItemDirective,
    KalturaPlayerComponent,
    ScormPlayerComponent,
    AngularSvgIconModule,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    DatepickerComponent,
    CustomButtonComponent,
    SliderComponent,
    CheckboxComponent,
    ModalBadgeComponent,
    TruncatePipe,
    InputSearchComponent,
    InputIconComponent,
    SelectIconComponent,
    SVGNotificationComponent,
    ProfilationComponent,
    SelectWithCheckboxComponent,
    SelectComponent,
    SelectListComponent,
    RadioComponent,
    LongTextComponent,
    InputCodeComponent,
    CirclePercentageComponent,
    BackButtonComponent,
    GeneraliModule,
    ModalBadgeContentComponent,
    ModalCoinContentComponent,
    PaginationComponent,
    GenericListPlaceholderComponent,
    ExpandableListComponent,
    SelectHeaderComponent,
    PageViewsCounterComponent,
    ReplacePlaceholderPipe,
    CollectionCardComponent,
    CourseCardComponent,
    DurationPipe,
    DragAndDropComponent,
    ProgressBarComponent,
    TruncateFilenamePipe,
    MenuDirective,
    MenuItemComponent,
    TimeFromNowPipe,
    CustomInputComponent,
    AgmCoreModule,
    SearchHomeComponent,
    ViewAllComponent,
    TextareaComponent
  ],
  providers: [
    DatePipe
  ]
})

export class SharedModule {
}
