<button [id]="_id" [tabindex]="_tabIndex" [ngClass]="buttonClasses" [type]="type" [disabled]="disabled"
  [attr.aria-label]="ariaLabel" (click)="emitOnButtonClicked($event)">
  <div fxLayout="row" fxLayoutAlign="center center">
    <!-- Eventuale icona di sinitra -->
    <div class="left-icon-wrapper" *ngIf="iconLeft">
      <svg-icon [src]="iconLeft" class="medium-size" [applyClass]="true">
      </svg-icon>
    </div>
    <!-- Testo -->
    <span [ngClass]="{'text-uppercase': textUppercase, 'white-space-normal': whiteSpaceNormal}">{{ text }}</span>
    <!-- Badge -->
    <generali-badge *ngIf="_importance === 'action' && points > 0 && !disabled" fxFlexOffset="10px"
      fxFlexOffset.xs="6px" type="primary" [title]="points"></generali-badge>
  </div>
</button>