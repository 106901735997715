import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromApp from '../../ngrx/app.reducers';
// Import dello State dell'autenticazione, che è quello che selezioneremo con lo slice dallo Store
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Lang } from 'atfcore-commonclasses';

@Component({
  selector: 'app-logout-sso-completed',
  templateUrl: './logout-sso-completed.component.html',
  styleUrls: ['./logout-sso-completed.component.scss']
})
export class LogoutSsoCompletedComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean;
  langs: Lang[];
  error: any;

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private router: Router) {
  }
  ngOnInit() {
  }
  ngOnDestroy() {
  }
}
