<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema
<div *ngIf="langs && langs.length">
  <generali-carousel noScroll [manageSwipe]="true" (indexChange)="indexChange($event)"
    [preventOverflowMarginOnRight]="true" [isFullWindow]="true" sectionId="first-intro-carousel" [contentLength]="2"
    [scrollToLeft]="scrollToIntro.asObservable()" [scrollToRight]="scrollToCustomization.asObservable()">

    <div class="first-introduction" [ngClass.xs]="{'min-height750': carouselIndex == 1}">
      <div class="first-introduction-content" fxLayout="column" fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start stretch">

        <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
          <div class="first-introduction-logo" fxLayout="column" fxLayoutAlign="center center">
            <img alt="LearningUP" height="80" layout="column"
              src="/assets/img/generali/logo_lockup_bianco.png">
          </div>
        </div>

        <div fxFlexOffset="90px" fxFlexOffset.xs="32px" class="first-introduction-text" fxLayout="column"
          fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">

          <h2 class="lighter white" translate="first-introduction.TITLE"></h2>
          <h2 class="bold white" translate="first-introduction.SUB_TITLE"></h2>

          <h5 class="lighter" translate="first-introduction.DESCRIPTION"></h5>

          <custom-button class="flex-hide-lt-md" fxFlexOffset.sm="10px" fxFlexOffset="70px"
            [text]="'generic.CONTINUE_UPPER' | translate" (onClick)="scrollToCustomization.next()">
          </custom-button>

        </div>

        <div fxFlexOffset="53px" class="first-introduction-carousel-text flex-hide-lt-md" fxLayout="column"
          fxLayoutAlign="start start">
          <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center">
            <div class="first-introduction-point"></div>
            <h6 translate="first-introduction.WELCOME"></h6>
          </div>
          <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="12px"
            (click)="scrollToCustomization.next()">
            <div class="first-introduction-point not-selected"></div>
            <h6 class="not-selected" translate="first-introduction.CUSTOM_EXPERIENCE"></h6>
          </div>
        </div>

      </div>

      <!-- IMMAGINE MONDO
      <div class="first-introduction-first-background" fxLayout="column" fxLayoutAlign="center center">
        <!-- Loader
        <ng-container *ngIf="isLoadingWelcomePage">
          <generali-loader></generali-loader>
        </ng-container>
        <!-- Immagine mondo
        <ng-container *ngIf="!isLoadingWelcomePage && !hasVideo">
          <img attr.aria-label=" First introduction image" src="../../../assets/img/intro/intro.svg"
            alt="firstIntroductionImage1" title="firstIntroductionImage1">
        </ng-container>
        <!-- Video kaltura
        <ng-container *ngIf="!isLoadingWelcomePage && hasVideo">
          <kaltura-player playerVisible [itemId]="welcomePage.homePageId"
            [kalturaScript]="welcomePage.homePageEmbeddedPlayerCode">
          </kaltura-player>
        </ng-container>

        <custom-button class="flex-show-lt-md z-index1" fxFlexOffset.xs="150px" fxFlexOffset="130px"
          [text]="'generic.CONTINUE_UPPER' | translate" (onClick)="scrollToCustomization.next()">
        </custom-button>
      </div>

    </div>

    <div class="first-introduction" [ngClass.xs]="{'min-height750': carouselIndex == 1}">
      <div class="first-introduction-content" fxLayout="column" fxLayoutAlign="space-between start"
        fxLayoutAlign.lt-md="start stretch">

        <div class="full-width" fxLayout="row" fxLayoutAlign="center center">
          <div class="first-introduction-logo" fxLayout="column" fxLayoutAlign="center center">
            <img alt="LearningUP" height="90" layout="column"
              src="/assets/img/generali/logo_lockup_bianco.png">
          </div>
        </div>

        <div fxFlexOffset="90px" fxFlexOffset.xs="32px" class="first-introduction-text" fxLayout="column"
          fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center">

          <h2 class="second-page lighter white" translate="first-introduction.TITLE2"></h2>
          <h2 class="second-page bold white" translate="first-introduction.SUB_TITLE2"></h2>

          <h5 class="second-page" translate="first-introduction.DESCRIPTION2"></h5>

          <div fxFlexOffset="70px" fxFlexOffset.lt-md="54px" class="first-introduction-buttons" fxLayout="column"
            fxLayoutAlign="start center">

            <custom-button *ngIf="!isFirstIntroFromHome" [text]="'profilation.CUSTOMIZE' | translate"
              (onClick)="openProfilationModal()">
            </custom-button>

            <custom-button *ngIf="isFirstIntroFromHome" [text]="'generic.SIGN_IN_UPPER' | translate"
              (onClick)="goToLocalLogin()">
            </custom-button>

            <custom-button fxFlexOffset="32px" fxFlexOffset.lt-md="20px" importance="secondary"
              [text]="'first-introduction.BUTTON_BROWSE' | translate" (onClick)="goToHome()">
            </custom-button>

          </div>

        </div>

        <div fxFlexOffset="108px" class="first-introduction-carousel-text flex-hide-lt-md" fxLayout="column"
          fxLayoutAlign="start start">
          <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center" (click)="scrollToIntro.next()">
            <div class="first-introduction-point grey-point"></div>
            <h6 translate="first-introduction.WELCOME"></h6>
          </div>
          <div class="cursor-pointer" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="12px">
            <div class="first-introduction-point grey-point"></div>
            <h6 translate="first-introduction.CUSTOM_EXPERIENCE"></h6>
          </div>
        </div>

      </div>

      <!-- IMMAGINE UFFICIO
      <div class="first-introduction-second-background" fxLayout="column" fxLayoutAlign="center center">
        <img attr.aria-label="Second introduction image" src="../../../assets/img/intro/intro2.svg"
          alt="firstIntroductionImage2" title="firstIntroductionImage2">
      </div>
    </div>

  </generali-carousel>
</div> -->

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>

<app-modal *ngIf="langs && langs.length" class="modal-profilation" id="profilationModal">
  <profilation [onOpenModal]="onOpenProfilationModal.asObservable()" (onCloseModal)="closeProfilationModal()" (profilationCompleted)="closeProfilationModal()">
  </profilation>
</app-modal>