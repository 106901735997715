import { Directive, Input } from "@angular/core";

/*
* Componente che crea una singola Tab
*/

@Directive({
	selector: '[dropdown-menu-item]'
})
export class DropdownMenuItemDirective {

	@Input() set dropdownMenuItem(condition: boolean) {
	}

	constructor() {
	}
}