import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeFromNow'
})
export class TimeFromNowPipe implements PipeTransform {

  transform(value: string): string {
    if (moment(value).isValid()) {
      return moment(value).fromNow();
    }
    return value;
  }

}
