import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent {

  @Input() accepts: string[] = ['image/*'];

  @Output() fileChanged = new EventEmitter<File>();

  // turn off the browser's default drag behavior
  @HostListener('dragover', ['$event']) onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // turn off the browser's default drag behavior
  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files: FileList = event.dataTransfer.files;

    if (files.length > 0 && this.isFileAccepted(files.item(0))) {
      this.selectedFileName = this.sliceFileName(files[0].name);
      this.fileChanged.emit(files[0]);
    }
  }

  @ViewChild('fileInput') private fileInput: ElementRef<HTMLInputElement>;

  selectedFileName: string | null = null;

  constructor() { }

  handleFileChange(event: Event) {
    const fileList: FileList = event.target['files'];

    if (fileList.length > 0 && this.isFileAccepted(fileList.item(0))) {
      const file: File = fileList[0];
      this.selectedFileName = this.sliceFileName(file.name);
      this.fileChanged.emit(file);
    }
  }

  resetInput() {
    this.fileInput.nativeElement.value = '';
    this.selectedFileName = null;
  }

  // Rimuovo la parte centrale del nome del file se e' troppo lunga
  // rimpiazzando con ... e mantenendo l'estensione del file
  private sliceFileName(fileName: string, maxLen: number = 15): string {
    if (fileName.length > 30) {
      const extension = fileName.split('.').pop();
      return fileName.slice(0, maxLen) + '...' + extension;
    }
    return fileName;

  }

  private isFileAccepted(file: File): boolean {
    // if accepts is empty, accept all files
    if (this.accepts.length === 0) {
      return true;
    }

    return this.accepts.some((type) => file.type.match(type));
  }

}
