import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CMUtils, GLPShowcaseCard } from 'atfcore-commonclasses';

@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {

  readonly translationPrefix = 'itemDetailsPage.initiativeTypes.';

  @Input() set course(course: GLPShowcaseCard) {
    this.courseId = course.courseId;
    this.type = this.getTranslatedType(course.type);
    this.courseDuration = course.courseDuration ?? '0 ore';
    this.title = course.title;
    // TODO: forse bisogna trovare un'immagine di default migliore per i corsi
    this.cardCover = course.cardCover ?? 'assets/img/courses/Corsi_01.jpg';
    this.argumentName = course.argumentName ?? 'No tag';
    this.isBookmarked = course.isBookmarked ?? false;
    this.status = course.userStatus ?? 'Da Iniziare';
    this.modality = this.getModality(course)
  }

  // emit courseId
  @Output() courseClicked = new EventEmitter<string>();

  // emit courseId
  @Output() courseBookmarked = new EventEmitter<string>();

  courseId: string;
  courseDuration: string;
  type: string;
  title: string;
  cardCover: string;
  argumentName: string;
  isBookmarked: boolean;
  status: string;
  modality: string[];


  // Allow user to pass custon <ng-template> for the mode and status icons
  @ContentChild('modeIcon') modalityIcon: TemplateRef<any>;

  @ContentChild('statusIcon') statusIcon: TemplateRef<any>;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  private getModality(course: GLPShowcaseCard): string[] {
    const isOnlineOrLibrary = CMUtils.isOnlineStage(course.type) || CMUtils.ALL_LIBRARY_ITEM_TYPES.includes(course.type);

    if (isOnlineOrLibrary) {
      return ['Online'];
    } else {
      return course?.editions?.reduce((acc, curr) => {
        return acc.concat(curr?.days.map(day => day?.location));
      }, []) ?? [];
    }
  }

  private getTranslatedType(type: string): string {
    return this.translate.instant(this.translationPrefix + type) || type;
  }

}
