import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { UserActivity } from 'src/app/core/services/profile.service';

export type NotificationType = 'bell' | 'flag' | 'document' | 'trophy' | 'info';

@Component({
  selector: 'svg-notification',
  templateUrl: './svg-notification.component.html',
  styleUrls: ['./svg-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SVGNotificationComponent implements OnInit {

  _fromHeader: boolean;
  @Input() set fromHeader(value: boolean | string) {
    this._fromHeader = coerceBooleanProperty(value);
  };

  @Input() isBlack: boolean;

  // If the svg has a notification
  _hasNotification: boolean;
  @Input() set hasNotification(value: boolean | string) {
    this._hasNotification = coerceBooleanProperty(value);
  };

  _hasDarkMood: boolean;
  @Input() set hasDarkMood(value: boolean | string) {
    this._hasDarkMood = coerceBooleanProperty(value);
  };

  @Input() activity?: UserActivity;

  // Emit the on icon click
  @Output() onClick = new EventEmitter<void>();

  get srcIcon(): string {

    if (this.activity) {
      switch (this.activity.itemType) {
        case 'CERTIFICATE':
          return "../../../../assets/icons/notification/icon-document.svg";
        case 'COURSE':
          if (this.activity.eventName === 'COURSE_STARTED') {
            return 'assets/icons/notification/icon-info.svg';
          } else return "../../../../assets/icons/notification/icon-flag.svg";
        case 'CHALLENGE':
        case 'EVENT':
        case 'JOB_POSITION':
          return "../../../../assets/icons/notification/icon-info.svg";
        case 'BADGE':
          return "../../../../assets/icons/notification/icon-trophy.svg";
        default:
          return "../../../../assets/icons/notification/icon-menu-bell-grey.svg";
      }
    } else if(this.isBlack) {
      return "../../../../assets/icons/notification/icon-menu-bell.svg";
    } else return "../../../../assets/icons/notification/icon-menu-bell-grey.svg";
  }

  constructor() {
  }

  ngOnInit() {

  }

}
