import { CommonConstants } from "atfcore-commonclasses";

export interface ApplicationUrl {
  url: string,
  baseUrl: string,
  protocol: string,
  domain: string,
}

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
export class UrlService {
  private development: boolean;
  private staging: boolean;
  private production: boolean;
  constructor() {
    this.checkHostName();
  }

  checkHostName() {
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      this.development = true;
    } else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      this.development = true;
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      this.staging = true;
    } else {
      this.production = true;
    }
  }

  isDevelopment() {
    return this.development;
  }

  isStaging() {
    return this.staging;
  }

  isProduction() {
    return this.production;
  }

  // Ritorna l'URL dell'applicazione
  getApplicationUrl(): ApplicationUrl {
    let applicationUrl: ApplicationUrl = {
      url: null,
      baseUrl: null,
      protocol: '',
      domain: ''
    }

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }

    // Controllo addizionale se è effettivamente localhost avviato da ng serve
    const isLocalhost = window.location.href.indexOf('localhost:') !== -1;
    if (isLocalhost) {
      applicationUrl.domain = "atfglp2022.alloy.it";
      this.development = true;
    } else {
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      applicationUrl.domain = url + secondLevelDomain;

      if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
        this.development = true;
      } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
        this.staging = true;
      } else {
        this.production = true;
      }
    }

    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    applicationUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

    // Forzo https nel caso di localhost per evitare problemi di CORS
    if (isLocalhost && secondLevelDomain === "localhost") {
      applicationUrl.protocol = "https";
    }
    //TODO: AR debug in cert
    // applicationUrl.domain = "testglp2022.genurl.it";

    applicationUrl.baseUrl = applicationUrl.protocol + "://" + applicationUrl.domain + "/";
    applicationUrl.url = applicationUrl.protocol + "://" + applicationUrl.domain;
    return applicationUrl;
  }

  // Metodo che ritorna l'URL per della GLP
  getGLPURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhost.";
    }
    else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atfglp2022.";
      secondLevelDomain = "alloy.it"
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "testglp2022.";
      secondLevelDomain = "genurl.it"
    }
    else {
      // Il dominio di generali in prod è cambiato da generalilearningplatform.com a learningup.generali.com,
      // riassegno il dominio corretto a secondLevelDomain per i redirect
      secondLevelDomain = "generali.it"
      thirdLevelDomain = "learningup.";
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  getServiceMediatorUrl(): string {
    return this.getApplicationUrl().baseUrl + 'rest-api/coursemanager-mediator/';
  }

  getCm2ServiceMediatorUrl(): string {
    return this.getCoursemanagerUrl(true).baseUrl + 'rest-api/coursemanager-mediator/';
  }

  // Proxa l'url di un'immagine
  getExternalResourceName = (requestUrl: string, requestName: string) => {
    return this.getCoursemanagerUrl(true).baseUrl + 'rest-api/coursemanager-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
  }

  // Ritorna l'url del courseManager
  getCoursemanagerUrl(forService?: boolean) {
    let domain = "";
    let courseManagerUrl: any = {
      url: null,
      baseUrl: null,
      protocol: ''
    }
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    courseManagerUrl.protocol = window.location.protocol === "http:" ? "https" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      // domain = "localhostcm2.alloy.it";
      domain = "atfcm2.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atfglp2022") == 0 && firstDot > 0)) {
      if(forService) {
        domain = "atfglp2022.alloy.it";
      } else {
        domain = "atfcm2.alloy.it";
      }
    }
    else if ((window.location.hostname.indexOf("atfdemocm2") == 0 && firstDot > 0)) {
      domain = "atfcm2.alloy.it";
    }
    else if (window.location.hostname.indexOf("testglp2022") == 0 && firstDot > 0) {
      domain = "testglp2022.genurl.it";
    }
    else if (window.location.hostname.indexOf("prodglp2022") == 0 && firstDot > 0) {
      domain = "prodglp2022.genurl.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      domain = "testcoursemanager.generalilearningplatform.com";
    }
    else {
      // Il dominio di generali in prod è cambiato da generalilearningplatform.com a learningup.generali.it,
      // riassegno il dominio corretto a secondLevelDomain
      // secondLevelDomain = "generalilearningplatform.com"
      // domain = "coursemanager." + secondLevelDomain;
      if(forService) {
        domain = "learningup.generali.it";
      } else {
        domain = "coursemanager.generalilearningplatform.com";
      }
    }
    // TODO: AR debug in cert
    // domain = "testglp2022.genurl.it";

    //courseManagerUrl.baseUrl = courseManagerUrl.protocol + "://" + domain + ":" + port + "/";
    courseManagerUrl.baseUrl = courseManagerUrl.protocol + "://" + domain + "/";
    courseManagerUrl.url = courseManagerUrl.protocol + "://" + domain;

    return courseManagerUrl;
  }

  // Ritorna l'URL dell'applicazione
  getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = '';
    let ssoUrl: string = null;
    let ssoReturnPage: string = null;
    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === 'http:' ? 'http' : 'https';

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf('.');
    if (lastDot !== -1) {
      firstDot = window.location.hostname.lastIndexOf('.', lastDot - 1);
    }
    if (firstDot === -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(
      firstDot,
      window.location.hostname.length
    );
    if (secondLevelDomain.charAt(0) === '.') {
      secondLevelDomain = secondLevelDomain.substring(
        1,
        window.location.hostname.length
      );
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf('localhost') === 0) {
      domain = 'localhosts.alloy.it';
    } else if (window.location.hostname.indexOf('atf') === 0 && firstDot > 0) {
      domain = 'testsso.generalilearningplatform.com';
      // domain = window.location.hostname;
    } else if (window.location.hostname.indexOf('test') === 0 && firstDot > 0) {
      domain = 'testsso.generalilearningplatform.com';
    } else {
      domain = 'sso.generalilearningplatform.com';
    }

    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    }

    ssoUrl =
      protocol +
      '://' +
      domain +
      '/login?originApplicationName=' +
      encodeURIComponent(CommonConstants.APPLICATION_TRAINING_PLATFORM) +
      '&applicationUrl=' +
      encodeURIComponent(ssoReturnPage) +
      '&deviceType=' +
      encodeURIComponent(deviceType) +
      '&userAgent=' +
      encodeURIComponent(userAgent);
    return ssoUrl;
  }



  // Metodo che ritorna l'URL per della Library
  getLibraryURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhostl.";
    }
    else if (window.location.hostname.indexOf("atf") == 0 && firstDot) {
      thirdLevelDomain = "atfl.";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      secondLevelDomain = "generalilearningplatform.com"
      thirdLevelDomain = "testlibraryonline.";
    }
    else { // prima era www.
      // Il dominio di generali in prod è cambiato da generalilearningplatform.com a learningup.generali.com,
      // riassegno il dominio corretto a secondLevelDomain
      secondLevelDomain = "generalilearningplatform.com"
      thirdLevelDomain = "libraryonline."
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  // Metodo che ritorna l'URL per della Library di Alleanza
  getLibraryAlleanzaURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhostla.";
    }
    else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atfl.";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "testalleanza.";
    }
    else { // prima era www.
      thirdLevelDomain = "alleanza.";
      secondLevelDomain = "generalilearningplatform.com"
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  // Metodo che ritorna l'URL per della Library di DAS
  getLibraryDasURL(addDefaultDestinationPage?: boolean, isLocalLogin?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhostl.";
    }
    else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atfl.";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "testlibrarydas.";
    }
    else { // prima era www.
      thirdLevelDomain = "librarydas."
      secondLevelDomain = "generalilearningplatform.com"
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhostl.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      if (isLocalLogin) {
        url += "/#/app/localLogin";
      } else {
        url += "/#/app/login";
      }
    }
    return url;
  };

}
