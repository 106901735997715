<app-modal class="modal-badge star" id="modal-coin">
  <div class="container" fxLayout="column" fxLayoutAlign="space-between center">

    <div fxLayout="column" fxLayoutAlign="start center">
      <!-- ICON STAR -->
      <svg-icon class="star" src="../../../assets/icons/badge/icon-star.svg"></svg-icon>
      <!-- COINS -->
      <h5 fxFlexOffset="34px" class="bold" translate="badge.COINS"></h5>
      <!-- TITLE -->
      <h3 fxFlexOffset="12px" fxFlexOffset.xs="16px" class="bold">+{{coinsNumber}}</h3>
    </div>

    <!-- CONTINUE -->
    <custom-button ngClass.xs="full-width" [text]="'generic.CONTINUE_UPPER' | translate"
      (onClick)="closeModalBadgeStar()">
    </custom-button>

  </div>
</app-modal>
