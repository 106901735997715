import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';

@Component({
  selector: 'circle-percentage',
  templateUrl: './circle-percentage.component.html',
  styleUrls: ['./circle-percentage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CirclePercentageComponent implements OnInit {

  _id: string;
  @Input() id: string | number;

  @Input() percentage: number = 0;

  constructor() {
  }

  ngOnInit() {
    if (!this.id) {
      this._id = "circle-percentage-" + Math.floor((Math.random() * 10000) + 1);
    } else {
      this._id = this.id.toString();
    }
  }

}
