import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { FitRatingResponse, JobDetails } from 'src/app/shared/interfaces/job.interface';

@Injectable()
export class JobMockService {

  constructor() {
  }

  /**
   * Get the detail of a job
   * @param id Job's id
   */
  getJobDetails(id: string): Observable<SenecaResponse<JobDetails>> {
    return new Observable(obsevable => {

      const jobDetails: JobDetails = {
        user: {
          applied: false,
          fitsPointStar: 100
        },
        info: {
          publicationDate: new Date(2021, 1, 18, 16, 0, 0, 0)
        },
        fitJob: {
          title: 'Titolo',
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis varius quam quisque id.

          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis varius quam quisque id.`,
          fitQuestions: [
            {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: false,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: true,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: false,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: true,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: false,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: true,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: false,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: true,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: false,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }, {
              description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed viverra tellus in hac habitasse platea dictumst?',
              multipleResponse: true,
              responses: [
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
              ]
            }
          ]
        }
      };

      if (id === '1234567') {
        jobDetails.user.userFitScore = 50;
      } else if (id === '123456789') {
        jobDetails.user.userFitScore = 75;
      }

      obsevable.next({
        error: null,
        response: jobDetails
      });
      obsevable.complete();
    });
  }

  /**
   * Apply to a job
   * @param id Job's id
   */
  applyToJob(id: string): Observable<SenecaResponse<any>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {

        }
      });
      obsevable.complete();
    });
  }

  /**
   * Check of much the user is fitted with the vacancy
   * @param id Job's id
   */
  checkFitRating(id: string): Observable<SenecaResponse<FitRatingResponse>> {
    return new Observable(obsevable => {
      let ratingResponse: SenecaResponse<FitRatingResponse> = {
        error: null,
        response: {
          rate: 50
        }
      };

      if (id === "123456789") {
        ratingResponse.response.rate = 75;
      }

      obsevable.next(ratingResponse);
      obsevable.complete();
    });
  }

}

