import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpEvent, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { CourseItem, GalleryItem, EventDocument, TalentFarmSenecaResponse } from "atfcore-commonclasses/bin/classes/talent-farm";

import { UrlService } from "src/app/shared/services/url.service";
import { ContentArea, handleContentAreas, handleEventTypes, handleFromRecord, handleNumRecords, handleSearchedText, handleSorting, handleTagIds } from "src/app/shared/utils";

@Injectable()
export class EventsService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Retrieves available events that matches the criteria
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {string[]} tagIds - Optional: TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true, retrieves only the events which the user has saved for later
   * @param {string | string[]} eventTypes - Optional: ItemType(s) to use to filter on the events
   * @param {boolean} skipFutureEvents - Optional: If true, only past events will be retrieved
   * @param {boolean} skipPastEvents - Optional: If true, only future events will be retrieved
   * @return {number}
   */
  countEvents(contentArea?: string, searchedText?: string, tagIds?: string | string[], eventTypes?: string | string[],
    mySavedOnly?: boolean, pastEvents?: boolean, nextEvents?: boolean, profiledForMe?: boolean): Observable<SenecaResponse<number>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleEventTypes(httpParams, eventTypes);
    if(contentArea && contentArea.length) {
      httpParams = handleTagIds(httpParams, contentArea);
    }

    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    if((nextEvents && pastEvents) || (!nextEvents && !pastEvents)) {
    }
    else if (nextEvents && !pastEvents) {
      httpParams = httpParams.append('skipPastEvents', 'true');
    } else if (!nextEvents && pastEvents) {
      httpParams = httpParams.append('skipFutureEvents', 'true');
    }

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-events', { params: httpParams });
  }

  /**
   * Send the feedback survey of the event
   * @param id Event's id
   */
  sendFeedbackSurvey(itemId: string, vote: number) {
    return this.http.post<SenecaResponse<CourseItem>>(this._mediatorUrl + 'judge-event', { itemId: itemId, vote: vote });
  }

  /**
   * Count the documents of the event
   * @param id Event's id
   */
  countEventDocuments(eventId: string): Observable<SenecaResponse<number>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('eventId', eventId);

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-event-documents', { params: httpParams });
  }

  /**
   * Get the documents of the event
   * @param id Event's id
   */
  getEventDocuments(eventId: string): Observable<SenecaResponse<EventDocument[]>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('eventId', eventId);
    httpParams = httpParams.append('allData', 'true');

    return this.http.get<SenecaResponse<EventDocument[]>>(this._mediatorUrl + 'list-event-documents', { params: httpParams });
  }

  /**
   * Count the gallery of the event
   * @param id Event's id
   */
  countEventGallery(itemId: string): Observable<SenecaResponse<any>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<SenecaResponse<CourseItem>>(this._mediatorUrl + 'count-event-gallery-official-media', { params: httpParams });
  }

  /**
   * Get the gallery of the event
   * @param id Event's id
   */
  getEventGallery(itemId: string): Observable<SenecaResponse<any>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    httpParams = httpParams.append('allData', 'true');

    return this.http.get<SenecaResponse<CourseItem>>(this._mediatorUrl + 'list-event-gallery-official-media', { params: httpParams });
  }

  /**
   * Retrieves the specified event
   * @param {string} itemId - Required: Event's itemId
   * @return {CourseItem}
   */
  getEventById(itemId: string): Observable<TalentFarmSenecaResponse<CourseItem>> {

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<TalentFarmSenecaResponse<CourseItem>>(this._mediatorUrl + 'get-event-by-id', { params: httpParams });
  }

  /**
   * Retrieves available events that matches the criteria
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string} sorting - Optional:  Flag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc) (default D if no filter, S otherwise)
   * @param {string[]} tagIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true, retrieves only the events which the user has saved for later
   * @param {string | string[]} eventTypes - Optional: ItemType(s) to use to filter on the events
   * @param {boolean} skipFutureEvents - Optional: If true, only past events will be retrieved
   * @param {boolean} skipPastEvents - Optional: If true, only future events will be retrieved
   * @return {CourseItem[]}
   */
  listEvents(contentArea?: string, fromRecord?: number, numRecords?: number, searchedText?: string, tagIds?: string | string[],
    eventTypes?: string | string[], mySavedOnly?: boolean, pastEvents?: boolean, nextEvents?: boolean,
    sorting?: string, allData?: boolean, itemIdsToIgnore?: string, profiledForMe?: boolean): Observable<SenecaResponse<CourseItem[]>> {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = handleSearchedText(httpParams, searchedText);
    if(sorting) {
      if(sorting == 'S') {
        httpParams = httpParams.append('profiledForMe', 'true')
      } else {
      httpParams = httpParams.append('orderBy', sorting);
      }
    }
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleEventTypes(httpParams, eventTypes);
    if(contentArea && contentArea.length) {
      httpParams = handleTagIds(httpParams, contentArea);
    }

    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;

    if((nextEvents && pastEvents) || (!nextEvents && !pastEvents)) {
    }
    else if (nextEvents && !pastEvents) {
      httpParams = httpParams.append('skipPastEvents', 'true');
    } else if (!nextEvents && pastEvents) {
      httpParams = httpParams.append('skipFutureEvents', 'true');
    }

    if(itemIdsToIgnore && itemIdsToIgnore.length) {
      httpParams = httpParams.append('itemIdsToIgnore', itemIdsToIgnore)
    }

    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    return this.http.get<SenecaResponse<CourseItem[]>>(this._mediatorUrl + 'list-events', { params: httpParams });
  }

  /**
   * Retrieves available Events that matches the criteria
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string[]} tagIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true, retrieves only the events which the user has saved for later
   * @param {string | string[]} eventTypes - Optional: ItemType(s) to use to filter on the events
   * @param {boolean} skipFutureEvents - Optional: If true, only past events will be retrieved
   * @param {boolean} skipPastEvents - Optional: If true, only future events will be retrieved
   * @return {CourseItem[]}
   */
  listSuggestedEvents(contentArea?: ContentArea, fromRecord?: number, numRecords?: number, searchedText?: string, tagIds?: string | string[],
    eventTypes?: string | string[], mySavedOnly?: boolean, pastEvents?: boolean, nextEvents?: boolean,
    sorting?: string): Observable<SenecaResponse<CourseItem[]>> {

    let httpParams: HttpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleEventTypes(httpParams, eventTypes);
    httpParams = handleContentAreas(httpParams, contentArea);

    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;

    if((nextEvents && pastEvents) || (!nextEvents && !pastEvents)) {
    }
    else if (nextEvents && !pastEvents) {
      httpParams = httpParams.append('skipPastEvents', 'true');
    } else if (!nextEvents && pastEvents) {
      httpParams = httpParams.append('skipFutureEvents', 'true');
    }

    return this.http.get<SenecaResponse<CourseItem[]>>(this._mediatorUrl + 'list-suggested-events', { params: httpParams });
  }

  /**
   * Save for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  saveEventForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'save-event-for-later', { itemId });
  }

  /**
   * Remove for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  removeEventFromSavedForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'remove-event-from-saved-for-later', { itemId });
  }

  /**
   *
   * @param {string} editionId - Required
   * @param {string} notes - Optional: Status notes
   * @return {boolean}
   */
  userSelfCancelFromEvent(editionId: string, notes?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'user-self-cancel-from-event', { editionId, notes });
  }

  /**
   *
   * @param {string} editionId - Required
   * @return {boolean}
   */
  userSelfConfirmToEvent(editionId: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'user-self-confirm-to-event', { editionId });
  }

  /**
   *
   * Retrieves the gallery media items that referes to the specified event
   * @param {string} itemId - Required: Event's id
   * @return {number}
   */
  countEventGalleryMedia(itemId: string): Observable<SenecaResponse<number>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);
    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-event-gallery-participants-media', { params: httpParams });
  }

  /**
   * Retrieves the gallery media items that referes to the specified event
   * @param {string} itemId - Required: Event's id
   * @param {number} fromRecord - Optional
   * @param {number} numRecords - Optional
   * @param {boolean} allData - Optional
   * @return {GalleryItem[]}
   */
  listEventGalleryMedia(itemId: string, allData: boolean, fromRecord?: number, numRecords?: number): Observable<SenecaResponse<GalleryItem[]>> {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    return this.http.get<SenecaResponse<GalleryItem[]>>(this._mediatorUrl + 'list-event-gallery-participants-media', { params: httpParams });
  }

  /**
   * Uploads a DAM item that will be associated to the selected event's gallery.
   * By default, those entries must be moderated by the platform's administrators and won't be immediatly visible.
   * @param {string} itemId - Required: Item's id
   * @param {string} title - Required: The title of item
   * @param {string} objectType - Optional : objectType for item (VIDEO)
   * @param {number} durationInMinutes Optional: duration of video
   * @return {void}
   */
  uploadEventGalleryMediaForUser(itemId: string, file: File, title?: string, objectType?: string,
    durationInMinutes?: number): Observable<HttpEvent<SenecaResponse<void>>> {
    const form = new FormData();
    form.append('itemId', itemId);
    form.append("file", file);
    form.append("title", title);

    if (objectType) {
      form.append("objectType", objectType);
    }
    if (durationInMinutes) {
      form.append("durationInMinutes", durationInMinutes.toString());
    }

    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'upload-event-gallery-media-for-user', form, {
      reportProgress: true, observe: "events"
    });
  }

  // Compra un contenuto premium oppure un premio da riscattare
  reedemPrize(itemId: string) {
    return this.http.post<SenecaResponse<number>>(this._mediatorUrl + 'redeem-prize', { itemId: itemId });
  }

  // Scarica il certificato
  downloadCertificate(userId: string, itemId: string, editionId: string, asImage?: boolean) {
    let httpParams = new HttpParams();
        httpParams = httpParams.append('userId', userId);
        httpParams = httpParams.append('stageId', itemId);
        httpParams = httpParams.append('getFullPath', 'false');
        if(editionId && editionId.length) {
          httpParams = httpParams.append('editionId', editionId);
        }
        if (asImage) {
            httpParams = httpParams.append('asImage', asImage.toString());
        }
        return this.http.get<SenecaResponse<string>>(this._mediatorUrl + 'get-user-initiative-certificate-for-public', {
            params: httpParams
        });
  }

  getDownloadTempFileUrl(filename: string, ssorqtp: string) {
    return this._mediatorUrl + 'download-temp-file?filename=' + filename + '&ssortkqp=' + ssorqtp;
}

}