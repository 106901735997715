<div class="select-list-header cursor-pointer" fxLayout="row" fxLayoutAlign="space-between center"
  (click)="arrowClick()" *ngIf="!_noHeader">
  <h6>{{ title }}</h6>
  <div class="select-list-header-arrow">
    <svg-icon [ngClass]="{'opened': _isOpen}" src="../../../../assets/icons/icon-arrow-down.svg"></svg-icon>
  </div>
</div>

<ng-container *ngIf="_isLoading">
  <generali-loader></generali-loader>
</ng-container>

<ng-container *ngIf="!_isLoading && (_isOpen || _noHeader)">
  <div *ngIf="_multiple" fxLayout="column" fxLayoutAlign="start">
    <!-- Select all -->
    <custom-checkbox *ngIf="_hasSelectAll && _shadowItems && _shadowItems.length > 0" avoidLabelClick
      [(simpleValue)]="_isSelectedAll" (onChangeValue)="_isSelectedAll ? selectAll() : unselectAll()">
      <h6 class="cursor-pointer select-text">{{ selectAllLabel || 'Select all' }}</h6>
    </custom-checkbox>

    <custom-checkbox *ngFor="let item of _shadowItems; let index = index; trackBy: trackBy" avoidLabelClick
      [(simpleValue)]="item.selected" (onChangeValue)="itemChange(item)"
      [disabled]="(_hasSelectAll && _isSelectedAll) || item.disabled"
      [fxFlexOffset]="index === 0 && !_hasSelectAll ? '0px' : '24px'">
      <ng-container [ngTemplateOutlet]="selectLabel" [ngTemplateOutletContext]="{item: item}"></ng-container>
    </custom-checkbox>

  </div>

  <div *ngIf="!_shadowItems || _shadowItems.length === 0">
    <h6 class="cursor-pointer select-text">{{ 'Nessun dato trovato' }}</h6>
  </div>

  <div *ngIf="!_multiple" fxLayout="column" fxLayoutAlign="start">

    <custom-radio *ngFor="let item of _shadowItems; let index = index; trackBy: trackBy" [(simpleValue)]="item.selected"
      [name]="_id" (onChangeValue)="itemChange(item)" [fxFlexOffset]="index === 0 && !_hasSelectAll ? '0px' : '24px'">
      <ng-container [ngTemplateOutlet]="selectLabel" [ngTemplateOutletContext]="{item: item}"></ng-container>
    </custom-radio>

  </div>
</ng-container>

<ng-template #selectLabel let-item='item'>
  <h6 *ngIf="!_labelToTranslate" class="cursor-pointer select-text">{{ item.label }}</h6>
  <h6 *ngIf="_labelToTranslate" class="cursor-pointer select-text">{{ item.label | translate}}</h6>
</ng-template>