import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { GeneraliCarouselComponent } from 'generali';
import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';
import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { ModalService } from 'src/app/core/modal/modal-services.component';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { AuthService } from '../services/auth.service';
import { AnagService } from '../../core/services/anag.service';
import { FilterItem } from 'src/app/shared/components/select/select.component';
import { GenderTypes } from 'atfcore-commonclasses/bin/classes/anag';
import { getUserFirstContact, getUserGender } from 'src/app/shared/utils';
import { PasswordVerifier, UserAccountTypes, SenecaResponse } from 'atfcore-commonclasses';
import * as ProfileActions from '../../profile/ngrx/profile.actions';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent extends BaseSubscriberComponent implements AfterViewInit {
  isAuthenticated: boolean;
  showLoader: boolean;

  _isSignin = false;
  scrollToRight: Subject<void> = new Subject<void>();
  carouselIndex: number = 0;

  //#region Signin form
  signinForm: FormGroup;

  tinyTokenResponse: string;

  get email() {
    return this.signinForm && this.signinForm.get('email') as FormControl;
  }

  get password() {
    return this.signinForm && this.signinForm.get('password') as FormControl;
  }

  get passwordCheck() {
    return this.signinForm && this.signinForm.get('passwordCheck') as FormControl;
  }

  //#endregion

  //#region User Data form

  userDataForm: FormGroup;

  get name() {
    return this.userDataForm && this.userDataForm.get('name') as FormControl;
  }

  get surname() {
    return this.userDataForm && this.userDataForm.get('surname') as FormControl;
  }

  /**
   * Set the max date to today - hope is not a new baby the one is trying to signin! :)
   * And people with less than 16 y.o. can't get profiled
   */
  _birthdayMaxDate: Date = new Date();

  get birthday() {
    return this.userDataForm && this.userDataForm.get('birthday') as FormControl;
  }

  get gender() {
    return this.userDataForm && this.userDataForm.get('gender') as FormControl;
  }

  get education() {
    return this.userDataForm && this.userDataForm.get('education') as FormControl;
  }

  get field() {
    return this.userDataForm && this.userDataForm.get('field') as FormControl;
  }
  fieldInputText: boolean = false;

  get contact() {
    return this.userDataForm && this.userDataForm.get('contact') as FormControl;
  }

  get zipCode() {
    return this.userDataForm && this.userDataForm.get('zipCode') as FormControl;
  }

  consentsForm: FormGroup;

  get privacy() {
    return this.consentsForm && this.consentsForm.get('privacy') as FormControl;
  }

  get privacyAnalyticsConfirm() {
    return this.consentsForm && this.consentsForm.get('privacyAnalyticsConfirm') as FormControl;
  }

  get privacyDataSharingConfirm() {
    return this.consentsForm && this.consentsForm.get('privacyDataSharingConfirm') as FormControl;
  }

  get newsletters() {
    return this.consentsForm && this.consentsForm.get('newsletters') as FormControl;
  }

  @ViewChild("signinCarousel") signinCarousel: GeneraliCarouselComponent;

  //#endregion

  genderList: FilterItem[] = [
    { id: GenderTypes.FEMALE, text: 'Donna' },
    { id: GenderTypes.MALE, text: 'Uomo' },
    { id: GenderTypes.OTHER, text: 'Non mi riconosco in nessuna delle due opzioni' },
    { id: GenderTypes.NOT_SPECIFIED, text: 'Preferisco non rispondere' }
  ];

  titleStudyList: FilterItem[] = [
    { id: "1", text: 'Laurea Triennale' },
    { id: "2", text: 'Laurea Magistrale' },
    { id: "3", text: 'Master I livello' },
    { id: "4", text: 'Master II livello ' },
    { id: "5", text: 'Dottorato/MBA' },
    { id: "6", text: 'Altro' }
  ];

  fieldStudyList: FilterItem[] = [
    { id: "1", text: 'Umanistico e Sociale' },
    { id: "2", text: 'Giuridico' },
    { id: "3", text: 'Economico' },
    { id: "4", text: 'Matematico, Fisico e Statistico' },
    { id: "5", text: 'Informatico' },
    { id: "6", text: 'Altro * (specifica quale)' }
  ];

  contactList: FilterItem[] = [
    { id: "1", text: 'Sito Aziendale' },
    { id: "2", text: 'Eventi Generali' },
    { id: "3", text: 'Social' },
    { id: "4", text: 'Università' },
    { id: "5", text: 'Networking' },
    { id: "6", text: 'Altro' }
  ];

  isConfirmPrivacyData: boolean;

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private redirectService: RedirectService,
    private modalService: ModalService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private anagService: AnagService,
    private toastr: ToastrService,
    private router: Router) {
    super();

    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Accedi o Registrati");

    this._birthdayMaxDate = moment().subtract(16, 'year').toDate();

    this.isConfirmPrivacyData = this.router && this.router.url && this.router.url.indexOf("confirmPrivacyData") !== -1;

    if (!this.isConfirmPrivacyData) {
      // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
      this.store.select(fromApp.isAuthenticated)
        .pipe(takeUntil(this.unsubscribe$.asObservable()))
        .subscribe((isAuthenticated) => {
          if (isAuthenticated) {
            this.goToHome();
          }
        });
    }

    this.signinForm = new FormGroup({
      'email': new FormControl(undefined, [Validators.required, Validators.email]),
      'password': new FormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
        this.passwordValidator()
      ]),
      'passwordCheck': new FormControl(undefined, [
        Validators.required,
        this.matchPasswordValidator()
      ])
    });

    this.userDataForm = new FormGroup({
      'name': new FormControl(undefined, Validators.required),
      'surname': new FormControl(undefined, Validators.required),
      'birthday': new FormControl(undefined, Validators.required),
      'gender': new FormControl(undefined, Validators.required),
      'education': new FormControl(undefined, Validators.required),
      'field': new FormControl(undefined, Validators.required),
      'zipCode': new FormControl(undefined, [Validators.required,
      Validators.minLength(5), Validators.maxLength(5), Validators.pattern('[0-9]*')]),
      'contact': new FormControl(undefined, Validators.required)
    });

    this.consentsForm = new FormGroup({
      'privacy': new FormControl(false, Validators.requiredTrue),
      'privacyAnalyticsConfirm': new FormControl(false),
      'privacyDataSharingConfirm': new FormControl(false, Validators.requiredTrue),
      'newsletters': new FormControl(false)
    });

    this.field.valueChanges
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(field => {
        if (field && field.id === '6') {
          this.field.setValue(undefined);
          this.fieldInputText = true;
        }
      });
  }

  ngAfterViewInit() {
    this.onScrollEvent(this.carouselIndex);

    if (this.isConfirmPrivacyData) {
      this.store.select(fromApp.getLoggedUser)
        .pipe(takeUntil(this.unsubscribe$.asObservable()))
        .subscribe((loggedUser) => {
          setTimeout(() => {
            this.signinCarousel.toIndexNoSmooth(1);
            this.modalService.open('signinModal');
            // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
            this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Conferma Privacy dopo Social Login");
            this._isSignin = true;

            let userGender = getUserGender(loggedUser);
            let gender = undefined;
            if (userGender) {
              for (let i = 0, dataLength = this.genderList.length; i < dataLength; i++) {
                if (this.genderList[i].id == userGender) {
                  gender = this.genderList[i];
                  break;
                }
              }
            }

            let userContact = getUserFirstContact(loggedUser);
            let contact = undefined;
            if (userContact) {
              for (let i = 0, dataLength = this.contactList.length; i < dataLength; i++) {
                if (this.contactList[i].id == userContact) {
                  contact = this.contactList[i];
                  break;
                }
              }
            }

            const forename = loggedUser && loggedUser.user && loggedUser.user.forename || undefined;
            const birthDate = loggedUser && loggedUser.user && loggedUser.user.birthDate || undefined;
            const surname = loggedUser && loggedUser.user && loggedUser.user.surname || undefined;
            const zipCode = loggedUser && loggedUser.user && loggedUser.user.zipCode || undefined;

            this.name.setValue(forename);
            this.surname.setValue(surname);
            this.birthday.setValue(birthDate);
            this.gender.setValue(gender);
            this.zipCode.setValue(zipCode);
            this.contact.setValue(contact);
            /* this.userDataForm.disable(); */
          }, 500);
        });
    }
  }

  actionModalButton() {
    switch (this.carouselIndex) {
      // Signin
      case 0:
        // Registrazione
        this.scrollToRight.next();
        break;
      // User data
      case 1:
        this.scrollToRight.next();
        break;
      // Privacy
      case 2:
        this.scrollToRight.next();
        break;
      // Coins e badge
      case 3:
        const degree: string = this.education.value && (this.education.value as FilterItem).id;
        const contact: string = this.contact.value && (this.contact.value as FilterItem).id;
        const gender: string = this.gender.value && (this.gender.value as FilterItem).id;
        const qualification: string = (this.field.value && (this.field.value as FilterItem).id) || this.field.value;

        // Rimossa chiamata a setMyPrivacyData
        const serviceToUse = this.authService.registerFromMail(
          this.email.value,
          this.password.value,
          this.name.value,
          this.surname.value,
          this.birthday.value,
          gender,
          degree,
          qualification,
          this.zipCode.value,
          contact,
          this.newsletters.value,
          this.privacy.value,
          this.privacyDataSharingConfirm.value,
          this.privacyAnalyticsConfirm.value
        );
        serviceToUse
          .pipe(takeUntil(this.unsubscribe$.asObservable()))
          .subscribe(data => {
            if (data.error) {
              this.toastr.error(this.translate.instant("errors." + data.error));
            } else {
              if (this.isConfirmPrivacyData) {
                this.setUserData();
              } else
                if (!this.isConfirmPrivacyData) {
                  this.scrollToRight.next();
                }
            }
          }, (err) => {
            if (err) {
              this.toastr.error(err.message || err);
            }
          });
        break;
      case 4:
        this.goToHome();
        if (this.tinyTokenResponse) {
          this.updateUserInfoFromTinyToken(this.tinyTokenResponse);
          this.store.dispatch(new AuthActions.RetrieveUserAcknowledges());
          this.tinyTokenResponse = null;
        }
        break;
      // Newsletters
      case 5:
        this.goToHome();
        if (this.tinyTokenResponse) {
          this.updateUserInfoFromTinyToken(this.tinyTokenResponse);
          this.store.dispatch(new AuthActions.RetrieveUserAcknowledges());
          this.tinyTokenResponse = null;
        }
        break;
    }
  }

  setUserData() {
    const degree = this.education.value && this.education.value.id;
    const contact = this.contact.value && this.contact.value.id;
    const gender = this.gender.value && this.gender.value.id;
    const qualification: string = (this.field.value && (this.field.value as FilterItem).id) || this.field.value;

    this.anagService.setMyUserData(
      this.name.value,
      this.surname.value,
      this.zipCode.value,
      degree,
      this.birthday.value,
      this.privacy.value,
      null,
      gender,
      contact,
      qualification,
      this.newsletters.value,
      null,
      null,
      this.privacyDataSharingConfirm.value,
      this.privacyAnalyticsConfirm.value)
      .subscribe(
        (tinyToken: SenecaResponse<string>) => {
          if (tinyToken.error) {
            this.toastr.error(this.translate.instant("errors." + tinyToken.error));
          } else {
            this.tinyTokenResponse = tinyToken.response;
            this.scrollToRight.next();
          }
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
        });
  }

  updateUserInfoFromTinyToken(tinyToken: string) {
    // Update the token
    this.store.dispatch(new AuthActions.SetToken(tinyToken));
    this.authService.getFullJWTToken(tinyToken)
      .subscribe(
        (tokenObj: any) => {
          if (tokenObj.error) {
            this.toastr.error(this.translate.instant("errors." + tokenObj.error));
          } else {
            this.store.dispatch(new ProfileActions.DecodeToken(tokenObj.response));
          }
        }, (err) => {
          if (err) {
            this.toastr.error(err.message || err);
          }
        });
  }

  /**
   * Listen of the scroll event of carousel for update tabindex
   */
  onScrollEvent(index: number) {

    this.carouselIndex = index;

    // First tab
    let noRegistrationFirst = document.getElementById("no-registration-first");
    if (noRegistrationFirst) {
      noRegistrationFirst.setAttribute("tabindex", index === 0 ? "5" : "-1");
    }

    // Third tab
    let privacySignin = document.getElementById("privacy-signin");
    if (privacySignin) {
      privacySignin.setAttribute("tabindex", index === 1 ? "2" : "-1");
    }

  }

  getCurrentTabIndex(index: number): string {
    return index === this.carouselIndex ? "0" : "-1";
  }

  /**
   * Check the password
   * The password should be with a minimum of 8 characters, a number and a special characters
   */
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (!this.signinForm) {
        return null;
      }

      if (this.passwordCheck !== null) {
        this.passwordCheck.updateValueAndValidity();
      }

      let validity: string = control.value;
      if (validity !== null && validity !== undefined && validity.match(/(?=.*["'()+,-./:;<=>?^_`{|}~!@#$%^&*\])(?=.*[0-9])/g)) {
        let passwordVerifier = new PasswordVerifier();
        let passCheck = passwordVerifier.check(control.value);
        if (!passCheck.strong) {
          return { 'error-composition': { value: control.value } };
        } else {
          return null;
        }
      } else return { 'error-composition': { value: control.value } };
    };
  }

  /**
   * Check that the two password are the same
   */
  matchPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (!this.signinForm) {
        return null;
      }

      const password: string = this.password.value;
      const match = password === control.value;
      return match ? null : { 'no-match': { value: control.value } };
    };
  }

  /**
   * Dal form passato come parametro
   * Ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
   */
  onLogin() {
    this.showLoader = true;
    this.store.dispatch(new AuthActions.DoLogin({ email: this.email.value, password: this.password.value }));
    this.showLoader = false;
  }

  /**
   * Open the modal for start the signin
   */
  openSigninModal() {
    this.signinCarousel.toIndexNoSmooth(0);
    this.modalService.open('signinModal');
    this._isSignin = true;
    // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
    this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Registrazione");
  }

  closeSigninModal() {
    this.modalService.close('signinModal');
  }

  /**
   * Close the signin modal
   */
  closeModalSignin(): void {
    if (this.carouselIndex === 0) {
      this.closeSigninModal();
      this._isSignin = false;
      this.resetDatas();
    } else if (this.carouselIndex === 4) {
      this.goToHome();
    } else {
      this.goWithoutDataModal();
    }
  }

  /**
   * Open the modal when the user want to proceed without data registration
   */
  goWithoutDataModal(): void {
    this.modalService.open('signinModalWithoutData');
  }

  /**
   * Close the modal
   */
  closeWithoutDataModal(): void {
    this.modalService.close('signinModalWithoutData');
  }

  /**
   * Continue the registration without the user datas
   */
  continueSigninWithoutData() {
    this.closeWithoutDataModal();
    this.carouselIndex = 0;
    this.closeModalSignin();
  }

  resetDatas() {
    this.email.setValue(undefined);
    this.password.setValue(undefined);
    this.passwordCheck.setValue(undefined);
    this.name.setValue(undefined);
    this.surname.setValue(undefined);
    this.birthday.setValue(undefined);
    this.gender.setValue(undefined);
    this.education.setValue(undefined);
    this.field.setValue(undefined);
    this.zipCode.setValue(undefined);
    this.contact.setValue(undefined);
    this.privacy.setValue(false);
    this.privacyDataSharingConfirm.setValue(false);
    this.privacyAnalyticsConfirm.setValue(false);
    this.newsletters.setValue(false);
  }

  /**
   * Go to login page
   */
  goToLogin() {
    this.closeSigninModal();
    this.redirectService.goToLogin();
  }

  /**
   * Go home
   */
  goToHome() {
    const redirectAfterSignIn = sessionStorage.getItem('redirectAfterSignIn');

    this.closeSigninModal();
    if (redirectAfterSignIn) {
      sessionStorage.removeItem('redirectAfterSignIn');
      this.router.navigate([redirectAfterSignIn]);
    } else {
      this.redirectService.goToHome();
    }
  }

  /**
   * Go to privacy page
   */
  goToPrivacy(event) {
    event.stopImmediatePropagation();
    this.redirectService.goToPrivacy();
  }

  goToTermsOfUse(event) {
    event.stopImmediatePropagation();
    this.redirectService.goToTermsOfUse();
  }
}
