import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { SigninComponent } from '../signin/signin.component';

@Injectable()
export class CanDeactivateConfirmDataService implements CanDeactivate<SigninComponent> {
  constructor() { }

  canDeactivate(component: SigninComponent): boolean {
    if (!component.isConfirmPrivacyData) {
      return true;
    } else {
      return component.privacy && component.privacy.value;
    }
  }
}