import { ItemAttributeObjectTypes, ItemChild, ItemRegistration } from "atfcore-commonclasses/bin/classes/item";
import { ScormRegistration } from "atfcore-commonclasses/bin/classes/scorm";

export const CKEditorConfigLibraryObjects = {
  allowedContent: true,
  autoParagraph: false,
  height: 317,
  enterMode: 2,
  toolbar: [
    {
      name: 'document',
      groups: ['mode', 'document', 'doctools', 'undo', 'basicstyles', 'cleanup', 'list', 'indent', 'blocks', 'align'],
      items: [
        'Undo', 'Redo', 'Bold', 'Italic', 'Underline'
      ]
    }
  ]
};

export const PL_COVERS = [
  {
    itemId: 27,
    url: '/assets/img/img-card/playlist/playlist-1.png',
    title: 'cover-playlist1',
    fromPredefiniteSet: true
  },
  {
    itemId: 28,
    url: '/assets/img/img-card/playlist/playlist-2.png',
    title: 'cover-playlist2',
    fromPredefiniteSet: true
  },
  {
    itemId: 29,
    url: '/assets/img/img-card/playlist/playlist-3.png',
    title: 'cover-playlist3',
    fromPredefiniteSet: true
  },
  {
    itemId: 30,
    url: '/assets/img/img-card/playlist/playlist-4.png',
    title: 'cover-playlist4',
    fromPredefiniteSet: true
  },
  {
    itemId: 31,
    url: '/assets/img/img-card/playlist/playlist-5.png',
    title: 'cover-playlist5',
    fromPredefiniteSet: true
  },
  {
    itemId: 32,
    url: '/assets/img/img-card/playlist/playlist-6.png',
    title: 'cover-playlist6',
    fromPredefiniteSet: true
  }
];

export const objectTypesToOpen = [ItemAttributeObjectTypes.PODCAST,
ItemAttributeObjectTypes.BOOK,
ItemAttributeObjectTypes.IMAGE,
ItemAttributeObjectTypes.VIDEO];
export const
  objectTypesToDownload = [ItemAttributeObjectTypes.DOCUMENT, ItemAttributeObjectTypes.GRAPH, ItemAttributeObjectTypes.EBOOK];


export class GlpItem {
  itemId: string;
  title?: string;
  subtitle?: string;
  originApplicationName: string;
  itemIdLinkedToCourse?: string;
  description?: string;
  clusterColor?: string;
  clusterTitle?: string;
  itemType?: string;
  objectType?: string;
  objectTypeSpecialization?: string;
  minutesValue?: string;
  completionPercentage?: string;
  wishlistEnabled?: boolean;
  addedToWishlist?: boolean;
  totalWishlist?: number;
  deadlineDate?: string;
  availableSeats?: string;
  landingLayout?: string;
  argumentTitle?: any;
  courseSyllabus: {
    AGENDA: string,
    AIMS: string,
    DURATION: string,
    PREREQS: string,
    APPROACH: string,
    PARTNER: string,
    TECH_COMPETENCES: string[],
    SOFT_COMPETENCES: string[],
    WAVE: string[],
    ARGUMENTS: string[],
    SAP_MANDATORY_COURSE_CODE?: string
  };
  syllabusId: string;
  pmList: any[];
  cardCoverUrl?: string;
  itemChilds?: ItemChild[];
  certificationDate?: string;
  scormRegistration?: ScormRegistration;
  averageLikingScore?: string;
  isCourseCertificationEnabled?: string;
  itemRegistration?: ItemRegistration;
  damObjectUrl?: string;
  isDam?: string;
  isScorm?: string;
  embeddedPlayerCode?: string;
  isCertified?: boolean;
  childrenCount?: string;
  isBookmarked?: boolean;
  techCompetences?: string;
  softCompetences?: string;
  argumentsTags?: string;
  clustersTags?: string;
  functionalAreaTag?: string;
  adminTag?: string;
  levelTag?: string;
  streamTag?: string;
  lang?: string[];
  linkUrls?: string | any; // Sembra che il tipo cambi a seconda di quale servizio del core si chiami
  damPlayerSeekTo
  suggestedList
  isItemPublic
  hasRentableAttribute?: string;
  hasItemPropedeuticCourse?: string;
  it9054propedeuticCourseValue?: string;
  rolePropedeuticCourseValue?: string;
  isSoftSkill?: string;
  isTechSkill?: string;
  editor?: string[];
  tutor?: string;
  tutorEmail?: string;
  helpdeskEmail?: string;
  publishYear?: string;
  publishLocation?: string;
  publishCountry?: string;
  editionYear?: string;
  isbn?: string;
  director?: string;
  shelf?: string;
  quantity?: string;
  collocation?: string;
  professionalProfile?: string;
  customArguments?: string[];
  author?: string[];
  isDeadlinePassed?: boolean;
  firstDayDate?: string;
  lastDayDate?: string;
  isProject?: boolean;
  isLearningPlan?: boolean;
  isItemOtherOrPhysicalType?: boolean;
  isVideo?: boolean;
  isImage?: boolean;
  isBook?: boolean;
  isEBook?: boolean;
  isGraph?: boolean;
  isMagazine?: boolean;
  isDvd?: boolean;
  isDocument?: boolean;
  isPodcast?: boolean;
  requireSingletonCheck?: boolean;
  courseUrl?: string;
  courseTitle?: string;
  webCourseSyllabus?: string;
  courseImage?: string;
  courseHostingPlatform?: string;
  webItemChilds: any[];
  isCompleted?: boolean;
  isConsumed?: boolean;
  isVerified?: boolean;
  scormId?: string;
  isMine?: boolean;
  isMandatoryTraining?: boolean;
  privacy: any;
  enableCertificationExternalSharing?: boolean;
  editionIdsWithDownloadedCertificate?: string[];
  editionIdsWithSharedCertificate?: string[];
  judgement?;
  myJudgement?;
}