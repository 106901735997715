import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { JobInformation } from "atfcore-commonclasses/bin/classes/coursemanager";

import { UrlService } from "src/app/shared/services/url.service";
import { handleFromRecord, handleJobTypes, handleLocations, handleNumRecords, handleSearchedText } from "src/app/shared/utils";
import { TalentFarmSenecaResponse } from 'atfcore-commonclasses';

export interface Filter { tagId:string, count: number, name: string }

@Injectable()
export class TaleoService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Retrieves the available jobs listings through a Taleo's API
   * @param {string} searchedText - Optional: String used to filter on the titles
   * @param {string | string[]} locations - Optional: Locations used to filter on the available jobs
   * @param {string | string[]} jobTypes - Optional: JobTypes used to filter on the available jobs
   * @param {string} sorting - Optional: Sort to apply
   * @param {number} fromRecord - Required (if allData is false): Pagination's offset
   * @param {number} numRecords - Required (if allData is false): Pagination's size
   * @param {boolean} allData - Optional (if fromRecord and numRecords are defined): All data will be retrieved
   * @return {number} Counter of the parsed jobs info
   */
  countAvailableJobs(searchedText?: string, locations?: string | string[],
    jobTypes?: string | string[], mySavedOnly?: boolean, sorting?: string, profiledForMe?: boolean): Observable<SenecaResponse<number>> {

    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.append('allData', 'true');
    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;

    if(sorting && sorting.length) {
      httpParams = httpParams.append('sorting', sorting);
    }

    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleLocations(httpParams, locations);
    httpParams = handleJobTypes(httpParams, jobTypes);

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-available-jobs', { params: httpParams });
  }

  /**
   * Retrieves the names of the locations of the available jobs
   * @param {string} searchedText - Optional: String used to filter on the titles
   * @param {string | string[]} jobTypes - Optional: JobTypes used to filter on the available jobs
   * @return {Filter[]} Array with the available locations names
   */
  getAvailableJobsLocations(searchedText?: string, jobTypes?: string | string[]): Observable<SenecaResponse<Filter[]>> {

    let httpParams: HttpParams = new HttpParams();
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleJobTypes(httpParams, jobTypes);

    return this.http.get<SenecaResponse<Filter[]>>(this._mediatorUrl + 'get-available-jobs-locations', { params: httpParams });
  }

  /**
   * Retrieves the names of the types of the available jobs
   * @param {string} searchedText - Optional: String used to filter on the titles
   * @param {string | string[]} locations - Optional: Locations used to filter on the available jobs
   * @return {Filter[]} Array with the available types names
   */
  getAvailableJobsTypes(searchedText?: string, locations?: string | string[]): Observable<SenecaResponse<Filter[]>> {

    let httpParams: HttpParams = new HttpParams();
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleLocations(httpParams, locations);

    return this.http.get<SenecaResponse<Filter[]>>(this._mediatorUrl + 'get-available-jobs-types', { params: httpParams });
  }

  /**
   * Retrieves the available jobs listings through a Taleo's API
   * @param {string} searchedText - Optional: String used to filter on the titles
   * @param {string | string[]} locations - Optional: Locations used to filter on the available jobs
   * @param {string | string[]} jobTypes - Optional: JobTypes used to filter on the available jobs
   * @param {number} fromRecord - Required (if allData is false): Pagination's offset
   * @param {number} numRecords - Required (if allData is false): Pagination's size
   * @param {boolean} allData - Optional (if fromRecord and numRecords are defined): All data will be retrieved
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @return {PaginationWrapper<JobInformation>} Object with the parsed jobs info which has been retrieved and the total counter
   */
  getAvailableJobs(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string, locations?: string | string[],
    jobTypes?: string | string[], mySavedOnly?: boolean, sorting?: string, isEvidenceForMe?: boolean): Observable<SenecaResponse<JobInformation[]>> {

    let httpParams: HttpParams = new HttpParams();
    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    if(sorting && sorting.length) {
      httpParams = httpParams.append('sorting', sorting);
    }

    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleLocations(httpParams, locations);
    httpParams = handleJobTypes(httpParams, jobTypes);

    if(isEvidenceForMe) {
      httpParams = httpParams.append('profiledForMe', 'true');
    }

    return this.http.get<SenecaResponse<JobInformation[]>>(this._mediatorUrl + 'get-available-jobs', { params: httpParams });
  }

  /**
   * Retrieves a specific job using its contestId
   * @param {string} contestId - Required: Job's contest id
   * @return {JobInformation} The requested JobInformation object
   */
  getJobByContestId(contestId: string): Observable<TalentFarmSenecaResponse<JobInformation>> {
    return this.http.get<TalentFarmSenecaResponse<JobInformation>>(this._mediatorUrl + 'get-job-by-contestId', {
      params: {
        contestId: contestId
      }
    });
  }

  candidateOnJob(contestId: string): Observable<SenecaResponse<boolean>> {
    return this.http.get<SenecaResponse<boolean>>(this._mediatorUrl + 'track-user-job-application', {
      params: {
        contestId: contestId
      }
    });
  }

  /**
   * Save for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  saveJobForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'save-job-for-later', { itemId });
  }

  /**
   * Remove for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  removeJobFromSavedForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'remove-job-from-saved-for-later', { itemId });
  }

}
