import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'long-text',
  templateUrl: './long-text.component.html',
  styleUrls: ['./long-text.component.scss']
})
export class LongTextComponent implements OnInit {

  @Input() text: string;

  @Input() limit: number = 315;

  @Input() customClass: any;

  _hideShowMore: boolean;
  @Input() set hideShowMore(value: boolean | string) {
    this._hideShowMore = coerceBooleanProperty(value);
  };

  _readMore: boolean;
  @Input() set readMore(value: boolean | string) {
    this._readMore = coerceBooleanProperty(value);
    this.checkTextToShow();
  };

  _textToShow: string;
  _cuttedText = false;

  constructor() {

  }

  ngOnInit() {
    this.checkTextToShow();
  }

  checkTextToShow() {
    if (this._readMore) {
      this.uncutText();
    } else {
      this.cutText();
    }
  }

  cutText() {
    this._cuttedText = this.text.length > this.limit;
    this._textToShow = this._cuttedText ? this.text.slice(0, this.limit) : this.text;
  }

  uncutText() {
    this._cuttedText = false;
    this._textToShow = this.text;
  }

  readMoreClick() {
    this._cuttedText = false;
    this._textToShow = this.text;
  }

}
