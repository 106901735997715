<div class="space20"></div>

<div class="content">
  <!-- <a class="link-privacy" (click)="goBack()">&lt; Indietro</a> -->
  <div class="space20"></div>

  <div class="header-img">
    <div class="logo-home" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="start start">
        <img height="70" width="100,44" title="generaliLogo" src="../../../assets/img/generali/logo-generali.svg">
      </div>
    </div>
  </div>
  <h5>&nbsp;</h5>
  <h5><strong>Una volta fatta la registrazione, perché non ricevo le istruzioni per confermare l’accesso?</strong></h5>

  <h5>È probabile che l’email di conferma iscrizione sia finita nella cartella spam. Ti suggeriamo di verificare.
  </h5>
  <h5>Se non ricevi alcun messaggio, ti invitiamo a contattare il supporto all’indirizzo email <a
      href="mailto:helpdesk@generalilearningplatform.com">helpdesk@generalilearningplatform.com</a></h5>
  <h5>&nbsp;</h5>

  <h5><strong>Utente non trovato: email e password non sono state inserite correttamente</strong></h5>
  <h5>Per poter accedere in piattaforma è necessario che l’indirizzo email scelto in fase di registrazione sia la stessa
    con la quale si sta tentando ad accedere in piattaforma.</h5>
  <h5>Se invece hai dimenticato la password, reimpostala. Per farlo utilizza la sezione “Password dimenticata” che trovi
    <a href="https://candidatejourneycontest.generalilearningplatform.com/#/intro">qui</a></h5>
  <h5>&nbsp;</h5>

  <h5><strong>Perché non riesco a visualizzare l’attestato che ho scaricato?</strong></h5>
  <h5>I documenti scaricabili dalla piattaforma sono in formato PDF. Per visualizzarli occorre aver installato
    l’apposito programma di lettura dei file PDF (ad esempio Adobe Reader).</h5>
  <h5>&nbsp;</h5>

  <h5><strong>Perché non riesco a condividere su LinkedIn il certificato di partecipazione?</strong></h5>
  <h5>Per segnalare eventuali anomalie legate alla condivisione del certificato su LinkedIn, ti invitiamo a contattare
    il servizio di supporto all’indirizzo email <a
      href="mailto:helpdesk@generalilearningplatform.com">helpdesk@generalilearningplatform.com</a></h5>
  <h5>&nbsp;</h5>

  <h5>&nbsp;</h5>
  <h5>&nbsp;</h5>

</div>
