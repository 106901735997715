

export const dateFormats: string[] = ['MMMM YYYY', 'DD/MM/YYYY', 'DD MMMM YYYY', 'DD-MM-YYYY'];
export const dateTimeFormats: string[] = ['DD/MM/YYYY HH:mm'];
export const timeFormats: string[] = ['HH:mm'];

const dateLocales = {
    'it': {},
    'en': {}
};

export function dateLocaleByLang(lang: string) {
    // default is it locale
    return dateLocales[lang] || {};
}

export function copyToClipboard(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
}
