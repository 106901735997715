import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ItemChild, ItemTypes } from 'atfcore-commonclasses';
import { parseBoolean } from 'src/commonclasses';

@Component({
  selector: 'course-card-list-item',
  templateUrl: './course-card-list-item.component.html',
  styleUrls: ['./course-card-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseCardListItemComponent {
  @Input() itemId: string;
  @Input() categoryTitle: string;
  @Input() title: string;
  @Input() courseType: string;
  @Input() courseTypeClass?: string;
  @Input() itemTypeLabel: string;
  @Input() hourValueText?: string;
  @Input() cardCover?: string;
  @Input() cardLabel?: string;
  @Input() clusterName?: string;
  @Input() argumentName?: string;
  @Input() clusterColorBackground?: string;
  @Input() headerDetail?: string;
  @Input() addedToWishlistDate?: string;
  @Input() headerTitleColor?: string;
  @Input() objectsNumber?: string;
  @Input() completionPercentage?: string;
  @Input() headerBackground?: string;
  @Input() competences?: string;
  @Input() isOnline?: boolean;

  @Input() showPropedeuticLabel: boolean;
  @Input() showRequiredLabel: boolean;
  @Input() isItemLocked: boolean;
  @Input() singleItemChild: ItemChild;

  @Input() isCompleted?: boolean;
  @Input() isVerified?: boolean;
  @Input() isCM?: boolean;

  @Input() sectionObj;

  @Output() onButtonClicked: EventEmitter<{ itemId: string, isItemLocked?: boolean, singleItemChild?: ItemChild, sectionObj?}> = new EventEmitter();
  limit: number = 130;

  isAssessment: boolean;
  isCatalogCourse: boolean;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    this.isCatalogCourse = this.courseType && this.courseType === ItemTypes.CATALOG_STAGE;
    this.isCM = parseBoolean(this.isCM);
    this.isVerified = parseBoolean(this.isVerified);
    this.isCompleted = parseBoolean(this.isCompleted);
    if (this.categoryTitle.indexOf('undefined') >= 0 || this.categoryTitle.indexOf('null') >= 0) {
      this.categoryTitle = this.translate.instant('itemDetailsPage.generic_card.OBJECT');
    }
    if (this.categoryTitle && this.categoryTitle === this.translate.instant('generic.itemTypes.ASSESSMENT')) {
      this.isAssessment = true;
    }
  }

  emitOnButtonClicked() {
    if (this.onButtonClicked && this.onButtonClicked instanceof EventEmitter) {
      let navigationData = {
        itemId: this.itemId,
        isItemLocked: this.isItemLocked,
        singleItemChild: this.singleItemChild,
        sectionObj: this.sectionObj
      }
      this.onButtonClicked.emit(navigationData);
    }
  }
}
