import { Component, ElementRef, OnInit } from '@angular/core';
import { ModalBadge, ModalBadgeId } from 'src/app/shared/interfaces/modal-badge.interface';
import { ModalService } from '../modal/modal-services.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-modal-badge',
  templateUrl: './modal-badge.component.html',
  styleUrls: ['./modal-badge.component.scss']
})

export class ModalBadgeComponent extends ModalComponent implements OnInit {

  readonly modalBadgeProfile: ModalBadge = {
    id: ModalBadgeId.PROFILE,
    iconSrc: '../../../assets/icons/badge/icon-new-badge.svg',
    title: 'badge.PROFILE',
    coins: 500
  };

  readonly modalBadgeJob: ModalBadge = {
    id: ModalBadgeId.JOB,
    iconSrc: '../../../assets/icons/badge/icon-new-badge-green.svg',
    title: 'badge.JOB',
    coins: 100
  };

  readonly modalBadgeCup: ModalBadge = {
    id: ModalBadgeId.CUP,
    iconSrc: '',
    title: '',
    coins: 500
  };

  currentModal: ModalBadge;
  _idStar: string;

  constructor(private _modalService: ModalService, private _el: ElementRef) {
    super(_modalService, _el);
  }

  ngOnInit(): void {

    this._idStar = "modal-badge-star-" + Math.floor((Math.random() * 10000) + 1);

    switch (this.id) {
      case ModalBadgeId.PROFILE:
        this.currentModal = this.modalBadgeProfile;
        break;
      case ModalBadgeId.JOB:
        this.currentModal = this.modalBadgeJob;
        break;
      case ModalBadgeId.CUP:
        this.currentModal = this.modalBadgeCup;
        break;
    }

  }

  closeModalBadge() {
    this._modalService.close(this.id);
    setTimeout(() => {
      this._modalService.open(this._idStar);
    }, 300);
  }

  closeModalBadgeStar() {
    this._modalService.close(this._idStar);
  }

}
