<div class="search-page page-padding" fxLayout="column">

  <div class="content-search" fxFlexOffset="80px" fxLayout="row" fxLayoutAlign="start start">

    <!-- Ricerca generale -->
    <div *ngIf="searchParams" fxFlex class="search-page-content" fxLayout="row" fxLayoutAlign="end center">
      <input-search class="full-width" [placeholder]="'header.SEARCH_PLACEHOLDER' | translate"
        [bindValue]="searchParams.searchedText" (onModelChanged)="onSearchedTextChanged($event)"
        (onStartSearch)="onInputSubmit($event)">
      </input-search>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start"
    class="result-label-container" *ngIf="showPageResultTitle()">
    <!-- Risultati per x -->
    <ng-container *ngIf="searchedTextValid()">
      <h3>{{ ('search.results.RESULTS_FOR') | translate: { value: searchParams.searchedText } }}</h3>
    </ng-container>

    <!-- Risultati -->
    <ng-container *ngIf="!searchedTextValid()">
      <h3 translate="search.results.RESULTS_NO_TEXT"></h3>
    </ng-container>

    <!-- x risultati -->
    <h6 *ngIf="!isFetchingLearningSystemCourses && !isFetchingWebCourses && !isFetchingViewAllCourses">{{
      ('search.results.RESULT' +
      (totalCoursesCounter !== 1 ? 'S' : '')) | translate:
      {
      value: totalCoursesCounter } }}</h6>
  </div>

  <!-- Filtri -->
  <div class="filters" [ngClass]="{ 'with-margin-top' : showPageResultTitle() }"
    *ngIf="!isLoadingFilters && searchParams">
    <!-- Categorie -->
    <custom-select-with-checkbox *ngIf="searchParams.categoriesFilter" class="categories" multiple
      [placeholderLabel]="'generic.CATEGORIES_TITLE' | translate"
      [selectedValue]="searchParams.categoriesFilter.selected"
      (selectedValueChange)="onFilterChanged($event, searchParams.categoriesFilter)"
      [items]="searchParams.categoriesFilter.options" bindLabel="title" bindValue="tagId">
    </custom-select-with-checkbox>

    <!-- Durata -->
    <custom-select-with-checkbox *ngIf="showDurationFilter()" class="duration" multiple
      [placeholderLabel]="'generic.DURATION_FILTER' | translate" [selectedValue]="searchParams.durationsFilter.selected"
      (selectedValueChange)="onFilterChanged($event, searchParams.durationsFilter)"
      [items]="searchParams.durationsFilter.options" bindLabel="label" bindValue="label">
    </custom-select-with-checkbox>

    <!-- Tipologia -->
    <custom-select-with-checkbox *ngIf="showTypologyFilter()" class="type" multiple
      [placeholderLabel]="'generic.TYPE_FILTER' | translate" [selectedValue]="searchParams.typesFilter.selected"
      (selectedValueChange)="onFilterChanged($event, searchParams.typesFilter)"
      [items]="searchParams.typesFilter.options" bindLabel="title" bindValue="tagId">
    </custom-select-with-checkbox>

    <!-- Ordine -->
    <custom-select-with-checkbox *ngIf="showSortinFilter()" class="order"
      [placeholderLabel]="'generic.ORDER_FILTER' | translate" [selectedValue]="searchParams.sortByFilter.selected"
      (selectedValueChange)="onFilterChanged($event, searchParams.sortByFilter)"
      [items]="searchParams.sortByFilter.options" bindLabel="title" bindValue="tagId">
    </custom-select-with-checkbox>
  </div>

  <div class="space-70"></div>

  <div class="search-page-results" fxLayout="column" *ngIf="searchParams">

    <!-- Carousel dei corsi Generali e dei corsi dal Web -->
    <ng-container *ngIf="!isViewAll && !searchParams.showAllGeneraliResults && !searchParams.showAllWebCoursesResults">
      <div class="full-width">

        <!-- Corsi Generali Academy -->
        <generali-card-carousel *ngIf="learningSystemCoursesData" showNoLimits
          sectionId="card-carousel-search-generaliAcademy" [itemCount]="learningSystemCoursesData.counter"
          [title]="'searchPage.GENERALI_ACADEMY_COURSES' | translate" showNoLimits
          [cardList]="learningSystemCoursesData.coursesForCarousel" [isLoadingItems]="isFetchingLearningSystemCourses"
          (onCardClick)="goToItemDetails ($event, true, 'searchPage.GENERALI_ACADEMY_COURSES')"
          (viewAllTap)="viewAllTap('generaliCourses')" [differentTypesForCard]="true" type="cm2-course"
          (onToggleBookmark)="onToggleBookmark($event)" [showAllCards]="true">
        </generali-card-carousel>

        <div class="space-70"></div>

        <!-- Corsi dal web -->
        <generali-card-carousel *ngIf="webCoursesData" showNoLimits sectionId="card-carousel-search-coursesFromWeb"
          [itemCount]="webCoursesData.counter" [title]="'searchPage.COURSES_FROM_WEB' | translate" showNoLimits
          [cardList]="webCoursesData.coursesForCarousel" [isLoadingItems]="isFetchingWebCourses"
          (onCardClick)="goToItemDetails($event, true, 'searchPage.COURSES_FROM_WEB')"
          [translatedCourseType]="'generic.itemTypes.OF_COURSE_ME_COURSE' | translate"
          (viewAllTap)="viewAllTap('webCourses')" type="item-of-web-collection"
          (onToggleBookmark)="onToggleBookmark($event)" [showAllCards]="true" [titleOnlyOneRow]="false">
        </generali-card-carousel>
      </div>

    </ng-container>

    <!-- Vedi tutti i corsi di Generali (pagina di ricerca principale) -->
    <div fxLayout="column" *ngIf="searchParams.showAllGeneraliResults">
      <div fxLayout="column" class="view-all-container">

        <!-- Titolo -->
        <div class="header-title-container" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="all-items-title">
            {{ 'searchPage.GENERALI_ACADEMY_COURSES' | translate }}
          </span>
          <!-- Contataore dei risultati -->
          <h5 *ngIf="!isFetchingLearningSystemCourses" class="all-items-result-number">
            {{
            ('search.results.RESULT' +
            (learningSystemCoursesData.counter !== 1 ? 'S' : '')) | translate:
            {
            value: learningSystemCoursesData.counter } }}
          </h5>
        </div>

        <!-- Loader -->
        <div *ngIf="isFetchingLearningSystemCourses">
          <div class="space-70"></div>
          <generali-loader></generali-loader>
        </div>

        <div *ngIf="learningSystemCoursesData && learningSystemCoursesData.courses && !isFetchingLearningSystemCourses"
          fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px" fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center" fxLayout.xs="column">
          <!-- Lista di card -->
          <generali-card [id]="'generali-course-' + i"
            [translatedCourseType]="'generic.itemTypes.OF_COURSE_ME_COURSE' | translate" *ngFor="let course of learningSystemCoursesData.courses  | paginate: { id: 'generaliCourses',
                itemsPerPage: learningSystemCoursesData.numRecords,
                currentPage: learningSystemCoursesData.page,
                totalItems: learningSystemCoursesData.counter }; let i = index;" [card]="course"
            [type]="course.cardType" (onCardClick)="goToItemDetails($event)"
            (onToggleBookmark)="onToggleBookmark($event)" [isSwitchingBookmark]="course.isSwitchingBookmark"
            [isBookmarked]="course.isBookmarked">
          </generali-card>
        </div>

        <!-- Paginazione -->
        <div *ngIf="!isFetchingLearningSystemCourses && learningSystemCoursesData && learningSystemCoursesData.counter"
          fxLayout="row" fxLayoutAlign="center center">
          <table-pagination [forceDisabled]="isFetchingLearningSystemCourses" [id]="'generaliCourses'"
            (pageChanged)="generaliCoursesPageChanged($event)">
          </table-pagination>
        </div>
      </div>
    </div>

    <!-- Vedi tutti i corsi dal web  (pagina di ricerca principale) -->
    <div fxLayout="column" *ngIf="searchParams.showAllWebCoursesResults">
      <div fxLayout="column" class="view-all-container">

        <!-- Titolo -->
        <div class="header-title-container" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="all-items-title">
            {{ 'searchPage.COURSES_FROM_WEB' | translate }}
          </span>
          <!-- Contataore dei risultati -->
          <h5 *ngIf="!isFetchingWebCourses" class="all-items-result-number">
            {{
            ('search.results.RESULT' +
            (webCoursesData.counter !== 1 ? 'S' : '')) | translate:
            {
            value: webCoursesData.counter } }}
          </h5>
        </div>

        <!-- Loader -->
        <div *ngIf="isFetchingWebCourses">
          <div class="space-70"></div>
          <generali-loader></generali-loader>
        </div>

        <div *ngIf="webCoursesData && webCoursesData.courses && !isFetchingWebCourses" fxLayout="row wrap"
          fxLayoutAlign="start center" fxLayoutGap="8px" fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center" fxLayout.xs="column">
          <!-- Lista di card -->
          <generali-card [id]="'web-course-' + i"
            [translatedCourseType]="'generic.itemTypes.OF_COURSE_ME_COURSE' | translate" *ngFor="let course of webCoursesData.courses  | paginate: { id: 'webCourses',
                itemsPerPage: webCoursesData.numRecords,
                currentPage: webCoursesData.page,
                totalItems: webCoursesData.counter }; let i = index;" [card]="course"
            (onCardClick)="goToItemDetails($event)" (onToggleBookmark)="onToggleBookmark($event)"
            [isSwitchingBookmark]="course.isSwitchingBookmark" [isBookmarked]="course.isBookmarked"
            type="item-of-web-collection">
          </generali-card>
        </div>

        <!-- Paginazione -->
        <div *ngIf="!isFetchingWebCourses && webCoursesData && webCoursesData.counter" fxLayout="row"
          fxLayoutAlign="center center">
          <table-pagination [forceDisabled]="isFetchingWebCourses" [id]="'webCourses'"
            (pageChanged)="webCoursesPageChanged($event)">
          </table-pagination>
        </div>
      </div>

    </div>

    <!-- Risultati di ricerca del "Vedi tutti" (ad es. quando arrivo da un carousel in home) -->
    <div fxLayout="column" *ngIf="isViewAll">
      <div fxLayout="column" class="view-all-container">

        <!-- Titolo -->
        <div class="header-title-container" fxLayout="row" fxLayoutAlign="space-between center">
          <span class="all-items-title">
            {{ ('searchPage.viewAllTitles.' + viewAllType) | translate }}
          </span>
          <!-- Contataore dei risultati -->
          <h5 *ngIf="!isFetchingViewAllCourses" class="all-items-result-number">
            {{
            ('search.results.RESULT' +
            (viewAllCoursesData.counter !== 1 ? 'S' : '')) | translate:
            {
            value: viewAllCoursesData.counter } }}
          </h5>
        </div>

        <!-- Loader -->
        <div *ngIf="isFetchingViewAllCourses">
          <div class="space-70"></div>
          <generali-loader></generali-loader>
        </div>

        <div *ngIf="viewAllCoursesData && viewAllCoursesData.courses && !isFetchingViewAllCourses" fxLayout="row wrap"
          fxLayoutAlign="start center" fxLayoutGap="8px" fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center" fxLayout.xs="column">
          <!-- Lista di card corsi generali -->
          <ng-container *ngIf="viewAllType !== 'WEB_COLLECTIONS'">
            <generali-card [id]="'view-all-courses-' + i"
              [translatedCourseType]="'generic.itemTypes.OF_COURSE_ME_COURSE' | translate" *ngFor="let course of viewAllCoursesData.courses  | paginate: { id: 'viewAllCourses',
                itemsPerPage: viewAllCoursesData.numRecords,
                currentPage: viewAllCoursesData.page,
                totalItems: viewAllCoursesData.counter }; let i = index;" [card]="course" [type]="course.cardType"
              (onCardClick)="goToItemDetails($event)" (onToggleBookmark)="onToggleBookmark($event)"
              [isSwitchingBookmark]="course.isSwitchingBookmark" [isBookmarked]="course.isBookmarked">
            </generali-card>
          </ng-container>

          <!-- Lista di card corsi dal web -->
          <ng-container *ngIf="viewAllType === 'WEB_COLLECTIONS'">
            <generali-card [id]="'view-all-courses-' + i"
              [translatedCourseType]="'generic.itemTypes.OF_COURSE_ME_COURSE' | translate" *ngFor="let course of viewAllCoursesData.courses  | paginate: { id: 'viewAllCourses',
                itemsPerPage: viewAllCoursesData.numRecords,
                currentPage: viewAllCoursesData.page,
                totalItems: viewAllCoursesData.counter }; let i = index;" [card]="course"
              (onCardClick)="goToItemDetails($event)" (onToggleBookmark)="onToggleBookmark($event)"
              [isSwitchingBookmark]="course.isSwitchingBookmark" [isBookmarked]="course.isBookmarked"
              type="web-collection">
            </generali-card>
          </ng-container>
        </div>

        <!-- Paginazione -->
        <div *ngIf="!isFetchingViewAllCourses && viewAllCoursesData && viewAllCoursesData.counter" fxLayout="row"
          fxLayoutAlign="center center">
          <table-pagination [forceDisabled]="isFetchingViewAllCourses" [id]="'viewAllCourses'"
            (pageChanged)="viewAllCoursesPageChanged($event)">
          </table-pagination>
        </div>
      </div>
    </div>

    <!-- Nessun dato trovato -->
    <div *ngIf="noDataFound()" fxLayoutGap.xs="5px" fxLayout="column" class="no-results-container"
      fxLayoutAlign="center center" fxLayoutGap="32px">
      <svg-icon fxHide.xs fxShow src="assets/icons/img-not-found.svg"></svg-icon>
      <svg-icon fxShow.xs fxHide src="assets/icons/img-not-found-mob.svg"></svg-icon>
      <p class="no-result-text" translate="generic.NO_RESULT_FOUND"></p>
      <p class="no-result-description" translate="generic.NO_RESULT_FOUND_DESCRIPTION"></p>
    </div>

  </div>

  <div class="space-133">
  </div>
</div>