import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ExpandableItem } from '../../interfaces/expandable-list.interface';

@Component({
  selector: 'expandable-list',
  templateUrl: './expandable-list.component.html',
  styleUrls: ['./expandable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableListComponent {
  @Input() list: ExpandableItem[];

  constructor() {
  }

  openCloseItem(index: number) {
    if (this.list[index].expanded) {
      this.closeItem(index);
    } else {
      this.openItem(index);
    }
  }

  openItem(index: number) {
    for (let i = 0; i < this.list.length; i++) {
      this.list[i].expanded = index === i;
    }
  }

  trackBy(index: any, item: any) {
    return index;
  }

  closeItem(index: number) {
    this.list[index].expanded = false;
  }

}
