import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { ForumPostsResponse } from 'src/app/shared/interfaces/forum.interface';

@Injectable()
export class ForumMockService {
  constructor() {
  }

  /***
   * Get the forum posts
   * @param searchText Text for research
   */
  getForumPosts(searchText: string): Observable<SenecaResponse<ForumPostsResponse>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {
          posts: [
            {
              id: "1234678",
              title: 'Come ricevere il certificato?',
              author: 'Marco Bianchi',
              isOwnPost: false,
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis. Lorem ipsum dolor sit amet, consecte et dolor  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris.`,
              likes: 4,
              isStaff: true,
              when: new Date(2021, 0, 18, 15, 0, 0, 0),
              isLiked: false,
              srcImage: '',
              answers: []
            }, {
              id: "1234678",
              title: 'Corso per principianti?',
              author: 'Laura Moretti',
              isOwnPost: true,
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis. Lorem ipsum dolor sit amet, consecte et dolor  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris.`,
              likes: 1,
              isStaff: false,
              when: new Date(2021, 0, 18, 15, 0, 0, 0),
              isLiked: false,
              srcImage: '',
              answers: [
                {
                  id: "1234678",
                  author: 'Matteo Sala',
                  when: new Date(2021, 0, 18, 15, 0, 0, 0),
                  srcImage: '',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis.'
                }
              ]
            }, {
              id: "1234678",
              title: 'Dubbi sul corso',
              author: 'Mariaserena Rossi',
              isOwnPost: false,
              text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis. Lorem ipsum dolor sit amet, consecte et dolor  sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris.`,
              likes: 2,
              isStaff: false,
              when: new Date(2021, 0, 11, 15, 0, 0, 0),
              isLiked: true,
              srcImage: '',
              answers: [
                {
                  id: "1234678",
                  author: 'Matteo Sala',
                  when: new Date(2021, 0, 18, 15, 0, 0, 0),
                  srcImage: '',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis.'
                }, {
                  id: "1234678",
                  author: 'Matteo Sala',
                  when: new Date(2021, 0, 18, 15, 0, 0, 0),
                  srcImage: '',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis.'
                }, {
                  id: "1234678",
                  author: 'Matteo Sala',
                  when: new Date(2021, 0, 18, 15, 0, 0, 0),
                  srcImage: '',
                  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis.'
                }
              ]
            }
          ]
        }
      });
      obsevable.complete();
    });
  }

  /***
   * Post a new post on the forum
   * @param title Title of the post
   * @param description Description of the post - max 800 chars
   */
  newPost(title: string, description: string): Observable<SenecaResponse<any>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {

        }
      });
      obsevable.complete();
    });
  }

  /***
   * Hide a post on the forum
   * @param postId Post's id to hide
   */
  hidePost(postId: string): Observable<SenecaResponse<any>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {

        }
      });
      obsevable.complete();
    });
  }
}

