import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, map, tap, switchMap, filter } from 'rxjs/operators';

// Import dello State dell'applicativo
import * as fromApp from '../../ngrx/app.reducers';
import { RequiredAuth } from 'atfcore-commonclasses';
import { onceIfNotEmpty } from '../utils';

@Injectable()
export class WomenInsuranceNetworkGuard implements CanActivate {
    constructor(private store: Store<fromApp.AppState>, private route: ActivatedRoute, private router: Router) {
    }

    // Ritorna un Observable che, risolvendo alla fine un boolean, possiamo mapparlo
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.store.select(fromApp.isAuthenticated)
            .pipe(
                filter((isAuthenticated) => isAuthenticated),
                switchMap(() => {
                    return this.store.select(fromApp.getLoggedUser);
                }),
                onceIfNotEmpty(),
                map(loggedUser => {
                    if (loggedUser && loggedUser.auths && loggedUser.auths.length) {
                        for (let i = 0, authsLength = loggedUser.auths.length; i < authsLength; i++) {
                            let currentAuth = loggedUser.auths[i];
                            if (currentAuth === RequiredAuth.GTP_WOMEN_INSURANCE_NETWORK) {
                                return true;
                            }
                        }
                    }

                    return false;
                })
            )
    }
}