import { ModalComponent } from "./modal.component";

/*
* Servizio che gestisce le modali
*/

export class ModalService {
  constructor() {
  }

  // Elenco di modali attive
  private modals: ModalComponent[] = [];

  // Aggiunge la modale al contenitore di tutte le modali
  add(modal: ModalComponent) {
    this.modals.push(modal);
  }

  // Rimuove una specifica modale dalla lista di quelle attiva
  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  clearClassForScroll() {
    document.body.classList.remove('modal-open');
  }

  /**
   * Open a modal page
   * @param id modal's id
   */
  open(id: string) {
    if (!id) {
      return;
    }
    let modal: ModalComponent = this.modals.filter(x => x.id === id)[0];
    if (modal._loginRequired && !modal._isAuthenticated) {
      // Apre la modale di login
      this.openModalLogin();
    } else {
      modal.open();
    }
  }

  /**
   * Close a modal
   * @param id modal's id
   */
  close(id: string) {
    let modal: ModalComponent = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }

  /**
   * Open the modal for ask the login
   */
  openModalLogin() {
    let modalLogin: ModalComponent = this.modals.filter(x => x.id === "modalLogin")[0];
    modalLogin.open();
  }

  /**
   * Close the modal for ask the login
   */
  closeModalLogin() {
    let modalLogin: ModalComponent = this.modals.filter(x => x.id === "modalLogin")[0];
    modalLogin.close();
  }

}
