/*
 * Componente per la pagina della privacy security
*/

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
    selector: 'app-privacy-security',
    templateUrl: './privacy-security.component.html',
    styleUrls: ['./privacy-security.component.scss']
})
export class PrivacySecurityComponent implements OnInit {

    pageReady: boolean = false;
    isExternalCallbackUrl = false;

    constructor(private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {

        this.route.queryParams
            .subscribe((params: Params) => {
                this.isExternalCallbackUrl = !!params['ackCallback'];
                if (this.isExternalCallbackUrl) {
                    // renderizza una pagina di passaggio per callback da app esterna
                    this.pageReady = false;
                } else {
                    let action = params['page'];
                    if (!!action) {
                        // Apro il profilo in modalità raccolta privacy
                        this.router.navigate(['/' + action], {
                            queryParams: {
                                fromApp: true
                            }
                        });
                    }
                    setTimeout(() => {
                        this.pageReady = true;
                    }, 200)
                }
            })
    }

}
