<div fxLayout="column" fxLayoutGap="16px" fxLatoytAlign="center center">
  <img height="39px" width="41.9px" src="assets/icons/upload-file-cloud.png">

  <p class="my-1 title" translate="profile.DRAG_FILE"></p>
  <p class="my-1" translate="profile.OR"></p>
  <button class="upload-btn" (click)="fileInput.click()"> {{'profile.CHOOSE_FILE_BTN' | translate | uppercase }}
  </button>

  <div fxHide fxLayout="row" fxLayoutAlign="start center">
    <input #fileInput type="file" [accept]="accepts" (change)="handleFileChange($event)" />
  </div>

  <p class="my-1 semi-bold" *ngIf="selectedFileName">{{selectedFileName}}</p>
</div>
