/*
 * Gestione dei dispatch che devono eseguire qualcosa di asincrono e che non alterano lo State (i così chiamati Effect). Struttura molto simile ai Reducer,
 * che verificano il tipo di azione e concatenano una serie di operatori degli Observable per fare qualcosa. L'unica differenza, appunto, è che qui non
 * andiamo a modificare lo State, gestiamo solo lo Side Effect
*/

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { timer, from, of, Observable } from 'rxjs';
import { map, switchMap, withLatestFrom, takeWhile, mergeMap, catchError } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';
import { Lang } from 'atfcore-commonclasses/bin/classes/anag';
import { Tag, TagInfo } from 'atfcore-commonclasses/bin/classes/tag';

import * as fromCore from './core.reducers';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from './core.actions';
import * as ProfileActions from '../../profile/ngrx/profile.actions';
import * as AuthActions from '../../auth/ngrx/auth.actions';

import { UrlService } from '../../shared/services/url.service';
import { LangsService } from '../services/langs.service';
import { authControl, GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { AuthService } from '../../auth/services/auth.service';
import { ProfilingService } from '../services/profiling.service';
import { AnagService } from '../services/anag.service';
import { ExtendedTagUtil } from 'src/app/shared/models/extended-tag.model';
import { TagService } from '../services/tag.service';

// Injectable perché abbiamo bisogno di importare le action e altri servizi
@Injectable()
export class CoreEffects {
  // actions$ col dollaro per marcare il fatto che è un Observable
  constructor(private actions$: Actions,
    private authService: AuthService,
    private langsService: LangsService,
    private profilingService: ProfilingService,
    private toastr: ToastrService,
    private router: Router,
    private anagService: AnagService,
    private urlService: UrlService,
    private translate: TranslateService,
    private store: Store<fromCore.CoreState>,
    private tagService: TagService) {
  }

  // Di default, inserisco la lingua del browser dell'utente, recupera grazie ad una funzione
  defaultLang: string = this.langsService.getBrowserLang();
  // Verifica se l'utente è autenticato
  isAuthenticated: boolean;
  // Verifica se l'utente è anonimo
  isAnonymous: boolean;
  // Tiny token
  tinyToken: string;
  // Token intero
  tokenObj: any;
  // Check principale sulla privacy
  isMainPrivacyChecked;
  forceRefreshUser?: boolean;
  authObject: any;

  isFirstTime: boolean;

  // Effects che crea un observable (timer) che ogni ora recupera un nuovo token ed esegue il dispatch delle action che lo aggiornano anche nello store
  @Effect()
  startRenewTokenPolling$ = this.actions$.pipe(
    ofType(CoreActions.START_RENEW_TOKEN_POLLING)
    , map((action: CoreActions.StartRenewTokenPolling) => {
      this.forceRefreshUser = action && action.payload && action.payload.forceRefreshUser || false;
      this.isFirstTime = true;
      // Grazie al map, il payload sarà wrappato in un nuovo Observable, così da poter far in seguito la concatenazione di altri operatori
      return action.payload;
    }),
    switchMap(
      () => timer(0, 3600000) // Il primo parametro è il delay iniziale. Nel nostro caso voglio che venga fatto subito, poiché potrebbe essere che l'utente sia già autenticato ma che abbia eseguito un refresh della pagina
        .pipe(
          withLatestFrom(this.store.select(fromApp.isAuthenticated)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello Store per vedere se l'utente è loggato
          , map(([action, isAuthenticated]) => {
            this.isAuthenticated = isAuthenticated;
          })
          , takeWhile(() => this.isAuthenticated), // Continuo con il loop del timer solamente finché l'utente è autenticato. Qualora eseguisse il logout, l'observable si distruggerebbe
          switchMap(() => {
            // L'effect, alla fine, si aspetta sempre di tornare un Observable; per questo convertiamo la Promise in un Observable grazie al metodo from()
            let sessionStorageToken: string = sessionStorage.getItem('token');

            //Se è la prima volta (quindi refresh della pagina oppure nuova tab) ritorno il token
            if(this.isFirstTime && sessionStorageToken){
              this.isFirstTime = false;
              return new Observable(observable => {
                observable.next({
                  error: null,
                  response: sessionStorageToken
                });
                observable.complete();
              });
            }

            //altrimenti lo rinnovo
            if (sessionStorageToken) {
              sessionStorage.removeItem('token');
              return from(this.authService.renewToken(sessionStorageToken));
            } else {
              throw (new Error('TOKEN_NOT_FOUND'));
            }
          })
          , switchMap(
            (tinyTokenObj: SenecaResponse<string>) => {
              if (tinyTokenObj.error) {
                throw (new Error(tinyTokenObj.error));
              } else {
                // Salvo il tiny token. Salvo questo perché è quello che mi servirà nelle chiamate rest, e che utilizzerà quindi l'interceptor
                this.tinyToken = tinyTokenObj.response;
                // Se non ho ricevuto nessun errore nella risposta, procedo con il convertire il tiny token
                return from(this.authService.getJWTToken(tinyTokenObj.response));
              }
            }),
          map((tokenObj: SenecaResponse<string>) => {
            if (tokenObj.error) {
              // Catturo l'errore del servizio del renew del token
              throw (new Error(tokenObj.error));
            } else {
              this.tokenObj = tokenObj.response;
              return this.store.dispatch(new AuthActions.SetToken(this.tinyToken));
            }
          }),
          map(() => {
            return this.store.dispatch(new ProfileActions.DecodeToken(this.tokenObj));
          }),
          switchMap(() => {
            return from(this.anagService.getAllUserAcknowledges());
          }),
          map((ack: any) => {
            const userAck = ack && ack.response;
            this.isMainPrivacyChecked = userAck && userAck.data && userAck.data.TALENT_FARM_PRIVACY_ACK && userAck.data.TALENT_FARM_PRIVACY_ACK.length;
            return this.store.dispatch(new AuthActions.SetUserAcknowledges(userAck));
          }),
          map(() => {
            return this.store.dispatch(new CoreActions.GetCategories());
          }),
          map(() => {
            return this.store.dispatch(new AuthActions.DoLoginFinished());
          }),
          withLatestFrom(this.store.select(fromApp.getLoggedUser))
          , switchMap(([action, loggedUser]) => {
            // Se ho settato l'utente loggato (e quindi decodificato il token) posso settare la lingua di sistema con quella scelta dall'utente
            let langToUse = this.langsService.getUserLang(loggedUser.user);
            // Prima di salvare la lingua dello store applicativo, la imposto anche per il componente che si occupa delle traduzioni
            this.langsService.useLanguage(langToUse);

            let actionsContainer = [{
              type: CoreActions.SET_APPLICATION_LANG,
              payload: langToUse
            },
            {
              type: CoreActions.GET_CLUSTERS
            },
            {
              type: CoreActions.GET_CATEGORIES
            },
            {
              type: AuthActions.RETRIEVE_USER_ACKNOWLEDGES
            },
            {
              type: AuthActions.RETRIEVE_GLP_AUTHS
            },
            {
              type: AuthActions.CHECK_WELCOME_PAGE
            }
            ];
            this.store.dispatch(new CoreActions.HideApplicationLoader({ closeManualLoader: true }));

            /* Logica spostata nella AuthActions.CHECK_WELCOME_PAGE

              const url = sessionStorage.getItem('redirectUrl');

              const redirectAfterSignIn = sessionStorage.getItem('redirectAfterSignIn');
              if (redirectAfterSignIn) {
                sessionStorage.removeItem('redirectAfterSignIn');
                this.router.navigate([redirectAfterSignIn]);
              } else if (url) {
                this.router.navigate([url]);
              }*/

            this.authObject = authControl(loggedUser && loggedUser.auths);
            if (this.authObject.isManager) {
              actionsContainer.push({
                type: AuthActions.SET_IS_MANAGER,
                payload: "true"
              })
            }
            if (this.authObject.isAdmin) {
              actionsContainer.push({
                type: AuthActions.SET_IS_ADMIN,
                payload: "true"
              })
            }
            return actionsContainer;
          })
        )
    )
    , catchError((err, caught) => {
      this.store.dispatch(new CoreActions.HideApplicationLoader({ closeManualLoader: true }));
      // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e
      // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
      // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
      // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
      // con il valore tornato dal catch.
      // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:
      this.translate.setDefaultLang(this.defaultLang);
      this.langsService.useLanguage(this.defaultLang);
      if (err && err.message) {
        // TODO-Alloy: da capire perché non traduce in lingua qua!
        if (err.message === "OLD_TOKEN_NOT_FOUND") {
          this.toastr.error("Sessione scaduta");
          this.store.dispatch(new AuthActions.DoLogout());
        } else {
          if (err.message === "USER_NOT_AUTHORIZED") {
            this.store.dispatch(new AuthActions.DoLogoutNoRedirect());
          }
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
      }
      // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
      return caught;
    })
  )

  // Effect che recupera la lista dei Cluster disponibili
  @Effect()
  initiativesGet = this.actions$
    .pipe(
      ofType(CoreActions.GET_CLUSTERS)
      , withLatestFrom(this.store.select(('clusters')))
      , switchMap(([action, clusters]) => {
        if (!clusters || !clusters.length) {
          return from(this.tagService.findTags("true", null, null, "CLUSTERS"));
        } else {
          // Torno un observable simulando una senecaResponse
          let newSenecaresponse = { response: clusters };
          return Observable.create(obs => {
            obs.next(newSenecaresponse);
            obs.complete();
          })
        }
      })
      , map(
        (clusterResponse: any) => {
          // Salvo i dati della risposta
          if (clusterResponse) {
            if (clusterResponse.error) {
              // Catturo l'errore
              throw (new Error(clusterResponse.error));
            } else {
              // Formatto i Tag prima di salvarli
              ExtendedTagUtil.setClusterClass(clusterResponse.response);
              return {
                type: CoreActions.SET_CLUSTERS,
                payload: clusterResponse.response
              }
            }
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
        this.store.dispatch(new CoreActions.SetClusters([]));
        return caught;
      })
    );

  @Effect()
  // Proprietà dell'Effect di cui NgRX starà in watch eseguendo il codice che gli assegnamo sulla destra.
  // Quindi, per prima cosa, si accede alle action dello Store applicativo (che abbiamo iniettato nel costruttore)
  coreActions$ = this.actions$.pipe(
    ofType(CoreActions.GET_AVAILABLE_LANGS),
    // In questo caso non c'è nessun payload
    withLatestFrom(this.store.select(fromApp.getAvailableLangs)) // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello state per vedere se devo eseguire un redirect dopo il login
    , switchMap(([action, storeLangs]) => {
      // Se ho già le lingue, eseguo il dispatch dell'azione che indica la fine del caricamento delle stesse
      if (storeLangs && storeLangs.length) {
        this.store.dispatch(new CoreActions.GetAvailableLangsFinished());
      } else {
        // Lingue non disponibili nello Store applicativo, quindi chiamo il servizio per recuperarle
        // L'effect, alla fine, si aspetta sempre di tornare un Observable; per questo convertiamo la Promise in un Observable grazie al metodo from()
        return this.langsService.getAvailableLangs();
      }
    })
    , map(
      (senecaResponse: SenecaResponse<Lang[]>) => {
        if (senecaResponse.response) {
          // Qualora abbia ricevuto le lingue in risposta significa che non le ho ancora salvate nello Store applicativo, quindi rimedio.
          // Prima del dispatch dell'action prendo la prima lingua disponibile, che sarà quella utilizzata come fallback qualora le traduzioni non siano presenti o non disponibili
          for (let i = 0, langsLength = senecaResponse.response.length; i < langsLength; i++) {
            if (senecaResponse.response[i] && senecaResponse.response[i].mandatory && senecaResponse.response[i].langCode) {
              this.defaultLang = senecaResponse.response[i].langCode.substring(0, 2);
              break;
            }
          }

          return this.store.dispatch(new ProfileActions.SaveAvailableLangs(senecaResponse.response));
        }
      }
    )
    , withLatestFrom(this.store.select(fromApp.getGlobalApplicationData)) // recupero dello Store l'oggetto principale GlobalApplicationData
    // , take(1) DA NON USARE NELL'EFFECT! Essendo quest'ultimo un singleton, una volta fatto l'unsubscribe tramite il take(1), non farà più il subscribe. Pertanto, se si provasse a fare il dispatch intercettato da questo Effect, non produrrebbe più, appunto, nessun effect e non entrerebbe nel metodo
    , mergeMap(
      ([action, savedGlobalApplicationData]) => {
        // Di default, utilizzo la lingua italiana. Questo comunque è un linguaggio di fallback qualora mancasse e non fosse trovata la lingua settata
        // Pertanto, setto la lingua. In ogni caso, quando l'utente segue il login, recupero la sua lingua, settandola come default all'applicazione
        this.translate.setDefaultLang(this.defaultLang);
        this.langsService.useLanguage(this.defaultLang);

        // Qualora non avessi il globalApplicationData, lo costruisco e lo salvo nello Store applicativo
        if (!savedGlobalApplicationData) {
          // Recupero l'url dell'applicazione, in quanto uno dei parametri dell'oggetto
          let applicationUrl = this.urlService.getApplicationUrl();

          // Ora che ho l'url posso passare alla valorizzazione del GlobalApplicationData
          let newGlobalApplicationData = new GlobalApplicationData(
            applicationUrl.baseUrl,
            '../index.html',
            '../isMaintenance.xml',
            'eTicketing-user/?#/app/eTicketUserApp/eTicketing',
            null,
            false,
            false,
            [],
            [],
            false,
            null,
            null
          );

          // Torno due azioni, una per salvare nello State applicativo il GlobalApplicationData e una per annunciare la fine del recupero delle lingue
          return [
            new CoreActions.SetCoreApplicationData(newGlobalApplicationData),
            new CoreActions.GetAvailableLangsFinished(),
            new CoreActions.SetDefaultLang(this.defaultLang)
          ]
        } else {
          return [
            new CoreActions.GetAvailableLangsFinished()
          ];
        }
      }
    )
    , catchError((err, caught) => {
      // L'errore è una condizione terminale che porrebbe fine all'Observable. Questo interromperebbe il flusso dell'Effect. In lato pratico, significa che se capita un errore, lo stream si interrompe e
      // se cercassi di fare il dispatch dell'azione con l'effect, quest'ultimo non si avvia. Quindi l'utente continua a premere, per esempio, un pulsante e questo non trigghera nessun Effect.
      // Fortunatamente, il catchError() consente di emettere valori personalizzati invece di incrementare l'observer con la callback di errore.
      // La cattura non viene fatta nello stream principale, ma nel flusso interno (quello dello switchMap), quindi l'observable interrotto è quello interno. Quello principale, invece, continua
      // con il valore tornato dal catch.
      // In alternativa, si potrebbe gestire l'errore direttamente all'interno dello switchMap:

      // Di default, utilizzo la lingua del browser dell'utente. Questo comunque è un linguaggio di fallback qualora mancasse e non fosse trovata la lingua settata, che è il nostro caso visto che
      // siamo nel catch dell'errore delle lingue
      this.translate.setDefaultLang(this.defaultLang);
      this.langsService.useLanguage(this.defaultLang);
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      // Segnalo che il servizio delle lingue è finito
      this.store.dispatch(new CoreActions.GetAvailableLangsFinished());
      // Quindi, alla fine, torniamo l'Observable di errore, affinché si possa ri-provare l'operazione
      return caught;
    })
  )

  @Effect()
  // Proprietà dell'Effect di cui NgRX starà in watch eseguendo il codice che gli assegnamo sulla destra.
  // Quindi, per prima cosa, si accede alle action dello Store applicativo (che abbiamo iniettato nel costruttore)
  getCategories$ = this.actions$.pipe(
    ofType(CoreActions.GET_CATEGORIES),
    // In questo caso non c'è nessun payload
    withLatestFrom(this.store.select(fromApp.getCategories)), // combina l'azione con il valore di un altro observable, mi serve per recuperare la parte "core" dello state per vedere se devo eseguire un redirect dopo il login
    switchMap(([action, categories]) => {
      return from(this.profilingService.listCategories(true));
    }),
    map(
      (tags: SenecaResponse<TagInfo[]>) => {
        if (tags.error) {
          this.toastr.error(this.translate.instant("errors." + tags.error));
          throw (new Error(tags.error));
        } else {
          return new CoreActions.SetCategories(tags.response);
        }
      }
    ),
    catchError((err, caught) => {
      if (err && err.message) {
        this.toastr.error(this.translate.instant('errors.' + err.message));
      }
      return caught;
    })
  )
}
