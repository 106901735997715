/*
* Componente che crea una singola Tab
*/

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
  ViewRef
} from '@angular/core';
import { fromEvent, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DropdownMenuItemDirective } from './dropdown-menu-item.directive';

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DropdownMenuComponent implements OnInit, AfterViewInit {

  @ContentChildren(DropdownMenuItemDirective) items: QueryList<DropdownMenuItemDirective>;
  @Input() isDisabled: boolean;
  @Input() contentClass: string;
  @Input() label: string;
  @Input() labelPosition: string;

  _advanced: boolean = false;
  @Input() set advanced(value: boolean | string){
    this._advanced = coerceBooleanProperty(value);
  };

  private listeningGlobalClick: boolean;
  private globalClickSubscription: Subscription;
  private clickOutside = new EventEmitter<any>();
  opened: boolean;
  // utility per sfruttare il takeUntil per fare l'unsubscribe delle subscriptions create nei components
  unsubscribe$ = new Subject();

  constructor(private currentComponent: ElementRef,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.clickOutside.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.opened = false;
      if (!(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    })
  }

  toggleDDContent() {
    this.opened = !this.opened;
    if (this.opened && !this.globalClickSubscription) {
      this.globalClickSubscription = this.globalClickSubscription = fromEvent(document, 'click').pipe(
        takeUntil(this.unsubscribe$)
      )
        .subscribe((event: any) => {
          this.listeningGlobalClick = true;
          this.onGlobalClick(event);
          if (!this.opened && !!this.globalClickSubscription) {
            this.globalClickSubscription.unsubscribe();
            this.globalClickSubscription = null;
            this.listeningGlobalClick = false;
          }
        });
    }
    if (!(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  onGlobalClick(event: MouseEvent) {
    if (event instanceof MouseEvent && this.listeningGlobalClick === true) {
      if (this.isMenuItem(event.target) || !this.isDescendant(this.currentComponent.nativeElement, event.target)) {
        this.clickOutside.emit({
          target: (event.target || null),
          value: true
        });
      }
    }
  }

  isMenuItem(child: any) {
    return child && child.hasAttribute("dropdown-menu-item");
  }

  isDescendant(parent: any, child: any) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }
}
