import { Component, Input, Output, TemplateRef, EventEmitter, OnInit } from '@angular/core';
import { ModalService } from 'src/app/core/modal/modal-services.component';

@Component({
  selector: 'app-description-tab',
  templateUrl: './description-tab.component.html',
  styleUrls: ['./description-tab.component.scss']
})
export class DescriptionTabComponent implements OnInit{

  // Dato che queste sono varibili di tipo primitivo, non posso modificarle direttamente in questo componente altrimenti il padre non ne verrà aggiornato
  // Per sicurezza le setto tutte a readonly ed eventualmente uso degli output corrispondenti
  @Input() readonly isSyllabusCourse: boolean;
  @Input() readonly isExternalCourse: boolean;
  @Input() readonly isCourseWithLimitedSeats: boolean;
  @Input() readonly hasMoreEditions: boolean;
  @Input() readonly isSubscribedToAnEdition: boolean;
  @Input() readonly isOnlineCourse: boolean;
  @Input() readonly isCourseOnlineStage: boolean;
  @Input() readonly isWebinar: boolean;
  @Input() readonly isCatalogStage: boolean;
  @Input() readonly editions: any[];
  @Input() readonly itemDetails: any;
  @Input() readonly stopDateCourseSubscriptionFormat: string;
  @Input() readonly isContentOfPlaylistVisible: boolean;
  @Input() readonly playlistChildsList: TemplateRef<HTMLElement>;
  @Input() readonly itemLinkedToCourse: any;
  @Input() readonly isContentOfProjectsVisible: boolean;
  @Input() readonly duration: number;
  @Input() readonly itemTypeLabel: string;
  @Input() readonly selectedEditionOfMainSelect: any;
  @Input() readonly isGettingItemDetails: boolean;
  @Input() readonly webinarCoursesByDay: any[];
  @Input() readonly isLoadingEditions: boolean;
  @Input() readonly webinarSelectedDay: any;
  @Input() readonly selectedCourseDateId: string;
  @Input() readonly projectChildsList: any;
  @Input() readonly selectedEditionInfo: any;
  @Input() readonly currentWebinarEditions: any[];
  @Input() readonly currentPlaceEditions: any[];
  @Input() readonly selectedPlace: any;
  @Input() readonly editionsByPlace: any[];
  @Input() readonly isGettingParentDetails: boolean;
  @Input() readonly collectionId: string;
  @Input() readonly webCourseId: string;
  @Input() readonly completedItems: number;
  @Input() readonly isItemOnline: boolean;
  @Input() readonly partialScore: number;
  @Input() readonly surveySended: boolean;
  @Input() readonly surveyPoints: number;
  @Input() readonly surveyPointsSelected: number;

  @Output() onChangeWebinarSelectedDay = new EventEmitter<any>();
  @Output() onSelectPlace = new EventEmitter<any>();
  @Output() onOpenCustomLink = new EventEmitter<string>();
  @Output() onSendFeedbackSurvey = new EventEmitter<void>();
  @Output() onSelectedEditionOfMainSelect = new EventEmitter<any>();
  @Output() onSelectedCourseDateId = new EventEmitter<any>();
  @Output() onSurveySended = new EventEmitter<boolean>();
  @Output() onSurveyPoints = new EventEmitter<number>();
  @Output() onSurveyPointsSelected = new EventEmitter<number>();

  constructor(
    private modalService: ModalService
  ) {
  }
  ngOnInit(): void {

  }

  ngOnChanges(val){

}

  // Verifica se è valorizzato il codice IT9054
  isITcodeFilled() {
    return this.isSyllabusCourse && this.itemDetails && this.itemDetails.courseSyllabus && this.itemDetails.courseSyllabus.SAP_MANDATORY_COURSE_CODE;
  }

  // Verifica se mostrare la data di inizio e di fine di un corso online (non lo devo mostrare se ha il codice IT valorizzato)
  showStartEndDate(): boolean {
    if (this.isCourseOnlineStage && this.isITcodeFilled()) {
      return false;
    }

    return true;
  }

  // Cambia il giorno selezionato
  changeWebinarSelectedDay(selectedDay: any): void {
    this.onChangeWebinarSelectedDay.emit(selectedDay);
  }

  selectPlace(place: any): void {
    this.onSelectPlace.emit(place);
  }

  selectWebinarEdition(day) {
    this.onSelectedEditionOfMainSelect.emit(day);
    this.onSelectedCourseDateId.emit(this.selectedEditionOfMainSelect.courseDateId);
  }

  // Selezione una edizione
  selectEditionFromSelect(edition: any) {
    this.onSelectedEditionOfMainSelect.emit(edition);
    this.onSelectedCourseDateId.emit(this.selectedEditionOfMainSelect.courseDateId);
    // Da decommentare se si vuole resettare il certificato in base alla sola edizione selezionata
    // this.resetCertifications([this.selectedEditionOfMainSelect], true, true);
  }

  openCustomLink(link: string): void {
    this.onOpenCustomLink.emit(link);
  }

  // Verifica se mostrare il titolo/link dell'evento online
  showTitleAndLinkOfOnlineEvent() {
    let trimLink;
    let trimLinkTitle;
    if (!this.hasMoreEditions && this.editions && this.editions[0]) {

      if (this.editions[0].onlineEventLink && this.editions[0].onlineEventLink.length) {
        trimLink = this.editions[0].onlineEventLink.trim();
      }

      if (this.editions[0].onlineEventLinkTitle && this.editions[0].onlineEventLinkTitle.length) {
        trimLinkTitle = this.editions[0].onlineEventLinkTitle.trim();
      }
    }
    return (trimLink && trimLink.length) || (trimLinkTitle && trimLinkTitle.length);
  }

  getItemChildrenCount(itemRef) {
    let counter = 0;
    if (itemRef) {
      if (itemRef.isProject) {
        if (itemRef.itemChilds && itemRef.itemChilds.length) {
          for (let i = 0, projectSeciontsLength = itemRef.itemChilds.length; i < projectSeciontsLength; i++) {
            const currentSection = itemRef.itemChilds[i] && itemRef.itemChilds[i].childObject;

            if (currentSection && currentSection.itemChilds && currentSection.itemChilds.length) {
              counter = counter + currentSection.itemChilds.length;
            }
          }
        }
      } else {
        counter = (itemRef.itemChilds && itemRef.itemChilds.length) || 0;
      }
    }

    return counter;
  }

  openFeedbackSurvey() {
    this.modalService.open("feedbackSurvey");
  }

  closeFeedbackSurvey() {
    this.onSurveySended.emit(false);
    this.modalService.close("feedbackSurvey");
  }

  /**
 * Rule for fill the star
 * @param index Index of the star
 */
  fillStar(index: number): boolean {
    if (this.surveyPointsSelected > 0 && this.surveyPoints === 0) {
      return this.surveyPointsSelected >= index;
    } else {
      return this.surveyPoints >= index;
    }
  }

  sendFeedbackSurvey(): void {
    this.onSendFeedbackSurvey.emit();
  }

  setSurveyPoints(points: number): void {
    this.onSurveyPoints.emit(points);
  }

  setSurveyPointsSelected(points: number): void {
    this.onSurveyPointsSelected.emit(points);
  }
}
