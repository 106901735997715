
    <div id="messages-container" class="messages-container">

        <!-- chat placeholder -->
        <div *ngIf="msgsChatLoading" class="margin-top8 margin-left8 margin-right8">
            <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
                <div class="chat-circle-placeholder"></div>
                <generic-list-placeholder style="width: 30%;"></generic-list-placeholder>
            </div>
            
            <div class="full-width" fxLayout="row" fxLayoutAlign="end center">
                <generic-list-placeholder style="width: 30%;"></generic-list-placeholder>
                <div class="chat-circle-placeholder"></div>
            </div>
        </div>
        
        <div *ngFor="let msg of msgs">
            <chat-message *ngIf="!(filterId && filterId !== msg.filterId)" [text]="msg.content" [firstLetters]="msg.firstLetters"
                [imgSrc]="msg.imgProfile" [fromRight]="!msg.isLeft" [whiteBackground]="!msg.isLeft"
                [fxLayoutAlign]="msg.isLeft ? alignLeft : alignRight "
                [customImageStyle]="{width: '45px', height: '45px', 'max-width': '45px', 'max-height': '45px', 'border-radius': '50%'}"
                class="full-width" style="margin-top: 3px; margin-bottom: 3px;">
            </chat-message>
        </div>
    </div>

    <div class="textToSend full-width" fxLayout="row" fxLayoutAlign="space-between center">

        <textarea [(ngModel)]="message" placeholder="{{placeholder | translate}}" (keydown.enter)="sendMsg(); false"></textarea>

        <div class="icon-container cursor-pointer" (click)="sendMsg()">
            <svg-icon id="iconSend" src="../../../../assets/img/icons/send-icon.svg"></svg-icon>
        </div>
    </div>