/*
 * Servizio che permette le chiamate rest relative all'autenticazione
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { SenecaResponse, CommonConstants } from 'atfcore-commonclasses/bin/classes/common';
import { UserAcknowledges } from "atfcore-commonclasses/bin/classes/anag";

import { DeviceType } from "src/app/shared/interfaces/common.interface";
import { UrlService } from "../../shared/services/url.service";

@Injectable()
export class AuthService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Function that activate a user after the registration process and create a login token
   * @param {string} userId - Required: The userId to activate
   * @param {string} activationCode - Required: the activation code received via mail
   * @param {string} deviceType - Optional: Type of device used by the user
   * @param {string} userAgent - Optional: UserAgent of the user
   * @return {token} The string of the auth token
   */
  activateNewUser(userId: string, activationCode: string, deviceType?: DeviceType, userAgent?: string): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'activate-new-user',
      {
        userId,
        activationCode,
        deviceType,
        userAgent
      });
  }

  // Marca la notifica come letta
  markNotificationAsRead(notificationQueueId: string): any {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-mark-notification-as-read', { notificationQueueId: notificationQueueId });
  }

  // Servizio che aggiorna una ack
  updateUserAcknowledges(userAcknowledges: UserAcknowledges): Observable<SenecaResponse<UserAcknowledges>> {
    return this.http.post<SenecaResponse<UserAcknowledges>>(this._mediatorUrl + 'update-userAcknowledges', {
      userAcknowledges: userAcknowledges
    });
  }

  /**
   * Function that returns the JWT given its key (which could be the token itself or, most of the time, the tiny token)
   * @param {string} token Tini token
   * @return {string} Full token
   */
  getFullJWTToken(token: string): Observable<SenecaResponse<string>> {
    return this.http.get<SenecaResponse<string>>(
      this._cm2MediatorUrl + 'get-full-jwt-token',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) }
    );
  }

  /**
   * Function that return a token of the anonymous user.
   * This method is used to grant access to all public features of the application. It return a specific "anonymous" user that is predefined in the DB.
   * @param {string} deviceType - Optional: Type of device used by the user
   * @param {string} userAgent - Optional: UserAgent of the user
   * @return {token} The string of the auth token
   */
  anonymousLogin(deviceType?: DeviceType, userAgent?: string): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'anonymous-login',
      {
        deviceType,
        userAgent
      });
  }

  /**
   * Function that create the retrieve for a user token in Redis, which is valid for 1 minute.
   * It is used to keep the token from traveling in the redirect url: a single-use key is inserted in its place
   * which allows the browser to recover the token within a predetermined maximum time. In this way the after login
   * page url or an attacker that capture the link will not be able to use the token after the user and it/he will be sent back to login
   * @return {string} The retrieveKey to use to recover the token - ssortkqp
   */
  createRetrieveTokenAfterLogin(withNewToken?: boolean): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'create-retrieve-token-after-login', {
      withNewToken: withNewToken
    });
  }

  /**
   * Function that returns the JWT given its key (which could be the token itself or, most of the time, the tiny token)
   * @param {string} token - Required by the action: The JWT token or tiny token used as key to find the JWT token
   * @return {string} The JWT token
   */
  getJWTToken(token: string): Observable<SenecaResponse<string>> {
    return this.http.get<SenecaResponse<string>>(this._mediatorUrl + 'get-full-jwt-token',
      {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
      });
  }

  // Recupera gli studi dell'utente loggato
  getUserStudies(userId: string, tenant: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('tenant', tenant || '');
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-studies', {
      params: httpParams
    });
  }

  // Recupera le esperienze dell'utente loggato
  getUserExperiences(userId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-experiences', {
      params: httpParams
    });
  }

  // Recupera i rents dell'utente loggato
  getFacultyRents(userId: string, tenant: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('withFullData', 'true');
    httpParams = httpParams.append('tenant', tenant || '');
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-faculty-rents-of-user', {
      params: httpParams
    });
  }

  // Elimina esperinza dell'utente
  deleteUserExperience(engagementId: string, userId, tenant) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId || '');
    httpParams = httpParams.append('engagementId', engagementId || '');
    httpParams = httpParams.append('tenant', tenant || '');
    return this.http.delete<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-delete-user-experience/' + engagementId, {
      params: httpParams
    });
  }

  // Aggiorna esperienza utente
  updateUserExperience(activity: any) {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-update-user-experience', {
      userActivity: activity
    });
  }

  // Crea esperienza utente
  createUserExperience(activity: any, activityType: any) {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-create-user-experience', {
      startDate: activity.activityStartDate,
      startTime: activity.activityStartTime,
      endDate: activity.activityEndDate,
      endTime: activity.activityEndTime,
      activityName: activity.activityName,
      activityPlace: activity.activityPlace,
      targetUser: activity.targetUser,
      referenceId: activity.referenceId,
      referenceType: activity.referenceType,
      activityType: activityType,
      engagementDetails: activity.engagementDetails
    });
  }

  updateUserStudy(activity: any) {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-update-user-study', {
      userStudy: activity
    });
  }


  createUserStudy(activity: any) {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-create-user-study', {
      startDate: activity.activityStartDate,
      endDate: activity.activityEndDate,
      activityName: activity.activityName,
      activityPlace: activity.activityPlace
    });
  }

  deleteUserStudy(engagementId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('engagementId', engagementId || '');
    return this.http.delete<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-delete-user-study/' + engagementId, {
      params: httpParams
    });
  }

  getUserFacultyActivities(userId: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('userId', userId);
    httpParams = httpParams.append('facultyActivities', 'DATA_USER_PROJECT_ACTIVITY');
    httpParams = httpParams.append('facultyActivities', 'DATA_USER_TRAINER_ACTIVITY');
    httpParams = httpParams.append('facultyActivities', 'DATA_USER_COMMUNITY_ACTIVITY');
    httpParams = httpParams.append('facultyActivities', 'DATA_USER_ONBOARDING_ACTIVITY');
    httpParams = httpParams.append('facultyActivities', 'DATA_USER_MENTOR_ACTIVITY');
    httpParams = httpParams.append('facultyActivities', 'DATA_USER_TUTOR_ACTIVITY');
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-user-faculty-activities', {
      params: httpParams
    });
  }

  // Crea esperienza utente faculty
  createUserFacultyActivity(activity: any, activityType: any) {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-create-user-faculty-activity', {
      startDate: activity.activityStartDate,
      startTime: activity.activityStartTime,
      endDate: activity.activityEndDate,
      endTime: activity.activityEndTime,
      activityName: activity.activityName,
      activityPlace: activity.activityPlace,
      targetUser: activity.targetUser,
      referenceId: activity.referenceId,
      referenceType: activity.referenceType,
      facultyActivityType: activityType,
      engagementDetails: activity.engagementDetails
    });
  }
  // Elimina esperienza dell'utente faculty
  deleteUserFacultyActivity(engagementId: string) {
    let httpParams = new HttpParams();
    return this.http.delete<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-delete-user-faculty-activity/' + engagementId, {
      params: httpParams
    });
  }

  // Aggiorna esperienza dell'utente faculty
  updateUserFacultyActivity(activity: any, userId: string, tenant: string) {
    return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-update-user-faculty-activity', {
      userId: userId,
      tenant: tenant,
      activityUserId: activity.activityUserId,
      activityId: activity.activityId,
      startDate: activity.startDate,
      endDate: activity.endDate,
      activityName: activity.activityName,
      activityPlace: activity.activityPlace,
      targetUser: activity.targetUser,
      facultyActivityType: activity.facultyActivityType || activity.activityType,
      startTime: activity.startTime,
      endTime: activity.endTime,
      engagementDetails: activity.engagementDetails,
      facultyRole: activity.facultyRole
    });
  }

  // Aggiunge l'item nei preferiti
  addCourseToBookmarks(courseId: string, referenceApplicationName: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('courseId', courseId || '');
    httpParams = httpParams.append('referenceApplicationName', referenceApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'add-course-to-bookmarks', {
      params: httpParams
    });
  }

  // Rimuove l'item dai preferiti
  removeCourseFromBookmarks(courseId: string, referenceApplicationName: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('courseId', courseId || '');
    httpParams = httpParams.append('referenceApplicationName', referenceApplicationName || '');
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'remove-course-from-bookmarks', {
      params: httpParams
    });
  }

  /**
   * Function that receive an email and a password to login the user with LOCAL data.
   * This method should be used when no SSO system is available.
   * @param {string} email - Required: The email of the user to login
   * @param {string} password - Required: The password of the user to login
   * @param {string} deviceType - Optional: Type of device used by the user
   * @param {string} userAgent - Optional: UserAgent of the user
   * @param {boolean} createPersistentUserAuth - Optional: If true, the performed authentication will be
   * saved as persistent auth too, which means that the user won't need to perform another login to access
   *  the protected resources (until the authentication expires, which usually will be after several time)
   * @return {token} The string of the auth token
   */
  login(email: string, password: string, deviceType?: DeviceType, userAgent?: string,
    createPersistentUserAuth?: boolean): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._cm2MediatorUrl + 'glp-login-local-password',
      {
        email: email,
        password: password,
        deviceType: deviceType,
        userAgent: userAgent,
        createPersistentUserAuth: createPersistentUserAuth
      });
  }

  loginTaker(searchedText: string, device?: string, userAgent?: string): Observable<SenecaResponse<string>> {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('searchedText', searchedText || '');
    httpParams = httpParams.append('device', device);
    httpParams = httpParams.append('userAgent', userAgent);
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + 'search-user-for-public', {
      params: httpParams
    });
  }

  /**
   * Function that destroy the token of the user session.
   * @return null
   */
  logout(): Observable<SenecaResponse<null>> {
    return this.http.get<SenecaResponse<null>>(this._mediatorUrl + 'logout');
  }

  /**
   * Service that register a new user having only his/her email. The user will not be valid and cannot login until the confirmation link sent by email will be clicked.
   * @param {string} email - Required: The email of the user
   * @param {string} password - Required: The password of the user
   * @param {string} forename
   * @param {string} surname
   * @param {string} birthDate
   * @param {string} gender
   * @param {string} degree
   * @param {string} qualification
   * @param {string} zipCode
   * @param {string} firstContact
   * @param {boolean} newsletterSubscribed
   * @param {boolean} privacyAck
   * @param {boolean} dataSharingPrivacyAck
   * @param {boolean} usageAnalyticsPrivacyAck
   * @return {null} null
   */
  registerFromMail(email: string, password: string, forename?: string, surname?: string,
    birthDate?: string, gender?: string, degree?: string, qualification?: string, zipCode?: string,
    firstContact?: string, newsletterSubscribed?: boolean, privacyAck?: boolean, dataSharingPrivacyAck?: boolean, usageAnalyticsPrivacyAck?: boolean): Observable<SenecaResponse<null>> {
    return this.http.post<SenecaResponse<null>>(this._mediatorUrl + 'register-from-mail',
      {
        email,
        password,
        forename,
        surname,
        birthDate,
        gender,
        degree,
        qualification,
        zipCode,
        firstContact,
        newsletterSubscribed,
        privacyAck,
        dataSharingPrivacyAck,
        usageAnalyticsPrivacyAck
      });
  }

  setMyPrivacyData(newsletterSubscribed?: boolean, privacyAck?: boolean, dataSharingPrivacyAck?: boolean, usageAnalyticsPrivacyAck?: boolean, isFromAcceptPage?: boolean): Observable<SenecaResponse<null>> {
    if (isFromAcceptPage) {
      return this.http.post<SenecaResponse<null>>(this._mediatorUrl + 'set-my-privacy-data',
        {
          usageAnalyticsPrivacyAck: true
        });
    } else {
      return this.http.post<SenecaResponse<null>>(this._mediatorUrl + 'set-my-privacy-data',
        {
          newsletterSubscribed,
          privacyAck,
          dataSharingPrivacyAck,
          usageAnalyticsPrivacyAck
        });
    }

  }

  /**
   * Function that receive a valid token and renew it. It is used to extend the lifetime of a user or anonymous session.
   * @return {token} The string of the auth token
   */
  renewToken(sessionStorageToken: string): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'renew-token', null, {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorageToken)
    });
  }

  getUserMainApplicationData(token: string) {
    // Preparo i parametri per la richiesta http
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + '/get-user-main-application');
  }

  isSsoEnabledForLoggedUser() {
    let httpParams = new HttpParams();
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + '/is-sso-enabled-for-logged-user', {
      params: httpParams
    });
  }

  /**
   * Validates the specified jwt token and, if requested, creates a login engagement
   * @param {string} token - Required: Token to validate, it must be defined as parameter or in the Bearer of the request
   * @param {boolean} createLoginEngagement - Optional: If true, a login engagement will be generated
   * @param {string} deviceType - Optional: Device type used by the user to perform the login, it will be saved in the login engagement's details
   * @param {string} userAgent - Optional: User agent used by the user to perform the login, it will be saved in the login engagement's details
   * @return {boolean} True if no errors were thrown during the execution
   */
  validateJwtToken(token: string, createLoginEngagement?: boolean, deviceType?: DeviceType,
    userAgent?: string): Observable<SenecaResponse<boolean>> {
    return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'validate-jwt-token', {
      token,
      createLoginEngagement,
      deviceType,
      userAgent
    });
  }

  getMyCategories(): Observable<SenecaResponse<any>> {
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-my-categories-preferences');
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-retrieve-token-after-login', {
      params: httpParams
    });
  }

  glpGetViewsCounter(pageName: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('pageName', pageName);
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-get-views-counter', {
      params: httpParams
    });
  }

  glpTrackPageView(pageName: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('pageName', pageName);
    return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'glp-track-page-view', {
      params: httpParams
    });
  }

  // Metodi per la gestione dei diritti e autenticazione
  isUserAuthorized(requiredAuthId: string, auths: string[]): boolean {
    let isAuthorized: boolean = false;

    for (let i = 0, authsLength = auths.length; i < authsLength; i++) {
      let currentAuth = auths[i];
      if (currentAuth === requiredAuthId) {
        isAuthorized = true;
        break;
      }
    }

    return isAuthorized;
  }

  // Crea in base al token l'ssorqtp
  crateRetrieveTokenAfterLogin(): any {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'create-retrieve-token-after-login', null);
  }

}
