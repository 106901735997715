import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { ScormRegistration } from "atfcore-commonclasses/bin/classes/scorm";

import { UrlService } from "src/app/shared/services/url.service";

@Injectable()
export class ScormService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  isSingletonInUse() {
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + 'glp-is-singleton-item-in-use', {
    });
  }

  markSingletonInUse() {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'glp-mark-singleton-item-in-use', {
    });
  }

  /**
   *
   * @param {string} itemId - Required: Course's itemId
   * @param {string} scormId - Required: Course's scormId
   * @return {ScormRegistration}
   */
  createScormRegistration(scormId: string, itemId: string, editionId?: string | string[]
    , stageId?: string): Observable<SenecaResponse<ScormRegistration>> {

    return this.http.post<SenecaResponse<ScormRegistration>>(this._mediatorUrl + `glp-create-scorm-registration/${scormId}`, {
      itemId: itemId,
      scormId: scormId,
      editionId: editionId,
      stageId: stageId
    });
  }

  /**
   *
   * @param {string} scormId - Required
   * @return {string}
   */
  getScormPreviewUrl(scormId: string): Observable<SenecaResponse<string>> {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('scormId', scormId || '');
    return this.http.get<SenecaResponse<string>>(this._mediatorUrl + `glp-get-scorm-preview-url/${scormId}`, {
      params: httpParams
    });
  }

  /**
   *
   * @param {string} scormRegistrationId - Required
   * @param {boolean} checkForSingleton - Optional
   * @return {string}
   */
  getScormLaunchUrl(scormRegistrationId: string, checkForSingleton: boolean): Observable<SenecaResponse<string>> {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('scormRegistrationId', scormRegistrationId || '');
    httpParams = httpParams.append('checkForSingleton', (checkForSingleton && checkForSingleton.toString()) || '');
    return this.http.get<SenecaResponse<string>>(this._mediatorUrl + 'glp-get-scorm-launch-url/' + scormRegistrationId + '/' + checkForSingleton, {
      params: httpParams
    });
  }
}
