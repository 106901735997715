<div class="card" [ngClass]="waveColor === 'red' ? 'red' : 'green'">
  <div (click)="collectionClicked.emit()" class="card__header">
    <img src="assets/img/cover/courses/Corsi_Mob.jpg" alt="" />
    <svg class="card__wave-top" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#DC4F3E" fill-opacity="1"
        d="M0,160L80,176C160,192,320,224,480,202.7C640,181,800,107,960,74.7C1120,43,1280,53,1360,58.7L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z">
      </path>
    </svg>
    <span class="card__header__label">{{
      "generic.COLLECTIONS" | translate | uppercase
      }}</span>

    <svg class="card__wave-bottom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path fill="#DC4F3E" fill-opacity="1"
        d="M0,288L80,288C160,288,320,288,480,245.3C640,203,800,117,960,90.7C1120,64,1280,96,1360,112L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z">
      </path>
    </svg>

    <span class="card__header__counter">{{ item?.contentLabel }}</span>
  </div>

  <div class="card__content">
    <p class="card__title">{{ item?.title || '' }}</p>
  </div>

  <div class="card__footer">
    <div class="card__tags">
      <ng-container *ngIf="item?.tags && item.tags.length">
        <div fxLayout="row">
          <p class="card__tag" *ngFor="let tag of item.tags">
            {{ tag }}
          </p>
        </div>
      </ng-container>
    </div>
    <svg-icon (click)="collectionLiked.emit()" [applyClass]="true" svgClass="like" src="assets/icons/icon-heart.svg">
    </svg-icon>
  </div>
</div>
