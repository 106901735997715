<div class="slick-section-header-wrapper"
  [ngClass]="((contentLength) ? (titleWrapperClass || 'red-border') : 'gray-border') === 'white-border' ? 'gray-background' : 'white-background'"
  fxLayout="column" fxLayoutAlign="center center">
  <section role="region" tabindex="0" attr.aria-label="{{'itemDetailsPage.section_header.TITLE_AND_DESCRIPTION_FASCY' | translate}}"
    class="slick-header-content">
    <!-- <div *ngIf="isFromDetailClusterOnLS" fxLayout="row" class="padding-bottom25" fxLayoutAlign="start center">
          <div class="slick-section-link">
            <a class="back-button-detail-cluster" (click)="onGetBackFromDetail()" translate="itemDetailsPage.section_header.BACK"></a>
        </div>
        </div> -->
    <div class="slick-section-title-wrapper"
      [ngClass]="((contentLength) ? (titleWrapperClass || 'red-border') : 'gray-border')" fxLayout="row"
      fxLayoutAlign="start center" fxLayoutGap="16px">
      <div class="title-sub-wrapper" fxLayout.gt-xs="row" fxLayout="column">
        <div *ngIf="contentLength" class="slick-section-title" [ngClass]="colorClusterDetail">
          <p tabindex="0">{{ sectionTitle }}</p>
        </div>
        <div *ngIf="!contentLength" class="slick-section-title gray-background">
          <p tabindex="0">{{ sectionTitle }}</p>
        </div>
        <div class="slick-section-sub" fxHide.xs fxFlex fxLayoutAlign="start center">
          <p *ngIf="sectionSubtitle && isLoadingResults" tabindex="0">
            {{ 'itemDetailsPage.section_header.LOADING' | translate}}
          </p>
          <!-- Etichettà di obbligatorietà -->
          <p tabindex="0" *ngIf="showRequiredLabel && !isLoadingResults" class="mandatory-label">
            {{ 'generic.MANDATORY' | translate}}&nbsp;
          </p>
          <p *ngIf="showRequiredLabel && !isLoadingResults">
            {{ '·' }}&nbsp;
          </p>
          <!-- Etichettà di propedeuticità -->
          <p tabindex="0" *ngIf="showPropedeuticLabel && !isLoadingResults" class="propedeutical-label">
            {{ ('generic.PROPEDEUTIC' | translate) }}&nbsp;
          </p>
          <p *ngIf="showPropedeuticLabel && !isLoadingResults && sectionSubtitle">
            {{ '·' }}&nbsp;
          </p>
          <p tabindex="0"
            *ngIf="sectionSubtitle && !isFromYouAreInvitedMyLearning && !isLoadingResults && contentLength">
            <b *ngIf="resultsNumber">{{ resultsNumber }}</b>&nbsp;
            {{ sectionSubtitle }}
            <strong *ngIf="order"> {{ order }} <img src="../../../../assets/icons/icon-caret-bottom.svg"></strong>
          </p>
          <!-- Numero dei risultati contenuti -->
          <p tabindex="0"
            *ngIf="sectionSubtitle && isFromYouAreInvitedMyLearning && !isLoadingResults && contentLength && !isFilterHeader">
            <b *ngIf="resultsNumber">{{ resultsNumber }}</b>&nbsp;
            {{ sectionSubtitle }}
          </p>
          <!-- Numero dei risultati contenuti per header filtri faculty-->
          <p tabindex="0" *ngIf="sectionSubtitle && !isLoadingResults && contentLength == 0 && isFilterHeader">
            <!--b *ngIf="resultsNumber">{{ resultsNumber }}</b>&nbsp;-->
            {{ sectionSubtitle }}
          </p>

          <!-- Scelta dell'ordinamento su facultyWho -->
          <div *ngIf="isFacultyOrder && resultsNumber && !isLoadingResults"
            class="custom-select select-type-you-are-invited margin-bottom2">
            <ng-select *ngIf="facultyOrderList && facultyOrderList.length" #selectFacultyOrder class="faculty-order"
              [addTag]="false" name="header-search-bar" [ngModelOptions]="{standalone: true}"
              (change)="onFacultyOrderSelectChange()" labelForId="faculty-order-id" [items]="facultyOrderList"
              aria-label="Ordina per" parameterNameOfSelectedItem="'desc'" [disabled]="isLoadingResults"
              [searchable]="false" bindLabel="desc" [multiple]="false" [(ngModel)]="selectedOrder"
              [dropdownPosition]="'bottom'">
              <!-- Elementi selezionati -->
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <div fxLayout="row" fxLayoutAlign="start center" style="padding: 0px 1px;">
                  <p tabindex="0" style="font-weight: bold">{{item.desc}}</p>
                </div>
              </ng-template>
              <!-- Template per i singoli item nelle options -->
              <ng-template ng-option-tmp let-item="item">
                <div class="select-options-card" aria-label="ciao" tabindex="0">
                  <div class="card-body" aria-label="ciao2" tabindex="0" fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column" aria-label="ciao3" tabindex="0">
                      <span role="option" tabindex="0" class="you-are-invited-option-text">{{item.desc}}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <!-- Scelta del tipo su sei stato invitato a del myLearning -->
          <div *ngIf="isFromYouAreInvitedMyLearning && contentLength && !isLoadingResults"
            class="custom-select select-type-you-are-invited margin-bottom2">
            <ng-select #selectYouAreInvited class="select-type-you-are-invited" [addTag]="false"
              name="header-search-bar" [ngModelOptions]="{standalone: true}"
              (change)="onTypeYouAreInvitedSelectChange()" labelForId="header-search-id"
              placeholder="Scegli un tipo di corso" [items]="youAreInvitedTypeList" aria-label="Ordina per"
              [disabled]="counterTypesYouAreInvitedNotExist()" [searchable]="false" bindLabel="name" [multiple]="false"
              [(ngModel)]="selectedYouAreInvitedType" [dropdownPosition]="'bottom'">
              <!-- Elementi selezionati -->
              <ng-template ng-label-tmp let-item="item" let-clear="clear">
                <div fxLayout="row" fxLayoutAlign="start center" style="padding: 0px 1px;">
                  <p tabindex="0" style="font-weight: bold">{{item.name}}</p>
                  <!-- ho dovuto aggiungere un div dove aggangiare il click per risolvere un problema con ng-select, si rompeva non trovando il .classname sull'elemento SVG al click della X -->
                  <div (click)="clear(item);" style="max-height: 16px;">
                    <svg-icon aria-hidden="true" src="assets/icons/icon-trash.svg"></svg-icon>
                  </div>
                </div>
              </ng-template>
              <!-- Template per i singoli item nelle options -->
              <ng-template ng-option-tmp let-item="item">
                <div class="select-options-card">
                  <div class="card-body" fxLayout="row" fxLayoutAlign="start center">
                    <div fxLayout="column">
                      <span role="option" tabindex="0" *ngIf="item && item.code != 'ALL'"
                        class="you-are-invited-option-text">{{item.name}}
                        ({{(counterTypesYouAreInvited && counterTypesYouAreInvited[item.keyForCount]) || 0}})</span>
                      <span role="option" tabindex="0" *ngIf="item && item.code == 'ALL'"
                        class="you-are-invited-option-text">{{item.name}}</span>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <button tabindex="0" class="slick-section-link show-all no-button-style"
        *ngIf="linkText && resultsNumber > 4 && !isLoadingResults" (click)="onShowAllClicked()">
        <a *ngIf="showAll">{{ linkText }}</a>
        <a *ngIf="!showAll && !goBackLabel" translate="itemDetailsPage.section_header.BACK_TO_HOME"></a>
        <a *ngIf="!showAll && goBackLabel" translate="itemDetailsPage.section_header.GO_BACK"></a>
      </button>
      <button tabindex="0" class="slick-section-link no-button-style"
        *ngIf="isFromDetailClusterOnLS && !isLoadingResults" [ngClass]="{'show-all': true }"
        (click)="onGetBackFromDetail()">
        <a translate="itemDetailsPage.section_header.GO_BACK"></a>
      </button>
      <button tabindex="0" class="slick-section-link no-button-style filters"
        *ngIf="isFilterHeader && !isLoadingResults && resultsNumber != 0" [ngClass]="{'show-all': true }"
        (click)="onGetBackFromDetail()">
        <a translate="itemDetailsPage.section_header.DELETE_FILTERS"></a>
      </button>
    </div>
    <div class="slick-section-descr-wrapper"
      [ngClass]="((contentLength) ? (titleWrapperClass || 'red-border') : 'gray-border') === 'white-border' ? 'white-text' : 'gray-text'">
      <!-- Lucchetto se lo slider è bloccato -->
      <div tabindex="0" *ngIf="isContentBlocked" class="item-blocked-container" (click)="emitOnContentBlockedClicked()"
        fxLayout="row" fxLayoutAlign="start center">
        <svg-icon class="item-locked-svg" src="../../../assets/icons/item-blocked.svg">
        </svg-icon>
        <p *ngIf="!contentBlockedDescription" translate="generic.HOW_CAN_I_UNBLOCK_IT"></p>
        <p *ngIf="contentBlockedDescription">{{ contentBlockedDescription }}</p>
      </div>

      <p tabindex="0" *ngIf="!sectionUnderDescription && !isFromDIStudy">
        {{ 'itemDetailsPage.section_header.SPECIAL_PROJECT_DESCR' | translate }}</p>
      <p tabindex="0" *ngIf="sectionUnderDescription && !isFromDIStudy"
        [innerHTML]="sectionUnderDescription | safeHtml: 'html'"></p>
    </div>
  </section>
</div>