<div class="card" (click)="courseClicked.emit(courseId)">
  <div class="card__header">
    <img class="card__image" [src]="cardCover" alt="card-image" />
  </div>

  <div class="card__body">
    <p class="course__type">{{ type }}</p>
    <p class="course__duration">
      {{ courseDuration }}
    </p>

    <p class="course__title">{{ title }}</p>

    <div>
      <div class="course__mode">
        <ng-container
          *ngTemplateOutlet="modalityIcon || defaultModeIcon"
        ></ng-container>
        <span class="status__label">{{ modality }}</span>
      </div>

      <div class="course__status">
        <ng-container
          *ngTemplateOutlet="statusIcon || defaultStatusIcon"
        ></ng-container>
        <span class="status__label">{{ status }}</span>
      </div>
    </div>
  </div>

  <div class="card__footer">
    <p class="course__tag">{{ argumentName }}</p>
    <svg-icon
      (click)="courseBookmarked.emit(courseId); $event.stopPropagation();"
      [applyClass]="true"
      class="course__icon"
      [src]="
      isBookmarked
          ? 'assets/icons/icon-heart-fill.svg'
          : 'assets/icons/icon-heart.svg'
      "
    ></svg-icon>
  </div>
</div>

<ng-template #defaultModeIcon>
  <svg-icon src="assets/icons/icon-map-pin.svg"></svg-icon>
</ng-template>

<ng-template #defaultStatusIcon>
  <svg-icon src="assets/icons/icon-map-pin.svg"></svg-icon>
</ng-template>
