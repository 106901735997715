/*
 * Reducer per il Core Module, triggherate ogniqualvolta viene eseguito il dispatch di un'action, per l'update dello state
 *
*/

import * as CoreActions from './core.actions';

import { Tag, TagInfo } from 'atfcore-commonclasses/bin/classes/tag';

import { GlobalApplicationData } from '../../shared/models/global-application-data.model';
import { ScreenSize } from 'src/app/shared/interfaces/screen.interface';
import { getScreenSizeCode } from 'src/app/shared/utils';

export interface CoreState {
  globalApplicationData: GlobalApplicationData,
  isFetchingLangs: boolean,
  showApplicationLoader: boolean,
  // flag per poter gestire il loader dell'applicazione manualmente e non tramite interceptor
  forceManualLoaderHandling: boolean,
  redirectUrl: string,
  applicationLang: string,
  defaultLang: string,
  screenSize: ScreenSize,
  screenWidth: number,
  isFetchingClusters: boolean,
  clusters: Tag[],
  categories: TagInfo[]
};

// State iniziale
const initialState: CoreState = {
  globalApplicationData: null,
  isFetchingLangs: false,
  showApplicationLoader: false,
  forceManualLoaderHandling: false,
  redirectUrl: null,
  applicationLang: null,
  defaultLang: null,
  screenSize: null,
  screenWidth: null,
  isFetchingClusters: false,
  clusters: null,
  categories: null
};

// E' necessario utilizzare una funzione normale, le Arrow function di ES6 in questo caso non funzionano. Lo state è passato automaticamente da Ngrx, e ne approfittiamo per dargli un valore iniziale.
// Alla fine la funzione tornerà il nuovo state, una nuova copia aggiornata. Infatti, grazie all'immutabilità dello state, in ogni "case" viene sempre tornato un nuovo oggetto.
export function coreReducer(state = initialState, action) {
  // Tramite lo switch, identifico di quale azione è stato fatto il dispatch
  switch (action.type) {
    case (CoreActions.SAVE_REDIRECT_URL):
      // Salvo l'url richiesto prima che l'applicazione faccia il redirect alla pagina di login a causa della mancata autenticazione dell'utente
      return {
        ...state,
        redirectUrl: action.payload
      };
    case (CoreActions.REMOVE_REDIRECT_URL):
      // Cancella l'url per il redirect dopo il login
      sessionStorage.removeItem('redirectUrl');
      return {
        ...state,
        redirectUrl: null
      };
    case (CoreActions.SET_CORE_APPLICATION_DATA):
      return {
        ...state,
        globalApplicationData: action.payload
      };
    case (CoreActions.SET_APPLICATION_LANG):
      return {
        ...state,
        applicationLang: action.payload
      };
    case (CoreActions.REMOVE_CORE_APPLICATION_DATA):
      return {
        ...state,
        globalApplicationData: null
      };
    case (CoreActions.REMOVE_APPLICATION_LANG):
      return {
        ...state,
        applicationLang: null
      };
    case (CoreActions.GET_AVAILABLE_LANGS):
      return {
        ...state,
        isFetchingLangs: true
      };
    case (CoreActions.GET_AVAILABLE_LANGS_FINISHED):
      return {
        ...state,
        isFetchingLangs: false
      };
    case (CoreActions.SET_DEFAULT_LANG):
      return {
        ...state,
        defaultLang: action.payload
      };
    case (CoreActions.SHOW_APPLICATION_LOADER):
      return {
        ...state,
        showApplicationLoader: true,
        forceManualLoaderHandling: action.payload && !!action.payload.forceManualLoaderHandling
      };
    case (CoreActions.HIDE_APPLICATION_LOADER):
      let closeManualLoader = action.payload && !!action.payload.closeManualLoader;
      let forceManualLoaderHandling = !!state.forceManualLoaderHandling && !closeManualLoader;
      // se forceManualLoaderHandling è attivo allora allora anche il loader deve rimandere visibile se non specificato diversamente con closeManualLoader
      let showApplicationLoader = forceManualLoaderHandling;
      return {
        ...state,
        showApplicationLoader: false,
        forceManualLoaderHandling: forceManualLoaderHandling
      };
    case (CoreActions.SET_SCREEN_SIZE):
      const screenWidth = window.innerWidth;
      let sSize: any = getScreenSizeCode();
      return {
        ...state,
        screenSize: sSize,
        screenWidth: screenWidth
      };
    case (CoreActions.SET_CLUSTERS):
      return {
        ...state,
        isFetchingClusters: false,
        clusters: action.payload
      };
    case (CoreActions.GET_CLUSTERS):
      return {
        ...state,
        isFetchingClusters: true
      };
    case (CoreActions.SET_CATEGORIES):
      // Salvo l'url richiesto prima che l'applicazione faccia il redirect alla pagina di login a causa della mancata autenticazione dell'utente
      return {
        ...state,
        categories: action.payload
      };
    default:
      return state;
  }
}

export const getGlobalApplicationData = (state: CoreState) => state.globalApplicationData;
export const isFetchingLangs = (state: CoreState) => state.isFetchingLangs;
export const showApplicationLoader = (state: CoreState) => state.showApplicationLoader;
export const getRedirectUrl = (state: CoreState) => state.redirectUrl;
export const getApplicationContext = (state: CoreState) => state.globalApplicationData.applicationContext;
export const getApplicationLang = (state: CoreState) => state.applicationLang;
export const getDefaultLang = (state: CoreState) => state.defaultLang;
export const getScreenSize = (state: CoreState) => state.screenSize;
export const getScreenWidth = (state: CoreState) => state.screenWidth;
export const getCategories = (state: CoreState) => state.categories;
export const getClusters = (state: CoreState) => state.clusters;
export const isFetchingClusters = (state: CoreState) => state.isFetchingClusters;