import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "chat-message",
  templateUrl: "./chat-message.component.html",
  styleUrls: ["./chat-message.component.scss"],
})
export class ChatMessageComponent implements OnInit {
  @Input() text: string;
  @Input() userName: string;
  @Input() closable?: boolean = false;
  @Input() imgSrc: string;
  @Input() fromRight?: boolean = false;
  @Input() whiteBackground?: boolean = false;
  @Input() customImageStyle?: any = {};
  @Input() containerStyle?: any = {};
  @Input() firstLetters?: string;

  @Output() onIconClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.text) {
      this.text = this.escapeHtml(this.text);
    }
  }

  escapeHtml(text) {
    var map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function (m) { return map[m]; });
  }

  clickClose() {
    this.onIconClicked.emit(this.text);
  }
}
