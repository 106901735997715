import {
  AfterContentInit,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  QueryList,
  Renderer2,
} from '@angular/core';
import { MenuItemComponent } from './menu-item/menu-item.component';

@Directive({
  selector: 'menu',
  exportAs: 'menu',
})
export class MenuDirective implements AfterContentInit {
  private _isOpen: boolean = false;

  private _transitionDuration = '0.2s';

  @Input() set transitionDuration(duration: string) {
    this._transitionDuration = duration;
  }

  @Input() set open(value: boolean) {
    this._isOpen = value;
    if (value) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  @ContentChildren(MenuItemComponent)
  private items: QueryList<MenuItemComponent>;

  constructor(
    private elRef: ElementRef<MenuDirective>,
    private renderer: Renderer2
  ) { }

  ngAfterContentInit(): void {
    this.defaultStyle();
    this.hideMenu();
  }

  toggle() {
    this._isOpen = !this._isOpen;

    if (this._isOpen) {
      this.showMenu();
    } else {
      this.hideMenu();
    }
  }

  isOpen() {
    return this._isOpen;
  }

  closeMenu() {
    this._isOpen = false;
    this.hideMenu();
  }

  private showMenu() {
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'transition',
      `opacity ${this._transitionDuration} ease-in-out`
    );
    this.renderer.setStyle(this.elRef.nativeElement, 'pointer-events', 'auto');
    this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'pointer');

    this.renderer.setStyle(this.elRef.nativeElement, 'opacity', '1');
  }

  private hideMenu() {
    this.renderer.setStyle(this.elRef.nativeElement, 'opacity', '0');
    this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'default');
    this.renderer.setStyle(this.elRef.nativeElement, 'pointer-events', 'none');
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'transition',
      `opacity ${this._transitionDuration} ease-in-out`
    );
  }

  defaultStyle() {
    this.renderer.setStyle(this.elRef.nativeElement, 'cursor', 'default');
    this.renderer.setStyle(this.elRef.nativeElement, 'pointer-events', 'none');
    this.renderer.setStyle(this.elRef.nativeElement, 'overflow', 'hidden');
    this.renderer.setStyle(this.elRef.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(this.elRef.nativeElement, 'top', '20px');
    this.renderer.setStyle(this.elRef.nativeElement, 'min-width', '100px');
    this.renderer.setStyle(this.elRef.nativeElement, 'right', '0');
    this.renderer.setStyle(this.elRef.nativeElement, 'z-index', '100');
    this.renderer.setStyle(this.elRef.nativeElement, 'background', 'white');
    this.renderer.setStyle(this.elRef.nativeElement, 'color', 'black');
    this.renderer.setStyle(this.elRef.nativeElement, 'list-style', 'none');
    this.renderer.setStyle(this.elRef.nativeElement, 'padding', '0');
    this.renderer.setStyle(this.elRef.nativeElement, 'margin', '0');
    this.renderer.setStyle(this.elRef.nativeElement, 'border-radius', '0.2em');
    this.renderer.setStyle(
      this.elRef.nativeElement,
      'box-shadow',
      '0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f'
    );
   /* this.renderer.setStyle(
      this.elRef.nativeElement,
      'max-height',
      `${this.items.length * 40}px`
    ); */
  }
}
