<div class="space40"></div>

<div class="content theme-primary" *ngIf="isExternalCallbackUrl">
  <div class="header-img">
    <div class="logo-home" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxLayoutAlign="start start">
        <img height="70" width="100,44" src="../../../assets/img/logo_generali.svg">
      </div>
    </div>
  </div>
</div>

<div class="theme-primary" *ngIf="pageReady">
  <div class="space20"></div>

  <div fxLayoutAlign="start center" class="privacy-title-container">
  <h2>Informativa Privacy
  </h2>
</div>
  <div class="space20"></div>
  <h5>Generali Business Solutions S.c.p.a. (il “Titolare” o la “Società”) tratta i dati personali dei
    propri stakeholder (clienti, dipendenti, fornitori, navigatori web, etc. – di seguito,
    congiuntamente, gli “Interessati”) nel ruolo di autonomo titolare del trattamento e
    nell’ambito dei servizi offerti dalla piattaforma LearningUP, acquisendo, ove necessario, il
    consenso.<br><br>
    Generali Business Solutions S.c.p.a., in applicazione della normativa vigente in materia di
    protezione dei dati personali, si è organizzata come segue:
    <ul>
      <li>
        per assolvere a funzioni di supporto e controllo relativamente all’applicazione del
        Regolamento UE n. 679/2016 (“GDPR”), è stato nominato un Responsabile della
        Protezione dei Dati (“RPD”);
      </li>
      <li>
        gli addetti che trattano dati personali sono stati nominati Autorizzati al trattamento;
        ad essi sono state fornite istruzioni specifiche e sono destinatari di un piano di
        formazione ed aggiornamento continuo;
      </li>
      <li>
        per specifiche esigenze tecniche od organizzative, la Società si avvale di soggetti
          terzi a cui affida parti del proprio processo. Tali soggetti possono assumere il ruolo
          di "Autorizzati al trattamento" o di "Responsabili" dei trattamenti della Società,
          oppure operare in totale autonomia come distinti "Titolari" di successivi trattamenti
          aventi le medesime finalità perseguite dalla Società.
      </li>
    </ul>
    Vengono trattati soltanto i dati personali strettamente necessari a realizzare le predette
    finalità, sia in modalità cartacea sia con l’ausilio di strumenti elettronici.<br><br>
    La Società acquisisce i soli dati personali strettamente necessari all'espletamento dei
    servizi richiesti o delle finalità per cui sono stati raccolti fornendo la specifica informativa;
    particolare attenzione viene posta in caso di dati personali particolari, che vengono trattati
    solo se prettamente indispensabili.<br>
    La Società tratta i dati personali adottando le misure di sicurezza necessarie, siano esse
    fisiche che informatiche, in sintonia con quanto previsto dal Regolamento UE n. 679/2016
    e normativa vigente.<br>
    Al termine del trattamento, la Società provvede alla conservazione obbligatoria dei dati
    trattati e, in assenza di tale obbligo o decorso tale termine, provvede alla loro
    cancellazione o anonimizzazione.<br><br>
    Alcuni dati possono essere indispensabili e la loro assenza può rendere impossibile la
    gestione dei rapporti in essere. Tali dati vengono trattati dai nostri collaboratori, in qualità
    di Responsabili o di Autorizzati al trattamento; per taluni servizi, inoltre, utilizziamo
    outsourcer che svolgono per nostro conto, in Italia o all’estero, compiti di natura tecnica,
    organizzativa e operativa. Non viene effettuata alcuna diffusione dei dati personali, salvo
    che ciò non derivi da uno specifico obbligo di legge.<br><br>
    Ogni Interessato potrà conoscere quali sono i dati trattati dalla Società e, ove ne ricorrano
    le condizioni, esercitare i diversi diritti relativi al loro utilizzo (diritto di accesso, rettifica,
    aggiornamento, integrazione, cancellazione, limitazione al trattamento, alla portabilità, alla
    revoca del consenso al trattamento e di ottenere una copia dei propri dati laddove questi
    siano conservati in Paesi al di fuori dell’Unione Europea, nonché di ottenere indicazione
    del luogo nel quale tali dati vengono conservati o trasferiti) nonché opporsi per motivi
    legittimi ad un loro particolare trattamento e comunque al loro uso a fini commerciali, in tutto o in parte anche per quanto riguarda l’uso di modalità automatizzate rivolgendosi a:
    Generali Business Solutions S.c.p.a., privacy.it@generali.com o al Responsabile della
    Protezione dei Dati (RPD), contattabile via e-mail a “RPD.it@generali.com” e/o via posta
    ordinaria all’indirizzo “RPD Generali Italia - Mogliano Veneto, Via Marocchesa 14 31021.<br><br>
    La informiamo, inoltre, che, qualora ravvisi un trattamento dei Suoi dati non coerente con i
    consensi da Lei espressi può sporgere reclamo al Garante per la protezione dei dati
    personali, con le modalità indicate sul sito del Garante stesso.<br><br>
    Anche in considerazione di futuri cambiamenti che potranno intervenire sulla normativa
    privacy applicabile, la Società potrà integrare e/o aggiornare, in tutto o in parte, la presente
    Informativa. Resta inteso che qualsiasi modifica, integrazione o aggiornamento Le sarà
    comunicato in conformità alla normativa vigente anche a mezzo di pubblicazione sul sito
    internet della Società.<br><br>
    </h5>
    <div fxLayoutAlign="start center" class="privacy-title-container">
    <h2>Privacy e Sicurezza del Sito
    </h2>
  </div>
    <br><br>
    <h5>
      Generali Business Solutions S.c.p.a. presta la massima attenzione alla riservatezza, alla
      tutela e alla sicurezza delle informazioni in proprio possesso, ancor più se relative a dati
      personali dei clienti e dei soggetti con cui entra in contatto o che raccoglie presso gli utenti
      ("<b>Utenti</b>").<br><br>
      Nel presente documento sono analizzate le modalità di gestione del sito in riferimento al
      trattamento dei dati personali degli Interessati che vi navigano. Si tratta di una informativa
      sul trattamento dei dati personali ai sensi degli articoli 13 e 14 del Regolamento UE n.
      679/2016 del 27 aprile 2016 ("<b>Regolamento</b>"), resa a coloro che interagiscono con i servizi
      erogati, tramite internet, da Generali Business Solutions S.c.p.a.<br><br>
      Gli Interessati che utilizzano il sito sono pertanto invitati a visitare le sezioni dell'area
      Privacy che raccolgono le linee guida adottate dalla Compagnia in materia di tutela dei dati
      personali. Si raccomanda di consultare l’informativa resa ai sensi degli articoli 13 e 14 del
      Regolamento UE n. 679/2016 del 27 aprile 2016 per avere maggiori informazioni in merito
      al trattamento dei dati personali.<br><br>
      L'informativa è resa ed è valida per i soli siti di Generali Business Solutions S.c.p.a. e non
      anche per altri siti web eventualmente consultabili tramite link esterni.<br><br>
      Gli Interessati che volessero entrare in contatto con noi o avvalersi della nostra
      consulenza in via del tutto facoltativa, dopo aver preso visione dell'Informativa, potranno
      fornirci i soli dati strettamente necessari all'evasione delle richieste effettuate. Per il
      trattamento di tali dati non sarà necessario il consenso degli Interessati in quanto Generali
      Business Solutions S.c.p.a. risponderà a richieste derivanti direttamente da questi ultimi.<br><br>
      Al fine di garantire il massimo rispetto della normativa privacy vigente, Generali Business
      Solutions S.c.p.a. si è data un'organizzazione specifica, descritta nella sezione Privacy in
      Generali Business Solutions S.c.p.a.<br><br>
      La navigazione nel nostro sito per la sola attività di consultazione non prevede alcuna
      richiesta di dati personali; utilizziamo tuttavia tecnologie che comportano la memorizzazione di alcuni dati relativi agli strumenti utilizzati, in qualche modo riconducibili
      al Navigatore.<br><br>
      Si riportano qui di seguito alcune notizie circa le modalità di raccolta attiva e passiva delle
      informazioni relative ai soggetti/strumenti che interagiscono con questo sito, nonché le
      misure adottate in materia di sicurezza.<br><br>
      Nel corso della navigazione all'interno di un sito internet è tecnicamente possibile, anche
      in assenza di una esplicita registrazione da parte degli Utenti e senza un suo ruolo attivo,
      effettuare una raccolta di informazioni che, per tali motivi, viene definita passiva. In
      particolare, sono di seguito descritte le modalità di utilizzo di Indirizzi IP, Cookies ed altri
      identificatori di sessione, Internet tags, dati di navigazione, compresa l'eventuale
      possibilità di escluderli e le relative implicazioni.<br><br>
      Per quanto riguarda la <b>raccolta passiva di informazioni</b> il sito:
      <ul>
        <li>
          non tratta indirizzi di Protocollo Internet (cd. indirizzi IP) per la raccolta di
          informazioni, ma li memorizza nei dati di navigazione;
        </li>
        <li>
          utilizza Dati di Navigazione solo per fini statistici come dati aggregati;
        </li>
        <li>
          utilizza Cookies tecnici ed altri identificatori di sessione propri e di terze parti.
        </li>
        <li>
          I cookie tecnici vengono utilizzati al fine di rendere possibile la navigazione o nella
          misura strettamente necessaria per fornire un servizio richiesto dall’utente, nel
          rispetto della Direttiva 2002/58/CE, come implementata nella normativa privacy
          vigente. Per queste finalità non viene richiesto il consenso degli Utenti.
        </li>
        <li>
          I cookie tecnici di terza parte vengono utilizzati per fini statistici - in forma anonima -
          allo scopo di fornire agli utenti un servizio in linea con le loro preferenze.
          Eventualmente è possibile disabilitare, in funzione del browser in uso, l'utilizzo dei
          cookie; in questo caso la navigazione non potrà essere parimenti agevole. Tale
          disattivazione potrà avvenire mediante il rinvio a siti di terze parti, mediante link
          all’interno della cookie policy di Generali Business Solutions S.c.p.a. o attraverso la
          modifica delle impostazioni del browser utilizzato:
          <ul>
            <li>
              <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a>
            </li>
            <li>
              <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank">Mozilla Firefox</a>
            </li>
            <li>
              <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Internet Explorer</a>
            </li>
            <li>
              <a href="https://help.opera.com/en/latest/web-preferences/" target="_blank">Opera</a>
            </li>
            <li>
              <a href="https://support.apple.com/kb/ph21411?locale=en_US" target="_blank">Safari</a>
            </li>
          </ul>
        </li>
      </ul>

      Per quanto riguarda la <b>raccolta attiva di informazioni</b> - se prevista - vale quanto segue:

      <ul>
        <li>
          E-Mail: i dati pervenuti tramite il contatto e-mail del sito Web vengono utilizzati
          esclusivamente per rispondere alle richieste dell’Utente; tali dati vengono conservati
          ai soli fini statistici e di verifica dell'esistenza di eventuali precedenti accessi.
        </li>
      </ul>
    </h5>
  <div class="space20"></div>
  <div class="space20"></div>
</div>