<label *ngIf="!onSurveyEdit" class="custom-radio-container">
  <ng-content></ng-content>
  <input [checked]="simpleValue" [tabindex]="_tabIndex"
    type="radio" [name]="name" [hidden]="_showLoader" [id]="_id"
    (change)="changeValue($event)">
  <span class="custom-radio-checkmark"></span>
</label>

<label *ngIf="onSurveyEdit" class="custom-radio-container">
    <input [value]="value"
      type="radio" [name]="name" [id]="id"
      [(ngModel)]="model">
    <span class="custom-radio-checkmark"></span>
    <p>{{text}}</p>
  </label>
  
