import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";

import { UserAcknowledges, UserOptions } from "atfcore-commonclasses/bin/classes/anag";
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { TalentFarmSeniorityLevel } from "atfcore-commonclasses/bin/classes/talent-farm";

import { UrlService } from "src/app/shared/services/url.service";
import { TranslateService } from "@ngx-translate/core";

import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';

@Injectable()
export class AnagService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService,
    private http: HttpClient, private store: Store<fromApp.AppState>,
    public translate: TranslateService) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Retrieve all userAcknowledges information (privacy acceptance, application tour completed, etc) accessing data by userId field
   * @return {UserAcknowledges} Retrieved data
   */
  getAllUserAcknowledges(): Observable<SenecaResponse<UserAcknowledges>> {
    return this.http.get<SenecaResponse<UserAcknowledges>>(this._mediatorUrl + 'get-all-userAcknowledges');
  }

  /**
   * Set the surname and forename for thel logged user (usually needed after the first login via mail link)
   * @param {string} forename - Optional: forename of the logged user
   * @param {string} surname - Optional: surname of the logged user
   * @param {string} zipCode - Optional: zipCode of the logged user
   * @param {string} qualification - Optional: study qualification of the logged user
   * @param {Date} birthDate - Optional: birth date of the logged user
   * @param {TalentFarmSeniorityLevel} seniority - Optional: the seniority level
   * @param {string[]} contentAreaIds - Optional: a list of tagId that represent user's interests
   * @param {boolean} privacyAck - Optional: true if the user has accepted the privacy
   * @param {boolean} dataSharingPrivacyAck
   * @param {boolean} usageAnalyticsPrivacyAck
   * @param {string[]} professionalFieldIds
   * @param {boolean} newsletterSubscribed
   * @return {string} The new user token with all data set
   */
  setMyUserData(forename?: string, surname?: string, zipCode?: string, degree?: string, birthDate?: Date, privacyAck?: boolean,
    professionalFieldIds?: string[], gender?: string, firstContact?: string, qualification?: string, newsletterSubscribed?: boolean, seniority?: TalentFarmSeniorityLevel, contentAreaIds?, dataSharingPrivacyAck?: boolean, usageAnalyticsPrivacyAck?: boolean): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'set-my-user-data', {
      forename,
      surname,
      zipCode,
      degree,
      birthDate,
      seniority,
      contentAreaIds,
      privacyAck,
      professionalFieldIds,
      newsletterSubscribed,
      gender,
      firstContact,
      qualification,
      dataSharingPrivacyAck,
      usageAnalyticsPrivacyAck
    });
  };

  getCallbackUrl() {
    const baseUrl = this.urlService.getApplicationUrl().url;
    return baseUrl + '/instagram.html';
  }

  // Cambia il check sulla newsletter
  updateNewsletter(newsletterSubscribed: boolean): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._mediatorUrl + 'set-my-user-data', {
      newsletterSubscribed: newsletterSubscribed
    });
  };

  /**
   * Update all userAcknowledges information (privacy acceptance, application tour completed, etc) accessing data by userId field.
   * All fileds that are not specifically set to null are left unchanged: that is this method update the
   * passed fields, append new fileds and left unchanged non passed fields.
   * @param {UserAcknowledges} userAcknowledges
   * @returns null
   */
  updateUserAcknowledges(userAcknowledges: UserAcknowledges): Observable<SenecaResponse<null>> {
    return this.http.post<SenecaResponse<null>>(this._mediatorUrl + 'update-userAcknowledges', {
      userAcknowledges
    });
  }

  /**
   * Update all userOptions information (langCode, timezone, etc) accessing data by userId field.
   * All fileds that are not specifically set to null are left unchanged: that is this method update the
   * passed fields, append new fileds and left unchanged non passed fields.
   * @param {UserOptions} userOptions - Required by the action: The UserOptions object to update
   * @return {string} The new token for the logged user
   */
  updateUserOptions(userOptions: UserOptions): Observable<SenecaResponse<string>> {
    return this.http.post<SenecaResponse<string>>(this._cm2MediatorUrl + 'update-userOptions', {
      userOptions
    });
  };

  // Manda la mail di recupero password
  sendMailRecoverPassword(email: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'init-password-recovery', {
      email: email
    });
  }

  chengeNewPassword(userId: string, validationToken: string, newPassword: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'recover-user-password', {
      userId: userId,
      validationToken: validationToken,
      newPassword: newPassword
    });

  }
}
