<div class="input-wrapper">
  <label *ngIf="title" class="control-label" for="searchinput">{{ title }}</label>
  <div [ngClass]="inputContainerClass">
    <textarea [(ngModel)]="bindValue" [name]="name" [required]="required" [placeholder]="placeholder"
      attr.aria-label="{{ attrAriaLabel }}" [disabled]="disabled" (ngModelChange)="emitOnModelChanged($event)"
      #textareaEl="ngModel"></textarea>

    <p *ngIf="subtitle" class="help-block">{{ subtitle }}</p>
  </div>
  <div fxLayout="row" *ngIf="textareaEl.invalid && (textareaEl.dirty || textareaEl.touched)"
    class="input-error-message">
    <div *ngIf="textareaEl.errors.required">
      {{ errorRequiredText }}
    </div>
    <div *ngIf="textareaEl.errors.minlength">
      {{ errorMinLengthText }}
    </div>
    <div *ngIf="textareaEl.errors.maxlength">
      {{ errorMaxLengthText }}
    </div>
    <div *ngIf="textareaEl.errors.forbiddenName">
      {{ errorForbiddenText }}
    </div>
  </div>
</div>