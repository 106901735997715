import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "input-icon",
  templateUrl: "./input-icon.component.html",
  styleUrls: ["./input-icon.component.scss"]
})
export class InputIconComponent implements OnInit {

  @Input() placeholder: string = '';
  @Input() iconSrc: string;
  @Input() inputValue: FormControl;
  @Input() passwordVisibility: boolean;
  @Output() onModelChanged: EventEmitter<any> = new EventEmitter();

  _tabIndex: string = "0";
  iconSrcForPasswordVisibility: string = "assets/icons/user/password-visible-off.svg";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      const element = document.getElementById(this._id);
      if (element) {
        element.setAttribute("tabindex", value);
      }
    }
  };

  _type: string;
  @Input() set type(value: string) {
    this._type = value || 'text';
  }

  _showLoader: boolean;
  @Input() set showLoader(value: boolean | string) {
    this._showLoader = coerceBooleanProperty(value);
  };

  _id: string;
  @Input() id: string | number;

  get inputIsFilled(): boolean {
    return this.inputValue !== undefined && this.inputValue !== null && this.inputValue.value;
  }

  constructor() { }

  ngOnInit() {

    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "input-icon-" + this.id;

  }

  /**
   * Clear the text
   */
  clearText() {
    this.inputValue.setValue(undefined);
    this.focusOnInput();
  }

  emitOnModelChanged(event?) {
    if (this.onModelChanged && this.onModelChanged instanceof EventEmitter) {
      this.onModelChanged.emit(event);
    }
  }

  /**
   * Focus on the input
   */
  focusOnInput() {
    setTimeout(() => {
      const element: HTMLElement = document.getElementById(this._id);;
      element.focus();
    }, 0);
  }

  toInputTextPasswordToggle() {
    if(this._type == 'password') {
      this._type = "text";
      this.iconSrcForPasswordVisibility = "assets/icons/user/password-visible-on.svg";
    } else {
      this._type = "password";
      this.iconSrcForPasswordVisibility = "assets/icons/user/password-visible-off.svg";
    }
  }
}
