<ng-container *ngIf="loggedUser && applicationLang">

  <header>
    <div class="content-wrapper">
      <div class="header">
        <h2>{{ 'support.TITLE' | translate }}</h2>
        <p class="header__subtitle">
          {{ 'support.SUBTITLE' | translate }}
        </p>
      </div>
      <img class="header__img" src="assets/icons/faq-man.svg">
    </div>
  </header>

  <section class="faq">
    <h3>{{ 'support.FAQ' | translate }}</h3>

    <div class="faq__list" *ngIf="!isLoadingFaq && faqList && faqList.length">
      <expandable-list [list]="faqList"></expandable-list>
    </div>

    <!-- Loader -->
    <div *ngIf="isLoadingFaq">
      <generali-loader></generali-loader>
    </div>
  </section>

  <section class="support">
    <h3>{{ 'support.HELP' | translate }}</h3>

    <div>
      <p>
        {{ 'support.OPEN_TICKET' | translate }}
      </p>

      <a (click)="sendOutboundLinkEvent()" target="_blank" [href]="ticketUrl">
        {{ ticketUrl }}
      </a>
    </div>

    <div>
      <p>{{ 'support.TICKET_GUIDE' | translate }}</p>
      <a [href]="tickerGuidePath" target="_blank">{{ 'support.CLICK_HERE' | translate }}</a>
    </div>
  </section>

  <section>
    <app-page-views-counter class="side-padding py-1"></app-page-views-counter>
  </section>

  <app-footer></app-footer>
</ng-container>