import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

declare const $: any;
@Component({
  selector: "chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit {
  id: string;
  @Input() title = "users.CHAT_WITH_HRBP";
  alignRight = "end";
  alignLeft = "start";

  @Input() msgs = [];
  @Input() msgsChatLoading = false;
  @Input() filterId?: string;

  message = "";
  @Input() placeholder = "generic.WRITE_SOMETHING"; //TODO: => @Input

  @Output() newMsg: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  textChanged(newText: string) {
    this.message = newText;
  }

  sendMsg() {
    this.newMsg.emit(this.message);
    this.message = "";
  }
}
