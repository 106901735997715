import { TranslateService } from '@ngx-translate/core';
import { CommonConstants, Engagement, Item, ItemAttribute, ItemAttributeTypes, ItemChild, ItemLang, ItemTenant, ItemTypes, ItemVisibility, ReferenceTypes, sortByRules, MailSender, Lang, ItemTakerEnrollStatusTypes } from "atfcore-commonclasses";
import { ExtendedItemAttribute } from 'src/app/home/item-details/models/extended-item-attribute.model';
import { ExtendedItem } from 'src/app/home/item-details/models/extended-item.model';
import { InitiativeUtils } from 'src/app/home/item-details/models/initiatives.model';
import { ItemUtil } from 'src/app/home/item-details/models/item.model';
import * as moment from 'moment';
import { LangsService } from 'src/app/core/services/langs.service';
import { UrlService } from 'src/app/shared/services/url.service';
import { DateUtil, defaultTo, parseBoolean } from 'src/commonclasses';

class FrontEndDate {
    courseEditionIndex: number;
    markerClass: string;
    editionId: string;
    dayId: string;
    address: string;
    room: string;
    dayDate: string;
    schedules: {
        startTime: string;
        endTime: string;
    }[];
    stopDate: string;
    status: string;
    place: string;
    invited: number;
    roomCapacity: number;
    seatsLimit: number;
}

/**
 * CourseStage rappresenta il "CORSO" cioè l'accoppiamento logico (iniziativa - modulo) manipolando le edizioni
 */
export class CourseStage {
    // item id del course module
    moduleItemId: string;
    // item id dell'iniziativa (che sarà sempre un corso o una tappa nel contesto delle edizioni)
    stageItemId: string;

    initiativeType: string;

    // informazioni da salvare nell'iniziativa (che sarà sempre un corso od una tappa nel contesto delle edizioni)
    courseValueInMinutes: number; // numero di ore convertite in minuti in scrittura
    multipleEditionAttendance: boolean; // permette iscrizione a più edizioni

    isPublicInitiative: boolean;

    constructor() {
        this.multipleEditionAttendance = false;
    }

    // courseValue in hours
    get courseValue() {
        return this.courseValueInMinutes ? (this.courseValueInMinutes / 60) : 0;
    }
    set courseValue(_value: any) {
        this.courseValueInMinutes = (_value ? parseInt(_value, 10) : 0) * 60;
    }
}

/**
 * COURSE_EDITION
 */
export class CourseEdition {
    // item id dell'edizione
    itemId: string;

    get isOnlineCourse() {
        return this.parentCourse && InitiativeUtils.isOnlineInitiative(this.parentCourse.initiativeType);
    }

    get isWebinar() {
        return this.parentCourse && InitiativeUtils.isWebinar(this.parentCourse.initiativeType);
    }

    get hasMoreDays() {
        return this.courseSchedules && this.courseSchedules.length > 1;
    }

    parentCourse: CourseStage;

    firstDayDate: string; // data iso con time
    lastDayDate: string; // data iso con time
    endEnrollDate: string; // data iso con time
    courseSchedules: Array<CourseDaySchedule>;

    status: string;

    isSelected: boolean;
    invitedPeople: any[];

    courseEditionFunded: boolean;
    limitedSeatsCourseEdition: boolean;
    autoPublish: boolean;
    editionName?: string;
    // isPublicEdition: boolean;
    // spostato l'attributo a livello di iniziativa
    // preRegistrationRequired?: boolean;

    managerNotification?: boolean;
    managerApprovalRequired?: boolean;
    managerApproved: boolean;
    managerApprovalLimitDate: string;

    hrbpNotification?: boolean;
    hrbpApprovalRequired?: boolean;
    hrbpApproved: boolean;
    hrbpApprovalLimitDate: string;

    subscriptionCustomLink?: string;
    subscriptionCustomMail?: string;
    subscriptionInfoMail?: string;

    itemLangs?: ItemLang[];

    seatsLimit: number;
    get availableSeats(): number {
        return (!this.usedSeats && this.seatsLimit)
            || (this.seatsLimit && this.usedSeats >= 0 && (this.seatsLimit - this.usedSeats)) || 0;
    }
    usedSeats?: number;
    overbookingLimit: number;
    get availableWithOverbooking(): number {
        return (!this.usedSeats && this.seatsLimit)
            || (this.overbookingLimit && this.usedOverbooking >= 0 && (this.overbookingLimit - this.usedOverbooking)) || 0;
    }
    usedOverbooking?: number;

    trainingInitiativeType: string;

    itemAttributes: ItemAttribute[];

    reminderActive: boolean;
    reminderDate: string;
    solicitsActive: boolean;
    firstSolicitAfterHours: number;
    secondSolicitAfterHours: number;
    thirdSolicitAfterHours: number;
    recurrentSolicitsActive: boolean;
    recurrentSolicitsAfterHours: number; // Ogni quante ore
    recurrentSolicitsDeadlineAfterDays: number; // Per quanti giorni 
    recurrentSolicitsAfterHoursAfterDeadline: number; // Ogni quante ore terminato il primo ciclo di solleciti
    calendarTypeOnConfirm: string;
    eTicket: boolean;
    enableUsableTakes: boolean;
    enableUsableTakesHint: string;
    maxUsableTakes: number;
    enableCustomTitleAndSubtitle: boolean;
    customTitle: string;
    customSubTitle: string;
    datesByPlace: any[];
    /** counter step invitati */
    invitedPeopleCounter: number;
    /** counter step edizioni */
    invited?: number;
    approvalStatus?: string;
    engagements?: Engagement[];
    isAlreadyCertified?: boolean;
    isItemAlreadyCertified?: boolean;
    certifiedDate?: Date;
    isSurveyStarted?: boolean;
    isSurveyCertificable?: boolean;
    isConsumed?: boolean;

    constructor() {
        this.parentCourse = new CourseStage();
        this.courseSchedules = new Array<CourseDaySchedule>();
        this.calendarTypeOnConfirm = 'USER_ONLY';
        this.invitedPeopleCounter = 0;
        this.invited = 0;
        this.firstDayDate = null;
        this.lastDayDate = null;
    }
}

export class CourseEditionOptions extends CourseEdition {
    confirmed: boolean;
    isOpened: boolean;
    isOnlineCourseType: boolean;
    isWebinarType: boolean;
    initiativeName?: any;
    fieldName?: any;
    confirmAction?: Function;
    onCloseAction?: Function;
    isFetchingSignatures?: boolean;
    signatureList?: any[];
    selectedSignature?: any;
    fromEmailList?: any[];
    selectedFromEmail?: MailSender;
    isLoadingRecipients?: boolean;

    constructor() {
        super();
        this.isOpened = false;
        this.calendarTypeOnConfirm = 'USER_ONLY';
        this.onCloseAction = () => { };
    }

    // Deve essere valorizzata la data del reminder se c'è il flag attivo
    isReminderValid() {
        if (this.reminderActive && !this.reminderDate) {
            return false;
        }

        return true;
    }

    // Deve essere valorizzato il titolo custom se c'è il flag attivo
    isCustomTitleValid() {
        if (this.enableCustomTitleAndSubtitle && (!this.customTitle || !this.customTitle.length)) {
            return false;
        }

        return true;
    }

    // Deve essere valorizzato il numero se è presente il check sull'abilitazione della prenotazione
    isMaxUsableTakesValid() {
        if (this.enableUsableTakes && !this.maxUsableTakes) {
            return false;
        }

        return true;
    }

    isConfirmEnabled() {
        if (this && this.parentCourse && (
            !this.parentCourse.isPublicInitiative
            || (
                !!this.selectedFromEmail
                && !!this.selectedSignature
            )) && this.isReminderValid() && this.isCustomTitleValid() && this.isMaxUsableTakesValid()) {
            return true;
        };
        return false;
    };
}

/**
 * COURSE_DAY
 */
export class CourseDaySchedule {
    itemId: string;
    dayDate: string;
    firstAndLastTimeSchedule: TimeSchedule;  // attribute order 0 usato per gli ONLINE
    morningTimeSchedule: TimeSchedule; // attribute order 0
    afternoonTimeSchedule: TimeSchedule; // attribute order 1
    location: Location;
    webinarTopicAccountId: any;
    webinarSessionId: string;
    webinarSessionType: any;
    webinarDisplayPartecipantsName: any;
    webinarAccessBeforeStart: any;
    webinarLobby: boolean;
    webinarPassword: string;
    webinarBreakoutRooms: boolean;
    webinarScreenSharing: boolean;
    webinarMediaLibrary: boolean;
    webinarRecording: boolean;
    webinarRequestToSpeak: boolean;

    teachers: Array<any>;
    tutors: Array<any>;
    itemAttributes: ItemAttribute[];
    isCourseDayFormValid?: boolean;

    get timeSchedules() {
        let _timeSchedules: TimeSchedule[] = [];
        _timeSchedules = this.morningTimeSchedule ? _timeSchedules.concat([this.morningTimeSchedule]) : _timeSchedules;
        _timeSchedules = this.afternoonTimeSchedule ? _timeSchedules.concat([this.afternoonTimeSchedule]) : _timeSchedules;
        _timeSchedules = this.firstAndLastTimeSchedule ? _timeSchedules.concat([this.firstAndLastTimeSchedule]) : _timeSchedules;
        return _timeSchedules;
    }

    getFirstValidTimeScheduleByOrder(reverse: boolean) {
        let firstSchedule = this.timeSchedules.filter((schedule) => {
            return !!schedule.startTime;
        }).sort((x2, y2) => {
            return sortByRules<TimeSchedule>(x2, y2, !!reverse, [{ fieldName: 'startTime' }])
        })[0];
        return firstSchedule;
    }

    constructor() {
        this.location = new Location();
    }
}

export class Location {
    physicalPlaceItem: Item;
    address: any;
    streetNumber: any;
    place: any;
    city: any;
    room: any;
    roomCapacity: number;
    locationLat: number;
    locationLong: number;
    building: any;

    constructor() {
        this.physicalPlaceItem = new Item();
        this.place = null;
        this.address = null;
        this.room = null;
        this.building = null;
    }

    locationNameForCard() {
        return (this.place || this.city || this.address) && (this.place || this.city || this.address).id
    }

    getBuilding() {
        return this.building;
    }
}

export class TimeSchedule {
    startTime: string; endTime: string;
}

const locationAttrKeys = [ItemAttributeTypes.LOCATION_ADDRESS,
ItemAttributeTypes.LOCATION_BUILDING,
ItemAttributeTypes.LOCATION_CITY, ItemAttributeTypes.LOCATION_COUNTRY,
ItemAttributeTypes.LOCATION_FLOOR,
ItemAttributeTypes.LOCATION_LAT, ItemAttributeTypes.LOCATION_LONG,
ItemAttributeTypes.LOCATION_PHONE, ItemAttributeTypes.LOCATION_PLACE,
ItemAttributeTypes.LOCATION_SAP_CODE, ItemAttributeTypes.LOCATION_ZIP,
ItemAttributeTypes.LOCATION_ARRANGEMENT, ItemAttributeTypes.LOCATION_SAP_CODE,
ItemAttributeTypes.LOCATION_ROOM, ItemAttributeTypes.LOCATION_ROOM_CAPACITY];

export class LocationItem extends Item {
    fullName: ItemAttribute;
    fullAddress: ItemAttribute;
    address: ItemAttribute;
    city: ItemAttribute;
    place: ItemAttribute;
    country: ItemAttribute;
    room: ItemAttribute;
    roomCapacity: ItemAttribute;
    locationLat: ItemAttribute;
    locationLong: ItemAttribute;
    sapCode: ItemAttribute;
    phone: ItemAttribute;
    building: ItemAttribute;
    floor: ItemAttribute;
    arrangement: ItemAttribute;
    zipCode: ItemAttribute;

    constructor(item: Item) {
        super();
        Object.assign(this, item);

        this.fullName = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_FULL_NAME) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_FULL_NAME, null);
        this.fullAddress = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_FULL_ADDRESS) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_FULL_ADDRESS, null);
        this.address = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ADDRESS) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ADDRESS, null);
        this.room = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ROOM) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ROOM, null);
        this.city = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_CITY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_CITY, null);
        this.place = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_PLACE) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_PLACE, null);
        this.country = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_COUNTRY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_COUNTRY, null);
        this.roomCapacity = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ROOM_CAPACITY) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ROOM_CAPACITY, null);
        this.locationLat = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_LAT) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_LAT, null);
        this.locationLong = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_LONG) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_LONG, null);
        this.sapCode = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_SAP_CODE) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_SAP_CODE, null);
        this.zipCode = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ZIP) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ZIP, null);
        this.arrangement = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_ARRANGEMENT) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_ARRANGEMENT, null);
        this.phone = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_PHONE) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_PHONE, null);
        this.building = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_BUILDING) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_BUILDING, null);
        this.floor = ItemUtil.getAttributeByKey(item, ItemAttributeTypes.LOCATION_FLOOR) || ItemUtil.setKeyValueAttribute(this, ItemAttributeTypes.LOCATION_FLOOR, null);
    }

    buildFullName() {
        return `${(this.place || this.city).attributeValue} - ${(this.address).attributeValue} - ${this.room.attributeValue}`.toUpperCase();
    }
}

export function newLocationItem(tenant, langCode, title, subTitle?: string, description?: string) {
    let itemLangs = [<ItemLang>{
        langCode: langCode,
        title: title,
        subTitle: subTitle,
        description: description
    }];
    let itemTenants = [<ItemTenant>{
        tenant: tenant,
        owner: true
    }];
    let itemVisibilities: ItemVisibility[] = [];

    // creo gli attributi necessari vuoti
    let itemAttributes: ItemAttribute[] = locationAttrKeys.map((key, i) => <ItemAttribute>{
        attributeType: key,
        attributeValue: null,
        attributeOrder: 0
    });

    return new LocationItem(<Item>{
        itemType: ItemTypes.PHYSICAL_PLACE,
        indexed: false,
        unpublished: false,
        originApplicationName: CommonConstants.APPLICATION_COURSE_MANAGER,
        itemAttributes: itemAttributes,
        itemLangs: itemLangs,
        itemTenants: itemTenants,
        itemVisibilities: itemVisibilities
    });
}

/*
* COURSE_MODULE utility
*/
export class CourseModuleUtil {
    static setCourseEditionOptionForSelect(courseEdition) {
        let labelForSelectOption = '<h6>';
        labelForSelectOption = labelForSelectOption + (courseEdition.address?.length ? (courseEdition.address.length > 28 ? (courseEdition.address.substring(0, 28) + '...<br>') : (courseEdition.address + '<br>')) : '') + (courseEdition.selectedRoom || courseEdition.room);

        if (courseEdition.days && courseEdition.days.length) {
            for (let m = 0, daysLength = courseEdition.days.length; m < daysLength; m++) {
                const currentDay = courseEdition.days[m];
                labelForSelectOption = labelForSelectOption + '<br><b>' + currentDay.formattedFullDate + '</b>';

                if (currentDay.formattedTimeSchedules && currentDay.formattedTimeSchedules.length) {
                    for (let q = 0, timesLength = currentDay.formattedTimeSchedules.length; q < timesLength; q++) {
                        labelForSelectOption = labelForSelectOption + ' ' + currentDay.formattedTimeSchedule;
                    }
                }
            }
        }

        // Orari
        labelForSelectOption = labelForSelectOption + "</h6>";

        return labelForSelectOption;
    }

    static setWebinarCourseEditionOptionForSelect(courseEdition) {
        let labelForSelectOption = '<h6>';

        if (courseEdition.days && courseEdition.days.length) {
            for (let m = 0, daysLength = courseEdition.days.length; m < daysLength; m++) {
                const currentDay = courseEdition.days[m];
                if (m !== 0) {
                    labelForSelectOption = labelForSelectOption + '<br>';
                }
                labelForSelectOption = labelForSelectOption + '<b>' + currentDay.formattedFullDate + '</b> ' + currentDay.formattedTimeSchedule;
            }
        }

        // Orari
        labelForSelectOption = labelForSelectOption + "</h6>";

        return labelForSelectOption;
    }

    // Formatta le date unendole tramite il luogo
    static formatEditionsByPlace(courseEditionList: any[], sort?: boolean, isExternalCourse?: boolean, translateService?: TranslateService) {
        const editionsByPlace = [];

        const places: any = {};

        // raggruppare per il luogo della prima giornata dell'edizione
        courseEditionList && courseEditionList.forEach((courseEdition: any) => {
            if (courseEdition.days && courseEdition.days.length) {
                let courseDay = courseEdition.days[0];
                const placeId = locationNameForCard(courseDay.location);
                if (placeId && places[placeId]) {
                    courseEdition.room = this.getEditionRoom(courseEdition);
                    courseEdition.streetNumber = this.getEditionAddressTitle(courseEdition);
                    courseEdition.selectedRoom = this.getEditionSelectedRoom(courseEdition);
                    this.getLabelStatusToDisplay(courseEdition, isExternalCourse);
                    let arrayLength = null;
                    if (courseEdition.days.length < 4) {
                        arrayLength = courseEdition.days.length;
                    } else {
                        courseEdition.hasMoreDaysText = "+ " + translateService.instant('itemDetailsPage.edition.OTHERS') + " <b>" + (courseEdition.days.length - 2) + "</b> " + translateService.instant('itemDetailsPage.edition.DATES')

                        arrayLength = 2;
                    }
                    for (let i = 0; i < arrayLength; i++) {
                        let day = courseEdition.days[i];
                        day.formattedTimeSchedules = this.getFormattedTimeSchedules(day);
                    }

                    // Preparo la scritta per l'option che andrà nella select
                    const labelForSelectOption = this.setCourseEditionOptionForSelect(courseEdition);
                    courseEdition.labelForSelectOption = labelForSelectOption;

                    places[placeId].editions.push(courseEdition);
                } else if (placeId && !places[placeId]) {
                    places[placeId] = { editions: [] };
                    courseEdition.room = this.getEditionRoom(courseEdition);
                    courseEdition.streetNumber = this.getEditionAddressTitle(courseEdition);
                    courseEdition.selectedRoom = this.getEditionSelectedRoom(courseEdition);

                    this.getLabelStatusToDisplay(courseEdition, isExternalCourse);
                    let arrayLength = null;
                    if (courseEdition.days.length < 4) {
                        arrayLength = courseEdition.days.length;
                    } else {
                        courseEdition.hasMoreDaysText = "+ " + translateService.instant('itemDetailsPage.edition.OTHERS') + " <b>" + (courseEdition.days.length - 2) + "</b> " + translateService.instant('itemDetailsPage.edition.DATES')

                        arrayLength = 2;
                    }
                    for (let i = 0; i < arrayLength; i++) {
                        let day = courseEdition.days[i];
                        day.formattedTimeSchedules = this.getFormattedTimeSchedules(day);
                    }

                    // Preparo la scritta per l'option che andrà nella select
                    const labelForSelectOption = this.setCourseEditionOptionForSelect(courseEdition);
                    courseEdition.labelForSelectOption = labelForSelectOption;
                    places[placeId].editions.push(courseEdition);
                }
            }
        });

        for (const key in places) {
            if (places.hasOwnProperty(key)) {
                const element = places[key];
                let soldOut = true;

                let dates = [];

                // Per ogni luogo ricavo tutte le giornate dell'edizione affinché si possa costruire la card
                const editionsOfPlace = element.editions;
                if (editionsOfPlace && editionsOfPlace.length) {
                    let index = 1;
                    element.editions.forEach((courseEdition) => {
                        if (courseEdition.availableSeats && courseEdition.availableSeats > 0) {
                            soldOut = false;
                        }
                        courseEdition.days.forEach((courseDay: CourseDaySchedule) => {
                            if (courseDay.dayDate) {
                                const feDate = CourseEditionUtil.getItemDetailDayCard(index, courseEdition, courseDay, isExternalCourse);
                                dates.push(feDate);
                            }
                        })
                        index++;
                    })
                }

                editionsByPlace.push({
                    placeId: key,
                    editions: element.editions,
                    dates: dates,
                    soldOut: soldOut
                });
            }
        }

        // ordino per place
        return sort ? editionsByPlace.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'place' }])) : editionsByPlace;
    }

    static getLabelStatusToDisplay(courseEdition: any, isExternalCourse?: boolean) {
        let availableSeats = courseEdition.availableSeats || 0;

        // Da notare che se un corso è esterno le azioni dell'utente non ci sono: non può nè iscriversi nè cancellarsi, ma solo vedere i dettagli
        if (isExternalCourse) {
            courseEdition.labelStatusToDisplay = '';
            courseEdition.callToAction = 'DETAILS';
        } else {
            if (courseEdition.userStatus && courseEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT) {
                // Utente presente
                courseEdition.labelStatusToDisplay = 'present';
                courseEdition.callToAction = 'DETAILS';
            } else if (courseEdition && courseEdition.isConfirmed) {
                courseEdition.labelStatusToDisplay = 'subscribed';
                courseEdition.callToAction = 'DETAILS';
            } else {
                if (!availableSeats) {
                    courseEdition.labelStatusToDisplay = 'noAvailableSeats';
                } else {
                    courseEdition.labelStatusToDisplay = 'availableSeats';
                    courseEdition.callToAction = 'SUBSCRIBE';
                }
            }
        }
    }

    static getFormattedTimeSchedules(courseDay: any) {

        let formattedTimeSchedules = [];
        if (courseDay.timeSchedules && courseDay.timeSchedules.length) {
            for (let u = 0, schedulesLength = courseDay.timeSchedules.length; u < schedulesLength; u++) {
                let formattedTime = '';
                let currentTimeSchedule = courseDay.timeSchedules[u];

                if (currentTimeSchedule.startTime) {
                    formattedTime = formattedTime + moment(currentTimeSchedule.startTime).format('HH:mm');
                    if (currentTimeSchedule.endTime) {
                        formattedTime = formattedTime + '-';
                    }
                }

                if (currentTimeSchedule.endTime) {
                    formattedTime = formattedTime + moment(currentTimeSchedule.endTime).format('HH:mm');
                }

                if (u != (courseDay.timeSchedules.length - 1)) {
                    formattedTime = formattedTime + ' | ';
                }

                formattedTimeSchedules.push(formattedTime);
            }
            return formattedTimeSchedules;
        }
    }

    static getEditionRoom(edition: any) {
        if (edition && edition.days && edition.days.length) {
            let courseDay = edition.days[0];
            if (this.isRoomFilled(edition)) {
                return courseDay.location && courseDay.location.room && courseDay.location.room.title;
            } else {
                return '';
            }
        }
        return '';
    }

    static isRoomFilled(edition: any): boolean {
        let courseDay = edition && edition.days && edition.days && edition.days.length && edition.days[0];
        if (courseDay && courseDay.location && courseDay.location.room && courseDay.location.room.title) {
            return true;
        }

        return false;
    }

    static getEditionAddressTitle(edition: any) {
        if (edition && edition.days && edition.days.length) {
            let courseDay = edition.days[0];
            if (this.isAddressFilled(edition)) {
                return courseDay.location && courseDay.location.streetNumber && courseDay.location.streetNumber.title;
            } else {
                return '';
            }
        }
        return '';
    }

    static getEditionSelectedRoom(edition: any) {
        if (edition && edition.days && edition.days.length) {
            let courseDay = edition.days[0];
            return courseDay.selectedRoom;
        }
        return '';
    }

    static isAddressFilled(edition: any): boolean {
        let courseDay = edition && edition.days && edition.days && edition.days.length && edition.days[0];
        if (courseDay && courseDay.location && courseDay.location.streetNumber && courseDay.location.streetNumber.title) {
            return true;
        }

        return false;
    }

    // Apre la pagina della privacy
    static openPrivacy(privacyPage: string, urlService: UrlService) {
        let applicationContext = urlService.getApplicationUrl().baseUrl;
        applicationContext = applicationContext + '#/' + privacyPage;
        window.open(applicationContext, '_blank');
    }

    static listCourseEditionsFromModule(courseModuleItem: Item, initiative: Item, includeEngagements?: boolean, langsService?: LangsService, currentLang?: Lang, includeItemLangs?: boolean, isNewTableHome?: boolean): Array<CourseEdition> {
        const courseEditions = new Array<CourseEdition>();
        if (!courseModuleItem || !courseModuleItem.itemChilds) {
            return courseEditions;
        }

        for (let i = 0, arrLeng = courseModuleItem.itemChilds.length; i < arrLeng; i++) {
            const child: ItemChild = courseModuleItem.itemChilds[i];
            if (child.childObject && child.childObject.itemType === ItemTypes.COURSE_EDITION) {
                const courseEdition = CourseEditionUtil.getCourseEditionModelFromItem(child.childObject, initiative, courseModuleItem, langsService, currentLang, isNewTableHome);
                if (includeEngagements) {
                    courseEdition.engagements = child.childObject.engagements;
                }
                if (includeItemLangs) {
                    courseEdition.itemLangs = child.childObject.itemLangs;
                }
                courseEditions.push(courseEdition);
            }
        }

        return courseEditions;
    }

    static getCourseStageFromItem(initiative: Item, moduleItem?: Item) {
        const courseModule: CourseStage = new CourseStage();
        courseModule.moduleItemId = moduleItem && moduleItem.itemId;
        courseModule.stageItemId = initiative.itemId;
        courseModule.initiativeType = initiative.itemType;
        courseModule.courseValueInMinutes = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.VALUE);
        courseModule.isPublicInitiative = parseBoolean(ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.PUBLIC_EDITION));
        courseModule.multipleEditionAttendance = parseBoolean(ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.MULTIPLE_EDITION_ATTENDACE));
        return courseModule;
    }
}


export function locationNameForCard(location: Location) {
    if (location && location.place || location.city || location.address) {
        return location.place?.id || location.city?.id || location.address?.id;
    }
    return '';
}



/*
* COURSE_EDITION utility
*/
export class CourseEditionUtil {
    static createCourseEditionItem(courseEdition: CourseEdition, initiativeItem: Item, parentItemLangs?: ItemLang[]): Item {
        const editionItem = <Item>{};
        editionItem.itemId = courseEdition.itemId;
        editionItem.itemType = ItemTypes.COURSE_EDITION;
        // dev'essere impostato a true altrimenti tutti i taker che verranno associati ad esso verranno cancellati
        // al momento fissso
        editionItem.consumeAuthRequired = true;

        editionItem.title = 'COURSE_EDITION';

        // Se non è un corso online devo calcolare l'inizio e fine dalle giornate inserite nell'edizione

        // creo gli attributi
        CourseEditionUtil.fillCourseEditionItem(editionItem, courseEdition, initiativeItem);

        // ricostruisco la schedulazione
        CourseDayUtil.setItemCourseDaySchedules(editionItem, courseEdition.courseSchedules, courseEdition.isOnlineCourse, courseEdition.isWebinar);

        let itemLangs = parentItemLangs && parentItemLangs.length && JSON.parse(JSON.stringify(parentItemLangs));

        // Preparo il contenitore delle lang
        if (itemLangs) {
            editionItem.itemLangs = itemLangs;
            for (let i = 0, langsLength = editionItem.itemLangs.length; i < langsLength; i++) {
                let currentItemLang = editionItem.itemLangs[i];
                currentItemLang.title = editionItem.title;
                currentItemLang.subTitle = null;
            }
        }

        return editionItem;
    }

    static updateCourseEditionItem(editionItem: Item, courseEdition: CourseEdition, initiativeItem: Item, currentLang?: Lang, langsService?: LangsService): Item {
        if (courseEdition.itemAttributes && courseEdition.itemAttributes.length) {
            editionItem.itemAttributes = courseEdition.itemAttributes;
        }
        // aggiorno o creo gli attributi necessari
        CourseEditionUtil.fillCourseEditionItem(editionItem, courseEdition, initiativeItem, true, currentLang, langsService);

        let isOnline = courseEdition.parentCourse && InitiativeUtils.isOnlineInitiative(courseEdition.parentCourse.initiativeType)
            || (<CourseEditionOptions>courseEdition).isOnlineCourseType === true
            || editionItem && InitiativeUtils.isOnlineInitiative(editionItem.itemType);

        let isWebinar = courseEdition.parentCourse && InitiativeUtils.isWebinar(courseEdition.parentCourse.initiativeType)
            || (<CourseEditionOptions>courseEdition).isWebinarType === true
            || editionItem && InitiativeUtils.isWebinar(editionItem.itemType);

        // ricostruisco la schedulazione
        CourseDayUtil.setItemCourseDaySchedules(editionItem, courseEdition.courseSchedules, isOnline, isWebinar);

        return editionItem;
    }

    static fillCourseEditionItem(editionItem: Item, courseEdition: CourseEdition, initiativeItem: Item, update?: boolean, currentLang?: Lang, langsService?: LangsService) {
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.END_ENROLL_DATE, courseEdition.endEnrollDate, update);

        // const calculateFirstAndLastDay = !courseEdition.isOnlineCourse || (!courseEdition.parentCourse && !(<CourseEditionOptions>courseEdition).isOnlineCourseType);
        const calculateFirstAndLastDay = (!(courseEdition.parentCourse && InitiativeUtils.isOnlineInitiative(courseEdition.parentCourse.initiativeType))
            || (<CourseEditionOptions>courseEdition).isOnlineCourseType === false)
            && (
                !(courseEdition.parentCourse && InitiativeUtils.isWebinar(courseEdition.parentCourse.initiativeType))
                || (<CourseEditionOptions>courseEdition).isWebinarType === false);

        if (calculateFirstAndLastDay && courseEdition.courseSchedules && courseEdition.courseSchedules.length) {
            let firstDayDate = null;
            let lastDayDate = null;
            for (let index = 0; index < courseEdition.courseSchedules.length; index++) {
                const courseDay = courseEdition.courseSchedules[index];
                const currentStartTime = courseDay.morningTimeSchedule && courseDay.morningTimeSchedule.startTime;
                const currentEndTime = courseDay.afternoonTimeSchedule && courseDay.afternoonTimeSchedule.endTime;
                firstDayDate = firstDayDate || currentStartTime;
                lastDayDate = lastDayDate || currentEndTime;
                if (currentEndTime && new Date(currentEndTime) > new Date(lastDayDate)) {
                    lastDayDate = currentEndTime;
                }
                if (currentStartTime && new Date(currentStartTime) < new Date(firstDayDate)) {
                    firstDayDate = currentStartTime;
                }
            }
            courseEdition.firstDayDate = firstDayDate;
            courseEdition.lastDayDate = lastDayDate;

        } else {
            if (courseEdition.courseSchedules && courseEdition.courseSchedules.length) {
                courseEdition.firstDayDate = courseEdition.courseSchedules[0].firstAndLastTimeSchedule.startTime;
                courseEdition.lastDayDate = courseEdition.courseSchedules[0].firstAndLastTimeSchedule.endTime;
            }
        }
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.FIRST_DAY_DATE, courseEdition.firstDayDate, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.LAST_DAY_DATE, courseEdition.lastDayDate, update);

        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MAX_ENROLLMENTS, !!courseEdition.seatsLimit ? courseEdition.seatsLimit : null, update, 0, true);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_MAIL, courseEdition.subscriptionCustomMail, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.SUBSCRIPTION_INFO_MAIL, courseEdition.subscriptionInfoMail, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_LINK, courseEdition.subscriptionCustomLink, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MAX_OB_ENROLLMENTS, courseEdition.overbookingLimit, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.FUNDED_ITEM, courseEdition.courseEditionFunded, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.ITEM_WITH_LIMITED_SEATS, courseEdition.limitedSeatsCourseEdition, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.AUTO_PUBLISH, courseEdition.autoPublish, update);
        // ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.PUBLIC_EDITION, courseEdition.isPublicInitiative, update);
        // spostato l'attributo a livello di iniziativa
        /* ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.PREREGISTRATION_REQUIRED,
            courseEdition.preRegistrationRequired, update); */
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MANAGER_NOTIFICATION, courseEdition.managerNotification, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.HRBP_NOTIFICATION, courseEdition.hrbpNotification, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MANAGER_APPROVAL_REQUIRED,
            courseEdition.managerApprovalRequired, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.HRBP_APPROVAL_REQUIRED, courseEdition.hrbpApprovalRequired, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MANAGER_APPROVAL_LIMIT_DATE,
            courseEdition.managerApprovalLimitDate, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.HRBP_APPROVAL_LIMIT_DATE,
            courseEdition.hrbpApprovalLimitDate, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.HRBP_APPROVED, courseEdition.hrbpApproved, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.MANAGER_APPROVED, courseEdition.managerApproved, update);
        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.INITIATIVE_TYPE, courseEdition.trainingInitiativeType, update);

        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.STATUS, courseEdition.status, update);

        ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.REMINDER_ACTIVE, courseEdition.reminderActive, update);
        // Se il reminder non è attivo, devo cancellare la data
        if (courseEdition.reminderActive) {
            ItemUtil.setKeyValueAttribute(editionItem, ItemAttributeTypes.REMINDER_DATE, courseEdition.reminderDate, update);
        } else {
            ItemUtil.removeItemAttributeByKey(editionItem, ItemAttributeTypes.REMINDER_DATE);
        }

        // Lingua dell'edizione
        if (currentLang && currentLang.langCode && langsService && editionItem.itemLangs && editionItem.itemLangs.length) {
            let initiativeIndex: number = langsService.findItemLangIndex(currentLang.langCode, editionItem);
            if (courseEdition.enableCustomTitleAndSubtitle) {
                editionItem.itemLangs[initiativeIndex].title = courseEdition.customTitle;
                editionItem.itemLangs[initiativeIndex].subTitle = courseEdition.customSubTitle;
            } else {
                // Se non è abilitato il check, resetto i dati
                editionItem.itemLangs[initiativeIndex].title = 'COURSE_EDITION';
                editionItem.itemLangs[initiativeIndex].subTitle = null;
            }
        }

        // Se esiste l'attributo, lo aggiorno, altrimenti lo elimino
        if (courseEdition.solicitsActive) {
            ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.SOLICITS_ACTIVE, courseEdition.solicitsActive, update);
        } else {
            ItemUtil.removeItemAttributeByKey(initiativeItem, ItemAttributeTypes.SOLICITS_ACTIVE);
        }
        ItemUtil.setKeyValueAttribute(
            initiativeItem, ItemAttributeTypes.SOLICIT_AFTER_HOURS, courseEdition.firstSolicitAfterHours, update, 0)
            ;
        ItemUtil.setKeyValueAttribute(
            initiativeItem, ItemAttributeTypes.SOLICIT_AFTER_HOURS, courseEdition.secondSolicitAfterHours, update, 1)
            ;
        ItemUtil.setKeyValueAttribute(
            initiativeItem, ItemAttributeTypes.SOLICIT_AFTER_HOURS, courseEdition.thirdSolicitAfterHours, update, 2)
            ;
        // Se esiste l'attributo, lo aggiorno, altrimenti lo elimino        
        if (courseEdition.recurrentSolicitsActive) {
            ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_ACTIVE, courseEdition.recurrentSolicitsActive, update);
        } else {
            ItemUtil.removeItemAttributeByKey(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_ACTIVE);
        }
        ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS, courseEdition.recurrentSolicitsAfterHours, update);
        ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_DEADLINE_AFTER_DAYS, courseEdition.recurrentSolicitsDeadlineAfterDays, update);
        ItemUtil.setKeyValueAttribute(initiativeItem, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS_AFTER_DEADLINE, courseEdition.recurrentSolicitsAfterHoursAfterDeadline, update);
        ItemUtil.setKeyValueAttribute(
            editionItem, ItemAttributeTypes.CALENDAR_TYPE_ON_CONFIRM, courseEdition.calendarTypeOnConfirm, update
        );
        ItemUtil.setKeyValueAttribute(
            editionItem, ItemAttributeTypes.ETICKET_ON_CONFIRM, courseEdition.eTicket, update
        );
        ItemUtil.setKeyValueAttribute(
            editionItem, ItemAttributeTypes.ENABLE_CUSTOM_TITLE_AND_SUBTITLE, courseEdition.enableCustomTitleAndSubtitle, update, null, true
        );
        ItemUtil.setKeyValueAttribute(
            editionItem, ItemAttributeTypes.MAX_USABLE_TAKES, courseEdition.maxUsableTakes, update, null, true
        );
        ItemUtil.setKeyValueAttribute(
            editionItem, ItemAttributeTypes.MAX_USABLE_TAKES_HINT, courseEdition.enableUsableTakesHint, update, null, true
        );
    }

    static getFrontEndDateByCourseEditionAndDay(courseEditionIndex: number, courseEdition: CourseEdition,
        courseDay: CourseDaySchedule): FrontEndDate {
        const timeschedules = [];
        const markerClass = courseEdition.courseSchedules.length > 1 ? 'counter-marker red' : 'counter-marker grey';
        if (courseDay.morningTimeSchedule) {
            timeschedules.push({
                startTime: courseDay.morningTimeSchedule.startTime,
                endTime: courseDay.morningTimeSchedule.endTime
            });
        }
        if (courseDay.afternoonTimeSchedule) {
            timeschedules.push({
                startTime: courseDay.afternoonTimeSchedule.startTime,
                endTime: courseDay.afternoonTimeSchedule.endTime
            });
        }
        if (courseDay.firstAndLastTimeSchedule) {
            timeschedules.push({
                startTime: courseDay.firstAndLastTimeSchedule.startTime,
                endTime: courseDay.firstAndLastTimeSchedule.endTime
            });
        }

        return <FrontEndDate>{
            markerClass: markerClass,
            courseEditionIndex: courseEditionIndex,
            editionId: courseEdition.itemId,
            dayId: courseDay.itemId,
            dayDate: courseDay.dayDate,
            schedules: timeschedules,
            stopDate: courseEdition.endEnrollDate,
            status: courseEdition.status,
            address: courseDay.location.address && courseDay.location.address.title,
            place: courseDay.location.address && courseDay.location.address.title,
            room: courseDay.location.room && courseDay.location.room.title,
            roomCapacity: courseDay.location.roomCapacity,
            seatsLimit: courseEdition.seatsLimit,
            subscriptionCustomLink: courseEdition.subscriptionCustomLink,
            subscriptionCustomMail: courseEdition.subscriptionCustomMail,
            itemAttributes: courseEdition.itemAttributes,
            invited: courseEdition.invited
        };
    }

    /**
     * Se corso in presenza genera un nome dalla prima data disponibile, esempio: Roma - via Bissolati (RM), 30/22/2018. 
     * Se corso online, oppure Webinar, genera il nome dalla startDate: "Edizione 20/11/2018"
     * @param courseEdition 
     */
    static generateEditionName(courseEdition: CourseEdition, editionLabel: string, dateFormat: string) {
        let editionName = "";
        if (courseEdition.isOnlineCourse || courseEdition.isWebinar) {
            let firstDayDate = courseEdition.firstDayDate;
            // prendo la prima data
            editionName = editionLabel + " " + moment(firstDayDate).format(dateFormat);
        } else {
            let address = courseEdition.courseSchedules[0].location && courseEdition.courseSchedules[0].location.address && courseEdition.courseSchedules[0].location.address.title;
            let firstDayDate = courseEdition.firstDayDate;
            editionName = address + ", " + moment(firstDayDate).format(dateFormat);
        }
        return editionName;
    }

    // static getHtmlTemplateLang(attribute: ExtendedItemAttribute, applicationLang: string) {
    //     if (attribute && applicationLang && attribute.crossReferenceObject && attribute.crossReferenceObject.templateLangs) {
    //         for (let k = 0, templatesLangLength = attribute.crossReferenceObject.templateLangs.length; k < templatesLangLength; k++) {
    //             const currentLang = attribute.crossReferenceObject.templateLangs[k];
    //             if (currentLang.langCode && currentLang.langCode === applicationLang.toLowerCase()) {
    //                 attribute.subject = currentLang.subject;
    //                 attribute.htmlText = currentLang.text;
    //                 break;
    //             }
    //         }
    //     }
    // }

    static setHtmlTemplateLang(attribute: ExtendedItemAttribute, applicationLang: string, crossReferenceObject: any) {
        if (attribute && applicationLang && crossReferenceObject && crossReferenceObject.templateLangs) {
            for (let k = 0, templatesLangLength = crossReferenceObject.templateLangs.length; k < templatesLangLength; k++) {
                const currentLang = crossReferenceObject.templateLangs[k];
                if (currentLang.langCode && currentLang.langCode === applicationLang.toLowerCase()) {
                    attribute.subject = currentLang.subject;
                    attribute.htmlText = currentLang.text;
                    break;
                }
            }
        }
    }

    static getHtmlTemplateLang(attribute: ExtendedItemAttribute, applicationLang: string, templateService?: any, skipCallService?: boolean) {
        return new Promise((resolve, reject) => {
            if (attribute && attribute.referenceId && applicationLang) {
                if (attribute.crossReferenceObject && attribute.crossReferenceObject.templateLangs) {
                    this.setHtmlTemplateLang(attribute, applicationLang, attribute.crossReferenceObject);
                    resolve(true);
                } else {
                    if (skipCallService) {
                        resolve(true);
                    } else {
                        // Recupero il crossReferenceObject dell'attributo, che è il dettaglio del template
                        templateService.getTemplateById(attribute.referenceId)
                            .subscribe((templateDetails: any) => {
                                attribute.crossReferenceObject = {
                                    textTemplateType: (templateDetails.response && templateDetails.response.textTemplateType) || null
                                };
                                this.setHtmlTemplateLang(attribute, applicationLang, templateDetails.response);
                                resolve(true);
                            },
                                (err) => {
                                    reject()
                                });
                    }
                }
            } else {
                resolve(true);
            }
        });
    }

    static getItemDetailDayCard(courseEditionIndex: number, courseEdition,
        courseDay, isExternalCourse: boolean) {
        let availableSeats = courseEdition.availableSeats || 0;

        let labelStatusToDisplay;
        let callToAction;
        // Da notare che se un corso è esterno le azioni dell'utente non ci sono: non può nè iscriversi nè cancellarsi, ma solo vedere i dettagli
        if (isExternalCourse) {
            labelStatusToDisplay = '';
            callToAction = 'DETAILS';
        } else {
            if (courseEdition.userStatus && courseEdition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT) {
                // Utente presente
                labelStatusToDisplay = 'present';
                callToAction = 'DETAILS';
            } else if (courseEdition && courseEdition.isConfirmed) {
                labelStatusToDisplay = 'subscribed';
                callToAction = 'DETAILS';
            } else {
                if (!availableSeats) {
                    labelStatusToDisplay = 'noAvailableSeats';
                } else {
                    labelStatusToDisplay = 'availableSeats';
                    callToAction = 'SUBSCRIBE';
                }
            }
        }

        let formattedTimeSchedules = [];
        if (courseDay.timeSchedules && courseDay.timeSchedules.length) {
            for (let u = 0, schedulesLength = courseDay.timeSchedules.length; u < schedulesLength; u++) {
                let formattedTime = '';
                let currentTimeSchedule = courseDay.timeSchedules[u];

                if (currentTimeSchedule.startTime) {
                    formattedTime = formattedTime + moment(currentTimeSchedule.startTime).format('HH:mm');
                    if (currentTimeSchedule.endTime) {
                        formattedTime = formattedTime + '-';
                    }
                }

                if (currentTimeSchedule.endTime) {
                    formattedTime = formattedTime + moment(currentTimeSchedule.endTime).format('HH:mm');
                }

                if (u != (courseDay.timeSchedules.length - 1)) {
                    formattedTime = formattedTime + ' | ';
                }
                formattedTimeSchedules.push(formattedTime);
            }
        }

        return {
            customSubtitle: courseEdition.customSubtitle,
            customTitle: courseEdition.customTitle,
            editionId: courseEdition.courseDateId,
            formattedTimeSchedules: formattedTimeSchedules,
            formattedFullDate: courseDay.formattedFullDate,
            itemId: courseDay.itemId,
            location: courseDay.location,
            teachers: courseDay.teachers,
            tutors: courseDay.tutors,
            isCancelled: courseEdition.isCancelled,
            isConfirmed: courseEdition.isCanisConfirmedcelled,
            isExternalEvent: courseEdition.isExternalEvent,
            isInvited: courseEdition.isInvited,
            isOnlineCourse: courseEdition.isOnlineCourse,
            isOnlineEvent: courseEdition.isOnlineEvent,
            isPresent: courseEdition.isPresent,
            callToAction: callToAction,
            labelStatusToDisplay: labelStatusToDisplay,
            availableSeats: availableSeats,
            courseEditionIndex: courseEditionIndex,
            dayId: courseDay.itemId,
            dayDate: courseDay.dayDate,
            timeSchedules: courseDay.timeSchedules,
            stopDate: courseEdition.endEnrollDate,
            status: courseEdition.status,
            streetNumber: courseDay.location.streetNumber && courseDay.location.streetNumber.title,
            address: courseDay.location.address && courseDay.location.address.title,
            place: courseDay.location.address && courseDay.location.address.title,
            room: courseDay.location.room && courseDay.location.room.title,
            roomCapacity: courseDay.location.roomCapacity,
            seatsLimit: courseEdition.seatsLimit,
            mapAddress: courseEdition.mapAddress,
            subscriptionCustomLink: courseEdition.subscriptionCustomLink,
            subscriptionCustomMail: courseEdition.subscriptionCustomMail,
            itemAttributes: courseEdition.itemAttributes
        };
    }

    // Prevalorizza i template mail dell'edizione con quelli dell'iniziativa qualora mancassero
    static prefillTemplateOnEdition(course, courseEdition) {
        if (courseEdition && course) {
            if (!courseEdition.itemAttributes) {
                courseEdition.itemAttributes = [];
            }

            let inviteTemplateFound = false;
            let stageInviteTemplateFound = false;
            let refuseTemplateFound = false;
            let preregistrationTemplateFound = false;
            let confirmTemplateFound = false;
            let discardedTemplateFound = false;
            let obConfirmTemplateFound = false;
            let obPromotionTemplateFound = false;
            let presenceRegisteredTemplateFound = false;
            let managerNotifyTemplateFound = false;
            let hrbpNotifyTemplateFound = false;
            let hrbpVerifyTemplateFound = false;
            let managerVerifyTemplateFound = false;
            let courseReminderTemplateFound = false;
            let invitationSolicit1TemplateFound = false;
            let invitationSolicit2TemplateFound = false;
            let invitationSolicit3TemplateFound = false;
            let invitationRecurrentSolicitTemplateFound = false;
            let invitationRecurrentSolicitAfterDeadlineTemplateFound = false;
            // Verifico cosa già possiede l'edizione
            for (let i = 0, itemAttributeslength = courseEdition.itemAttributes.length; i < itemAttributeslength; i++) {
                const currentAttribute = courseEdition.itemAttributes[i];
                if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_MAIL) {
                    // Template dell'invito
                    inviteTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.STAGE_INVITATION_MAIL) {
                    // Template dell'invito
                    stageInviteTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.SELF_CANCEL_MAIL) {
                    // Template della cancellazione/rifiuto
                    refuseTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.PREREGISTRATION_MAIL) {
                    // Template della preiscrizione
                    preregistrationTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.CONFIRMATION_MAIL) {
                    // Template della conferma partecipazione
                    confirmTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.DISCARDED_MAIL) {
                    // Template scartato dal capo/hrbp
                    discardedTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.OB_CONFIRMATION_MAIL) {
                    // Template di conferma partipazione Overbooking
                    obConfirmTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.OB_PROMOTION_MAIL) {
                    // Template di passagio da lista d'attesa a effettivo
                    obPromotionTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.PRESENCE_REGISTERED_MAIL) {
                    // Template di presenza registrata
                    presenceRegisteredTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.MANAGER_NOTIFY_MAIL) {
                    // Template di notifica ai capi
                    managerNotifyTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.HRBP_NOTIFY_MAIL) {
                    // Template di notifica agli hrbp
                    hrbpNotifyTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.MANAGER_VERIFY_MAIL) {
                    // Template di richiesta approvazione capi
                    managerVerifyTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.HRBP_VERIFY_MAIL) {
                    // Template di richiesta approvazione hrbp
                    hrbpVerifyTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.COURSE_REMINDER_MAIL) {
                    // Template di reminder edizione
                    courseReminderTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1) {
                    // Template del primo sollecito all'iscrizione
                    invitationSolicit1TemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2) {
                    // Template del secondo sollecito all'iscrizione
                    invitationSolicit2TemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3) {
                    // Template del terzo sollecito all'iscrizione
                    invitationSolicit3TemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL) {
                    // Template del terzo sollecito all'iscrizione
                    invitationRecurrentSolicitTemplateFound = true;
                } else if (currentAttribute.attributeType === ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL_AFTER_DEADLINE) {
                    // Template del terzo sollecito all'iscrizione
                    invitationRecurrentSolicitAfterDeadlineTemplateFound = true;
                }
            }

            if (!inviteTemplateFound || !stageInviteTemplateFound || !refuseTemplateFound || !preregistrationTemplateFound || !confirmTemplateFound || !discardedTemplateFound || !obConfirmTemplateFound || !obPromotionTemplateFound || !presenceRegisteredTemplateFound || !managerNotifyTemplateFound || !hrbpNotifyTemplateFound || !hrbpVerifyTemplateFound || !managerVerifyTemplateFound || !courseReminderTemplateFound || !invitationSolicit1TemplateFound || !invitationSolicit2TemplateFound || !invitationSolicit3TemplateFound) {
                for (let j = 0, itemAttributeslength = course.itemAttributes.length; j < itemAttributeslength; j++) {
                    const currentCourseAttribute = course.itemAttributes[j];
                    if (!inviteTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_MAIL) {
                        // Template dell'invito
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!stageInviteTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.STAGE_INVITATION_MAIL) {
                        // Template dell'invito
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!refuseTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.SELF_CANCEL_MAIL) {
                        // Template della cancellazione/rifiuto
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!preregistrationTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.PREREGISTRATION_MAIL) {
                        // Template della preiscrizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!confirmTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.CONFIRMATION_MAIL) {
                        // Template della conferma partecipazione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!discardedTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.DISCARDED_MAIL) {
                        // Template scartato dal capo/hrbp
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!obConfirmTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.OB_CONFIRMATION_MAIL) {
                        // Template di conferma partipazione Overbooking
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!obPromotionTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.OB_PROMOTION_MAIL) {
                        // Template di passagio da lista d'attesa a effettivo
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!presenceRegisteredTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.PRESENCE_REGISTERED_MAIL) {
                        // Template di presenza registrata
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!managerNotifyTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.MANAGER_NOTIFY_MAIL) {
                        // Template di notifica ai capi
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!hrbpNotifyTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.HRBP_NOTIFY_MAIL) {
                        // Template di notifica agli hrbp
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!managerVerifyTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.MANAGER_VERIFY_MAIL) {
                        // Template di richiesta approvazione capi
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!hrbpVerifyTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.HRBP_VERIFY_MAIL) {
                        // Template di richiesta approvazione hrbp
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!courseReminderTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.COURSE_REMINDER_MAIL) {
                        // Template di reminder edizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!invitationSolicit1TemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1) {
                        // Template del primo sollecito all'iscrizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!invitationSolicit2TemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2) {
                        // Template del secondo sollecito all'iscrizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!invitationSolicit3TemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3) {
                        // Template del terzo sollecito all'iscrizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!invitationRecurrentSolicitTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL) {
                        // Template del terzo sollecito all'iscrizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    } else if (!invitationRecurrentSolicitAfterDeadlineTemplateFound && currentCourseAttribute.attributeType === ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL_AFTER_DEADLINE) {
                        // Template del terzo sollecito all'iscrizione
                        let copiedAttribute = JSON.parse(JSON.stringify(currentCourseAttribute));
                        // Rimuovo l'attribute id
                        copiedAttribute.attributeId = null;
                        // Aggiorno l'id
                        copiedAttribute.copiedAttribute = courseEdition.itemAttributes.length ? courseEdition.itemAttributes.length + 1 : 0;
                        copiedAttribute.itemId = courseEdition.itemId;
                        courseEdition.itemAttributes.push(copiedAttribute);
                    }
                }
            }
        }
    }

    static MAIL_TEMPLATES_TO_MAP_ON_ITEM = {
        [ItemAttributeTypes.INVITATION_MAIL]: "inviteTemplate",
        [ItemAttributeTypes.STAGE_INVITATION_MAIL]: "stageInviteTemplate",
        [ItemAttributeTypes.SELF_CANCEL_MAIL]: "refuseTemplate",
        [ItemAttributeTypes.PREREGISTRATION_MAIL]: "preregistrationTemplate",
        [ItemAttributeTypes.CONFIRMATION_MAIL]: "confirmTemplate",
        [ItemAttributeTypes.DISCARDED_MAIL]: "discardedTemplate",
        [ItemAttributeTypes.OB_CONFIRMATION_MAIL]: "obConfirmTemplate",
        [ItemAttributeTypes.OB_PROMOTION_MAIL]: "obPromotionTemplate",
        [ItemAttributeTypes.PRESENCE_REGISTERED_MAIL]: "presenceRegisteredTemplate",
        [ItemAttributeTypes.MANAGER_NOTIFY_MAIL]: "managerNotifyTemplate",
        [ItemAttributeTypes.HRBP_NOTIFY_MAIL]: "hrbpNotifyTemplate",
        [ItemAttributeTypes.MANAGER_VERIFY_MAIL]: "managerVerifyTemplate",
        [ItemAttributeTypes.HRBP_VERIFY_MAIL]: "hrbpVerifyTemplate",
        [ItemAttributeTypes.COURSE_REMINDER_MAIL]: "courseReminderTemplate",
        [ItemAttributeTypes.INVITATION_SOLICIT_MAIL_1]: "invitationSolicit1Template",
        [ItemAttributeTypes.INVITATION_SOLICIT_MAIL_2]: "invitationSolicit2Template",
        [ItemAttributeTypes.INVITATION_SOLICIT_MAIL_3]: "invitationSolicit3Template",
        [ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL]: "invitationRecurrentSolicitTemplate",
        [ItemAttributeTypes.RECURRENT_INVITATION_SOLICIT_MAIL_AFTER_DEADLINE]: "invitationRecurrentSolicitAfterDeadlineTemplate"
    }

    // Recupera i template associati all'edizione
    static getSelectedTemplates(initiative: ExtendedItem, course: ExtendedItem, applicationLang: string, templateService?: any, skipCallService?: boolean) {
        let promises = [];
        if (course && course.itemAttributes) {
            Object.keys(this.MAIL_TEMPLATES_TO_MAP_ON_ITEM).forEach(templateId => {
                promises.push(new Promise((resolve: Function, reject: Function) => {
                    // inizializzo a vuoto il campo per gestire il template a FE
                    course[this.MAIL_TEMPLATES_TO_MAP_ON_ITEM[templateId]] = null;
                    // per ogni template id cerco prima nell'edizione e poi eventualmente nell'iniziativa
                    let attribute = ItemUtil.getAttributeByKey(course, templateId) || ItemUtil.getAttributeByKey(initiative, templateId);
                    let getTemplatePromise = CourseEditionUtil.getHtmlTemplateLang(attribute, applicationLang, templateService, skipCallService);
                    getTemplatePromise.then(() => {
                        course[this.MAIL_TEMPLATES_TO_MAP_ON_ITEM[templateId]] = attribute;
                        resolve(true);
                    })
                        .catch(() => {
                            reject();
                        });
                }));
            });
        }
        // Risolvo le promesse coi dati recuperati
        return Promise.all(promises);
    }

    // Formatta le date unendole tramite il luogo
    static formatDatesByPlace(courseEditionList: CourseEdition[]): CourseEdition[] {
        const datesByPlace = [];

        const places: any = {};
        let index = 1;

        // creo i raggruppamenti per giorno per giorno le edizioni per Giorno
        courseEditionList.forEach((courseEdition: CourseEdition) => {
            courseEdition.courseSchedules.forEach((courseDay: CourseDaySchedule) => {
                const placeId = courseDay.location && courseDay.location.address && courseDay.location.address.id;
                if (placeId && places[placeId]) {
                    const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);
                    places[placeId].dates.push(feDate);

                } else if (placeId && !places[placeId]) {
                    places[placeId] = { place: placeId };
                    places[placeId].dates = [];
                    const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);
                    places[placeId].dates.push(feDate);
                }
            });
            index++;
        });

        for (const key in places) {
            if (places.hasOwnProperty(key)) {
                const element = places[key];
                datesByPlace.push({
                    place: key,
                    dates: element.dates
                });
            }
        }

        // ordino per place
        return datesByPlace.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'place' }]));
    }

    // Formatta le date unendole tramite la giornata
    static formatDatesByDay(courseEditionList: CourseEdition[]): CourseEdition[] {
        const datesByDay = [];

        const days: any = {};

        let index = 1;
        // creo i raggruppamenti per giorno per giorno le edizioni per Giorno
        courseEditionList.forEach((courseEdition: CourseEdition) => {
            courseEdition.courseSchedules.forEach((courseDay: CourseDaySchedule) => {
                if (courseDay.dayDate && days[courseDay.dayDate]) {
                    const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);

                    days[courseDay.dayDate].dates.push(feDate);

                } else if (courseDay.dayDate && !days[courseDay.dayDate]) {
                    days[courseDay.dayDate] = { day: courseDay.dayDate };
                    days[courseDay.dayDate].dates = [];

                    const feDate: FrontEndDate = CourseEditionUtil.getFrontEndDateByCourseEditionAndDay(index, courseEdition, courseDay);

                    days[courseDay.dayDate].dates.push(feDate);
                }
            });
            index++;
        });

        for (const key in days) {
            if (days.hasOwnProperty(key)) {
                const element = days[key];
                datesByDay.push({
                    day: key,
                    dates: element.dates
                });
            }
        }

        // ordino per day
        return datesByDay.sort((x, y) => sortByRules<any>(x, y, false, [{ fieldName: 'day' }]));
    }

    static sortCourseEditionList(courseEditions: Array<CourseEdition>, reverse?: boolean, fieldName?: string) {
        if (courseEditions && courseEditions.length) {
            const _fieldName = !!fieldName ? fieldName : 'dayDate';
            // ordino tutte le date delle singole edizioni, poi ordino le edizioni per la prima delle proprie giornate
            courseEditions = courseEditions.sort((x, y) => {
                return sortByRules<CourseEdition>(x, y, !!reverse, [{
                    fieldExtractor: (courseEdition) => {
                        if (courseEdition && courseEdition.courseSchedules.length) {

                            courseEdition.courseSchedules = courseEdition.courseSchedules.sort((x1, y1) => {
                                return sortByRules<CourseDaySchedule>(x1, y1, !!reverse, [{
                                    fieldExtractor: (courseDaySchedule) => {
                                        let firstTimeSchedule = courseDaySchedule.getFirstValidTimeScheduleByOrder(!!reverse);
                                        return firstTimeSchedule && firstTimeSchedule.startTime;
                                    }
                                }]);
                            });

                            let firstDay = courseEdition.courseSchedules[0];
                            if (!!firstDay && !!firstDay.timeSchedules) {
                                let firstTimeSchedule = firstDay.getFirstValidTimeScheduleByOrder(!!reverse);
                                return firstTimeSchedule && firstTimeSchedule.startTime;
                            }
                            return firstDay && firstDay[fieldName];
                        } else {
                            return null;
                        }
                    }
                }]);
            });
        }
    }

    static getFirstValidTimeScheduleByOrderForUser(timeSchedules, reverse: boolean) {
        let firstSchedule = timeSchedules.filter((schedule) => {
            return !!schedule.startTime;
        }).sort((x2, y2) => {
            return sortByRules<TimeSchedule>(x2, y2, !!reverse, [{ fieldName: 'startTime' }])
        })[0];
        return firstSchedule;
    }

    static sortCourseEditionListForUser(courseEditions: Array<any>) {
        if (courseEditions && courseEditions.length) {
            // ordino tutte le date delle singole edizioni, poi ordino le edizioni per la prima delle proprie giornate
            courseEditions = courseEditions.sort((x, y) => {
                return sortByRules<any>(x, y, false, [{
                    fieldExtractor: (courseEdition) => {
                        if (courseEdition && courseEdition.days && courseEdition.days.length) {
                            courseEdition.days = courseEdition.days.sort((x1, y1) => {
                                return sortByRules<CourseDaySchedule>(x1, y1, false, [{
                                    fieldExtractor: (day) => {
                                        let firstTimeSchedule = CourseEditionUtil.getFirstValidTimeScheduleByOrderForUser(day.timeSchedules || [], false);
                                        return firstTimeSchedule.startTime;
                                    }
                                }]);
                            });

                            let firstDay = courseEdition.days[0];
                            if (!!firstDay && !!firstDay.timeSchedules) {
                                let firstTimeSchedule = CourseEditionUtil.getFirstValidTimeScheduleByOrderForUser(firstDay.timeSchedules || [], false);
                                return firstTimeSchedule.startTime;
                            }
                            return firstDay && firstDay['dayDate'];
                        } else {
                            return null;
                        }
                    }
                }]);
            });
        }
    }

    // serve a ricostruire il modello del form dall'Item
    static getCourseEditionModelFromItem(courseEditionItem: Item, initiative: Item, moduleItem: Item, langsService?: LangsService, currentLang?: Lang, isNewTableHome?: boolean): CourseEdition {
        const courseEdition: CourseEdition = new CourseEdition();
        courseEdition.itemId = courseEditionItem.itemId;

        courseEdition.parentCourse = CourseModuleUtil.getCourseStageFromItem(initiative, moduleItem);

        courseEdition.endEnrollDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.END_ENROLL_DATE);
        courseEdition.firstDayDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.FIRST_DAY_DATE);
        courseEdition.lastDayDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.LAST_DAY_DATE);

        courseEdition.seatsLimit = parseInt(defaultTo(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_ENROLLMENTS), 0));
        courseEdition.subscriptionCustomMail = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_MAIL);
        courseEdition.subscriptionInfoMail = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.SUBSCRIPTION_INFO_MAIL);
        courseEdition.subscriptionCustomLink = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.SUBSCRIPTION_CUSTOM_LINK);
        courseEdition.overbookingLimit = parseInt(defaultTo(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_OB_ENROLLMENTS)
            , 0));
        courseEdition.courseEditionFunded = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.FUNDED_ITEM));
        courseEdition.limitedSeatsCourseEdition = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.ITEM_WITH_LIMITED_SEATS));
        courseEdition.autoPublish = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.AUTO_PUBLISH));
        // courseEdition.isPublicEdition = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.PUBLIC_EDITION));
        // spostato l'attributo a livello di iniziativa
        /* courseEdition.preRegistrationRequired = parseBoolean(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.PREREGISTRATION_REQUIRED)); */
        courseEdition.managerNotification = parseBoolean(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MANAGER_NOTIFICATION));
        courseEdition.managerApprovalRequired = parseBoolean(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MANAGER_APPROVAL_REQUIRED));
        courseEdition.hrbpNotification = parseBoolean(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.HRBP_NOTIFICATION));
        courseEdition.hrbpApprovalRequired = parseBoolean(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.HRBP_APPROVAL_REQUIRED));

        courseEdition.hrbpApprovalLimitDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.HRBP_APPROVAL_LIMIT_DATE);
        courseEdition.managerApprovalLimitDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MANAGER_APPROVAL_LIMIT_DATE);

        courseEdition.reminderActive = parseBoolean(
            ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.REMINDER_ACTIVE));
        courseEdition.reminderDate = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.REMINDER_DATE);
        courseEdition.solicitsActive = parseBoolean(
            ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.SOLICITS_ACTIVE));
        courseEdition.recurrentSolicitsActive = parseBoolean(
            ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_ACTIVE));
        courseEdition.recurrentSolicitsAfterHours = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS);
        courseEdition.recurrentSolicitsDeadlineAfterDays = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_DEADLINE_AFTER_DAYS);
        courseEdition.recurrentSolicitsAfterHoursAfterDeadline = ItemUtil.getAttributeValue(initiative, ItemAttributeTypes.RECURRENT_SOLICITS_AFTER_HOURS_AFTER_DEADLINE);
        courseEdition.firstSolicitAfterHours = (ItemUtil.getAttributeValue(
            initiative, ItemAttributeTypes.SOLICIT_AFTER_HOURS, 0));
        courseEdition.secondSolicitAfterHours = (ItemUtil.getAttributeValue(
            initiative, ItemAttributeTypes.SOLICIT_AFTER_HOURS, 1));
        courseEdition.thirdSolicitAfterHours = (ItemUtil.getAttributeValue(
            initiative, ItemAttributeTypes.SOLICIT_AFTER_HOURS, 2));
        courseEdition.calendarTypeOnConfirm = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.CALENDAR_TYPE_ON_CONFIRM);
        courseEdition.eTicket = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.ETICKET_ON_CONFIRM));
        courseEdition.enableCustomTitleAndSubtitle = parseBoolean(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.ENABLE_CUSTOM_TITLE_AND_SUBTITLE));

        courseEdition.enableUsableTakes = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_USABLE_TAKES);
        if (courseEdition.enableUsableTakes) {
            courseEdition.maxUsableTakes = parseInt(ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_USABLE_TAKES));
            courseEdition.enableUsableTakesHint = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.MAX_USABLE_TAKES_HINT);
        }

        // Lingua dell'edizione
        if (currentLang && currentLang.langCode && langsService && courseEditionItem.itemLangs && courseEditionItem.itemLangs.length) {
            let initiativeIndex: number = langsService.findItemLangIndex(currentLang.langCode, courseEditionItem);
            courseEdition.customTitle = JSON.parse(JSON.stringify(courseEditionItem.itemLangs[initiativeIndex].title));
            courseEdition.customSubTitle = JSON.parse(JSON.stringify(courseEditionItem.itemLangs[initiativeIndex].subTitle));
        }

        // Ricostruisco le giornate
        courseEdition.courseSchedules = CourseDayUtil.getCourseSchedulesFromEditionItem(courseEditionItem, courseEdition.isOnlineCourse, isNewTableHome, courseEdition.isWebinar);

        courseEdition.itemAttributes = courseEditionItem.itemAttributes;

        courseEdition.trainingInitiativeType = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.INITIATIVE_TYPE);
        courseEdition.status = ItemUtil.getAttributeValue(courseEditionItem, ItemAttributeTypes.STATUS);

        return courseEdition;
    }

    // Ritorna l'etichetta della tipologia già tradotta
    static getCourseTypologyLabel(courseEditionItem: ExtendedItem, translateService: TranslateService): string {
        let type: string = null;
        type = translateService.instant('itemDetailsPage.initiativeTypes.' + courseEditionItem.itemType);
        return type;
    }

    // Ritorna l'etichetta dello status già tradotta
    static getCourseStatusLabel(item: Item, translateService: TranslateService): string {
        let status: string = null;
        // Recupero l'attributo relativo allo stato
        const itemStatus = ItemUtil.getAttributeByKey(item, 'STATUS');
        status = translateService.instant('itemDetailsPage.initiativeStatuses.' + itemStatus.attributeValue);
        return status;
    }

    // Ritorna l'etichetta dello status già tradotta del meeting
    static getMeetingStatusLabel(item: Item, translateService: TranslateService): string {
        let status: string = null;
        // Recupero l'attributo relativo allo stato
        const itemStatus = ItemUtil.getAttributeByKey(item, 'STATUS');
        status = translateService.instant('itemDetailsPage.meetingStatuses.' + itemStatus.attributeValue);
        return status;
    }

    // Ritorna l'etichetta della data e dell ora del meeting
    static getMeetingDateLabel(item: Item, translateService: TranslateService) {

        let edition = item && item.itemChilds[0] && item.itemChilds[0].childObject && item.itemChilds[0].childObject.itemChilds[0] && item.itemChilds[0].childObject.itemChilds[0].childObject;

        // Vado a vedere gli attributi dell' edizione
        let first = ItemUtil.getAttributeByKey(edition, 'FIRST_DAY_DATE');
        let last = ItemUtil.getAttributeByKey(edition, 'LAST_DAY_DATE');
        moment.locale(translateService.currentLang);

        let dateDay = moment(first && first.attributeValue).format('L');
        let dateTimeStart = moment(first && first.attributeValue).format('HH:mm');
        let dateTimeEnd = moment(last && last.attributeValue).format('HH:mm');

        let date = {
            dateDay: dateDay,
            dateTimeStart: dateTimeStart,
            dateTimeEnd: dateTimeEnd
        }
        return date;
    }

    static getEditionLocationLabelByInitiativeType(initiative: Item, translateService: any) {
        if (InitiativeUtils.isOnlineEvent(initiative.itemType)) {
            return (ItemUtil.getAttributeByKey(initiative, ItemAttributeTypes.ONLINE_EVENT_LINK_TITLE) || <ItemAttribute>{}).attributeValue;
        } else if (InitiativeUtils.isOnlineCourseOrEvent(initiative.itemType)) {
            return translateService.instant('editionLocationsLabels.ONLINE');
        } else if (InitiativeUtils.isAssessment(initiative.itemType)) {
            return translateService.instant('editionLocationsLabels.ASSESSMENT');
        } else if (InitiativeUtils.isWebinar(initiative.itemType)) {
            return translateService.instant('editionLocationsLabels.WEBINAR');
        } else {
            return translateService.instant('editionLocationsLabels.PRESENCE');
        }
    }

    static getEditionLocationByInitiativeType(initiative: Item, courseEdition: CourseEdition) {
        if (InitiativeUtils.isOnlineEvent(initiative.itemType)) {
            return (ItemUtil.getAttributeByKey(initiative, ItemAttributeTypes.ONLINE_EVENT_LINK) || <ItemAttribute>{}).attributeValue;
        } else if (InitiativeUtils.isOnlineCourseOrEvent(initiative.itemType)
            || InitiativeUtils.isAssessment(initiative.itemType) || InitiativeUtils.isWebinar(initiative.itemType)) {
            return '';
        } else {
            if (courseEdition.courseSchedules && courseEdition.courseSchedules.length) {
                let courseDay = courseEdition.courseSchedules[0];
                return courseDay.location && courseDay.location.address && courseDay.location.address.id;
            }
            return '';
        }
    }

}


/*
* COURSE_DAY utility
*/
export class CourseDayUtil {

    static getCourseSchedulesFromEditionItem(editionItem: Item, isOnline: boolean, isNewTableHome: boolean, isWebinar?: boolean): Array<CourseDaySchedule> {
        const courseDays = new Array<CourseDaySchedule>();
        for (let i = 0, arrLeng = editionItem.itemChilds.length; i < arrLeng; i++) {
            const itemChild: ItemChild = editionItem.itemChilds[i];
            if (itemChild.itemChildId && itemChild.childObject && itemChild.childObject.itemId) {
                if (itemChild.childObject.itemType === ItemTypes.COURSE_DAY) {
                    courseDays.push(CourseDayUtil.getCourseDayScheduleFromItem(itemChild.childObject, isOnline, isNewTableHome, isWebinar));
                }
            }
        }
        return courseDays;
    }

    static getCourseDayScheduleFromItem(courseDayItem: any, isOnline: boolean, isNewTableHome: boolean, isWebinar?: boolean): CourseDaySchedule {
        const daySchedule = new CourseDaySchedule();

        daySchedule.itemId = courseDayItem.itemId;

        if (isNewTableHome) {
            if (!!isOnline || !!isWebinar) {
                daySchedule.firstAndLastTimeSchedule = new TimeSchedule();
                daySchedule.firstAndLastTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 0);
                daySchedule.firstAndLastTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 0);
            } else {
                // mattina
                daySchedule.morningTimeSchedule = new TimeSchedule();
                daySchedule.morningTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 0);
                daySchedule.morningTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 0);

                // pomeriggio
                daySchedule.afternoonTimeSchedule = new TimeSchedule();
                daySchedule.afternoonTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 1);
                daySchedule.afternoonTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 1);
            }
            if (daySchedule.morningTimeSchedule) {
                daySchedule.timeSchedules.push({
                    startTime: daySchedule.morningTimeSchedule.startTime,
                    endTime: daySchedule.morningTimeSchedule.endTime
                });
            }
            if (daySchedule.afternoonTimeSchedule) {
                daySchedule.timeSchedules.push({
                    startTime: daySchedule.afternoonTimeSchedule.startTime,
                    endTime: daySchedule.afternoonTimeSchedule.endTime
                });
            }
            if (daySchedule.firstAndLastTimeSchedule) {
                daySchedule.timeSchedules.push({
                    startTime: daySchedule.firstAndLastTimeSchedule.startTime,
                    endTime: daySchedule.firstAndLastTimeSchedule.endTime
                });
            }
        }

        const attrLocation = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.LOCATION);
        if (attrLocation) {
            const courseDayLocationItem = <Item>attrLocation.crossReferenceObject;
            if (courseDayLocationItem) {
                daySchedule.location = new Location();
                daySchedule.location.physicalPlaceItem = courseDayLocationItem;
                const fullAddressValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_FULL_ADDRESS);
                daySchedule.location.address = {
                    id: fullAddressValue,
                    title: fullAddressValue,
                    reference: courseDayLocationItem
                };
                const streetNumber = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_ADDRESS);
                daySchedule.location.streetNumber = {
                    id: streetNumber,
                    title: streetNumber,
                    reference: courseDayLocationItem
                };
                const buildingValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_BUILDING);
                daySchedule.location.building = {
                    id: buildingValue,
                    title: buildingValue,
                    reference: courseDayLocationItem
                };
                const roomValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_ROOM);
                daySchedule.location.room = {
                    id: roomValue,
                    title: roomValue,
                    reference: courseDayLocationItem
                };
                const cityValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_CITY);
                daySchedule.location.city = {
                    id: cityValue,
                    title: cityValue,
                    reference: courseDayLocationItem
                };
                const placeValue = ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_PLACE);
                daySchedule.location.place = {
                    id: placeValue,
                    title: placeValue,
                    reference: courseDayLocationItem
                };
                daySchedule.location.roomCapacity = defaultTo(
                    ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_ROOM_CAPACITY), 0
                );

                daySchedule.location.locationLat = defaultTo(parseFloat(ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_LAT)), null);
                daySchedule.location.locationLong = defaultTo(parseFloat(ItemUtil.getAttributeValue(courseDayLocationItem, ItemAttributeTypes.LOCATION_LONG)), null);
            }
        }

        const tutorsAttrs = ItemUtil.getAttributesByKey(courseDayItem, ItemAttributeTypes.TUTOR);
        daySchedule.tutors = tutorsAttrs.map((attr) => {
            return {
                id: attr.referenceId,
                title: attr.crossReferenceObject && (attr.crossReferenceObject.surname + ' ' + attr.crossReferenceObject.forename),
                reference: attr.crossReferenceObject
            };
        }) || [];

        const teachersAttrs = ItemUtil.getAttributesByKey(courseDayItem, ItemAttributeTypes.TEACHER);
        daySchedule.teachers = teachersAttrs.map((attr) => {
            return {
                id: attr.referenceId,
                title: attr.crossReferenceObject && (attr.crossReferenceObject.surname + ' ' + attr.crossReferenceObject.forename),
                reference: attr.crossReferenceObject
            };
        }) || [];

        // Dati per SAMBA (Webinar)
        // Salvo l'accountID di samba, che identifica l'aula
        const sambaTopicAccountAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.SAMBALIVE_ACCOUNT_ID);
        if (sambaTopicAccountAttr) {
            daySchedule.webinarTopicAccountId = {
                id: sambaTopicAccountAttr.attributeValue
            };
        }

        // Salvo l'id della sessione di samba
        const sambaSessionIdAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.SAMBALIVE_SESSION_ID);
        if (sambaSessionIdAttr) {
            daySchedule.webinarSessionId = sambaSessionIdAttr.attributeValue
        };

        // Salvo l'eventuale password del webinar
        const webinarPasswordAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.SAMBALIVE_PASSWORD);
        if (webinarPasswordAttr) {
            daySchedule.webinarPassword = webinarPasswordAttr.attributeValue;
        }

        // Salvo la tipologia della sessione (html5/flash)
        const webinarSessionTypeAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.SAMBALIVE_SESSION_TYPE);
        if (webinarSessionTypeAttr) {
            daySchedule.webinarSessionType = {
                id: webinarSessionTypeAttr.attributeValue
            };
        };

        // Salvo la visualizzazione dei nomi partecipanti
        const webinarDisplayPartecipantsNameAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.SAMBALIVE_DISPLAY_PARTICIPANTS_NAME);
        if (webinarDisplayPartecipantsNameAttr) {
            daySchedule.webinarDisplayPartecipantsName = {
                id: webinarDisplayPartecipantsNameAttr.attributeValue
            };
        };

        // Salvo la limitazione dell'accesso prima dell'avvio
        const accessBeforeStartAttr = ItemUtil.getAttributeByKey(courseDayItem, ItemAttributeTypes.SAMBALIVE_ACCESS_BEFORE_START);
        if (accessBeforeStartAttr) {
            daySchedule.webinarAccessBeforeStart = {
                id: accessBeforeStartAttr.attributeValue
            };
        };

        // Lobby di Samba
        daySchedule.webinarLobby = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.SAMBALIVE_LOBBY));
        // Breakout rooms
        daySchedule.webinarBreakoutRooms = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.SAMBALIVE_BREAKOUT_ROOMS));
        // Media Library
        daySchedule.webinarMediaLibrary = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.SAMBALIVE_MEDIA_LIBRARY));
        // Registrazione
        daySchedule.webinarRecording = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.SAMBALIVE_RECORDING));
        // Richiesta di parola
        daySchedule.webinarRequestToSpeak = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.SAMBALIVE_REQUEST_TO_SPEAK));
        // Richiesta di parola
        daySchedule.webinarScreenSharing = parseBoolean(ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.SAMBALIVE_SCREEN_SHARING));

        if (!isNewTableHome) {
            if (!!isOnline || !!isWebinar) {
                daySchedule.firstAndLastTimeSchedule = new TimeSchedule();
                daySchedule.firstAndLastTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 0);
                daySchedule.firstAndLastTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 0);
            } else {
                // mattina
                daySchedule.morningTimeSchedule = new TimeSchedule();
                daySchedule.morningTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 0);
                daySchedule.morningTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 0);

                // pomeriggio
                daySchedule.afternoonTimeSchedule = new TimeSchedule();
                daySchedule.afternoonTimeSchedule.startTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.START_TIME, 1);
                daySchedule.afternoonTimeSchedule.endTime = ItemUtil.getAttributeValue(courseDayItem, ItemAttributeTypes.END_TIME, 1);
            }
        }

        // prendo la prima data valorizzata per ricavare il dayDate
        const _dayDate = (daySchedule.firstAndLastTimeSchedule && daySchedule.firstAndLastTimeSchedule.startTime)
            || (daySchedule.morningTimeSchedule && daySchedule.morningTimeSchedule.startTime)
            || (daySchedule.afternoonTimeSchedule && daySchedule.afternoonTimeSchedule.startTime);

        // DAY_DATE è calcolato dalla startTime per prevalorizzare il form di EDIT
        // la data viene calocolata in base al locale se avevo già salvato una data partendo da quella UTC
        daySchedule.dayDate = DateUtil.getDayDateFromUTCDate(_dayDate);

        // Aggiungo gli attributi
        daySchedule.itemAttributes = courseDayItem.itemAttributes;
        return daySchedule;
    }

    static setItemCourseDaySchedules(editionItem: Item, courseSchedules: Array<CourseDaySchedule>, isOnlineCourse: boolean, isWebinar?: boolean) {
        editionItem.itemChilds = editionItem.itemChilds || [];
        let myArray = [];
        if (editionItem.itemChilds.length > courseSchedules.length) {

            for (let i = 0; i < editionItem.itemChilds.length; i++) {
                for (let j = 0; j < courseSchedules.length; j++) {
                    if (editionItem.itemChilds[i].referenceId == courseSchedules[j].itemId) {
                        myArray.push(editionItem.itemChilds[i]);
                    }

                }
            }

            editionItem.itemChilds = myArray;
        }

        if (courseSchedules && courseSchedules.length) {
            courseSchedules.forEach((day: CourseDaySchedule) => {
                // verifico se sto aggiornando un course day esistente
                let itemToUpdate: Item;
                for (let i = 0, arrLeng = editionItem.itemChilds.length; i < arrLeng; i++) {
                    const itemChild: ItemChild = editionItem.itemChilds[i];
                    if (itemChild.itemChildId && itemChild.childObject && itemChild.childObject.itemId === day.itemId) {
                        itemToUpdate = itemChild.childObject;
                        break;
                    }
                }
                if (itemToUpdate) {
                    CourseDayUtil.updateCourseDayItem(itemToUpdate, day, isOnlineCourse, isWebinar);
                } else {
                    const _newItem = CourseDayUtil.createCourseDayItem(day, isOnlineCourse, isWebinar);
                    const newItemChild: ItemChild = {
                        itemChildId: null,
                        itemId: editionItem.itemId,
                        childOrder: editionItem.itemChilds.length + 1,
                        referenceId: null,
                        mandatory: false,
                        propedeuticReferenceId: null,
                        childObject: _newItem
                    };
                    editionItem.itemChilds.push(newItemChild);
                }
            });
        }
    }

    static createCourseDayItem(daySchedule: CourseDaySchedule, isOnlineCourse?: boolean, isWebinar?: boolean): Item {
        const courseDayItem = <Item>{};
        courseDayItem.itemType = ItemTypes.COURSE_DAY;
        courseDayItem.itemId = daySchedule.itemId;

        courseDayItem.title = 'COURSE_DAY';

        CourseDayUtil.fillCourseDayItem(courseDayItem, daySchedule, false, isOnlineCourse, isWebinar);

        return courseDayItem;
    }

    static updateCourseDayItem(courseDayItem: Item, daySchedule: CourseDaySchedule, isOnlineCourse: boolean, isWebinar?: boolean): Item {
        CourseDayUtil.fillCourseDayItem(courseDayItem, daySchedule, false, isOnlineCourse, isWebinar);

        return courseDayItem;
    }

    static fillCourseDayItem(courseDayItem: Item, daySchedule: CourseDaySchedule, update?: boolean, isOnlineCourse?: boolean, isWebinar?: boolean) {
        // ricreo gli attributi
        courseDayItem.itemAttributes = [];

        if (daySchedule.tutors && daySchedule.tutors.length) {
            daySchedule.tutors.forEach((tutor: any) => {
                ItemUtil.setProgressiveAttributeWithReference(courseDayItem, ItemAttributeTypes.TUTOR,
                    tutor.id, ReferenceTypes.SUPPLIER_PERSON);
            });
        }

        if (daySchedule.teachers && daySchedule.teachers.length) {
            daySchedule.teachers.forEach((teacher: any) => {
                ItemUtil.setProgressiveAttributeWithReference(courseDayItem, ItemAttributeTypes.TEACHER,
                    teacher.id, ReferenceTypes.SUPPLIER_PERSON);
            });
        }

        // Dati per SAMBA (webinar)
        // Aula
        if (daySchedule.webinarTopicAccountId) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_ACCOUNT_ID,
                daySchedule.webinarTopicAccountId.id);
        }
        if (daySchedule.webinarSessionId) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_SESSION_ID,
                daySchedule.webinarSessionId);
        }
        // Password
        if (daySchedule.webinarPassword) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_PASSWORD,
                daySchedule.webinarPassword);
        }
        // Tipologia (html5/flash)
        if (daySchedule.webinarSessionType) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_SESSION_TYPE,
                daySchedule.webinarSessionType.id);
        }
        // Visualizzazione partecipanti
        if (daySchedule.webinarDisplayPartecipantsName) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_DISPLAY_PARTICIPANTS_NAME,
                daySchedule.webinarDisplayPartecipantsName.id);
        }
        // Limita accesso prima dell'inizio
        if (daySchedule.webinarAccessBeforeStart) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_ACCESS_BEFORE_START,
                daySchedule.webinarAccessBeforeStart.id);
        }
        // Abilita Lobby
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_LOBBY, daySchedule.webinarLobby, update);
        // Abilita breakout rooms
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_BREAKOUT_ROOMS, daySchedule.webinarBreakoutRooms, update);
        // Abilita media library
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_MEDIA_LIBRARY, daySchedule.webinarMediaLibrary, update);
        // Abilita screen sharing
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_SCREEN_SHARING, daySchedule.webinarScreenSharing, update);
        // Abilita registrazione
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_RECORDING, daySchedule.webinarRecording, update);
        // Abilita richiesta di parola
        ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.SAMBALIVE_REQUEST_TO_SPEAK, daySchedule.webinarRequestToSpeak, update);

        // creando per l'ONLINE (o webinar) troverò valorizzato il firstAndLastTimeSchedule
        if ((isOnlineCourse || isWebinar) && daySchedule.firstAndLastTimeSchedule) {
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME,
                daySchedule.firstAndLastTimeSchedule.startTime, update);
            ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME,
                daySchedule.firstAndLastTimeSchedule.endTime, update);

        } else if (!isOnlineCourse && !isWebinar) {
            // create/update Item Location
            CourseDayUtil.setAttributeLocation(courseDayItem, daySchedule);
            // DAY_DATE non è più utilizzato
            // ItemUtil.setKeyValueAttribute(courseDayItem, ItemAttributeTypes.DAY_DATE, daySchedule.dayDate);

            // mattina
            if (daySchedule.morningTimeSchedule) {
                ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME,
                    daySchedule.morningTimeSchedule.startTime, update);
                ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME,
                    daySchedule.morningTimeSchedule.endTime, update);
            }
            // pomeriggio
            if (daySchedule.afternoonTimeSchedule) {
                ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.START_TIME,
                    daySchedule.afternoonTimeSchedule.startTime, update);
                ItemUtil.setProgressiveKeyValueAttribute(courseDayItem, ItemAttributeTypes.END_TIME,
                    daySchedule.afternoonTimeSchedule.endTime, update);
            }
        }
    }

    static setAttributeLocation(item: Item, daySchedule: CourseDaySchedule, update?: boolean) {
        // non gestisco l'update, ricreo nuovamente tutto l'attributo location
        // lo valorizzo con l'item che corrisponde alla aula selezionata
        const attribute = <ItemAttribute>{
            attributeId: null,
            attributeType: ItemAttributeTypes.LOCATION,
            attributeValue: daySchedule.location.room && daySchedule.location.room.reference.itemId,
            attributeOrder: 0,
            attributeWeight: null,
            itemId: null,
            crossReferenceObject: null,
            referenceApplicationName: null,
            referenceId: null,
            referenceType: null
        };
        if (!item.itemAttributes) {
            item.itemAttributes = [];
        }
        // Mi basta salvare il riferimento all'item PHYSICAL_PLACE
        attribute.referenceId = daySchedule.location.room && daySchedule.location.room.reference.itemId;
        attribute.referenceType = ReferenceTypes.ITEM;

        item.itemAttributes.push(attribute);

        return attribute;
    }

}
