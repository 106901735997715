/*
 * Modulo per la gestione dell'autenticazione
 *
*/

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FirstIntroductionComponent } from './first-introduction/first-introduction.component';
import { SigninComponent } from './signin/signin.component';
import { UtmCampaignComponent } from './utmCampaign/utm-campaign.component';
import { LocalLoginComponent } from './local-login/local-login.component';
import { LoginComponent } from './login/login.component';
import { LogoutSsoCompletedComponent } from './login/logout-sso-completed.component';
import { LoginSamlErrorComponent } from './login/login-saml-error.component';

@NgModule({
  declarations: [
    UtmCampaignComponent,
    FirstIntroductionComponent,
    SigninComponent,
    LocalLoginComponent,
    LoginSamlErrorComponent,
    LogoutSsoCompletedComponent,
    LoginComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule
  ]
})
export class AuthModule { }
