import { Component, EventEmitter, Input, Output } from '@angular/core';

type WaveColor = 'red' | 'green';

type Collection = {
  contentLabel: string | number;
  title: string;
  tags: string[];
  [key: string]: any;
}

@Component({
  selector: 'app-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss'],
})
export class CollectionCardComponent {
  @Input() item: Collection;

  // Used in the template to determine the color of the waves
  // Additional colors can be added to the enum and by creating a new
  // css class overriding the css variables
  @Input() waveColor: WaveColor = 'red';

  @Output() collectionClicked = new EventEmitter<void>();
  @Output() collectionLiked = new EventEmitter<void>();

  constructor() {
  }
}
