import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { UrlService } from "src/app/shared/services/url.service";
import { SenecaResponse } from 'atfcore-commonclasses';
import { handleFromRecord, handleNumRecords } from 'src/app/shared/utils';
import { of } from 'rxjs';

@Injectable()
export class CategoriesService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  // Recupera il count delle categorie piu cercate
  countCategoriesNoSearchedText() {

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-most-searched-categories');
  }

  // Recupera le categorie piu cercate
  getCategoriesNoSearchedText(allData?: boolean, fromRecord?: number, numRecords?: number) {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    

    return this.http.get<SenecaResponse<any[]>>(this._mediatorUrl + 'list-most-searched-categories', { params: httpParams });
    /* return of(new SenecaResponse<any>(null, [
      {
        tagId: 'DESIGN',
        icon: ''
      },
      {
        tagId: 'DESIGN',
        icon: ''
      },
      {
        tagId: 'DESIGN',
        icon: ''
      },
      {
        tagId: 'DESIGN',
        icon: ''
      }
  ])); */
  }

  // Recupera le categorie piu consigliate
  getCategoriesWithSearchedText(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string) {

    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }

    if(searchedText) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }
    

    return this.http.get<SenecaResponse<any[]>>(this._mediatorUrl + 'list-suggested-categories', { params: httpParams });

    /* return of(new SenecaResponse<any>(null, [
      {
        tagId: 'DESIGN',
        icon: ''
      }
  ])); */
  }
  
}
