import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

import { SenecaResponse, CommonConstants } from 'atfcore-commonclasses/bin/classes/common';
import { ItemTypes, ItemAttributeObjectTypes } from 'atfcore-commonclasses/bin/classes/item';

import { BaseSubscriberComponent } from "src/app/shared/components/base-subscriber.component";
import { RedirectService } from "src/app/shared/services/redirect.service";
import { AnalyticsService, VirtualPageViewCustomDimensions } from "src/app/shared/services/analytics.service";
import { ScrollTo } from "src/app/shared/services/scroll-to.service";
import * as fromApp from '../../../ngrx/app.reducers';
import { Store } from "@ngrx/store";
import { AuthService } from "src/app/auth/services/auth.service";
import { CourseService } from "src/app/core/services/course.service";
import { TagService } from "src/app/core/services/tag.service";
import { coursesCardMapper, formatWebCoursesCollections } from "src/app/shared/utils";

@Component({
    selector: "search-home",
    templateUrl: "./search-home.component.html",
    styleUrls: ["./search-home.component.scss"]
})
export class SearchHomeComponent extends BaseSubscriberComponent implements OnInit {
    noSearchedText: boolean;
    searchParams;
    isLoadingFilters: boolean = true;
    typesFilter;
    sortByFilter;

    getLearningSystemCourses$: any;
    isFetchingLearningSystemCourses: boolean;
    learningSystemCoursesData: any;

    getWebCoursesData$: any;
    isFetchingWebCourses: boolean;
    webCoursesData: any;

    totalCoursesCounter: number = 0;
    viewAllType: string;
    isViewAll: boolean;

    getViewAllCourses$: any;
    isFetchingViewAllCourses: boolean;
    viewAllCoursesData: any;

    coursesForYouPage: any;

    constructor(private translate: TranslateService,
        private authService: AuthService,
        private store: Store<fromApp.AppState>,
        public router: Router,
        private toastr: ToastrService,
        private route: ActivatedRoute,
        private redirectService: RedirectService,
        private courseService: CourseService,
        private scrollTo: ScrollTo,
        private tagService: TagService,
        private analyticsService: AnalyticsService) {
        super();
    }

    ngOnInit() {
        const appInitiatives = document.getElementsByClassName("ng-sidebar__content") && document.getElementsByClassName("ng-sidebar__content")[0];
        if (appInitiatives) {
            appInitiatives.scrollTop = 0;
        }

        // Quando ho l'utente (anche anonimo) procedo alla chiamata
        this.store.select(fromApp.getLoggedUser)
            .pipe(takeUntil(this.unsubscribe$.asObservable()))
            .subscribe(
                (loggedUser) => {
                    if (loggedUser) {
                        this.route.params
                            .subscribe(
                                (urlParams: Params) => {
                                    this.isViewAll = this.router.url.indexOf('home/viewAll') !== -1;
                                    this.viewAllType = urlParams && urlParams.viewAllType || undefined;

                                    const params = (sessionStorage.getItem('searchParams') && JSON.parse(sessionStorage.getItem('searchParams'))) || {};

                                    this.searchParams = {
                                        searchedText: params.searchedText || '',
                                        selectedCategoriesIds: params.selectedCategoriesIds || [],
                                        categoriesFilter: { selected: [], options: [] },
                                        durationsFilter: { selected: params.selectedDurationsFilters || [], options: [] },
                                        typesFilter: { selected: params.selectedTypesFilters || [], options: [] },
                                        sortByFilter: { selected: params.selectedSortByFilters || {}, options: [] },
                                        showAllGeneraliResults: params.showAllGeneraliResults || false,
                                        showAllWebCoursesResults: params.showAllWebCoursesResults || false
                                    };

                                    this.learningSystemCoursesData = {
                                        fromRecord: params.learningSystemDataFromRecord || 0,
                                        numRecords: 12,
                                        counter: 0,
                                        page: params.learningSystemDataPage || 0,
                                        courses: []
                                    };

                                    this.viewAllCoursesData = {
                                        fromRecord: params.viewAllCoursesDataFromRecord || 0,
                                        numRecords: 12,
                                        counter: 0,
                                        page: params.viewAllCoursesDataPage || 0,
                                        courses: []
                                    };

                                    this.webCoursesData = {
                                        fromRecord: params.webCoursesDataFromRecord || 0,
                                        numRecords: 12,
                                        counter: 0,
                                        page: params.webCoursesDataPage || 0,
                                        courses: []
                                    };

                                    this.scrollTo.header();

                                    this.getFilters();
                                })
                    }
                });
    }

    // Recupera i filtri
    getFilters() {
        this.isLoadingFilters = true;
        //searchParams.categoriesFilter.options
        this.translate.get(
            [
                'searchPage.durations.ALL_DURATIONS',
                'searchPage.durations.UNTIL_10M',
                'searchPage.durations.UNTIL_30M',
                'searchPage.durations.UNTIL_60M',
                'searchPage.durations.UP_1H',
                'searchPage.types.ASSESSMENT',
                'searchPage.types.WEBINAR',
                'searchPage.types.COURSE_ONLINE_STAGE',
                'searchPage.types.CERTIFICATE_ITEM',
                'searchPage.types.EXTERNAL_COURSE_CLASS_STAGE',
                'searchPage.types.OF_COURSE_ME',
                'searchPage.types.EXTERNAL_ONLINE_STAGE',
                'searchPage.types.COURSE_CLASS_STAGE',
                'searchPage.types.DVD',
                'searchPage.types.DOCUMENT',
                'searchPage.types.EBOOK',
                'searchPage.types.SCORM',
                'searchPage.types.EVENT_CLASS_STAGE',
                'searchPage.types.EVENT_ONLINE_STAGE',
                'searchPage.types.IMAGE',
                'searchPage.types.PROJECT',
                'searchPage.types.BOOK',
                'searchPage.types.LEARNING_PLAN',
                'searchPage.types.PODCAST',
                'searchPage.types.SURVEY_ITEM',
                'searchPage.types.MAGAZINE',
                'searchPage.types.GRAPH',
                'searchPage.types.VIDEO',
                'generic.filtersOrderBy.D',
                'generic.filtersOrderBy.T'
            ])
            .subscribe(translations => {
                // Filtro durata
                this.getDurationsFilters(translations);

                // Filtro tipologia
                this.getItemTypesFilters(translations);
                // Filtro ordine
                this.getOrdersFilters(translations);

                // Recupero le categorie
                this.tagService.findTags("true", null, null, "ARGUMENTS")
                    .subscribe(categoryData => {
                        if (categoryData.error) {
                            this.toastr.error(this.translate.instant('errors.' + categoryData.error));
                        } else {
                            if (categoryData.response && categoryData.response.length) {
                                for (let i = 0, dataLength = categoryData.response.length; i < dataLength; i++) {
                                    const currentData = categoryData.response[i];
                                    this.searchParams.categoriesFilter.options.push(currentData);

                                    if (this.searchParams.selectedCategoriesIds && this.searchParams.selectedCategoriesIds.length) {
                                        for (let l = 0, idsLength = this.searchParams.selectedCategoriesIds.length; l < idsLength; l++) {
                                            let currentSelectedId = this.searchParams.selectedCategoriesIds[l];

                                            if (currentData.tagId === currentSelectedId) {
                                                this.searchParams.categoriesFilter.selected.push(currentData);
                                            }
                                        }
                                    }
                                }
                            }

                            // Avvio una nuova ricerca
                            if (this.isViewAll) {
                                this.onGetViewAllCourses();
                            } else if (this.searchParams.showAllGeneraliResults) {
                                this.onGetLearningSystemCourses();
                            } else if (this.searchParams.showAllWebCoursesResults) {
                                this.clearLearningSystemCoursesData();
                                this.onGetWebCourses(false, true);
                            } else {
                                this.onGetLearningSystemCourses(true);
                            }
                        }
                        this.isLoadingFilters = false;
                    })
            })
    }

    showTypologyFilter() {
        return this.searchParams && this.searchParams.typesFilter && !this.searchParams.showAllWebCoursesResults && (!this.isViewAll || (this.viewAllType && this.viewAllType !== "WEB_COLLECTIONS"));
    }

    showDurationFilter() {
        return this.searchParams && this.searchParams.durationsFilter && !this.searchParams.showAllWebCoursesResults && (!this.isViewAll || (this.viewAllType && this.viewAllType !== "WEB_COLLECTIONS"));
    }

    showSortinFilter() {
        return this.searchParams && this.searchParams.sortByFilter && !this.searchParams.showAllWebCoursesResults && (!this.isViewAll || (this.viewAllType && this.viewAllType !== "WEB_COLLECTIONS"));
    }

    getItemTypesFilters(translations) {
        let tmpOptions = [
            {
                tagId: ItemTypes.CERTIFICATE_ITEM,
                title: translations['searchPage.types.CERTIFICATE_ITEM']
            }, {
                tagId: ItemTypes.EXTERNAL_COURSE_CLASS_STAGE,
                title: translations['searchPage.types.EXTERNAL_COURSE_CLASS_STAGE']
            },
            // Commentato perchè c'è una sezione a parte per i corsi of course me
            // e il filtro viene ignorato dai servizi
            //{
            //    tagId: "OF_COURSE_ME",
            //    title: translations['searchPage.types.OF_COURSE_ME']
            //},
            {
                tagId: ItemTypes.EXTERNAL_ONLINE_STAGE,
                title: translations['searchPage.types.EXTERNAL_ONLINE_STAGE']
            }, {
                tagId: ItemTypes.COURSE_CLASS_STAGE,
                title: translations['searchPage.types.COURSE_CLASS_STAGE']
            }, {
                tagId: ItemAttributeObjectTypes.DVD,
                title: translations['searchPage.types.DVD']
            }, {
                tagId: ItemAttributeObjectTypes.DOCUMENT,
                title: translations['searchPage.types.DOCUMENT']
            }, {
                tagId: ItemAttributeObjectTypes.EBOOK,
                title: translations['searchPage.types.EBOOK']
            },
            //{
            //    tagId: ItemAttributeObjectTypes.SCORM,
            //    title: translations['searchPage.types.SCORM']
            //}, 
            {
                tagId: ItemTypes.EVENT_CLASS_STAGE,
                title: translations['searchPage.types.EVENT_CLASS_STAGE']
            }, {
                tagId: ItemTypes.EVENT_ONLINE_STAGE,
                title: translations['searchPage.types.EVENT_ONLINE_STAGE']
            }, {
                tagId: ItemAttributeObjectTypes.IMAGE,
                title: translations['searchPage.types.IMAGE']
            }, {
                tagId: ItemAttributeObjectTypes.PROJECT,
                title: translations['searchPage.types.PROJECT']
            }, {
                tagId: ItemAttributeObjectTypes.BOOK,
                title: translations['searchPage.types.BOOK']
            }, {
                tagId: ItemAttributeObjectTypes.LEARNING_PLAN,
                title: translations['searchPage.types.LEARNING_PLAN']
            }, {
                tagId: ItemAttributeObjectTypes.PODCAST,
                title: translations['searchPage.types.PODCAST']
            }, {
                tagId: ItemTypes.SURVEY_ITEM,
                title: translations['searchPage.types.SURVEY_ITEM']
            }, {
                tagId: ItemTypes.ASSESSMENT,
                title: translations['searchPage.types.ASSESSMENT']
            }, {
                tagId: ItemAttributeObjectTypes.MAGAZINE,
                title: translations['searchPage.types.MAGAZINE']
            }, {
                tagId: ItemAttributeObjectTypes.GRAPH,
                title: translations['searchPage.types.GRAPH']
            }, {
                tagId: ItemAttributeObjectTypes.VIDEO,
                title: translations['searchPage.types.VIDEO']
            }, {
                tagId: ItemTypes.COURSE_ONLINE_STAGE,
                title: translations['searchPage.types.COURSE_ONLINE_STAGE']
            }, {
                tagId: ItemTypes.WEBINAR,
                title: translations['searchPage.types.WEBINAR']
            }
        ]
        let labels = tmpOptions.map(item => item.title);
        labels.sort();
        this.searchParams.typesFilter.options = [];
        for (let key of labels) {
            let item = tmpOptions.find(item => item.title === key);
            this.searchParams.typesFilter.options.push(item);
        }
    }

    getOrdersFilters(translations) {
        this.searchParams.sortByFilter.options = [
            { tagId: 'D', title: translations['generic.filtersOrderBy.D'] },
            { tagId: 'T', title: translations['generic.filtersOrderBy.T'] }
        ];


        this.searchParams.sortByFilter.selected = this.searchParams.sortByFilter.options[0];

    }

    getDurationsFilters(translations) {
        this.searchParams.durationsFilter.options = [
            /*{
                label: translations['searchPage.durations.ALL_DURATIONS'],
                isAll: true
            }, */
            {
                label: translations['searchPage.durations.UNTIL_10M'],
                fromNumber: 0,
                toNumber: 10
            },
            {
                label: translations['searchPage.durations.UNTIL_30M'],
                fromNumber: 10,
                toNumber: 30
            },
            {
                label: translations['searchPage.durations.UNTIL_60M'],
                fromNumber: 30,
                toNumber: 60
            },
            {
                label: translations['searchPage.durations.UP_1H'],
                fromNumber: 60,
                toNumber: 999
            }
        ]
    }

    // Sta in ascolto del cambio di filtri
    onFilterChanged(data, filterRef) {
        // Salvo i dati selezionati
        filterRef.selected = data;

        if (!this.searchParams.sortByFilter.selected || (!this.searchParams.sortByFilter.selected.tagId)) {
            this.searchParams.sortByFilter.selected = this.searchParams.sortByFilter.options[1];
        }

        if (this.searchParams.selectedCategoriesIds) {
            this.searchParams.selectedCategoriesIds = [];

            if (this.searchParams.categoriesFilter.selected && this.searchParams.categoriesFilter.selected.length) {
                for (let i = 0, selectedCategoriesLength = this.searchParams.categoriesFilter.selected.length; i < selectedCategoriesLength; i++) {
                    this.searchParams.selectedCategoriesIds.push(this.searchParams.categoriesFilter.selected[i].tagId);
                }
            }
        }
        // Salvo i filtri
        this.saveFiltersInSessionStorage();

        this.startNewSearch();
    }

    saveFiltersInSessionStorage() {
        const params = {
            searchedText: this.searchParams.searchedText || '',
            selectedCategoriesIds: this.searchParams.selectedCategoriesIds,
            selectedDurationsFilters: this.searchParams.durationsFilter.selected,
            selectedTypesFilters: this.searchParams.typesFilter.selected,
            selectedSortByFilters: this.searchParams.sortByFilter.selected,
            showAllGeneraliResults: this.searchParams.showAllGeneraliResults,
            learningSystemDataFromRecord: this.learningSystemCoursesData.fromRecord,
            learningSystemDataPage: this.learningSystemCoursesData.page,
            showAllWebCoursesResults: this.searchParams.showAllWebCoursesResults,
            webCoursesDataFromRecord: this.webCoursesData.fromRecord,
            webCoursesDataPage: this.webCoursesData.page,
            viewAllCoursesDataFromRecord: this.viewAllCoursesData.fromRecord,
            viewAllCoursesDataPage: this.viewAllCoursesData.page
        };

        // Li salvo anche nel session storage
        sessionStorage.setItem('searchParams', JSON.stringify(params));
    }

    // Recupera la lista di corsi ricerca globale a sinistra
    onGetLearningSystemCourses(newSearch?: boolean) {
        return new Promise((resolve, reject) => {
            this.isFetchingLearningSystemCourses = true;

            const status = "A";
            let sorting = this.searchParams.sortByFilter.selected.tagId;
            let itemTypes = null;
            let serviceToUse = null;
            let argumentsTags = null;

            let notCallGetLearning;

            if (newSearch) {
                this.clearLearningSystemCoursesData();

                this.clearWebCoursesData();
            }

            // Tipologie selezionate
            let selectedItemType = [];
            if (this.searchParams.typesFilter.selected.length) {
                for (let i = 0, dataLength = this.searchParams.typesFilter.selected.length; i < dataLength; i++) {
                    selectedItemType.push(this.searchParams.typesFilter.selected[i].tagId);
                }
                let filterByOnlineCourse = selectedItemType.find((x) => x == ItemTypes.COURSE_ONLINE_STAGE);
                if (filterByOnlineCourse) {
                    selectedItemType.push(ItemAttributeObjectTypes.SCORM);
                } else {
                    selectedItemType = selectedItemType.filter(x => x != ItemAttributeObjectTypes.SCORM);
                }
            }
            itemTypes = selectedItemType;

            // Categorie selezionate
            let selectedArgumentsTags = [];
            if (this.searchParams.categoriesFilter.selected.length) {
                for (let i = 0, dataLength = this.searchParams.categoriesFilter.selected.length; i < dataLength; i++) {
                    const tagId = this.searchParams.categoriesFilter.selected[i].tagId;
                    if (tagId === 'OF_COURSE_ME') {
                        notCallGetLearning = true;
                    }
                    selectedArgumentsTags.push(tagId);
                }
            }
            argumentsTags = selectedArgumentsTags;

            if (this.getLearningSystemCourses$) {
                this.getLearningSystemCourses$.unsubscribe();
            }

            let selectedDuration = this.searchParams.durationsFilter.selected || [];

            serviceToUse = this.courseService.getLearningSystemCoursesList(this.learningSystemCoursesData.fromRecord, this.learningSystemCoursesData.numRecords, true, this.searchParams.searchedText, status, argumentsTags, null, false, false, null, null, itemTypes, null, sorting, true, null, null, selectedDuration)

            this.getLearningSystemCourses$ = serviceToUse
                .subscribe(
                    (learningSystemCoursesData: SenecaResponse<any>) => {
                        if (learningSystemCoursesData.error) {
                            this.toastr.error(this.translate.instant('errors.' + learningSystemCoursesData.error));
                        } else {
                            this.learningSystemCoursesData.counter = learningSystemCoursesData.response.rowsCount;
                            this.learningSystemCoursesData.counterForCarousel = learningSystemCoursesData.response.rowsCount >= 12 ? 12 : learningSystemCoursesData.response.rowsCount;

                            const formattedCourses = coursesCardMapper(learningSystemCoursesData.response.rows, this.translate);

                            this.learningSystemCoursesData.courses = formattedCourses;
                            this.learningSystemCoursesData.coursesForCarousel = this.learningSystemCoursesData.courses;

                            if (this.learningSystemCoursesData.coursesForCarousel && this.learningSystemCoursesData.coursesForCarousel.length && this.learningSystemCoursesData.coursesForCarousel.length > 12) {
                                this.learningSystemCoursesData.coursesForCarousel.length = 12;
                            }
                        }
                        this.isFetchingLearningSystemCourses = false;

                        if (!newSearch) {
                            // Conteggio totale
                            this.totalCoursesCounter = this.learningSystemCoursesData.counter;
                            this.sendVirtualPageViewEvent();

                            this.scrollTo.header();
                        } else {
                            if (!this.searchParams.showAllGeneraliResults) {
                                this.onGetWebCourses(true, true);
                            }
                        }
                        resolve(true);
                    }
                    , (err) => {
                        this.isFetchingLearningSystemCourses = false;
                        if (err && err.message) {
                            this.toastr.error(this.translate.instant('errors.' + err.message));
                        }
                        resolve(true);
                    }
                )
        });
    }

    // Pulisce gli oggetti dei corsi learning system ricerca globale recuperati
    clearLearningSystemCoursesData() {
        this.totalCoursesCounter = 0;
        this.learningSystemCoursesData.fromRecord = 0;
        this.learningSystemCoursesData.courses = [];
        this.learningSystemCoursesData.counter = 0;
        this.learningSystemCoursesData.counterForCarousel = 0;
        this.learningSystemCoursesData.page = 0;
    }

    // Recupera la lista di corsi ricerca
    onGetViewAllCourses(newSearch?: boolean) {
        return new Promise((resolve, reject) => {
            this.isFetchingViewAllCourses = true;

            const status = "A";
            let sorting = this.searchParams.sortByFilter.selected.tagId;
            let itemTypes = null;
            let serviceToUse = null;
            let argumentsTags = null;

            let notCallGetLearning;

            if (newSearch) {
                this.clearViewAllData();
            }

            // Tipologie selezionate
            let selectedItemType = [];
            if (this.searchParams.typesFilter.selected.length) {
                for (let i = 0, dataLength = this.searchParams.typesFilter.selected.length; i < dataLength; i++) {
                    selectedItemType.push(this.searchParams.typesFilter.selected[i].tagId);
                }
                let filterByOnlineCourse = selectedItemType.find((x) => x == ItemTypes.COURSE_ONLINE_STAGE);
                if (filterByOnlineCourse) {
                    selectedItemType.push(ItemAttributeObjectTypes.SCORM);
                } else {
                    selectedItemType = selectedItemType.filter(x => x != ItemAttributeObjectTypes.SCORM);
                }
            }
            itemTypes = selectedItemType;

            // Categorie selezionate
            let selectedArgumentsTags = [];
            if (this.searchParams.categoriesFilter.selected.length) {
                for (let i = 0, dataLength = this.searchParams.categoriesFilter.selected.length; i < dataLength; i++) {
                    const tagId = this.searchParams.categoriesFilter.selected[i].tagId;
                    if (tagId === 'OF_COURSE_ME') {
                        notCallGetLearning = true;
                    }
                    selectedArgumentsTags.push(tagId);
                }
            }
            argumentsTags = selectedArgumentsTags;

            if (this.getViewAllCourses$) {
                this.getViewAllCourses$.unsubscribe();
            }

            let selectedDuration = this.searchParams.durationsFilter.selected || [];
            if (this.viewAllType === 'FEATURED_COURSES') {
                serviceToUse = this.courseService.getFeaturedCourses(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, false, false, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes);
            } else if (this.viewAllType === 'COURSES_TO_COMPLETE') {
                serviceToUse = this.courseService.getUserStartedCoursesList(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes);
            } else if (this.viewAllType === 'TOP_TEN_COURSES') {
                serviceToUse = this.courseService.glpGetTopTenMostViewedCourseAndObjects(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, false, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes);
            } else if (this.viewAllType === 'COURSES_FOR_YOU') {
                serviceToUse = this.courseService.glpGetSuggestedItemsByCategoriesPreferences(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, false, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes, false);
            } else if (this.viewAllType === 'INCLUSION_COURSES') {
                serviceToUse = this.courseService.glpGetCoursesInclusionForCarousel(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes);
            } else if (this.viewAllType === 'PLAYLIST_ITEMS') {
                serviceToUse = this.courseService.glpGetSuggestedItemsByCategoriesPreferences(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, true, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes);
            } else if (this.viewAllType === 'WEB_COLLECTIONS') {
                serviceToUse = this.courseService.getOfCourseMeCollections(this.viewAllCoursesData.fromRecord, this.viewAllCoursesData.numRecords, this.searchParams.searchedText, argumentsTags, sorting, selectedDuration, itemTypes, true);
            }

            this.getViewAllCourses$ = serviceToUse
                .subscribe(
                    (viewAllCoursesData: SenecaResponse<any>) => {
                        if (viewAllCoursesData.error) {
                            this.toastr.error(this.translate.instant('errors.' + viewAllCoursesData.error));
                        } else {
                            this.viewAllCoursesData.counter = viewAllCoursesData.response.rowsCount;

                            let formattedCourses;
                            if (this.viewAllType === 'WEB_COLLECTIONS') {
                                formatWebCoursesCollections(viewAllCoursesData.response.rows, this.translate);
                                formattedCourses = viewAllCoursesData.response.rows;
                            } else {
                                formattedCourses = coursesCardMapper(viewAllCoursesData.response.rows, this.translate);
                            }

                            this.viewAllCoursesData.courses = formattedCourses;
                        }
                        this.isFetchingViewAllCourses = false;

                        this.scrollTo.header();

                        if (!newSearch) {
                            // Conteggio totale
                            this.totalCoursesCounter = this.viewAllCoursesData.counter;
                            this.sendVirtualPageViewEvent();
                        }
                        resolve(true);
                    }
                    , (err) => {
                        this.isFetchingViewAllCourses = false;
                        if (err && err.message) {
                            this.toastr.error(this.translate.instant('errors.' + err.message));
                        }
                        resolve(true);
                    }
                )
        });
    }

    // Pulisce gli oggetti dei corsi learning system ricerca globale recuperati
    clearViewAllData() {
        this.totalCoursesCounter = 0;
        this.viewAllCoursesData.fromRecord = 0;
        this.viewAllCoursesData.courses = [];
        this.viewAllCoursesData.counter = 0;
        this.viewAllCoursesData.page = 0;
    }

    // Recuera i corsi dal web
    onGetWebCourses(newSearch?: boolean, sendAnalyticsEvent?: boolean) {
        return new Promise((resolve, reject) => {
            this.isFetchingWebCourses = true;

            const status = "A";
            let sorting = this.searchParams.sortByFilter.selected.tagId;
            let itemTypes = null;
            let serviceToUse = null;
            let argumentsTags = null;

            let notCallGetLearning;

            if (newSearch) {
                this.clearWebCoursesData();
            }

            // Tipologie selezionate
            let selectedItemType = [];
            if (this.searchParams.typesFilter.selected.length) {
                for (let i = 0, dataLength = this.searchParams.typesFilter.selected.length; i < dataLength; i++) {
                    selectedItemType.push(this.searchParams.typesFilter.selected[i].tagId);
                }
                let filterByOnlineCourse = selectedItemType.find((x) => x == ItemTypes.COURSE_ONLINE_STAGE);
                if (filterByOnlineCourse) {
                    selectedItemType.push(ItemAttributeObjectTypes.SCORM);
                } else {
                    selectedItemType = selectedItemType.filter(x => x != ItemAttributeObjectTypes.SCORM);
                }
            }
            itemTypes = selectedItemType;

            // Categorie selezionate
            let selectedArgumentsTags = [];
            if (this.searchParams.categoriesFilter.selected.length) {
                for (let i = 0, dataLength = this.searchParams.categoriesFilter.selected.length; i < dataLength; i++) {
                    const tagId = this.searchParams.categoriesFilter.selected[i].tagId;
                    if (tagId === 'OF_COURSE_ME') {
                        notCallGetLearning = true;
                    }
                    selectedArgumentsTags.push(tagId);
                }
            }

            argumentsTags = selectedArgumentsTags;

            if (this.getWebCoursesData$) {
                this.getWebCoursesData$.unsubscribe();
            }

            let selectedDuration = this.searchParams.durationsFilter.selected || [];

            serviceToUse = this.courseService.getOfCourseMeCourses(this.webCoursesData.fromRecord, this.webCoursesData.numRecords, this.searchParams.searchedText, null, argumentsTags, true)

            this.getWebCoursesData$ = serviceToUse
                .subscribe(
                    (webCoursesData: SenecaResponse<any>) => {
                        if (webCoursesData.error) {
                            this.toastr.error(this.translate.instant('errors.' + webCoursesData.error));
                        } else {
                            this.webCoursesData.counter = webCoursesData.response.rowsCount;
                            this.webCoursesData.counterForCarousel = webCoursesData.response.rowsCount >= 12 ? 12 : webCoursesData.response.rowsCount;

                            const formattedCourses = coursesCardMapper(webCoursesData.response.rows, this.translate);

                            this.webCoursesData.courses = formattedCourses;
                            this.webCoursesData.coursesForCarousel = this.webCoursesData.courses;

                            if (this.webCoursesData.coursesForCarousel && this.webCoursesData.coursesForCarousel.length && this.webCoursesData.coursesForCarousel.length > 12) {
                                this.webCoursesData.coursesForCarousel.length = 12;
                            }
                        }

                        // Conteggio totale
                        this.totalCoursesCounter = this.webCoursesData.counter + this.learningSystemCoursesData.counter;

                        if (sendAnalyticsEvent) {
                            this.sendVirtualPageViewEvent();
                        }

                        this.scrollTo.header();

                        this.isFetchingWebCourses = false;
                        resolve(true);
                    }
                    , (err) => {
                        this.isFetchingWebCourses = false;
                        if (err && err.message) {
                            this.toastr.error(this.translate.instant('errors.' + err.message));
                        }
                        resolve(true);
                    }
                )
        });
    }

    // Pulisce gli oggetti dei corsi dal web
    clearWebCoursesData() {
        this.webCoursesData.fromRecord = 0;
        this.webCoursesData.courses = [];
        this.webCoursesData.counter = 0;
        this.webCoursesData.counterForCarousel = 0;
        this.webCoursesData.page = 0;
    }

    searchedTextValid() {
        return this.searchParams && this.searchParams.searchedText && this.searchParams.searchedText.length;
    }

    // Porta alla pagina di dettaglio di un oggetto
    goToItemDetails(item, sendAnalytics?: boolean, carouselName?: string): void {
        const itemId = item && (item.courseId || item.itemId || item.collectionId);
        const isWebCourse = item && item.itemType && item.itemType === "OF_COURSE_ME_COURSE";
        this.saveFiltersInSessionStorage();

        if (sendAnalytics) {
            this.analyticsService.sendEventCarouselClick(this.translate.instant(carouselName), itemId, item.title);
        }

        if (this.isViewAll) {
            if (item.collectionId) {
                this.redirectService.goToWebCollectionDetailsFromHome(itemId);
            } else if (item.cardType == "playlist-item") {
                this.redirectService.goToItemCollectionDetailsFromHome(itemId);
            } else if (isWebCourse) {
                this.router.navigate(['../webCourseDetails', itemId], { relativeTo: this.route });
            } else {
                this.redirectService.goToItemDetailsFromHome(itemId);
            }
        } else {
            if (isWebCourse) {
                this.router.navigate(['../webCourseDetails', itemId], { relativeTo: this.route });
            } else if (item.cardType == "playlist-item") {
                this.redirectService.goToItemCollectionDetailsFromHome(itemId);
            } else {
                this.redirectService.goToItemDetailsFromSearchHome(itemId);
            }
        }
    }

    /**
    * Search function
    */
    onInputSubmit(newValue?: string) {
        this.searchParams.searchedText = newValue || '';

        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        this.analyticsService.sendSearchEvent(this.searchParams.searchedText || '');

        this.startNewSearch();
    }

    startNewSearch() {
        // Salvo i filtri
        this.saveFiltersInSessionStorage();
        if (this.isViewAll) {
            this.onGetViewAllCourses(true);
        } else {
            // E avvio una nuova ricerca
            if (this.searchParams.showAllWebCoursesResults) {
                this.onGetWebCourses(true, true);
            } else {
                this.onGetLearningSystemCourses(true);
            }
        }
    }

    /**
     * Search function
     * @param newValue New searched value
     */
    onSearchedTextChanged(newValue?: string) {
        // this.searchedText = newValue;
    }

    viewAllTap(type) {
        this.searchParams.showAllGeneraliResults = false;
        this.searchParams.showAllWebCoursesResults = false;
        if (type === 'generaliCourses') {
            // Mostro tutti i risultati dei corsi di generali
            this.searchParams.showAllGeneraliResults = true;

            // Salvo i filtri
            this.saveFiltersInSessionStorage();

            // E avvio una nuova ricerca
            this.onGetLearningSystemCourses(true);
        } else if (type === 'webCourses') {
            // Mostro tutti i risultati dei corsi dal web
            this.searchParams.showAllWebCoursesResults = true;

            // Salvo i filtri
            this.saveFiltersInSessionStorage();

            // E avvio una nuova ricerca
            this.clearLearningSystemCoursesData();
            this.onGetWebCourses(true, true);
        }
    }

    generaliCoursesPageChanged(page) {
        this.learningSystemCoursesData.page = page;
        this.learningSystemCoursesData.fromRecord = (page - 1) * this.learningSystemCoursesData.numRecords;

        // Salvo i filtri
        this.saveFiltersInSessionStorage();

        // E avvio una nuova ricerca
        this.onGetLearningSystemCourses();
    }

    webCoursesPageChanged(page) {
        this.webCoursesData.page = page;
        this.webCoursesData.fromRecord = (page - 1) * this.webCoursesData.numRecords;

        // Salvo i filtri
        this.saveFiltersInSessionStorage();

        // E avvio una nuova ricerca
        this.onGetWebCourses(false, true);
    }

    viewAllCoursesPageChanged(page) {
        this.viewAllCoursesData.page = page;
        this.viewAllCoursesData.fromRecord = (page - 1) * this.viewAllCoursesData.numRecords;

        // Salvo i filtri
        this.saveFiltersInSessionStorage();

        // E avvio una nuova ricerca
        this.onGetViewAllCourses();
    }

    // Gestisce l'aggiuna e la rimozione dai preferiti
    onToggleBookmark(course) {
        if (course.isSwitchingBookmark) {
            return;
        }

        this.isFetchingLearningSystemCourses = true;
        this.isFetchingWebCourses = true;
        this.isFetchingViewAllCourses = true;
        course.isSwitchingBookmark = true;
        let serviceToUse = null;

        if (course.isBookmarked) {
            serviceToUse = this.authService.removeCourseFromBookmarks(course.syllabusId || course.itemId || course.courseId || course.collectionId, course.originApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
        } else {
            serviceToUse = this.authService.addCourseToBookmarks(course.syllabusId || course.itemId || course.courseId || course.collectionId, course.originApplicationName || CommonConstants.APPLICATION_TRAINING_PLATFORM);
            // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
            this.analyticsService.sendObjectBookmarkedEvent(course.syllabusId || course.itemId || course.courseId || course.collectionId);
        };

        serviceToUse
            .subscribe(data => {
                if (data.error) {
                    this.toastr.error(this.translate.instant('errors.' + data.error));
                } else {
                    course.isBookmarked = !course.isBookmarked;
                }
                this.isFetchingLearningSystemCourses = false;
                this.isFetchingWebCourses = false;
                this.isFetchingViewAllCourses = false;
                course.isSwitchingBookmark = false;
            }, (err) => {
                this.isFetchingLearningSystemCourses = false;
                this.isFetchingWebCourses = false;
                this.isFetchingViewAllCourses = false;
                course.isSwitchingBookmark = false;
                this.toastr.error(this.translate.instant('errors.' + err));
            });
    }

    noDataFound() {
        const noLearningSystemData = !this.learningSystemCoursesData || !this.learningSystemCoursesData.courses || !this.learningSystemCoursesData.courses.length;
        const noWebCoursesData = !this.webCoursesData || !this.webCoursesData.courses || !this.webCoursesData.courses.length;
        const noViewAllData = !this.viewAllCoursesData || !this.viewAllCoursesData.courses || !this.viewAllCoursesData.courses.length;
        if (!this.isFetchingViewAllCourses && !this.isFetchingLearningSystemCourses && !this.isLoadingFilters && !this.isFetchingWebCourses &&
            this.searchParams && (
                ((this.searchParams.showAllGeneraliResults && noLearningSystemData)
                    || (this.searchParams.showAllWebCoursesResults && noWebCoursesData)
                    || (noLearningSystemData && noWebCoursesData))
                && (this.isViewAll && noViewAllData)
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    showPageResultTitle() {
        return this.searchParams && !this.searchParams.showAllGeneraliResults && !this.searchParams.showAllWebCoursesResults && !this.isViewAll;
    }

    sendVirtualPageViewEvent() {
        // Questo if è per evitare che l'evento di tracciamento si ha ogni volta che l'applicazione si avvia.
        // Il componente <search-page> è anche utilizzato nell'header, quindi verrebbe tracciato sempre ed è sbagliato.
        // A noi interessa solo quando l'utente entra in pagina di ricerca
        if (this.router.url.indexOf('search/homeSearch') !== -1 || this.isViewAll) {
            const customDimension: VirtualPageViewCustomDimensions = {
                titleDetail: "Totale risultati: " + (this.totalCoursesCounter || 0)
            }

            let additionalPageName = '';
            if (this.isViewAll && this.viewAllType) {
                additionalPageName = additionalPageName + ' - ';
                if (this.viewAllType === 'FEATURED_COURSES') {
                    additionalPageName = additionalPageName + "Corsi in vetrina";
                } else if (this.viewAllType === 'COURSES_TO_COMPLETE') {
                    additionalPageName = additionalPageName + "Corsi da completare";
                } else if (this.viewAllType === 'TOP_TEN_COURSES') {
                    additionalPageName = additionalPageName + "Top Ten";
                } else if (this.viewAllType === 'COURSES_FOR_YOU') {
                    additionalPageName = additionalPageName + "Scelti per te";
                } else if (this.viewAllType === 'INCLUSION_COURSES') {
                    additionalPageName = additionalPageName + "Inclusion";
                } else if (this.viewAllType === 'PLAYLIST_ITEMS') {
                    additionalPageName = additionalPageName + "Collezioni per te";
                } else if (this.viewAllType === 'WEB_COLLECTIONS') {
                    additionalPageName = additionalPageName + "Collezioni dal web";
                }
            }

            this.analyticsService.sendVirtualPageViewEvent(this.router.url, 'Risultati di ricerca' + additionalPageName, customDimension);
        }
    }
}
