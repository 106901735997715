/*
 * Servizio che permette le chiamate rest relative alle iniziative (corsi)
*/
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromApp from '../../ngrx/app.reducers';
import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { Survey } from "atfcore-commonclasses/bin/classes/survey";
import { buildAutocompleteServiceKey, findCachedResult, storeCachedResult } from 'src/commonclasses';
import { UrlService } from 'src/app/shared/services/url.service';

@Injectable({
    providedIn: 'root'
})
export class CourseEditionService {
    result$: Subscription;

    updatingDatesAndPlaces: EventEmitter<any>;
    updateDatesAndPlacesDone: EventEmitter<any>;
    cacheMap: any;
    _cm2MediatorUrl: string;

    constructor(private store: Store<fromApp.AppState>, private http: HttpClient, private urlService: UrlService) {
        this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();

        this.updatingDatesAndPlaces = new EventEmitter();
        this.updateDatesAndPlacesDone = new EventEmitter();
        this.cacheMap = {};
    }

    // Servizio che cancella un upload
    deleteUploadFile(uploadId: string) {
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'delete-upload-file', {
            uploadId: uploadId
        });
    }

    getSurveyReferenceOfItem(referenceId: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId);

        return this.http.get<SenecaResponse<string>>(this._cm2MediatorUrl + `get-survey-reference-of-item/${referenceId}`, {
            params: httpParams
        })
            .pipe(
                map((senecaResponse) => {
                    if (senecaResponse && senecaResponse.error) {
                        throw senecaResponse.error;
                    } else {
                        let surveyTemplateId = senecaResponse && senecaResponse.response;
                        // mapping
                        return surveyTemplateId || null;
                    }
                })
            );
    }

    // Recupera la survey associata ad un oggetto
    getSurveysTemplateByReferenceId(referenceId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        return this.http.get<SenecaResponse<Survey>>(this._cm2MediatorUrl + 'get-surveys-template-by-reference-id/' + referenceId, {
            params: httpParams
        });
    }

    // Recupera un upload
    getUploadById(uploadId?: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('uploadId', uploadId || '');
        return this.http.get<SenecaResponse<any>>(this._cm2MediatorUrl + 'get-upload-by-id', {
            params: httpParams
        });
    }

    // Elimina un template survey
    deleteSurveyTemplate(referenceId: string): any {
        // Preparo i parametri per la richiesta http
        let httpParams = new HttpParams();
        httpParams = httpParams.append('referenceId', referenceId || '');
        return this.http.delete<SenecaResponse<null>>(this._cm2MediatorUrl + 'delete-survey-template', {
            params: httpParams
        });
    }

    // Esegue un upload generico
    uploadAnyFile(uploadObj: any) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('referenceId', uploadObj.referenceId);
        formData.append('referenceType', uploadObj.referenceType);
        formData.append('surveyType', uploadObj.surveyType);
        return this.http.post<SenecaResponse<any>>(this._cm2MediatorUrl + 'upload-any-file', formData);
    }

    // Esegue l'upload di un template di una Survey
    uploadSurveyTemplate(uploadObj: any) {
        let formData = new FormData();
        formData.append('file', uploadObj.file.file.rawFile);
        formData.append('referenceId', uploadObj.referenceId);
        formData.append('referenceType', uploadObj.referenceType);
        formData.append('surveyType', uploadObj.surveyType);
        let params = new HttpParams();
        const options = {
            params: params
        };
        const req = new HttpRequest('POST', this._cm2MediatorUrl + 'upload-survey-template', formData, options);
        return this.http.request(req);
    }

    listAvailableCourseLocations(fromRecord, searchedText: string, useCache?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseLocations', false, fromRecord, 0, '', searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        return this.http.get<any>(
            this._cm2MediatorUrl + 'list-available-course-locations', {
            params: { fromRecord: fromRecord, searchedText: searchedText }
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
            }
            return senecaResponse;
        }));
    }

    listAvailableCourseLocationRooms(fromRecord, fullLocationText: string, searchedText: string, useCache?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseLocationRooms', false, fromRecord, 0,
            fullLocationText, searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        return this.http.get<any>(
            this._cm2MediatorUrl + 'list-available-course-location-rooms', {
            params: { fromRecord: fromRecord, fullLocationText: fullLocationText, searchedText: searchedText }
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
            }
            return senecaResponse;
        }));
    }

    listAvailableTeachers(fromRecord, searchedText: string, useCache?: boolean, skipPrivateData?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseTeachers', false, fromRecord, 0, '', searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        return this.http.get<any>(
            this._cm2MediatorUrl + 'list-available-teachers', {
            params: { fromRecord: fromRecord, searchedText: searchedText, skipPrivateData: skipPrivateData.toString() }
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
            }
            return senecaResponse;
        }));
    }

    listAvailableTutors(fromRecord, searchedText: string, useCache?: boolean, skipPrivateData?: boolean) {
        const serviceCacheKey = buildAutocompleteServiceKey('courseTutors', false, fromRecord, 0, '', searchedText, useCache);

        if (serviceCacheKey) {
            const cachedResult = findCachedResult(this.cacheMap, serviceCacheKey, searchedText);
            if (cachedResult) {
                return of(cachedResult.response);
            }
        }

        return this.http.get<any>(
            this._cm2MediatorUrl + 'list-available-tutors', {
            params: { fromRecord: fromRecord, searchedText: searchedText, skipPrivateData: skipPrivateData.toString() }
        }).pipe(map((senecaResponse) => {
            if (serviceCacheKey && senecaResponse
                && !senecaResponse.error && senecaResponse.response) {
                storeCachedResult(this.cacheMap, serviceCacheKey, senecaResponse, searchedText);
            }
            return senecaResponse;
        }));
    }
}
