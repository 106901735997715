import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { FAQService } from '../core/services/faq.service';
import { LangsService } from '../core/services/langs.service';
import { AnalyticsService } from '../shared/services/analytics.service';
import * as fromApp from '../ngrx/app.reducers';
import { Store } from "@ngrx/store";
import { Subject } from 'rxjs';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
})
export class SupportPageComponent implements OnInit, OnDestroy {
  countFaqItems$: any;
  getFaqItems$: any;
  isLoadingFaq: boolean;
  faqList;

  applicationLang: string;
  loggedUser;
  private _unsubscribeSignal$: Subject<void> = new Subject();

  readonly ticketUrl = 'https://usercaregenerali-dwp.onbmc.com/dwp/app/?user_domain=dir';
  readonly tickerGuidePath = 'assets/documents/ENDUSER_guida_apertura_ticket_LearningUP_agg_lug24.pdf';


  constructor(
    private analyticsSvc: AnalyticsService,
    private router: Router,
    private faqSvc: FAQService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private langsService: LangsService,
    private store: Store<fromApp.AppState>
  ) { }

  ngOnInit(): void {
    this.analyticsSvc.sendVirtualPageViewEvent(this.router.url, 'Supporto');

    this.store.select(fromApp.getApplicationLang)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (applicationLang) => {
          this.applicationLang = applicationLang;
          this.langsService.useLanguage(applicationLang || 'it');
        });

    this.store.select(fromApp.getLoggedUser)
      .pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (loggedUser) => {
          this.loggedUser = loggedUser;

          if (loggedUser) {
            this.countFaqItems();
          }
        });
  }

  countFaqItems() {
    this.isLoadingFaq = true;

    this.countFaqItems$ = this.faqSvc.countListFAQItems()
      .subscribe((response) => {
        if (response.error) {
          this.toastr.error(this.translate.instant('errors.' + response.error));
          this.isLoadingFaq = false;
        } else {
          this.getFaqItems();
        }
      },
        (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.isLoadingFaq = false;
        });
  }

  getFaqItems() {
    this.getFaqItems$ = this.faqSvc.getListFAQItems()
      .subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          this.toastr.error(this.translate.instant('errors.' + senecaResponse.error));
        } else {
          this.faqList = senecaResponse.response.map((faq, index) => ({
            title: faq.title,
            description: faq.description,
            expanded: index === 0,
          }));
        }
        this.isLoadingFaq = false;
      },
        (err) => {
          this.toastr.error(this.translate.instant('errors.' + err.message));
          this.isLoadingFaq = false;
        });
  }

  sendOutboundLinkEvent() {
    this.analyticsSvc.sendOutboundLinkEvent(this.ticketUrl);
  }

  ngOnDestroy(): void {
    if (this.countFaqItems$) {
      this.countFaqItems$.unsubscribe();
    }
    if (this.getFaqItems$) {
      this.getFaqItems$.unsubscribe();
    }
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

}
