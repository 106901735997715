<div [ngClass]="inputWrapperClass" class="input-wrapper">
  <label *ngIf="formTitle" class="control-label">{{ formTitle }}</label>
  <div [ngClass]="containerClass ? containerClass : 'small-dimension'">
    <ng-select [virtualScroll]="virtualScroll" labelForId="search-header-bar" [bindValue]="bindValue"
      [bindLabel]="bindLabel" [items]="items" placeholder="{{ placeholderLabel }}" [multiple]="multiple"
      (add)="emitAddItem($event)" [disabled]="disabled" (remove)="emitRemoveItem($event)"
      (change)="emitOnSelectionChanged($event)" [(ngModel)]="bindModel" (ngModelChange)="emiOnModelChanged($event)"
      [dropdownPosition]="'bottom'" (scrollToEnd)="emitOnScrollToEnd($event)" (close)="emitOnClose()" [addTag]="addTag"
      aria-label="header-search" [clearOnBackspace]="clearOnBackspace" [searchable]="searchable"
      [hideSelected]="hideSelected" [typeahead]="typeahead" [loading]="loading" [ngClass]="{'flex-1-ie': ieFlexProblem}"
      [compareWith]="compareWith" [markFirst]="markFirst" #alloySelect>

      <!-- Elementi selezionati -->
      <ng-template class="alloy-select" ng-label-tmp let-item="item" let-clear="clear">
        <!-- <div *ngIf="!item.isCheckBox" fxLayout="row" fxLayoutAlign="start center">
            <p class="margin0 option-class" translate="{{'searchBar.CATEGORIES' | translate}}"></p>
          </div> -->
      </ng-template>

      <!-- Template per l'inizio della ricerca -->
      <ng-template class="alloy-select" ng-typetosearch-tmp>
        <div class="ng-option disabled">{{ startSearchLabel || 'Inizia una ricerca' }}</div>
      </ng-template>
      <!-- Template per i dati non trovati -->
      <ng-template class="alloy-select" ng-notfound-tmp let-searchTerm="filter">
        <div class="ng-option disabled">{{ noDataFoundLabel || 'Nessun dato trovato' }}</div>
      </ng-template>
      <!-- Template per il caricamento dei risultati -->
      <ng-template class="alloy-select" ng-loadingtext-tmp>
        <div class="ng-option disabled">{{ loadingData || 'Caricamento in corso' }}</div>
      </ng-template>
      <!-- Template per i singoli item nelle options -->
      <ng-template class="alloy-select" ng-option-tmp let-item="item" let-search="filter" let-item$="item$">
        <div *ngIf="!item.isCheckbox" class="default-option" fxLayout="row">
          <!-- <p class="margin0">{{resolveItemTitle(item)}}</p> -->
          <p class="option-class">{{item.titleName || item.title}}</p>
        </div>
        <!-- Template per i singoli item nelle options (se è un checkbox) -->
        <div *ngIf="item.isCheckbox || item.isRadiobox">
          <div fxLayout="row">
            <label class="container-checkbox">
              <div class="margin-left7" fxLayout="column">
                <p *ngIf="item.isCheckbox" class="option-class"><b>{{item.titleName}}</b></p>
                <p *ngIf="item.isCheckbox" class="option-class">{{item.subtitleName}}</p>
                <p *ngIf="item.isRadiobox" class="option-class radio"><b>{{item.titleName}}</b></p>
                <p *ngIf="item.isRadiobox" class="option-class radio">{{item.subtitleName}}</p>
              </div>
              <input *ngIf="item.isCheckbox"
                [ngClass]="{'view-not-view-input': (item.code == 'VIEWED' || item.code == 'NOT_VIEWED')}"
                [ngModel]="item.isActive" id="checkbox{{item.id}}" type="checkbox">
              <span *ngIf="item.isCheckbox" class="checkmark"></span>
              <input *ngIf="item.isRadiobox" [ngModel]="item.isActive" id="checkbox{{item.id}}" type="checkbox">
              <span *ngIf="item.isRadiobox" class="checkmark radio"></span>
              <span *ngIf="item.isRadiobox" class="checkmark radio double-check"></span>
            </label>
          </div>
        </div>
      </ng-template>
    </ng-select>

  </div>
  <svg-icon id="arrow-down" src="assets/icons/icon-arrow-down.svg"></svg-icon>
</div>