import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { Lang } from 'atfcore-commonclasses/bin/classes/anag';

import * as fromApp from '../../ngrx/app.reducers';
import * as AuthActions from '../ngrx/auth.actions';

import { BaseSubscriberComponent } from 'src/app/shared/components/base-subscriber.component';
import { RedirectService } from 'src/app/shared/services/redirect.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { AnagService } from 'src/app/core/services/anag.service';
import { PasswordVerifier } from 'atfcore-commonclasses';
import { ModalService } from 'src/app/core/modal/modal-services.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-local-login',
  templateUrl: './local-login.component.html',
  styleUrls: ['../signin/signin.component.scss', './local-login.component.scss']
})
export class LocalLoginComponent extends BaseSubscriberComponent implements OnInit {
  langs: Lang[];
  isAuthenticated: boolean;
  showLoader: boolean;
  userId: string;
  activationCode: string;

  loginForm: FormGroup;
  signinFormPwRecover: FormGroup;

  get email() {
    return this.loginForm && this.loginForm.get('email') as FormControl;
  }

  get password() {
    return this.loginForm && this.loginForm.get('password') as FormControl;
  }

  get passwordRecover() {
    return this.signinFormPwRecover && this.signinFormPwRecover.get('passwordRecover') as FormControl;
  }

  get passwordRecoverCheck() {
    return this.signinFormPwRecover && this.signinFormPwRecover.get('passwordRecoverCheck') as FormControl;
  }

  constructor(private store: Store<fromApp.AppState>,
    private redirectService: RedirectService,
    private router: Router,
    private anagService: AnagService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.store.select(fromApp.getAvailableLangs)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe((availableLangs) => {
        this.langs = availableLangs;

        if (this.langs && this.langs.length) {

          if (this.isThisCurrentPage('recoveryUserPassword')) {
            this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Recupero Password");
            this.userId = this.route.snapshot.paramMap.get('userId');
            this.activationCode = this.route.snapshot.paramMap.get('token');
            this.signinFormPwRecover = new FormGroup({
              'passwordRecover': new FormControl(undefined, [
                Validators.required,
                Validators.minLength(8),
                this.passwordValidator()
              ]),
              'passwordRecoverCheck': new FormControl(undefined, [
                Validators.required,
                this.matchPasswordValidator()
              ])
            });
            setTimeout(() => {
              this.modalService.open("recoverPasswordModal");
            }, 100)

          } else {
            this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Login");

            this.loginForm = new FormGroup({
              'email': new FormControl(undefined, [Validators.required, Validators.email]),
              'password': new FormControl(undefined, Validators.required)
            });
          }
        }
      });
  }

  saveNewPassword() {

    this.anagService.chengeNewPassword(this.userId, this.activationCode, this.passwordRecover.value)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(data => {

        if (data.error) {
          this.toastr.error(this.translate.instant("errors." + data.error));
        } else if (data.response) {
          this.toastr.success(this.translate.instant("login.CHANGED_NEW_PASSWORD"));
          this.modalService.close("recoverPasswordModal");
          this.redirectService.goToLogin();
        }
      }, (err) => {
        if (err) {
          this.toastr.error(err.message || err);
        }
      });
  }

  private isThisCurrentPage(page: string) {
    return page && this.router && this.router.url && this.router.url.indexOf(page) !== -1;
  }

  /**
   * Check the password
   * The password should be with a minimum of 8 characters, a number and a special characters
   */
  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (!this.signinFormPwRecover) {
        return null;
      }

      if (this.passwordRecoverCheck !== null) {
        this.passwordRecoverCheck.updateValueAndValidity();
      }

      let validity: string = control.value;
      if (validity !== null && validity !== undefined && (/(?=.*["'()+,-./:;<=>?^_`{|}~!@#$%^&*\])(?=.*[0-9])/g)) {
        let passwordVerifier = new PasswordVerifier();
        let passCheck = passwordVerifier.check(control.value);
        if (!passCheck.strong) {
          return { 'error-composition': { value: control.value } };
        } else {
          return null;
        }
      } else return { 'error-composition': { value: control.value } };
    };
  }

  /**
   * Check that the two password are the same
   */
  matchPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      if (!this.signinFormPwRecover) {
        return null;
      }

      const password: string = this.passwordRecover.value;
      const match = password === control.value;
      return match ? null : { 'no-match': { value: control.value } };
    };
  }

  closeModalRecoverPassword() {
    this.modalService.close("recoverPasswordModal");
    this.redirectService.goToLogin();
  }

  /**
   * Dal form passato come parametro
   * Ricavo i dati immessi dall'utente per inserirli nel dispatch dell'action che tenterà il login
   */
  onLogin() {
    this.showLoader = true;
    this.store.dispatch(new AuthActions.DoLogin({ email: this.email.value, password: this.password.value }));
    this.showLoader = false;
  }

  goToLocalLogin() {
    this.redirectService.goToLocalLogin();
  }

  goToLogin() {
    this.redirectService.goToLogin();
  }

  goToHome() {
    this.redirectService.goToHome();
  }

  goToNoCredentials() {
    if (this.email.valid) {
      this.sendMailRecoverPw();
    }
  }

  // Manda la mail per il recupero password
  sendMailRecoverPw() {
    this.anagService.sendMailRecoverPassword(this.email.value)
      .pipe(takeUntil(this.unsubscribe$.asObservable()))
      .subscribe(data => {

        if (data.error) {
          this.toastr.error(this.translate.instant("errors." + data.error));
        } else if (data.response) {
          this.toastr.success(this.translate.instant("login.MAIL_SENDED_RECOVER_PASSWORD"));
        }
      }, (err) => {
        if (err) {
          this.toastr.error(err.message || err);
        }
      });
  }

}
