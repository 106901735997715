import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from "@angular/core";
import { TabInterface } from "../../interfaces/tab.interface";

@Component({
  selector: 'tab',
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"]
})
export class TabComponent implements OnInit {

  @Input() tabs: TabInterface[];

  @Input() currentTabId: number;
  @Input() isProfileTab: boolean;
  @Output() currentTabIdChange = new EventEmitter<number>();

  @Output() onTabClicked: EventEmitter<number> = new EventEmitter();

  @Input() bgColor: string = '#eeeff2';

  selectedTab;

  _forceFullShow: boolean;
  /**
   * Force the show of all the tabs
   */
  @Input() set forceFullShow(value: boolean | string) {
    this._forceFullShow = coerceBooleanProperty(value);
  };

  // Questo serve per aggiornare la select se faccio il resize da desktop a mobile
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.selectedTab = this.tabs[this.currentTabId];
  }

  constructor(private renderer: Renderer2, private elRef: ElementRef<TabComponent>) { }

  ngOnInit() {
    this.selectedTab = [this.tabs[this.currentTabId]];

    this.renderer.setStyle(this.elRef.nativeElement, '--bg-color', this.bgColor, 2);
  }

  /**
   * Select a tab based on his ID
   * @param id Tab
   */
  selectTab(tab: TabInterface) {
    const newTab = this.tabs.filter((x: any) => {
      return x.id === tab?.id;
    });
    if (newTab && newTab.length) {
      this.currentTabId = newTab[0].id;
      this.currentTabIdChange.emit(this.currentTabId);
      this.onTabClicked.emit(tab.id);
    }
  }
}
