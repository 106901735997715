<div class="item-list-wrapper full-width" fxLayout="row" (click)="emitOnItemClicked()"
  fxLayoutAlign="space-between start">

  <div fxLayout="row" fxLayoutAlign="start center" class="full-width">
    <img *ngIf="imageSrc" [src]="imageSrc">
    <div *ngIf="!imageSrc" style="margin-right: 13px;" class="user-placeholder" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <div class="circle-placeholder chat-dimension" fxLayoutAlign="center center">
        {{firstLetters}}
      </div>
  </div>
    <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="12px">
      <p class="name" style="max-width: 95%">
        {{name}}
      </p>
    </div>
  </div>
</div>
