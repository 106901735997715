import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'view-all',
  templateUrl: 'view-all.component.html',
  styleUrls: ['./view-all.component.scss'],
})
export class ViewAllComponent implements OnInit {
  @Input() label: string = 'generic.VIEW_ALL';
  @Input() customClass: string = "";

  constructor() { }

  ngOnInit() { }
}
