import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";

import { UrlService } from "src/app/shared/services/url.service";
import { Survey, TalentFarmSenecaResponse } from 'atfcore-commonclasses';
import { ExtendedSurvey } from 'src/app/shared/models/extended-survey.module';

@Injectable()
export class ItemService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  getHomeHeroContents(fromRecord: number, numRecords: number) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('fromRecord', `${fromRecord || 0}`);
    httpParams = httpParams.append('numRecords', `${numRecords || 20}`);
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + 'glp-get-homepage-slides-for-users', {
      params: httpParams
    });
  }

  // Torna i coins dell'utente loggato
  getHeroCoinBalance() {
    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'get-my-coins-balance');

  }

  // Torna i badge dell'utente loggato
  getHeroBadgeBalance() {
    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-my-earned-badges');
  }

  // Torna i premi dell'utente loggato
  getUserPremiumBalance() {
    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-user-redeemed-prizes');
  }

  sendTotalFeedback(vote: number, comment: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'create-user-platform-feedback', { vote: vote, comment: comment });
  }

  getTotalFeedback() {
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + 'get-user-platform-feedback');
  }

  // Recupera una lista di rilevazioni di un'edizione specifica
  getEditionAssessmentsForPublic(editionItemId?: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('editionItemId', editionItemId || '');
    return this.http.get<SenecaResponse<any>>(this._mediatorUrl + 'get-edition-assessments-for-public', {
      params: httpParams
    });
  }

  // Recupera tutte le Survey dell'utente loggato
  getSurveys(referenceId: string, itemId?: string, startIfNotStarted?: boolean): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('referenceId', referenceId || '');
    httpParams = httpParams.append('itemId', itemId || '');
    httpParams = httpParams.append('startIfNotStarted', startIfNotStarted && startIfNotStarted.toString() || '');
    return this.http.get<SenecaResponse<Survey>>(this._mediatorUrl + 'get-surveys/' + referenceId, {
      params: httpParams
    });
  }

  // Recupera tutte le Survey dell'utente loggato
  getFlatSurveyBySurveyId(surveyId: string): any {
    // Preparo i parametri per la richiesta http
    let httpParams = new HttpParams();
    httpParams = httpParams.append('surveyId', surveyId || '');
    return this.http.get<SenecaResponse<Survey>>(this._mediatorUrl + 'get-flat-survey-by-surveyId/' + surveyId, {
      params: httpParams
    });
  }

  // Aggiorna la survey
  updateSurvey(survey: Survey, closeSurvey: boolean, itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<ExtendedSurvey>>(this._mediatorUrl + 'update-survey', {
      survey: survey,
      closeSurvey: closeSurvey,
      itemId: itemId
    });
  }

}
