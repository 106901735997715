/*
 * Filtro che tronca una stringa direttamente nel DOM
*/

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutString',
  pure: false
})
export class CutStringPipe implements PipeTransform {
  constructor() {
  }
  public transform(value: any, wordwise: any, max: any, tail: any): string {
    if (!value) return '';

    max = parseInt(max, 10);
    if (!max) return value;
    if (value.length <= max) return value;

    value = value.substr(0, max);
    if (wordwise) {
      var lastspace = value.lastIndexOf(' ');
      if (lastspace != -1) {
        if (value.charAt(lastspace - 1) == '.' || value.charAt(lastspace - 1) == ',') {
          lastspace = lastspace - 1;
        }
        value = value.substr(0, lastspace);
      }
    }

    return value + (tail || '…');
  }
}