<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="signin" fxLayout="column">

  <div class="signin-container" fxLayout="column" fxLayoutAlign="start center">
    <!-- LOGO GENERALI -->
    <div *ngIf="!_isSignin" class="signin-logo-container" fxLayout="column" fxLayoutAlign="center center">
      <!-- <svg-icon src="../../../assets/img/generali/logo-generali-white.svg" height="60" width="75">
      </svg-icon> -->
      <img alt="LearningUP" height="90" layout="column"
        src="/assets/img/generali/logo_lockup_bianco.png">
    </div>

    <h3 *ngIf="!_isSignin" translate="signin.TITLE" class="lighter center"></h3>
    <h5 *ngIf="!_isSignin" translate="signin.DESCRIPTION" class="lighter center"></h5>

    <div *ngIf="!_isSignin" fxLayout="column" class="signin-actions-container">
      <custom-button [text]="'introduction.SIGNIN' | translate" (onClick)="openSigninModal()"></custom-button>

      <div class="signin-or" fxLayout="row" fxLayoutAlign="center center">
        <div class="signin-divider"></div>
        <h6 class="lighter" translate="introduction.OR"></h6>
        <div class="signin-divider"></div>
      </div>

      <custom-button importance="secondary" [text]="'introduction.LOGIN' | translate" (onClick)="goToLogin()">
      </custom-button>

      <div class="signin-without-reg" fxFlex fxLayout="column" fxLayoutAlign="end start">
        <h6 class="cursor-pointer center" translate="introduction.NO_SIGNIN" (click)="goToHome()"></h6>
      </div>
    </div>

  </div>

</div>

<!-- Modale che apre la sezione di ricerca mobile -->
<app-modal class="modal-signin" id="signinModal">

  <generali-carousel #signinCarousel [isFullWindow]="true" noScroll hidePoints sectionId="profilation-carousel"
    [contentLength]="5" [scrollToRight]="scrollToRight.asObservable()" [(index)]="carouselIndex"
    (onScrollEvent)="onScrollEvent($event)" [preventOverflowMarginOnRight]="true">

    <!-- Fissi -->
    <ng-container>
      <!-- Percentuale di avanzamento -->
      <div class="modal-signin-percentage">
        <div tabindex="-1" class="modal-signin-percentage-bar" [ngStyle]="{'width': ((carouselIndex * 25) + '%')}">
        </div>
      </div>

      <!-- Chiudi -->
      <svg-icon *ngIf="!isConfirmPrivacyData" tabindex="-1" class="modal-signin-close cursor-pointer"
        src="../../../assets/icons/icon-close-light.svg" height="40" width="40" (click)="closeModalSignin()">
      </svg-icon>
    </ng-container>

    <!-- Prima pagina - email e password -->
    <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="start center">

      <!-- Logo generali -->
      <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
          <!-- <svg-icon tabindex="-1" src="../../../assets/img/generali/logo-generali-white.svg" height="60" width="75">
          </svg-icon> -->
          <img alt="LearningUP" height="90" layout="column"
            src="/assets/img/generali/logo_lockup_bianco.png">
        </div>
      </div>

      <!-- Inserisci email e password -->
      <h4 tabindex="-1" class="center" translate="signin.first-page.TITLE" fxFlexOffset="60px" fxFlexOffset.xs="40px">
      </h4>
      <!-- Inserisci la tua migliore email -->
      <h5 tabindex="-1" translate="signin.first-page.DESCRIPTION" class="lighter"></h5>

      <!-- Form registrazione -->
      <form *ngIf="signinForm" class="modal-signin-page-actions" fxLayout="column" fxLayoutAlign="space-between"
        (ngSubmit)="actionModalButton()" [formGroup]="signinForm">

        <div fxLayout="column">
          <!-- Email -->
          <input-icon [tabIndexValue]="getCurrentTabIndex(0)" class="modal-signin-input-email"
            [placeholder]="'signin.first-page.EMAIL' | translate" [inputValue]="email"
            iconSrc="../../../assets/icons/user/icon-email.svg" [showLoader]="showLoader">
          </input-icon>

          <!-- Descrizione password -->
          <h5 tabindex="-1" class="lighter modal-signin-password-description">
            Inserisci la tua password , deve contenere almeno:<br>
            - 8 caratteri (non si possono ripetere i caratteri più di 2 volte)<br>
            - un carattere minuscolo, uno maiuscolo, un numero ed almeno un carattere speciale tra questi:
            !"#$%&'()*+,-./:;<=>?@[\]^_`&#123;|&#125;~
          </h5>

          <!-- Password -->
          <input-icon [passwordVisibility]="true" [tabIndexValue]="getCurrentTabIndex(0)"
            class="modal-signin-input-password" [placeholder]="'signin.first-page.PASSWORD' | translate"
            [inputValue]="password" type="password" iconSrc="../../../assets/icons/user/icon-password.svg"
            [showLoader]="showLoader">
          </input-icon>

          <!-- Conferma password -->
          <input-icon [passwordVisibility]="true" [tabIndexValue]="getCurrentTabIndex(0)"
            [placeholder]="'signin.first-page.PASSWORD2' | translate" [inputValue]="passwordCheck" type="password"
            iconSrc="../../../assets/icons/user/icon-password.svg" [showLoader]="showLoader">
          </input-icon>
        </div>

        <div fxLayout="column">
          <!-- Continua -->
          <div class="modal-signin-button" fxLayout="row" fxLayoutAlign="center center">
            <custom-button [tabIndexValue]="getCurrentTabIndex(0)" [text]="'signin.first-page.ACTION' | translate"
              [disabled]="signinForm && signinForm.invalid" type="submit">
            </custom-button>
          </div>

          <!-- Continua senza registrazione -->
          <h6 id="no-registration-first" tabindex="-1" class="cursor-pointer"
            translate="signin.first-page.NO_REGISTRATION" (click)="goToHome()">
          </h6>
        </div>

      </form>

    </div>

    <!-- Seconda pagina - Profilazione -->
    <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="start center">

      <!-- Logo generali -->
      <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
          <!-- <svg-icon tabindex="-1" src="../../../assets/img/generali/logo-generali-white.png" height="60" width="75">
          </svg-icon> -->
          <img alt="LearningUP" height="90" layout="column"
            src="/assets/img/generali/logo_lockup_bianco.png">
        </div>
      </div>

      <!-- Completa i tuoi dati -->
      <h4 tabindex="-1" class="center" translate="signin.second-page.TITLE" fxFlexOffset="60px" fxFlexOffset.xs="40px">
      </h4>
      <!-- Avrai un’esperienza personalizzata. -->
      <!-- <h5 tabindex="-1" translate="signin.second-page.DESCRIPTION" fxFlexOffset="7px" fxFlexOffset.xs="4px"
        class="lighter second-page"></h5> -->

      <!-- Form profilazione -->
      <form class="modal-signin-page-actions second-page" fxLayout="column" fxLayoutAlign="space-between"
        fxFlexOffset="60px" fxFlexOffset.xs="34px" (ngSubmit)="actionModalButton()" [formGroup]="userDataForm">

        <div class="full-height" fxLayout="column" fxLayoutAlign="start center">
          <div class="container" fxLayoutGap.xs="16px" fxLayout="row wrap" fxLayout.xs="column"
            fxLayoutAlign="space-between start">

            <!-- Nome -->
            <input-icon [tabIndexValue]="getCurrentTabIndex(1)" [placeholder]="'signin.second-page.NAME' | translate"
              [inputValue]="name" iconSrc="../../../assets/icons/user/icon-user.svg" [showLoader]="showLoader">
            </input-icon>

            <!-- Cognome -->
            <input-icon [tabIndexValue]="getCurrentTabIndex(1)" [placeholder]="'signin.second-page.SURNAME' | translate"
              [inputValue]="surname" iconSrc="../../../assets/icons/user/icon-user.svg" [showLoader]="showLoader">
            </input-icon>

            <!-- Compleanno -->
            <datepicker [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
              [placeholder]="'signin.second-page.BIRTHDAY' | translate" [inputValue]="birthday"
              [showLoader]="showLoader" [maxDate]="_birthdayMaxDate">
            </datepicker>

            <!-- Gender -->
            <select-icon [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
              [placeholder]="'signin.second-page.GENDER' | translate" [inputValue]="gender" [items]="genderList"
              iconSrc="../../../assets/icons/user/icon-user.svg" [showLoader]="showLoader">
            </select-icon>

            <!-- Titolo di studio -->
            <select-icon [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
              [placeholder]="'signin.second-page.EDUCATION' | translate" [inputValue]="education"
              [items]="titleStudyList" iconSrc="../../../assets/icons/user/icon-education.svg"
              [showLoader]="showLoader">
            </select-icon>

            <!-- Indirizzo di studio -->
            <ng-container *ngIf="!fieldInputText">
              <select-icon [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
                [placeholder]="'signin.second-page.FIELD' | translate" [inputValue]="field" [items]="fieldStudyList"
                iconSrc="../../../assets/icons/user/icon-education.svg" [showLoader]="showLoader">
              </select-icon>
            </ng-container>

            <ng-container *ngIf="fieldInputText">
              <input-icon [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
                [placeholder]="'signin.second-page.FIELD' | translate" [inputValue]="field"
                iconSrc="../../../assets/icons/user/icon-education.svg" [showLoader]="showLoader">
              </input-icon>
            </ng-container>

            <!-- CAP -->
            <input-icon [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
              [placeholder]="'signin.second-page.CAP' | translate" [inputValue]="zipCode"
              iconSrc="../../../assets/icons/user/icon-cap.svg" [showLoader]="showLoader">
            </input-icon>

            <!-- Come ci hai conosciuto -->
            <select-icon [tabIndexValue]="getCurrentTabIndex(1)" class="actions-margin-top"
              [placeholder]="'signin.second-page.HOW_KNOW' | translate" [inputValue]="contact" [items]="contactList"
              iconSrc="../../../assets/icons/user/icon-user.svg" [showLoader]="showLoader">
            </select-icon>

          </div>

        </div>

        <div fxLayout="column">
          <!-- CONTINUE -->
          <div class="modal-signin-button on-user-data" fxLayout="row" fxLayoutAlign="center center">
            <custom-button [tabIndexValue]="getCurrentTabIndex(1)" [text]="'signin.first-page.ACTION' | translate"
              [disabled]="userDataForm && userDataForm.invalid" type="submit">
            </custom-button>
          </div>
          <div fxFlexOffset="90px"></div>
        </div>

      </form>

    </div>

    <!-- Terza pagina - Privacy -->
    <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="start center">

      <!-- Logo generali -->
      <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
          <!--<svg-icon tabindex="-1" src="../../../assets/img/generali/logo-generali-white.svg" height="60" width="75">
          </svg-icon> -->
          <img alt="LearningUP" height="90" layout="column"
            src="/assets/img/generali/logo_lockup_bianco.png">
        </div>
      </div>

      <!-- Consensi Privacy -->
      <h4 tabindex="-1" class="center" translate="signin.privacy-page.TITLE" fxFlexOffset="55px" fxFlexOffset.xs="40px">
      </h4>

      <!-- Form profilazione -->
      <form class="modal-signin-page-actions second-page" fxLayout="column" fxLayoutAlign="space-between"
        fxFlexOffset="60px" fxFlexOffset.xs="34px" (ngSubmit)="actionModalButton()" [formGroup]="consentsForm">

        <div class="full-height" fxLayout="column" fxLayoutAlign="start center">

          <!-- Privacy -->
          <div class="privacy-container" fxLayout="column" fxLayoutAlign="start start">
            <!-- Confermo ... -->
            <custom-checkbox [tabIndexValue]="getCurrentTabIndex(2)" [inputForm]="privacy" [showLoader]="showLoader">
              <div class="second-page-privacy-container" fxLayout="row wrap" fxLayoutAlign="start start">
                <p tabindex="-1">
                  <span tabindex="-1" class="second-page-privacy-text lighter"
                    translate="signin.privacy-page.PRIVACY"></span>
                  <span id="privacy-signin" class="cursor-pointer" tabindex="-1"
                    translate="signin.privacy-page.PRIVACY2" (click)="goToPrivacy($event)"></span>
                  <span class="second-page-privacy-text lighter" tabindex="-1"
                    translate="signin.privacy-page.AND"></span>
                  <span id="privacy-signin" tabindex="-1" class="cursor-pointer"
                    translate="signin.privacy-page.PRIVACY3" (click)="goToTermsOfUse($event)"></span>
                  <span tabindex="-1" class="second-page-privacy-text lighter"
                    translate="signin.privacy-page.PRIVACY4"></span>
                </p>
              </div>
            </custom-checkbox>

            <!-- Preso atto dell’informativa di cui sopra, esprimo i seguenti consensi: -->
            <h5 tabindex="-1" translate="signin.privacy-page.DESCRIPTION" fxFlexOffset="60px" fxFlexOffset.xs="40px"
              class="privacy-description small lighter second-page"></h5>

            <!-- Trattamento dati personali -->
            <custom-checkbox fxFlexOffset="60px" fxFlexOffset.xs="40px" [tabIndexValue]="getCurrentTabIndex(2)"
              [inputForm]="privacyDataSharingConfirm">
              <div class="second-page-privacy-container" fxLayout="row wrap" fxLayoutAlign="start start">
                <p tabindex="-1">
                  <span tabindex="-1" class="second-page-privacy-text lighter"
                    translate="profile.SHARING_PRIVACY"></span>
                </p>
              </div>
            </custom-checkbox>

            <custom-checkbox *ngIf="privacyAnalyticsConfirm" fxFlexOffset="30px" fxFlexOffset.xs="20px"
              [tabIndexValue]="getCurrentTabIndex(2)" [inputForm]="privacyAnalyticsConfirm">
              <div class="second-page-privacy-container" fxLayout="row wrap" fxLayoutAlign="start start">
                <p tabindex="-1">
                  <span tabindex="-1" class="second-page-privacy-text lighter"
                    translate="profile.ANALYTICS_PRIVACY"></span>
                </p>
              </div>
            </custom-checkbox>
          </div>

        </div>

        <div fxLayout="column">
          <!-- CONTINUE -->
          <div class="modal-signin-button" fxLayout="row" fxLayoutAlign="center center">
            <custom-button [tabIndexValue]="getCurrentTabIndex(2)" [text]="'signin.first-page.ACTION' | translate"
              [disabled]="consentsForm && consentsForm.invalid" type="submit">
            </custom-button>
          </div>
          <div fxFlexOffset="90px"></div>
        </div>

      </form>

    </div>

    <!-- Quarta pagina - Newsletters -->
    <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="start center">

      <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
          <!-- <svg-icon tabindex="-1" src="../../../assets/img/generali/logo-generali-white.svg" height="60" width="75">
          </svg-icon> -->
          <img alt="LearningUP" height="90" layout="column"
            src="/assets/img/generali/logo_lockup_bianco.png">
        </div>
      </div>

      <h4 tabindex="-1" class="center" translate="signin.third-page.TITLE" fxFlexOffset="60px" fxFlexOffset.xs="40px">
      </h4>

      <div class="privacy-container" fxLayout="column" fxLayoutAlign="start start">

        <h5 tabindex="-1" fxFlexOffset="55px" translate="signin.third-page.SUBTITLE" class="lighter third-page"></h5>

        <h6 tabindex="-1" fxFlexOffset="10px" translate="signin.third-page.DESCRIPTION"
          class="lighter small primary third-page">
        </h6>

        <custom-checkbox fxFlexOffset="42px" [tabIndexValue]="getCurrentTabIndex(3)" [inputForm]="newsletters"
          [showLoader]="showLoader">
          <div fxLayout="row" fxLayout="start center" fxFlexOffset="12px">
            <h6 tabindex="-1" translate="signin.third-page.NEWSLETTERS" class="lighter small primary third-page">
            </h6>
          </div>
        </custom-checkbox>
      </div>

      <div fxLayout="column">
        <div class="modal-signin-button third-page" fxLayout="row" fxLayoutAlign="center center">
          <custom-button [tabIndexValue]="getCurrentTabIndex(3)" [text]="'generic.CONTINUE_UPPER' | translate"
            (onClick)="actionModalButton()">
          </custom-button>
        </div>
        <div fxFlexOffset="90px"></div>
      </div>

    </div>

    <!--
    <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="start center">

      <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
        <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
          <img alt="LearningUP" height="90" layout="column" src="/assets/img/generali/logo_lockup_bianco.png">
        </div>
      </div>

      <h4 tabindex="-1" class="center" translate="signin.benefits-profiling.TITLE" fxFlexOffset="60px" fxFlexOffset.xs="40px">
      </h4>
      <h5 tabindex="-1" translate="signin.benefits-profiling.DESCRIPTION" class="lighter third-page"></h5>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start center"
        fxFlexOffset="62px" fxFlexOffset.xs="40px">

        <div class="modal-signin-badge">
          <div class="container" fxLayout="column" fxLayoutAlign="start center">
            <svg-icon tabindex="-1" class="badge1" src="../../../assets/icons/badge/icon-star.svg" height="100"
              width="100">
            </svg-icon>
            <h5 tabindex="-1" translate="signin.benefits-profiling.COINS" class="badge"></h5>
            <h6 tabindex="-1" translate="signin.benefits-profiling.COINS_DESCRIPTION" class="badge lighter"></h6>
          </div>
        </div>

        <div class="modal-signin-badge badge">
          <div class="container" fxLayout="column" fxLayoutAlign="start center">
            <svg-icon tabindex="-1" class="badge2" src="../../../assets/icons/badge/icon-cup.svg" height="100"
              width="100">
            </svg-icon>
            <h5 tabindex="-1" translate="signin.benefits-profiling.BADGE" class="badge"></h5>
            <h6 tabindex="-1" translate="signin.benefits-profiling.BADGE_DESCRIPTION" class="badge lighter"></h6>
          </div>
        </div>

      </div>

      <div fxLayout="column">
        <div class="modal-signin-button third-page" fxLayout="row" fxLayoutAlign="center center">
          <custom-button [tabIndexValue]="getCurrentTabIndex(4)" [textUppercase]="true" [text]="'signin.benefits-profiling.CREATE_YOUR_PROFILE' | translate"
            (click)="actionModalButton()">
          </custom-button>
        </div>
      </div>

    </div> -->

    <!-- Quinta pagina - Registrazione completata -->
    <div class="modal-signin-carousel-page" fxLayout="column" fxLayoutAlign="space-between center">

      <div fxLayout="column" fxLayoutAlign="start center">
        <!-- LOGO GENERALI -->
        <div class="modal-signin-logo-container" fxLayout="row" fxLayoutAlign="center center">
          <div class="modal-signin-logo" fxLayout="column" fxLayoutAlign="center center">
            <!-- <svg-icon tabindex="-1" src="../../../assets/img/generali/logo-generali-white.png" height="60" width="75">
            </svg-icon> -->
            <img alt="LearningUP" height="90" layout="column"
              src="/assets/img/generali/logo_lockup_bianco.png">
          </div>
        </div>

        <!-- TITLE -->
        <h4 tabindex="-1" class="center" translate="signin.fourth-page.TITLE" fxFlexOffset="60px"
          fxFlexOffset.xs="40px"></h4>
        <!-- ICON CHECK -->
        <svg-icon tabindex="-1" class="completed-icon" src="../../../assets/icons/badge/icon-check.svg" height="100"
          width="100">
        </svg-icon>
        <!-- DESCRIPTION -->
        <h5 tabindex="-1"
          [translate]="privacyAnalyticsConfirm && privacyAnalyticsConfirm.value ? 'signin.fourth-page.DESCRIPTION_PROFILED' : 'signin.fourth-page.DESCRIPTION'"
          class="lighter center fourth-page"></h5>
      </div>

      <!-- GO TO HOME -->
      <div class="modal-signin-button fourth-page" fxLayout="row" fxLayoutAlign="center center">
        <custom-button [tabIndexValue]="getCurrentTabIndex(4)" [text]="'signin.fourth-page.ACTION' | translate"
          (onClick)="actionModalButton()">
        </custom-button>
      </div>

    </div>

  </generali-carousel>

</app-modal>

<!-- Modale per l'uscita dalla seconda pagina senza salvare i dati utente -->
<app-modal class="modal-signin-go-without-data" id="signinModalWithoutData">

  <div class="container" fxLayout="column" fxLayoutAlign="space-between center">

    <!-- CLOSE ICON -->
    <svg-icon class="modal-signin-go-without-data-close cursor-pointer" src="../../../assets/icons/icon-close-light.svg"
      height="40" width="40" (click)="closeWithoutDataModal()">
    </svg-icon>

    <div fxLayout="column" fxLayoutAlign="start center">
      <!-- TITLE -->
      <h4 class="center lighter" translate="signin.go-no-data.TITLE"></h4>
      <!-- DESCRIPTION -->
      <h6 class="center lighter" translate="signin.go-no-data.DESCRIPTION"></h6>
    </div>

    <div ngClass.xs="full-width" fxLayout="row" fxLayout.xs="column-reverse" fxLayoutAlign="center center">
      <!-- Torna alla registrazione -->
      <custom-button ngClass.xs="full-width" fxFlexOffset.xs="32px"
        [text]="'signin.go-no-data.CANCEL_BTN' | translate | uppercase" importance="secondary"
        (onClick)="closeWithoutDataModal()">
      </custom-button>
      <!-- Abbandona -->
      <custom-button ngClass.xs="full-width" fxFlexOffset="32px" fxFlexOffset.xs="0px"
        [text]="'signin.go-no-data.CONFIRM_BTN' | translate | uppercase" (onClick)="continueSigninWithoutData()">
      </custom-button>
    </div>

  </div>

</app-modal>

<app-modal-badge id="modal-badge-profile"></app-modal-badge>
