/*
 * Modello che dinisce una classe con i dati comuni e utili per l'applicazione (tipo il context)
*/

import { RequiredAuth } from "atfcore-commonclasses";
const euassTenants = ["EUASS_GRP"];

export class GlobalApplicationData {
    // Prefisso applicativo (context) e le altre varibili del server
    public applicationContext: string;
    public isAliveAddress: string;
    public isMaintenanceAddress: string;
    public eTicketResourcePath: string;
    public orginalRequestedURL: string;
    // Indica se mostrare un alert di "sessione/token scaduta/o"
    public showSessionExpiredAlert: boolean;
    // Indica alla pagina di autorizzazione che deve effettuare un reload completo, in modo da pulire tutti gli status
    public forceFullPageRefresh: boolean;
    // Ruoli disponibili
    // Questo array di oggetti role/label sarà popolato durante la chiamata che recupera i dati dell'utente loggato
    public availableUserGroups: string[];
    // Azioni a cui un utente è abilitato
    public actions: string[];
    // Indica se deve essere forzata l'apertura della dialog delle preferenze utente
    public forceShowUserPrefDialog: boolean;
    // Tipo di device
    public deviceType: string;
    // Agent dell'utente
    public userAgent: string;

    constructor(applicationContext: string, isAliveAddress: string, isMaintenanceAddress: string, eTicketResourcePath: string, orginalRequestedURL: string, showSessionExpiredAlert: boolean, forceFullPageRefresh: boolean, availableUserGroups: string[], actions: string[], forceShowUserPrefDialog: boolean, deviceType: string, userAgent: string) {
        this.applicationContext = applicationContext;
        this.isAliveAddress = isAliveAddress;
        this.isMaintenanceAddress = isMaintenanceAddress;
        this.eTicketResourcePath = eTicketResourcePath;
        this.orginalRequestedURL = orginalRequestedURL;
        this.showSessionExpiredAlert = showSessionExpiredAlert;
        this.forceFullPageRefresh = forceFullPageRefresh;
        this.availableUserGroups = availableUserGroups;
        this.actions = actions;
        this.forceShowUserPrefDialog = forceShowUserPrefDialog;
        this.deviceType = deviceType;
        this.userAgent = userAgent;
    }
}

export function authControl(auths: string[]) {
    let authObject: any = {};
    if (auths && auths.filter((x: any) => {
        return x == RequiredAuth.COURSEMANAGER_MANAGE_MEETING_MANAGER;
    }).length
    ) {
        authObject.isManager = true;
    }
    if (auths && auths.filter((y: any) => {
        return y == RequiredAuth.COURSEMANAGER_IS_ADMIN;
    }).length
    ) {
        authObject.isAdmin = true;
    }
    if (auths && auths.filter((y: any) => {
        return y == RequiredAuth.COURSEMANAGER_MANAGE_PERFORMANCE;
    }).length
    ) {
        authObject.isPerformance = true;
    }
    if (auths && auths.filter((y: any) => {
        return y == RequiredAuth.COURSEMANAGER_LINGUISTIC_MANAGEMENT;
    }).length
    ) {
        authObject.isLinguisticManagement = true;
    }
    if (auths && auths.filter((x: any) => {
        return x == RequiredAuth.GTP_MANAGE_FACULTY;
    }).length
    ) {
        authObject.isFacultyAdmin = true;
    }
    return authObject;
}

export function isEuass(tenant) {
    if (tenant) {
      for (let tenantsCounter = 0; tenantsCounter < euassTenants.length; tenantsCounter++) {
        if (tenant === euassTenants[tenantsCounter]) {
          return true;
        }
      }
    }
    return false;
  }