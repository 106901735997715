import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SenecaResponse } from 'atfcore-commonclasses/bin/classes/common';

import { CourseDetails, FilterCourseResponse } from 'src/app/shared/interfaces/course.interface';

@Injectable()
export class CourseMockService {

  constructor() {

  }

  getCourseFilters(): Observable<SenecaResponse<FilterCourseResponse>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {
          courseCategories: [
            {
              count: 1,
              name: 'Technology'
            }, {
              count: 2,
              name: 'Design'
            }, {
              count: 3,
              name: 'Innovation'
            }, {
              count: 4,
              name: 'Business'
            }, {
              count: 5,
              name: 'Strategy'
            }, {
              count: 6,
              name: 'Science'
            }, {
              count: 7,
              name: 'Languages'
            }, {
              count: 8,
              name: 'Engineer'
            }, {
              count: 9,
              name: 'Sales'
            }, {
              count: 10,
              name: 'Data analysis'
            }
          ],
          courseLevels: [
            {
              count: 1,
              name: 'Avanzato'
            }, {
              count: 2,
              name: 'Intermedio'
            }, {
              count: 3,
              name: 'Iniziante'
            }
          ]
        }
      });
      obsevable.complete();
    });

    // return this.http.get<any>('https://atf.alloy.it/rest-api/coursemanager-mediator/glp-get-item-or-object-detail-by-id', {
    //     params: httpParams
    // });
  }

  getCoursesDetails(searchText: string): Observable<SenecaResponse<any>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {
          featuredCourses: [
            {
              title: 'User Experience Design Training',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_03.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 6,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }, {
              title: 'Introduction to A/B Testing',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_04.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 1.5,
              badgeType: 'accent',
              badgeTitle: 'PREMIUM'
            }, {
              title: 'Creative Direction',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_05.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 3,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }, {
              title: 'User Experience Design Training',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_03.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 6,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }, {
              title: 'Introduction to A/B Testing',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_04.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 1.5,
              badgeType: 'accent',
              badgeTitle: 'PREMIUM'
            }, {
              title: 'Creative Direction',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_05.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 3,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }
          ],
          forYouCourses: [
            {
              title: 'User Experience Design Training',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_03.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 6,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }, {
              title: 'Introduction to A/B Testing',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_04.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 1.5,
              badgeType: 'accent',
              badgeTitle: 'PREMIUM'
            }, {
              title: 'Creative Direction',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_05.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 3,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }, {
              title: 'User Experience Design Training',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_03.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 6,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }, {
              title: 'Introduction to A/B Testing',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_04.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 1.5,
              badgeType: 'accent',
              badgeTitle: 'PREMIUM'
            }, {
              title: 'Creative Direction',
              itemId: '12345678',
              itemImage: '../../../assets/img/courses/Corsi_05.jpg',
              arguments: [
                {
                  tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                  title: "Emotion"
                },
                {
                  tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                  title: "Food"
                }
              ],
              durationInMinutes: 3,
              badgeType: 'primary',
              badgeTitle: 'VINCI 100'
            }
          ],
          coursesIn: [
            {
              category: 'Technology',
              courses: [
                {
                  title: 'User Experience Design Training',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_03.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 6,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }, {
                  title: 'Introduction to A/B Testing',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_04.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 1.5,
                  badgeType: 'accent',
                  badgeTitle: 'PREMIUM'
                }, {
                  title: 'Creative Direction',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_05.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 3,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }, {
                  title: 'User Experience Design Training',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_03.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 6,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }, {
                  title: 'Introduction to A/B Testing',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_04.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 1.5,
                  badgeType: 'accent',
                  badgeTitle: 'PREMIUM'
                }, {
                  title: 'Creative Direction',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_05.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 3,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }
              ]
            }, {
              category: 'Design',
              courses: [
                {
                  title: 'User Experience Design Training',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_03.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 6,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }, {
                  title: 'Introduction to A/B Testing',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_04.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 1.5,
                  badgeType: 'accent',
                  badgeTitle: 'PREMIUM'
                }, {
                  title: 'Creative Direction',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_05.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 3,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }, {
                  title: 'User Experience Design Training',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_03.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 6,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }, {
                  title: 'Introduction to A/B Testing',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_04.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 1.5,
                  badgeType: 'accent',
                  badgeTitle: 'PREMIUM'
                }, {
                  title: 'Creative Direction',
                  itemId: '12345678',
                  itemImage: '../../../assets/img/courses/Corsi_05.jpg',
                  arguments: [
                    {
                      tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                      title: "Emotion"
                    },
                    {
                      tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                      title: "Food"
                    }
                  ],
                  durationInMinutes: 3,
                  badgeType: 'primary',
                  badgeTitle: 'VINCI 100'
                }
              ]
            }
          ]
        }
      });
      obsevable.complete();
    });
  }

  /**
   * Get the detail of an event
   * @param id Id of the event
   */
  getCourseDetails(id: string): Observable<SenecaResponse<CourseDetails>> {
    return new Observable(obsevable => {

      let courseDetails: CourseDetails = {
        title: 'Design Thinking',
        description: {
          aboutTheCourse: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis varius quam quisque id.',
          whatToLearn: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis varius quam quisque id. Lorem ipsum dolor sit amet, consectetur adipiscing elit.

          Dictum non consectetur a erat nam at lectus urna duis. Augue eget arcu dictum varius duis at. Quam quisque id diam vel quam. Ultrices neque ornare aenean euismod elementum.

          Egestas quis ipsum suspendisse ultrices gravida dictum. Egestas egestas fringilla phasellus faucibus. Ultricies integer quis auctor elit sed. Odio aenean sed adipiscing diam donec adipiscing.

          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Congue eu consequat ac felis. Id diam maecenas ultricies mi eget mauris. Morbi non arcu risus quis varius quam quisque id.`
        },
        info: {
          points: 100,
          area: 'Technology',
          type: 'video-course',
          certificate: true,
          duration: 240,
          tags: ['Artificial Intelligence', 'Lorem', 'Lorem ipsum'],
          score: 4.2
        },
        coursesCorrelated: [
          {
            title: 'User Experience Design Training',
            itemId: '12345678',
            itemImage: '../../../assets/img/courses/Corsi_03.jpg',
            arguments: [
              {
                tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                title: "Emotion"
              },
              {
                tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                title: "Food"
              }
            ],
            durationInMinutes: 6,
            badgeType: 'primary',
            badgeTitle: 'VINCI 100'
          }, {
            title: 'Introduction to A/B Testing',
            itemId: '12345678',
            itemImage: '../../../assets/img/courses/Corsi_04.jpg',
            arguments: [
              {
                tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                title: "Emotion"
              },
              {
                tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                title: "Food"
              }
            ],
            durationInMinutes: 1.5,
            badgeType: 'accent',
            badgeTitle: 'PREMIUM'
          }, {
            title: 'Creative Direction',
            itemId: '12345678',
            itemImage: '../../../assets/img/courses/Corsi_05.jpg',
            arguments: [
              {
                tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                title: "Emotion"
              },
              {
                tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                title: "Food"
              }
            ],
            durationInMinutes: 3,
            badgeType: 'primary',
            badgeTitle: 'VINCI 100'
          }, {
            title: 'User Experience Design Training',
            itemId: '12345678',
            itemImage: '../../../assets/img/courses/Corsi_03.jpg',
            arguments: [
              {
                tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                title: "Emotion"
              },
              {
                tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                title: "Food"
              }
            ],
            durationInMinutes: 6,
            badgeType: 'primary',
            badgeTitle: 'VINCI 100'
          }, {
            title: 'Introduction to A/B Testing',
            itemId: '12345678',
            itemImage: '../../../assets/img/courses/Corsi_04.jpg',
            arguments: [
              {
                tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                title: "Emotion"
              },
              {
                tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                title: "Food"
              }
            ],
            durationInMinutes: 1.5,
            badgeType: 'accent',
            badgeTitle: 'PREMIUM'
          }, {
            title: 'Creative Direction',
            itemId: '12345678',
            itemImage: '../../../assets/img/courses/Corsi_05.jpg',
            arguments: [
              {
                tagId: "590ead6d-8677-11eb-b38c-000d3aade8fd",
                title: "Emotion"
              },
              {
                tagId: "58f987f9-8677-11eb-b38c-000d3aade8fd",
                title: "Food"
              }
            ],
            durationInMinutes: 3,
            badgeType: 'primary',
            badgeTitle: 'VINCI 100'
          }
        ],
        user: {
          subscribed: false,
          started: false,
          ended: false
        }
      };

      if (id === "123456789") {
        courseDetails.info.certificate = false;
      }

      obsevable.next({
        error: null,
        response: courseDetails
      });
      obsevable.complete();
    });
  }

  /***
   * Registration to an event
   * @param id Id of the event
   */
  subscribeToCourse(id: string): Observable<SenecaResponse<any>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {

        }
      });
      obsevable.complete();
    });
  }

  /**
   * Send the feedback survey of the course
   * @param id Course's id
   */
  sendFeedbackSurvey(id: string): Observable<SenecaResponse<any>> {
    return new Observable(obsevable => {
      obsevable.next({
        error: null,
        response: {

        }
      });
      obsevable.complete();
    });
  }
}

