import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, AfterViewInit } from '@angular/core';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { SelectType } from '../../interfaces/select.interface';

export interface FilterItem { id: string, text: string, tagId?: string };

@Component({
  selector: 'custom-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, AfterViewInit {

  @Input() noDataFoundLabel?: string;
  @Input() startSearchLabel?: string;
  @Input() loadingDataLabel?: string;
  @Input() placeholderLabel?: string;
  @Input() selectAllLabel?: string;
  @Input() isOnSurvey?: boolean;
  @Input() disabled?: boolean;
  @Input() selectedOptionMaxLength?: number;
  @Input() inProjectDetailSelect?: boolean;
  @Input() noWhitespace?: boolean;
  @Input() typeahead?;
  @Input() typetosearch?: string;
  @Input() avatarImageAccessibilityText?: string;
  @Input() areUsersList?: boolean;
  @Input() areGroupsList?: boolean;

  /**
   * Allow to search for value.
   */
  _searchable: boolean;
  @Input() set searchable(value: boolean | string) {
    this._searchable = coerceBooleanProperty(value);
  };

  /**
   * Translate the label
   */
  _labelToTranslate: boolean;
  @Input() set labelToTranslate(value: boolean | string) {
    this._labelToTranslate = coerceBooleanProperty(value);
  };

  /**
   * Enable virtual scroll for better performance when rendering a lot of data
   */
  _virtualScroll: boolean;
  @Input() set virtualScroll(value: boolean | string) {
    this._virtualScroll = coerceBooleanProperty(value);
  };

  /**
   * Object property to use for selected model. By default binds to whole object.
   */
  @Input() bindValue?: string;

  /**
   * Classe aggiuntiva per il contenitore wrapper
   */
  @Input() customContainerClass?: string = '';
  @Input() parameterNameOfSelectedItem?: string = '';

  @Input() isMultipleSelectionVisible?: boolean = false;

  /**
   * Values selected
   */
  _selectedValue: any;
  @Input()
  set selectedValue(value: any) {
    this._selectedValue = value;
  }
  get selectedValue(): any {
    return this._multiple ? this._selectedValue : [this._selectedValue];
  };

  /**
   * Allows to create custom options.
   */
  @Input() addTag?: boolean | ((term: string) => any | Promise<any>);

  /**
   * You can set the loading state from the outside (e.g. async items loading)
   */
  _loading: boolean;
  @Input() set loading(value: boolean | string) {
    this._loading = coerceBooleanProperty(value);
  };

  /**
   * Clear selected values one by one when clicking backspace.
   */
  @Input() clearOnBackspace: boolean = true;

  _hideSelected: boolean;
  @Input() set hideSelected(value: boolean | string) {
    this._hideSelected = coerceBooleanProperty(value);
  };

  _multiple: boolean;
  @Input() set multiple(value: boolean | string) {
    this._multiple = coerceBooleanProperty(value);
  };

  /**
   * Object property to use for label.
   */
  _bindLabel;
  @Input() set bindLabel(value) {
    this._bindLabel = value;
  };

  /**
   * Set the dropdown position on open
   */
  @Input() dropdownPosition?: DropdownPosition = 'bottom';

  @Input() attrAriaLabel?: string;

  /**
   * Marks first item as focused when opening/filtering.
   */
  @Input() markFirst: boolean = true;
  // lista dei risultati

  @Input() items: any[];

  @Input() type: SelectType = 'default';

  @Input() isCm2EditionsSelect: boolean = false;

  @Input() isSecondSelectEdition: boolean = false;

  @Input() arrowIconSrc?: string = "../../../../assets/icons/icon-arrow-down.svg";

  @Output() onBindModelChanged = new EventEmitter<any>();
  @Output() onOptionClicked = new EventEmitter<any>();

  /**
   * Fired on model change. Outputs whole model
   */
  @Output() onSelectionChanged = new EventEmitter<any>();

  /**
   * Fired when item is added while [multiple]="true". Outputs added item
   */
  @Output() addItem = new EventEmitter<any>();

  /**
   * Fired when item is removed while [multiple]="true"
   */
  @Output() removeItem = new EventEmitter<any>();

  @Output() onBlur = new EventEmitter<any>();

  /**
   * Fired when scrolled to the end of items. Can be used for loading more items in chunks.
   */
  @Output() scrollToEnd = new EventEmitter<any>();

  /**
   * Fired on select dropdown close
   */
  @Output() close = new EventEmitter();

  @ViewChild('alloySelect') alloySelect: NgSelectComponent;

  constructor(private renderer: Renderer2) {

  }

  ngOnInit(): void {
    if (!this._multiple && this._selectedValue && this._selectedValue.length > 0) {
      if (!this.isOnSurvey) {
        this._selectedValue = this._selectedValue[0];
      }
    }
  }

  labelValue(item) {
    return item[this._bindLabel];
  }

  ngAfterViewInit() {
    if (this.attrAriaLabel) {
      setTimeout(() => {
        if (this.alloySelect && this.alloySelect.searchInput) {
          this.renderer.setAttribute(this.alloySelect.searchInput.nativeElement, "aria-label", this.attrAriaLabel);
        }
      })
    }
  }

  compareWith(item, selected) {
    if (item.answerId && item.answerId.length) {
      return item.answerId === selected;
    } else if (item.courseDateId && item.courseDateId.length) {
      return item.courseDateId === selected;
    } else if (item.placeId && item.placeId.length) {
      return item.placeId === selected;
    } else if (item.dayLabel && item.dayLabel.length) {
      return item.dayLabel === selected;
    } else if (item.fullName && item.fullName.length && selected && selected.fullName) {
      return item.fullName === selected.fullName;
    } else if(item.isSpecialProjectTypeOption && selected.isSpecialProjectTypeOption && (item.isSpecialProjectTypeOption == selected.isSpecialProjectTypeOption)) {
      return item === selected;
    } else {
      return item.id === selected.id;
    }
  };

  resolveItemTitle(item: any, isForSelectedItem?: boolean) {
    if (this.parameterNameOfSelectedItem) {
      return item[this.parameterNameOfSelectedItem];
    } else if (this.areGroupsList) {
      return item.title;
    } else if (typeof item === 'string' || typeof item === 'number') {
      return item;
    } else {
      return item.name || '';
    }
  }

  emitRemoveItem(item: any): void {
    if (this.removeItem && this.removeItem instanceof EventEmitter) {
      this.removeItem.emit(item);
    }
  }

  emitOnBlur(data: any): void {
    if (this.onBlur && this.onBlur instanceof EventEmitter) {
      this.onBlur.emit(data);
    }
  }

  emitAddItem(item: any): void {
    if (this.addItem && this.addItem instanceof EventEmitter) {
      this.addItem.emit(item);
    }
  }

  emitOnClose(): void {
    if (this.close && this.close instanceof EventEmitter) {
      this.close.emit();
    }
  }

  emitOnSelectionChanged(data: any): void {
    if (this.onSelectionChanged && this.onSelectionChanged instanceof EventEmitter) {
      this.onSelectionChanged.emit(data);
    }
  }

  emiOnModelChanged(data?: any): void {
    if (this.onBindModelChanged && this.onBindModelChanged instanceof EventEmitter) {
      this.onBindModelChanged.emit(data);
    }
  }

  emitOnOptionClicked(data?) {
    if (this.onOptionClicked && this.onOptionClicked instanceof EventEmitter) {
      this.onOptionClicked.emit(data);
    }
  }

  emitOnScrollToEnd(data?: any): void {
    if (this.scrollToEnd && this.scrollToEnd instanceof EventEmitter) {
      this.scrollToEnd.emit(data);
    }
  }

  sliceAndConcatDots(label: string, maxLength: number = 15): string {
    const lengthToConsider = this.selectedOptionMaxLength || maxLength;
    if (label && label.length && (label.length > lengthToConsider)) {
      return label.slice(0, lengthToConsider) + '...';
    } else {
      return label;
    }
  }

  // Torna l'avatar di un utente
  getUserAvatar(selectedUser): string {
    return (selectedUser && selectedUser.userOptions && selectedUser.userOptions.avatarImage) || (selectedUser && selectedUser && selectedUser.chiaveSesso == "M" ? 'assets/img/placeholder.png' : 'assets/img/placeholder_female.png');
  }

}
