import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { WelcomePageConfig } from "atfcore-commonclasses/bin/classes/talent-farm";

import { UrlService } from "src/app/shared/services/url.service";

@Injectable()
export class WelcomeService {
  private _mediatorUrl: string;
  private _cm2MediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
    this._cm2MediatorUrl = this.urlService.getCm2ServiceMediatorUrl();
  }

  /**
   * Retrieve configuration of welcome page fe
   * @return {Item} The configuration
   */
  getWelcomePage(): Observable<SenecaResponse<WelcomePageConfig>> {
    let httpParams: HttpParams = new HttpParams();

    return this.http.get<SenecaResponse<WelcomePageConfig>>(this._mediatorUrl + 'get-welcome-page', { params: httpParams });
  }

  isProfilingComplete(): Observable<SenecaResponse<boolean>> {
    let httpParams: HttpParams = new HttpParams();

    return this.http.get<SenecaResponse<boolean>>(this._cm2MediatorUrl + 'glp-is-profiling-complete', { params: httpParams });
  }


}
