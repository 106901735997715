/*
 * Componente per la pagina di errore compatibilità, che può verificarsi qualora un utente acceda con un device o con un browser non supportato
*/

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';

@Component({
    selector: 'app-compatibility-error',
    templateUrl: './compatibility-error.component.html',
    styleUrls: ['./compatibility-error.component.scss']
})
export class CompatibilityErrorComponent {
    constructor(private router: Router,
        private analyticsService: AnalyticsService) {
        // Invio un evento di tracciamento a Google Tag Manager per Google Analytics
        this.analyticsService.sendVirtualPageViewEvent(this.router.url, "Errore compatibilità dispositivo");
    }

}
