import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "custom-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"]
})
export class RadioComponent implements OnInit {

  @Input() inputForm: FormControl = new FormControl(false);

  @Input() name: string = "radio";

  @Input()
  set simpleValue(value: boolean) {
    this.inputForm.setValue(value, { emitEvent: false });
  }
  get simpleValue(): boolean {
    return this.inputForm.value;
  };
  @Output() simpleValueChange = new EventEmitter<boolean>();

  @Input() bindValue?: string;

  _tabIndex: string = "0";
  @Input() set tabIndexValue(value: string) {
    if (value) {
      this._tabIndex = value;
      const element = document.getElementById(this._id);
      if (element) {
        element.setAttribute("tabindex", value);
      }
    }
  };

  _showLoader: boolean;
  @Input() set showLoader(value: boolean | string) {
    this._showLoader = coerceBooleanProperty(value);
  };

  @Input() set disabled(value: boolean | string) {
    if (coerceBooleanProperty(value)) {
      this.inputForm.disable();
    } else {
      this.inputForm.enable();
    }
  };

  @Output() onChangeValue = new EventEmitter<void>();

  _id: string;
  @Input() id: string | number;
  @Input() text: string;
  @Input() onSurveyEdit: boolean;
  @Input() model;

  @Input() value: string;

  constructor() { }

  ngOnInit() {
    if (!this.id) {
      this.id = Math.floor((Math.random() * 10000) + 1);
    }
    this._id = "custom-radio-" + this.id;

  }

  changeValue(event?) {
    this.simpleValueChange.emit(this.simpleValue);
    this.onChangeValue.emit();
  }

}
