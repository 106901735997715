import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { BlogPost } from "atfcore-commonclasses/bin/classes/blog";

import { UrlService } from "src/app/shared/services/url.service";
import { ContentArea, handleContentAreas, handleFromRecord, handleNumRecords, handleSearchedText, handleSorting, handleTagIds } from "src/app/shared/utils";
import { TalentFarmSenecaResponse } from 'atfcore-commonclasses';

@Injectable()
export class BlogService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  /**
   * Retrieves the count of available Blog articles.
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {string[]} tagIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @return {number}
   */
  countBlogArticles(contentArea?: ContentArea, searchedText?: string, tagIds?: string | string[], mySavedOnly?: boolean, profiledForMe?: boolean): Observable<SenecaResponse<number>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleContentAreas(httpParams, contentArea);
    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-blog-articles', { params: httpParams });
  }

  /**
   * Retrieves a specific Blog article with all its relevant data
   * @param {string} itemId - Required by the action: The id of the article to retrieve
   * @return {BlogPost} The requested blog article
   */
  getBlogArticle(itemId: string): Observable<TalentFarmSenecaResponse<BlogPost>> {

    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('itemId', itemId);

    return this.http.get<TalentFarmSenecaResponse<BlogPost>>(this._mediatorUrl + 'get-blog-article', { params: httpParams });
  }

  /**
   * Retrieves available Blog articles that matches the criteria
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string} sorting - Optional:  Flag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc) (default D if no filter, S otherwise)
   * @param {string[]} tagIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @return {BlogPost[]}
   */
  listBlogArticles(contentArea?: ContentArea, fromRecord?: number, numRecords?: number, searchedText?: string, tagIds?: string | string[],
    sorting?: string, mySavedOnly?: boolean, allData?: boolean, profiledForMe?: boolean): Observable<SenecaResponse<BlogPost[]>> {
    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }
    httpParams = handleSearchedText(httpParams, searchedText);
    if(sorting) {
      if(sorting == 'S') {
        httpParams = httpParams.append('profiledForMe', 'true')
      } else {
      httpParams = httpParams.append('orderBy', sorting);
      }
    }
    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleContentAreas(httpParams, contentArea);

    return this.http.get<SenecaResponse<BlogPost[]>>(this._mediatorUrl + 'list-blog-articles', { params: httpParams });
  }

  /**
   * Retrieves available Blog articles that matches the criteria
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string} sorting - Optional:  Flag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc) (default D if no filter, S otherwise)
   * @param {string[]} tagIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @return {BlogPost[]}
   */
  listSuggestedBlogArticles(contentArea?: ContentArea, fromRecord?: number, numRecords?: number, searchedText?: string, tagIds?: string | string[],
    sorting?: string, mySavedOnly?: boolean, itemIdsToIgnore?: string): Observable<SenecaResponse<BlogPost[]>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);
    if(sorting) {
      if(sorting == 'S') {
        httpParams = httpParams.append('profiledForMe', 'true')
      } else {
      httpParams = httpParams.append('orderBy', sorting);
      }
    }
    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleContentAreas(httpParams, contentArea);

    if(itemIdsToIgnore && itemIdsToIgnore.length) {
      httpParams = httpParams.append('itemIdsToIgnore', itemIdsToIgnore)
    }

    return this.http.get<SenecaResponse<BlogPost[]>>(this._mediatorUrl + 'list-suggested-blog-articles', { params: httpParams });
  }

  /**
   * Retrieves available Blog articles that matches the criteria
   * @param {string[]} contentAreaIds
   * @param {string} searchedText - Optional for the action: The text provided by the user to start the search
   * @param {number} fromRecord - Optional: Number of record to start from (default 0)
   * @param {number} numRecords - Optional: Number of record returned (default 15)
   * @param {string} sorting - Optional:  Flag that indicates which sort will be used (S: score desc, D: create date desc, T: title asc) (default D if no filter, S otherwise)
   * @param {string[]} tagIds - Optional:  TagIds of Arguments to be used as filter
   * @param {boolean} mySavedOnly - Optional: If true only items that has been saved for later will be retrieved
   * @return {BlogPost[]}
   */
  listSuggestedBlogArticlesNew(contentArea?: ContentArea, fromRecord?: number, numRecords?: number, searchedText?: string, tagIds?: string | string[],
    sorting?: string, mySavedOnly?: boolean, itemIdsToIgnore?: string): Observable<SenecaResponse<BlogPost[]>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = handleFromRecord(httpParams, fromRecord);
    httpParams = handleNumRecords(httpParams, numRecords);
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleSorting(httpParams, sorting);
    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleTagIds(httpParams, tagIds);
    httpParams = handleContentAreas(httpParams, contentArea);

    if(itemIdsToIgnore && itemIdsToIgnore.length) {
      httpParams = httpParams.append('itemIdsToIgnore', itemIdsToIgnore)
    }

    return this.http.get<SenecaResponse<BlogPost[]>>(this._mediatorUrl + 'list-insight-hero-blog-articles', { params: httpParams });
  }

  /**
   * Save for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  saveArticleForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'save-article-for-later', { itemId });
  }

  /**
   * Remove for later the specified item
   * @param {string} itemId - Required: Item's id
   * @return {void}
   */
  removeArticleFromSavedForLater(itemId: string): Observable<SenecaResponse<void>> {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'remove-article-from-saved-for-later', { itemId });
  }

  /**
   * Send the feedback survey of the event
   * @param id Event's id
   */
  sendFeedbackSurvey(itemId: string, vote: number) {
    return this.http.post<SenecaResponse<BlogPost>>(this._mediatorUrl + 'judge-blog-article', { itemId: itemId, vote: vote });
  }

}
