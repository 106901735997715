import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { BlogPost } from "atfcore-commonclasses/bin/classes/blog";
import { CarouselCourseItem } from "atfcore-commonclasses/bin/classes/talent-farm";
import { CategoryCardInterface } from "generali";

import * as fromApp from '../../ngrx/app.reducers';

import { ModalService } from "src/app/core/modal/modal-services.component";
import { CategoryBadge } from "../interfaces/badge.interface";
import { BackType } from "../components/back-button/back-button.component";
import { UrlService } from "./url.service";
import { OfCourseMeObjectTypes } from "atfcore-commonclasses/bin/classes/of-course-me";
import { getScreenSizeCode } from "../utils";
import { ScreenSize } from "../interfaces/screen.interface";
import { AnalyticsService } from "./analytics.service";
import { AuthService } from "src/app/auth/services/auth.service";

/*
*    Servizio che gestisce i vari redirect
*/
@Injectable()
export class RedirectService implements OnDestroy {
  isAuthenticated: boolean;

  private _unsubscribeSignal$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromApp.AppState>,
    private modalService: ModalService,
    private urlService: UrlService,
    private location: Location,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private router: Router) {


    this.store.select(fromApp.isAuthenticated).pipe(takeUntil(this._unsubscribeSignal$.asObservable()))
      .subscribe(
        (isAuthenticated) => {
          this.isAuthenticated = isAuthenticated;
        });
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    this._unsubscribeSignal$.next();
    this._unsubscribeSignal$.unsubscribe();
  }

  // Porta all'home page
  goToHome() {
    this.router.navigate(['/home/main']);
    this.location.replaceState("/home/main");
  }

  goToIntro() {
    this.router.navigate(['/intro']);
  }

  goToCollectionList() {
    this.router.navigate(['/collection-list']);
  }

  goToFeatured() {
    this.router.navigate(['/featured']);
  }

  goToSupport() {
    this.router.navigate(['/support']);
  }

  goToSpecialprojectsList() {
    this.router.navigate(['/specialProjects/projectsList']);
  }

  goToHeroHomeSlideshow() {
    this.router.navigate(['/manageHeroHome/slideshowList']);
  }

  goToManageInspiredTags() {
    this.router.navigate(['/manageInspiredTags/inspiredTagList']);
  }

  goToEditSlide(id) {
    this.router.navigate(['/manageHeroHome/slideDetails/', id]);
  }

  goToManageFaq() {
    this.router.navigate(['/manageFaq/faqList']);
  }

  goToCollectionsHome() {
    this.router.navigate(['/collectionList/collectionListHome']);
  }

  goToSelectedForYou() {
    this.router.navigate(['/home/viewAll/COURSES_FOR_YOU/coursesForYouPage']);
  }

  goToFaculty() {
    this.router.navigate(['/faculty']);
  }

  // Rimuove i dati delle ricerche dal session storage
  removeSessionStorageSearchData() {
    const searchParams = sessionStorage.getItem('searchParams');
    const collectionListSearchParams = sessionStorage.getItem('collectionListSearchParams');

    if (searchParams) {
      sessionStorage.removeItem('searchParams');
    }
    if (collectionListSearchParams) {
      sessionStorage.removeItem('collectionListSearchParams');
    }
  }

  goToEditFaq(id) {
    this.router.navigate(['/manageFaq/faqDetail/', id]);
  }

  goToFeaturedCourses() {
    this.router.navigate(['/home/viewAll/FEATURED_COURSES/featuredCoursesPage']);
  }

  goToEditSpecialProject(id) {
    this.router.navigate(['/specialProjects/projectDetails/', id]);
  }

  // Porta alla gestione delle visibilità dei progetti speciali
  goToMangeSpecialProjectsVisibilities(id) {
    this.router.navigate(['/specialProjects/manageVisibilities', id]);
  }

  goToLocalLogin() {
    this.router.navigate(['/localLogin']);
  }

  goToMobileSearch() {
    this.router.navigate(['/home/mobileSearch']);
  }

  goToFirstIntro() {
    this.router.navigate(['/first-intro']);
  }

  goToFirstIntroFromHome() {
    this.router.navigate(['/first-intro-home']);
  }

  goToFirstIntroMailConfirmation() {
    this.router.navigate(['/first-intro-mail-confirmation']);
  }

  goToProfile() {
    this.router.navigate(['/profile']);
  }

  goToLogin() {
    this.router.navigate(['/localLogin']);
  }

  goToPrivacy() {
    let applicationContext = this.urlService.getApplicationUrl().url;
    applicationContext = applicationContext + '/#/privacy';
    window.open(applicationContext, '_blank');
  }

  goToPrivacySecurity() {
    let applicationContext = this.urlService.getApplicationUrl().url;
    applicationContext = applicationContext + '/#/privacySecurity';
    window.open(applicationContext, '_blank');
  }

  goToTermsOfUse() {
    let applicationContext = this.urlService.getApplicationUrl().url;
    applicationContext = applicationContext + '/#/termsOfUse';
    window.open(applicationContext, '_blank');
  }

  // Porta alla schermata di search
  goToSearch() {
    this.router.navigate([`/search/homeSearch`]);
  }

  goToMobileMenuPage() {
    this.router.navigate(["/home/mobileMenuPage"]);
  }

  goToTab(tab: CategoryBadge) {
    tab.redirect();
  }

  /**
   * Go to the specific card based on card type
   * @param cardType Card type
   * @param id Item id
   */
  goToCardSection(cardType: string, card: any) {
    const id = card && (card.itemId || card.contestId || card.id);
    if (cardType === 'course' || cardType === 'featuredCourses') {
      const course: CarouselCourseItem = card;
      if (course.itemType === OfCourseMeObjectTypes.OF_COURSE_ME_COLLECTION) {
        this.goToOfCourseMe(id);
      } else {
        this.goToCourse(id);
      }
    } else if (cardType === 'category') {
      const categoryCard: CategoryCardInterface = card;
      this.goToCategories(categoryCard.title);
    } else if (cardType === 'blog') {
      const article: BlogPost = card;
      if (article.requiresUserRegistration && !this.isAuthenticated) {
        this.modalService.openModalLogin();
      } else {
        this.goToArticleDetails(id);
      }
    } else if (cardType === 'general-event') {
      this.goToEvent(id);
    } else if (cardType === 'job') {
      this.goToJobPosition(id);
    } else if (cardType === 'badge') {
      this.goToProfileBadgesFromProfile();
    } else if (cardType === 'challenge') {
      this.goToChallenge(id);
    } else if (cardType === 'challenge-team') {
      this.goToChallengeTeam(id);
    }
  }

  goToCourse(itemId: string) {
    this.router.navigate([`/home/course/${itemId}`]);
  }

  goToOfCourseMe(itemId: string) {
    this.router.navigate([`/home/ofcourseme/${itemId}`]);
  }

  // Porta al dettaglio dell'oggetto dalla home
  goToItemDetailsFromHome(itemId: string) {
    this.router.navigate([`/home/itemDetails/${itemId}`]);
  }

  // Porta al dettaglio della collezione dal web dalla home
  goToWebCollectionDetailsFromHome(itemId: string) {
    this.router.navigate([`/home/webCollectionDetails/${itemId}`]);
  }

  // Porta al dettaglio della collezione degli item dalla home
  goToItemCollectionDetailsFromHome(itemId: string) {
    this.router.navigate([`/home/itemCollectionDetails/${itemId}`]);
  }

  // Porta al dettaglio dell'oggetto dalla pagina di ricerca
  goToItemDetailsFromSearchHome(itemId: string) {
    this.router.navigate([`/search/itemDetails/${itemId}`]);
  }

  // Porta al dettaglio della collezione dal web dalla pagina di ricerca
  goToWebCollectionDetailsFromSearchHome(itemId: string) {
    this.router.navigate([`/search/webCollectionDetails/${itemId}`]);
  }

  // Porta al dettaglio dell'oggetto dal profilo
  goToItemDetailsFromProfile(itemId: string) {
    this.router.navigate([`/profile/itemDetails/${itemId}`]);
  }

  goToOfCourseMeModule(itemId: string, moduleId: string) {
    this.router.navigate([`/home/ofcourseme/${itemId}/${moduleId}`]);
  }

  goToEvent(itemId: string) {
    this.router.navigate([`/home/event/${itemId}`]);
  }

  goToJobPosition(contestId: string) {
    this.router.navigate(['home/job/' + contestId]);
  }

  goToCourseModule(learningPlanId: string, moduleId: string) {
    this.router.navigate([`/home/course/${learningPlanId}/${moduleId}`]);
  }

  goToCourses = () => {
    this.router.navigate(['/home/courses']);
  }

  goToJobs = () => {
    this.router.navigate(['/home/jobs']);
  }

  goToEvents = () => {
    this.router.navigate(['/home/events']);
  }

  goToCategories = (category?: string) => {
    if (category) {
      this.router.navigate([`/home/categories/${category.toLowerCase()}`]);
    } else {
      this.router.navigate(['/home/categories']);
    }
  }

  goToNews = () => {
    this.router.navigate(['/home/insight']);
  }

  /**
   * Go to the details of the article
   * @param itemId Article id
   * @param back Back to
   */
  goToArticleDetails(itemId: string, back?: BackType) {
    if (back) {
      this.router.navigate(['/home/insight/article/' + itemId + '/' + back]);
    } else {
      this.router.navigate(['/home/insight/article/' + itemId]);
    }
  }

  /**
   *
   * @param category Category label
   */
  goToArticleCategory(category: string) {
    this.router.navigate(['/home/insight/' + category.toLowerCase()]);
  }

  goToChallenges = () => {
    this.router.navigate(['/home/challenges']);
  }

  goToChallenge = (challengeId: string) => {
    this.router.navigate(['/home/challenge/' + challengeId]);
  }

  goToChallengeTeam = (challengeId: string) => {
    this.router.navigate(['/home/challenge/teamTabActive/' + challengeId]);
  }

  goToPrems = () => {
    this.router.navigate(['/home/prems']);
  }

  goToHomeGenerali = () => {
    window.open('https://www.generali.it/', '_blank');
  }

  goToProfileBadges = () => {
    this.router.navigate(['/profile/badges']);
  }

  goToProfileBadgesFromProfile = () => {
    this.router.navigate(['profile/badges']);
  }

  goToPrizesCategory(tagId: string) {
    this.router.navigate(['/home/prems/' + tagId]);
  }

  goToProfileModalOpened() {
    this.router.navigate(['profile/openProfileModal']);
  }

  // Vai a smart challenge
  goToSmartChallenge() {
    const getTokenFromKeyPromise = this.getTokenFromSsortkqp();
    getTokenFromKeyPromise.then((ssorqtp: string) => {
      let redirectUrl = '';
      let baseUrl = '';

      if (this.urlService.isDevelopment()) {
        baseUrl = 'http://generali.isite.it/#/';
      } else {
        baseUrl = 'https://www.generalilearningplatform.com/microlearning/#/';
      }
      this.analyticsService.sendVirtualPageViewEvent(baseUrl, "SmartChallenge");

      redirectUrl = baseUrl + '?ssortkqp=' + ssorqtp;
      window.open(redirectUrl, "_self")
    })
  }

   // Vai a smart challenge
   goToCyberChallenge() {
    const getTokenFromKeyPromise = this.getTokenFromSsortkqp();
    getTokenFromKeyPromise.then((ssorqtp: string) => {
      let redirectUrl = '';
      let baseUrl = '';
      if (this.urlService.isProduction()) {
        baseUrl = 'https://learningup.generali.it/challenge/#/CYBER_CHALLENGE/home';
      } else if (this.urlService.isDevelopment()) {
        baseUrl = 'http://generali.isite.it/#/';
      } else {
        baseUrl = 'https://testglp2022.genurl.it/challenge/#/CYBER_CHALLENGE/home';
      }
      this.analyticsService.sendVirtualPageViewEvent(baseUrl, "CyberChallenge");

      redirectUrl = baseUrl + '?ssortkqp=' + ssorqtp;
      window.open(redirectUrl, "_self")
    })
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp() {
    return new Promise((resolve, reject) => {
      this.authService.crateRetrieveTokenAfterLogin().subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          reject();
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err) => {
          reject();
        })
    }
    )
  }

  // Vai a Values&Behaviors
  openValuesAndBehaviours() {
    this.router.navigate(['/valuesAndBehaviours']);
  }

  // Porta alla pagina Women Insurance
  goToWomenInsuranceNetworkHome() {
    this.router.navigate(['/womenInsuranceNetwork/home']);
}

}
