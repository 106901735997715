<div class="select-with-checkbox" fxLayout="row" fxLayoutAlign="start center">
  <ng-select [id]="_id" fxFlex [virtualScroll]="_virtualScroll" [bindValue]="bindValue" [bindLabel]="bindLabel"
    [items]="items" [placeholder]="labelForPlaceholder" [multiple]="_multiple" (add)="emitAddItem($event)"
    [closeOnSelect]="_closeOnSelect" (remove)="emitRemoveItem($event)" (change)="emitOnSelectionChanged($event)"
    [(ngModel)]="_selectedValue" (ngModelChange)="emiOnModelChanged($event)" [dropdownPosition]="dropdownPosition"
    (scrollToEnd)="emitOnScrollToEnd($event)" (open)="emitOnOpen()" (close)="emitOnClose()" [addTag]="addTag"
    aria-label="header-search" [clearOnBackspace]="clearOnBackspace" [searchable]="_searchable"
    [hideSelected]="_hideSelected" [loading]="_loading" [compareWith]="compareWith" [markFirst]="markFirst"
    #alloySelect>

    <!-- Templace seleziona tutto -->
    <ng-template *ngIf="_hasSelectAll && items && items.length > 0" ng-header-tmp>
      <custom-checkbox avoidLabelClick [simpleValue]="_isSelectedAll"
        (onChangeValue)="_isSelectedAll ? unselectAll() : selectAll()">
        <h6>{{ selectAllLabel || 'Select all' }}</h6>
      </custom-checkbox>
    </ng-template>

    <!-- Template per l'inizio della ricerca -->
    <ng-template ng-typetosearch-tmp>
      <div class="ng-option disabled">{{ startSearchLabel || 'Inizia una ricerca' }}</div>
    </ng-template>
    <!-- Template per i dati non trovati -->
    <ng-template ng-notfound-tmp let-searchTerm="filter">
      <div class="ng-option disabled">{{ noDataFoundLabel || 'Nessun dato trovato' }}</div>
    </ng-template>
    <!-- Template per il caricamento dei risultati -->
    <ng-template ng-loadingtext-tmp>
      <div class="ng-option disabled">{{ loadingDataLabel || 'Caricamento in corso' }}</div>
    </ng-template>

    <!-- Template per i singoli item nelle options -->
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <custom-checkbox [tooltip]="resolveItemTitle(item)" avoidLabelClick [simpleValue]="item$.selected" (onChangeValue)="onChangeValue($event)">
        <h6>{{ resolveItemTitle(item) }}</h6>
      </custom-checkbox>
    </ng-template>
  </ng-select>
  <!-- Arrow with compability for IE -->
  <div class="cursor-pointer select-with-checkbox-arrow" fxLayout="column" fxLayoutAlign="center center"
    (click)="alloySelect.handleArrowClick()">
    <svg-icon [ngClass]="{'opened': alloySelect.isOpen}" src="../../../../assets/icons/icon-arrow-down.svg"></svg-icon>
  </div>
</div>
