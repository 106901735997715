import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpRequest } from "@angular/common/http";

import { SenecaResponse } from "atfcore-commonclasses/bin/classes/common";
import { Challenge, RankingElement, TalentFarmSenecaResponse, ChallengeTeam, ChallengeTeamMember } from "atfcore-commonclasses/bin/classes/talent-farm";
import { UrlService } from 'src/app/shared/services/url.service';
import { handleSearchedText, handleCustomList, handleFromRecord, handleNumRecords } from 'src/app/shared/utils';
import { ChallengeImplemented } from "src/app/shared/interfaces/challenges.interface";
import { ChallengeTeamMemberExtended } from 'src/app/shared/models/user';
import { NgTypeToSearchTemplateDirective } from '@ng-select/ng-select/lib/ng-templates.directive';

@Injectable()
export class ChallengesService {
  private _mediatorUrl: string;

  constructor(private urlService: UrlService, private http: HttpClient) {
    this._mediatorUrl = this.urlService.getServiceMediatorUrl();
  }

  // Count delle challenges
  countChallenges(searchedText?: string, challengeTypes?: string[], categoryIds?: string[], contentAreaIds?: string[], professionalFieldIds?: string[], seniorities?: string[], mySavedOnly?: boolean, itemIdsToIgnore?: string[], profiledForMe?: boolean): Observable<SenecaResponse<number>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleCustomList(httpParams, 'challengeTypes', challengeTypes);
    if(categoryIds && categoryIds.length) {
    httpParams = handleCustomList(httpParams, 'categoryIds', categoryIds);
    }
    httpParams = handleCustomList(httpParams, 'contentAreaIds', contentAreaIds);
    httpParams = handleCustomList(httpParams, 'professionalFieldIds', professionalFieldIds);
    httpParams = handleCustomList(httpParams, 'seniorities', seniorities);
    httpParams = handleCustomList(httpParams, 'itemIdsToIgnore', itemIdsToIgnore);
    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-challenges', { params: httpParams });
  }

  // Get delle challenges
  getChallenges(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string, challengeTypes?: string[], categoryIds?: string[], contentAreaIds?: string[], professionalFieldIds?: string[], seniorities?: string[], mySavedOnly?: boolean, itemIdsToIgnore?: string[], profiledForMe?: boolean): Observable<SenecaResponse<ChallengeImplemented[]>> {
    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleCustomList(httpParams, 'challengeTypes', challengeTypes);
    if(categoryIds && categoryIds.length) {
    httpParams = handleCustomList(httpParams, 'categoryIds', categoryIds);
    }
    httpParams = handleCustomList(httpParams, 'contentAreaIds', contentAreaIds);
    httpParams = handleCustomList(httpParams, 'professionalFieldIds', professionalFieldIds);
    httpParams = handleCustomList(httpParams, 'seniorities', seniorities);
    httpParams = handleCustomList(httpParams, 'itemIdsToIgnore', itemIdsToIgnore);
    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    return this.http.get<SenecaResponse<ChallengeImplemented[]>>(this._mediatorUrl + 'list-challenges', { params: httpParams });
  }

  // Get delle challenges suggerite
  listSuggestedChallenges(allData?: boolean, fromRecord?: number, numRecords?: number, searchedText?: string, challengeTypes?: string[], categoryIds?: string[], contentAreaIds?: string[], professionalFieldIds?: string[], seniorities?: string[], mySavedOnly?: boolean, itemIdsToIgnore?: string[], profiledForMe?: boolean): Observable<SenecaResponse<Challenge[]>> {
    let httpParams: HttpParams = new HttpParams();

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = mySavedOnly ? httpParams.append('mySavedOnly', 'true') : httpParams;
    httpParams = handleSearchedText(httpParams, searchedText);
    httpParams = handleCustomList(httpParams, 'challengeTypes', challengeTypes);
    httpParams = handleCustomList(httpParams, 'categoryIds', categoryIds);
    httpParams = handleCustomList(httpParams, 'contentAreaIds', contentAreaIds);
    httpParams = handleCustomList(httpParams, 'professionalFieldIds', professionalFieldIds);
    httpParams = handleCustomList(httpParams, 'seniorities', seniorities);
    httpParams = handleCustomList(httpParams, 'itemIdsToIgnore', itemIdsToIgnore);
    if(profiledForMe) {
      httpParams = httpParams.append('profiledForMe', 'true')
    }

    return this.http.get<SenecaResponse<Challenge[]>>(this._mediatorUrl + 'list-suggested-challenges', { params: httpParams });
  }

  /**
   * Get challenge by id
   * @param {string} challengeId
   * @return {any}
   */
  getChallengeById(challengeId: string): Observable<SenecaResponse<Challenge>> {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<Challenge>>(this._mediatorUrl + 'get-challenge-by-id', { params: httpParams });
  }

  userSelfCancelFromChallenge(challengeId: string) {
    return of(new SenecaResponse<any>(null, true));
  }

  userSelfConfirmToChallenge(challengeId: string) {
    let httpParams: HttpParams = new HttpParams();

    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<TalentFarmSenecaResponse<boolean>>(this._mediatorUrl + 'track-user-external-challenge-participation', { params: httpParams });
  }

  // Salva la challenge per dopo
  saveChallengeForLater(challengeId: string) {
    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'save-challenge-for-later', { challengeId: challengeId });
  }

  // Rimuove la challenge per dopo
  removeChallengeForLater(challengeId: string) {

    return this.http.post<SenecaResponse<void>>(this._mediatorUrl + 'remove-challenge-from-saved-for-later', { challengeId: challengeId });
  }

  countLeaderboard(allData?: boolean, fromRecord?: number, numRecords?: number, challengeId?: string, searchedText?: string) {
    let httpParams: HttpParams = new HttpParams();

    if(searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-challenge-ranking-by-id', { params: httpParams });
  }

  getLeaderboard(allData?: boolean, fromRecord?: number, numRecords?: number, challengeId?: string, searchedText?: string) {
    let httpParams: HttpParams = new HttpParams();

    if(searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<RankingElement[]>>(this._mediatorUrl + 'list-challenge-ranking-by-id', { params: httpParams });
  }

  // Compra un contenuto premium oppure un premio da riscattare
  reedemPrize(itemId: string) {
    return this.http.post<TalentFarmSenecaResponse<number>>(this._mediatorUrl + 'redeem-prize', { itemId: itemId });
  }

  // Recupera il count dei documenti
  countDocuments(challengeId?: string, searchedText?: string, teamId?: string) {
    let httpParams: HttpParams = new HttpParams();

    if(searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if(teamId && teamId.length) {
      httpParams = httpParams.append('teamId', teamId);
    }

    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-folder-and-document-challenge', { params: httpParams });
  }

  // Recupera il get dei documenti
  getDocuments(allData?: boolean, fromRecord?: number, numRecords?: number, challengeId?: string, searchedText?: string, teamId?: string) {
    let httpParams: HttpParams = new HttpParams();

    if(searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if(teamId && teamId.length) {
      httpParams = httpParams.append('teamId', teamId);
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<RankingElement[]>>(this._mediatorUrl + 'list-folder-and-document-challenge', { params: httpParams });
  }

  // Recupera il count dei documenti all'interno della cartella
  countDocumentsInsideFolder(challengeId?: string, searchedText?: string, teamId?: string, folderId?: string) {
    let httpParams: HttpParams = new HttpParams();

    if(searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if(teamId && teamId.length) {
      httpParams = httpParams.append('teamId', teamId);
    }

    if(folderId && folderId.length) {
      httpParams = httpParams.append('folderId', folderId);
    }

    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-folder-and-document-challenge', { params: httpParams });
  }

  // Recupera il get dei documenti all'interno della cartella
  getDocumentsInsideFolder(allData?: boolean, fromRecord?: number, numRecords?: number, challengeId?: string, searchedText?: string, teamId?: string, folderId?: string) {
    let httpParams: HttpParams = new HttpParams();

    if(searchedText && searchedText.length) {
      httpParams = httpParams.append('searchedText', searchedText || '');
    }

    if(teamId && teamId.length) {
      httpParams = httpParams.append('teamId', teamId);
    }

    if(folderId && folderId.length) {
      httpParams = httpParams.append('folderId', folderId);
    }

    if (allData) {
      httpParams = httpParams.append('allData', 'true');
    } else {
      httpParams = handleFromRecord(httpParams, fromRecord);
      httpParams = handleNumRecords(httpParams, numRecords);
    }
    httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<RankingElement[]>>(this._mediatorUrl + 'list-folder-and-document-challenge', { params: httpParams });
  }

  // Elimina il documento
  deleteDocument(documentId: string[]) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'delete-document-challenge', { documentId: documentId });
  }

  // Elimina la cartella
  deleteFolder(folderId: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'delete-folder-challenge', { folderId: folderId });
  }

  // Cancella sia documenti che cartelle
  deleteDocumentsAndFolders(tagIds: string[]) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'delete-document-folder-challenge', { tagIds: tagIds });
  }

  createDocument(uploadObj, fileTitle, challengeId, folderId?: string, teamId?: string) {
    let formData = new FormData();
        formData.append('file', uploadObj);
        formData.append('fileName', fileTitle);
        formData.append('title', fileTitle);
        formData.append('challengeId', challengeId);

        if(teamId) {
          formData.append('teamId', teamId);
        }

        if (folderId && folderId.length) {
        formData.append('folderId', folderId);
        }

        let params = new HttpParams();
        const options = {
            params: params
        };
        const req = new HttpRequest('POST', this._mediatorUrl + 'create-document-challenge', formData, options);
        return this.http.request(req);
  }

  // Crea nuova cartella
  createFolder(title: string, challengeId: string, teamId?: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'create-folder-challenge', { title: title, challengeId: challengeId, teamId: teamId });
  }

  // Iscriviti alla sfida interna individuale
  subscribeToInternalIndividualChallenge(challengeId: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'enroll-to-internal-challenge', { challengeId: challengeId });
  }

  // Ritorna tutti i tagId dei documenti
  getAllDocumentsTagIds(challengeId: string) {
    let httpParams: HttpParams = new HttpParams();
    if (challengeId && challengeId.length) {
        httpParams = httpParams.append('challengeId', challengeId);
  }
  return this.http.get<SenecaResponse<string>>(this._mediatorUrl + 'list-folder-and-document-challenge', { params: httpParams });
  }

  // Ritorna il count dei team
  countTeams(challengeId: string, searchedText?: string) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('challengeId', challengeId);
    if (searchedText && searchedText.length) {
        httpParams = httpParams.append('searchedText', searchedText || '');
  }
  return this.http.get<SenecaResponse<number>>(this._mediatorUrl + 'count-challenge-teams', { params: httpParams });
  }

  // Ritorna il get dei team
  getTeams(challengeId: string, allData?: boolean) {
    let httpParams: HttpParams = new HttpParams();
    httpParams = httpParams.append('challengeId', challengeId);
    if (allData) {
        httpParams = httpParams.append('allData', 'true');
  }
  return this.http.get<SenecaResponse<ChallengeTeam[]>>(this._mediatorUrl + 'list-challenge-teams', { params: httpParams });
  }

  // Crea un team
  createTeam(challengeId: string, teamName: string, description: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'create-challenge-team', { teamName: teamName, challengeId: challengeId, description: description });
  }

  // Aggiorna un team il titolo e la descrizione
  updateTeamInfo(challengeId: string, teamId: string, teamName: string, description: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'update-challenge-team', { teamName: teamName, teamId: teamId, challengeId: challengeId, description: description });
  }

  // Cancella un team
  deleteTeam(challengeId: string, teamId: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'delete-challenge-team', { challengeId: challengeId, teamId: teamId });
  }

  // Cancella un'utente dal team
  deleteUserFromTeam(challengeId: string, userId: string) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'remove-team-member', { challengeId: challengeId, userId: userId });
  }

  // Marca come letti i messaggi dello user indicato in input.
  chat_updateMarkMessage(recipientId) {
    return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'update-chat-mark', { recipientId: recipientId });
  }

  // Manda il messaggio
  chat_sendMessage(text, recipientId, clientId) {
    return this.http.post<SenecaResponse<any>>(
      this._mediatorUrl + 'send-chat-message',
      {
        text,
        recipientId,
        clientId
      }
    );
  }

  // Lista degli ultimi messaggi della chat di team
    chat_getLastMessage(
      recipientId: string,
      numRecords: string = "20",
      fromRecord: string = "0"
    ) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("numRecords", numRecords);
      httpParams = httpParams.append("fromRecord", fromRecord);
      httpParams = httpParams.append("recipientId", recipientId);
      return this.http.get<SenecaResponse<any>>(this._mediatorUrl + 'get-last-chat-message', { params: httpParams });
    }

    // Inizializza la chat di team
    chat_initializeChat(clientId, challengeId) {
      return this.http.post<SenecaResponse<any>>(this._mediatorUrl + 'initialize-chat', { clientId: clientId, challengeId: challengeId });
    }

    // Download di un documento o di una cartella
    downloadDocument(challengeId: string, folderId: string, teamId?: string, allDocs?: boolean, allDocumentsInsideFolder?: boolean, isOnFolderView?: boolean) {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.append('challengeId', challengeId);
      if(allDocumentsInsideFolder || isOnFolderView) {
        httpParams = httpParams.append('folderId', folderId);
      }
      else if(!allDocs) {
      httpParams = httpParams.append('tagIds', folderId);
      }
      if(teamId) {
        httpParams = httpParams.append('teamId', teamId);
      }

    return this.http.get<SenecaResponse<string>>(this._mediatorUrl + 'download-document-challenge', { params: httpParams });
    }

    // Unisciti ad un team esistente
    joinToTeam(challengeId, teamId: string, message: string) {
      return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'request-team-membership', { challengeId: challengeId, teamId: teamId, message: message });
    }

    // Recupera gli utenti dentro ad un team
    getTeamMembers(challengeId: string) {
      let httpParams: HttpParams = new HttpParams();
      httpParams = httpParams.append('challengeId', challengeId);

    return this.http.get<SenecaResponse<ChallengeTeamMemberExtended[]>>(this._mediatorUrl + 'get-my-challenge-team-members', { params: httpParams });
    }

    // Approva nuovo utente al team
    approveNewTeamMember(challengeId: string, userId: string) {
      return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'approve-new-team-member', { challengeId: challengeId, userId: userId });
    }

    // Rifiuta nuovo utente al team
    refuseNewTeamMember(challengeId: string, userId: string) {
      return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'refuse-new-team-member', { challengeId: challengeId, userId: userId });
    }

    // Manda mail invita un amico al team
    sendMailToChallengeParticipant(challengeId: string, teamId: string, email: string) {
      return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'invite-new-team-member', { challengeId: challengeId, teamId: teamId, email: email });
      /* return of(new SenecaResponse<any>(null, true)); */
    }

    // Accetta invito a partecipare al team
    acceptChallengeTeamInvite(challengeId: string, teamId: string) {
      return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'accept-challenge-team-invite', { challengeId: challengeId, teamId: teamId });
      /* return of(new SenecaResponse<any>(null, true)); */
    }

    // Rifiuta invito a partecipare al team
    refuseChallengeTeamInvite(challengeId: string, teamId: string) {
      return this.http.post<SenecaResponse<boolean>>(this._mediatorUrl + 'decline-challenge-team-invite', { challengeId: challengeId, teamId: teamId });
      /* return of(new SenecaResponse<any>(null, true)); */
    }
}
